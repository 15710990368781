import React from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./textEditor.scss";

interface Props {
  enableAnchors?: boolean;
  onChange: (value: string) => void;
  value: string;
}

export default function TextEditor(props: Props): JSX.Element {
  const {enableAnchors=false, onChange, value} = props;
  const toolbar = ["bold", "italic", "bulletedList", "numberedList"];

  if (enableAnchors) {
    toolbar.push("link");
  }

  return (
    <CKEditor
      config={{toolbar}}
      data={value}
      editor={ClassicEditor}
      onChange={(_, editor) => onChange(editor.getData())}
    />
  );
}
