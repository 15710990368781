import React from "react";
import PropTypes from "prop-types";
import styles from "./projectTemplateCard.module.css";

class ProjectTemplateCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const projectTemplate = this.props.data;

    return (
      <a className={styles.container} href={Variables.urls.projects.bespoke + "?project-template=" + projectTemplate.uuid}>
        <div className={styles.header}>
          <div className={styles.icon} style={{backgroundImage: `url("${projectTemplate.icon}")`}} />
          <p className={styles.title}>{projectTemplate.name}</p>
        </div>
        <ul className={styles.list}>
          {projectTemplate.activities.slice(0, 4).map(activity =>
            <li className={styles.activity} key={activity}>{activity}</li>,
          )}
        </ul>
      </a>
    );
  }
}

ProjectTemplateCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProjectTemplateCard;
