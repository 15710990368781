import React from "react";
import classNames from "classnames";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {addLocale} from "primereact/api";
import {Calendar as PrimeCalendar} from "primereact/calendar";
import PropTypes from "prop-types";

import styles from "./calendar.module.scss";
import useConstraintValidation from "./useConstraintValidation";


addLocale("en_GB", {
  firstDayOfWeek: 1,
});

/*
 * A wrapper around the PrimeReact calendar, integrating it with our automatic
 * form validation and providing some consitent styling and defaults.
 */
function BaseCalendar({className, name, onChange, required, touchUI, value, ...otherProps}) {
  const {className: constraintClassName} = useConstraintValidation({name, value, validator});

  function validator(value) {
    if (required && value == null) {
      return {
        isValid: false,
        reason: "This field is required",
      };
    }
    return {isValid: true};
  }

  return (
    <PrimeCalendar
      className={classNames(className, constraintClassName, styles.calendarInput)}
      dateFormat="dd/mm/yy"
      locale="en_GB"
      name={name}
      onChange={e => onChange(e.target.value, e.target.name)}
      placeholder="dd/mm/yyyy"
      required={required}
      value={value}
      touchUI={touchUI ?? (window.innerWidth <= 576)}
      {...otherProps}
    />
  );
}

BaseCalendar.propTypes = {
  appendTo: PropTypes.instanceOf(Element),
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  theme: PropTypes.object,
  touchUI: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

export default BaseCalendar;
