import React from "react";
import SocialMediaShareButtons from "./socialMediaShareButtons";
import styles from "./storyCard.module.scss"
import classNames from "classnames";
import { textWithLineBreaks } from "util/react-string";
import { STORY_TYPE_MAP, STORY_TYPE_URL_MAP, StoryCardProps, STORY_TYPE } from "./constants"

const StoryCard = ({story} : StoryCardProps) => {
  const photoUrl = story.story_type == STORY_TYPE.CANDIDATE
    ? story.candidate_photo
    : story.host_photo
  const detailPageUrl = `/stories/${STORY_TYPE_URL_MAP[story.story_type]}/${story.username}`;

  return (
    <div className={styles.storyCard}>
      { photoUrl &&
        <div className={styles.storyImage} style={{ backgroundImage: `url(${photoUrl})`}}
          onClick={() => window.location.href = detailPageUrl}/>
      }
      <div className={styles.companyInfo} onClick={() => window.location.href = detailPageUrl}>
        {
          story.company_logo &&
          <img src={story.company_logo}/>
        }
        <h2>{story.company_name}</h2>
      </div>
      <div className={styles.nameStoryType} onClick={() => window.location.href = detailPageUrl}>
        <span>{STORY_TYPE_MAP[story.story_type]} Story</span>
        <span>&#x2022;</span>
        <span>{story.author_full_name}</span>
      </div>
      <div className={styles.description}>{textWithLineBreaks(story.quote, 520)}</div>
      <div className={styles.cardFooter}>
        <SocialMediaShareButtons story={story} />
        <a className={styles.linkDetailPage} href={detailPageUrl}>
          <span className={styles.readStory}>Read story</span>
          <i className={classNames(styles.arrowRight, "fas fa-arrow-right")}></i>
        </a>
      </div>
    </div>
  );
}

export default StoryCard;
