import React, {useState} from "react";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";
import {Content} from "components/shared/Slate";
import Modal from "components/shared/Modal/Modal";
import styles from "./offerSentModal.module.scss";
import {
  Heading,
  Body,
} from "workfinder-components";
import DefaultLogo from "/static/workfinder/images/generic-photo.png";
import cone from "/static/workfinder/images/cone.png";

export function CandidateCard({candidate, isExtension}) {
  const photo = candidate.photo ? candidate.photo : DefaultLogo;
  const currentDate = moment();
  let differenceInDays = 0;
  if (isExtension) {
    const end_date = moment(candidate.offer_end_date);
    differenceInDays = end_date.diff(currentDate, "days");
  }

  return (
    <div className="card">
      <div className="card-body media">
        <img
          className={classNames(styles.candidatePhoto, "mr-3")}
          src={photo} alt={candidate.user.full_name}
        />
        <div className="media-body">
          <Heading className="mt-0" size="h6">
            <a
              className={styles.candidateNameLink}
              target="_blank"
              rel="noreferrer"
              href={Variables.urls.hosts.applicant_details.replace("<placement_id>", candidate.placementID)}
            >
              {candidate.user.full_name}
            </a>
          </Heading>
          <Body className="mb-1">For&nbsp;
            <a className={styles.roleNameLink}
              target="_blank"
              rel="noreferrer"
              href={Variables.urls.projects.detail.replace("<uuid:uuid>", candidate.projectId)}
            >{candidate.project}</a></Body>
          <Body>At {candidate.company_name}</Body>
          {isExtension && differenceInDays > 0 && differenceInDays && <Heading size="h6" className={styles.extensionDaysText}>
            Role ends in {differenceInDays} Days
          </Heading>}
        </div>
      </div>
    </div>
  );
}

export default function OfferSentModal({candidate, onClose, open, ...modalProps}) {
  if (!open) {
    return null;
  }
  return (
    <Modal id="offer-sent-modal"
      open={open}
      onClose={onClose}
      className={styles.offerSentModal}
      {...modalProps}
    >
      <Content className={styles.content}>
        <CandidateCard candidate={candidate} isExtension={false}/>
        <div className="d-flex align-items-center flex-column mt-4">
          <img width="100px" src={cone} alt="globe" />
          <Heading size="h5">Congrats! Your role offer has been sent</Heading>
          <Body>We will inform you as soon as we hear back from {candidate.user.first_name}. </Body>
        </div>
      </Content>
    </Modal>
  );
}
