import {useCallback, useEffect, useMemo, useState} from "react";
import {useQueryClient} from "react-query";
import useWebSocket from "react-use-websocket";


import {MessageTypes} from "services/ws/chat";
import {useChatMessagesQuery} from "services/queries";
import {addToListCache} from "util/mutation";

interface useChatParams {
  /** The query parameters to pass to `getChatMessages`. */
  queryParams?: {correspondent?: uuid};
  /** Whether chat functionality should be enabled. */
  enabled?: boolean;
  /** The callback to call when an error occurs. */
  onError?: (msg: string) => void;
  /** The callback to call when a text message comes in. */
  onMessageIDCreated?: (data: Any) => void;
  /** The callback to call when a message is read. */
  onMessageRead?: (data: Any) => void;
  /** The callback to call when a text message comes in. */
  onTextMessage?: (data: Any) => void;
  /** The callback to call when a file message comes in. */
  onFileMessage?: (data: Any) => void;
}

interface useChatReturn {
  /** Whether the hook is still loading either messages or connecting to the chat socket. */
  isLoading: boolean;
  /** Whether an error has occurred. */
  isError: boolean;
  /** Whether the messages have been loaded and the chat socket opened. */
  isOpen: boolean;
  /** The last error that occurred. */
  error: string | null;
  /** The messages in the chat dialog. */
  messages: ChatMessage[];
  /** A function to mark a message as read. */
  markRead: (message_id: number) => void;
  /** A function to send a message. */
  sendMessage: (text: string, optionals?: {user_id?: uuid, random_id?: number}) => void;
  /** A function to send a file. */
  sendFile: (user_id: uuid, random_id?: number) => void;
}

function getWebSocketURL(): string {
  const wsURL = window.location.origin.replace(/^http/, "ws");
  return `${wsURL}/ws/chat/`;
}

function getRandomId() {
  return Math.floor(Math.random() *  ((1 << 31)-1) + 1);
}

export default function useChat(params: useChatParams): useChatReturn {
  const {
    queryParams={},
    enabled,
    onError,
    onFileMessage,
    onMessageRead,
    onMessageIDCreated,
    onTextMessage,
  } = params;
  const [error, setError] = useState<string | null>(null);
  const queryKey = useMemo(() => ["chat-messages", queryParams], [queryParams]);
  const queryClient = useQueryClient();
  const messageQuery = useChatMessagesQuery(
    queryParams,
    {
      enabled,
      onError() {
        const errStr = "Could not retrieve chat messages.";
        onError?.(errStr);
        setError(errStr);
      },
    },
  );
  const messages = messageQuery.data;
  const handleMessage = useCallback(e => {
    const data = JSON.parse(e.data);
    if (queryClient) {
      if (data.msg_type === MessageTypes.TEXT_MESSAGE || data.msg_type === MessageTypes.FILE_MESSAGE) {
        data.out = false;
        addToListCache(queryClient, queryKey, data, 0);
        if (data.msg_type === MessageTypes.TEXT_MESSAGE) {
          onTextMessage?.(data);
        }
        else {
          onFileMessage?.(data);
        }
      }
      else if (data.msg_type === MessageTypes.MESSAGE_ID_CREATED) {
        queryClient?.setQueryData(
          queryKey,
          old => old === undefined ? undefined : old.map(message =>
            message.random_id === data.random_id ?
              {...message, id: data.db_id} :
              message,
          ),
        );
        onMessageIDCreated?.(data);
      }
      else if (data.msg_type === MessageTypes.MESSAGE_READ) {
        onMessageRead?.(data);
      }
    }
  }, [queryClient, queryKey, onFileMessage, onMessageIDCreated, onMessageRead, onTextMessage]);
  const wsConn = useWebSocket(
    enabled ? getWebSocketURL() : null,
    {
      onMessage: handleMessage,
      shouldReconnect: () => true,
    },
  );
  const isError = messageQuery.isError || error !== null;
  const isOpen = messageQuery.isSuccess;
  const isLoading = messageQuery.isLoading;

  useEffect(() => {
    if (!enabled) {
      setError(null);
    }
  }, [enabled]);

  /**
   * Mark the message as read.
   * @param text Message to send.
   * @param user_id UUID of the user whose will be notified the message has
   *                been read.
   */
  const markRead = useCallback((message_id: number, user_id?: uuid): void => {
    user_id ??= queryParams.correspondent;
    wsConn.sendJsonMessage({
      msg_type: MessageTypes.MESSAGE_READ,
      message_id,
      user_id: user_id ?? queryParams.correspondent,
    });
    queryClient.setQueryData(queryKey, (old?: ChatMessage[]) => {
      if (old === undefined) {
        return undefined;
      }
      const newData =  old.map(message => message.id === message_id ? {...message, read: true} : message);

      queryClient.setQueryData(["dialog", user_id], old => {
        if (old === undefined) {
          return undefined;
        }
        return {
          ...old,
          // Clip to 0 in case the original unread count and messages were out of sync.
          unread_count: Math.max(old.unread_count - 1, 0),
          last_message: old.last_message.id === message_id ?
            {...old.last_message, read: true} :
            old.last_message,
        };
      });
      return newData;
    });
  }, [wsConn.sendJsonMessage, queryClient, queryParams.correspondent, queryKey]);

  /**
   * Send a plaintext message.
   * @param text Message to send.
   * @param user_id ID of the user to send the message to.
   * @param random_id A random negative integer, allowing the message to be
   *                  identified before the database assigns an ID.
   */
  const sendMessage = useCallback((text: string, optionals={}): void => {
    const {user_id} = optionals;
    const random_id = optionals.random_id ?? getRandomId();
    const time = new Date().toISOString();
    wsConn.sendJsonMessage({
      msg_type: MessageTypes.TEXT_MESSAGE,
      random_id,
      text,
      user_id: user_id ?? queryParams.correspondent,
    });
    addToListCache(
      queryClient,
      queryKey, {
        text,
        created: time,
        modified: time,
        read: false,
        file: null,
        sender: Variables.uuids.user,
        random_id,
        recipient: user_id,
        out: true,
      },
      0,
    );
  }, [wsConn.sendJsonMessage, queryClient, queryParams.correspondent, queryKey]);

  /**
   * TODO
   */
  const sendFile = useCallback((optionals = {}): void => {
    let {user_id, random_id} = optionals;
    const time = new Date().toISOString();
    user_id ??= queryParams.correspondent;
    random_id ??= getRandomId();
    wsConn.sendJsonMessage({
      msg_type: MessageTypes.FILE_MESSAGE,
      random_id,
      user_id,
    });
    addToListCache(
      queryClient,
      queryKey,
      {
        text: "",
        created: time,
        modified: time,
        read: false,
        file: null,
        sender: Variables.uuids.user,
        recipient: user_id,
        out: true,
      },
      0,
    );
  }, [wsConn.sendJsonMessage, queryClient, queryParams, queryKey]);

  return {
    isError,
    isLoading,
    isOpen,
    error,
    messages,
    markRead,
    sendMessage,
    sendFile,
  };
}
