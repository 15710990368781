import React, {createContext, useState} from "react";
import {Map} from "immutable";

import {
  DISABLE_REFETCH_OPTIONS,
  usePrimaryCompanyQuery,
  useCompanySocialLinksQuery,
} from "services/queries";
import _ from "lodash";

interface Context {
  isCompanyLoading: boolean;
  companyData: Map;
  isSocialLinksLoading: boolean;
  socialLinks: Map
}

export const CompanyPublicProfileContext = createContext<Context>({
  isCompanyLoading: false,
  companyData: null,
  isSocialLinksLoading: false,
  socialLinks: {},
});


const CompanyPublicProfileProvider = ({companyid, children}) => {
  const [companyData, setCompanyData] = useState({});
  const [socialLinks, setSocialLinks] = useState({});


  const {
    isLoading: isCompanyLoading,
  } = usePrimaryCompanyQuery(companyid, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      setCompanyData(data);
    },
  });
  const {isLoading: isSocialLinksLoading} = useCompanySocialLinksQuery(
    companyid,
    {
      ...DISABLE_REFETCH_OPTIONS,
      onSuccess: (data) => {
        if (data.length) {
          setSocialLinks(data[0]);
        }
        else {
          setSocialLinks({ });
        }
      },
    },
  );
  return <CompanyPublicProfileContext.Provider
    value={{
      isCompanyLoading,
      companyData,
      socialLinks,
      isSocialLinksLoading,
    }}
  >
    {children}
  </CompanyPublicProfileContext.Provider>;
};
export default CompanyPublicProfileProvider;

