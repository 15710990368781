import React, { useContext, useState } from "react";
import { Button, Caption, Subtitle, Tooltip } from "workfinder-components";
import styles from "./roleDetails.module.scss"
import classNames from "classnames";
import DefaultPhoto from "/static/workfinder/images/generic-photo.png";
import PauseProjectModal from "components/my-projects/PauseProjectModal";
import EventTracking from "tracking/EventTracking";
import { featureUrl, interestEvent } from "util/cms/constants";
import ShareModal from "components/shared/Modal/ShareModal";
import { formatDateTimeShort } from "util/date-time";
import SocialMediaShareDropdown from "components/shared/SocialMediaShareDropdown";
import ResumeProjectModal from "components/my-projects/ResumeProjectModal";
import CloneProjectModal from "./CloneProjectModal";
import { RoleListContext } from "components/company-overview/RoleListContextProvider";

const RoleDetails = ({role, roleTitle}) => {
  return (<div className="mb-1">
    <div className={styles.roleDetailsContainer}>
      <div className={styles.roleTitleContainer}>
        {roleTitle}
        {role && <RoleOtherDetails role={role} />}
      </div>
      {role && <RoleActions role={role} />}
    </div>
    <hr/>
    {role && <RoleOwnerCollaborators role={role} />}
  </div>);
}

export const RoleOtherDetails = ({role}) => {
  return (<div className="d-flex flex-column flex-sm-row">
    <Subtitle size="small" inline>{role.salary_str}, {role.working_hours_str}&nbsp;</Subtitle>
    <Subtitle size="small" inline className={classNames(styles.grey, "d-none d-sm-inline")}>•&nbsp;</Subtitle>
    <Subtitle size="small" inline className={styles.grey}>Last updated {formatDateTimeShort(role.updated_at)}</Subtitle>
  </div>);
}

export const RoleOwnerCollaborators = ({role}) => {
  const imgSrc = role.association.host.photo_thumbnails?.avatar || DefaultPhoto;
  return(<div className="d-flex align-items-center justify-content-between">
    <div className={styles.ownerContainer}>
      <img className={styles.hostImage} src={imgSrc}/>
      <div className="d-flex flex-column">
        <Caption className={styles.grey}>Role owner</Caption>
        <Caption>{role.association.host.full_name}</Caption>
      </div>
    </div>
    <div className={styles.collaboratorContainer}>
      {role.collaborators && role.collaborators.length > 0 &&
        role.collaborators[0].recipients && role.collaborators[0].recipients.length > 0 &&
        role.collaborators[0].recipients.map(r => {
          const cImgSrc = r.photo || DefaultPhoto;
          return <div title={r.full_name}><img className={styles.hostImage} src={cImgSrc}/></div>
        })
      }
    </div>
  </div>);
}

export const RoleActions = ({role, showShareButton = false, showText = true}) => {
  const roleContext = useContext(RoleListContext);
  const {
    refetchRoles: refetch
  } = roleContext

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [sharedUUID, setSharedUUID] = useState();
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const roleUuid = role.uuid;
  const roleName = role.name;
  const roleUrl = `${window.location.origin}${Variables.urls.projects.detail.replace("<uuid:uuid>", roleUuid)}`;
  const isOwner = role.association.host.uuid == Variables.uuids?.host;

  const companyName = role.association.location.company.name;
  const editRoleUrl = new URL(`${Variables.urls.hosts.my_projects}edit/${roleUuid}`, window.location.origin);
  const companyProfileIncomplete = companyName == "Unspecified company";
  const businessSkillsIncomplete = !(role?.tech_skills && role?.tech_skills.length > 0);

  const openPausePopup = () => {
    setShowPauseModal(true);
  }

  const openResumePopup = () => {
    setShowResumeModal(true);
  }

  const openClonePopup = () => {
    setShowCloneModal(true);
  };

  const openCollaborationPopup = () => {
    if (!Variables.user.partner.COLLABORATION) {
      EventTracking.send({
        content_object: {
          object_id: Variables.user.pk,
          object_type: "User",
        },
        event_type: interestEvent.COLLABORATION,
      });
      window.open(featureUrl.COLLABORATION);
    }
    else {
      setSharedUUID(roleUuid);
      setIsShareModalOpen(true);
    }
  }

  const first_message = encodeURIComponent("Are you ready to boost your career?");
  const message = encodeURIComponent(`Are you ready to boost your career? Join us at ${companyName} and apply to our new open role today: ${roleName} ${roleUrl}`);

  const actions = [
    {text: "View Listing", tooltip: "View role listing", icon: "eye-line", onClick: () => window.open(roleUrl)},
    {text: "Share", tooltip: "Share role listing", icon: "share-line", onClick: () => {}},
    role.status == "open"
    ? {text: "Pause", tooltip: "Pause marketing of role", icon: "pause-circle-line", onClick: () => openPausePopup(), color: "#C73E1D", disabled: !isOwner}
    : {text: "Resume", tooltip: "Resume marketing of role", icon: "play-circle-line", onClick: () => openResumePopup(), color: "#1A936F", disabled: !isOwner},
    {text: "Edit", tooltip: "Edit role", icon: "edit-line", onClick: () => window.open(editRoleUrl), disabled: !isOwner},
    {text: "Clone", tooltip: "Clone role", icon: "file-copy-line", onClick: () => openClonePopup(), disabled: !isOwner},
    {text: "Collaborator", tooltip: "Add collaborators to role", icon: "user-add-line", onClick: () => openCollaborationPopup(), disabled: !isOwner},
  ]

  return (<div className={styles.iconsContainer} onClick={(e) => {e.stopPropagation()}}>
    {actions.map((action, i) => {
      let wrapperComponent : React.ReactNode | null = null;
      if(action.text == "Share") {
        if(!showShareButton) {
          return <></>;
        }
        wrapperComponent = ({children}) => {
          return (
          <SocialMediaShareDropdown
            text={roleUrl}
            message={message}
            title={first_message}
            toggler={children}
          />
          );
        }
      }
      return <RoleAction
        id={`role-action-${action.text}-${roleUuid}`}
        key={`role-action-${action.text}-${roleUuid}`}
        text={action.text}
        tooltip={action.tooltip}
        icon={action.icon}
        onClick={action.onClick}
        showText={showText}
        disabled={action.disabled ?? false}
        color={action.color ?? ""}
        WrapperComponent={wrapperComponent}
      />
    })}
    <PauseProjectModal
      open={showPauseModal}
      onClose={() => setShowPauseModal(false)}
      projectUUID={roleUuid}
      roleName={roleName}
      refetch={refetch}
    />
    <ResumeProjectModal
      open={showResumeModal}
      onClose={() => setShowResumeModal(false)}
      projectUUID={roleUuid}
      roleName={roleName}
      businessSkillsIncomplete={businessSkillsIncomplete}
      companyProfileIncomplete={companyProfileIncomplete}
      refetch={refetch}
    />
    <ShareModal
      open={isShareModalOpen}
      onClose={() => setIsShareModalOpen(false)}
      UUID={sharedUUID}
      kind="project"
      share=""  //ToDo: Remove
    />
    <CloneProjectModal
      open={showCloneModal}
      onClose={() => setShowCloneModal(false)}
      projectUUID={roleUuid}
      roleName={roleName}
      refetch={refetch}
    />
  </div>);
}

const RoleAction = ({text, tooltip, icon, onClick, disabled=false, color="", className="", showText=true, WrapperComponent=null, ...otherProps}) => {
  let iconStyleProps = {"color": color};
  if(!color && !disabled) {
    iconStyleProps["color"] = "#000"
  }
  const actionButton = (<Button
    kind="tertiary"
    className={classNames(styles.actionButton, className)}
    onClick={(e) => { onClick(e)}}
    disabled={disabled}
    {...otherProps}
  >
    {!showText && <Tooltip iconComponent={<i style={iconStyleProps} className={`ri-${icon}`}></i>} place="bottom">{tooltip}</Tooltip>}
    {showText && <i style={iconStyleProps} className={`ri-${icon}`}></i>}
    {showText && <Caption>{text}</Caption>}
  </Button>);
  if(WrapperComponent) {
    return <WrapperComponent>
      {actionButton}
    </WrapperComponent>
  }
  return actionButton;
}

export default RoleDetails;
