import React, { useContext } from "react";
import styles from "./index.module.scss";
import { Heading, Body, SplitButton } from "workfinder-components";
import {
  CandidatePublicProfileContext
} from "./CandidatePublicProfileContextProvider";
import WikipediaTooltip from "util/wikipedia/WikipediaTooltip";

function Certification({ certificate }): JSX.Element {
  return (
    <div className={styles.boxContainer}>
      <div className="d-flex mb-2">
        <Heading size="h6" className="mb-0">
          <span className="mr-2">{certificate.name}</span>
        </Heading>
      </div>
      {certificate.issuing_organization ? (
        <Body size="medium" className="mb-2">
          {certificate.issuing_organization}
        </Body>
      ) : null}
      {certificate.issue_date ? (
        <Body size="small" className="mb-2">
          <i className="ri-calendar-event-fill"></i> {certificate.issue_date}
          {certificate.expiration_date && " - "}
          {certificate.expiration_date && certificate.expiration_date}
        </Body>
      ) : null}
      {certificate.credential_id ? (
        <div className="d-flex flex-column">
          {certificate.credential_id.split(",").map((id, index) => (
            <Body size="small" className="mb-2" key={index}>
              <i className="ri-pass-valid-line"></i>
              Credential ID: {id.trim()}
            </Body>
          ))}
        </div>
      ) : null}
      {certificate.url ? (
        <div className="d-flex flex-column">
          {certificate.url.split(",").map((url, index) => (
            <Body size="small" className="mb-2" key={index}>
              <i className="ri-link"></i>
              <a className={styles.link}
                onClick={
                  () => {
                    if (Variables.isAuthenticated) {
                      window.location.href = url;
                    }
                    else {
                      window.location.href = "/signup/recruiter";
                    }
                  }
                }
              >
                Credential URL
              </a>
            </Body>
          ))}
        </div>
      ) : null}

      <div className="d-flex flex-wrap">
        {certificate.techSkills && certificate.techSkills.length > 0 && certificate.techSkills.map((skill, index) => (
          <div key={index} className="d-inline-flex">
            <SplitButton
              tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug} />}
              secondaryComponent={skill.level && <span className="font-weight-normal">{skill.level}</span>}
            >
              <span className="text-nowrap">{skill.skill}</span>
            </SplitButton>
          </div>
        ))}
      </div>
    </div>
  );
}

const Certifications = (): JSX.Element => {
  const context = useContext(CandidatePublicProfileContext);
  const { certificates, isCertificateLoading } = context;

  if (isCertificateLoading) {
    return <></>;
  }

  if (!(certificates && certificates.length > 0)) {
    return <></>;
  }

  return (
    <>
      <Heading size="h4" className="mr-5 mb-3">
        Certificates
      </Heading>
      {certificates && certificates.length > 0 && certificates.map((certificate) => {
        return (
          <Certification key={certificate.uuid} certificate={certificate} />
        );
      })}
    </>
  );
};
export default Certifications;
