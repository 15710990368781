import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./loadingSpinner.module.css";


function LoadingSpinner({color, size, className}) {
  const circles = [...Array(12)].map((_, idx) => (
    <div key={idx} style={{background: `${color}`, width: size * 0.075, height: size * 0.075}}/>
  ));

  return (
    <>
      <div className={classNames(styles.lsdRing, className)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}

LoadingSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  color: "#1aa84c",
  size: 80,
};

export default LoadingSpinner;
