import React from "react";
import styles from "./tag.module.scss";
import classNames from "classnames";

const DOCUMENT_REQUEST_TAGS = {
  "req-li-pending": {
    className: "red",
    text: "🙏 Request: LinkedIn",
  },
  "req-po-pending": {
    className: "red",
    text: "🙏 Request: Portfolio",
  },
  "req-rw-pending": {
    className: "red",
    text: "🙏 Request: Right to work",
  },
  "req-li-shared": {
    className: "green",
    text: "🙏 Linkedin shared!",
  },
  "req-po-shared": {
    className: "green",
    text: "🙏 Portfolio shared!",
  },
  "req-rw-shared": {
    className: "green",
    text: "🙏 Right to work shared!",
  },
};

const TagMapping = {
  ...DOCUMENT_REQUEST_TAGS,
};

export interface TagProps {
  tag: string;
}

export default function Tag(props: TagProps): JSX.Element {
  const {tag} = props;
  const {className, text} = TagMapping[tag];
  return (
    <div className={classNames(styles.tag, styles[className])}>
      {text}
    </div>
  );
}
