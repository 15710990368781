import React, {useMemo, useState} from "react";
import {Button, Heading, Body, CheckboxGroup, Input} from "workfinder-components";
import styles from "./offerCard.module.scss";
import moment from "moment";
import classNames from "classnames";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";
import _ from "lodash";
import {CompanyHeaderProps, OfferTagProps, OfferType, OfferCardProps, PlacementOfferStatus, TriggerOptions, ActionButtonsProps} from "./types";
import {usePlacementOfferDeclinedReasonsQuery} from "services/queries";
import {useUpdatePlacementOfferMutation} from "services/mutations";
import {updateListCache} from "util/mutation";
import {useQueryClient} from "react-query";
import {chatModalInterface} from "components/shared/Modal/ChatModal";

const OfferTag = ({offer_status, offer_type}: OfferTagProps) => {
  if (offer_type === "normal") {
    if (offer_status) {
      switch (offer_status) {
      case PlacementOfferStatus.OFFERED:
        return (
          <div className={styles.offerPlacementTag}>
          🎉 You have received a job offer!
          </div>
        );
      case PlacementOfferStatus.ACCEPTED:
        return (
          <div className={styles.offerPlacementTag}>
          🎉 You have accepted the job offer!
          </div>
        );
      case PlacementOfferStatus.WITHDRAWN:
        return (
          <div className={styles.withdrawnPlacementTag}>
          ☔ You have declined the job offer
          </div>
        );
      default:
        return null;
      }
    }
  }
  if (offer_type === "extension") {
    switch (offer_status) {
    case PlacementOfferStatus.OFFERED:
      return (
        <div className={styles.roleExtensionTag}>
          ⏳Role extension offer received!
        </div>
      );
    case PlacementOfferStatus.ACCEPTED:
      return (
        <div className={styles.extensionAcceptedTag}>
          ⏳ Role extension accepted!
        </div>
      );
    case PlacementOfferStatus.WITHDRAWN:
      return (
        <div className={styles.roleExtensionTag}>
          ⏳Role extension declined
        </div>
      );
    default:
      return null;
    }
  }
};

const CompanyHeader = (props: CompanyHeaderProps) => {
  const {companyLogo, projectName, companyName, projectUrl, status, responseDate, createdAt} = props;
  const formattedResponseDate = responseDate ? moment(responseDate).format("Do MMMM, YYYY") : null;
  const formattedCreatedAt = createdAt ? moment(createdAt).format("Do MMMM, YYYY") : null;
  return (
    <div className="d-flex">
      <img className={styles.companyLogo} src={companyLogo || defaultCompanyLogo}/>
      <div className="d-flex flex-column">
        <a
          className={styles.projectLink}
          href={projectUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Heading
            size="h5"
          >
            {projectName}
          </Heading>
        </a>
        <span className={styles.companyName}>{companyName}</span>
        {status === PlacementOfferStatus.OFFERED ? (
          <span className={styles.declinedDate}>Received on {formattedCreatedAt}</span>
        ) : null}
        {status === PlacementOfferStatus.ACCEPTED ? (
          <span className={styles.acceptedDate}>Accepted on {formattedResponseDate}</span>
        ): null}
        {status === PlacementOfferStatus.WITHDRAWN ? (
          <span className={styles.declinedDate}>Declined on {formattedResponseDate}</span>
        ): null}
      </div>
    </div>
  );
};

const HiddenDetails = ({offer}:{offer: OfferType}) => {
  const [showDetails, setShowDetails] = useState(false);
  const {offer_notes: offerNotes, placement} = offer;
  const {host_name: hostName} = placement;
  const toggleShowDetails = () => {
    setShowDetails(() => !showDetails);
  };
  const workingDays = useMemo(() => {
    if (!offer.working_days) {
      return null;
    }
    const initials = offer.working_days.map(day => _.capitalize(day.slice(0, 2)));
    return initials.join(", ");
  }, [offer.working_days]);

  const workingPeriod = useMemo(() => {
    if (!offer.periods_of_day) {
      return null;
    }
    // TODO: handle timezones
    return offer.periods_of_day.map(period => period === "flexible" ? "Working periods are flexible" : _.capitalize(period) + "s");
  }, [offer.periods_of_day]);

  const locationRepresentation = useMemo(() => {
    const lines: string[] = [];

    if (offer.location_preference) {
      lines.push(_.capitalize(offer.location_preference));
    }

    if (!offer.location) {
      return lines;
    }

    const {address_city, address_country_name, address_postcode, address_region, address_street} = offer.location;

    if (address_street) {
      lines.push(address_street);
    }

    if (address_postcode && address_city) {
      lines.push(`${address_postcode}, ${address_city}`);
    }
    else if (address_postcode || address_city) {
      lines.push(address_postcode || address_city);
    }

    if (address_region) {
      lines.push(`${address_region}, ${address_country_name}`);
    }
    else {
      lines.push(address_country_name);
    }

    return lines;
  }, [offer.location_preference, offer.location]);

  return (
    <>
      <div
        className={`${styles.hiddenDetails} ${showDetails ? styles.showDetails : ""}`}
      >
        <div className="row">
          <div className="col-12 col-sm-6 px-2">
            <Heading size="h6">Role location</Heading>
            <Body size="small">
              {locationRepresentation.map(line => (
                <div key={line}>{line}</div>
              ))}
            </Body>
          </div>
          <div className="col-12 col-sm-6 px-2">
            {workingDays ? (
              <>
                <Heading size="h6">Working days per week</Heading>
                <Body size="small">
                  {workingDays}
                </Body>
              </>
            ) : null}
            {workingPeriod ? (
              <>
                <Heading size="h6">Working period</Heading>
                <Body size="small">
                  {workingPeriod.map(period => <div className="mb-2" key={period}>{period}</div>)}
                </Body>
              </>
            ) : null}
          </div>
          <div className="col-12">
            <span style={{fontSize: 12}}><i className="ri-mail-fill mr-1"/>Message from {hostName}</span>
            <Body>
              {offerNotes}
            </Body>
          </div>
        </div>
      </div>
      <div className={styles.expandToggle} onClick={toggleShowDetails}>
        {showDetails ? "Collapse details" : "Expand details"} <i className={showDetails ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
      </div>
    </>
  );
};

const ActionButtons = ({offer, trigger, setTrigger, updatePlacementOfferMutation}: ActionButtonsProps) => {
  const handleAccept = () => {
    updatePlacementOfferMutation.mutateAsync({
      status: PlacementOfferStatus.ACCEPTED,
      response_date: new Date(),
    });
  };
  const {offer_type} = offer;
  switch (trigger) {
  case TriggerOptions.WITHDRAW:
    return null;
  default:
    break;
  }
  switch (offer.status) {
  case PlacementOfferStatus.OFFERED:
    return (
      <>
        <hr/>
        <div className={classNames("d-flex justify-content-between", styles.actionButtons)}>
          <Button
            className="flex-grow-1 mb-0"
            kind="secondary"
            onClick={() => setTrigger(TriggerOptions.WITHDRAW)}
          >
          Decline {offer_type === "normal" ? "offer" : "extension"}
          </Button>
          <Button
            className="flex-grow-1 mb-0"
            onClick={handleAccept}
          >
          Accept {offer_type === "normal" ? "offer" : "extension"}
          </Button>
        </div>
      </>
    );
  case PlacementOfferStatus.ACCEPTED:
    return (
      <div className={styles.actionButtons}>
        <Button
          kind="secondary"
          className="flex-grow-1 mb-0 w-100"
          onClick={() => {
            chatModalInterface.dispatch({
              open: true,
              correspondentUUID: offer.placement.host_uuid,
              correspondentName: offer.placement.host_name,
              placementUUID: offer.placement.uuid,
            });
          }}
        >
          <i className="ri-message-3-line mr-1"></i>Message
        </Button>
      </div>
    );
  default:
    return null;
  }
};

const RoleAccepted = ({offer_type, hostName}) => {
  return (
    <div className="d-flex flex-column mb-3" style={{lineHeight: "120%"}}>
      <div className="text-center mb-3" style={{fontSize: "100px", lineHeight: "120px"}}>🎉</div>
      <div className="text-center mb-3"><strong>Congratulations!</strong></div>
      <div>
        {offer_type === "normal" ?
          `We have informed ${hostName} that you have accepted their offer and we have updated your profile with your new experience.` :
          `We have informed ${hostName} that you have accepted their extension offer and we have updated your profile to extend your experience.`}
        <br/><br/>
        <strong>What&apos;s next?</strong><br/><br/>
        {hostName} will reach out to you regarding next steps. Feel free to message them in the meantime:
      </div>
    </div>
  );
};

const RoleWithdrawn = ({offer, trigger, setTrigger, updatePlacementOfferMutation}) => {
  const {offer_type} = offer;
  const {data: options, isLoading} = usePlacementOfferDeclinedReasonsQuery({reasons: offer_type.slice(0, 3)});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [responseText, setResponseText] = useState("");
  const [responseOther, setResponseOther] = useState("");
  const hostFirstName = offer.placement.host_name;
  // TODO: get the actual first name. Right now we are using the full name
  if (isLoading) {
    return null;
  }
  const handleDecline = () => {
    updatePlacementOfferMutation.mutateAsync({
      decline_offer_response: selectedOptions,
      decline_offer_response_other: selectedOptions.includes("other") ? responseOther : null,
      decline_message: responseText,
      status: PlacementOfferStatus.WITHDRAWN,
      response_date: new Date(),
    });
  };

  if (trigger === TriggerOptions.WITHDRAW) {
    return (
      <>
        <CheckboxGroup
          checkedOptions={selectedOptions}
          options={options}
          setCheckedOptions={setSelectedOptions}
          className={classNames("text-nowrap", styles.checkboxGroup)}
          name="decline-reason"
        />
        {selectedOptions.includes("other") ? (
          <div className="ml-4">
            <Input
              placeholder="Other"
              onChange={setResponseOther}
            />
          </div>
        ): null}
        Add a message for {hostFirstName}
        <Input
          className={styles.declinedReasonText}
          placeholder="Add message explaining your reasoning here"
          onChange={setResponseText}
          long
        />
        <Button
          className="w-100"
          onClick={handleDecline}
          disabled={selectedOptions.length === 0}
        >
          Decline offer
        </Button>
        <Button
          className="w-100"
          kind="tertiary"
          onClick={() => setTrigger(null)}
        >
          <i className="ri-arrow-left-line mr-1"></i>Go back
        </Button>
      </>
    );
  }
  return (
    <>
      <div>{
        offer_type === "normal" ?
          `We have shared with ${hostFirstName} your reasons for declining this role offer.` :
          `We have shared with ${hostFirstName} that you will not be extending your time with them.`}
      </div>
    </>
  );
};

const PlacementOfferBody = ({offer, trigger, setTrigger, updatePlacementOfferMutation}) => {
  if (trigger === TriggerOptions.WITHDRAW) {
    return <RoleWithdrawn
      offer={offer}
      updatePlacementOfferMutation={updatePlacementOfferMutation}
      trigger={trigger}
      setTrigger={setTrigger}
    />;
  }
  switch (offer.status) {
  case PlacementOfferStatus.OFFERED:
    return (
      <>
        <OfferPreview offer={offer}/>
        <HiddenDetails offer={offer}/>
      </>
    );
  case PlacementOfferStatus.ACCEPTED:
    return <RoleAccepted offer_type={offer.offer_type} hostName={offer.placement.host_name}/>;
  case PlacementOfferStatus.WITHDRAWN:
    return <RoleWithdrawn
      offer={offer}
      updatePlacementOfferMutation={updatePlacementOfferMutation}
      trigger={trigger}
      setTrigger={setTrigger}
    />;
  default:
    return null;
  }
};

const OfferPreview = ({offer}) => {
  const {working_duration_hours_per_day: workingHours, salary, currency, rate, placement, offer_type} = offer;
  const {associated_project: project} = placement;
  const compensationDetails = useMemo(() => {
    if (!rate || !currency || !rate) {
      return null; // we don't have enough information or might be voluntary
    }
    return `${currency} ${salary} ${rate}`;
  }, [salary, currency, rate]);
  return (
    <div>
      <div className="row">
        {offer_type === "normal" ? (
          <>
            <div className="col-12 col-sm-6 px-2">
              <div className="p-1">
                <Heading size="h6">You&apos;ll start on</Heading>
                <Body size="small">
                  {moment(offer.start_date).format("ddd, Do MMMM, YYYY")}
                </Body>
              </div>
            </div>
            <div className="col-12 col-sm-6 px-2">
              <div className="p-1">
                <Heading size="h6">You&apos;ll finish on</Heading>
                <Body size="small">
                  {moment(offer.end_date).format("ddd, Do MMMM, YYYY")}
                </Body>
              </div>
            </div>
          </>
        ) : null}
        {offer_type === "extension" ? (
          <>
            <div className="col-12 col-sm-6 px-2">
              <div className="p-1">
                <Heading size="h6">Original end date</Heading>
                <Body size="small">
                  {moment(offer.end_date).format("ddd, Do MMMM, YYYY")}
                </Body>
              </div>
            </div>
            <div className="col-12 col-sm-6 px-2">
              <div className={classNames("p-1", styles.newEndDate)}>
                <Heading size="h6">New end date</Heading>
                <Body size="small">
                  {moment(placement.end_date).format("ddd, Do MMMM, YYYY")}
                </Body>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 px-2">
          <div className="p-1">
            <Heading size="h6">Compensation</Heading>
            <Body size="small">
              {offer.is_paid ? (compensationDetails || "Paid") : "Voluntary"}
            </Body>
          </div>
        </div>
        {workingHours ? (
          <div className="col-12 col-sm-6 px-2">
            <div className="p-1">
              <Heading size="h6">Working hours per week</Heading>
              <Body size="small">
                {workingHours} hours
              </Body>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const OfferCard = ({offer}: OfferCardProps): JSX.Element => {
  const {uuid} = offer;
  const queryClient = useQueryClient();
  const updatePlacementOfferMutation = useUpdatePlacementOfferMutation(
    uuid,
    {
      onSuccess: (data) => {
        updateListCache(
          queryClient,
          "placement-offers",
          {uuid, ...data},
        );
        setTrigger();
      },
    },
  );
  const [trigger, setTrigger] = useState<TriggerOptions>();
  const {placement} = offer;
  const {
    company_name: companyName,
    associated_project: project,
    associated_project_name: role,
    company_logo_url: companyLogo,
  } = placement;
  const projectUrl = Variables.urls.projects.detail.replace("<uuid:uuid>", project);

  const commonActionProps = {
    offer,
    trigger,
    setTrigger,
    updatePlacementOfferMutation,
  };

  return (
    <div className={styles.card}>
      <OfferTag offer_status={offer.status} offer_type={offer.offer_type}/>
      <CompanyHeader
        companyLogo={companyLogo || defaultCompanyLogo}
        projectName={role}
        companyName={companyName}
        projectUrl={projectUrl}
        status={offer.status}
        responseDate={offer.response_date}
        createdAt={offer.created_at}
      />

      <hr/>

      <PlacementOfferBody {...commonActionProps}/>

      <ActionButtons {...commonActionProps}/>
    </div>
  );
};

export default OfferCard;
