import _ from "lodash";

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function convertNewlineToSlashN(str) {
  if (!str) {
    return "";
  }
  return str.replace(/(?:\r\n|\r|\n)/g, "\n");
}

export function countWords(str) {
  return str && str !== "" ? str.trim().split(/\s+/).length : 0;
}

export function toSentenceCase(str) {
  return str.replace(/^[a-z]|[A-Z]/g, function (v, i) {
    return i === 0 ? v.toUpperCase() : " " + v.toLowerCase();
  });
}

export function interpolate(str, params) {
  const keys = Object.keys(params);
  const values = Object.values(params);
  return new Function(...keys, `return \`${str}\`;`)(...values);
}

/**
 * Format the salary to 2 decimal places, used when the salary input is
 * blurred.
 */
export function formatSalary(salary) {
  if (salary) {
    return parseFloat(salary).toFixed(2);
  }
  return salary;
}

export function snakeToCamelCase(s) {
  return s
    .toLowerCase()
    .split("_")
    .map((x, i) => i ? _.capitalize(x) : x)
    .join("");
}

export const getAddressParts = (text) => {
  let parts = text.split(',').map(t => t.trim());
  if(parts.length == 0) return {addressLine1: "", city: "", county: "", postalCode: ""};
  else if(parts.length == 1)
    return {addressLine1: parts[0], city: "", county: "", postalCode: ""};
  else if(parts.length == 2)
    return {addressLine1: parts[0], city: "", county: parts[1], postalCode: ""};
  else if(parts.length == 3)
    return {addressLine1: parts[0], city: "", county: parts[1], postalCode: parts[2]};
  else
    return {addressLine1: parts[0], city: parts.slice(1, parts.length - 2).join(), county: parts[parts.length - 2], postalCode: parts[parts.length - 1]};
}

export const formatAddressToText = (obj) => {
  let text = "";
  if(obj) {
    if(obj.city)
      text = obj.city + ", ";
    if(obj.state)
      text = text + obj.state + ", ";
    text = text + obj.country;
  }
  return text;
}

export const formatLink = (htmlText, linkClassName = "") => {
  const anchorStartRegex = /<a(?:\s+.*?)*>/g;
  const anchorEndRegex = /<\/a>/g;
  const urlRegex = /(((https?:\/\/)?(www.)?[a-zA-Z0-9@:%._\\+~#?&/=]{2,256}\.[a-z]{2,6}\b)|(http:\/\/localhost(:\d+)))([-a-zA-Z0-9@:%_\\+~#?&\/=]*)/g;
  const anchorWholeRegex = /<a(?:\s+.*?)*>(((https?:\/\/)?(www.)?[a-zA-Z0-9@:%._\\+~#?&/=]{2,256}\.[a-z]{2,6}\b)|(http:\/\/localhost(:\d+)))([-a-zA-Z0-9@:%_\\+~#?&\/=]*)<\/a>/g;

  const removeAnchorTag = (partString) => {
    return partString.replace(anchorStartRegex, "").replace(anchorEndRegex, "");
  }

  const addAnchorTag = (partString) => {
    const partStringUrl = partString.toLowerCase().startsWith("http") ? partString : `http://${partString}`;
    return `<a
      href=${partStringUrl}
      className=${linkClassName}
      target="_blank"
      rel="noopener noreferrer"
    >${partString}</a>`;
  }

  //First remove any anchor tag if already there in the text
  const htmlTextWithoutAnchorTags = htmlText.replace(anchorWholeRegex, ele => {
    return removeAnchorTag(ele);
  });

  //Next enclose all urls within anchor tag
  return htmlTextWithoutAnchorTags.replace(urlRegex, (ele, p1, p2, p3, offset, str, groups) => {
    return addAnchorTag(ele);
  });
}

export const trimHtmlTags = (htmlText) => {
  let div = document.createElement("div");
  div.innerHTML = htmlText;
  let text = div.textContent || div.innerText || "";
  div.remove();
  return text;
}
