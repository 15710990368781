import React, {createContext} from "react";
import {DISABLE_REFETCH_OPTIONS, usePlacementsQuery, useSkillsReferralRequestQuery} from "services/queries";

export const CandidateDashboardContext = createContext();

function CandidateDashboardContextProvider({children}) {

  const {data: placements, isLoading: isPlacementLoading} = usePlacementsQuery("CAN", {"expand-project": 1, ordering: "-created_at", limit: 18}, DISABLE_REFETCH_OPTIONS);
  const skillReferralRequestsQuery = useSkillsReferralRequestQuery({mode: "receiver"});
  const {data: skillReferralRequests} = skillReferralRequestsQuery;
  const isLoading = isPlacementLoading;

  return (
    <CandidateDashboardContext.Provider
      value={{
        placements,
        skillReferralRequests,
        isLoading,
      }}
    >
      {children}
    </CandidateDashboardContext.Provider>
  );
}

export {CandidateDashboardContextProvider};

