import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import {mergeClassNames} from "styles/utils";

import baseStyles from "./iconbutton.module.scss";


function IconButtonGroup(props) {
  const {
    textBelow,
    textStyles,
    buttons,
  } = props;

  return (
    <div className={baseStyles.buttonGroup}>
      <div className={baseStyles.iconButtonGroupContainer}>
        { buttons.map(buttonProps => <IconButton {...buttonProps} /> )}
      </div>
      <span style={textStyles}>{textBelow}</span>
    </div>
  );
}

IconButtonGroup.propTypes = {
  textBelow: PropTypes.string,
  textStyles: PropTypes.object,
  buttons: PropTypes.arrayOf(IconButton)
}

export { IconButtonGroup };

function IconButton(props) {
  const {
    children,
    className,
    disabled,
    extraStyles,
    icon,
    position="left",
    theme,
    type,
    materialIcon,
    outlined=false,
    isVertical=false,
    ...restProps
  } = props;
  const styles = mergeClassNames(baseStyles, theme);
  const buttonStyles = classNames(
    styles.container,
    className,
    {
      [styles.vertical]: isVertical,
      [styles.rightAlign]: position === "right",
      [styles.disabled]: disabled,
      [`btn-${type}`]: type,
    },
  );

  return (
    <button
      className={buttonStyles}
      style={extraStyles}
      {...restProps}
    >
      {icon && <i style = {extraStyles?.icon} className={classNames(icon, styles.icon)}></i>}
      {materialIcon && <span className={outlined?"material-icons-outlined":"material-icons"} style={extraStyles?.materialIcon}> {materialIcon}</span>}
      {children}
    </button>
  );
}

IconButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  extraStyles: PropTypes.object,
  icon: PropTypes.string,
  materialIcon: PropTypes.string,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  position: PropTypes.oneOf(["left", "right"]),
  theme: PropTypes.shape({
    container: PropTypes.string,
    icon: PropTypes.string,
  }),
  type: PropTypes.any,
  isVertical: PropTypes.bool,
};

export default IconButton;
