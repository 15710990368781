import React, {useState, useMemo, useContext, useEffect} from "react";
import classNames from "classnames";
import { CandidateProfileContext } from "../candidate-profile/CandidateProfileContextProvider";

import styles from "./ProgressBar.module.scss";
import {Heading, Subtitle} from "workfinder-components";
import bee from "/static/workfinder/images/bee.png";
import duck from "/static/workfinder/images/duck.png";
import unicorn from "/static/workfinder/images/unicorn.png";
import robot_arm from "/static/workfinder/images/robot_arm.png";
import superhero from "/static/workfinder/images/superhero.png";
import {DISABLE_REFETCH_OPTIONS, useCandidateScoreQuery} from "services/queries";
import useStoredState from "hooks/useStoredState";
import UploadCV from "../candidate-profile/uploadCV";

const statuses = ["Newbee", "Getting Started", "Intermediate", "Advanced", "Superhero"];
const images = [bee, duck, unicorn, robot_arm, superhero];

const ProgressBar = ({showUploadCVButton = false, minimal=false}) => {
  const context = useContext(CandidateProfileContext);
  const {
    updateProgressBar,
  } = context;


  const [showCard, setShowCard] = useState(true);

  const {data: candidate, isLoading, refetch} = useCandidateScoreQuery(DISABLE_REFETCH_OPTIONS);

  const progressPercent = isLoading ? -1 : candidate?.profile_status_perc;

  const [storedStateHideCard, setStoredState] = useStoredState(
    false, `hide-gamification-card-${Variables.uuids.candidate}`, Variables.uuids.user, {storage: localStorage},
  );

  const currentStatus = useMemo(() => {
    if (!candidate?.profile_status_perc == undefined) {
      return null;
    }
    return progressPercent >= 0 && statuses[Number.parseInt(progressPercent/(100/(statuses.length-1)))];
  }, [candidate, progressPercent]);

  const index = statuses.indexOf(currentStatus);
  useEffect(() => {
    refetch();
  }, [ updateProgressBar]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={classNames( styles.container, (!showCard || storedStateHideCard) && styles.hide)}>
      {(index === 0 || index > 0) && (
        <div className={classNames("d-flex justify-content-between", styles.heading, minimal ? "mb-4" : "")}>
          <>
            <Heading size="h6" className={classNames(styles.nonBold, "mb-1")}>
              Profile Status:&nbsp;
              <img src={images[index]} alt={currentStatus} height="20"/>
              &nbsp;
              <b>{currentStatus}</b>
            </Heading>
          </>
          {minimal ? (
            <a
              className={styles.profileLink}
              href={Variables.urls.candidates.member_profile}
              target="_blank"
              rel="noreferrer"
            >
              <span>
                {progressPercent === 100 ? "Improve my profile" : "Complete my profile"}
              </span>
              <i className="ri-external-link-line ml-1 text-decoration-none"></i>
            </a>
          ) : (
            progressPercent === 100 && <a onClick={() => {
              setShowCard(false);
              setStoredState(true);
            }} style={{color: "#9EA7B8"}}
            >
              <span className="material-icons">close</span>
            </a>
          )}
        </div>
      )}
      {minimal ? null : (
        <Subtitle className={styles.subText}>
          {progressPercent !== 100 ? "Complete your profile to increase your visibility to recruiters and to receive more personalised recommendations.":"Congrats your profile is complete! Stand out even more by adding more skills and sections to your profile."}
        </Subtitle>
      )}
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{background: `linear-gradient(45deg, #006FF1 0%, #CA78EE ${progressPercent === 0 ? 0 : 47.92 / progressPercent}%, #FAC94C ${progressPercent}%, #E2E7F0 ${progressPercent}%, #E2E7F0 100%)`, borderRadius: "10px"}}
        >
          <span className={classNames(styles.icon, progressPercent>= 0 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={bee} alt="Newbee" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Newbee</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 25 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={duck} alt="Getting started" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Getting started</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 50 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={unicorn} alt="Intermediate" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Intermediate</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 75 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={robot_arm} alt="Advanced" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Advanced</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 100 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={superhero} alt="Superhero"  /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Superhero</div>
          </span>
        </div>
      </div>
      <div className={styles.buttons}>
        {(window.location.pathname === Variables.urls.candidates.dashboard && progressPercent !== 100) ? (
          <a className={styles.button} href={Variables.urls.candidates.member_profile}>
            <Subtitle className={styles.rowButton}>Complete profile</Subtitle>
          </a>
        ) : null}
        {showUploadCVButton && <UploadCV className="mt-3"/>}
      </div>
    </div>
  );
};

export default ProgressBar;
