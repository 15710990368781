/*
 * The main content of the slate. Applies the correct styling to make the
 * content scroll when the slate is too small its content while keeping the
 * title and buttons working properly.
 */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./content.module.scss";


function Content({children, className, ...props}) {
  return (
    <div className={classNames(styles.content, className)} {...props}>
      { children }
    </div>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Content;
