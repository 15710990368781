import React, { useState } from "react";
import Modal from "components/shared/Modal/Modal";
import styles from "styles/modals/flat.module.scss";
import { Button, Heading } from "workfinder-components";
import PulseLoader from "react-spinners/PulseLoader";
import CongratulationsModal from "components/bespoke-project/pages/CongratulationsModal";
import {useProjectMutation} from "services/mutations";

const ResumeProjectModal = ({projectUUID, roleName="", companyProfileIncomplete, businessSkillsIncomplete, onClose, open, refetch}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
  const projectMutation = useProjectMutation(projectUUID);

  const markProjectLive = () => {
    setIsLoading(true);
    projectMutation.mutateAsync({
      status: "open",
    })
    .then(project => {
      setIsLoading(false);
      onClose();
      setShowCongratulationsModal(true);
      refetch();
    })
    .catch(errors => {
      setIsLoading(false);
    });
  };

  return (<>
    <Modal
      id="resume-role-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={styles.mobileBottom}
    >
      <div className={styles.header}>
        <Heading size="h4" className="align-items-start">Your role is paused, ready to resume marketing it?</Heading>
      </div>
      <div className={styles.content}>
        <p className="text-left">Because your role is paused:
          <ul className="pl-3">
            <li>Your role is not marketed to candidates who match your role requirements at the moment.</li>
            <li>Candidates can still apply to your role.</li>
          </ul>
          Resume marketing your role to fill it faster:
        </p>
        <p>
          <Button
            className="mt-3 w-100"
            kind="primary"
            onClick={markProjectLive}
            type="button"
          >
            {isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Resume marketing"}
          </Button>
        </p>
      </div>
    </Modal>
    <CongratulationsModal
      hasCollaboration={false}
      companyProfileIncomplete={companyProfileIncomplete}
      businessSkillsIncomplete={businessSkillsIncomplete}
      open={showCongratulationsModal}
      onClose={() => setShowCongratulationsModal(false)}
      projectUUID={projectUUID}
      isReOpened={true}
      projectName={roleName}
    />
  </>);
}

export default ResumeProjectModal;
