import React from "react";
import {Heading, Button, Subtitle} from "workfinder-components";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss"
import classNames from "classnames";

export default function SelectAccountType() {
  const history = useHistory();
  const redirect = (path) => {
    history.push(`/${path}`)
  }

  return (
    <>
      <Heading size="h2" className={classNames(styles.header, "text-center")}><small>👋</small> First, select your account type</Heading>
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <Button size="large" kind="secondary" className={classNames(styles.button, styles.big)} onClick={() => redirect("member")}>Member Account</Button>
          <Subtitle size="large" className="mx-1 mx-md-5 text-center">I am an individual looking for jobs and upskilling services</Subtitle>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <Button size="large" kind="secondary" className={classNames(styles.button, styles.big)} onClick={() => redirect("recruiter")}>Recruiter Account</Button>
          <Subtitle size="large" className="mx-1 mx-md-4 text-center">I am an employee of a company looking to recruit people onto my team</Subtitle>
        </div>
      </div>
      <Subtitle size="small" className={classNames(styles.lhMd, "text-center my-4")}>Already have an account? <a href="/login" className={styles.link}>Log in</a></Subtitle>
    </>
  )
}
