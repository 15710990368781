import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper-bundle.css";
import RecommendationCard, {OtherRecommendationCard} from "./RecommendationCard";
import DocumentRequestCard from "./DocumentRequestCard";
import OfferCard from "./OfferCard";
import OfferInterviewCard from "./OfferInterviewCard";
import styles from "./projectList.module.scss";
import SwiperCore, {Navigation} from "swiper/core";
import * as constants from "util/documentRequest/constants";
import SkillReferralRequestCard from "./SkillReferralRequest/SkillReferralRequestCard";
import InterviewFeedbackCard from "./InterviewFeedbackCard";

SwiperCore.use([Navigation]);

export const RecommendationList = ({recommendations, isFeatured, hasPlacements, clickedOnCard, setClickedOnCard}) => {
  const [id] = useState(_.uniqueId());
  return (
    <div
      id="projects"
      className={classNames(
        isFeatured ? styles.recommendationsContainerFeature : styles.recommendationsContainer,
      )}
    >
      <div className={styles.swiperContainer}>
        <div id={`rl-swiper-button-prev${id}`} className="swiper-button-prev"></div>
        <Swiper
          slidesPerView="auto"
          slidesPerGroup={1}
          navigation={{
            nextEl: `#rl-swiper-button-next${id}`,
            prevEl: `#rl-swiper-button-prev${id}`,
          }}
          breakpoints={{
            768: {slidesPerGroup: 2},
            1200: {slidesPerGroup: 3},
          }}
          spaceBetween={30}
          key="recommendationlist"
        >
          {recommendations.map((project) => {
            const type = project.recommendation_type || project.type;
            const pk = project[type]?.pk || project.id;
            return project &&
              <SwiperSlide key={project.uuid || `${type}_${pk}`}>
                {["masterclass", "mentor", "course"].includes(type) ?
                  <OtherRecommendationCard
                    project={project}
                    className={styles.card}
                    isFeatured={isFeatured}
                    setClickedOnCard={setClickedOnCard}
                    clickedOnCard={clickedOnCard}
                  /> :
                  <RecommendationCard
                    project={project}
                    className={styles.card}
                    hasPlacements={hasPlacements}
                  />
                }
              </SwiperSlide>;
          })}
        </Swiper>
        <div id={`rl-swiper-button-next${id}`} className="swiper-button-next"></div>
      </div>
    </div>
  );
};

export const GetOfferInterviewListNodes = ({interviews, onAccept, onDecline,
  onNewTimeRequest, offers=[], updateOffer, documentRequests=[], skillReferralRequests=[],
  setIsSkillReferralRequestModalOpen=(() => {}), submittedReferralUuid,
  closeCard={}, setCloseCard=(() => {}), completedInterviews=[]}) => {
 
  const DocumentRequests = () => {
    return documentRequests.map(documentRequest => {
      if ([constants.documentType.LINKEDIN, constants.documentType.TECH_SKILL_LEVEL].includes(documentRequest.doc_type)) {
        // let's just not display linkedin request anymore
        // TODO: Card for Tech skill level
        return null;
      }
      return {
        id: `document_request_${documentRequest.id}`,
        node: <DocumentRequestCard
          documentRequest={documentRequest}
          setCloseCard={() => setCloseCard({...closeCard, [documentRequest.id]: true})}
        />,
      };
    });
  };

  const skillReferralNodes = () => {
    return skillReferralRequests.map((skillReferralRequest) => ({
      id: skillReferralRequest.uuid,
      node: (
        <SkillReferralRequestCard
          skillReferralRequest={skillReferralRequest}
          setIsSkillReferralRequestModalOpen={setIsSkillReferralRequestModalOpen}
          submittedReferralUuid={submittedReferralUuid}
        />
      ),
    }));
  };

  const nodes =  [...interviews.map(interview => {
    return {
      id: interview.id,
      node: <OfferInterviewCard
        interview={interview}
        className={styles.card}
        onAccept={onAccept}
        onDecline={() => onDecline(interview)}
        onNewTimeRequest={candidateMessage => onNewTimeRequest(interview.uuid, candidateMessage)}
      />,
    };
  }),
  ...offers.map(offer => {
    return {
      id: offer.uuid,
      node: <OfferCard
        className={styles.card}
        offer={offer}
        updateOffer={updateOffer}
      />,
    };
  }),
  ...completedInterviews.map(interview=> {
    return {
      id: interview.id,
      node:<InterviewFeedbackCard interview={interview} />
    }
  }),
  ...DocumentRequests(),
  ...skillReferralNodes(),
  ];

  return nodes;
};

export const LiveProjectList = ({recommendations}) => {
  return (
    <div
      id="projects"
      className={styles.liveProjectList}
    >
      {
        recommendations.map((project, i) =>
          project && <RecommendationCard key={i} project={project} className={styles.card} hasPlacements={false}/>,
        )
      }
    </div>
  );
};

RecommendationList.propTypes = {
  isFeatured: PropTypes.bool,
  recommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
