import React from "react";
import Pencil from "/static/workfinder/images/icons/pencil.svg";

import styles from "./bespokeProjectCard.module.scss";

export default function BespokeProjectCard() {
  return (
    <a className={styles.container} href={Variables.urls.projects.bespoke}>
      <span className={styles.link} >
        <img src={Pencil} style={{marginRight: "0.6875rem"}} alt="Pencil" />Create Your Own Project
      </span>
    </a>
  );
}
