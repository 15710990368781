import React, {useContext} from "react";
import {
  CandidatePublicProfileContext,
} from "./CandidatePublicProfileContextProvider";
import styles from "./index.module.scss";
import {Button} from "workfinder-components";
import DefaultPhoto from "/static/workfinder/images/generic-photo.png";


const About = ({hidehire=false, showInitials=false, showphoto=true}):JSX.Element => {
  const context = useContext(CandidatePublicProfileContext);
  const {
    userData,
    candidateData,
    isUserLoading,
    isCandidateLoading,
  } = context;


  function createInitials(fullName) {
    const words = fullName.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join(".");
  }

  if (isCandidateLoading || isUserLoading) {
    return <></>;
  }
  return (
    <div className={styles.profileCard}>
      <div className={styles.profileHeader}>
        {candidateData && <div className="position-relative">
          <img
            className={styles.avatar}
            src={candidateData.photo || DefaultPhoto}
            alt="User Avatar"
          />
          {!showphoto && <div className={styles.blur}/>}
        </div>}
        <div className={styles.nameLocation}>
          <div className={styles.name}>
            {(userData && userData.full_name) && <>{showInitials ? createInitials(userData.full_name):userData.full_name}</>}.
          </div>
          {candidateData && candidateData.current_location && <div className={styles.locationMain}>
            {candidateData.current_location.address_city}
            {", "}
            {candidateData.current_location.address_region}
            {", "}
            {candidateData.current_location.address_country_name}
          </div>}
        </div>
      </div>
      <div className={styles.skills}>
        {candidateData && candidateData.wef_skills.slice(0, 3).map((skill, index) => <div key={index} className={styles.skillPill}>{skill}</div>)}
      </div>
      {/* <div className={styles.description}>{userData && userData.summary}</div> */}
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: userData.summary }}></div>
      {(Variables.isAuthenticated === false && !hidehire) && <Button className={styles.hireButton} onClick={() => (window.location.href = "/signup/recruiter")}>
        Hire {createInitials(userData.full_name)}
      </Button>}
    </div>
  );
};

export default About;

