import React, {useState, useEffect, useMemo, useContext} from "react";
import _ from "lodash";
import {GetOfferInterviewListNodes} from "./ProjectList";
import {status as interviewStatus} from "util/interview/constants";
import {useAlerts} from "components/shared/AlertsProvider";
import {DISABLE_REFETCH_OPTIONS, useCandidateInterviewDatesQuery, useCompletedInterviewsQuery, usePlacementOffersQuery} from "services/queries";
import {useInterviewMutation, useInterviewDateMutation, usePlacementMutation} from "services/mutations";
import {removeFromListCache, updateListCache} from "util/mutation";
import {status as placementStatus} from "util/placement/constants";
import api from "services/v3";
import {useQueryClient} from "react-query";
import {CandidateDashboardContext} from "apps/CandidateDashboardContext";
import SwiperList from "./SwiperList";
import bell from "base64/bell";
import moment from "moment";

const OFFER_STATUSES = [placementStatus.OFFERED, placementStatus.ACCEPTED, placementStatus.CANCELLED];

export default function Actions({hideDetails, setIsSkillReferralRequestModalOpen, submittedReferralUuid}) {

  const [addAlert] = useAlerts();
  const [sortBy, __] = useState("1");
  const [declined, setDeclined] = useState(new Set());
  const [timeRequested, setTimeRequested] = useState(new Set());
  const queryClient = useQueryClient();
  const [documentRequests, setDocumentRequests] = useState([]);
  const [closeCard, setCloseCard] = useState({});

  const {placements, isLoading: arePlacementsLoading, skillReferralRequests} = useContext(CandidateDashboardContext);
  const {data: offers, isLoading: isOffersLoading} = usePlacementOffersQuery({ordering: "-created_at", status: OFFER_STATUSES}, {...DISABLE_REFETCH_OPTIONS, placeholderData: []});
  const {data: rawInterviews} = useCandidateInterviewDatesQuery();
  const {data: completedInterviews, isLoading: iscompletedInterviewsLoading} = useCompletedInterviewsQuery(DISABLE_REFETCH_OPTIONS);

  useEffect(() => {
    api.getDocumentRequestAsCandidate().then(r => setDocumentRequests(r.results));
  }, []);

  const interviews = (rawInterviews ?? []).map(interview => ({
    ...interview,
    declined: declined.has(interview.id),
    timeRequested: timeRequested.has(interview.id),
  }));
  const offerInterviewList = interviews.filter(isOfferedInterview);
  const hasInterviews = interviews?.length > 0;
  const sortOptions = [
    {
      title: "Application Date",
      value: "1",
      sort: offers => offers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    },
    {
      title: "Status",
      value: "2",
      sort: projects => {
        const order = [interviewStatus.OFFERED, interviewStatus.ACCEPTED, interviewStatus.CANCELLED];
        return projects.sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status));
      },
    },
  ];
  const sortedOffers = useMemo(
    () => {
      return offers && sortOptions.find(x => x.value === sortBy).sort(offers);
    },
    [offers, sortBy],
  );

  function isOfferedInterview(interview) {
    return interview.status == interviewStatus.INTERVIEW_OFFERED;
  }

  async function handleInterviewAccept(dateId) {
    await api.updateUserDetectedFields({timezone: moment.tz.guess()});
    await interviewDateMutation.mutateAsync({id: dateId, data: {status: "selected"}});
  }

  function handleInterviewDecline(interview) {
    const {uuid} = interview;
    const id = interviews.find(interview => interview.uuid == uuid).id;
    setDeclined(declined => new Set([...declined, id]));
    interviewMutation.mutate({uuid, data: {status: interviewStatus.INTERVIEW_DECLINE}});
  }

  function handleInterviewNewTimeRequest(uuid, candidateMessage) {
    const id = interviews.find(interview => interview.uuid == uuid).id;
    setTimeRequested(timeRequested => new Set([...timeRequested, id]));
    interviewMutation.mutate({
      uuid,
      data: {status: interviewStatus.INTERVIEW_TIME_REQUESTED, candidate_message: candidateMessage},
    });
  }


  const interviewMutation = useInterviewMutation({
    updateCache: false,
    onSuccess(data) {
      setTimeout(
        () => {
          if (data.status === interviewStatus.INTERVIEW_DECLINE) {
            setDeclined(declined => new Set([...declined].filter(id => id !== data.id)));
            updateListCache(
              queryClient,
              "interviews",
              {uuid: data.uuid, status: interviewStatus.INTERVIEW_DECLINE},
            );
          }
          if (data.status === interviewStatus.INTERVIEW_TIME_REQUESTED) {
            setTimeRequested(timeRequested => new Set([...timeRequested].filter(id => id !== data.id)));
            updateListCache(
              queryClient,
              "interviews",
              {uuid: data.uuid, status: interviewStatus.INTERVIEW_TIME_REQUESTED, candidate_message: data.candidate_message},
            );
          }
        },
        10000,
      );
    },
    onError(_, {status, uuid}) {
      // Show error and undo optimistic update.
      const interviewId = interviews.find(interview => interview.uuid === uuid).id;
      if (status === interviewStatus.INTERVIEW_DECLINE) {
        setDeclined(declined => new Set([...declined].filter(id => id !== interviewId)));
        addAlert("decline-error", "danger", "An error happened while declining the interview");
      }
      if (status === interviewStatus.INTERVIEW_TIME_REQUESTED) {
        setTimeRequested(timeRequested => new Set([...timeRequested].filter(id => id !== interviewId)));
        addAlert("timeRequest-error", "danger", "An error happened while requesting a new time");
      }
    },
  });

  const interviewDateMutation = useInterviewDateMutation({
    onSuccess(data) {
      updateListCache(
        queryClient,
        "interviews",
        {id: data.interview, status: interviewStatus.INTERVIEW_ACCEPTED},
        "id",
      );
    },
  });

  function updateOffer(uuid, data) {
    placementMutation.mutate({uuid, data});
  }

  const placementMutation = usePlacementMutation("CAN", {onSuccess(data) {
    if (OFFER_STATUSES.includes(data.status)) {
      updateListCache(queryClient, "placement-offers", _.pick(data, ["status", "uuid"]));
    }
    else {
      removeFromListCache(queryClient, "placement-offers", _.pick(data, ["status", "uuid"]));
    }
  }});

  if (isOffersLoading || arePlacementsLoading || iscompletedInterviewsLoading) {
    return <></>;
  }

  const offerInterview = (
    (
      offers?.length > 0 ||
      offerInterviewList?.length > 0 ||
      completedInterviews?.length > 0 ||
      documentRequests?.length > 0  ||
      placements?.filter(placement => ![placementStatus.VIEWED].includes(placement.status)).length > 0 ||
      skillReferralRequests?.length > 0
    ) &&
    <>
      <SwiperList
        name="actionRequired"
        title="Action required"
        strBase64={bell}
        nodes={GetOfferInterviewListNodes({
          interviews: offerInterviewList,
          onAccept: handleInterviewAccept,
          onDecline: handleInterviewDecline,
          onNewTimeRequest: handleInterviewNewTimeRequest,
          slidesPerView: 2,
          offers: sortedOffers,
          skillReferralRequests,
          hasInterviews,
          setIsSkillReferralRequestModalOpen,
          submittedReferralUuid,
          documentRequests: documentRequests,
          updateOffer: updateOffer,
          hideDetails: hideDetails,
          closeCard: closeCard,
          setCloseCard: setCloseCard,
          completedInterviews: completedInterviews
        }).filter(n => !!n)
        }
      />
    </>
  );

  return (
    offerInterview
  );
}
