import React, {useEffect, useState} from "react";
import styles from "./passwordError.module.scss";
import classNames from "classnames";
interface Props {
  /** A list of errors to display. */
  errors: string[] | undefined;
  /** The name of the input that has errors. This is used to scroll it into view. */
  name: string;

}

export default function PasswordError({errors, name}: Props): JSX.Element | null {

  if (!errors?.length) {
    return null;
  }
  if (errors.length > 1) {
    return (
      <ul className="text-danger" data-error-for={name}>
        {errors.map(error => <li key="error">{error}</li>)}
      </ul>
    );
  }
  return (<div className={styles.errorCard}>
    <p className="card-text">
    Whoops! The password entered for this existing account is incorrect. Forgot your password?&nbsp;
      <a href="/auth/password/reset/">Reset your password here.</a>
    </p>

  </div>);
}
