import React from "react";
import classNames from "classnames";

import styles from "./header.module.scss";


interface Props {
  children: React.ReactNode;
  className: string;
  onClose?: () => void;
}


/**
 * A header section, styled to fit at the top of the slate design.
 */
export default function Header({children, className, onClose, ...divprops}: Props) {
  return (
    <div className={classNames(styles.header, className)} {...divprops}>
      {children}
      {
        onClose && <i className={styles.closeIcon} onClick={onClose}></i>
      }
    </div>
  );
}
