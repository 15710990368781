import React, {forwardRef} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import {mergeClassNames} from "styles/utils";

import ModalContainer from "./ModalContainer";
import baseStyles from "./modal.module.scss";
import Slate from "../Slate";


interface Props {
  /** The content of the modal. */
  children: React.ReactNode;
  /** A class name to restyle the modal. */
  className?: string;
  /** The ID of the container. */
  id: string;
  /** The callback to call when the modal is closed. */
  onClose?: (event: MouseEvent) => void;
  /** Whether the modal is open. */
  open?: boolean;
  /** Theme to restyle the modal and its container. */
  theme?: {[key: string]: string};
}

const Modal = forwardRef<HTMLDivElement, Props>((props, ref): JSX.Element => {
  const {children, className, id, open=true, onClose, theme, ...contentProps} = props;
  const styles = mergeClassNames(baseStyles, theme);
  const modalContentStyle = classNames(styles.modalContent, styles.slate, className);

  return (
    <ModalContainer id={id} open={open} onClickAway={onClose} ref={ref} theme={theme}>
      <Slate className={modalContentStyle} {...contentProps}>
        {onClose && <i className={classNames("pi pi-times", styles.closeIcon)} onClick={onClose}/>}
        {children}
      </Slate>
    </ModalContainer>
  );
});
Modal.displayName = "Modal";

export default Modal;
