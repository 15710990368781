import classNames from "classnames";


type StringObject = {[key: string]: string};

/*
 * Merges two similarly-keyed styles objects into one. This function is useful
 * for restyling a component, where a styles and theme object follow the same
 * structure. Only keys present `styles` object will be merged. Keys in the
 * `theme` will be ignored if not also present in `styles`.
 */
export function mergeClassNames(styles: StringObject = {}, theme: StringObject = {}): StringObject {
  const keys = [...new Set([...Object.keys(styles), ...Object.keys(theme)])];

  return Object.fromEntries(
    keys.map(k => [k, classNames(styles[k], theme[k])]),
  );
}
