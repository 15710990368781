import React, { useState } from "react";
import classNames from "classnames";
import _ from "lodash";

import {PAYMENT_CYCLE, features, featuresDescriptions, featuresMapping} from "components/my-account/constants/UpgradeToPremium";
import styles from "./planOverview.module.scss";
import "./planOverview.scss";
import RadioGroupSwitch from "components/shared/RadioGroupSwitch";
import {Heading, Button} from "workfinder-components";
import Tooltip from "./Tooltip";
import NavTabs from "./NavTabsResponsive/NavTabs";

export interface Feature {
  /** The name of the plan. */
  name: string;
  /** The description of the plan. */
  description: string;
  /** The href link for learn more. */
  link?: string;
  show?: boolean
}

export interface Plan {
  /** The name of the plan. */
  name: string;
  /** The price of the plan in GBP (£). */
  price: object;
  /** If this is enterprise or not */
  isEnterprise: boolean,
  /** If this is free or not */
  isFree: boolean,
  /** A short decription of the plan. */
  description: string;
  /** Icon or emoji */
  icon?: string,
  /** Chargebee Id */
  chargebeeId?: string,
  /** A secondary decription of the plan. */
  descriptionSecondary: string;
  /** Button text */
  buttonText: string;
  /** Button kind */
  buttonKind: string;
  /** Text before feature listing */
  featureTitle: string;
  /** The features included in the plan. */
  features: Feature[];
  /** Text after feature listing */
  featureNote: object;
  /** Any promotional information, e.g. discounts. */
  isPromoted: boolean;
  /** Promo header text */
  promoText?: string;
  /** Yearly discount if available */
  discount?: object;
  /** object that contains Id of chargeebee */
  id: object;
}

interface Props {
  /** A callback to call when the "choose plan" button is clicked. */
  onPlanClick: (plan: Plan, paymentCycle: string) => void;
  /** The plans to display in the columns. */
  plans: Plan[];
  skipFree?: boolean;
}

export interface PlanCardProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  paymentCycle: string;
  className?: string;
  onClick: (plan: Plan) => void;
  plan: Plan;
  skipFree: boolean;
}

function PlanCard({paymentCycle, className, onClick, plan, skipFree, ...extraProps}: PlanCardProps) {
  return (
    <div
      className={classNames(styles.planCardContainer, plan.isPromoted ? styles.promoted : "", className)}
      key={`plan-${plan.name}`}
      {...extraProps}
    >
      {
        plan.isPromoted &&
        <div className={styles.promoHeader}>{plan.promoText}</div>
      }
      <div className={styles.planCard}>
        <h2 className={styles.planTitle}>{plan.icon}&nbsp;{plan.name}
          {paymentCycle == PAYMENT_CYCLE.ANNUM && plan.discount && plan.discount[paymentCycle] && (<span className={styles.yearlyDiscount}>{`-${plan.discount[paymentCycle]}%`}</span>)}
        </h2>
        <span className={styles.description}>{plan.description}</span>
        <div className={styles.priceContainer}>
          <strong className={styles.price}>
            {plan.isEnterprise && (<span className="material-icons" style={{fontSize: "2rem", padding: "0.25rem 0"}}>question_answer</span>)}
            {!plan.isEnterprise && (<><sup>£&nbsp;</sup>{plan.price[paymentCycle]}</>)}
          </strong>
        </div>
        <div className={styles.descriptionSecondaryContainer}>
          {plan.price[paymentCycle] != 0 && !plan.isEnterprise && (<span className={styles.description}>Per month</span>)}
          <p className={styles.description}>{plan.descriptionSecondary.replace("{paymentCycle}", paymentCycle == PAYMENT_CYCLE.ANNUM ? "annually" : "monthly")}</p>
        </div>
        <Button
          className={classNames(styles.button, "w-100") }
          kind={plan.buttonKind}
          onClick={onClick}
        >{skipFree && !plan.isEnterprise ? "Upgrade" : plan.buttonText}</Button>
        <h3 className={styles.featureTitle}>{plan.featureTitle}</h3>
        <ul>
          {
            plan.features?.filter(f => f.show).map((feature, idx) => {
              return (
                <li
                  key={`feature-${idx}`}
                  className={classNames(styles.feature, "")}
                >
                  <i className={classNames(styles.listStyle, "fa fa-check")}></i>{feature.name}
                  {feature.description &&
                    <div className={styles.tooltip}>
                      {feature.description}
                      {feature.link && (<a href={feature.link} target="_blank" rel="noreferrer">Learn more</a>)}
                    </div>
                  }
                </li>
              );
            })
          }
        </ul>
        {
          plan.featureNote[paymentCycle] && (<div className={styles.featureFooterNote}><i className="fa fa-user"></i><p className={styles.description}>{plan.featureNote[paymentCycle]}</p></div>)
        }
      </div>
    </div>
  );
}

function DesktopPlanOverview({paymentCycle, onPlanClick, plans, skipFree=false, fullWidthCards=false}) {
  return (
    <div
      className={classNames(styles.desktopPlanOverview, "row pt-0 pb-0 two-px-gutters justify-content-center", fullWidthCards && "px-4 mb-4")}
    >
      {plans.map((plan) => {
        if (typeof(plan.price[paymentCycle]) == "undefined" || skipFree && plan.name.includes("Free")) {
          return <></>;
        }

        return <React.Fragment key={`plan-border-${plan.name}`}>
          <div className={fullWidthCards ? "col-12 col-md-4" : "col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 d-flex flex-column flex-sm-row"}>
            <PlanCard
              paymentCycle={paymentCycle}
              onClick={() => onPlanClick(plan)}
              plan={plan}
              skipFree={skipFree}
            />
          </div>
        </React.Fragment>;
      })}
    </div>
  );
}

export default function PlanOverview(props: Props): JSX.Element {
  /** Payment cycle is monthly or annually */
  const [paymentCycle, setPaymentCycle] = useState(PAYMENT_CYCLE.ANNUM);
  const paymentOptions =
  [
    {
      value: PAYMENT_CYCLE.ANNUM,
      text: "annually",
      secondaryText: "up to -44%",
    },
    {
      value: PAYMENT_CYCLE.MONTH,
      text: "monthly",
    },
  ];

  return <>
    <div className={classNames(styles.radioContainer, "justify-content-md-center", "my-4")}>
      <span className={styles.radioLabel}>Pay</span>
      <RadioGroupSwitch
        currentlValue={paymentCycle}
        name="paymentPeriod"
        onChange={(newValue) => setPaymentCycle(newValue)}
        radioProps={paymentOptions}
      />
    </div>
    <DesktopPlanOverview paymentCycle={paymentCycle} {...props} onPlanClick={(plan) => props.onPlanClick(plan, paymentCycle)}/>
  </>;
}

export const ComparePlans = ({plans}) => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  return (
    <div className={styles.grid}>
      <div className={styles.header} style={{gridColumn: "1 / 2", gridRow: "1 / 2"}}><Heading size="h6" className={styles.recruitment}>🤝 Recruitment</Heading></div>
      <div className={classNames(styles.header, "d-flex d-sm-none")} style={{gridColumn: `2 / ${(plans.length + 2)}`, gridRow: "1 / 2"}}>
        <NavTabs
          tabData={plans.map(p => ({title: `${p.icon} ${p.name}`}))}
          onClick={t => setSelectedPlan(plans.find(p => `${p.icon} ${p.name}` == t))}
          selectedTabTitle={`${selectedPlan.icon} ${selectedPlan.name}`}
          titleClassName={styles.title}
          tabClassName={styles.tab}
        />
      </div>
      {plans.map((plan, j) => (
        <div className={classNames(styles.header, "d-none d-sm-flex justify-content-center")} style={{gridColumn: `${(j+2)} / ${(j+3)}`, gridRow: "1 / 2"}}><Heading size="h6">{plan.icon}&nbsp;{plan.name}</Heading></div>
      ))}
      {Object.getOwnPropertyNames(features).map((feature, i) => (
        <>
          <div className={styles.featureRow} style={{gridColumn: "1 / 2", gridRow: `${(i+2)} / ${(i+3)}`}}>
            {features[feature]} {featuresDescriptions[features[feature]] && <Tooltip>{featuresDescriptions[features[feature]]}</Tooltip>}
          </div>
          <div className={classNames(styles.featureRow, "d-flex d-sm-none justify-content-center")} style={{gridColumn: `2 / ${(plans.length + 2)}`, gridRow: `${(i+2)} / ${(i+3)}`}}>
            {featuresMapping[selectedPlan.name].includes(features[feature]) &&
              <i className={classNames("ri-check-line", styles.icon)}></i>
            }
            {!featuresMapping[selectedPlan.name].includes(features[feature]) &&
              <i className={classNames("ri-subtract-line", styles.icon, styles.grey)}></i>
            }
          </div>
          {plans.map((plan, j) => (
            <div className={classNames(styles.featureRow, "d-none d-sm-flex justify-content-center")} style={{gridColumn: `${(j+2)} / ${(j+3)}`, gridRow: `${(i+2)} / ${(i+3)}`}}>
              {featuresMapping[plan.name].includes(features[feature]) &&
                <i className={classNames("ri-check-line", styles.icon)}></i>
              }
              {!featuresMapping[plan.name].includes(features[feature]) &&
                <i className={classNames("ri-subtract-line", styles.icon, styles.grey)}></i>
              }
            </div>
          ))}
        </>
      ))}
    </div>
  )
}
