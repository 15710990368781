import React, {useContext, useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import {CandidatePublicProfileContext} from "./CandidatePublicProfileContextProvider";
import styles from "./index.module.scss";
import {Heading, Caption, Button, ButtonStatus, SplitButton} from "workfinder-components";
import Tooltip from "components/shared/Tooltip";
import WikipediaTooltip from "util/wikipedia/WikipediaTooltip";
import BaseTooltip from "react-tooltip";

import {skillBuilderTextColor} from "components/candidate-dashboard/skillsBuilderSkills";
import {useRequestedTechSkillReferrals, useSkillsReferralRequestQuery} from "services/queries";

interface skillProps {
  requestSkillLevel: ((id: number) => {}) | null;
  pendingRequests: Array<any>
}


type level = "Inexperienced" | "Getting started" | "Intermediate" | "Advanced" | "Mastery" | "I don’t know";
type relationship = "Present Team Member" | "Present Manager" | "Past Team Member" |"Past Manager";

interface Verification {
  profilePic: string;
  relationship: relationship;
  organisation: string;
  level: level;
  name: string;
  profileUrl: string;
  skill: string;
}

const Skills = ({requestSkillLevel = null, pendingRequests = []} : skillProps):JSX.Element => {
  // const userId = window.location.pathname.split("/").filter(Boolean).pop();
  const context = useContext(CandidatePublicProfileContext);
  const {
    userData,
    candidateData,
    isCandidateLoading,
    isUserLoading,
  } = context;
  const [pendingRequestIds, setPendingRequestIds] = useState(pendingRequests.map(re => re.tech_skill));
  const {data: skillReferralRequests} = useSkillsReferralRequestQuery({mode: "public", user:  userData.id});
  const {data: requestedSkills} = useRequestedTechSkillReferrals({user: userData.id})
  const verifications: Verification[] = useMemo(() => {
    if (!skillReferralRequests || !requestedSkills) {
      return [];
    }
    const joinList = requestedSkills.map(skill => {
      const referralRequest = skillReferralRequests.find(request => request.uuid === skill.referance_uuid);
      if (!referralRequest) {
        return null;
      }
      return {
        profilePic: referralRequest.receiver_photo_url,
        relationship: referralRequest.association_with_requestor,
        name: referralRequest.receiver_full_name,
        organisation: referralRequest.organisation,
        level: skill.level,
        profileUrl: Variables.urls.candidates.member_public_profile.replace("<user_id>", referralRequest.receiver),
        skill: skill.skill,
      };
    });
    return joinList.filter(Boolean);
  }, [requestedSkills, skillReferralRequests]);
  if (isCandidateLoading || isUserLoading) {
    return <></>;
  }

  const getButtonClasses = (skill) => {
    return classNames( {
      [styles.blueButton]: skill.level === null && !pendingRequestIds.includes(skill.id),
      [styles.yellowButton]: skill.level === null && pendingRequestIds.includes(skill.id),
    });
  };

  const requestLevel = (skill) => {
    if (requestSkillLevel) {
      if (!pendingRequestIds.includes(skill.id)) {
        requestSkillLevel(skill.id);
        setPendingRequestIds(existingIds => [...existingIds, skill.id]);
      }
    }
  };

  return (<>
    <div className="mt-3">
      {candidateData && candidateData?.tech_skill_with_level && candidateData.tech_skill_with_level.length > 0 && <Heading size="h6" className="d-flex align-items-center">🛠️ Hard skills
        <Tooltip
          place="right"
        >
          <Heading size="h5">🛠 What are hard skills? </Heading>
          <Caption type="caption">
          Hard skills are like tools in your toolbox. They are hands-on abilities you typically acquire through education, training programs and experience that are measurable and demonstrated through certifications, degrees, projects, portfolios or practical assessments.
          By sharing as many of your hard skills and their levels as possible, we can provide you with more roles, courses and masterclass recommendations that perfectly align with your skillset to help you build your skills faster.
          Examples of hard skills: Python, Figma, user experience design, marketing, Microsoft Word, HTML
          </Caption>
        </Tooltip>
      </Heading>}

      <div className={styles.skillsList}>
        {candidateData && candidateData?.tech_skill_with_level && candidateData.tech_skill_with_level.length > 0 && candidateData.tech_skill_with_level?.map((skill, index) =>  {
          console.log("this is the filtered version", verifications.filter((verification) => (verification.skill === skill.skill) && verification.level));

          return (
            <div key={skill.index} className="d-inline-flex">
              <ButtonStatus
                onClick={() => skill.level === null ? requestLevel(skill) : null}
                tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug} />}
                status={(requestSkillLevel && skill.level === null) ? (pendingRequestIds.includes(skill.id) ? "pending" : "not available") : "known"}
                text={(requestSkillLevel && skill.level === null) ? (pendingRequestIds.includes(skill.id) ? "Level pending" : "Request level") : skill.level}
                verifications={verifications.filter((verification) => (verification.skill === skill.skill) && verification.level)}
                memberName={skillReferralRequests?.length ? skillReferralRequests[0].requestor_full_name : null}
              >
                <span className="text-nowrap">{skill.skill}</span>
              </ButtonStatus>
            </div>
          );
        })}
      </div>
    </div>
    <div className="mt-3">
      {candidateData && candidateData?.skills_builder_skills_with_levels && candidateData.skills_builder_skills_with_levels.length > 0 && <Heading size="h6" className="d-flex align-items-center">💫 Soft skills
        <Tooltip
          place="right"
        >
          <Heading size="h4">💫 What are soft skills? </Heading>
          <Caption type="caption">
          Soft Skills are your personal qualities that make your interactions sparkle. You need them throughout your life and in every life situation. They're the magic ingredients that bring warmth and effectiveness to your work with other people and they help you navigate any situation with finesse. These are the most important soft skills of tomorrow according to the Skills Builder Universal Framework for Essential Skills.
          </Caption>
        </Tooltip>
      </Heading>}

      <div className={styles.skillsList}>
        {candidateData && candidateData?.skills_builder_skills_with_levels && candidateData.skills_builder_skills_with_levels.length > 0 && candidateData.skills_builder_skills_with_levels?.map((skill, index) =>  {
          if (skill.is_top_selected) {
            return (<>
              <Button key={skill.skill_uuid} kind="secondary" size="small" >{skill.skill}</Button>
            </>
            );
          }
        })}
      </div>
      <div className={styles.skillsList}>
        {candidateData && candidateData?.skills_builder_skills_with_levels && candidateData.skills_builder_skills_with_levels.length > 0 && candidateData.skills_builder_skills_with_levels?.map((skill, index) =>  {
          if (skill.skill_level) {
            return (
              <div key={skill.index} className="d-inline-flex">
                <SplitButton
                  tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug}/>}
                  secondaryComponent={skill.skill_level && <span className="font-weight-normal">Level {skill.skill_level.position}</span>}
                  data-tip
                  data-for={skill.skill}
                >
                  <span className="text-nowrap">{skill.skill}</span>
                </SplitButton>
                {skill.skill_level && <BaseTooltip
                  className={classNames(styles.toolTip, styles.noBorderTooltip)}
                  effect="solid"
                  place="bottom"
                  arrowColor="transparent"
                  id={skill.skill}
                >
                  <>
                    <h6 className={styles.header} style={{color: skillBuilderTextColor[skill]}}>Step {skill.skill_level.position}</h6>
                    <p className="mb-0"><em>{skill.skill_level.category_description}</em></p>
                  </>
                </BaseTooltip>}
              </div>
            );
          }
        })}
      </div>
    </div>
    <div className="mt-3">
      {candidateData && candidateData?.languages_with_levels && candidateData.languages_with_levels.length > 0 && <Heading size="h6">🌎 Language skills</Heading>}
      <div className={styles.skillsList}>
        {candidateData?.languages_with_levels && candidateData.languages_with_levels.length > 0 && candidateData.languages_with_levels?.map((skill, index) =>  (
          <div key={skill.index} className="d-inline-flex">
            <SplitButton
              tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug}/>}
              secondaryComponent={skill.level && <span className="font-weight-normal">{skill.level}</span>}
            >
              <span className="text-nowrap">{skill.language}</span>
            </SplitButton>
          </div>
        ))}
      </div>
    </div>
  </>
  );
};
export default Skills;
