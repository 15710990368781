import React, {useState} from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import {usePopper} from "react-popper";

import EventTracking from "tracking/EventTracking";
import {chatModalInterface} from "components/shared/Modal/ChatModal";
import {offerInterviewModalInterface} from "components/dashboard/OfferInterviewModal";
import DropdownMenu from "components/shared/DropdownMenu";
import {getActiveFeatures, BUTTONS} from "util/placement/activeFeatures";
import {interestEvent, featureUrl, OFFER_EVENT_TYPE} from "util/cms/constants";
import {useSelfQuery} from "services/queries";
import {Button} from "workfinder-components"
import styles from "./QuickActions.module.scss";
import useIsMobile from "hooks/useIsMobile";

export function QuickActions(props) {
  const {data: me} = useSelfQuery();
  const {
    isOwner,
    isProjectCollaborator,
    disabled,
    text,
    candidate,
    setActionedCandidate,
    setDisqualifyModalOpen,
    setSuggestOfferModalOpen,
    setSuggestDeclineModalOpen,
    onRowClick,
    setSharedPlacementUUID,
    setIsShareModalOpen,
    className,
    fullWidthInMobile,
    allowedFeatures=null,
  } = props;

  const activeFeatures = getActiveFeatures(me, {...candidate, interview_status: candidate.interview?.status},
    isProjectCollaborator, isOwner);


  let allowedProjectFeatures = Variables.user? Variables.user.partner : {};
  if (allowedFeatures) {
    allowedProjectFeatures = allowedFeatures;
  }

  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {placement: "bottom"},
  );

  const menuButtonId = `${candidate?.placementID}-menu-button`;
  const menuId = `${menuButtonId}-menu`;
  const offerButton = {
    icon: <i class="ri-mic-fill"/>,
    label: "Offer Interview",
    onClick: e => {
      e.preventDefault();
      setActionedCandidate && setActionedCandidate(candidate);
      offerInterviewModalInterface.dispatch({
        open: true,
        candidate: candidate,
      });
    },
  };

  let buttons = {
    [BUTTONS.VIEW_APPLICATION]: {
      icon: <i class="ri-eye-fill"/>,
      label: "View briefing",
      onClick: (e) => onRowClick(e, candidate.placementID),
    },
    [BUTTONS.OFFER_INTERVIEW]: offerButton,
    [BUTTONS.MESSAGING]: {
      icon: <i class="ri-question-answer-fill"/>,
      label: "Message",
      onClick: e => {
        if (!allowedProjectFeatures.MESSAGING) {
          EventTracking.send({
            content_object: {
              object_id: Variables.user.pk,
              object_type: "User",
            },
            event_type: interestEvent.MESSAGING,
          });
          window.open(featureUrl.MESSAGING);
        }
        else {
          e.preventDefault();
          chatModalInterface.dispatch({
            open: true,
            placementUUID: candidate.placementID,
            correspondentUUID: candidate.user.uuid,
            correspondentName: candidate.user.initials,
          });
        }
      },
    },
    [BUTTONS.MAKE_OFFER]: {
      icon: <i class="ri-trophy-fill"/>,
      label: "Offer Role",
      onClick: e => {
        e.preventDefault();
        const placement_id = candidate.placementKey;
        if (placement_id) {
          EventTracking.send({
            content_object: {
              object_id: placement_id,
              object_type: "Placement",
            },
            event_type: OFFER_EVENT_TYPE,
          });
        }
        window.open(Variables.urls.hosts.offer_role.replace("<placement_id>", candidate.placementID), "_blank");
      },
    },
    [BUTTONS.DISQUALIFY]: {
      icon: <i class="ri-prohibited-line"/>,
      label: "Decline",
      onClick: e => {
        e.preventDefault();
        setActionedCandidate&&setActionedCandidate(candidate);
        setDisqualifyModalOpen(true);
      },
    },
    [BUTTONS.SUGGEST_OFFER_ROLE]: {
      icon: <i class="ri-trophy-fill"/>,
      label: "Suggest to offer role",
      onClick: e => {
        e.preventDefault();
        setActionedCandidate&&setActionedCandidate(candidate);
        setSuggestOfferModalOpen(true);
      },
    },
    [BUTTONS.SUGGEST_DECLINE]: {
      icon: <i class="ri-prohibited-line"/>,
      label: "Suggest to decline",
      onClick: e => {
        e.preventDefault();
        setActionedCandidate&&setActionedCandidate(candidate);
        setSuggestDeclineModalOpen(true);
      },
    },
  };

  if (!onRowClick) {
    delete buttons[BUTTONS.VIEW_APPLICATION];
  }
  const exclusive_buttons = new Set([BUTTONS.DISQUALIFY, BUTTONS.MAKE_OFFER, BUTTONS.SUGGEST_DECLINE, BUTTONS.SUGGEST_OFFER_ROLE]);
  let valid_dropdownitems = Object.entries(buttons)
    .filter(([feature, _]) => {
      if (exclusive_buttons.has(feature) && !activeFeatures.has(feature)) {
        return false;
      }
      return true;
    });

  buttons = valid_dropdownitems.reduce((buttons, province) => {
    buttons[province[0]] = province[1];
    return buttons;
  }, {});

  const dropdownItems = Object.entries(buttons)
    .map(([feature, button]) => {
      if (!activeFeatures.has(feature)) {
        button.onClick = () => {};
        button.disabled = true;
      }
      return button;
    });

  dropdownItems.push({
    icon: <i class="ri-user-add-fill"/>,
    label: "Share application",
    onClick: e => {
      e.preventDefault();
      setSharedPlacementUUID&&setSharedPlacementUUID(candidate.placementID);
      setIsShareModalOpen(true);
    },
  });

  const isMobile = useIsMobile(768);

  if (fullWidthInMobile && isMobile) {
    dropdownItems.forEach(item => {
      item.attrs = {style: {justifyContent: "center", height: "2.75rem"}};
    });
  }

  return (
    <div onClick={e => e.stopPropagation()} className={className}>
      <Button
        disabled={disabled}
        id={menuButtonId}
        kind="secondary"
        onClick={() => setOpen(!open)}
        className={classNames("w-100 justify-content-center", styles.quickActionButton, fullWidthInMobile && isMobile ? styles.mobile : "", className)}
        aria-haspopup="true"
        aria-expanded="false"
        role="button"
        ref={setReferenceElement}
      >
        {text} <i className={classNames("ml-1", fullWidthInMobile && isMobile ? "ri-arrow-down-s-line" : "ri-arrow-down-s-line")}></i>
      </Button>
      {
        open &&
        ReactDOM.createPortal(
          <ClickAwayListener onClickAway={(e) => {
            if (e.target !== referenceElement) {
              setOpen(false);
            }
          }}
          >
            <DropdownMenu
              id={menuId}
              items={dropdownItems}
              toggleId={menuButtonId}
              ref={setPopperElement}
              style={{
                display: "block",
                ...(fullWidthInMobile && isMobile && { width: "calc(100% - 30px)" }),
                ...popperStyles.popper,
              }}
              {...attributes.popper}
            />
          </ClickAwayListener>
          , document.body,
        )
      }
    </div>
  );
}
