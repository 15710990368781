import React, {useEffect, useRef, useState} from "react";
import {
  AsyncTypeahead,
  TypeaheadProps,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Input from "components/shared/Input";
import api from "services/v3";
import duedil from "services/duedil";
import styles from "./input.module.scss";
import Down from "/static/workfinder/images/icons/dropdown-down.png";
import classNames from "classnames";

interface Props extends Omit<Omit<TypeaheadProps<string>, "onChange">, "selected"> {
  name?: string;
  onChange: (value: string, name?: string) => void;
  selected: any,
  updateSelectedCompany: any,
  value: string,
  industriesMapping?: any,
  fetchAddress?: boolean
}

/**
 * A single input typeahead.
 *
 * A thin wrapper around react-bootstrap-typeahead, adding styles and making
 * the API consistent with other single-input controls.
 */
export default function DuedilAsyncTypeahead({name, onChange, updateSelectedCompany, selected, fetchAddress=true, ...typeaheadProps}: Props): JSX.Element {
  const typeaheadRef = useRef(null);
  const [serchedCompanies, setserchedCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDueDilCompanies = (keyWord: string) => {
    if (keyWord.length > 0) {
      setLoading(true);
      api.getDuedilSearch({endpoint: "companies", kwargs: {name: keyWord, countries: ["GB"]}}).then(
        result => {
          setserchedCompanies(result.companies);
          setLoading(false);
        },
      );
    }
    onChange(keyWord, name);
  };


  const fetchCompany = async (selectedCompany) => {
    if (selectedCompany?.countryCode && selectedCompany?.companyId) {
      if (fetchAddress) {
        const result = await duedil.getCompanyData(selectedCompany.countryCode, selectedCompany.companyId);
        updateSelectedCompany(result);
      }
      else {
        updateSelectedCompany(selectedCompany);
      }
    }
  };
  return (
    <AsyncTypeahead<string>
      {...typeaheadProps}
      paginate={false}
      labelKey={(option: {name: any;}) => `${option.name}`}
      // onSearch={fetchDueDilCompanies}
      options={serchedCompanies}
      isLoading={loading}
      onChange={selected => fetchCompany(selected[0])}
      onInputChange={text => {
        fetchDueDilCompanies(text);onChange(text, name)
        ;
      }}
      renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
        <Input
          {...inputProps}
          ref={ref => {
            inputRef(ref);
            referenceElementRef(ref);
          }}
        />
      )}
      ref={typeaheadRef}
      selected={selected ? [selected] : undefined}
    />
  );
}

export function DuedilAsyncTypeaheadSignUp({ name, onChange, updateSelectedCompany, onCompanyNotFound, onCompanyFound,
  selected, className, ...typeaheadProps}): JSX.Element {
  const typeaheadRef = useRef(null);
  const [serchedCompanies, setserchedCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({companyId: null, countryCode: null, name: null});

  const cantfind = {
    companyId: "NA",
    countryCode: null,
    name: "Can’t find your organisation?",
  };

  const fetchCompany = async (selectedCompany) => {
    if (selectedCompany?.companyId) {
      if ( selectedCompany.companyId == cantfind.companyId && selectedCompany.countryCode == cantfind.countryCode ) {
        onCompanyNotFound();
        updateSelectedCompany({...cantfind, name: ""});
        setSelectedCompany({...cantfind, name: ""});
        return;
      }
      updateSelectedCompany(selectedCompany);
      setSelectedCompany(selectedCompany);
      onCompanyFound();
    }
  };


  const fetchDueDilCompanies = (keyWord: string) => {
    if (keyWord.length > 0) {
      setLoading(true);
      api.getDuedilSearch({endpoint: "companies", kwargs: {name: keyWord, countries: ["GB"]}}).then(
        result => {
          let newResults = result.companies;
          if ( selectedCompany?.name !== "" ) {
            newResults.push(cantfind);
          }
          setserchedCompanies(newResults);
          setLoading(false);
        },
      );
    }
    onChange(keyWord, name);
  };
  return (
    <AsyncTypeahead<string>
      {...typeaheadProps}
      className={className}
      paginate={false}
      onMenuToggle={setOpen}
      labelKey={(option: {name: any;}) => `${option.name}`}
      // onSearch={(query: string) => {} }
      options={serchedCompanies}
      isLoading={loading}
      onChange={selected => fetchCompany(selected[0])}
      onInputChange={text => {
        fetchDueDilCompanies(text);onChange(text, name)
        ;
      }}
      renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
        <Input
          {...inputProps}
          value={typeaheadProps.value}
          ref={ref => {
            inputRef(ref);
            referenceElementRef(ref);
          }}
        />

      )}
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem option={result} position={index} key={`${result.name}`}>
              {result.name}
            </MenuItem>
          ))}
          <MenuItem option={cantfind} position={results.length+1} key={`${cantfind.name}`}>
            {cantfind.name}
          </MenuItem>
        </Menu>)}
      ref={typeaheadRef}
      selected={selected ? [selected] : undefined}
    >
      {() => (
        <div className="rbt-aux">
          {!loading && (
            <div
              className={classNames(styles.dropdown, open ? styles.chevronUp : undefined)}
            >
              <img src={Down}/>
            </div>
          )}
        </div>
      )}
    </AsyncTypeahead>
  );
}
