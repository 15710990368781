import React, {useMemo, useContext, useEffect} from "react";
import { CompanyProfileContext } from "./CompanyProfileContextProvider";

import classNames from "classnames";
import styles from "./companyProgressBar.module.scss";
import {Heading, Subtitle} from "workfinder-components";
import plant from "/static/workfinder/images/plant.png";
import scooter from "/static/workfinder/images/scooter.png";
import building from "/static/workfinder/images/building.png";
import hat from "/static/workfinder/images/hat.png";
import Verified from "/static/workfinder/images/Verified.png";
import {DISABLE_REFETCH_OPTIONS, useCompanyScoreQuery} from "services/queries";
import Tooltip from "components/shared/Tooltip";
const statuses = ["CorpGreenhorn", "FirmStarter", "Firmiddle", "BizMaestro", "Verified"];
const images = [plant, scooter, building, hat, Verified];

const CompanyProgressBar = ({company_id, minimal=false}) => {
  const context = useContext(CompanyProfileContext);

  const {
    updateProgressBar,
  } = context;

  const {data: company, isLoading, refetch} = useCompanyScoreQuery({company_id: company_id}, DISABLE_REFETCH_OPTIONS);

  useEffect(() => {
    refetch();
  }, [updateProgressBar]);

  const progressPercent = isLoading ? -1 : company?.profile_status_perc;

  const currentStatus = useMemo(() => {
    if (!company?.profile_status_perc == undefined) {
      return null;
    }
    return progressPercent >= 0 && statuses[Number.parseInt(progressPercent/(100/(statuses.length-1)))];
  }, [company, progressPercent]);

  const index = statuses.indexOf(currentStatus);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      {(index === 0 || index > 0) && (
        <div className={classNames("d-flex justify-content-between", styles.heading, minimal ? "mb-4" : "")}>
          <>
            <Heading size="h6" className={classNames(styles.nonBold, "d-flex mb-1")}>
              Profile Status:&nbsp;
              <img src={images[index]} alt={currentStatus} height="20"/>
              &nbsp;
              <b>{currentStatus}</b>
              &nbsp;
              {progressPercent === 100 && <Tooltip>At Superpower, we’re committed to ensuring the safety of our members and of our entire Superpower community.<br/><br/>Your company has successfully passed verification. Being a verified company means you are recognized as an integral and trusted part of our platform, standing out to our members for your commitment to professionalism and security.As a verified company, you'll find it easier to fill open roles. Members trust verified companies, which leads to smoother and quicker hiring processes, making it easier to find the talent you need.</Tooltip>}
            </Heading>
          </>
        </div>
      )}
      {progressPercent < 100 && <>
      <Subtitle className={styles.subText}>
        Complete your company profile to verify your company and to offer roles to candidates.
      </Subtitle>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{background: `linear-gradient(45deg, #006FF1 0%, #CA78EE ${progressPercent === 0 ? 0 : 47.92 / progressPercent}%, #FAC94C ${progressPercent}%, #E2E7F0 ${progressPercent}%, #E2E7F0 100%)`, borderRadius: "10px"}}
        >
          <span className={classNames(styles.icon, progressPercent>= 0 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={plant} alt="CorpGreenhorn" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>CorpGreenhorn</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 25 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={scooter} alt="FirmStarter" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>FirmStarter</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 50 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={building} alt="Firmiddle" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Firmiddle</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 75 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={hat} alt="BizMaestro" /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>BizMaestro</div>
          </span>
          <span className={classNames(styles.icon, progressPercent>= 100 ? styles.active: "")}>
            <img className={minimal ? "mt-0" : ""} src={Verified} alt="Verified"  /><div className={classNames(styles.iconText, minimal ? "d-none" : "")}>Verified</div>
          </span>
        </div>
      </div>
      <div className={styles.buttons}>
        {(window.location.pathname === Variables.urls.candidates.dashboard && progressPercent !== 100) ? (
          <a className={styles.button} href={Variables.urls.candidates.member_profile}>
            <Subtitle className={styles.rowButton}>Complete profile</Subtitle>
          </a>
        ) : null}
      </div>
      </>}
    </div>
  );
};
export default CompanyProgressBar;
