export function classifyTurnover(turnoverOptions, turnover) {
  if (turnoverOptions.length === 0) {
    return null;
  }
  if (!turnover) {
    return turnoverOptions[0];
  }
  if (turnover < 1e6) {
    return turnoverOptions[0];
  }
  if (turnover < 10e6) {
    return turnoverOptions[1];
  }
  if (turnover < 25e6) {
    return turnoverOptions[2];
  }
  if (turnover < 50e6) {
    return turnoverOptions[3];
  }
  if (turnover < 100e6) {
    return turnoverOptions[4];
  }
  if (turnover < 250e6) {
    return turnoverOptions[5];
  }
  if (turnover < 500e6) {
    return turnoverOptions[6];
  }
  if (turnover < 1e9) {
    return turnoverOptions[7];
  }
  if (turnover >= 1e9) {
    return turnoverOptions[8];
  }
  return turnoverOptions[0]; // if for any reason it gets here
}

export function classifySize(companySizeOptions, size) {
  if (companySizeOptions.length === 0) {
    return null;
  }
  if (!size) {
    return companySizeOptions[0];
  }
  if (size < 11) {
    return companySizeOptions[0];
  }
  if (size < 26) {
    return companySizeOptions[1];
  }
  if (size < 51) {
    return companySizeOptions[2];
  }
  if (size < 100) {
    return companySizeOptions[3];
  }
  if (size < 251) {
    return companySizeOptions[4];
  }
  if (size < 501) {
    return companySizeOptions[5];
  }
  if (size < 1001) {
    return companySizeOptions[6];
  }
  return companySizeOptions[7];
}
