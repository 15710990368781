import React, {useMemo} from "react";
import classNames from "classnames";
import BaseTooltip from "react-tooltip";
import {v4 as uuidv4} from "uuid";


import styles from "./tooltip.module.scss";

interface Props {
  /** Content of the tooltip. */
  children: React.ReactNode;
  /** A CSS class used to style the container element. */
  className?: string;
  /** A class to apply to the tooltip icon. */
  iconClassName?: string;
}

export default function Tooltip(props: Props): JSX.Element {
  const id = useMemo<string>(uuidv4, []);
  const {children, className, iconClassName, ...tooltipProps} = props;

  return (
    <>
      <span className={classNames("material-icons", styles.tooltipTrigger, iconClassName)}
        data-tip
        data-for={id}
      >
        help_outline
      </span>

      <BaseTooltip
        className={classNames(styles.tooltip, className)}
        effect="solid"
        place="right"
        {...tooltipProps}
        id={id}
      >
        {children}
      </BaseTooltip>
    </>
  );
}
