import React, {useState, useEffect, useMemo} from "react";

import {Heading} from "workfinder-components";
import UpgradeToPremium from "components/my-account/UpgradeToPremium";
import Modal from "components/shared/Modal"
import {mergeClassNames} from "styles/utils";
import baseStyles from "./upgradeToPremiumModal.module.scss";
import modalTheme from "styles/modals/flat.module.scss";
import classNames from "classnames";
import api from "services/v3";

const styles = mergeClassNames(baseStyles, modalTheme);

interface ModalProps {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  // A title for that modal
  title?: string;
}

export default function UpgradeToPremiumModal({open, handleClose, title=""}:ModalProps): JSX.Element {

  if (!open) {
    return <></>;
  }
  const [companyName, setCompanyName] = useState("");
  const headerText = useMemo(() => {
    return !!title ? title : `Upgrade your free ${companyName} account to access your archived candidates`
  }, [title, companyName]);
  useEffect(() => {

    async function fetchCompany() {
      const user = await api.getUserUpToCompany(true);
      const association = user.host.association;
      const location = association.location;
      const company = location.company;
      setCompanyName(company.name);
    }
    if(!title) {
      fetchCompany();
    }
  }, []);

  return (
    <Modal
      className={classNames(styles.success, styles.mobileBottom, styles.extraTall, styles.upgradeContainer)}
      theme={styles}
      open={open}
      onClose={handleClose}
      id="upgrade-to-premium-modal"
    >
      <Heading className="text-center mt-4" size="h4">{headerText}</Heading>
      <UpgradeToPremium
        skipFree
        hideDetails
        fullWidthCards
        title=""
      />
    </Modal>
  );
}
