import React from "react";

import PersonIcon from "/static/workfinder/images/icons/person.svg";

import LondonLogo from "/static/workfinder/images/logos/01-london.png";
import ZooplaLogo from "/static/workfinder/images/logos/02-zoopla.png";
import PayPalLogo from "/static/workfinder/images/logos/03-paypal.png";
import AdthenaLogo from "/static/workfinder/images/logos/04-adthena.png";
import GogoLogo from "/static/workfinder/images/logos/05-gogo.png";
import MadeLogo from "/static/workfinder/images/logos/06-made.png";
import SiliconLogo from "/static/workfinder/images/logos/07-silicon.png";
import AMillionAdsLogo from "/static/workfinder/images/logos/08-amillionads.png";
import WayraLogo from "/static/workfinder/images/logos/09-wayra.png";
import TheSocialElementLogo from "/static/workfinder/images/logos/10-the-social-element.png";
import topLeft from "/static/workfinder/images/candidates/cover-top-left.png";
import bottomLeft from "/static/workfinder/images/candidates/cover-bottom-left.png";
import topRight from "/static/workfinder/images/candidates/cover-top-right.png";
import bottomRight from "/static/workfinder/images/candidates/cover-bottom-right.png";
import arrow from "/static/workfinder/images/home/channel-partner/dashed-arrow.svg";
import arrowMobile from "/static/workfinder/images/home/channel-partner/dashed-arrow-mobile.svg";
import Star from "/static/workfinder/images/home/channel-partner/star.png";
import Globe from "/static/workfinder/images/home/channel-partner/globe-1.png";

import Surfing from "/static/workfinder/images/home/employers/enterprise/1-surfing.png";
import Rainbow from "/static/workfinder/images/home/employers/enterprise/2-rainbow.png";
import Palette from "/static/workfinder/images/home/employers/enterprise/3-palette.png";
import Bars from "/static/workfinder/images/home/employers/enterprise/4-bars.png";
import Coding from "/static/workfinder/images/home/employers/enterprise/5-coding.png";
import Hands from "/static/workfinder/images/home/employers/enterprise/6-hands.png";
import Rocket from "/static/workfinder/images/home/employers/enterprise/7-rocket.png";
import Telescope from "/static/workfinder/images/home/employers/enterprise/8-telescope.png";
import Arrow from "/static/workfinder/images/home/employers/enterprise/arrow-right.svg";

import Ashley from "/static/workfinder/images/home/employers/team/ashley.png";
import Katie from "/static/workfinder/images/home/employers/enterprise/katie.png";
import Daniela from "/static/workfinder/images/home/DanielaConrad.png";
import Jess from "/static/workfinder/images/home/employers/team/jess.png";
import Makers from "/static/workfinder/images/home/employers/team/makers.png";
import MakersFull from "/static/workfinder/images/home/makers.png";
import Disguise from "/static/workfinder/images/home/disguise.png"
import Octopus from "/static/workfinder/images/home/employers/team/octopus.png";

import ForTeams from "/static/workfinder/images/home/for-teams.png";
import ForBusinesses from "/static/workfinder/images/home/for-businesses.png";
import ForEnterprises from "/static/workfinder/images/home/for-enterprises.png";

import classNames from "classnames";
import styles from "./index.module.scss";
import {Body, Button as ButtonFromLibrary, Heading} from "workfinder-components";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper-bundle.css";
import SwiperCore, {Autoplay, Pagination, Navigation} from "swiper";
import Button from "../Button";
import vector from "/static/workfinder/images/home/v2/Vector.png";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export const ContactUs = () => (
  <div className="d-inline-block">
    <Button
      baseComponent="a"
      kind="blue-primary"
      href={`${window.location.origin}/demo`}
      size="large"
      target="_blank"
    >
      Contact us
    </Button>
  </div>
);

export const PricingButton = ({className=""}) => (
  <div className="d-inline-block">
    <ButtonFromLibrary
      className={className}
      baseComponent="a"
      kind="primary"
      href={`${window.location.origin}/partners/plans/`}
      size="large"
      target="_blank"
    >
      Pricing
    </ButtonFromLibrary>
  </div>
);

export const RequestDemo = ({className=""}) => (
  <div className="d-inline-block">
    <ButtonFromLibrary
      className={className}
      baseComponent="a"
      href={`${window.location.origin}/demo`}
      target="_blank"
      kind="secondary"
      size="large"
    >
      Request demo
    </ButtonFromLibrary>
  </div>
);

export function StartMyJourneyButton({className=""}):JSX.Element {
  return (
    <ButtonFromLibrary
      kind="primary"
      size="large"
      className={className}
      onClick={() => window.location.href = "/signup/member"}
    >
      Start my journey<img alt="arrow-right" className="ml-2" src={vector}></img>
    </ButtonFromLibrary>
  );
}

export function HighlightedSection({title, backgroundColor, children, className=""}):JSX.Element {
  return (
    <div className="container">
      <div className={classNames(styles.highlightedSection, className)} style={{backgroundColor}}>
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  );
}

const HighlightedSection1 = ({children}) => (
  <div className={classNames(styles.purpleSection)}>
    {children || (
      <>
        <div className={styles.title}>
          <img src={Globe} alt="globe"/>
          <Heading size="h3">A world-class service for you</Heading>
        </div>
        <Body size="medium">
        We believe that world-class services are co-created with our users.
          <br/><br/>
        This is why we consistently ask our talent and recruiters like you for feedback to improve them.
          <br/><br/>
        We are happy to announce that talent and recruiters have consistently rated our services with a world-class Net Promoter Score*:
        </Body>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.services}>Talent services</div>
            <div className={styles.score}>48 NPS</div>
          </div>
          <div className="col-12 col-md-6">
            <div className={styles.services}>Recruiter services</div>
            <div className={styles.score}>43 NPS</div>
          </div>
        </div>
        <Body size="small mt-4">*The Net Promoter Score scale ranges from -100 to 100 with scores above 30 considered world-class.</Body>
      </>
    )}
  </div>
);

const HighlightedSection2 = () => (
  <div className={classNames(styles.yellowSection)}>
    <div className={styles.title}>
      <img src={Star} alt="star-emoji"/>
      <Heading size="h3">Our accreditations</Heading>
    </div>
    <Body size="medium">
      <ul>
        <li>We have attained the highest accreditation level of the <a href="https://www.skillsbuilder.org/" target="_blank" rel="noreferrer">Skills Builder Framework</a>.</li>
        <li>We help progress talent toward the sought-after skills promoted by the <a href="https://www.weforum.org/" target="_blank" rel="noreferrer">World Economic Forum</a>.</li>
        <li>We help progress talent according to the <a href="https://www.gatsby.org.uk/education/focus-areas/good-career-guidance" target="_blank" rel="noreferrer">Gatsby Framework</a> widely adopted in the UK.</li>
        <li>We upskill our clients and prevent unconscious bias, allowing for a balanced fair workforce.</li>
        <li>The <a href="https://www.managers.org.uk/" target="_blank" rel="noreferrer">Chartered Management Institute</a> has endorsed our DE&I work.</li>
        <li>We are endorsed by the <a href="https://www.gov.uk/government/organisations/cabinet-office" target="_blank" rel="noreferrer">Cabinet Office</a>, <a href="https://www.gov.uk/government/organisations/department-for-digital-culture-media-sport" target="_blank" rel="noreferrer">DCMS</a>, <a href="https://digileaders.com/" target="_blank" rel="noreferrer">Digital Leaders</a>, the <a href="https://www.cbi.org.uk/" target="_blank" rel="noreferrer">CBI</a>, and the <a href="https://www.scaleupinstitute.org.uk/" target="_blank" rel="noreferrer">Scaleup Institute</a>.</li>
      </ul>
    </Body>
    <a href={`${window.location.origin}/accreditations/`} target="_blank" rel="noreferrer">Learn more about our accreditations <img src={Arrow}/></a>
  </div>
);

export const HighlightedSectionGroup = ({className, childrenSection1}) => (
  <div className={classNames("row", styles.highlightedSectionGroup, className)}>
    <HighlightedSection1>{childrenSection1}</HighlightedSection1>
    <HighlightedSection2/>
  </div>
);

function ImageSetCover():JSX.Element {
  return (
    <div className={styles.imageSet}>
      <img alt="smiling-person" src={topLeft} className={styles.topLeft}/>
      <img alt="smiling-person" src={topRight} className={styles.topRight}/>
      <img alt="smiling-person" src={bottomLeft} className={styles.bottomLeft}/>
      <img alt="smiling-person" src={bottomRight} className={styles.bottomRight}/>
    </div>
  );
}

export function CoverPage({headerImage, headerImageMobile, headerImageClassName, paragraph, children=null}):JSX.Element {
  return (
    <div className={styles.coverSection}>
      <ImageSetCover/>
      <div className="container">
        <img alt="build-your-skills" className={classNames(styles.headerImage, "img-fluid mx-auto d-none d-md-block", headerImageClassName)} src={headerImage}></img>
        <img alt="build-your-skills" className={classNames(styles.headerImage, "img-fluid mx-auto d-block d-md-none", headerImageClassName)} src={headerImageMobile}></img>
        <Heading size="h4" className="text-center font-weight-normal mx-auto mt-4">
          {paragraph}
        </Heading>
        {!children ? <StartMyJourneyButton className={classNames("mx-auto", styles.startButton)}/> : children}
      </div>
    </div>
  );
}

export function Companies({title=""}):JSX.Element {
  const companies = [
    {className: "gogo-logo", src: GogoLogo},
    {className: "adthena-logo", src: AdthenaLogo},
    {className: "paypal-logo", src: PayPalLogo},
    {className: "zoopla-logo", src: ZooplaLogo},
    {className: "london-and-partners-logo", src: LondonLogo},
    {className: "made-com-logo", src: MadeLogo},
    {className: "amillionads-logo", src: AMillionAdsLogo},
    {className: "silicon-logo", src: SiliconLogo},
    {className: "wayra-logo", src: WayraLogo},
    {className: "the-social-element-logo", src: TheSocialElementLogo},
  ];
  return (
    <div className={classNames(styles.companies, "container-fluid")}>
      <div className="row">
        <div className="col-12">
          {title && <p className={styles.companiesTitle}>{title}</p>}
          <Swiper className={styles.companiesSwiper}
            slidesPerView={2}
            freeMode
            loop
            spaceBetween={50}
            speed={3000}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            breakpoints={{
              425: {spaceBetween: 70, slidesPerView: 2,
                slidesPerGroup: 1, speed: 4000},
              768: {spaceBetween: 80, slidesPerView: 5,
                slidesPerGroup: 1, speed: 12000},
              992: {spaceBetween: 90, slidesPerView: 7,
                slidesPerGroup: 5, speed: 30000},
              1200: {spaceBetween: 110, slidesPerView: 7,
                slidesPerGroup: 5, speed: 50000},
            }}
          >
            {
              companies.map(company =>
                <SwiperSlide key={company.className}>
                  <img
                    className={classNames("m-auto", company.className)}
                    key={company.src}
                    src={company.src}
                    alt={company.className.split("-").join(" ")}
                  />
                </SwiperSlide>,
              )
            }
          </Swiper >
        </div>
      </div>
    </div>
  );
}

export function ImageSetReadyToStart():JSX.Element {
  return (
    <div className={styles.imageSetReady}>
      <img alt="smiling-person" src={topLeft} className={styles.bottomLeft}/>
      <img alt="smiling-person" src={topRight} className={styles.bottomRight}/>
      <img alt="smiling-person" src={bottomLeft} className={styles.topLeft}/>
      <img alt="smiling-person" src={bottomRight} className={styles.topRight}/>
    </div>
  );
}

export function PartnerBanner({title="Interested in joining forces?"}: {title?: string}): JSX.Element {
  return (
    <div>
      <h2 className={styles.interestedJoining}>{title}</h2>
      <div className={styles.card}>
        <div className="row pt-0 pb-0">
          <div className="col-12 col-lg-7">
            <div className="row pt-0 pb-0">
              <div className="col-12 col-md-8">
                <Heading size="h4">Partners</Heading>
                <Body size="small">
                  Partner with us to eradicate the skills crisis and drive growth for the economy helping your network to succeed.
                  <br/><br/>
                  Together, we can create a better future.
                </Body>
              </div>
              <div className="col-12 col-md-4">
                <Heading size="h6">What&apos;s included</Heading>
                <i className={classNames(styles.checkMark, "fa fa-check")}></i><Body size="small">Our features your label</Body>
                <br/><br/>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="row pt-0 pb-0">
              <div className="col-12 col-md-5">
                <Heading size="h6">Join partners including</Heading>
                <ul>
                  <li>Barclays Eagle Labs</li>
                  <li>IRIS HR solutions</li>
                  <li>GTI</li>
                </ul>
                <Body size="small">among many more...</Body>
              </div>
              <div className="col-12 col-md-7">
                <span className="material-icons" style={{fontSize: "2rem", paddingBottom: "1rem"}}>question_answer</span>
                <Body size="small">Custom pricing for your organisation</Body>
                <ButtonFromLibrary onClick={() => window.open(`${window.location.origin}/demo/`)} kind="primary">Contact us</ButtonFromLibrary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DividerWithArrows({ text }):JSX.Element {
  return (
    <div className={styles.dividerWithArrows}>
      <img className="d-none d-md-block" src={arrow}/>
      <img className={classNames("d-block d-md-none", styles.arrowMobile)} src={arrowMobile}/>
      <span>{text}</span>
      <img className={classNames(styles.flip, "d-none d-md-block")} src={arrow}/>
      <img className={classNames(styles.flip, "d-block d-md-none", styles.arrowMobileBottom)} src={arrowMobile}/>
    </div>
  );
}

const FeatureCard = ({style, emoji="", feature="", link=null}):JSX.Element => (
  (emoji && feature) ? (
    <a className={styles.featureCard} href={link} target="_blank" rel="noreferrer" style={style}>
      <img src={emoji}/>
      <div>{feature}</div>
    </a>
  ) : <div/>
);

const FeaturesGrid = ():JSX.Element => (
  <div className={styles.featuresGrid}>
    <FeatureCard emoji={Surfing} feature="Talent Upskilling" link="https://superpower.tech/features/let-down/"/>
    <FeatureCard emoji={Rainbow} feature="Talent Acquisition" link="https://superpower.tech/features/dashboard/"/>
    <FeatureCard emoji={Palette} feature="Recruiter Branding" link="https://superpower.tech/employer-branding/"/>
    <FeatureCard emoji={Bars} feature="Diversity Analytics"/>

    <FeatureCard/>

    <FeatureCard emoji={Coding} feature="Virtual Recruiting" link="https://superpower.tech/features/messaging/"/>
    <FeatureCard emoji={Hands} feature="Team Collaboration" link="https://superpower.tech/features/collaborate/" style={{paddingLeft: "6px", paddingRight: "6px"}} />
    <FeatureCard emoji={Rocket} feature="Shortlist in 72 hours" link="https://superpower.tech/features/boost/"/>
    <FeatureCard emoji={Telescope} feature="Data driven Recruiting" link="https://superpower.tech/features/dashboard/"/>
  </div>
);

export const YourTalentSuperpower = ({className="", isPlanButton=false}):JSX.Element => (
  <div className={classNames("row", styles.summary, className)}>
    <div className="col-12 col-lg-6">
      <Heading size="h1">Your talent<br/><strong>superpower</strong></Heading>
      <Body size="large">We help you recruit and upskill talent with mentoring, masterclasses and online courses to deliver high-impact projects to grow your business.
        Your competitive superpower to maximise ROI.</Body>
      <div className={styles.hideOnMobile}>
        {isPlanButton ?
          <div className={styles.contactUsContainer}>
            <PricingButton className="mr-3"/>
            <RequestDemo/>
          </div>:<div className={styles.contactUsContainer}>
            <ContactUs/>
          </div>}
      </div>
    </div>
    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
      <FeaturesGrid/>
      <div className={styles.hideOnDesktop}>
        {isPlanButton ?
          <div className={styles.contactUsContainer}>
            <PricingButton className="mr-3"/>
            <RequestDemo/>
          </div>:<div className={styles.contactUsContainer}>
            <ContactUs/>
          </div>}
      </div>
    </div>
  </div>
);

const PlanDescriptionCard = ({plan, target, description, image, link}):JSX.Element => (
  <div className={styles.planDescriptionCard}>
    <Heading size="h3">{plan}</Heading>
    <span><img src={PersonIcon} alt="person"/>{target}</span>
    <Body size="small">{description}</Body>
    <img src={image} alt={plan} className="flex-grow-1"/>
    <Button
      baseComponent="a"
      href={link}
      target="_blank"
      kind="blue-primary"
    >
      Learn More
    </Button>
  </div>
);

export const PlanDescriptions = ({className=""}):JSX.Element => (
  <div className={classNames(styles.planDescriptions, className)}>
    <PlanDescriptionCard
      plan="For Teams"
      target="Starting at 1 member"
      description="Find skilled talent to deliver projects on time to help you drive growth for your team."
      image={ForTeams}
      link={`${window.location.origin}${Variables.urls.hosts.teams_page}`}
    />
    <PlanDescriptionCard
      plan="For Businesses"
      target="For up to 250 employees"
      description="Supercharge your teams with skilled talent and upskilling services to scale your business."
      image={ForBusinesses}
      link={`${window.location.origin}${Variables.urls.hosts.business}`}
    />
    <PlanDescriptionCard
      plan="For Enterprises"
      target="For over 250 employees"
      description="Empower your teams with skilled talent, upskilling services and bespoke enterprise solutions."
      image={ForEnterprises}
      link={`${window.location.origin}${Variables.urls.hosts.enterprise}`}
    />
  </div>
);

const TestimonialCard = ({description, videoLink, logo, photo, companyName, reviewerName}) => (
  <div className={styles.testimonialCard}>
    <div className={styles.description}>
      {description}
    </div>
    <iframe src={videoLink} allowFullScreen/>
    <div className="d-flex mt-3">
      <img src={logo} height="38" className="mr-2"/>
      <img src={photo} width="45" height="45" className="mr-1"/>
      <div className="d-flex flex-column">
        {reviewerName}
        <strong>{companyName}</strong>
      </div>
    </div>
  </div>
);

export const Testimonials = ({className}) => (
  <div className={classNames(styles.testimonials, className)}>
    <Swiper
      slidesPerView={1}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      breakpoints={{
        250: {slidesPerView: 1},
        768: {slidesPerView: 2},
      }}
      watchOverflow
    >
      <SwiperSlide>
        <TestimonialCard
          videoLink="https://www.youtube.com/embed/pRNTyn-WSUo"
          reviewerName="Jess Redfern"
          companyName="Makers Academy"
          logo={Makers}
          photo={Jess}
          description={
            <em>
            Superpower is brilliant because the <strong>talent is high quality.</strong><br/>We&apos;ve been able to hire interns on several occasions really quickly and without exception, they have <strong>driven business impact from day one.</strong>
            </em>
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialCard
          videoLink="https://www.youtube.com/embed/N3aiu8mkL4o"
          reviewerName="Ashley Firth"
          companyName="Octopus Energy"
          logo={Octopus}
          photo={Ashley}
          description={
            <em>
            I would recommend Superpower because it will quickly give you <strong>access to a wide array of people</strong> with different <strong>skills</strong>, different ways of <strong>thinking</strong> that can scale regardless of how big you get as a company.<br/>Superpower is just as valuable to us now, as it was two years ago.
            </em>
          }
        />
      </SwiperSlide>
    </Swiper>
  </div>
);

export function CoverPageWithImage({subheading, heading, headingText, coverImage, contactUs=true, className=""}):JSX.Element {
  return (
    <div className={classNames("container", styles.coverPageWithImage, className)}>
      <div className="row">
        <div className="col-12 col-md-7">
          <h2 className={styles.subheading}>
            {subheading}
          </h2>
          <h1 className={styles.heading}>
            {heading}
          </h1>
          <p className={styles.headingText}>{headingText}</p>
          {contactUs ? <ContactUs/> : (
            <div>
              <PricingButton className="mr-3"/>
              <RequestDemo/>
            </div>
          )}
        </div>

        <div className="col-12 col-md-5 d-flex justify-content-center">
          <img src={coverImage} alt="cover-image" className="img-fluid"></img>
        </div>
      </div>
    </div>
  );
}

export const FeaturedSectionWithListItems = ({reviewer, company, companyLogo, reviewerPic, subheading, heading, features, testimonial, sideImage, customImages, successStoryLink, className=""}) => (
  <div className={classNames("row", styles.featuredSectionWithListItems, className)}>
    <div className="col-12 col-md-6">
      <h2 className={styles.subheading}>
        {subheading}
      </h2>
      <h1 className={styles.heading}>
        {heading}
      </h1>
      <ul className={styles.featuresList}>
        {typeof features[0] === "string" ? (
          features.map((feature, i) => <li key={`feature-${i}`}>{feature}</li>)
        ) : (
          features.map(({title, description}, i) => (
            <li className={styles.liWithTitle} key={`feature-${i}`}>
              <strong>{title}</strong>
              <br/>
              <p>{description}</p>
            </li>
          ))
        )}
      </ul>
      <p className={styles.story}>
        <em>
          {testimonial}
        </em>
      </p>
      {customImages ? (
        <div className={styles.customImages}>
          {customImages.map(([logo, company, link]) => (
            <a key={company} href={link} target="_blank" rel="noreferrer">
              <img src={logo} alt={`${company}-logo`}/>
            </a>
          ))}
        </div>
      ) : company && (
        <div>
          <div className={styles.featuredSectionWithListItemsFooter}>
            <img src={companyLogo} alt={`${company}-logo`}/>
            <img src={reviewerPic} alt={`${reviewer}-${company}`}/>
            <div>
              <div>{reviewer}</div>
              <div><strong>{company}</strong></div>
            </div>
          </div>
          {successStoryLink && <a href={successStoryLink} target="_blank" rel="noreferrer"><strong>Read {company}’s success story</strong> <img src={Arrow} alt="go-to-page"/></a>}
        </div>
      )}
    </div>
    <div className="col-12 col-md-6">
      <img src={sideImage} alt="side-image" className={styles.sideImage}/>
    </div>
  </div>
);

export const HiringNote = ({className=""}) => {
  return <><Body size="small" className={className}><b>Making hiring as easy as 1, 2, 3</b><br/>
  At Superpower, we believe finding exceptional team members shouldn't be difficult, time-consuming, or expensive. Founded by entrepreneurs who share your frustration with the antiquated complexities of hiring in the age of space travel and AI, we decided to fix it for you.<br/>
  Our <a className={styles.link} href="/success-fee" target="_blank">3% success fee</a> only charged once your role is filled ensures that hiring great talent is affordable, fast and guaranteed, compared to others like LinkedIn, Indeed, Otta, RippleMatch, Workable, Upwork, and Fiverr, so you can get back to what matters most - your mission 🚀</Body></>
}

export const MiniSuccessStoryContainer = ({className=""}) => {
  const data = [
    {
      reviewerFirstName: "Katie",
      companyLogo: PayPalLogo,
      reviewerPic: Katie,
      text: "We recently hosted Michelangelo for two weeks, working with our B2B team on a Competitor Analysis project. Superpower made it very easy and simple to set up.",
      reviewerTitle: "B2B Marketing Manager"
    },
    {
      reviewerFirstName: "Jess",
      companyLogo: MakersFull,
      reviewerPic: Jess,
      text: "With Superpower’s help we were able to find someone aligned to our mission and vision at Makers. They’ve been with us for just under a month and have already added so much value.",
      reviewerTitle: "Head of People"
    },
    {
      reviewerFirstName: "Daniela",
      companyLogo: Disguise,
      reviewerPic: Daniela,
      text: "We collaborated with Superpower to offer a Women in Tech placement within our QA Team. It's been a great experience on both ends.",
      reviewerTitle: "Senior Quality Assurance Analyst"
    }
  ]
  return (
    <div className={classNames("row", className)}>
      {data.map(story => (
        <MiniSuccessStory
          reviewerFirstName={story.reviewerFirstName}
          companyLogo={story.companyLogo}
          reviewerPic={story.reviewerPic}
          text={story.text}
          reviewerTitle={story.reviewerTitle}
          containerClassName="col-12 col-sm-6 col-md-4"
        />
      ))}
    </div>
  )
}

export const MiniSuccessStory = ({reviewerFirstName, companyLogo, reviewerPic, text, reviewerTitle = "", containerClassName="", className=""}) => {
  return (
    <div className={classNames(styles.miniSuccessStory, containerClassName)}>
      <img className={styles.companyLogo} src={companyLogo}/>
      <Body className={classNames(styles.text, className)}>
        {text}
      </Body>
      <div className={styles.recruiter}>
        <img src={reviewerPic} />
        <div>
          <Body>{reviewerFirstName}</Body>
          {reviewerTitle && <Body>{reviewerTitle}</Body>}
        </div>
      </div>
    </div>
  );
}

export const TrySuperpower = ({className="", title=<>Try Superpower<sub>TM</sub> for free</>}) => {
  if(!Variables.user?.uuid) {
    return (
      <div className={classNames("row align-items-center", className)}>
        <div className="col d-flex flex-column align-items-center">
          <Heading size="h3">
            {title}
          </Heading>
          <Body size="medium">
            <ButtonFromLibrary onClick={() => (window.location.href = "/signup")}>
              Sign up free&nbsp;<i className="fa fa-arrow-right"></i>
            </ButtonFromLibrary>
          </Body>
        </div>
      </div>
    );
  }
  else {
    return <></>;
  }
}
