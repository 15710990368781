import React from "react";
import Modal from "components/shared/Modal/Modal";
import styles from "./demoVideo.module.scss";

interface Props {
  /** The callback to call when the modal is closed. */
  onClose?: (event: MouseEvent) => void;
  /** Whether the modal is open. */
  open?: boolean;
}

function DemoVideoModal(props: Props):JSX.Element {
  const {open, onClose} = props;
  let url = "https://www.youtube.com/embed/vwDsP5OoZp0";
  if (!open) {
    url="";
  }
  return (
    <Modal id="demoVideo" open={open} onClose={onClose} theme={styles}>
      <div className="video-responsive">
        <iframe
          id="VideoIframe"
          style={{width: "100%"}} height="514"
          src={url}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </Modal>
  );
}

export default DemoVideoModal;
