import Modal from "components/shared/Modal/Modal";
import Content from "components/shared/Slate/Content";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "services/v3";
import { CardHeaderCompany } from "./DocumentRequestCard";
import { EventInterface } from "util/events";
import { Button, Heading, RichTextEditor, Subtitle } from "workfinder-components";
import classNames from "classnames";
import Tooltip from "components/shared/Tooltip";
import baseStyles from "./coverLetterModal.module.scss";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
const styles = mergeClassNames(baseStyles, modalTheme);

interface CoverLetterData {
  /** The callback to call when the modal is closed. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
  /** Call back function on submit cover letter */
  submitCallback?: () => void;
  placementUuid?: string;
  roleUuid?: string;
  companyLogo?: string;
  roleName?: string;
  companyName?: string;
  requestorName?: string;
}

export const coverLetterModalInterface = new EventInterface<CoverLetterData>("coverLetterModal");

const CoverLetterModal = () => {
  const [placementUuid, setPlacementUuid] = useState<string | undefined>();
  const [roleUuid, setRoleUuid] = useState<string | undefined>();
  const [companyLogo, setCompanyLogo] = useState<string | undefined>();
  const [roleName, setRoleName] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [requestorName, setRequestorName] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const [submitCallback, setSubmitCallback] = useState<(() => void) | undefined>();
  const [coverLetterContent, setCoverLetterContent] = useState('');
  const [initComplete, setInitComplete] = useState(false);

  const placeholder = useMemo(() => {
    return `Write your cover letter here\nDear ${requestorName},\nI am exited about this role because...\nMy experiences and skills that align with this role are...\nI am passionate about your company's mission because...\nBest regards,\n${Variables.user?.full_name}`;
  }, [requestorName]);

  const handleModalEvent = useCallback(
    (detail: CoverLetterData) => {
      setOpen(open => detail.open ?? open);
      setPlacementUuid(detail.placementUuid);
      setRoleUuid(detail.roleUuid);
      setCompanyLogo(detail.companyLogo);
      setRoleName(detail.roleName);
      setCompanyName(detail.companyName);
      setRequestorName(detail.requestorName);
      setSubmitCallback(() => detail.submitCallback);
      if (detail.onClose) {
        setOnClose(() => detail.onClose);
      }
      else {
        setOnClose(undefined);
      }
      setInitComplete(true);
    }, [setOpen, setPlacementUuid, setRoleUuid, setPlacementUuid, setCompanyLogo, setRoleName, setCompanyName, setRequestorName, setSubmitCallback],
  );

  useEffect(() => {
    coverLetterModalInterface.register(handleModalEvent);
    return () => {
      coverLetterModalInterface.remove();
    };
  }, [handleModalEvent]);

  function handleClose() {
    onClose?.();
    setOpen(false);
    setPlacementUuid(undefined);
    setOnClose(undefined);
  }

  const handleSave = () => {
    api.updateCandidatePlacement(placementUuid, {cover_letter: coverLetterContent});
    submitCallback?.();
    handleClose();
  };

  return(
    <Modal theme={styles} open={open} onClose={handleClose} id="add-meeting-link-modal" className={classNames(styles.mobileBottom, styles.smWidth)}>
      <Content className="p-4">
      <CardHeaderCompany
          roleUuid={roleUuid}
          companyLogo={companyLogo}
          companyName={companyName}
          roleName={roleName}
        />
        <div className={styles.divider}></div>
          <div className="d-flex align-items-baseline justify-content-start">
            <Heading size="h5">Cover letter</Heading>
            <Tooltip
                effect="solid"
                place="left"
                arrowColor="transparent"
              >
                <Heading size="h6">Request skill level</Heading>
                <Subtitle className="mb-0">Request the candidate&apos;s skill level by clicking onto the button</Subtitle>
            </Tooltip>
            <div className={styles.label}>Requested</div>
          </div>
          <div style={{ color: "#6F788B" }}>
            <Subtitle size="small" className="mb-auto">
              Including a cover letter increases your chances of success by 10x. Tailor your cover letter to the role & highlighting how your skills align.
            </Subtitle>
          </div>
          {initComplete && <>
            <Subtitle size="small" className="mt-2">The recruiter’s name is: {requestorName}</Subtitle>
            <div className={styles.richTextEditor}>
              <RichTextEditor
                type="text"
                placeholder={placeholder}
                onChange={setCoverLetterContent}
                value={coverLetterContent}
              />
            </div>
          </>}
          <div className={styles.buttonContainer}>
            {/* <Button
              kind="secondary"
              onClick={handleSave}
            >
              Save draft
            </Button> */}
            {/* TODO: Save cover letter as draft */}
            <Button
              disabled={coverLetterContent.length < 3}
              onClick={handleSave}
            >
              Share
            </Button>
          </div>
      </Content>
    </Modal>
  )
};

export default CoverLetterModal;
