import React, {useState} from "react";

import PrimaryButton from "./Button";
import lightning from "/static/workfinder/images/icons/lightning.svg";
import tick from "/static/workfinder/images/icons/tick-yellow.svg";
import cross from "/static/workfinder/images/icons/cross.svg";

import styles from "./checklistPopup.module.scss";
import classNames from "classnames";
import api from "services/v3";
import {contactUs} from "services/messaging";
import PulseLoader from "react-spinners/PulseLoader";
import EventTracking from "tracking/EventTracking";

const sendEvent = ({sent_from}) => {
  EventTracking.send({
    content_object: {
      object_type: "User",
      object_id: Variables.user.pk,
    },
    extra_data: {
      sent_from,
    },
    event_type: "hlp-btn",
  });
};

const HelpBox = ({helpText, setHelpText, sent_from}) => {
  const [messageSent, setMessageSent] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [msgSentText, setMsgSentText] = useState("");
  const sendMessage = () => {
    setIsLoading(true);
    contactUs({data: {
      sent_from,
      message: helpText,
    }}).then(() => {
      setErrorClass("");
      setHelpText("");
      setMsgSentText("Thank you!\nYour message has been sent");
    }).catch(() => {
      setErrorClass(styles.error);
      setMsgSentText("Some error occured when sending your message!");
    }).finally(() => {
      setMessageSent(true);
      setIsLoading(false);
    });
    setInterval(() => setMessageSent(false), 15e3);
  };
  return (
    <div className={styles.helpBox}>
      <h2 className="text-left mb-3">We are here to help</h2>
      <p>Any questions or remarks? Send us a message and we will get back to you in 24hrs. </p>
      <textarea
        className="w-100"
        onChange={e => setHelpText(e.target.value)}
        value={helpText}
        placeholder="Write your message here"
      />
      <div className="d-flex justify-content-between">
        <div
          className={classNames(styles.messageSent, errorClass)}
        >
          {messageSent ? msgSentText : null}
        </div>
        <PrimaryButton kind="blue-primary" onClick={sendMessage} disabled={helpText === ""}>
          {
            isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Send"
          }
        </PrimaryButton>
      </div>
    </div>
  );
};

// Data

const buttonTitle = "Chat";
const title = "Get started with these 3 steps";
const subtitle = ""; // "Let’s get you set up for success";
const completedSubtitle = "Publish your next project to discover more talent for your team";
const completedTitle = "Wow! You completed all first steps";
const actionMsg = "Create next role";
const urls = {
  project: `${window.location.origin}/projects/template/`,
  applications: `${window.location.origin}${Variables.urls.hosts.dashboard}`,
  interviews: `${window.location.origin}${Variables.urls.hosts.dashboard}#interviews`,
};
const steps = (data) => {
  if (!data) {
    return [];
  }
  return [
    {
      key: "project",
      label: "Create your first role",
      completed: false,
      onClick: () => {
        window.location.href = urls.project;
      },
    },
    {
      key: "interview",
      label: "Offer your first interview",
      completed: false,
      onClick: () => {
        window.location.href = urls.applications;
      },
    },
    {
      key: "offer",
      label: "Make your first offer",
      completed: false,
      onClick: () => {
        window.location.href = urls.interviews;
        location.reload();
      },
    },
  ];
};

// Components

const StepNumber = ({stepNumber}) => {
  return (
    <span className={styles.number}>{stepNumber}</span>
  );
};

const ProgressBars = ({qty, completed, steps}) => {
  const width = 94.2 / qty; // percentage. Remaining will be for gaps.
  return (
    <div className={styles.barGroup}>
      {steps.map((_, index) => {
        return <div
          className={classNames(styles.bar, index < completed ? styles.done : styles.pending)}
          key={`progress-bar-${index}`}
          style={{width: `${width}%`}}
        />;
      })}
    </div>
  );
};

const onButtonClick = (isOpen, setIsOpen) => {
  const classNamesToOveride = [["site-header", 1], ["fillContainer", 0]].map(
    ([className, zIndex]: [string, number]) => [document.getElementsByClassName(className)[0], zIndex],
  );
  if (isOpen) {
    setIsOpen(false);
    classNamesToOveride.forEach(([el, _]) => {
      if (el) { //@ts-ignore
        el.style.zIndex = null;
      }
    });
  }
  else {
    setIsOpen(true);
    classNamesToOveride.forEach(([el, zIndex]) => {
      if (el) { //@ts-ignore
        el.style.zIndex = zIndex;
      }
    });
  }
};

const Button = ({buttonTitle, isOpen, setIsOpen, sent_from}) => {
  return (
    <div className={classNames(styles.button, isOpen && styles.isOpen)} onClick={() => onButtonClick(isOpen, setIsOpen)}>
      {
        !isOpen ? <div onClick={() => sendEvent({sent_from})}> <img src={lightning} className="mr-1"/>{buttonTitle}</div> : (
          <img src={cross} />
        )
      }
    </div>
  );
};

const Card = ({data, isOpen, title, subtitle, completedTitle, completedSubtitle, steps, sent_from, hideOnboarding, actionMsg = "", onAction = () => { }}) => {
  const [helpText, setHelpText] = useState("");
  const {onboarding} = data || {};
  const qty = steps.length;
  const completed = onboarding && Object.entries(onboarding).length;

  return (
    isOpen ? (
      <div className={styles.card}>
        {
          (onboarding?.finished || hideOnboarding) ? null : <>
            <span className={styles.title}>
              {completed === qty ? completedTitle : title}
            </span>
            {subtitle ? (
              <span className="pt-2 pb-4">
                {completed === qty ? completedSubtitle : subtitle}
              </span>
            ) : <span className="mt-3"/>}
            <ProgressBars steps={steps} qty={qty} completed={completed} />
            {steps.map((step, idx) => (
              <div className="d-flex flex-row align-items-center pt-4" key={`step-${step.key}`}>
                <div>
                  {(onboarding && onboarding[step.key]) ?
                    <img src={tick} /> :
                    <StepNumber stepNumber={idx + 1} />}
                </div>
                <span className={styles.stepLabel} onClick={() => step.onClick()}>
                  {step.label}
                </span>
              </div>
            ))}
            {actionMsg && (completed >= qty) && (
              <PrimaryButton
                kind="blue-primary"
                onClick={onAction}
                className="mt-3"
              >
                {actionMsg}
              </PrimaryButton>
            )}
            <div className="mt-3 mb-4"/>
          </>
        }
        <HelpBox helpText={helpText} setHelpText={setHelpText} sent_from={sent_from}/>
      </div>
    )
      : null
  );
};

const ChecklistPopup = ({data, setData}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onAction = () => {
    setData({...data, onboarding: {...data.onboarding, finished: true}});
    // we'll assume ChecklistPopup works only for hosts for now so we'll just call api.updateHost()
    api.updateHost(data.uuid, {onboarding: {...data.onboarding, finished: true}}).then(
      () => {
        window.location.href = urls.project;
      },
    );
  };
  const sent_from = "CH-DB";

  return (<div className={classNames("container-fluid", styles.checklist)}>
    {isOpen && <div className={styles.background} />}
    <Card
      data={data}
      isOpen={isOpen}
      title={title}
      steps={steps(data)}
      subtitle={subtitle}
      completedSubtitle={completedSubtitle}
      completedTitle={completedTitle}
      actionMsg={actionMsg}
      onAction={onAction}
      sent_from={sent_from}
      hideOnboarding
    />
    <Button
      buttonTitle={buttonTitle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      sent_from={sent_from}
    />
  </div>);
};

export default ChecklistPopup;
