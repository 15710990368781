import React, {useCallback, useEffect, useState} from "react";

import Modal from "./Modal";
import Content from "../Slate/Content";
import Header from "../Slate/Header";
import {useSelfQuery} from "services/queries";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
import classNames from "classnames";
import {getActiveFeatures, getName} from "util/placement/activeFeatures";
import { formatStartDateDurationLong } from "util/date-time";
import baseStyles from "./timesModal.module.scss";
import {EventInterface} from "util/events";
import {CandidatePlacement} from "./AddMeetingLinkModal"
import _ from "lodash";

const styles = mergeClassNames(baseStyles, modalTheme);

interface TimesModalData {
   /** The callback to call when the modal is closed. */
   onClose?: () => void;
   /** Whether the modal should be open. */
   open?: boolean;
   candidate?: CandidatePlacement;
}

export const timesModalInterface = new EventInterface<TimesModalData>("timesModal");

export default function TimesModal() {
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const [open, setOpen] = useState(false);
  const [candidate, setCandidate] = useState<CandidatePlacement | undefined>();
  const {data: me} = useSelfQuery();
  const activeFeatures = candidate ? getActiveFeatures(me, candidate) : [];

  const handleModalEvent = useCallback(
    (detail: TimesModalData) => {
      setOpen(open => detail.open ?? open);
      setCandidate(detail.candidate);
      if (detail.onClose) {
        setOnClose(() => detail.onClose);
      }
      else {
        setOnClose(undefined);
      }
    }, [setOpen, setCandidate, setOnClose],
  );

  useEffect(() => {
    timesModalInterface.register(handleModalEvent);
    return () => {
      timesModalInterface.remove();
    };
  }, [handleModalEvent]);

  function handleClose() {
    onClose?.();
    setOpen(false);
    setCandidate(undefined);
    setOnClose(undefined);
  }

  function name() {
    if(candidate && candidate.user)
      return getName({activeFeatures, user: candidate.user})
    else
      return "";
  }

  return (
    <Modal id="viewTimesModal" theme={styles} open={open} className={classNames(styles.mobileBottom, styles.extraShort)} onClose={handleClose}>
      <Header className={styles.header}>
        You offered {name()} an interview for the following times:
      </Header>
      <Content className={styles.content}>
        <ol className={styles.timeList}>
          {
            _.orderBy(candidate?.interview?.interview_dates ?? [], ["date_time"]).map(date =>
              <li key={date.id}>
                {formatStartDateDurationLong(date.date_time, date.duration)}
              </li>,
            )
          }
        </ol>
      </Content>
    </Modal>
  );
}
