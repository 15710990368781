import React, {useEffect, useState} from "react";
import styles from "./emailError.module.scss";
import classNames from "classnames";
interface Props {
  /** A list of errors to display. */
  errors: string[] | undefined;
  /** The name of the input that has errors. This is used to scroll it into view. */
  name: string;
  /** A callback to call when Yes is clicked. */
  onYesClick: () => void;
  /** A callback to call when No is clicked. */
  onNoClick: () => void;
  /** Already error message. */
  already_email_message: string;
}

export default function EmailError({errors, name, onYesClick, onNoClick, already_email_message}: Props): JSX.Element | null {

  if (!errors?.length) {
    return null;
  }
  if (errors.length > 1) {
    return (
      <ul className="text-danger" data-error-for={name}>
        {errors.map(error => <li key="error">{error}</li>)}
      </ul>
    );
  }
  if (errors[0] == already_email_message) {
    return (<div className={styles.errorCard}>
      <p className="card-text">
    An existing account is linked to this email.
    Would you like to subscribe to Talent Solutions with this account?
      </p>
      <div className="d-flex">
        <button className={classNames(styles.actionButton, styles.yes, "btn-sm")}
          onClick={() => {
            onYesClick();
          } }
        >Yes</button>
        <button className={classNames(styles.actionButton, styles.no, "btn-sm ml-auto")}
          onClick={() => {
            onNoClick();
          } }
        >No</button>
      </div>

    </div>);
  }
  return <p className="text-danger" data-error-for={name}>{errors[0]}</p>;
}
