import {QueryClient} from "react-query";

interface JSONResponse {
  json: {[name: string]: string[]};
}

export function combineJSONErrors(errors: JSONResponse[]) {
  const combinedErrors = {};
  errors.filter(error => error !== null).forEach(error => {
    for (const [name, errorList] of Object.entries(error.json)) {
      if (!(name in combinedErrors)) {
        combinedErrors[name] = errorList;
      }
      else {
        combinedErrors[name].push(...errorList);
      }
    }
  });
  return combinedErrors;
}

interface CombinedQuery {
  // An array aggregating the data of all passed queries.
  data: any[];
  // An array aggregating the errors of all passed queries.
  errors: any[];
  // Indicates that at least one passed query has encountered an error.
  isError: boolean;
  // Indicates that all passed queries are fetched.
  isFetched?: boolean;
  // Indicates that at least one passed query is fetching.
  isFetching?: boolean;
  // Indicates that all passed queries are idle.
  isIdle: boolean;
  // Indicates that at least one passed query is loading.
  isLoading: boolean;
  // Indicates that all passed queries succeeded.
  isSuccess: boolean;
}

/**
 * Combines multiple queries into a single object, consolidating data and
 * flags. This function also works on mutations.
 */
export function combineQueries(queries) : CombinedQuery {
  return queries.reduce(
    (acc, val) => (
      {
        data: [...acc.data, val.data],
        errors: [...acc.errors, val.error],
        isError: acc.isError || val.isError,
        isFetched: acc.isFetched && val.isFetched,
        isFetching: acc.isFetching || val.isFetching,
        isIdle: acc.isIdle && val.isIdle,
        isLoading: acc.isLoading || val.isLoading,
        isSuccess: acc.isSuccess && val.isSuccess,
      }
    ),
    {
      data: [],
      errors: [],
      isError: false,
      isFetched: true,
      isFetching: false,
      isIdle: true,
      isLoading: false,
      isSuccess: true,
    },
  );
}

interface CombinedMutation {
  // An array aggregating the data of all passed queries.
  data: any[];
  // An array aggregating the errors of all passed queries.
  errors: any[];
  // Indicates that at least one passed mutation has encountered an error.
  isError: boolean;
  // Indicates that all passed mutations are idle.
  isIdle: boolean;
  // Indicates that at least one passed mutation is loading.
  isLoading: boolean;
  // Indicates that any of the passed mutations has succeeded. This is
  // different from the query logic.
  isSuccess: boolean;
}

/**
 * Combines multiple queries into a single object, consolidating data and
 * flags. This function also works on mutations.
 */
export function combineMutations(mutations) : CombinedMutation {
  return mutations.reduce(
    (acc, val) => (
      {
        data: [...acc.data, val.data],
        errors: [...acc.errors, val.error],
        isError: acc.isError || val.isError,
        isIdle: acc.isIdle && val.isIdle,
        isLoading: acc.isLoading || val.isLoading,
        isSuccess: acc.isSuccess || val.isSuccess,
      }
    ),
    {
      data: [],
      errors: [],
      isError: false,
      isIdle: true,
      isLoading: false,
      isSuccess: false,
    },
  );
}

const queryClient = new QueryClient({defaultOptions: {
  staleTime: 60 * 1000,
}});

/**
 * Create the default query client, which defines some of the global query
 * behaviour.
 */
export function getDefaultQueryClient() {
  return queryClient;
}
