export const MessageTypes = {
  WENT_ONLINE: 1,
  WENT_OFFLINE: 2,
  TEXT_MESSAGE: 3,
  FILE_MESSAGE: 4,
  IS_TYPING: 5,
  MESSAGE_READ: 6,
  ERROR_OCCURRED: 7,
  MESSAGE_ID_CREATED: 8,
  NEW_UNREAD_COUNT: 9,
  TYPING_STOPPED: 10,
};
