import React from "react";
import {useProjectRecommendationsQuery, DISABLE_REFETCH_OPTIONS} from "services/queries";
import RecommendationCard from "./RecommendationCard";
import SwiperList from "./SwiperList";
import rocket from "base64/rocket";

const RoleList = () => {
  const {data: recommendations, isLoading: areRecommendationsLoading} = useProjectRecommendationsQuery({DISABLE_REFETCH_OPTIONS, placeholderData: []});
  if (areRecommendationsLoading) {
    return null;
  }
  return <SwiperList
    name="roleList"
    title="Roles for you"
    subtitle="Matches selected just for you based on your interests, skills, and goals. The more you check-in, the more matches we drop."
    strBase64={rocket}
    nodes={recommendations.map(recommendation => {
      const project = recommendation.project ? {
        ...recommendation.project,
        recommendation_uuid: recommendation.uuid,
        feedback_submitted: typeof recommendation.is_interested == "boolean",
      } :
        {
          ...recommendation,
          recommendation_uuid: null,
          feedback_submitted: false,
        };
      return {id: recommendation.uuid,
        node: <RecommendationCard
          project={project}
          className=""
        />,
      };
    })}
  />;
};

export default RoleList;
