import React from "react";

import styles from "./editButton.module.scss";

export default function EditButton(props: React.ComponentPropsWithoutRef<"button">) {
  return (
    <button className={styles.editButton} {...props}>
      <i className="wf-icon wf-pencil"></i> Edit
    </button>
  );
}
