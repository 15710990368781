import React, { useState } from "react";
import {useCourseRecommendationsQuery, DISABLE_REFETCH_OPTIONS} from "services/queries";
import {OtherRecommendationCard} from "./RecommendationCard";
import SwiperList from "./SwiperList";
import NoData from "./NoData"
import books from "base64/books"

const CourseList = () => {
  const {data: recommendations, isLoading: areRecommendationsLoading} = useCourseRecommendationsQuery({DISABLE_REFETCH_OPTIONS, placeholderData: []});
  const [clickedOnCard, setClickedOnCard] = useState(false);

  if (areRecommendationsLoading) return null;
  return <SwiperList
    name="courseList"
    title="Courses for you"
    subtitle="Tailored to you, these courses will help you boost your skills and help you achieve your goals:"
    strBase64={books}
    noDataComponent={<NoData title="Recommended courses for you will show up here" subtitle="Oops, it looks like our courses are taking a little break. Please check back later." />}
    nodes={recommendations.map(recommendation => {
      const rec = !!recommendation.course ? recommendation : {course: recommendation, recommendation_type: "course"};
      const isFeatured = !!recommendation.course ? true : false;
      return { id: recommendation.uuid,
        node: <OtherRecommendationCard
          project={rec}
          className={""}
          isFeatured={isFeatured}
          setClickedOnCard={setClickedOnCard}
          clickedOnCard={clickedOnCard}
      />
      }
    })}
    />
}

export default CourseList;
