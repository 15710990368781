import React, {Component} from "react";
import PropTypes from "prop-types";
import Sentry from "tracking/Sentry";


/**
 * The fallback is rendered when an error has occurred and the broken page
 * contents have been removed.
 */
function Fallback({componentStack, resetError}) {
  return (
    <div className="container my-5">
      <div className="card">
        <div className="card-header">
          <p className="mt-3">
            There was an error in loading this page.{" "}
            <span
              style={{cursor: "pointer", color: "var(--green)"}}
              onClick={resetError}
            >
              Reload this page
            </span>{" "}
          </p>
        </div>
        <div className="card-body">
          <details className="error-details" style={{whiteSpace: "pre-wrap"}}>
            <summary>Click for error details</summary>
            {componentStack}
          </details>
          <button
            className="btn btn-primary btn-block text-uppercase mt-4"
            onClick={() => Sentry.showReportDialog({
              user: {
                name: Variables.user?.full_name,
                email: Variables.user?.email,
              },
            })}
          >
            Report feedback
          </button>
        </div>
      </div>
    </div>
  );
}


class ErrorBoundary extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary
        fallback={Fallback}
        showDialog
        dialogOptions={{user: {
          name: Variables.user?.full_name,
          email: Variables.user?.email,
        }}}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ErrorBoundary;
