import useTrackedState from "hooks/useTrackedState";
import React, {Dispatch, SetStateAction, createContext, useEffect, useState} from "react"
import {
  DISABLE_REFETCH_OPTIONS,
  useCandidateQuery,
  useCandidateTechSkillQuery,
  useSelfQuery,
  useUserEducationsQuery,
  useUserExperiencesQuery,
  useUserCertificatesQuery,
 } from "services/queries";
import _ from "lodash";
import {Map} from "immutable";

interface Context {
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  refetchUser: () => void;
  isUserLoading: boolean;
  userData: Map;
  setUserData: Dispatch<SetStateAction<any>>;
  hasUserChanged: boolean;
  convertUser: (user: any) => any;
  candidate: any;
  setCandidate: Dispatch<SetStateAction<any>>;
  refetchCandidate: () => void;
  isCandidateLoading: boolean;
  candidateData: Map;
  setCandidateData: Dispatch<SetStateAction<any>>;
  hasCandidateChanged: boolean;
  convertCandidate: (user: any) => any;
  educations: Array<any>;
  setEducations: Dispatch<SetStateAction<any>>;
  isEducationLoading: boolean;
  refetchEducation: () => void;
  certificates: Array<any>;
  setCertificates: Dispatch<SetStateAction<any>>;
  isCertificateLoading: boolean;
  refetchCertificate: () => void;
  experiences: Array<any>;
  setExperiences: Dispatch<SetStateAction<any>>;
  isExperienceLoading: boolean;
  refetchExperience: () => void;
  selectedSkills: Array<TechSkill>;
  setSelectedSkills: Dispatch<SetStateAction<TechSkill[]>>;
  isTechSkillLoading: boolean;
  refetchTechSkill: () => void;
  updateProgressBar: number;
  setUpdateProgressBar: (counter:number) => number;
}

export const CandidateProfileContext = createContext<Context>({
  user: null,
  setUser: () => {},
  refetchUser: () => {},
  isUserLoading: false,
  userData: null,
  setUserData: () => {},
  hasUserChanged: false,
  convertUser: () => {},
  candidate: null,
  setCandidate: () => {},
  refetchCandidate: () => {},
  isCandidateLoading: false,
  candidateData: null,
  setCandidateData: () => {},
  hasCandidateChanged: false,
  convertCandidate: () => {},
  educations: [],
  setEducations: () => [],
  isEducationLoading: false,
  refetchEducation: () => {},
  certificates: [],
  setCertificates: () => [],
  isCertificateLoading: false,
  refetchCertificate: () => {},
  experiences: [],
  setExperiences: () => [],
  isExperienceLoading: false,
  refetchExperience: () => {},
  selectedSkills: [],
  setSelectedSkills: () => [],
  isTechSkillLoading: false,
  refetchTechSkill: () => {},
  updateProgressBar: 0,
  setUpdateProgressBar: () => any,
});

const USER_FIELDS = [
  //"country",
  "first_name",
  "last_name",
  "summary",
];
const CANDIDATE_FIELDS = [
  "photo",
  "date_of_birth",
  "current_location",
  "wef_skills",
];

const CandidateContextProvider = ({children}) => {
  const [candidate, setCandidate] = useState();
  const [user, setUser] = useState();
  const [userData, setUserData, setReferenceUserData, hasUserChanged] = useTrackedState();
  const [candidateData, setCandidateData, setReferenceCandidateData, hasCandidateChanged] = useTrackedState();
  const [educations, setEducations] = useState([]);
  const [updateProgressBar, setUpdateProgressBar] = useState(0);
  const [certificates, setCertificates] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState<TechSkill[]>([]);

  const {
    data: user_data,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useSelfQuery({
    onSuccess: data => {
      setUser(data);
      setReferenceUserData(Map(convertUser(data)));
    }
  });

  const {
    data: candidate_data,
    isLoading: isCandidateLoading,
    refetch: refetchCandidate,
  } = useCandidateQuery(Variables.uuids.candidate, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      setCandidate(data);
      setReferenceCandidateData(Map(convertCandidate(data)));
    }
  });

  const {
    data: educationData,
    isLoading: isEducationLoading,
    isSuccess: isEducationSuccess,
    refetch:refetchEducation
  } = useUserEducationsQuery({
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setEducations(data);
        // setHasEducationsChanged(true);
      }
      else {
        setEducations([{}]);
      }
    },
  });

  const {
    data: certificateData,
    isLoading: isCertificateLoading,
    isSuccess: isCertificateSuccess,
    refetch: refetchCertificate,
  } = useUserCertificatesQuery({
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setCertificates(data);
      }
      else {
        setCertificates([{}]);
      }
    },
  });

  const {
    data: experienceData,
    isLoading: isExperienceLoading,
    isSuccess: isExperienceSuccess,
    refetch: refetchExperience,
  } = useUserExperiencesQuery({
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setExperiences(data);
      }
      else {
        setExperiences([{}]);
      }
    },
  });

  const {
    data: techSkillData,
    isLoading: isTechSkillLoading,
    isSuccess: isTechSkillSuccess,
    refetch: refetchTechSkill,
  } = useCandidateTechSkillQuery({}, {
    onSuccess: data => {
      if (data.length > 0) {
        setSelectedSkills(data);
      }
    },
  });

  function convertCandidate(candidate) {
    let candidate_data = _.pick(candidate, CANDIDATE_FIELDS);
    if (candidate.date_of_birth) {
      candidate_data.date_of_birth = new Date(candidate.date_of_birth);
    }
    if (candidate.wef_skills && candidate.wef_skills.length > 3) {
      candidate_data.wef_skills = candidate.wef_skills.slice(0, 3);
    }
    return candidate_data;
  }

  function convertUser(user) {
    return _.pick(user, USER_FIELDS);
  }

  return <CandidateProfileContext.Provider
    value={{
      user,
      setUser,
      refetchUser,
      isUserLoading,
      userData,
      setUserData,
      hasUserChanged,
      convertUser,
      candidate,
      setCandidate,
      refetchCandidate,
      isCandidateLoading,
      candidateData,
      setCandidateData,
      hasCandidateChanged,
      convertCandidate,
      educations,
      setEducations,
      isEducationLoading,
      refetchEducation,
      certificates,
      setCertificates,
      isCertificateLoading,
      refetchCertificate,
      experiences,
      setExperiences,
      isExperienceLoading,
      refetchExperience,
      selectedSkills,
      setSelectedSkills,
      isTechSkillLoading,
      refetchTechSkill,
      updateProgressBar,
      setUpdateProgressBar,
    }}
  >
    {children}
  </CandidateProfileContext.Provider>
}

export default CandidateContextProvider;
