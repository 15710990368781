import React, {useState} from "react";
import classNames from "classnames";
import _ from "lodash";
import "primeicons/primeicons.css";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";

import api from "services/v3";
import {ButtonGroup, Content} from "components/shared/Slate";
import Calendar from "components/shared/Form/Calendar";
import Checkbox from "components/shared/Form/Checkbox";
import Modal from "components/shared/Modal/Modal";
import styles from "components/shared/Modal/acceptModal.module.scss";

let cbInstance;
export default function OfferRoleModal({candidate, onClose, open, ...modalProps}) {
  if (!open) {
    return null;
  }

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [offerNote, setOfferNote] = useState("");
  const [viewDate, setViewDate] = useState(new Date());
  const [isProcessing, setIsProcessing] = useState(false);

  async function sendOffer(hostedPageId="") {
    await api.verifyOneTimeCharge(
      {
        placement: candidate.placementKey,
        hostedPageId: hostedPageId,
        additional_offer_note: offerNote,
        offer_starting_date: moment(startDate).format("YYYY-MM-DD"),
        offer_end_date: moment(endDate).format("YYYY-MM-DD"),
      });
  }

  async function handleSendOffer() {
    try {
      setIsProcessing(true);

      const response = await api.getOneTimeChargePage({
        placement: candidate.placementKey,
        offer_starting_date: moment(startDate).format("YYYY-MM-DD"),
        offer_end_date: moment(endDate).format("YYYY-MM-DD"),
      });
      if (Variables.isAuthenticated && "url" in response) {
        cbInstance = cbInstance ?? window.Chargebee.init({site: Variables.chargebee_site});
        cbInstance.openCheckout({
          hostedPage: async function () {
            return response;
          },
          loaded: function () {
          },
          error: function () {
          },
          success: async function (hostedPageId) {
            sendOffer(hostedPageId);
          },
          close: function () {
          },
          step: function (value) {
            // value -> which step in checkout
          },
        });

      }
      else if ("success" in response) {
        sendOffer();
      }

      setIsProcessing(false);
      onClose();
    }
    catch (err) {
      console.log(err);
    }
  }

  function handleStartDateChange(value) {
    if (endDate !== null && value > endDate) {
      setStartDate(value);
      setViewDate(value);
    }
    else {
      setStartDate(value);
      if (endDate === null) {
        setViewDate(value);
      }
    }
  }

  function handleEndDateChange(value) {
    setEndDate(value);
  }

  return (
    <Modal open={open} onClose={onClose} {...modalProps}>
      <Content className={styles.content}>
        <h1 className={styles.title}>MAKE AN OFFER</h1>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-sm mb-3">
                <p className={styles.sectionTitle}>Starting date</p>
                <Calendar
                  appendTo={document.body}
                  value={startDate}
                  inputId="startDate"
                  name="startDate"
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  minDate={new Date()}
                  required
                  onChange={handleStartDateChange}
                  baseZIndex={1100}
                  touchUI
                />
              </div>
              <div className="col-sm mb-3">
                <p className={styles.sectionTitle}>End date</p>
                <Calendar
                  appendTo={document.body}
                  value={endDate}
                  inputId="endDate"
                  name="endDate"
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  minDate={startDate || new Date()}
                  required
                  onChange={handleEndDateChange}
                  onViewDateChange={e => setViewDate(e.value)}
                  viewDate={viewDate}
                  baseZIndex={1100}
                  touchUI
                />
              </div>
            </div>
            <div className={styles.paidSection}>
              <div>
                <p className={styles.sectionTitle}>Additional notes to candidate</p>
                <textarea
                  value={offerNote}
                  className={classNames(styles.textArea, "form-control")}
                  placeholder="Please detail any additional information about the offer"
                  onChange={e => setOfferNote(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.optIn}>
              <Checkbox id="offerOptIn" name="optIn" required
                checked={confirm} theme={styles} onChange={setConfirm}
              />
              <label className={styles.optInlabel} htmlFor="offerOptIn">
                I understand if the candidate accepts this offer I am agreeing to pay a placement fee to Superpower in
                line with <a href="https://superpower.tech/commercial-terms/">the Commercial Terms</a>.
              </label>
            </div>
          </div>
        </div>
      </Content>
      <ButtonGroup>
        <button type="button" className={styles.cancelBtn} onClick={onClose}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary" disabled={!startDate || !endDate || !confirm || isProcessing}
          onClick={handleSendOffer}
        >
          { isProcessing ? <PulseLoader color="#fff" margin={2} size={6}/> : "Make an offer" }
        </button>
      </ButtonGroup>
    </Modal>
  );
}
