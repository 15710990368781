import React from "react";
import {useCertificatesQuery, DISABLE_REFETCH_OPTIONS} from "services/queries";
import SwiperList from "./SwiperList";
import CompletionCertificate from "../project-certificates/CompletionCertificate"
import NoData from "./NoData"
import trophy from "base64/trophy"

export default function CompletionCertificates() {
  const { data: certificates, isLoading } = useCertificatesQuery({}, {...DISABLE_REFETCH_OPTIONS, placeholderData: []});
  if (isLoading) {
    return null;
  }
  else {
    return <SwiperList
      name="certificateList"
      title="My Skills Journey"
      strBase64={trophy}
      noDataComponent={<NoData title="Your skills journey will show up here" subtitle="Build your skills journey by applying to roles, taking courses, masterclasses and mentoring sessions!" />}
      nodes={
        certificates.map(certificate => {
          return {
            id: certificate.uuid,
            node: <CompletionCertificate certificate={certificate} ></CompletionCertificate>
          }
        })
      }
    />
  }
}
