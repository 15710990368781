import React, {createContext, useEffect, useState} from "react";

import {
  DISABLE_REFETCH_OPTIONS,
  useCompanyQuery,
  usePrimaryCompanyQuery,
} from "services/queries";
import _ from "lodash";
import api from "services/v3";
import { useCompanyMutation, useLocationMutation, usePrimaryCompanyMutation } from "services/mutations";
import {Map} from "immutable";
import useTrackedState from "hooks/useTrackedState";
import { locationPurpose, locationType } from "util/companies/constants";

interface Context {
  isCompanyLoading: boolean;
  companyData: Map;
  primaryCompanyData: Map;
  locationData: any;
  logo: any;
  updateLogo: (logo: any) => void;
  handleCompanyChange: (obj: any) => void;
  handlePrimaryCompanyChange: (value: any, name: string) => void;
  handleLocationChange: (obj: any) => void;
  companyUuid?: string | null;
  companyId?: number | null;
  isSaving: boolean;
  updateProgressBar: number,
  setUpdateProgressBar: (n1:number)=> any,
}

export const CompanyProfileContext = createContext<Context>({
  isCompanyLoading: false,
  companyData: null,
  primaryCompanyData: null,
  locationData: null,
  logo: null,
  updateLogo: () => {},
  handleCompanyChange: () => {},
  handlePrimaryCompanyChange: () => {},
  handleLocationChange: () => {},
  companyUuid: null,
  companyId: null,
  isSaving: false,
  updateProgressBar: 0,
  setUpdateProgressBar: (n1: number) => {},
});

const COMPANY_FIELDS = [
  "id",
  "name",
  "year_founded",
  "tagline",
  "description",
  "type",
  "employee_count",
  "growth_rate",
  "currency",
  "turnover",
  "domains"
];

const PRIMARY_COMPANY_FIELDS = [
  "benefits",
  "values",
  "sectors"
]

const CompanyProfileContextProvider = ({companyUuid, companyId, children}) => {
  const [companyData, setCompanyData, setCompanyReferenceData, hasCompanyChanged] = useTrackedState(Map({}));
  const [primaryCompanyData, setPrimaryCompanyData, setPrimaryCompanyReferenceData, hasPrimaryCompanyChanged] = useTrackedState(Map({}));
  const [logo, setLogo] = useState();
  const [locationData, setLocationData] = useState({});
  const [isPendingSave, setIsPendingSave] = useState(false);
  const [isPrimaryPendingSave, setIsPrimaryPendingSave] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [isLogoSaving, setIsLogoSaving] = useState(false);
  const [updateProgressBar, setUpdateProgressBar] = useState(0);

  const {
    isLoading,
    isSuccess: isCompanySuccess,
    data
  } = useCompanyQuery(companyUuid, {
    ...DISABLE_REFETCH_OPTIONS,
  });

  const {
    isLoading: isPrimaryCompanyLoading,
    isSuccess: isPrimaryCompanySuccess,
    data: primaryCompany
  } = usePrimaryCompanyQuery(companyId, {
    ...DISABLE_REFETCH_OPTIONS,
    enabled: isCompanySuccess && !!companyId,
  });

  useEffect(() => {
    if(isCompanySuccess) {
      setCompanyReferenceData(Map(data));
      setLogo(data.logo);
      setLocationData(data.locations?.find(l => l.type == locationType.REGISTERED && l.purpose == locationPurpose.PLACEMENT));
    }
  }, [isCompanySuccess]);

  useEffect(() => {
    if(isPrimaryCompanySuccess) {
      setPrimaryCompanyReferenceData(Map(primaryCompany));
      setIsCompanyLoading(false);
    }
  }, [isPrimaryCompanySuccess]);

  const companyMutation = useCompanyMutation(companyUuid, {
    onSuccess: data =>{
      setUpdateProgressBar(prevCounter => prevCounter + 1);
    },
  });
  const primaryCompanyMutation = usePrimaryCompanyMutation(companyId, {
    onSuccess: data =>{
      setUpdateProgressBar(prevCounter => prevCounter + 1);
    },
  });
  const locationMutation = useLocationMutation(locationData.uuid, {
    onSuccess: data =>{
      setUpdateProgressBar(prevCounter => prevCounter + 1);
    },
  });

  const handleCompanyChange = (obj) => {
    setCompanyData(data => data.merge(obj));
  }

  const handlePrimaryCompanyChange = (value, name) => {
    setPrimaryCompanyData(data => data.set(name, value));
  }

  const handleLocationChange = (obj) => {
    locationMutation.mutate(obj);
  }

  const updateLogo = (newLogo) => {
    let form = new FormData();
    form.append("logo", newLogo);
    setIsLogoSaving(true);
    api.updateCompany(companyUuid, form).then(() =>{
      setLogo(newLogo);
      setIsLogoSaving(false);
      setUpdateProgressBar(prevCounter => prevCounter + 1);
    }).catch(error => setIsLogoSaving(false));
  }

  useEffect(() => {
    if (!companyMutation.isLoading && isPendingSave === true) {
      updateCompany();
    }
  }, [isPendingSave, companyMutation.isLoading]);

  useEffect(() => {
    if (hasCompanyChanged) {
      updateCompany();
    }
  }, [companyData]);

  function updateCompany() {
    const payload = _.pick(companyData.toJSON(), COMPANY_FIELDS);
      if (!companyMutation.isLoading) {
        companyMutation.mutateAsync(payload).then(
          (company) => {
            setIsPendingSave(false);
          });
      }
      else {
        setIsPendingSave(true);
      }
  }

  useEffect(() => {
    if (!primaryCompanyMutation.isLoading && isPrimaryPendingSave === true) {
      updatePrimaryCompany();
    }
  }, [isPrimaryPendingSave, primaryCompanyMutation.isLoading]);

  useEffect(() => {
    if (hasPrimaryCompanyChanged) {
      updatePrimaryCompany();
    }
  }, [primaryCompanyData]);

  function updatePrimaryCompany() {
    const payload = _.pick(primaryCompanyData.toJSON(), PRIMARY_COMPANY_FIELDS);
      if (!primaryCompanyMutation.isLoading) {
        primaryCompanyMutation.mutateAsync(payload).then(
          (primaryCompany) => {
            setIsPrimaryPendingSave(false);
          });
      }
      else {
        setIsPrimaryPendingSave(true);
      }
  }

  return <CompanyProfileContext.Provider
    value={{
      isCompanyLoading: isCompanyLoading || !isCompanySuccess || !isPrimaryCompanySuccess,
      companyData,
      primaryCompanyData,
      locationData,
      logo,
      updateLogo,
      handleCompanyChange,
      handlePrimaryCompanyChange,
      handleLocationChange,
      companyUuid,
      companyId,
      updateProgressBar,
      setUpdateProgressBar,
      isSaving: isLogoSaving || companyMutation.isLoading || primaryCompanyMutation.isLoading || locationMutation.isLoading
    }}
  >
    {children}
  </CompanyProfileContext.Provider>;
};
export default CompanyProfileContextProvider;
