import React, {useEffect, useState} from "react";
import Modal from "components/shared/Modal/Modal";
import api from "services/v3";
import classNames from "classnames";
import styles from "./interviewFeedbackTrigger.module.scss";
import {
  Body,
  Button,
  Subtitle,
  CheckboxGroup,
  Input,
} from "workfinder-components";
import {candidateFeedback, hostFeedback} from "util/interview/constants";
import { UserType } from "util/users/constants";

const OTHER_OPTION = "other";

export const InterviewFeedbackModal = ({
  showFeedbackModal,
  setShowFeedbackModal,
  npsScore,
  feedbackuuid,
  options,
  npsCategory,
  message,
  setMessage,
  hostUserUuid,
  candidateUserUuid,
  page,
  setPage,
  userType,
}) => {
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [otherText, setOtherText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = () => {
    setIsLoading(true);
    let recipient_id = candidateUserUuid;
    const sender_id = Variables.user.uuid;

    if (userType == UserType.CANDIDATE) {
      recipient_id = hostUserUuid;
    }

    api.updateInterviewFeedback(feedbackuuid, {
      other_text: otherText,
      message,
      flags: checkedOptions.filter(option => option !== OTHER_OPTION),
      recipient_id: recipient_id,
      sender_id: sender_id,
    }).then(() => {
      setIsLoading(false);
      setPage(2);
      history.pushState("", document.title, window.location.pathname + window.location.search);
    });
  };

  const getPage = () => {
    switch (page) {
    case 0:
      return (
        <>
          <div className="row">
            <div className="col-12">
              <p className={classNames(styles.round, styles[`nps-${npsScore}`])}>{npsScore}</p>
              <Subtitle size="large" className="text-center">
                <b>Thank You!</b>
                <br/>
                {candidateFeedback[npsCategory].subtitle}
              </Subtitle>
              <Subtitle size="large" className={classNames(styles.primary, "mt-4 mb-3")}>
                <b>{candidateFeedback[npsCategory].request}</b>
              </Subtitle>
              <CheckboxGroup
                checkedOptions={checkedOptions}
                options={options}
                setCheckedOptions={setCheckedOptions}
                className={styles.gap}
                checkboxClassName="mb-0"
              />
              {checkedOptions.includes(OTHER_OPTION) ? (
                <Input
                  placeholder="Please write your reason here"
                  className="mt-3"
                  value={otherText}
                  onChange={setOtherText}
                  long
                />
              ) : null}
            </div>
          </div>
          <Button
            className="mt-3 w-100"
            kind="primary"
            onClick={() => {
              if (!checkedOptions.includes(OTHER_OPTION)) {
                setOtherText("");
              };
              setPage(1);
            }}
            type="button"
            disabled={!checkedOptions.length || (checkedOptions.includes(OTHER_OPTION) && !otherText)}
          >
            {userType == UserType.CANDIDATE ? "Next: Leave a good impression" : "Next: Share your thanks"}
          </Button>
        </>
      );
    case 1:
      return (
        <>
          <Subtitle size="large" className="mt-4 mb-4 text-center">
            <b>{userType == UserType.CANDIDATE ? "Next: Leave a good impression" : "Next: Share your thanks"}</b>
          </Subtitle>
          <Body size="medium">
            {userType == UserType.CANDIDATE ? "It’s good practice to send a thank you message to your interviewer after an interview. Would you like us to send your interviewer the following thank you message on your behalf?" : "It’s good practice to send a thank you message to the candidate after an interview to thank them for their time. Would you like us to send the candidate the following thank you message on your behalf?"}
          </Body>
          <Input
            className={styles.messageBox}
            placeholder="Please write your message here"
            value={message}
            onChange={setMessage}
            long
          />
          <Button
            className="w-100"
            kind="primary"
            onClick={handleSendMessage}
            type="button"
            disabled={!message || isLoading}
          >
            Send message
          </Button>
        </>
      );
    case 2:
      return (
        <>
          <p className={classNames(styles.round, styles[`nps-${npsScore}`])}>{npsScore}</p>
          <Subtitle size="large" className={classNames("text-center mb-4", styles.maxWidth440)}>
            <b>Done!</b>
            <br/>
            Your valuable feedback will allow us to tailor our services to your needs to help you crush your goals with ease.
          </Subtitle>
          <Button
            className="w-100"
            kind="primary"
            onClick={() => setShowFeedbackModal(false)}
            type="button"
          >
            Close
          </Button>
        </>
      );
    default:
      return null;
    }
  };
  return (
    <Modal id="interview-feedbackmodal" className={styles.interviewFeedbackModal} open={showFeedbackModal}
      onClose={() => {
        setShowFeedbackModal(false);
      }}
    >
      <div className="container-fluid">
        {getPage()}
      </div>
    </Modal>
  );
};

export default function InterviewFeedbackTrigger(): JSX.Element {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackuuid, setFeedbackUUID] = useState();
  const [message, setMessage] = useState("");
  const [hostUserUuid, setHostUserUuid] = useState();
  const [candidateUserUuid, setCandidateUserUuid] = useState();

  const [page, setPage] = useState(-1);
  const [npsScore, setNpsScore] = useState();
  const [userType, setUserType] = useState(UserType.CANDIDATE);

  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const npsScoreFromParam = queryParams.get("fd");
    const interview = queryParams.get("inv");
    const user_type = queryParams.get("ut");
    setUserType(user_type);
    if (interview && npsScoreFromParam) {
      api.createInterviewFeedback({
        interview,
        nps_score: npsScoreFromParam,
        feedback_type: user_type}
      ).then((result) => {
        setNpsScore(result.nps_score);
        setPage(result.is_created ? 0 : 2);
        setFeedbackUUID(result.uuid);
        setShowFeedbackModal(true);
        if (user_type == UserType.CANDIDATE) {
          const salutation = result.host_name ? `Dear ${result.host_name}` : "Hello";
          setMessage(`${salutation},\n
          Thank you for your time today, it was a pleasure speaking to you.
          Looking forward to hearing back from you.\n
          Kind regards,
          ${Variables.user.full_name}`);
        }
        else {
          const salutation = result.candidate_name ? `Dear ${result.candidate_name}` : "Hello";
          setMessage(`${salutation},\n
          Thank you for your time today, it was a pleasure speaking to you. \n
          We will be in touch soon regarding next steps. \n
          Kind regards,\n
          ${Variables.user.full_name}`);
        }
        setHostUserUuid(result.host_user_uuid);
        setCandidateUserUuid(result.candidate_user_uuid)
        queryParams.delete('inv');
        queryParams.delete('fd');
        queryParams.delete('ut');
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryParams.toString();
        history.replaceState(null, null, newUrl);
      });
    }
  }, []);
  const npsCategory = Number(npsScore) < 7 ? "NPS_0_6" : Number(npsScore) < 9 ? "NPS_7_8" : "NPS_9_10";
  const baseOptions = userType == UserType.CANDIDATE ? candidateFeedback[npsCategory] : hostFeedback[npsCategory];
  const options = [...baseOptions.responses, {label: "Other", value: "other"}];
  return (<>
    <InterviewFeedbackModal
      showFeedbackModal={showFeedbackModal}
      setShowFeedbackModal={setShowFeedbackModal}
      npsScore={npsScore}
      feedbackuuid={feedbackuuid}
      options={options}
      npsCategory={npsCategory}
      message={message}
      setMessage={setMessage}
      hostUserUuid={hostUserUuid}
      candidateUserUuid={candidateUserUuid}
      page={page}
      setPage={setPage}
      userType={userType}
    />
  </>);
}
