import React, { useEffect, useState } from "react";
import {Heading, Button, Subtitle, Input, Tooltip} from "workfinder-components";
import * as yup from "yup";
import api from "services/v3";
import Google from "/static/workfinder/images/login/google.png";
import Linkedin from "/static/workfinder/images/login/linkedin.png";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss"
import classNames from "classnames";
import PulseLoader from "react-spinners/PulseLoader";
import ErrorMessage from "components/bespoke-project/pages/ErrorMessage";
import {useParams} from "react-router-dom";

const already_email_message = "A user is already registered with this e-mail address. You will be redirected to login page.";
const no_email_message = "No user found with this email, signup instead";

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const urlToUserTypeMap = {
  "recruiter": "PPADM",
  "member": "CAN"
}

const urlToSlugKeyMap = {
  "recruiter": "company_slug",
  "member": "referral_key"
}

const dataShareAgreement = (isCandidate, partnerName = "", agreementText = "") => {
  if (agreementText) {
      return agreementText.replaceAll("__partner__", partnerName)
  }
  else if (isCandidate) {
    return `You agree that we can share end-to-end details about the roles you applied for and personal details with ${partnerName}. You are free at any time to update your preferences if you no longer wish us to share your information with ${partnerName}`;
  }
  else {
    return `You agree that we can share how many roles you published and what skills these roles involved. You are free at any time to update your preferences if you no longer wish us to share your information with ${partnerName} by adjusting your host settings.`
  }
}

export default function EnterEmail({isLogin}) {
  const paramValues = useParams();
  const {slug, partneruuid, partnerdomain} = paramValues;

  const history = useHistory();
  const url = new URL(window.location.href);
  const signupUserType = isLogin ? "" : url.pathname.split("/").slice(2)[0];
  const companySlug = slug ?? ""
  const isCompanySlug = companySlug != "";
  const partnerUuid = partneruuid ?? ""
  const isPartner = (partneruuid && partnerdomain) ? true : false;
  const searchParams = url.searchParams;
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [userErrors, setUserErrors] = useState<Record<string, string[]>>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerName, setPartnerName] = useState("");
  const [agreementText, setAgreementText] = useState("");
  const title = isLogin ? "Welcome back!" : (isCompanySlug ? "Join to continue" : "Next, enter your email");
  const buttonText = isLogin ? "Login" : "Continue";
  const footerText = isLogin ? "Don’t have an account?" : "Already have an account?";
  const footerLinkText = isLogin ? "Sign up" : "Log in";
  const footerLink = isLogin ? "/signup" : "/login";
  const submitApi = isLogin ? api.submitLoginformForOtp : api.submitSignUpformForOtp;
  const redirectUrl = isLogin ? "verification/{user_uuid}?email={email}" : `${signupUserType}/verification/{user_uuid}?email={email}`;
  const userData = isLogin ? {email: email} :
    ((isCompanySlug && isPartner) ? {email: email, user_type: urlToUserTypeMap[signupUserType], [urlToSlugKeyMap[signupUserType]]: companySlug, channel_partner: partnerUuid} :
      (isCompanySlug ? {email: email, user_type: urlToUserTypeMap[signupUserType], [urlToSlugKeyMap[signupUserType]]: companySlug} :
        (isPartner ? {email: email, user_type: urlToUserTypeMap[signupUserType], channel_partner: partnerUuid} :
          {email: email, user_type: urlToUserTypeMap[signupUserType]})));
  const socialSearchParams = isLogin ? "" :
    new URLSearchParams({
      auth_params: isPartner && isCompanySlug ? `${urlToUserTypeMap[signupUserType]},${companySlug},${partnerUuid}` :
        (isCompanySlug ? `${urlToUserTypeMap[signupUserType]},${companySlug}` :
        (isPartner ? `${urlToUserTypeMap[signupUserType]},partner,${partnerUuid}` : urlToUserTypeMap[signupUserType]))
    });
  const baseUrl = "https://superpower.tech/";
  const termConditionsUrl = `${baseUrl}terms-and-conditions/`;
  const comercialTermUrl = `${baseUrl}commercial-terms`;
  const privacyPolicy = `${baseUrl}privacy`;
  const cookiePolicy = `${baseUrl}cookies`;
  const SafeguardingPolicy = `${baseUrl}safe-guarding`;

  const redirect = (user_uuid, isRedirectToLogin) => {
    if(isRedirectToLogin) {
      window.location.href = `${url.origin}/login/verification/${user_uuid}`;
    }
    else {
      const path = redirectUrl.replace("{user_uuid}", user_uuid).replace("{email}", encodeURIComponent(email));
      history.push(`/${path}`)
    }
  }

  useEffect(() => {
    const emailParam = searchParams.get("email");
    if (emailParam)
      checkEmail(emailParam);

    if(partnerUuid && partnerdomain) {
      api.getPartner(partnerdomain).then(response => {
        setPartnerName(response.subdomain.name);
        if(signupUserType == "member")
          setAgreementText(response.channel_data_options?.candidate_accept_terms);
      });
    }
  }, []);

  async function submitForm() {
    let apiFunc = submitApi;
    let redirectToLogin = false;
    if(!isLogin && userErrors && userErrors["email"] && userErrors["email"].length > 0 && userErrors["email"][0] == already_email_message) {
      //If user is an existing one redirect to login page
      apiFunc = api.submitLoginformForOtp;
      redirectToLogin = true;
    }
    setIsLoading(true);
    apiFunc(userData).then((response) => {
      setIsLoading(false);
      redirect(response.user_uuid, redirectToLogin);
    }).catch((error) => {
      setIsLoading(false);
      if(error.status == 404) {
        setUserErrors({
          email: [
            no_email_message
          ]
        });
        setIsDisabled(true);
      }
      else
        setUserErrors(error.json);
    });
  }

  function checkEmail(value) {
    emailSchema.validate({email: value}).then(is_valid => {
      if (is_valid) {
        if (!isLogin) {
          api.checkEmailExists(value).then((results) => {
            if (results.isExists) {
              setUserErrors({
                email: [
                  already_email_message,
                ],
              });
            }
            else {
              setUserErrors({});
            }
          });
        }
        else { setUserErrors({}); }
        setIsDisabled(false);
      }
    }).catch(error => {
      setIsDisabled(true);
      setUserErrors({});
    });
  }

  return (
    <div className="row">
      <div className="col-12 col-sm-9 col-md-8 col-centered">
        <Heading size="h2" className={classNames(styles.header, "text-center")}>{title}</Heading>
        <Input
          className={classNames(styles.input, userErrors["email"] && userErrors["email"].length > 0 ? styles.error : "")}
          placeholder="Email Address"
          value={email}
          onChange={(value, e) => {
            setEmail(value);
            checkEmail(value);
          }}
        />
        <div className="d-flex">
          <ErrorMessage errors={userErrors.email} name="email"><span className="material-icons-outlined" style={{"fontSize": "1.25rem"}}>error</span></ErrorMessage>
        </div>
        <Button disabled={isDisabled || isLoading} className={styles.button} onClick={submitForm}>
          {isLoading ? <PulseLoader color="#fff" margin={2} size={6}/> : `${buttonText} with Email`}
        </Button>
        <div className="separator my-3">Or</div>
        <Button kind="secondary" baseComponent="a" href={`/auth/google/login/?${socialSearchParams}`} className={styles.button}><img src={Google}/>&nbsp;{`${buttonText} with Google`}</Button>
        <Button kind="secondary" baseComponent="a" href={`/auth/linkedin_oauth2/login/?${socialSearchParams}`} className={styles.button}><img src={Linkedin}/>&nbsp;{`${buttonText} with Linkedin`}</Button>
        {!isLogin &&
          <Subtitle size="small" className={classNames(styles.lhMd, "my-4")}>By continuing, you’re agreeing to our <a href={termConditionsUrl} target="_blank" className={styles.link}>Terms & Conditions</a>, {signupUserType != "member" && <><a href={comercialTermUrl} target="_blank" className={styles.link}>Commercial Terms</a>, </>}<a href={privacyPolicy} target="_blank" className={styles.link}>Privacy Policy</a>, <a href={cookiePolicy} target="_blank" className={styles.link}>Cookie Policy</a>{(partnerUuid && partnerdomain) ? ", " : " and "}<a href={SafeguardingPolicy} target="_blank" className={styles.link}>Safeguarding Policy</a>{partnerUuid && <> and agree to share your analytics with {partnerName || partnerdomain} {partnerName && <>(<Tooltip iconComponent={<u>What is shared and why?</u>} iconClassName="d-inline">{dataShareAgreement(signupUserType == "member", partnerName, agreementText)}</Tooltip>)</>}</>}.</Subtitle>
        }
        <Subtitle size="small" className={classNames(styles.lhMd, "text-center my-5")}>{footerText} <a href={footerLink} className={styles.link}>{footerLinkText}</a></Subtitle>
      </div>
    </div>
  );
}
