export function disableBodyScroll() {
  document.body.style.overflow = "hidden";
}

export function enableBodyScroll() {
  document.body.style.overflow = "auto";
}

export function scrollToTarget(id) {
  // It takes into account the height of the navbar
  const idWithHashtag = id.startsWith("#") ? id : `#${id}`;
  const targetSection = document.querySelector(idWithHashtag);
  if (targetSection) {
    const navbarHeight = document.getElementById("left-nav")?.clientHeight || 0;
    const yOffset = -navbarHeight + 1;
    const rect = targetSection.getBoundingClientRect();
    const y = rect.top + window.scrollY + yOffset;
    window.scrollTo({top: y, behavior: "smooth"});
  }
}
