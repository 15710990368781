import React, {forwardRef} from "react";
import classNames from "classnames";
import styles from "./radioButton.module.scss";
import {FormControlProps} from "./types";

interface Props extends FormControlProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id: string;
  children: React.ReactNode;
  position?: "left" | "right";
  theme?: {[key: string]: string};
}

/**
 * A single radiobutton does not a working component make. This component is
 * meant to be integrated into other components.
 */
const RadioButton = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    checked,
    children,
    className,
    disabled,
    id,
    name,
    onChange,
    position = "left",
    theme = {},
    ...restProps
  } = props;

  const containerStyle = classNames(
    className,
    styles.radioButton,
    theme.radioButton,
    {
      [styles.disabled]: disabled,
      [theme.disabled]: disabled,
      [styles.left]: position === "left",
      [styles.right]: position === "right",
    },
  );

  const checkerClassName = classNames(styles.checker, theme.checker, {
    [styles.checked]: checked,
    [theme.checked]: checked,
  });

  function handleOnChange(e) {
    const {checked, name, value} = e.target;
    if (checked) {
      onChange(value, name, e);
    }
  }

  return (
    <label className={containerStyle} htmlFor={id}>
      <input
        {...restProps}
        disabled={disabled}
        checked={checked}
        id={id}
        name={name}
        onChange={handleOnChange}
        ref={ref}
        type="radio"
      />
      <i className={checkerClassName}></i>
      {children}
    </label>
  );
});

export default RadioButton;
