import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./card.module.scss";

function Card({children, className, ...props}) {
  return (
    <div className={classNames(styles.card, className)} {...props}>
      { children }
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Card;
