import React from "react";

import styles from "./avatar.module.scss";
import defaultProfileImage from "./default-profile-image.png";

interface Props {
  /** The size of the avatar. */
  size?: number;
  /** The profile picture to display. If ommitted, a default will be displayed. */
  src?: string;
}

export default function Avatar({size=29, src}: Props): JSX.Element {
  return <img
    src={src ?? defaultProfileImage}
    className={styles.avatar}
    style={{
      height: `${size}px`,
      width: `${size}px`,
    }}
  />;
}
