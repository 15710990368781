import React, {Dispatch, SetStateAction, createContext, useCallback, useMemo, useState} from "react";

import _ from "lodash";
import { DISABLE_REFETCH_OPTIONS, useExpandedPlacements, useInterviewsQuery } from "services/queries";
import { INTERVIEW_PLACEMENT_STATUS_MAPPING } from "components/dashboard/constants/index";


interface Context {
  isLoading: boolean;
  actionedCandidate: any;
  setActionedCandidate: Dispatch<SetStateAction<any>>;
  disqualifyModalOpen: boolean;
  setDisqualifyModalOpen: Dispatch<SetStateAction<boolean>>;
  suggestOfferModalOpen: boolean;
  setSuggestOfferModalOpen: Dispatch<SetStateAction<boolean>>;
  suggestDeclineModalOpen: boolean;
  setSuggestDeclineModalOpen: Dispatch<SetStateAction<boolean>>;
  isShareModalOpen: boolean;
  setIsShareModalOpen: Dispatch<SetStateAction<boolean>>;
  offerSentModalOpen: boolean;
  setOfferSentModalOpen: Dispatch<SetStateAction<boolean>>;
  offerExtensionSentModalOpen: boolean;
  setOfferExtensionSentModalOpen: Dispatch<SetStateAction<boolean>>;
  offerExtensionDeclineModalOpen: boolean;
  setOfferExtensionDeclineModalOpen: Dispatch<SetStateAction<boolean>>;
  isUpgradeOpen: boolean;
  setIsUpgradeOpen: Dispatch<SetStateAction<boolean>>;
  sharedPlacementUUID: string | null;
  setSharedPlacementUUID: Dispatch<SetStateAction<string | null>>;
  interviewCandidates: Array<any> | null;
  nonInterviewCandidates: Array<any> | null;
}

export const HostDashboardContext = createContext<Context>({
  isLoading: false,
  actionedCandidate: null,
  setActionedCandidate: () => {},
  disqualifyModalOpen: false,
  setDisqualifyModalOpen: () => {},
  suggestOfferModalOpen: false,
  setSuggestOfferModalOpen: () => {},
  suggestDeclineModalOpen: false,
  setSuggestDeclineModalOpen: () => {},
  isShareModalOpen: false,
  setIsShareModalOpen: () => {},
  offerSentModalOpen: false,
  setOfferSentModalOpen: () => {},
  offerExtensionSentModalOpen: false,
  setOfferExtensionSentModalOpen: () => {},
  offerExtensionDeclineModalOpen: false,
  setOfferExtensionDeclineModalOpen: () => {},
  isUpgradeOpen: false,
  setIsUpgradeOpen: () => {},
  sharedPlacementUUID: null,
  setSharedPlacementUUID: () => {},
  interviewCandidates: null,
  nonInterviewCandidates: null,
});

const HostDashboardContextProvider = ({children}) => {
  const [actionedCandidate, setActionedCandidate] = useState();
  const [disqualifyModalOpen, setDisqualifyModalOpen] = useState(false);
  const [suggestOfferModalOpen, setSuggestOfferModalOpen] = useState(false);
  const [suggestDeclineModalOpen, setSuggestDeclineModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [offerSentModalOpen, setOfferSentModalOpen] = useState(false);
  const [offerExtensionSentModalOpen, setOfferExtensionSentModalOpen] = useState(false);
  const [offerExtensionDeclineModalOpen, setOfferExtensionDeclineModalOpen] = useState(false);
  const [sharedPlacementUUID, setSharedPlacementUUID] = useState(null);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

  const {data: expandedPlacements, isLoading: isPlacementsLoading} = useExpandedPlacements({});
  const {data: interviews, isLoading: isInterviewsLoading} = useInterviewsQuery({});

  const loadCandidates = useCallback(() => {
    const candidates = expandedPlacements.map(placement => {
      let projectTitle = placement.associated_project?.name;
      if (!projectTitle) {
        const projectName = placement.project?.name || placement.project?.other;
        projectTitle = projectName ? `Speculative (${projectName})` : null;
      }
      const placementInterviews =
        interviews && interviews?.length > 0
          ? interviews?.filter(
            interview => (
              interview.placement === placement.id
              && interview.candidate === placement.candidate.id
            ),
          ) || null
          : null;

      return {
        ...placement.candidate,
        placementKey: placement.id,
        placementID: placement.uuid,
        owner_name: placement.owner_name,
        owner_uuid: placement.owner_uuid,
        is_project_collaborator: placement.is_project_collaborator,
        interview: placementInterviews,
        duration: placement.duration ? placement.duration : "",
        start_date: placement.availability?.lower ?? null,
        end_date: placement.availability?.upper ?? null,
        offer_end_date: placement.end_date,
        placement_type: placement.placement_type?.name ?? "",
        project: projectTitle,
        projectId: placement.associated_project?.uuid,
        type: placement.associated_project?.type,
        wef_skills: placement.wef_skills?.map(p => p.name).join(", ") ?? null,
        skills_builder_skills: placement.skills_builder_skills,
        interview_status: placementInterviews ? placementInterviews.map(i => i.status) : "open",
        status: placement.status,
        hasSupportingDoc: placement.supporting_document,
        institution: placement.institution,
        subject: placement.subject,
        created_at: placement.created_at,
        shared_by: placement.is_shared ? placement.shared_by.full_name : "",
        is_shared: placement.is_shared,
        expiration_date: placement.expiration_date,
        is_recommended: placement.is_recommended,
        confidence_score: placement.confidence_score ?? 0,
        tech_skills: placement.tech_skills,
        allowed_features: placement.allowed_features,
        photo: placement.candidate.photo,
        company_name: placement.company_name,
        comments_count: placement.comments_count ?? 0,
        like_count: placement.voting_count?.Like ?? 0,
        disike_count: placement.voting_count?.Dislike ?? 0
      };
    });

    return candidates;
  }, [expandedPlacements, interviews]);

  const nonInterviewCandidates = useMemo(() => {
    if (expandedPlacements && interviews) {
      const candidates = loadCandidates();
      const nonInterviewCandidates = candidates.filter(candidate => (
        !Object.keys(INTERVIEW_PLACEMENT_STATUS_MAPPING).includes(candidate.status)
      ));
      return nonInterviewCandidates;
    }
    return [];
  }, [expandedPlacements, interviews]);

  const interviewCandidates = useMemo(() => {
    if (expandedPlacements && interviews) {
      const candidates = loadCandidates();
      const interviewCandidates = candidates.filter(
        candidate => candidate?.interview?.length && Object.keys(INTERVIEW_PLACEMENT_STATUS_MAPPING).includes(candidate.status),
      ).map(candidate => candidate.interview.map(
        (interview, idx) => {
          return {...candidate, interview, interview_status: candidate.interview_status[idx]};
        },
      )).flat();
      return interviewCandidates;
    }
    return [];
  }, [expandedPlacements, interviews]);

  return <HostDashboardContext.Provider
    value={{
      isLoading: isInterviewsLoading || isPlacementsLoading,
      actionedCandidate,
      setActionedCandidate,
      disqualifyModalOpen,
      setDisqualifyModalOpen,
      suggestOfferModalOpen,
      setSuggestOfferModalOpen,
      suggestDeclineModalOpen,
      setSuggestDeclineModalOpen,
      isShareModalOpen,
      setIsShareModalOpen,
      offerSentModalOpen,
      setOfferSentModalOpen,
      offerExtensionSentModalOpen,
      setOfferExtensionSentModalOpen,
      offerExtensionDeclineModalOpen,
      setOfferExtensionDeclineModalOpen,
      isUpgradeOpen,
      setIsUpgradeOpen,
      sharedPlacementUUID,
      setSharedPlacementUUID,
      interviewCandidates,
      nonInterviewCandidates,
    }}
  >
    {children}
  </HostDashboardContext.Provider>;
};
export default HostDashboardContextProvider;
