// WikipediaTooltip.js
import React, { useState, useEffect } from "react";

const WikipediaTooltip = ({ keyword }) => {
  const [content, setContent] = useState("");

  const fetchData = (keyword) => {
    fetch(
      `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(
        keyword
      )}`).then((response) => response.json())
      .then((data)=> {
        setContent(data.extract);
      });
  };

  useEffect(() => {
    fetchData(keyword);
  }, []);

  return (
    <p>{content}</p>
  );
};

export default WikipediaTooltip;
