/* eslint-disable space-before-blocks */
import React, {ReactChild, useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames";
import {Heading} from "workfinder-components";

import PlanOverview, { ComparePlans } from "components/shared/PlanOverview";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {interestEvent} from "util/cms/constants";
import api from "services/v3";
import {planNames} from "components/my-account/constants/UpgradeToPremium";
import {usePlansQuery, useMyPremiumPartnerQuery} from "services/queries";
import {setNext} from "util/modal";
import styles from "./upgradeToPremium.module.scss";
import SelectedPlan from "./SelectedPlan";
import {combineQueries} from "util/query";
import {SingnUpLoginModal} from "components/shared/Modal/SignUpModal";
import EventTracking from "tracking/EventTracking";
import {HiringNote, MiniSuccessStoryContainer, TrySuperpower} from "components/shared/homepage";
import {Partners} from "components/request-demo";
import {
  featuresMapping,
  featuresDescriptions,
  featuresLinks
} from "./constants/UpgradeToPremium";
import useStoredState from "hooks/useStoredState";

const featureDetailMapping = (feature: string, show?: boolean) => {
  return {
    name: feature,
    description: featuresDescriptions[feature],
    link: featuresLinks[feature],
    show: show
  };
};

export function createPlanMapping(rawPlans) {
  let alreadyAddedFeature : string[] = [];
  return rawPlans?.map(plan => (
    {
      name: plan.name,
      price: plan.billing_cycles.reduce((prev, billing_cycle_obj) =>
        ({
          ...prev, [billing_cycle_obj.billing_cycle]: billing_cycle_obj.price,
        }), {}),
      discount: plan.billing_cycles.reduce((prev, billing_cycle_obj) =>
        ({
          ...prev,
          ...(billing_cycle_obj.metadata?.discount ? {[billing_cycle_obj.billing_cycle]: billing_cycle_obj.metadata?.discount} : {}),
        }), {}),
      description: plan.description,
      icon: plan.icon,
      chargebeeId: plan.chargebee_id,
      features: featuresMapping[plan.name]?.map(feature => {
        let show = true;
        if(!alreadyAddedFeature.includes(feature))
          alreadyAddedFeature.push(feature);
        else
          show = false;
        return featureDetailMapping(feature, show);
      }),
      id: plan.billing_cycles.reduce((prev, billing_cycle_obj) =>
        ({
          ...prev, [billing_cycle_obj.billing_cycle]: billing_cycle_obj.chargebee_id,
        }), {}),
      isPromoted: plan.is_promoted,
      promoText: plan.metadata?.promo_text,
      isDisplay: plan.is_display,
      isEnterprise: plan.is_enterprise,
      isFree: plan.is_free,
      descriptionSecondary: plan.metadata?.description_secondary,
      buttonText: plan.is_enterprise ? "Contact us" : "Get started",
      buttonKind: plan.is_free ? "secondary" : "primary",
      featureTitle: plan.metadata?.feature_title,
      featureNote: plan.billing_cycles.reduce((prev, billing_cycle_obj) =>
        ({
          ...prev,
          ...(billing_cycle_obj.metadata?.feature_note ? {[billing_cycle_obj.billing_cycle]: billing_cycle_obj.metadata?.feature_note} : {}),
        }), {}),
    }
  ),
  );
}

export function createPlanBillingCycleMapping(rawPlans) {
  return rawPlans?.map(plan =>
    plan.billing_cycles?.map(billing_cycle_obj => (
      {
        icon: plan.icon,
        name: plan.name,
        price: billing_cycle_obj.price,
        discount: billing_cycle_obj.metadata?.discount,
        billingCycle: billing_cycle_obj.billing_cycle,
        description: plan.description,
        features: featuresMapping[plan.name]?.map(feature =>
          featureDetailMapping(feature),
        ),
        id: billing_cycle_obj.chargebee_id,
        isPromoted: plan.is_promoted,
        promoText: plan.metadata?.promo_text,
        isDisplay: plan.is_display,
        isEnterprise: plan.is_enterprise,
        isFree: plan.is_free,
        freeTrial: billing_cycle_obj.free_trial,
        descriptionSecondary: plan.metadata?.description_secondary,
        buttonText: plan.is_free ? "Go to dashboard" : (plan.is_enterprise ? "Contact us" : `Buy ${plan.name}`),
        featureTitle: plan.metadata?.feature_title,
        featureNote: billing_cycle_obj.metadata?.feature_note,
      }
    )),
  ).reduce((prevItems, planArray) => {
    return [...prevItems, ...planArray];
  }, []);
}

const selected_plan_id = new URLSearchParams(location.search).get("plan");
let cbInstance;

function PageHeader({title}):JSX.Element {
  return (
    <div className="row justify-content-md-center" id="pricing">
      <div className="col-md-auto text-center">
        {title && <Heading size="h2">{title}</Heading>}
        <div className={classNames(styles.logoContainer, "mx-auto")}><Partners/></div>
      </div>
    </div>
  );
}
export const openPaymentDialog = (plan: string, addon="Messaging-with-candidates-GBP-Monthly"):void => {

  if (Variables.isAuthenticated) {
    cbInstance = cbInstance ?? window.Chargebee.init({site: Variables.chargebee_site});
    cbInstance.openCheckout({
      hostedPage: async function () {
        try {
          const response = await api.getPaymentPage({plan, addon});
          return response;
        }
        catch (error) {
          // addAlert("load-error", "danger", error.json?.detail ?? "An error has occurred");
          cbInstance.closeAll();
        }
      },
      loaded: function () {
        console.log("checkout opened");
      },
      error: function () {
        console.log("error");
      },
      success: function (hostedPageId) {
        console.log("success", hostedPageId);
      },
      close: function () {
        console.log("close");
      },
      step: function (value) {
        // value -> which step in checkout
        console.log(value);
      },
    });
  }
  else {
    setNext(`/partner/setup/?plan=${plan}`);
    $("#ppsignupModal").modal();
  }
};

interface UpgradeToPremiumProps {
  hideDetails?: boolean;
  title: ReactChild | string;
  /** it will assume the user already has a free plan and skip this one */
  skipFree?: boolean;
  /** Will make plan cards as wide as possible in the container */
  fullWidthCards?: boolean;
}

export default function UpgradeToPremium({
  hideDetails=false,
  title=<>Your Shortcut to filling your open roles.<br/>Free for everyone to try.</>,
  skipFree=false,
  fullWidthCards=false
}: UpgradeToPremiumProps): JSX.Element {
  const partner = Variables.isAuthenticated? Variables.user?.partner: {};
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Business");
  const [selectedPlanId, setSelectedPlanId] = useState("Talent-Magnet-GBP-Yearly");

  const [storedState, setStoredState, removeStoredState] = useStoredState(
    {name: "", icon: "", description: "", billingCycle: "", chargebeeIds: {}},
    'plan-info',
    'signup'
  );

  useEffect(() => {
    if (location.hash === "#interest") {
      const uri = window.location.toString();
      const clean_uri = uri.substring(0, uri.indexOf("#"));
      window.history.replaceState({}, document.title, clean_uri);
      if (!Object.keys(Variables.user.partner).length) {
        EventTracking.send({
          content_object: {
            object_id: Variables.user.pk,
            object_type: "User",
          },
          event_type: interestEvent.TALENT_SOLUTIONS,
        });
      }
    }
  }, []);

  const queries = [
    usePlansQuery(),
    useMyPremiumPartnerQuery({}, {enabled: Object.keys(partner).length >0 ? true : false}),
  ];

  const {data, isLoading, isSuccess} = combineQueries(queries);
  const [
    rawPlans,
    premiumPartnerData,
  ] = data;


  useEffect(() => {
    if (rawPlans) {
      if (selected_plan_id){
        openPaymentDialog(selected_plan_id);
      }
    }
  }, [rawPlans]);

  if (!isLoading) {
    if (premiumPartnerData?.plan_price_id) {
      return (
        <div className="col-12 mt-2">
          <SelectedPlan
            plan={createPlanBillingCycleMapping(rawPlans).find(plan => plan.id === premiumPartnerData.plan_price_id)}
            amount={premiumPartnerData.plan_amount/100}
          />
        </div>
      );
    }
    return (
      <>
        <PageHeader title={title} />
        <PlanOverview
          onPlanClick={(plan, paymentCycle) => {
            if (plan.isEnterprise) {
              window.open(`${window.location.origin}/demo/`);
            }
            else {
              if (!Variables.isAuthenticated) {
                if (plan.name != planNames.FREE) {
                  setStoredState(storedState => ({
                    ...storedState,
                    name: plan.name,
                    description: plan.description,
                    icon: plan.icon,
                    billingCycle: paymentCycle,
                    chargebeeIds: plan.id
                  }));
                }
                window.location.href = `${window.location.origin}/signup/recruiter/`;
              }
              else {
                if (plan.name == planNames.FREE) {
                  window.open(`${window.location.origin}/`);
                }
                else {
                  openPaymentDialog(plan.id[paymentCycle]);
                }
              }
            }
          }}
          plans={createPlanMapping(rawPlans).filter(plan => plan.isDisplay)}
          skipFree={skipFree}
          fullWidthCards={fullWidthCards}
        />
        {!hideDetails && (
          <>
            <HiringNote className="py-5" />
            <Heading size="h3">Compare plans & features</Heading>
            <ComparePlans plans={createPlanMapping(rawPlans).filter(plan => plan.isDisplay)} />
            <div className="py-5">
              <Heading size="h3">Used by the best teams to recruit</Heading>
              <MiniSuccessStoryContainer className="py-1" />
            </div>
            <TrySuperpower className="py-5" title="Try Superpower today" />
          </>
        )}
        <SingnUpLoginModal
          showSignUpModal={showSignUpModal}
          setShowSignUpModal={setShowSignUpModal}
          plan_id={selectedPlanId}
          plan_name={selectedPlan}
        />
      </>);
  }
  return <LoadingSpinner className="d-block m-auto"/>;
}

