export const locationType = {
  REGISTERED: "registered",
  TRADING: "trading",
  BRANCH: "branch"
}

export const locationPurpose = {
  BILLING: "billing",
  PLACEMENT: "placement"
}
