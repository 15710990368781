import React, {useState, useEffect, useContext, useMemo} from "react";
import classNames from "classnames";
import {Caption, Heading, Input, ButtonDropdown, Dropdown, Button} from "workfinder-components";
import {mergeClassNames} from "styles/utils";
import Modal from "components/shared/Modal/Modal";
import {RequestorProfile, Tag} from "./SkillReferralRequestCard";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./skillReferralRequestModal.module.scss";
import {CandidateDashboardContext} from "apps/CandidateDashboardContext";
import {useCandidateTechSkillQuery, useSkillReferralAssociationChoicesQuery} from "services/queries";
import PulseLoader from "react-spinners/PulseLoader";
import LoadingSpinner from "components/shared/LoadingSpinner";
import api from "services/v3";

const styles = mergeClassNames(modalTheme, baseStyles);

const OTHER_ORG = "Enter organisation manually";

interface ReferralRequest {
  uuid: string;
  requestor: string;
  receiver: string;
  receiver_email: string;
  requestor_first_name: string;
  feedback?: string;
  association_with_requestor?: string;
  organisation?: string;
  requestor_full_name: string;
  requestor_photo_url?: string;
  organisations: string[];
}

const SKILL_LEVEL_OPTIONS = [
  {name: "Inexperienced", code: "Inexperienced"},
  {name: "Getting started", code: "Getting started"},
  {name: "Intermediate", code: "Intermediate"},
  {name: "Advanced", code: "Advanced"},
  {name: "Mastery", code: "Mastery"},
  {name: "I don’t know", code: null},
];

const SelectSkillLevel = ({techSkills, onSkillLevelChange}) => {
  const [selectedValues, setSelectedValues] = useState({});

  if (techSkills.length === 0) {
    return "No skills available.";
  }

  const handleSkillLevelChange = (skillCode, level) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [skillCode]: level,
    }));

    onSkillLevelChange(skillCode, level);
  };
  return (
    <div className={styles.techSkills}>
      {techSkills.map(skill => {
        return (
          <ButtonDropdown
            id={skill.skill}
            key={skill.skill}
            className="mb-0 mr-2 text-nowrap"
            options={SKILL_LEVEL_OPTIONS}
            selectedValue={selectedValues[skill.skill]}
            setSelectedValue={(level) =>
              handleSkillLevelChange(skill.skill, level)
            }
          >
            {skill.skill}
          </ButtonDropdown>
        );
      })}
    </div>
  );
};

export default function SkillReferralRequestModal({isOpen, setIsOpen, setSubmittedReferralUuid}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [referralRequest, setReferralRequest] = useState<ReferralRequest>();
  const [selectedSkillLevels, setSelectedSkillLevels] = useState({});
  const [relationship, setRelationship] = useState();
  const [organisation, setOrganisation] = useState();
  const [otherOrg, setOtherOrg] = useState();
  const [feedback, setFeedback] = useState("");
  const {skillReferralRequests} = useContext(CandidateDashboardContext);
  const {data: relationshipOptions} = useSkillReferralAssociationChoicesQuery({placeholderData: []});
  const {data: techSkills, isFetched: isTechSkillsFetched} = useCandidateTechSkillQuery({user: referralRequest?.requestor}, {enabled: !!referralRequest?.uuid});


  const handleSkillLevelChange = (skillCode, level) => {
    setSelectedSkillLevels((prevLevels) => ({
      ...prevLevels,
      [skillCode]: level,
    }));
  };

  const handleSubmit = () => {
    const data = {
      skills: Object.keys(selectedSkillLevels).map((skillCode) => ({
        skill: skillCode,
        level: selectedSkillLevels[skillCode],
      })),
      association_with_requestor: relationship,
      organisation: otherOrg ?? organisation,
      feedback,
    };

    setIsLoading(true);

    api.updateSkillsReferralRequest(referralRequest.uuid, data)
      .then((response) => {
        setIsOpen(false);
        setSubmittedReferralUuid(referralRequest.uuid);
      })
      .catch((error) => {
        console.error("Error updating referral request:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const urlSearchParams = new URLSearchParams(window.location.search);
  const ref_req = urlSearchParams.get("ref_req");

  useEffect(() => {
    if (skillReferralRequests && isOpen) {
      const request = skillReferralRequests.find(({uuid}) => uuid === ref_req);
      if (request) {
        setReferralRequest(request);
        urlSearchParams.delete("ref_req");
        const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${window.location.hash}`;
        window.history.replaceState({}, "", newUrl);
      }

      if (window.location.hash.includes("skillreferral")) {
        if (!isOpen) {
          setIsOpen(true);
        }
        window.location.hash = "";
      }
    }
  }, [isOpen, skillReferralRequests, ref_req, urlSearchParams]);

  const isSubmitDisabled = useMemo(() => {
    if (!relationship || !organisation) {
      return true;
    }
    if (Object.entries(selectedSkillLevels).length === 0) {
      return true;
    }
    if (organisation === OTHER_ORG && !otherOrg) {
      return true;
    }
    return false;
  }, [relationship, organisation, selectedSkillLevels, otherOrg]);

  return (
    <Modal
      id="skill-referral-request"
      theme={styles}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      className={classNames(styles.mobileBottom, styles.extraTall)}
    >
      <div className={styles.cardContent}>
        <Tag/>
        <RequestorProfile
          requestor={referralRequest?.requestor}
          requestor_photo_url={referralRequest?.requestor_photo_url}
          requestor_full_name={referralRequest?.requestor_full_name}
          includeFor
        />
        {isTechSkillsFetched ? (
          <>
            <Heading size="h5" className="mb-0">
            Select the skill level you believe {referralRequest?.requestor_first_name} has in the following skills:
            </Heading>
            <SelectSkillLevel
              techSkills={techSkills}
              onSkillLevelChange={handleSkillLevelChange}
            />
          </>
        ) : (
          <LoadingSpinner className="my-4"/>
        )
        }
        <Heading size="h5" className="mb-0">
          How do you know {referralRequest?.requestor_first_name}?
        </Heading>
        <div className={styles.relationshipContainer}>
          <Dropdown
            className="mb-0 flex-grow-1"
            value={relationship}
            setValue={setRelationship}
            options={relationshipOptions.filter(({label}) => label)}
            placeholder="Select relationship*"
          />
          <strong>at</strong>
          {organisation === OTHER_ORG ? (
            <Input
              className={styles.input}
              placeholder="Enter organisation*"
              value={otherOrg}
              onChange={setOtherOrg}
            />
          ) : (
            <Dropdown
              className="mb-0 flex-grow-1"
              value={organisation}
              setValue={setOrganisation}
              options={referralRequest?.organisations ? [...referralRequest.organisations, OTHER_ORG] : []}
              placeholder="Select organisation*"
            />
          )}
        </div>
        <Heading size="h5" className="mb-0">
          Could you share more about your experience working with {referralRequest?.requestor_first_name}?
        </Heading>
        <Input
          className="Add your experience here"
          value={feedback}
          onChange={setFeedback}
          long
        />
        <Heading size="h6" className="mb-0">
          Your feedback will be shared with {referralRequest?.requestor_full_name} to help them grow.
        </Heading>
        <Caption size="large">
          Your feedback provides valuable insights that can help them improve and refine their skillset. It&apos;s a small gesture that goes a long way in supporting their career journey.
          <br/>
          <br/>
          By contributing a reference, you support the Superpower community by helping others thrive in our shared pursuit of skill development and career success.
        </Caption>

        <div className="d-flex justify-content-end">
          <Button
            className="mr-4 mb-0"
            kind="tertiary"
            onClick={() => {
              setSelectedSkillLevels({});
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="mb-0"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Submit"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
