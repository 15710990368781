import React from "react";
import {Content} from "components/shared/Slate";
import classNames from "classnames";
import styles from "./SkillSurvey.module.scss";
import Modal from "./Modal";
import flexedBicep from "/static/workfinder/images/Flexed-Bicep.svg";
import creativityCertificate from "/static/workfinder/images/SkillBuilderCreativity.png";
import aimingHighCertificate from "/static/workfinder/images/SkillBuilderAiming-High.png";
import leadershipCertificate from "/static/workfinder/images/SkillBuilderLeadership.png";
import listeningCertificate from "/static/workfinder/images/SkillBuilderListening.png";
import problemSolvingCertificate from "/static/workfinder/images/SkillBuilderProblem-Solving.png";
import speakingCertificate from "/static/workfinder/images/SkillBuilderSpeaking.png";
import stayingPositiveCertificate from "/static/workfinder/images/SkillBuilderStaying-Positive.png";
import teamworkCertificate from "/static/workfinder/images/SkillBuilderTeamwork.png";


interface Props {
  open: boolean,
  onClose: () => void,
  skill: string,
}

const skill_certificate_thumbnail_map = {
  "Aiming-High": aimingHighCertificate,
  Creativity: creativityCertificate,
  Leadership: leadershipCertificate,
  Listening: listeningCertificate,
  "Problem-Solving": problemSolvingCertificate,
  Speaking: speakingCertificate,
  "Staying-Positive": stayingPositiveCertificate,
  Teamwork: teamworkCertificate,
};

export default function SkillSurveyModal({open, onClose, skill}: Props) {
  const skillName = skill.replace(" ", "-");

  return (
    <Modal className={styles.modal} id="skill-survey" onClose={onClose} open={open}>
      <Content className={styles.content}>
        <div className={styles.heading}>
          <img alt="flexed-bicep" src={flexedBicep}></img>
          <h3 className="">Nicely done!</h3>
          <p className="">Rock on</p>
        </div>
        <div className={styles.description}>
          <p className={styles.skill}>
            Thank you for sharing where you are at with {skill}.
          </p>
          <p>
            We have updated your profile: This drives the recommendations for courses, masterclasses, mentors and other roles for you and our brilliant briefings about you sent to recruiters you apply to.
          </p>
          {skill_certificate_thumbnail_map[skillName] &&
            <img src={skill_certificate_thumbnail_map[skillName]}></img>
          }
        </div>
        <div className={styles.learnMore}>
          <button className="btn btn-primary mt-3">
            <a href={`https://www.skillsbuilder.org/universal-framework/${skillName}`}>Learn more about {skill}</a>
          </button>
        </div>
      </Content>
    </Modal>
  );
}
