import api from "services/v3";
const SEARCH_URL = "https://duedil.io/v4/search/companies.json";
const COMPANY_URL = "https://duedil.io/v4/company/";

async function fetchDueDilAPI(url, options) {
  const {method = "POST", data} = options || {};
  var myHeaders = new Headers();
  myHeaders.append("X-AUTH-TOKEN", "jtnvbVlAso7zoP7dCyU8ZeVobNhG2mZi");
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(data);
  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: data?raw:null,
  };

  const promise = window.fetch(url, requestOptions);
  return promise.then(async response => {
    if (!response.ok) {
      throw response;
    }
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.split(";")[0] === "application/json") {
      return promise.then(response => response.json());
    }
    return promise;
  });
}

export default class duedil {
  static search(searchString) {
    const url = SEARCH_URL;
    return fetchDueDilAPI(url, {
      data: {criteria: {
        countryCodes: {
          mode: "any",
          values: ["GB"],
        },
        name: searchString,
        simplifiedStatuses: {
          values: [
            "Active",
          ],
        },
      },
      },
    });
  }

  static async getIndustryData(countryCode, companyId) {
    const url = COMPANY_URL + countryCode.toLocaleLowerCase() + "/" + companyId +"/industries.json";
    return fetchDueDilAPI(url, {
      method: "GET",
    }).then(result => result.industries);
  }

  static async getCompanyData(countryCode, companyId) {
    const company =  await api.getCompanyData({
      endpoint: "company_data",
      "company-id": companyId,
      "country-code": countryCode,
    });
    company.industries = await api.getCompanyData({
      endpoint: "company_industries",
      "company-id": companyId,
      "country-code": countryCode,
    }).then(result => result.industries);
    return company;
  }

}

