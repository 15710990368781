export const STORY_TYPE = {
  CANDIDATE: "CAN",
  HOST: "HOS"
}

export const STORY_TYPE_URL_REVERSE_MAP = {
  "member": STORY_TYPE.CANDIDATE,
  "recruiter": STORY_TYPE.HOST
}

export const STORY_TYPE_MAP = {
  [STORY_TYPE.CANDIDATE]: "Talent",
  [STORY_TYPE.HOST]: "Recruiter"
}

export const STORY_TYPE_URL_MAP  = {
  [STORY_TYPE.CANDIDATE]: "member",
  [STORY_TYPE.HOST]: "recruiter"
}

export const EXTERNAL_URL_TYPE = {
  VIDEO : "VID",
  LINKEDIN_POST: "LIN"
}

export interface StoryCardProps {
  /** Success story object */
  story: SuccessStory
}

export interface SuccessStory {
  /** success story fields */
  author_full_name: string;
  author_first_name: string;
  project_uuid?: string;
  project_name: string;
  company_logo?: string;
  company_name: string;
  story_type: string;
  external_url_type?: string
  external_url_thumbnail?: string;
  external_url?: string;
  quote: string;
  company_description: string;
  username: string;
  candidate_photo?: string;
  host_photo?: string;
  host_full_name?: string;
  host_title?: string;
  candidate_linkedin_url?: string;
  host_linkedin_url?: string;
}
