import React, {useState} from "react";
import ReactDOM from "react-dom";
import {status} from "util/interview/constants";
import {Button} from "workfinder-components";
import ClickAwayListener from "react-click-away-listener";
import {usePopper} from "react-popper";
import DropdownMenu from "components/shared/DropdownMenu";
import styles from "./editInterviewDropdown.module.scss";
import {FEATURES} from "util/placement/activeFeatures";
import {meetingLinkModalInterface} from "components/shared/Modal/AddMeetingLinkModal";
import {offerInterviewModalInterface} from "components/dashboard/OfferInterviewModal";

const EditInterviewDropdown = ({interview, candidate, activeFeatures}) => {
  const interviewId = interview.uuid;
  const menuId = `${interviewId}-edit-menu`;
  const menuButtonId = `${interviewId}-edit-menu-button`;
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {placement: "bottom"},
  );
  const closeDropdown = () => setOpen(false);

  const isAddMeetingLinkFeature = activeFeatures.has(FEATURES.ADD_MEETING_LINK);
  const isOfferInterviewFeature = activeFeatures.has(FEATURES.OFFER_INTERVIEW);
  const dropdownItems = [
    {
      label: "Reschedule",
      onClick: isOfferInterviewFeature ? e => {
        offerInterviewModalInterface.dispatch({
          open: true,
          hostReschedule: true,
          candidate: {...candidate, interview},
        });
        closeDropdown();
      }: () => {},
      disabled: !isOfferInterviewFeature,
    },
  //   {
  //     label: "Cancel event",
  //     onClick: isOfferInterviewFeature ? e => {
  //     }: () => {},
  //     disabled: !isOfferInterviewFeature
  //   }
  ];
  if (interview.status === status.INTERVIEW_OFFERED || interview.status === status.MEETING_LINK_ADDED || interview.status === status.INTERVIEW_ACCEPTED) {
      dropdownItems.unshift({
        label: "Edit location",
        onClick: isAddMeetingLinkFeature ? e => {
          meetingLinkModalInterface.dispatch({
            open: true,
            candidate: {...candidate, interview},
          });
          closeDropdown();
        } : () => {},
        disabled: !isAddMeetingLinkFeature
      });
    }

  let showEditButton = false;
  if([status.INTERVIEW_ACCEPTED, status.MEETING_LINK_ADDED, status.INTERVIEW_OFFERED, status.INTERVIEW_TIME_REQUESTED, status.RESCHEDULE_REQUIRED].includes(interview.status)
      && dropdownItems.length > 0)
    showEditButton = true;

  return (
    showEditButton &&
    <>
      <Button
        kind="tertiary"
        className={styles.interviewEditButton}
        id={menuButtonId}
        onClick={() => setOpen(!open)}
        aria-haspopup="true"
        aria-expanded="false"
        role="button"
        ref={setReferenceElement}>
          <span className="material-icons-outlined" style={{fontSize: "0.75rem", fontWeight: "normal"}}>border_color</span>
          Edit
      </Button>
      {open &&
        ReactDOM.createPortal(
          <ClickAwayListener onClickAway={(e) => {
            if(e.target != referenceElement) {
              setOpen(false);
            }
          }}>
            <DropdownMenu
              id={menuId}
              toggleId={menuButtonId}
              ref={setPopperElement}
              items={dropdownItems}
              style={{
                display: "block",
                ...popperStyles.popper,
              }}
              {...attributes.popper}
            />
          </ClickAwayListener>,
          document.body
        )
      }
    </>
  );
}

export default EditInterviewDropdown;
