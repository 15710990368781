import React from "react";
import classNames from "classnames";
import Button from "components/shared/Button";
import {Feature} from "components/shared/PlanOverview";

import styles from "./selectedPlan.module.scss";

export interface PlanBillingCycle {
  /** The name of the plan. */
  name: string;
  /** The price of the plan in GBP (£). */
  price: number;
  /** Billing cycle */
  billingCycle: string;
  /** If this is enterprise or not */
  isEnterprise: boolean,
  /** A short decription of the plan. */
  description: string;
  /** Chargebee id */
  id: string;
  /** A secondary decription of the plan. */
  descriptionSecondary: string;
  /** Button text */
  buttonText: string;
  /** Text before feature listing */
  featureTitle: string;
  /** The features included in the plan. */
  features: Feature[];
  /** Text after feature listing */
  featureNote: object;
  /** Any promotional information, e.g. discounts. */
  isPromoted: boolean,
  promoText?: string,
  /** Yearly discount if available */
  yearlyDiscount?: number;
  /** Free trial days */
  freeTrial?: number;
}


export interface SelectedPlanProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  className?: string;
  plan: PlanBillingCycle;
  amount: number;
}


export default function SelectedPlan({className, plan, amount , ...extraProps}: SelectedPlanProps): JSX.Element {
  return (
    <div
      className={classNames(styles.planCard, className)}
      key={`plan-${plan.name}`}
      {...extraProps}
    >
      <div className="d-flex flex-column">
        <h2 className={styles.partnerPlan}>Partner Plan</h2>
        <h3 className={classNames("mb-4", styles.planTitle)}>{plan.name}</h3>
        <div className={styles.description}>{plan.description}</div>
      </div>

      <div className="d-flex flex-column align-items-end">
        <div className={styles.priceContainer}>
          <strong className={styles.price}>£{amount}</strong>/mo
        </div>
        <div className={styles.billingContainer}>
          <span>Billed {plan.billingCycle}</span>
          <span className={classNames("my-2", styles.excludingVAT)}>excl. VAT</span>
        </div>
        <Button
          baseComponent="a"
          className={classNames("mt-auto", styles.button)}
          href="/chargebee/self-serve/"
          kind="solid"
          style={{background: "var(--off-black)"}}
        >Upgrade</Button>
      </div>
    </div>
  );
}
