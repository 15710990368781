import React, { useState, useEffect } from "react";
import styles from "../host-setup/index.module.scss";
import classNames from "classnames";
import { Heading, Subtitle, Input, Button, LocationSearch } from "workfinder-components"
import * as yup from "yup";
import {Map} from "immutable";
import {yupErrorToMapping} from "util/errors";
import useTrackedState from "hooks/useTrackedState";
import api from "services/v3";
import EmailBox from "components/shared/EmailBox/index";
import PulseLoader from "react-spinners/PulseLoader";
import ThankYouModal from "components/shared/Modal/ThankYouModal";
import {StepBars} from "../host-setup/index"
import Tooltip from "components/shared/Tooltip";

const commonSchema = {
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .nullable()
    .label("Last Name"),
};
const step1Schema = yup.object().shape(commonSchema);
const step2Schema = yup.object().shape({
    ...commonSchema,
    current_location: yup
      .object()
      .required("Current location is a required field")
      .label("Current location"),
})

const CandidateSetup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userErrors, setUserErrors] = useState<Record<string, string[]>>({});
  const [referralKey, setReferralKey] = useState("");
  const [user, setUser, setReferenceUser, hasUserChanged] = useTrackedState(Map({
    first_name: "",
    last_name: "",
    current_location: ""
  }));
  const [candidateUuid, setCandidateUuid] = useState(null);
  const url = new URL(window.location.href);
  const user_uuid = url.pathname.split("/").slice(-1)[0];
  const [header, setHeader] = useState("Welcome!");
  const [label1, setLabel1] = useState("First things first, tell us a bit about yourself.");
  const [label2, setLabel2] = useState("What is your name?");

  const redirect = () => {
    window.location.href = "/";
  }

  async function loadData() {
    try {
      setIsLoading(true);
      const user = await api.getExpandedSelf();
      setUser(data => data.set("first_name", user.first_name));
      setUser(data => data.set("last_name", user.last_name));
      setUser(data => data.set("current_location", user.candidate.current_location));
      setCandidateUuid(user.candidate.uuid);
      if(!!user.referral_key) {
        setReferralKey(user.referral_key);
      }
      else {
        const referralKey = await api.createUserReferralSlug();
        setReferralKey(referralKey);
      }
      setIsLoading(false);
    }
    catch {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    validate(true);
  }, [user]);

  useEffect(() => {
    loadData();
  }, []);

  async function validate(abortEarly, forcedCurrentStep: number | undefined = undefined) {
    const actualcurrentStep = forcedCurrentStep ?? currentStep;
    const is_valid = await (
      actualcurrentStep == 1 ? step1Schema : step2Schema
    ).validate(user.toJSON(), {strict: true, abortEarly: abortEarly})
      .then(() => {
        setUserErrors({});
        if (actualcurrentStep == 1) {
          setCurrentStep(2);
          validate(true, 2);
        }
        else {
          setIsDisabled(false);
        }
        return true;
      })
      .catch(e => {
        setIsDisabled(true);
        setUserErrors(yupErrorToMapping(e));
        return false;
      });
    return is_valid;
  }

  async function submitForm() {
    const is_valid = await validate(false);
    if(is_valid) {
      setIsLoading(true);
      const onSuccess = () => {
        setCurrentStep(3);
        setIsLoading(false);
        setReferenceUser(Map());
      };
      await api.updateUser({
        first_name: user.get("first_name"),
        last_name: user.get("last_name"),
      });
      await api.updateCandidate(candidateUuid, {
        current_location: user.get("current_location")
      });
      onSuccess();
    }
  }

  function handleUserChange(value, name) {
    setUser(data => data.set(name, value));
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-9 col-md-8 col-centered">
          {isLoading && <div className={styles.dummySteps}><hr/></div>}
          {!isLoading && <StepBars currentStep={currentStep} totalSteps={3}/>}
          {currentStep <= 2 &&
            <>
              <Heading size="h2" className={styles.header}>{header}</Heading>
              <Subtitle size="large" className="mb-4">{label1}</Subtitle>
              <Subtitle size="large">{label2}</Subtitle>
              <div className="row align-items-end">
                <Input
                  placeholder="First name"
                  className={classNames(styles.input, "col-6")}
                  value={user.get("first_name", "")}
                  onChange={(value, e) => handleUserChange(value, "first_name")}
                  disabled={isLoading}
                />
                <Input
                  placeholder="Last name"
                  className={classNames(styles.input, "col-6")}
                  value={user.get("last_name", "")}
                  onChange={(value, e) => handleUserChange(value, "last_name")}
                  disabled={isLoading}
                />
              </div>
            </>
          }
          {currentStep == 2 &&
            <>
              <Subtitle size="large" className="d-flex">Where are you currently based?<Tooltip>📍 Your location is essential for our personalized approach! It helps us recommend local opportunities aligned with your skills and preferences, among others. Don't worry; you can further customize your location preferences at any time later.</Tooltip></Subtitle>
              <div className="pb-4">
                <LocationSearch
                  value={user.get("current_location")}
                  onChange={(value) => {
                    const obj = Object.fromEntries(
                      Object.entries(value).map(([key, value]) => [key, value ?? ""])
                    );
                    handleUserChange(obj, "current_location")
                  }}
                  name="current_location"
                  placeholder="Search location"
                  url={Variables.urls["api-v3"].location_search}
                  searchField="q"
                  resultField = "results"
                  apiDataMap={{
                    countryCode : "country_code",
                    country: "country_name",
                    state: "state_name",
                    city: "name"
                  }}
                  valueMap={{
                    countryCode : "address_country",
                    country: "address_country_name",
                    state: "address_region",
                    city: "address_city"
                  }}
                  className="w-100"
                />
              </div>
            </>
          }
          {currentStep == 2 &&
            <Button disabled={isDisabled || isLoading} onClick={submitForm} className={styles.button}>
              {isLoading ? <PulseLoader color="#fff" margin={2} size={6}/> : "Continue"}
            </Button>
          }
          {currentStep == 3 &&
            <InviteReferral referralKey={referralKey} redirect={redirect}/>
          }
        </div>
      </div>
    </>
  )
}

export const InviteReferral = ({
  referralKey,
  redirect,
  norewardMessage=false,
  noskipbutton=false
}) => {
  const [invitationEmails, setInvitationEmails] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseText1, setResponseText1] = useState("");
  const [responseText2, setResponseText2] = useState("");
  const [rewardHired, setRewardHired] = useState(null);
  const [rewardJobFilled, setRewardJobFilled] = useState(null);
  const url = new URL(window.location.href);
  const link = `${url.origin}/signup/member/${referralKey}`

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  };

  useEffect(() => {
    api.getConfigValue({key : "reward_hired,reward_jobfilled"}).then(response => {
      const rewardHiredKey = Object.keys(response).find(
        (key) => key.toLowerCase() === "reward_hired");
      if(rewardHiredKey) {
        if(response[rewardHiredKey] > 0)
          setRewardHired(response[rewardHiredKey]);
      }

      const rewardJobFilledKey = Object.keys(response).find(
        (key) => key.toLowerCase() === "reward_jobfilled");
      if(rewardJobFilledKey) {
        if(response[rewardJobFilledKey] > 0)
          setRewardJobFilled(response[rewardJobFilledKey]);
      }
    })
  }, [])

  const sendInvitations = () => {
    setIsLoading(true);
    const data = invitationEmails.map(e => { return { email: e } });
    api.createReferralInvitation(data).then(response => {
      let invitationEmailsSuccessLength = response.length;
      if(invitationEmails.length > invitationEmailsSuccessLength) {
        const failureLength = invitationEmails.length - invitationEmailsSuccessLength;
        setResponseText2(`Invitation could not be sent to following ${failureLength == 1 ? "email as he/she is already user" : "emails as they are already users"}, of Superpower: ${invitationEmails.filter(e => !response.map(r => r.email).includes(e)).join(", ")}`)
      }
      if(invitationEmailsSuccessLength == 0) {  //Failure
        setResponseTitle("❎ Invitation could not be sent!")
      }
      else {  //Success
        setResponseTitle("✅ Your invitation is on it's way!");
        setResponseText1(`You have successfully sent your invitation to ${invitationEmailsSuccessLength} ${invitationEmailsSuccessLength > 1 ? "contacts" : "contact"}.`);
      }
      setIsSuccess(true);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      //ToDo: Handle response
    });
  }

  const clear = () => {
    setInvitationEmails([]);
    setIsSuccess(false);
    setResponseText1("");
    setResponseText2("");
    setResponseTitle("");
  }

  return (
    <>
      {!norewardMessage && <>
        <Heading size="h2" className={styles.header}>Refer friends{(rewardHired || rewardJobFilled) && ", earn rewards!"}</Heading>
        <Subtitle size="large" className="mb-4">Refer a friend to Superpower{(rewardHired || rewardJobFilled) && " and earn"}{rewardHired && ` a $${rewardHired} Amazon gift card when they get hired`}{rewardHired && rewardJobFilled && ", or"}{rewardJobFilled && ` a $${rewardJobFilled} Amazon gift card if they create a job posting and hire through us`}.{(rewardHired || rewardJobFilled) && <Tooltip iconClassName="align-middle" clickable={true}>When three or more people sign up to Superpower using your link, youl'llqualify for rewards as their referrer. Learn more about our <a target="_blank" href="/referral-program/terms">Referral Program's Terms & Conditions</a>.</Tooltip>}</Subtitle>
      </>}
      {norewardMessage &&
        <Heading size="h4" >Share your invitation link</Heading>
      }
      <label className="form-label">Copy this invitation link to share with your network:</label>
      <div className="input-group flex-nowrap">
        <Input
          placeholder="Please enter your organisation name here"
          value={link}
          disabled={true}
          className={classNames(styles.input, styles.inviteLink)}
          aria-describedby="basic-addon2"
        />
        <Button size="small" onClick={handleCopyClick} className={styles.copyButton} id="basic-addon2">
          Copy
          {tooltipVisible && (
              <div className={styles.tooltip}>Link copied</div>
          )}
        </Button>
      </div>
      <EmailBox
        placeholder="Enter emails here"
        label="Or send them an invitation via email:"
        emails={invitationEmails}
        setEmails={(values) => {setInvitationEmails(values)}}
      />
      <Button disabled={isLoading || !(invitationEmails && invitationEmails.length > 0)} onClick={sendInvitations} className={styles.button}>{isLoading ? <PulseLoader color="#fff" margin={2} size={6}/> : "Send invitations"}</Button>
      {!noskipbutton && <Button kind="tertiary" onClick={redirect} className={classNames(styles.button, styles.skipButton)}>Skip for now</Button>}
      <ThankYouModal
        //@ts-ignore
        isSuccess={isSuccess}
        header={<Heading size="h4">{responseTitle}</Heading>}
        message={<Subtitle>{responseText1} {responseText2}</Subtitle>}
        onClose={clear}
        width={410}
        theme={styles}
      />
    </>
  )
}

export default CandidateSetup;
