import React, {forwardRef} from "react";
import classNames from "classnames";

import styles from "./button.module.scss";

type ButtonKinds = "gradient" | "green_text" | "link" | "link2" | "linkedin" | "primary" | "review" | "solid" | "review-solid" | "orange" | "offblack" | "white" | "blue-primary" | "secondary" | "tertiary" | "tertiary-orange";

type ButtonSizes = "large" | "medium" | "small";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  baseComponent?: "a" | "button";
  /** The kind of button to render. */
  kind?: ButtonKinds;
  /** Whether to round the button. */
  rounded?: boolean;
  /** Button Sizes. */
  size?: ButtonSizes;
};

const ROUNDED_KINDS: ButtonKinds[] = ["primary", "gradient", "linkedin", "review", "solid", "review-solid", "orange", "offblack", "white", "blue-primary", "secondary"];

const Button = forwardRef<any, Props>((props, ref): JSX.Element => {
  const {
    baseComponent="button",
    className,
    children,
    kind = "primary",
    size = "medium",
    rounded,
    ...buttonProps
  } = props;

  return React.createElement(
    baseComponent,
    {
      className: classNames(
        styles.button,
        styles[kind],
        styles[size],
        {[styles.rounded]: rounded || (ROUNDED_KINDS.includes(kind) && rounded !== false)},
        className,
      ),
      ref,
      ...buttonProps,
    },
    children,
  );
});
Button.displayName = "Button";

export default Button;
