import React from "react";
import _ from "lodash";
import {mergeClassNames} from "styles/utils";
import classNames from "classnames";
import baseStyles from "./courseRecommendationCard.module.scss";
import swiperStyles from "./swiperList.module.scss";
import activeStar from "/static/workfinder/images/candidates/star-active.svg";
import inActiveStar from "/static/workfinder/images/candidates/star-inactive.svg";
import {Button} from "workfinder-components"

const styles = mergeClassNames(baseStyles, swiperStyles);

export default function CourseRecommendationCard({className, handleClick, course}): JSX.Element {
  const type = "Online Course";
  const {
    accreditation,
    is_paid,
    image_url,
    name,
    introduction,
    rating,
    reviews,
    hours_per_week,
    duration,
    provider_logo_url,
  } = course;
  const footer = "View course details";
  return (
    <div
      className={classNames("d-flex flex-column", className, styles.cardRecommendation)}
      onClick={(e) => handleClick(e)}
      style={{cursor: "pointer"}}
    >
      <div className={styles.headerImg}>
        {!is_paid && <span className={styles.freeTag}>Free</span>}
        <img className={classNames("img-fluid", styles.img) } src={image_url}/>
      </div>
      <div className="row mt-3 align-items-center">
        <div className={classNames("col-6", styles.type)}>
          <span>{type}</span>
        </div>
        <div className="col-6">
          <img src={provider_logo_url} className={classNames(styles.providerLogoUrl, "float-right")} />
        </div>
      </div>
      <span className={styles.name}>{name}</span>
      <div className={classNames("d-flex flex-column", styles.recDescription)}>
        <div className="d-flex align-items-center">
          {rating && Array(5).
            fill(<img src={activeStar} alt="rating-star-active" width="12" height="12"/>, 0, rating).
            fill(<img src={inActiveStar} alt="rating-star" width="12" height="12" />, rating)}
          {rating && <span className={styles.ratingText}> {rating} {reviews && `(${reviews} reviews)`}</span>}
        </div>
        <p className={classNames("mt-2", styles.description) }>
          {introduction}
        </p>
        <div className="row mb-4">
          {duration && <div className={classNames("col-4", styles.flexColumn)}>
            <span className={styles.cardDescriptionTitle}>Duration</span>
            <span> {duration}</span>
          </div>}
          {hours_per_week && <div className={classNames("col-4", styles.flexColumn)}>
            <span className={styles.cardDescriptionTitle}>Weekly study</span>
            <span>{hours_per_week}</span>
          </div>}
          {accreditation && <div className={classNames("col-4", styles.flexColumn)}>
            <span className={styles.cardDescriptionTitle}>Accreditation</span>
            <span>{accreditation}</span>
          </div>}
        </div>
      </div>
      <div className={styles.footer}>
        <Button kind="secondary" onClick={(e) => handleClick(e)}>
          {footer}&nbsp;
          <i className="fas fa-arrow-right"></i>
        </Button>
      </div>
    </div>
  );
}
