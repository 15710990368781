import React from "react";
import styles from "./CompletionCertificate.module.scss";
import personIcon from "/static/workfinder/images/icons/person_icon_green.png";
import { Heading, Subtitle, Button } from "workfinder-components";
import { formatDateShort } from "util/date-time";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png"

export default function CompletionCertificate({certificate, showCompanyDetails = true, showPlacementLink = true}) {
  return (
    <div key={certificate.id} className={styles.myCertificate}>
      {
        showCompanyDetails &&
        <div className={styles.companyInfo}>
          <img className="img-fluid" src={certificate.company_logo_url ?? defaultCompanyLogo}/>
          <div className={styles.roleInfo}>
            <Heading size="h5">{certificate.project_name}</Heading>
            <Subtitle size="small">{certificate.company_name}</Subtitle>
            <Subtitle size="small">{formatDateShort(certificate.start_date)}{certificate.end_date && ` - ${formatDateShort(certificate.end_date)}`}</Subtitle>
          </div>
        </div>
      }
      <div>
        <img className="img-fluid" src={certificate.image_file} />
      </div>
      {
        showCompanyDetails &&
        <div className={styles.companyInfo}>
          <img className="img-fluid" src={certificate.host?.photo_thumbnail?.avtar ?? personIcon}/>
          <div className={styles.roleInfo}>
            <Heading size="h5">{certificate.host.full_name}</Heading>
          </div>
        </div>
      }
      <div className={styles.buttonContainer}>
        <Button kind="secondary" onClick={() => window.open(`/certificates/${certificate.uuid}`)}>View certificate</Button>
        { showPlacementLink && <Button kind="secondary" onClick={() => window.open(`/projects/${certificate.project_uuid}`)} >View placement</Button> }
      </div>
    </div>
  );
}
