import React, {useState} from "react";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";

import Calendar from "components/shared/Form/Calendar";
import {ButtonGroup, Content} from "components/shared/Slate";
import Checkbox from "components/shared/Form/Checkbox";

import useFormValidation from "hooks/useFormValidation";
import styles from "./acceptModal.module.scss";
import Modal from "./Modal";


function formatDate(date) {
  return date ? moment(date).format("YYYY-MM-DD") : date;
}

function AcceptModal(props) {
  const {acceptClicked, open, onClose} = props;
  const [optIn, setOptIn] = useState(false);
  const [offer, setOffer] = useState({
    startDate: null,
    endDate: null,
    duration: "",
    notes: "",
  });
  const [viewDate, setViewDate] = useState(new Date());

  const [changed, errors, isComplete, handleSubmit] = useFormValidation(offer, onSubmit, "accept-modal-form", dateValidator);

  function onSubmit(data) {
    // The date format isn't quite right for dates, expecting strings not `Date` objects.

    acceptClicked(_.merge(_.clone(data), {
      startDate: formatDate(offer.startDate),
      endDate: formatDate(offer.endDate),
    }));
  }

  function dateValidator(inputEl) {
    if (inputEl.name === "startDate" && offer.startDate === undefined || offer.startDate === null) {
      return false;
    }

    if (inputEl.name === "endDate" && offer.endDate === undefined || offer.endDate === null) {
      return false;
    }

    return true;
  }

  function handleStartDateChange(value) {
    if (offer.endDate !== null && value > offer.endDate) {
      setOffer(offer => _.merge(_.clone(offer), {startDate: value, endDate: null}));
      setViewDate(value);
    }
    else {
      setOffer(offer => _.set(_.clone(offer), "startDate", value));
      // If an end date hasn't been set yet, the end date view will jump to the
      // start date.
      if (offer.endDate === null) {
        setViewDate(value);
      }
    }
  }

  function handleEndDateChange(value) {
    setOffer(offer => _.set(_.clone(offer), "endDate", value));
  }


  const acceptButtonStyle = classNames(
    "btn btn-primary", {disabled: !isComplete || !optIn},
  );
  const startDateInvalidFeedback = classNames(
    "invalid-feedback", {"d-block !important": changed.has("startDate") && errors.has("startDate")},
  );
  const endDateInvalidFeedback = classNames(
    "invalid-feedback", {"d-block !important": changed.has("endDate") && errors.has("endDate")},
  );

  return (
    <Modal name="acceptModal" open={open} onClose={onClose}>
      <form id="accept-modal-form"  className={styles.form} onSubmit={handleSubmit}>
        <Content className={styles.content}>
          <h1 className={styles.title}>MAKE AN OFFER</h1>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-sm mb-3">
                  <p className={styles.sectionTitle}>Starting date</p>
                  <Calendar
                    appendTo={document.body}
                    value={offer.startDate}
                    inputId="startDate"
                    name="startDate"
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    minDate={new Date()}
                    required
                    onChange={handleStartDateChange}
                    baseZIndex={1050}
                    touchUI
                  />
                </div>
                <div className="col-sm mb-3">
                  <p className={styles.sectionTitle}>End date</p>
                  <Calendar
                    appendTo={document.body}
                    value={offer.endDate}
                    inputId="endDate"
                    name="endDate"
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    minDate={offer.startDate || new Date()}
                    required
                    onChange={handleEndDateChange}
                    onViewDateChange={e => setViewDate(e.value)}
                    viewDate={viewDate}
                    baseZIndex={1050}
                    touchUI
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={startDateInvalidFeedback}>Please choose a start date.</div>
                  <div className={endDateInvalidFeedback}>Please choose an end date.</div>
                </div>
              </div>
              <div className={styles.paidSection}>
                <div>
                  <p className={styles.sectionTitle}>Additional notes to candidate</p>
                  <textarea value={offer.notes}
                    className={classNames(styles.textArea, "form-control")}
                    placeholder="Please detail any additional information about the offer"
                    onChange={e => setOffer(_.set(_.clone(offer), "notes", e.target.value ))}
                  />
                </div>
              </div>
              <div className={styles.optIn}>
                <Checkbox
                  id="acceptOptIn"
                  name="optIn"
                  required
                  checked={optIn}
                  theme={styles}
                  onChange={setOptIn}
                />
                <label className={styles.optInlabel} htmlFor="acceptOptIn">
                  I understand if the candidate accepts this offer I am
                  agreeing to pay a placement fee to Superpower in line with{" "}
                  <a href="https://superpower.tech/commercial-terms/">the Commercial Terms</a>.
                </label>
              </div>
            </div>
          </div>
        </Content>
        <ButtonGroup>
          <button type="button" className={styles.cancelBtn} onClick={onClose}>Cancel</button>
          <button type="submit" className={acceptButtonStyle}>Make an offer</button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

AcceptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  acceptClicked: PropTypes.func.isRequired,
  association: PropTypes.shape({
    location: PropTypes.shape({
      address_unit: PropTypes.string,
      address_building: PropTypes.string,
      address_street: PropTypes.string.isRequired,
      address_city: PropTypes.string.isRequired,
      address_country: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      address_postcode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AcceptModal;
