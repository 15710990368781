import React, {useState} from "react";
import {Button, Input} from "workfinder-components";
import RequestCard from "./AbstractCard";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";


const LinkedInRequest = ({isSubmitted, submitFile, documentRequest, setCloseCard}):JSX.Element => {
  const [linkedinUrl, setLinkedInUrl] = useState("");
  const [validationError, setValidationError] = useState("");
  const [showError, setShowError] = useState(false);
  const {project, project_name, project_company_name, project_company_logo} = documentRequest;
  const sendLinkedInUrl = (url) => {
    if (isValidLinkedInUrl(url)) {
      // URL is valid, proceed with sending
      submitFile(url);
    }
    else {
      // URL is not valid, display an error message
      setValidationError("Please enter a valid LinkedIn profile URL");
      setShowError(true);

      // Hide the error message after 3 seconds
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const isValidLinkedInUrl = (url) => {
    // Regular expression pattern for a valid LinkedIn URL
    const linkedinUrlPattern = /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+(\/)?$/i;
    return linkedinUrlPattern.test(url);
  };

  const requestCardProps = {
    tag: isSubmitted ? "req-li-shared" : "req-li-pending",
    title: project_name,
    subtitle: project_company_name,
    image: project_company_logo || defaultCompanyLogo,
    link: Variables.urls.projects.detail.replace("<uuid:uuid>", project),
  };

  if (isSubmitted) {
    return (
      <RequestCard {...requestCardProps} tag="req-li-shared" onClose={() => setCloseCard()}>
        <p>Thank you! We have shared your LinkedIn profile with the employer. </p>
      </RequestCard>
    );
  }

  return (
    <RequestCard
      {...requestCardProps}
      tag="req-li-pending"
    >
      <p>Exciting update! A recruiter is reviewing your application and would like to see your LinkedIn profile.</p>
      <p>Boost your chances by sharing it with us! 😊</p>
      <Input
        placeholder="e.g. www.linkedin.com/in/jane"
        onChange={setLinkedInUrl}
      />
      {showError && <div className="text-danger pb-3">{validationError}</div>}
      <Button
        className="w-100"
        onClick={() => sendLinkedInUrl(linkedinUrl)}
        disabled={!linkedinUrl}
      >
          Share LinkedIn
      </Button>
      <Button
        baseComponent="a"
        className="w-100 pt-2 mb-0"
        kind="tertiary"
        href="https://www.topuniversities.com/student-info/careers-advice/five-reasons-why-every-student-should-be-linkedin"
        target="_blank"
        rel="noreferrer"
      >
          I don&apos;t have LinkedIn
      </Button>

    </RequestCard>
  );
};

export default LinkedInRequest;
