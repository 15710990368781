import React, { useState } from "react";
import api from "services/v3";
import Modal from "components/shared/Modal/Modal";
import styles from "styles/modals/flat.module.scss";
import { Button, Heading, Subtitle } from "workfinder-components";
import PulseLoader from "react-spinners/PulseLoader";

const CloneProjectModal = ({projectUUID, roleName, onClose, open, refetch}) => {
  const [isLoading, setIsLoading] = useState(false);

  const cloneProject = () => {
    setIsLoading(true);
    api.cloneProject({
      project: projectUUID,
    }).then((data) => {
      setIsLoading(false);
      onClose();
      refetch();
    });
  };

  return (<>
    <Modal
      id="resume-role-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={styles.mobileBottom}
    >
      <div className={styles.header}>
        <Heading size="h4" className="align-items-start">Your role {roleName} will be cloned, do you want to proceed?</Heading>
      </div>
      <div className={styles.content}>
        <Subtitle>💫 Don't forget to resume marketing for the cloned role.</Subtitle>
        <Button
          className="mt-3 w-100"
          kind="primary"
          onClick={cloneProject}
          type="button"
        >
          {isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Clone role"}
        </Button>
      </div>
    </Modal>
  </>);
};

export default CloneProjectModal;
