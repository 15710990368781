import React, {useState} from "react";
import classNames from "classnames";
import {mergeClassNames} from "styles/utils";

import baseStyles from "./pillList.module.scss";

interface Props {
  /** A CSS class used to style the container element. */
  className?: string;
  /** Whether pills should have a delete option. */
  enableDelete?: boolean;
  /** The name of the form control. */
  name?: string;
  /** A callback to call when the value is saved. */
  onChange?: (value: string[], name: string | undefined) => void;
  /** An object to restyle the element, mirroring the style element. */
  theme?: {[key: string]: string};
  /** The value of the form control. */
  value: string[];
}

export default function PillList(props: Props): JSX.Element {
  const {className, enableDelete, name, onChange, theme, value} = props;
  const styles = mergeClassNames(baseStyles, theme);
  const [hoveringOverDelete, setHoveringOverDelete] = useState(false);

  function handleRemove(clickedValue) {
    onChange?.(value.filter(item => item !== clickedValue), name);
  }

  return (
    <ul className={classNames(styles.list, className, {[styles.enableDelete]: enableDelete})}>
      {
        value.map(item =>
          <li key={item} className={classNames(hoveringOverDelete, styles.hoveringOverDelete)}>
            {item}
            {
              enableDelete &&
              <i
                className="pi pi-times"
                onClick={() => handleRemove(item)}
                onMouseEnter={() => setHoveringOverDelete(true)}
                onMouseLeave={() => setHoveringOverDelete(false)}
              />
            }
          </li>,
        )
      }
    </ul>
  );
}
