import React from "react";
import PropTypes from "prop-types";

import styles from "./projectTemplateList.module.css";
import ProjectTemplateCard from "./ProjectTemplateCard";

function ProjectTemplateList({data}) {
  return (
    <div className={styles.container}>
      {
        data.map(projectType =>
          <ProjectTemplateCard
            key={projectType.uuid}
            data={projectType}
          />,
        )
      }
    </div>
  );
}

ProjectTemplateList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ProjectTemplateList;
