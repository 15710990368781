import React, {useRef, useState, useEffect} from "react";
import classNames from "classnames";
import styles from "./InterviewFeedbackCard.module.scss";
import {Heading, Button, Subtitle, Caption} from "workfinder-components";
import api from "services/v3";
import {formatDateDurationRange, formatDayMonthTimeRange, getTimeZoneAbbr} from "util/date-time";
import { InterviewFeedbackModal } from "./InterviewFeedbackTrigger";
import { candidateFeedback, hostFeedback } from "util/interview/constants";
import { UserType } from "util/users/constants";
import DefaultPhoto from "/static/workfinder/images/generic-photo.png";
import PulseLoader from "react-spinners/PulseLoader";

const CardHeaderCandidate = ({interviewDate, placmentUuid, candidate={}, project={}}) => {
    const {name: role, uuid} = project;
    const roleURL = Variables.urls.projects.detail.replace("<uuid:uuid>", uuid);
    const applicationUrl = Variables.urls.hosts.applicant_details.replace("<placement_id>", placmentUuid);
    return (
      <div className={classNames("d-flex", styles.cardHeaderCompany)}>
        <img
          className={styles.cardLogo}
          src={candidate.photo || DefaultPhoto}
          alt="candidate photo"
        />
        <div className={styles.cardLogoText}>
          <Subtitle size="large" className={styles.roleText}>
            <a href={applicationUrl} className={styles.underline} target="_blank" rel="noreferrer">{candidate.user.full_name}</a>
          </Subtitle>
          <Subtitle size="large">
            For <a href={roleURL} className={styles.underline} target="_blank" rel="noreferrer">{role}</a>
          </Subtitle>
          {interviewDate && (
            <>
              <Subtitle size="small" className={styles.interviewDate}>
                Last Interview: {formatDateDurationRange(interviewDate.date_time, interviewDate.duration)}
              </Subtitle>
            </>
          )}
        </div>
      </div>
    )
}
const numberstylemap = {
  0: styles.zero,
  1: styles.one,
  2: styles.two,
  3: styles.three,
  4: styles.four,
  5: styles.five,
  6: styles.six,
  7: styles.seven,
  8: styles.eight,
  9: styles.nine,
  10: styles.ten,
}
export default function InterviewFeedbackCard({interview}) {
    const {placement, interview_dates:interviewDate, candidate} = interview;
    const feedback_url = Variables.urls.candidates.dashboard + "?inv=" + interview.uuid + "&ut=HOS" + "&fd=";
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [message, setMessage] = useState("");
    const [candidateUserUuid, setCandidateUserUuid] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [feedbackuuid, setFeedbackUUID] = useState();
    const [npsScore, setNpsScore] = useState();
    const npsCategory = Number(npsScore) < 7 ? "NPS_0_6" : Number(npsScore) < 9 ? "NPS_7_8" : "NPS_9_10";
    const baseOptions = hostFeedback[npsCategory];
    const options = [...baseOptions.responses, {label: "Other", value: "other"}];
    const [hostUserUuid, setHostUserUuid] = useState();
    const [page, setPage] = useState(-1);
    const numbers = Array.from({ length: 11 }, (_, index) => index);
    const onFeedbackClick = (event, number) => {
      setNpsScore(number);
      if (interview.uuid && number) {
        setIsLoading(true);
        api.createInterviewFeedback({
          interview: interview.uuid,
          nps_score: number,
          feedback_type: "HOS"
        }).then( (result) => {
          setIsLoading(false);
          setFeedbackUUID(result.uuid);
          setCandidateUserUuid(result.candidate_user_uuid);
          setHostUserUuid(result.host_user_uuid);
          setPage(result.is_created || result.flags == 0 ? 0 : 2);
          const salutation = result.candidate_name ? `Dear ${result.candidate_name}` : "Hello";
          setMessage(`${salutation},\nThank you for your time today, it was a pleasure speaking to you. \nWe will be in touch soon regarding next steps. \n\nKind regards,\n${Variables.user.full_name}`);
          setShowFeedbackModal(true);
        }).finally(()=>{
          setIsLoading(false);
        })
      }
    };

    return(
      <div className={styles.feedbackCard}>
        <div className={classNames(styles.bannerLabel)}>
          🌱 Post interview feedback
        </div>
        <CardHeaderCandidate
          candidate={candidate}
          placmentUuid={placement.uuid}
          project={{name: placement.associated_project_name, uuid: placement.associated_project}}
          interviewDate={interviewDate[0]}
        />
        <hr/>
        <Heading size="h6" className="p-3">
          On a scale of 0 (bad) to 10 (good), how would you rate your interview
          with {candidate.user.first_name}?</Heading>
        <div
          className={classNames(styles.box, "d-flex flex-wrap")}
        >
          {numbers.map((number) => (
            <Button
              className={classNames(styles.feedbackButtons, numberstylemap[number])}
              key={number}
              onClick={(event) => onFeedbackClick(event, number)}
            >
              {number == npsScore && isLoading ? <PulseLoader color="#FFF" margin={2} size={3}/> :<>{number}</>}
            </Button>
          ))}
        </div>
        <div className="px-3">
            <Caption>
              After you submitted your rating you will be redirected to a thank you message that we encourage you to send to the candidate as a way to express gratitude for their time.
            </Caption>
        </div>
        <InterviewFeedbackModal
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          npsScore={npsScore}
          feedbackuuid={feedbackuuid}
          options={options}
          npsCategory={npsCategory}
          message={message}
          setMessage={setMessage}
          hostUserUuid={hostUserUuid}
          candidateUserUuid={candidateUserUuid}
          page={page}
          setPage={setPage}
          userType={UserType.HOST}
        />
      </div>

    )
}