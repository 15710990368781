import React, { useState } from "react";
import {useMasterClassRecommendationsQuery, DISABLE_REFETCH_OPTIONS} from "services/queries";
import {OtherRecommendationCard} from "./RecommendationCard";
import SwiperList from "./SwiperList";
import NoData from "./NoData"
import mortarboard from "base64/mortarboard"

const MasterclassList = () => {
  const {data: recommendations, isLoading: areRecommendationsLoading} = useMasterClassRecommendationsQuery({DISABLE_REFETCH_OPTIONS, placeholderData: []});
  const [clickedOnCard, setClickedOnCard] = useState(false);

  if (areRecommendationsLoading) return null;
  return <SwiperList
    name="masterclassList"
    title="Masterclasses for you"
    subtitle="Level up your skills with personalized, group-based masterclasses led by experts, designed to help you crush your goals!"
    strBase64={mortarboard}
    noDataComponent={<NoData title="Recommended masterclasses for you will show up here" subtitle="Looks like there are no upcoming masterclasses at the moment. Please check back later." />}
    nodes={recommendations.map(recommendation => {
      const rec = !!recommendation.masterclass ? recommendation : {masterclass: recommendation, recommendation_type: "masterclass"};
      const isFeatured = !!recommendation.masterclass ? true : false;
      return { id: recommendation.uuid,
        node: <OtherRecommendationCard
          project={rec}
          className={""}
          isFeatured={isFeatured}
          setClickedOnCard={setClickedOnCard}
          clickedOnCard={clickedOnCard}
      />
      }
    })}
    />
}

export default MasterclassList;
