import {status} from "util/placement/constants";
import {status as interviewStatus} from "util/interview/constants";

export const TAB = {
  APPLICATIONS: "Applications",
  INTERVIEWS: "Interviews",
  OFFERS: "Offers",
};

export const TABCOLOURS = {
  Applications: "#ac5fd8",
  Interviews: "#F5BF00",
  Offers: "#1aa84c",
};

export const SUBTAB = {
  QUALIFIED: "Applications",
  ARCHIVED: "Archived",
  DISQUALIFIED: "Declined",
  SHARED_WITH_YOU: "Shared with you",
  INTERVIEW_OFFERED: "Offered",
  INTERVIEW_CONFIRMED: "Confirmed",
  INTERVIEW_COMPLETED: "Completed",
  INTERVIEW_DECLINED: "Interview declined",
  SENT: "Sent",
  ACCEPTED: "Accepted",
  DECLINED: "Declined",
};

export const DEFAULT_TABLE_MESSAGES = {
  [SUBTAB.QUALIFIED]: "You do not currently have any open applications",
  [SUBTAB.ARCHIVED]: "You do not currently have any archived applications",
  [SUBTAB.DISQUALIFIED]: "You do not currently have any declined applications",
  [SUBTAB.SHARED_WITH_YOU]: "No applications have been shared with you yet",
  [SUBTAB.INTERVIEW_OFFERED]: "You have not offered any interviews yet",
  [SUBTAB.INTERVIEW_CONFIRMED]: "You do not currently have any confirmed interviews",
  [SUBTAB.INTERVIEW_COMPLETED]: "You do not currently have any completed interviews",
  [SUBTAB.INTERVIEW_DECLINED]: "You do not currently have any declined interviews",
  [SUBTAB.SENT]: "You have currently not sent any offers",
  [SUBTAB.ACCEPTED]: "You do not currently have any accepted offers",
  [SUBTAB.DECLINED]: "You do not currently have any declined offers",
};

export const TAB_TO_SUBTABS = {
  [TAB.APPLICATIONS]: [SUBTAB.QUALIFIED, SUBTAB.ARCHIVED, SUBTAB.DISQUALIFIED, SUBTAB.SHARED_WITH_YOU],
  [TAB.INTERVIEWS]: [
    SUBTAB.INTERVIEW_OFFERED,
    SUBTAB.INTERVIEW_CONFIRMED,
    SUBTAB.INTERVIEW_COMPLETED,
    SUBTAB.INTERVIEW_DECLINED,
  ],
  [TAB.OFFERS]: [SUBTAB.SENT, SUBTAB.ACCEPTED, SUBTAB.DECLINED],
};
export const BANNER_CONTAINER = document.getElementById("banner-container");

// Maps placement state to the tab they belong in.
export const INTERVIEW_PLACEMENT_STATUS_MAPPING = {
  [status.INTERVIEW_OFFERED]: SUBTAB.INTERVIEW_OFFERED,
  [status.INTERVIEW_TIME_REQUESTED]: SUBTAB.INTERVIEW_OFFERED,
  [status.INTERVIEW_CONFIRMED]: SUBTAB.INTERVIEW_CONFIRMED,
  [status.RESCHEDULE_REQUIRED]: SUBTAB.INTERVIEW_OFFERED,
  [status.INTERVIEW_MEETING_LINK_ADDED]: SUBTAB.INTERVIEW_CONFIRMED,
  [status.INTERVIEW_COMPLETED]: SUBTAB.INTERVIEW_COMPLETED,
  [status.INTERVIEW_DECLINE]: SUBTAB.INTERVIEW_DECLINED,
};
export const STATUS_TO_SUBTAB = {
  [status.PENDING]: SUBTAB.QUALIFIED,
  [status.EXPIRED]: SUBTAB.DECLINED,
  [status.VIEWED]: SUBTAB.QUALIFIED,
  [status.ARCHIVED]: SUBTAB.ARCHIVED,
  [status.DECLINED]: SUBTAB.DISQUALIFIED,
  [status.OFFERED]: SUBTAB.SENT,
  [status.ACCEPTED]: SUBTAB.ACCEPTED,
  [status.WITHDRAWN]: SUBTAB.DECLINED,
  [status.CANCELLED]: SUBTAB.DECLINED,
  [status.UNROUTEABLE]: SUBTAB.DECLINED,
  [status.SHARED_WITH_YOU]: SUBTAB.SHARED_WITH_YOU,
  [status.COMPLETED]: SUBTAB.ACCEPTED,
  ...INTERVIEW_PLACEMENT_STATUS_MAPPING,
};
export const INTERVIEW_TO_SUBTAB = {
  [interviewStatus.INTERVIEW_ACCEPTED]: SUBTAB.INTERVIEW_CONFIRMED,
  [interviewStatus.INTERVIEW_COMPLETED]: SUBTAB.INTERVIEW_COMPLETED,
  [interviewStatus.INTERVIEW_OFFERED]: SUBTAB.INTERVIEW_OFFERED,
  [interviewStatus.INTERVIEW_TIME_REQUESTED]: SUBTAB.INTERVIEW_OFFERED,
  [interviewStatus.INTERVIEW_DECLINE]: SUBTAB.INTERVIEW_DECLINED,
  [interviewStatus.MEETING_LINK_ADDED]: SUBTAB.INTERVIEW_CONFIRMED,
  [interviewStatus.RESCHEDULE_REQUIRED]: SUBTAB.INTERVIEW_OFFERED,
};
export const EXCLUDED_FROM_COUNT = [
  status.ARCHIVED,
  status.SHARED_WITH_YOU,
  status.EXPIRED,
  status.DECLINED,
  status.INTERVIEW_DECLINE,
  status.WITHDRAWN,
  status.CANCELLED,
  status.UNROUTEABLE,
];


// Computed constants

export const SUBTABS_TO_TAB = Object.fromEntries(
  Object.entries(TAB_TO_SUBTABS)
    .flatMap(([tab, subTabs]) => subTabs.map(subTab => [subTab, tab])),
);

export const FUNNELTAB = {
  NEW: "new",
  INTERVIEW: "interview",
  OFFER: "offer",
  ARCHIVED: "archived",
  DECLINED: "declined"
};

export const STATUS_TO_FUNNELTAB = {
  [status.INTERVIEW_OFFERED]: FUNNELTAB.INTERVIEW,
  [status.INTERVIEW_TIME_REQUESTED]: FUNNELTAB.INTERVIEW,
  [status.INTERVIEW_CONFIRMED]: FUNNELTAB.INTERVIEW,
  [status.RESCHEDULE_REQUIRED]: FUNNELTAB.INTERVIEW,
  [status.INTERVIEW_MEETING_LINK_ADDED]: FUNNELTAB.INTERVIEW,
  [status.INTERVIEW_COMPLETED]: FUNNELTAB.INTERVIEW,
  [status.INTERVIEW_DECLINE]: FUNNELTAB.INTERVIEW,
  [status.PENDING]: FUNNELTAB.NEW,
  [status.EXPIRED]: FUNNELTAB.ARCHIVED,
  [status.VIEWED]: FUNNELTAB.NEW,
  [status.ARCHIVED]: FUNNELTAB.ARCHIVED,
  [status.DECLINED]: FUNNELTAB.DECLINED,
  [status.OFFERED]: FUNNELTAB.OFFER,
  [status.ACCEPTED]: FUNNELTAB.OFFER,
  [status.WITHDRAWN]: FUNNELTAB.OFFER,
  [status.CANCELLED]: FUNNELTAB.DECLINED,
  [status.UNROUTEABLE]: FUNNELTAB.ARCHIVED,
  // [status.SHARED_WITH_YOU]: FUNNELTAB.SHARED_WITH_YOU,  //ToDo
  [status.COMPLETED]: FUNNELTAB.OFFER,
};
export const INTERVIEW_TO_FUNNELTAB = {
  [interviewStatus.INTERVIEW_ACCEPTED]: FUNNELTAB.INTERVIEW,
  [interviewStatus.INTERVIEW_COMPLETED]: FUNNELTAB.INTERVIEW,
  [interviewStatus.INTERVIEW_OFFERED]: FUNNELTAB.INTERVIEW,
  [interviewStatus.INTERVIEW_TIME_REQUESTED]: FUNNELTAB.INTERVIEW,
  [interviewStatus.INTERVIEW_DECLINE]: FUNNELTAB.INTERVIEW,
  [interviewStatus.MEETING_LINK_ADDED]: FUNNELTAB.INTERVIEW,
  [interviewStatus.RESCHEDULE_REQUIRED]: FUNNELTAB.INTERVIEW,
};

export const STATUS_BACKGROUND_COLOR_MAP = {
  [status.PENDING]: "#F2F5FA",
  [status.VIEWED]: "#F2F5FA",
  [status.ARCHIVED]: "#F2F5FA",
  [status.EXPIRED]: "#F2F5FA",
  [status.INTERVIEW_OFFERED]: "#FAF3E3",
  [`${status.INTERVIEW_OFFERED}_EXPIRED`]: "#F0F0F0",
  [status.INTERVIEW_CONFIRMED]: "#E9F8ED",
  [status.INTERVIEW_MEETING_LINK_ADDED]: "#E9F8ED",
  [status.INTERVIEW_DECLINE]: "#FFD7CE",
  [status.INTERVIEW_COMPLETED]: "#E2F0FF",
  [status.OFFERED]: "#FAF3E3",
  [status.WITHDRAWN]: "#FFD7CE",
  [status.ACCEPTED]: "linear-gradient(88deg, #E5F1FF 0%, #FCF1FF 55.59%, #FFF6E3 99.76%), #F2F5FA",
  [status.COMPLETED]: "#F2F5FA",
  [status.INTERVIEW_TIME_REQUESTED]: "#F8E6E2",
  [status.RESCHEDULE_REQUIRED]: "#F8E6E2",
  [status.DECLINED]: "#F2F5FA",
  [status.CANCELLED]: "#FFD7CE",
}
export const STATUS_ICON_MAP = {
  [status.PENDING]: "🌟",
  [status.VIEWED]: "🙌",
  [status.ARCHIVED]: "🕸️",
  [status.EXPIRED]: "🕸️",
  [status.INTERVIEW_OFFERED]: "⏳",
  [`${status.INTERVIEW_OFFERED}_EXPIRED`]: "⏳",
  [status.INTERVIEW_CONFIRMED]: "🗓️",
  [status.INTERVIEW_MEETING_LINK_ADDED]: "🗓️",
  [status.INTERVIEW_DECLINE]: "☔️",
  [status.INTERVIEW_COMPLETED]: "☑️",
  [status.OFFERED]: "⏳",
  [status.WITHDRAWN]: "☔️",
  [status.ACCEPTED]: "🎉",
  [status.COMPLETED]: "🌟",
  [status.INTERVIEW_TIME_REQUESTED]: "🔄",
  [status.RESCHEDULE_REQUIRED]: "🔄",
  [status.DECLINED]: "🚫",
  [status.CANCELLED]: "☔️",
}
export const STATUS_TEXT_MAP = {
  [status.PENDING]: "New",
  [status.VIEWED]: "Viewed",
  [status.ARCHIVED]: "Archived",
  [status.EXPIRED]: "Archived",
  [status.INTERVIEW_OFFERED]: "Waiting for Interview Confirmation",
  [`${status.INTERVIEW_OFFERED}_EXPIRED`]: "Interview Offer Expired",
  [status.INTERVIEW_CONFIRMED]: "Interview confirmed",
  [status.INTERVIEW_MEETING_LINK_ADDED]: "Interview confirmed",
  [status.INTERVIEW_DECLINE]: "Interview cancelled",
  [status.INTERVIEW_COMPLETED]: "Interview completed",
  [status.OFFERED]: "Offer pending",
  [status.WITHDRAWN]: "Offer declined",
  [status.ACCEPTED]: "Offer accepted",
  [status.COMPLETED]: "Placement completed",
  [status.INTERVIEW_TIME_REQUESTED]: "Interview reschedule request",
  [status.RESCHEDULE_REQUIRED]: "Interview reschedule request",
  [status.DECLINED]: "Declined",
  [status.CANCELLED]: "Cancelled",
}
