/**
 * Create an interface for an event.
 */
export class EventInterface<T=never> {
  name: string;
  callback: (data?: CustomEvent<T>) => void;

  constructor(name: string) {
    this.name = name;
    this.register = this.register.bind(this);
    this.dispatch = this.dispatch.bind(this);
    this.remove = this.remove.bind(this);
  }

  register(callback: (e?: T) => void): void {
    this.callback = (e: CustomEvent) => callback(e.detail);
    document.addEventListener(this.name, this.callback);
  }

  dispatch(data: T): void {
    document.dispatchEvent(new CustomEvent<T>(this.name, {detail: data}));
  }

  remove(): void {
    document.removeEventListener(this.name, this.callback);
  }
}
