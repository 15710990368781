import React, {useEffect, useState} from "react";

import DemoVideoModal from "components/my-account/DemoVideo";
import CheckBox from "components/shared/Checkbox";
import Dropdown from "components/shared/Dropdown";
import Input from "components/shared/Input";
import Tooltip from "components/shared/Tooltip";
import {DuedilAsyncTypeaheadSignUp} from "components/shared/DuedilAsyncTypeahead";
import * as yup from "yup";
import {yupErrorToMapping} from "util/errors";
import ErrorMessage from "components/bespoke-project/pages/ErrorMessage";
import {DISABLE_REFETCH_OPTIONS, useCountriesQuery, useSelfUpToCompanyQuery} from "services/queries";

import styles from "./index.module.scss";
import classNames from "classnames";

import paypal from "/static/workfinder/images/logos/grey-paypal.png";
import made from "/static/workfinder/images/logos/made-small.png";
import london from "/static/workfinder/images/logos/grey-london.png";
import zoopla from "/static/workfinder/images/logos/grey-zoopla.png";

import beach from "/static/workfinder/images/emojis/beach.png";
import star from "/static/workfinder/images/emojis/star.png";
import people from "/static/workfinder/images/emojis/people.png";
import rocket from "/static/workfinder/images/emojis/rocket.png";
import api from "services/v3";
import {classifySize, classifyTurnover} from "util/companies/helpers";

const DescriptionItem = ({key, icon, title, message}) => (
  <div key={key} className="d-flex mb-3">
    <img src={icon}/>
    <div className="w-100">
      <p className="d-flex flex-column mb-0">
        <strong>{title}</strong>
        {message}
      </p>
    </div>
  </div>
);

const Description = () => (
  <div className={styles.description}>
    <DescriptionItem
      key="beach"
      icon={beach}
      title="Acquire flexible skilled talent fast to reach your goals"
      message="Our AI recommends your opportunities to the ideal candidates who have the right skills to apply to fill your skills gaps."
    />
    <DescriptionItem
      key="star"
      icon={star}
      title="Sophisticated collaboration tools"
      message="Assess, shortlist, collaborate, message, interview, and secure candidates from one mighty dashboard."
    />
    <DescriptionItem
      key="people"
      icon={people}
      title="Drive down hiring and training costs"
      message="We support your interns with personalised marketing courses, masterclasses, and mentoring."
    />
    <DescriptionItem
      key="rocket"
      icon={rocket}
      title="Reduce pressure on your team"
      message="Improve retention and lower stress across your whole company while supporting the next generation of talent."
    />
  </div>
);

const Header = (): JSX.Element => {
  const [openDemoVideoModal, setOpenDemoVideoModal] = useState(false);
  const informationPackUrl = "https://superpower.tech/features/informationpack/";

  return (
    <div className={styles.header}>
      <h2>Acquire and develop talent at lightning speed</h2>
      <div>
        <a onClick={() => setOpenDemoVideoModal(true)} className="mr-3">
          <span className="material-icons mr-1">
            play_arrow
          </span>
          Watch Demo</a>
        <a href={informationPackUrl} target="_blank" rel="noreferrer">Information Pack</a>
      </div>
      <DemoVideoModal open={openDemoVideoModal} onClose={() => {
        setOpenDemoVideoModal(false);
      }}
      />
    </div>
  );
};

const partnerImgs = [paypal, zoopla, made, london];

export const Partners = (): JSX.Element => (
  <div className={styles.partners}>
    <span><strong>Trusted by thousands of members and by teams at</strong></span>
    <div className="d-flex justify-content-between mt-1">
      {partnerImgs.map((image, idx) => <img key={`partner-logo-${idx}`} src={image}/>)}
    </div>
  </div>
);

const Success = () => (
  <div className={classNames(styles.formContainer, "d-flex flex-column align-items-center text-center p-4")}>
    <img src={rocket} width={80} height={60}/>
    <h3><span>Let’s do this!</span></h3>
    <span className={classNames("mb-4", styles.thankYou)}><strong>Thank you for registering for your free demo!</strong></span>
    <p className={styles.thankYou}>We can’t wait to get started and help your organisation find talent at lightning speed.
Our team will be in touch with you shortly to confirm the date and time.</p>
  </div>
);

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required()
    .trim("First name is a required field")
    .label("First name"),
  last_name: yup
    .string()
    .required()
    .trim("Last name is a required field")
    .label("Last name"),
  role: yup
    .string()
    .required("Role title is a required field")
    .label("Role title"),
  phone: yup
    .string()
    .nullable()
    .max(30)
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      {
        excludeEmptyString: true,
        message: "Must be a valid phone number",
      },
    )
    .matches(/^(|.{3,})$/, "Phone number too short")
    .label("Phone number"),
  email_address: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is a required field"),
  company_name: yup
    .string()
    .required()
    .trim("Organisation name is a required field")
    .label("Organisation name"),
  domain: yup
    .string()
    .nullable()
    .notRequired()
    .matches(
      /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
      {
        excludeEmptyString: true,
        message: "Must be a valid URL",
      },
    ),
  country: yup
    .string()
    .required("Country is a required field")
    .label("Country"),
  headcount: yup
    .object()
    .label("Headcount")
    .notRequired(),
  revenue: yup
    .object()
    .label("Revenue")
    .notRequired(),
  is_subscribed: yup
    .boolean()
    .label("Subscribe")
    .required(),
});

const RegistrationForm = ({setShowSuccess}) => {
  const [user, setUser] = useState({is_subscribed: true});
  const [userErrors, setUserErrors] = useState({});
  const [showCompanyTextBox, setShowCompanyTextBox] = useState(false);
  const [headcountOptions, setHeadcountOptions] = useState([]);
  const [revenueOptions, setRevenueOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const {data: countryOptions} = useCountriesQuery();
  const updateSelectedCompany = (result) => {
    const {companyId, countryCode} = result;
    setUser({...user, company_name: result.name, duedil_company_id: companyId});
    api.getCompanyData({
      endpoint: "company_data",
      "company-id": companyId,
      "country-code": countryCode,
    }).then(res => {
      const turnover = res.financialSummary.turnover;
      const numberOfEmployees = res.numberOfEmployees;
      res.primaryWebsite && setUser({
        ...user,
        domain: res.primaryWebsite,
        company_name: res.name,
        duedil_company_id: res.companyId,
        country: res.countryCode,
        revenue: classifyTurnover(revenueOptions, res.financialSummary?.turnover).value,
        headcount: classifySize(headcountOptions, res.numberOfEmployees).value,
      });
    });
  };

  const companyUuid = Variables.uuids?.companies && Variables.uuids?.companies.length > 0 ? Variables.uuids?.companies[0] : null;
  const {data: userData,  isSuccess: isQuerySuccess} = useSelfUpToCompanyQuery({
    ...DISABLE_REFETCH_OPTIONS,
    enabled: !!companyUuid,
  });

  useEffect(() => {
    if(isQuerySuccess) {
      console.log("userData", userData);
      setUser(us => ({
        ...us,
        "first_name": userData.first_name,
        "last_name": userData.last_name,
        "role": userData.host.association.title,
        "email_address": userData.email,
        "domain": userData.host.association.location.company.domains && userData.host.association.location.company.domains.length > 0 ? userData.host.association.location.company.domains[0] : "",
        "country": userData.host.association.location.address_country.code,
        "headcount": JSON.parse(userData.host.association.location.company.employee_count),
        "revenue": JSON.parse(userData.host.association.location.company.turnover),
        "company_name": userData.host.association.location.company.name,
        "duedil_company_id": userData.host.association.location.company.duedil_company_id,
      }));
    }
  }, [isQuerySuccess])

  useEffect(() => {
    api.getDemoOptions().then(({headcount, revenue, role}) => {
      setHeadcountOptions(headcount);
      setRevenueOptions(revenue);
      setRolesOptions(role);
    });
  }, []);

  useEffect(() => {
    validate(true);
  }, [user]);

  async function validate(abortEarly) {
    const is_valid = await schema.validate(user, {strict: true, abortEarly: abortEarly})
      .then(() => {
        setUserErrors({});
        setIsDisabled(false);
        return true;
      })
      .catch(e => {
        setIsLoading(false);
        setIsDisabled(true);
        setUserErrors(yupErrorToMapping(e));
        return false;
      });
    return is_valid;
  }

  const onCompanyNotFound = () => {
    setShowCompanyTextBox(true);
  };
  const onCompanyFound = () => {
    setShowCompanyTextBox(false);
  };

  const handleUserChange = (value, name) => {
    setUser({...user, [name]: value});
  };

  const onSubmit = () => {
    api.createDemo(user).then(() => {
      setShowSuccess(true);
      window.scrollTo({top: 0, behavior: "smooth"});
    });
  };

  return (
    <div className={styles.formContainer}>
      <h4><strong>Register for a demo</strong></h4>
      <div id="request_demo_form" className="row">
        <div className={classNames("col-6", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="First name"
            value={user.first_name}
            required
            onChange={e => handleUserChange(e.target.value, "first_name")}
          />
          <ErrorMessage errors={userErrors.first_name} name="first_name" />
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="Last name"
            value={user.last_name}
            required
            onChange={e => handleUserChange(e.target.value, "last_name")}
          />
          <ErrorMessage errors={userErrors.last_name} name="last_name" />
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Dropdown
            onChange={(value, name) => {
              handleUserChange(value, name);
            }}
            options={rolesOptions}
            name="role"
            id="role"
            key="role"
            placeholder="Role title"
            value={user.role}
          />
          <ErrorMessage errors={userErrors.role} name="role"/>
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="Phone number"
            value={user.phone}
            required
            onChange={e => handleUserChange(e.target.value, "phone")}
          />
          <ErrorMessage errors={userErrors.phone} name="phone" />
        </div>

        <div className={classNames("col-12", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="Email address"
            value={user.email_address}
            required
            onChange={e => handleUserChange(e.target.value, "email_address")}
          />
          <ErrorMessage errors={userErrors.email_address} name="email_address" />
        </div>

        <div className={classNames("col-12", styles.shareMore)}>
          Share a bit more about your organisation
          <Tooltip className={styles.toolTip} place="right" iconClassName={classNames( styles.toolTipIcon, "d-inline")}>
            By registering for your demo you agree that Workfinder can store your organisation’s name, website URL, headcount, revenue and location to personalise your demo.
          </Tooltip>
        </div>
        <div className={classNames("col-12", styles.inputGroup)}>
          <DuedilAsyncTypeaheadSignUp
            id="company"
            className={styles.asyncAheadInput}
            updateSelectedCompany={updateSelectedCompany}
            onCompanyNotFound = {onCompanyNotFound}
            onCompanyFound = {onCompanyFound}
            value={showCompanyTextBox? "Can’t find your organisation?": user.company_name}
            name="company_name"
            onChange={(value, name) => setUser({...user, [name]: value})}
            placeholder="Search organisation name"
            fetchAddress={false}
          />
          <ErrorMessage errors={userErrors.company_name} name="company_name" />
        </div>
        { showCompanyTextBox && <div className={classNames("col-12", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="Please enter your company name here"
            value={user.company_name}
            onChange={e => setUser({...user, company_name: e.target.value})}
          />
          <ErrorMessage errors={userErrors.company_name} name="company_name" />
        </div>
        }
        <div className={classNames("col-6", styles.inputGroup)}>
          <Input
            className={styles.inputText}
            placeholder="Website URL"
            value={user.domain}
            required
            onChange={e => handleUserChange(e.target.value, "domain")}
          />
          <ErrorMessage errors={userErrors.domain} name="domain" />
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Dropdown
            onChange={(value, name) => {
              handleUserChange(value, name);
            }}
            options={countryOptions?.map(country => {
              const {name, iso} = country;
              country.label = name;
              country.value = iso;
              return country;
            }) || []}
            name="country"
            id="country"
            key="country"
            placeholder="Country"
            value={user.country}
          />
          <ErrorMessage errors={userErrors.country} name="country"/>
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Dropdown
            onChange={(value, name) => {
              handleUserChange(value, name);
            }}
            options={headcountOptions}
            name="headcount"
            id="headcount"
            key="headcount"
            placeholder="Headcount"
            value={user.headcount}
          />
          <ErrorMessage errors={userErrors.headcount} name="headcount"/>
        </div>
        <div className={classNames("col-6", styles.inputGroup)}>
          <Dropdown
            onChange={(value, name) => {
              handleUserChange(value, name);
            }}
            options={revenueOptions}
            name="revenue"
            id="revenue"
            key="revenue"
            placeholder="Revenue"
            value={user.revenue}
          />
          <ErrorMessage errors={userErrors.revenue} name="revenue"/>
        </div>
        <div className={classNames("col-12", styles.inputGroup)}>
          <CheckBox
            checked={user.is_subscribed}
            id="email"
            onChange={(value) => {
              setUser({...user, is_subscribed: value});
            }}
          >
            Subscribe to Superpower’s digital communications
          </CheckBox>

          <p>Superpower uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <a href="https://superpower.tech/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>

          <button onClick={onSubmit} disabled={isDisabled}>
            Register for a free demo
          </button>
        </div>
      </div>
    </div>
  );
};

const RequestDemo = (): JSX.Element => {
  const [showSuccess, setShowSuccess] = useState(false);
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className={classNames("col-12 col-md-6", styles.width)}>
          <Header/>
          <div className="d-none d-md-block">
            <Partners/>
            <Description/>
          </div>
        </div>
        <div className="col-12 col-md-6">
          {showSuccess ? <Success/> : <RegistrationForm setShowSuccess={setShowSuccess}/>}
        </div>
        <div className="row">
          <div className="col-12 d-block d-md-none">
            <Partners/>
            <Description/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemo;
