import React, {useState} from "react";
import _ from "lodash";
import {Content} from "components/shared/Slate";
import Modal from "components/shared/Modal/Modal";
import styles from "./offerSentModal.module.scss";
import {
  Heading,
  Body,
} from "workfinder-components";
import cone from "/static/workfinder/images/cone.png";
import {CandidateCard} from "./OfferSentModal";


export default function OfferExtensionSentModal({candidate, onClose, open, ...modalProps}) {
  if (!open) {
    return null;
  }
  return (
    <Modal id="offer-extension-sent-modal"
      open={open}
      onClose={onClose}
      className={styles.offerSentModal}
      {...modalProps}
    >
      <Content className={styles.content}>
        <div className="d-flex align-items-center flex-column mt-4">
          <img width="100px" src={cone} alt="globe" />
          <Heading size="h5">Your offer to extend the role has been sent!</Heading>
        </div>
        <CandidateCard candidate={candidate} isExtension={true} />
        <div>
          <Heading size="h6">What&apos;s next?</Heading>
          <Body>We will inform you as soon as we hear back from {candidate.user.full_name}. </Body>
        </div>
      </Content>
    </Modal>
  );
}
