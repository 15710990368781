import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";

import CongratulationsModal from "components/bespoke-project/pages/CongratulationsModal";
import AddMeetingLinkModal from "components/shared/Modal/AddMeetingLinkModal";
import CandidatesRejectedModal from "./CandidatesRejectedModal";
import TimesModal from "components/shared/Modal/TimesModal";
import {EventInterface} from "util/events";
import api from "services/v3";

import ChatModal from "./ChatModal";
import OfferInterviewModal, {offerInterviewModalInterface} from "components/dashboard/OfferInterviewModal";
import BiasModal, {biasModalInterface} from "./BiasModal";
import CompensationModal, {compensationModalInterface} from "./CompensationModal";
import SkillSurvey from "./SkillSurvey";
import ChecklistPopup from "../ChecklistPopup";
import {useProjectQuery} from "services/queries";
import JoinCallModal from "components/dashboard/JoinCallModal"
import { AddProjectModal } from "components/add-project/index";

interface ModalData {
  /** What the open state of the modal should be. */
  open?: boolean;
}

export const connectModalInterface = new EventInterface<ModalData | undefined>("linkedInConnectModal");
export const congratulationsModalInterface = new EventInterface<ModalData | undefined>("congratulationsModal");
export const skillSurveyModalInterface = new EventInterface<ModalData | undefined>("skillSurveyModal");

const projectUUID = Cookies.get("post-project");
const projectName = Cookies.get("post-project-name");
const skill = Cookies.get("skill-survey");

/**
 * A components that handles all the global modals, modals that could appear
 * on any page.
 */
export default function GlobalModals(): JSX.Element {

  const [isPostProject, setIsPostProject] = useState(!!Cookies.get("post-project"));
  const [isSkillSurvey, setIsSkillSurvey] = useState(!!Cookies.get("skill-survey"));
  const [isOpen, setIsOpen] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [requestTime, setRequestTime] = useState(false);
  const [hostReschedule, setHostReschedule] = useState(false);
  const [notificationId, setNotificationId] = useState();
  const [isBiasModalOpen, setIsBiasModalOpen] = useState(false);
  const [isCompensationModalOpen, setCompensationModalOpen] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [customDEI, setCustomDEI] = useState(false);
  const [stage, setStage] = useState("");
  const [hasCollaboration, setHasCollaboration] = useState(false);
  const projectQuery = useProjectQuery(projectUUID, {enabled: !!projectUUID});

  function handlePostProjectModal({open=false}) {
    if (!open) {
      Cookies.remove("post-project");
      Cookies.remove("post-project-name");
    }
    setIsPostProject(open);
  }

  function handleSkillSurveyModal({open=false}) {
    if (!open) {
      Cookies.remove("skill-survey");
    }
    setIsSkillSurvey(false);
  }

  function closeInterviewModal () {
    setIsOpen(false);
  }

  function handleInterviewModal(detail) {
    setCandidate(detail.candidate);
    setNotificationId(detail.notificationId);
    setRequestTime(detail.requestTime ?? false);
    setHostReschedule(detail.hostReschedule ?? false);
    setIsOpen(true);
  }

  function handleCompensationModal(detail) {
    setProjectId(detail.projectId);
    setRoleName(detail.roleName);
    setCompensationModalOpen(true);
  }

  function handleBiasModal(detail) {
    setProjectId(detail.projectId);
    setCustomDEI(detail.customDEI);
    setStage(detail.stage);
    setIsBiasModalOpen(true);
  }

  function closeBiasModal () {
    setIsBiasModalOpen(false);
  }

  function closeCompensationModal() {
    setCompensationModalOpen(false);
  }

  useEffect(
    () => {
      congratulationsModalInterface.register(handlePostProjectModal);
      skillSurveyModalInterface.register(handleSkillSurveyModal);
      offerInterviewModalInterface.register(handleInterviewModal);
      biasModalInterface.register(handleBiasModal);
      compensationModalInterface.register(handleCompensationModal);
      if (Variables.user) {
        api.getSelf().then(
          user => setHasCollaboration(user.allowed_features.COLLABORATION ? true : false),
        );
      }
      return () => {
        connectModalInterface.remove();
        congratulationsModalInterface.remove();
        skillSurveyModalInterface.remove();
        offerInterviewModalInterface.remove();
        biasModalInterface.remove();
        compensationModalInterface.remove();
      };
    },
    [],
  );

  const contratulationsProps = {
    open: true,
    projectUUID,
    projectName,
    onClose: handlePostProjectModal,
  };

  const modal = () => {
    if (!isPostProject || window.location.href.indexOf(Variables.urls.hosts.company_overview) === -1) {
      return <></>;
    }
    else {
      const projectData = projectQuery?.data;
      const companyProfileIncomplete = projectData?.association?.location?.company?.name == "Unspecified company";
      const businessSkillsIncomplete = !(projectData?.tech_skills && projectData?.tech_skills.length > 0);
      return (
        <CongratulationsModal
          hasCollaboration={hasCollaboration}
          companyProfileIncomplete={companyProfileIncomplete}
          businessSkillsIncomplete={businessSkillsIncomplete}
          {...contratulationsProps}
        />
      );
    }
  };

  return (
    <>
      <AddMeetingLinkModal/>
      <ChatModal/>
      <TimesModal/>
      <OfferInterviewModal
        candidate={candidate}
        open={isOpen}
        onClose={closeInterviewModal}
        requestTime={requestTime}
        hostReschedule={hostReschedule}
        notificationId={notificationId}
      />
      <BiasModal
        id="BiasModal"
        projectId={projectId}
        customDEI={customDEI}
        stage={stage}
        onClose={closeBiasModal}
        open={isBiasModalOpen}
      />
      <CompensationModal
        open={isCompensationModalOpen}
        onClose={closeCompensationModal}
        projectId={projectId}
        roleName={roleName}
      />
      <JoinCallModal />
      {isSkillSurvey && <SkillSurvey open onClose={handleSkillSurveyModal} skill={skill}/>}
      {modal()}
      <ChecklistPopup/>
      <CandidatesRejectedModal/>
      <AddProjectModal />
    </>
  );
}
