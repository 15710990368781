import React, { useMemo, useState, useEffect } from "react";
import styles from "./index.module.scss"
import classNames from "classnames";
import {
  Heading,
  Caption,
  Button,
  CardNumber,
  ExpiryDate,
  CVC,
  Input,
  Subtitle
} from "workfinder-components"
import Dropdown from "components/shared/Dropdown";
import { useCountriesQuery } from "services/queries";
import api from "services/v3";
import { PAYMENT_CYCLE, PAYMENT_METHOD } from "components/my-account/constants/UpgradeToPremium";
import {Map} from "immutable";
import useTrackedState from "hooks/useTrackedState";
import LoadingSpinner from "components/shared/LoadingSpinner";
import * as yup from "yup";
import { yupErrorToMapping } from "util/errors";
import PulseLoader from "react-spinners/PulseLoader";

export const validateSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is a required field"),
  company_name: yup
    .string()
    .required("Company name is a required field")
    .trim(),
  address_line_1: yup
    .string()
    .required("Profile picture is a required field"),
  postal_code: yup
    .string()
    .required("Post code is a required field"),
  country: yup
    .string()
    .required("Country is a required field"),
  state: yup
    .string()
    .required("State is a required field"),
  city: yup
    .string()
    .required("City is a required field")
});

const cardSchema = yup.object().shape({
  number: yup
    .string()
    .required("Card number is a required field"),
  expiry_month: yup
    .number()
    .required("Expiry month is a required field"),
  expiry_year: yup
    .number()
    .required("Expiry year is a required field"),
  cvv: yup
    .string()
    .required("CVV is a required field")
});

const PaymentInfo = ({planData, successCallback=null, showDowngradeButton=true}) => {
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CARD)
  const [planId, setPlanId] = useState("");
  const [planIcon, setPlanIcon] = useState("");
  const [planName, setPlanName] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [planAmount, setPlanAmount] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [billingCycle, setBillingCycle] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyData, setCompanyData, setReferenceCompanyData, hasCompanyChanged] = useTrackedState();
  const [paymentData, setPaymentData, setReferencePaymentData, hasPaymentChanged] = useTrackedState();
  const [contactData, setContactData, setReferenceContactData, hasContactChanged] = useTrackedState();
  const [couponId, setCouponId]= useState("");
  const [purchaseOrderNumber, setPurchaseOrderNumber]= useState("");
  const [isCardError, setIsCardError] = useState(false);
  const [userErrors, setUserErrors] = useState<Record<string, string[]>>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [generalError, setGeneralError] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [initComplete, setInitComplete] = useState(false);
  const [cachedData, setCachedData] = useState({});

  useEffect(() => {
    setBillingCycle(planData.billingCycle);
  }, [planData])

  useEffect(() => {
    setSubTotal(billingCycle == PAYMENT_CYCLE.ANNUM ? planAmount * 12 : planAmount);
  }, [planAmount, billingCycle]);

  useEffect(() => {
    setTotal(Math.round(subTotal * (1 + taxPercentage / 100) * 100) / 100);
  }, [subTotal, taxPercentage])

  const getChargebeePlanData: (chargeebeePlanId: string) => Promise<any> = (chargeebeePlanId) => {
    if(chargeebeePlanId in cachedData) {
      return new Promise((resolve, reject) => {resolve(cachedData[chargeebeePlanId])});
    }
    else {
      return new Promise((resolve, reject) => {
        return api.getChargebeePlan(chargeebeePlanId).then(response => {
          setCachedData(cache => (
            {...cache, [chargeebeePlanId]: response}
          ));
          resolve(response);
        }).catch(err => reject(err))
      });
    }
  }

  useEffect(() => {
    if(billingCycle) {
      setIsLoading(true);
      getChargebeePlanData(planData.chargebeeIds[billingCycle]).then(response => {
        setPlanId(planData.chargebeeIds[billingCycle]);
        setCurrencySymbol(response.currency_symbol);
        setTaxPercentage(response.tax);
        setPlanAmount(billingCycle == PAYMENT_CYCLE.ANNUM ? response.price / 12 : response.price);

        if(!initComplete) {
          setPlanIcon(planData.icon);
          setPlanName(planData.name);
          setPlanDescription(planData.description);

          setReferencePaymentData(Map({
            number: "",
            expiry_month: null,
            expiry_year: null,
            cvv: ""
          }));
          setReferenceContactData(Map({"email": Variables.user.email}));

          api.getCompany(Variables.uuids.companies[0]).then(company => {
            const billingLocations = company.locations.filter(item => {
              return item.purpose == "billing";
            });
            const otherLocations = company.locations
            const billingLocation = billingLocations.length > 0 ? billingLocations[0] : otherLocations[0];
            setReferenceCompanyData(Map({
              company_name: company.name,
              address_line_1: billingLocation?.address_building,
              address_line_2: billingLocation?.address_street,
              city: billingLocation?.address_city,
              postal_code: billingLocation?.address_postcode,
              country: billingLocation?.address_country?.code,
              state: billingLocation?.address_region
            }));
            setIsLoading(false);
          });
          setInitComplete(true);
        }
        else {
          setIsLoading(false);
        }
      });
    }
  }, [billingCycle])

  useEffect(() => {
    validate(true);
  }, [contactData, companyData, paymentMethod, paymentData, isCardError]);

  function validate(abortEarly) {
    const promises: Array<Promise<boolean>> = [];
    if (paymentMethod == PAYMENT_METHOD.CARD) {
      if(isCardError) {
        setIsDisabled(true);
        return false;
      }
      if(paymentData) {
        promises.push(
          cardSchema
          .validate(paymentData.toJSON(), {strict: true, abortEarly: abortEarly})
          .then(() => true)
          .catch((e) => false)
        );
      }
    }
    if (contactData && companyData) {
      promises.push(
        validateSchema
        .validate({...contactData.toJSON(), ...companyData.toJSON()}, {strict: true, abortEarly: abortEarly})
        .then(() => {
          return true;
        })
        .catch((e) => {
          setUserErrors(yupErrorToMapping(e));
          return false;
        })
      );
    }
    return Promise
      .all(promises)
      .then((allVal) => {
        if(allVal && allVal.length > 0) {
          if(allVal.every(val => val)) {
            setUserErrors({});
            setIsDisabled(false);
            return true;
          }
          else {
            setIsDisabled(true);
            return false;
          }
        }
        else {
          return true;
        }
      });
  }

  const redirect = () => {
    if (successCallback) {
      successCallback();
    }
    else {
      window.location.href = "/";
    }
  }

  async function submitForm() {
    const isValid = await validate(false);
    if(isValid) {
      setIsSubmitting(true);
      api.setBillingInfo({
        plan_id: planId,
        billing_address: companyData,
        card: paymentData,
        contact: contactData,
        payment_method: paymentMethod,
        coupon_id: couponId,
        po_number: purchaseOrderNumber,
      }).then (response => {
        setIsSubmitting(false);
        redirect();
      }).catch(response => {
        setIsSubmitting(false);
        if(response?.json?.detail) {
          setGeneralError(response.json.detail);
          setTimeout(() => {
            setGeneralError("");
          }, 5000);
        }
      });
    }
  }

  const {data: countries} = useCountriesQuery();
  const countryOptions = useMemo(
    () =>
      countries
        ? countries.map((country) => ({
          label: country.name,
          value: country.iso,
        }))
        : [],
    [countries],
  );

  function handleCompanyChange(value, name) {
    setCompanyData(data => data.set(name, value));
  }

  function handlePaymentChange(value, name) {
    setPaymentData(data => data.set(name, value));
  }

  function handleContactChange(value, name) {
    setContactData(data => data.set(name, value));
  }

  if(isLoading) {
    return <LoadingSpinner className="d-block m-auto"/>;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-7 my-4">
        <div className={styles.card}>
          <div className={styles.header}>
            {generalError && <Heading size="h6" className={styles.red}>{generalError}</Heading>}
            {planIcon && <div className={styles.logo}>{planIcon}</div>}
            <Heading size="h5">Continue with {planName}</Heading>
            <Caption inline className={styles.grey}>{planDescription}</Caption>
          </div>
          <div className={styles.col}>
            <Caption inline className={styles.grey}><b>Billing period</b></Caption>
            <div className={styles.row}>
              <Button kind="secondary" className={classNames("w-50", styles.button, billingCycle == PAYMENT_CYCLE.ANNUM ? "" : styles.inactive)} onClick={() => setBillingCycle(PAYMENT_CYCLE.ANNUM)}>Yearly&nbsp;<span className={styles.green}>40% off</span></Button>
              <Button kind="secondary" className={classNames("w-50", styles.button, billingCycle == PAYMENT_CYCLE.MONTH ? "" : styles.inactive)} onClick={() => setBillingCycle(PAYMENT_CYCLE.MONTH)}>Monthly</Button>
            </div>
          </div>
          <div className={styles.col}>
            <Caption inline className={styles.grey}><b>Payment method</b></Caption>
            <div className={styles.row}>
              <Button kind="secondary" className={classNames("w-50", styles.button, paymentMethod == PAYMENT_METHOD.CARD ? "" : styles.inactive)} onClick={() => setPaymentMethod(PAYMENT_METHOD.CARD)}><i className="ri-bank-card-line"></i>&nbsp;Card</Button>
              <Button kind="secondary" className={classNames("w-50", styles.button, paymentMethod == PAYMENT_METHOD.CARD ? styles.inactive : "")} onClick={() => setPaymentMethod(PAYMENT_METHOD.INVOICE)}><i className="ri-building-line"></i>&nbsp;Invoice</Button>
            </div>
          </div>
          <div className={styles.col}>
            {paymentMethod == PAYMENT_METHOD.INVOICE &&
              <Caption inline className={styles.grey}>Who is responsible for paying this invoice?</Caption>
            }
            {paymentMethod == PAYMENT_METHOD.CARD &&
              <Caption inline className={styles.grey}>Billed to</Caption>
            }
            <Input
              name="email"
              value={contactData.get("email")}
              onChange={(value, event) => handleContactChange(value, "email")}
              placeholder="e.g. email of  department"
            />
            {paymentMethod == PAYMENT_METHOD.CARD && <>
              <CardNumber
                value={paymentData.get("number")}
                onChange={(value) => handlePaymentChange(value, "number")}
                setIsError={(error) => setIsCardError(error)}
              />
              <div className={styles.row}>
                <ExpiryDate
                  month={paymentData.get("expiry_month")}
                  year={paymentData.get("expiry_year")}
                  onChange={(month, year) => { handlePaymentChange(month, "expiry_month"); handlePaymentChange(year, "expiry_year"); }}
                  setIsError={(error) => setIsCardError(error)}
                  className="w-50"
                />
                <CVC
                  value={paymentData.get("cvv")}
                  onChange={(value) => handlePaymentChange(value, "cvv")}
                  setIsError={(error) => setIsCardError(error)}
                  className="w-50"
                />
              </div>
            </>}
          </div>
          <div className={styles.col}>
            <Caption inline className={styles.grey}><b>Company information</b></Caption>
            <Caption inline className={styles.grey}>This information will be displayed on your invoice and used to calculate taxes</Caption>
            <Input
              name="company_name"
              value={companyData.get("company_name")}
              onChange={(value, event) => handleCompanyChange(value, "company_name")}
              placeholder="Company name*"
            />
            <Input
              name="address_line_1"
              value={companyData.get("address_line_1")}
              onChange={(value, event) => handleCompanyChange(value, "address_line_1")}
              placeholder="Address line 1*"
            />
            <Input
              name="address_line_2"
              value={companyData.get("address_line_2")}
              onChange={(value, event) => handleCompanyChange(value, "address_line_2")}
              placeholder="Address line 2"
            />
            <div className={styles.row}>
              <Input
                name="city"
                value={companyData.get("city")}
                onChange={(value, event) => handleCompanyChange(value, "city")}
                placeholder="City*"
                className="w-50"
              />
              <Input
                name="postal_code"
                value={companyData.get("postal_code")}
                onChange={(value, event) => handleCompanyChange(value, "postal_code")}
                placeholder="Postcode*"
                className="w-50"
              />
            </div>
            <div className={styles.row}>
              <Input
                name="state"
                value={companyData.get("state")}
                onChange={(value, event) => handleCompanyChange(value, "state")}
                placeholder="State*"
                className="w-50"
              />
              <Dropdown
                name="country"
                value={companyData.get("country")}
                onChange={(value, event) => handleCompanyChange(value, "country")}
                options={countryOptions}
                key="addressCountry"
                placeholder="Country"
                className="w-50"
                required
                filter
              />
            </div>
            <Input
              name="po_number"
              value={purchaseOrderNumber}
              onChange={(value, event) => setPurchaseOrderNumber(value)}
              placeholder="Purchase order #"
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-5 my-4">
        <div className={styles.card}>
          <div className={styles.header}>
            <Heading size="h5">Order summary</Heading>
          </div>
          <div className={styles.col}>
            <div className={classNames(styles.row, styles.orderSummary)}>
              <Heading size="h6" className={styles.grey}>{planName}</Heading>
              <Heading size="h6" className={styles.grey}>{currencySymbol}{planAmount}</Heading>
            </div>
            <Caption inline className={styles.grey}>{currencySymbol}{planAmount} per seat / month</Caption>
            <Caption inline className={styles.grey}>Billed {billingCycle}</Caption>
          </div>
          <div className={styles.col}>
            <div className={classNames(styles.row, styles.orderSummary)}>
              <Caption inline className={styles.grey}>Subtotal</Caption>
              <Caption inline className={styles.grey}>{currencySymbol}{subTotal}</Caption>
            </div>
            <div className={classNames(styles.row, styles.orderSummary)}>
              <Caption inline className={styles.grey}>Tax</Caption>
              <Caption inline className={styles.grey}>{taxPercentage}%</Caption>
            </div>
          </div>
          <div className={styles.col}>
            <div className={classNames(styles.row, styles.orderSummary)}>
              <Heading size="h5">Total</Heading>
              <Heading size="h5">{currencySymbol}{total}</Heading>
            </div>
          </div>
          <div className={classNames(styles.col, styles.grey)}>
            <Subtitle size="small" inline>Have a promo code?</Subtitle>
            <Input
              name="coupon_id"
              value={couponId}
              onChange={(value, event) => setCouponId(value)}
              placeholder="Enter code"
            />
          </div>
          <Button kind="primary" disabled={isDisabled || isSubmitting} className={styles.button} onClick={submitForm}>
            {isSubmitting ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Upgrade now"}
          </Button>
        </div>
        <div className="d-flex py-3 justify-content-center">
          {showDowngradeButton && <Button kind="tertiary" className={classNames(styles.button, styles.grey)} onClick={redirect}>Downgrade to Free</Button>}
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
