import React, {useState, useEffect, useCallback} from "react";

import Modal from "components/shared/Modal/Modal";
import {Content} from "components/shared/Slate";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
import classNames from "classnames";
import {EventInterface} from "util/events";
import baseStyles from "./JoinCallModal.module.scss";
import personIcon from "/static/workfinder/images/generic-photo.png"
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";
import {formatStartEndDateDayLongSeparately} from "util/date-time";
import {Subtitle} from "workfinder-components";
import { formatLink } from "util/string";

const styles = mergeClassNames(baseStyles, modalTheme);

type InterviewDateStatus = "open" | "selected";

export interface InterviewDate {
  id: number;
  date_time: Date;
  duration: number;
  status: InterviewDateStatus;
}

/**
 * A legacy data structure inherited from the old dashboard. It combines
 * information from various sources into one object. Its variable is usually
 * also named `candidate`, even though its value is more similar to a
 * placement.
 */
export interface CandidatePlacement {
  /** The interviews attached to the placement. */
  interview?: {
    uuid: string;
    interview_dates: InterviewDate[];
    meeting_link?: string;
  };
  /**
   * The ID of the placement, used to retrieve interview data when not
   * present.
   */
  placementID?: uuid;
  /** The name of the project. Only used for display purposes. */
  project?: string;
  /** Project Uuid */
  projectId?: string;
  /**
   *
   */
  user: {
    /** The user's first name. If ommitted, it is guessed from the full name. */
    first_name?: string;
    full_name: string;
  };
}

interface JoinCallData {
  /** The callback to call when the modal is closed. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
  candidate?: CandidatePlacement;
}

export const joinCallModalInterface = new EventInterface<
  JoinCallData | undefined
>("joinCallModal");

export default function JoinCallModal(): JSX.Element | null {
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const [open, setOpen] = useState(false);
  const [candidate, setCandidate] = useState<CandidatePlacement | undefined>();
  const isCandidateDashboard = window.location.pathname.includes(
    Variables.urls.candidates.dashboard,
  );
  const interview = candidate?.interview;
  const selectedDate = interview?.interview_dates?.filter(
    (date) => date.status === "selected",
  )[0];
  const {duration, date_time: interviewDate} = selectedDate ?? {};
  const projectName =
    interview?.placement.associated_project_name ||
    interview?.placement.associated_project?.name ||
    interview?.associated_project_name ||
    candidate?.project;
  const projectUrl = Variables.urls.projects.detail.replace(
    /<uuid(:uuid)?>/,
    interview?.placement.associated_project || candidate?.projectId,
  );
  const placementUrl = Variables.urls.hosts.applicant_details.replace(
    /<placement_id>/,
    interview?.placement.uuid || candidate?.placementID,
  );
  const meetingLink = interview?.meeting_link;

  const handleModalEvent = useCallback(
    (detail: JoinCallData) => {
      setOpen(open => detail.open ?? open);
      setCandidate(detail.candidate);
      if (detail.onClose) {
        setOnClose(() => detail.onClose);
      }
      else {
        setOnClose(undefined);
      }
    }, [setOpen, setCandidate, setOnClose],
  );

  useEffect(() => {
    joinCallModalInterface.register(handleModalEvent);
    return () => {
      joinCallModalInterface.remove();
    };
  }, [handleModalEvent]);

  function handleClose() {
    onClose?.();
    setOpen(false);
    setCandidate(undefined);
    setOnClose(undefined);
  }

  function renderDate(date: {date_time: Date, duration: number} | undefined) {
    const dateTimeString = formatStartEndDateDayLongSeparately(date?.date_time, date?.duration)
    return <>
      <Subtitle size="large">{dateTimeString.date}</Subtitle>
      <Subtitle size="large">{dateTimeString.time}</Subtitle>
    </>;
  }

  const companyLogo = isCandidateDashboard
    ? interview?.placement.association.location.company.logo ??
      defaultCompanyLogo
    : null;
  const avatar = isCandidateDashboard ? companyLogo : personIcon;

  if (!interview) {
    return null;
  }

  return (
    <Modal
      theme={styles}
      open={open}
      onClose={handleClose}
      id="join-call-modal"
      className={classNames(styles.mobileBottom, styles.extraTall)}
    >
      <Content className={styles.content}>
        <h2>Interview details</h2>
        <div className={classNames(styles.candidateInterviewInfo, "flex-row")}>
          <div className={styles.candidateInfo}>
            <div className={styles.avtar}>
              <img className="img-fluid" src={avatar} alt="Person Icon" />
            </div>
          </div>
          <div className={styles.interviewInfo}>
            {!isCandidateDashboard ? (
              <p>
                <a href={placementUrl} target="_blank" rel="noreferrer" className={styles.black}>
                  <strong>{candidate?.user.full_name}</strong>
                </a>
              </p>
            ) : null}
            <p>
              For&nbsp;
              <a href={projectUrl} target="_blank" rel="noreferrer" className={styles.blue}>
                <strong>{projectName}</strong>
              </a>
            </p>
            {isCandidateDashboard ? (
              <p>
                At {interview?.placement?.association.location.company.name}
              </p>
            ) : null}
          </div>
        </div>
        <p className={styles.textIcon}>
          <span className="material-icons">schedule</span>
          <b>Interview time</b>
        </p>
        <div className={styles.margin26px}>{renderDate({date_time: interviewDate, duration: duration ?? 0})}</div>
        <p className={classNames(styles.textIcon, "mt-4")}>
          <span className="material-icons-outlined">location_on</span>
          <b>Interview location</b>
        </p>
        <p className={styles.margin26px}>
          <div
            dangerouslySetInnerHTML={{__html: meetingLink ? formatLink(meetingLink, styles.meetingLink) : ""}}
            style={{
              wordWrap: 'break-word',
              overflowWrap: 'break-word'
            }}
            className={styles.meetingLocation}
          />
        </p>
      </Content>
    </Modal>
  );
}
