import React, {useEffect, useMemo, useState} from "react";
import styles from "./navTabs.module.scss";
import classNames from "classnames";

function Tab(props) {
  const {title, colour, amount, onClick, isActive, additionalClasses, titleClassName=""} = props;
  return (
    <div
      className={classNames(styles.container, isActive ? styles.active : "", ...(additionalClasses ? additionalClasses : []))}
      onClick={onClick}
    >
      <div className={classNames(styles.title, titleClassName)}>
        {title}
      </div>
      {amount &&
        <div className={styles.amount} style={{color: colour}}>
          {amount}
        </div>
      }
    </div>
  )
}

export default function NavTabs({tabData, onClick, selectedTabTitle = null, titleClassName="", tabClassName="", isGutter = true}) {
  const [selectedTab, setSelectedTab] = useState(null);
  useEffect(() => {
    if(selectedTabTitle == null)
      setSelectedTab(tabData[0]);
    else
      setSelectedTab(_.filter(tabData, t => t.title == selectedTabTitle)[0])
  }, tabData);
  const colSpan = useMemo (calculateSpan, [tabData])
  function calculateSpan() {
    let span = 1;
    if(tabData.length <= 6 && tabData.length > 4)
      span = 2;
    else if(tabData.length == 4)
      span = 3;
    else if(tabData.length == 3)
      span = 4;
    else if(tabData.length == 2)
      span = 6;
    else if(tabData.length == 1)
      span = 12;
    return span;
  }
  return (
    <>
    <div className={classNames("row", "d-sm-none", "d-flex", "no-gutters")}>
      <div className={classNames("col", "dropdown")}>
        <div className={classNames("dropdown-toggle", styles.dropdownToggle, tabClassName)} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <Tab
            key="dropdown-toggler"
            isActive={true}
            title={selectedTab?.title}
            amount={selectedTab?.recordCount}
            colour={selectedTab?.colour}
            additionalClasses={[styles.noBorders, styles.noShadows, styles.noPadding, tabClassName]}
            titleClassName={titleClassName}
          />
          <i className="pi pi-chevron-down" style={{marginLeft: "0.8em", color: "#A2AAA4"}}></i>
        </div>
        <div className={classNames("dropdown-menu", styles.dropdownMenu)} aria-labelledby="dropdownMenuButton">
        {
          tabData.map(tab => {
            return (
              <Tab
                key={`dropdown-${tab.title}`}
                isActive={(selectedTab?.title == tab.title)}
                title={tab.title}
                amount={tab.recordCount}
                colour={tab.colour}
                additionalClasses={[styles.noBorders, styles.noShadows, tabClassName]}
                titleClassName={titleClassName}
                onClick={() => {
                  setSelectedTab(tab);
                  onClick(tab.title);
                }}
              />
            );
          })
        }
        </div>
      </div>
    </div>
    <div className={classNames("row", "d-none", "d-sm-flex", isGutter ? "" : "no-gutters")}>
      {
        tabData.map(tab => {
          return (
            <div key={`div-${tab.title}`} className={`col-sm-${colSpan}`}>
              <Tab
                key={tab.title}
                isActive={selectedTab?.title == tab.title}
                title={tab.title}
                amount={tab.recordCount}
                colour={tab.colour}
                titleClassName={titleClassName}
                additionalClasses={tabClassName}
                onClick={() => {
                  setSelectedTab(tab);
                  onClick(tab.title);
                }}
              />
            </div>
          );
        })
      }
    </div>
    </>
  );
}
