import React, { useState } from "react";
import { StoryCardProps, STORY_TYPE_URL_MAP } from "./constants";
import styles from "./socialMediaShareButtons.module.scss"
import copy from "/static/workfinder/images/copy-grey.png";
import facebook from "/static/workfinder/images/facebook-grey.png";
import linkedin from "/static/workfinder/images/linkedin-grey.png";
import mail from "/static/workfinder/images/mail-grey.png";
import twitter from "/static/workfinder/images/twitter-grey.png";

const SocialMediaShareButtons = ({story}: StoryCardProps) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const storyUrl = new URL(`/stories/${STORY_TYPE_URL_MAP[story.story_type]}/${story.username}`, window.location.origin);
  const socialMediaText = `High five to ${story.company_name} for using www.superpower.tech for their talent acquisition! Read more here ${storyUrl}`;
  const title ="Talent acquisition supercharged with Superpower!";

  function onLinkCopy(e) {
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 5000);
    navigator.clipboard.writeText(storyUrl);
    e.stopPropagation();
  }

  return (
    <div className={styles.socialMediaShareButtons}>
        <img src={linkedin}
          onClick={(e) => {
            const link = `https://www.linkedin.com/shareArticle?mini=true&url=${storyUrl}&summary=${socialMediaText}`;
            window.open(link, "_blank");
          }}/>
        <img src={facebook}
          onClick={(e) => {
            window.open(`https://facebook.com/sharer/sharer.php?u=${storyUrl}`, "_blank");
          }}/>
        <img src={twitter}
          onClick={(e) => {
            window.open(`https://twitter.com/share?text=${socialMediaText}&url=${storyUrl}`, "_blank");
          }}/>
        <img src={mail}
          onClick={(e) => {
            window.location.href = `mailto:?subject=${title}&body=${socialMediaText}`;
          }}/>
        <img src={copy}
          onClick={e => onLinkCopy(e)}/>
        {linkCopied ?
          <span className={styles.tooltip}>Story link copied!</span>
          : null}
    </div>
  )
}

export default SocialMediaShareButtons;
