import {useCallback, useEffect, useState} from "react";
import {isMobile as checkIsMobile} from "util/device-check";
import {throttle} from "lodash";

export default function useIsMobile(threshold?: number): boolean {
  const updateIsMobile = useCallback(throttle(() => {
    setIsMobile(checkIsMobile(threshold));
  }), [threshold]);
  const [isMobile, setIsMobile] = useState(checkIsMobile(threshold));

  useEffect(
    () => {
      window.addEventListener("resize", updateIsMobile, true);
      return () => window.removeEventListener("resize", updateIsMobile);
    },
    [updateIsMobile],
  );

  return isMobile;
}
