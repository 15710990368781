import React, {useRef} from "react";
import {Typeahead as BaseTypeahead, TypeaheadProps} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import Input from "components/shared/Input";

import styles from "./typeahead.module.scss";

interface Props extends Omit<TypeaheadProps<string>, "onChange" | "selected"> {
  /** Maximum length of the typeahead input. */
  maxLength?: number;
  /** The name if the typeahead. */
  name?: string;
  /** The callback to call when a item is picked from the typeahead. */
  onChange: (value: string, name?: string) => void;
  /** The currently selected item. */
  selected: string;
}

/**
 * A single input typeahead.
 *
 * A thin wrapper around react-bootstrap-typeahead, adding styles and making
 * the API consistent with other single-input controls.
 */
export default function Typeahead({name, onChange, options, selected, ...typeaheadProps}: Props): JSX.Element {
  const typeaheadRef = useRef(null);

  return (
    // Wrapper div is necessary because restyling the typeahead without it is
    // the <redacted> worst.
    <div className={styles.typeahead}>
      <BaseTypeahead<string>
        {...typeaheadProps}
        onChange={selected => {
          onChange(selected[0], name);
          typeaheadRef.current?.clear();
        }}
        options={options}
        paginate={false}
        renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
          <Input
            {...inputProps}
            ref={ref => {
              inputRef(ref);
              referenceElementRef(ref);
            }}
          />
        )}
        ref={typeaheadRef}
        selected={selected ? [selected] : undefined}
      />
    </div>
  );
}
