import React from "react";
import styles from "./index.module.scss";
import RemovablePill from "./RemovablePill";
import EmailInputBox from "./EmailInputBox";
import classNames from "classnames";

interface EmailBoxProps {
  placeholder?: string;
  label?: string;
  emails: string[];
  setEmails: (emails: string[]) => void;
}

const EmailBox = (props: EmailBoxProps) => {
  const {emails, setEmails, label, placeholder} = props;
  const anyValue = emails && emails.length > 0;

  function addToEmails(val: string) {
    let newArr = emails.concat(val);
    setEmails(newArr);
  }

  function removeFromEmails(index: number) {
    let newArr = emails.filter((val, i) => index !== i);
    setEmails(newArr);
  }

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <div className={styles.emailBoxContainer}>
          {anyValue && emails.map((val, i) => {
            return (
              <RemovablePill id={`tag-${i}`} text={val} onClose={() => removeFromEmails(i)}></RemovablePill>
            );
          })}
        <EmailInputBox
          onValidEmail={(val) => {addToEmails(val)}}
          placeholder={!anyValue && placeholder ? placeholder : ""}
          displayError={false}
          className={styles.emailInputBox}
        />
      </div>
      <label className={classNames("form-label", styles.footerLabel)}>Separate multiple emails with a comma</label>
    </>
  )
}

export default EmailBox;
