import React, {useState} from "react";

import SwiperCore, {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper-bundle.css";
import {useDeclinedReasonsQuery, useInterviewDashboardQuery} from "services/queries";
import {status as placementStatus} from "util/placement/constants";
import {Heading} from "workfinder-components";

import InterviewCard from "../InterviewCard";
import styles from "./index.module.scss";
import DisqualifyModal from "../DisqualifyModal";
import OfferRoleModal from "../OfferRoleModal";
import SuggestOfferModal from "../SuggestOfferModal";
import SuggestDeclineModal from "../SuggestDeclineModal";
import ShareModal from "components/shared/Modal/ShareModal";
import UpgradeToPremiumModal from "components/shared/Modal/UpgradeToPremiumModal/index";
import OfferInterviewCard from "components/candidate-dashboard/OfferInterviewCard";

SwiperCore.use([Navigation]);

interface InterviewsListProps {
    loadInitialData?: () => void;
}
export default function InterviewsList({loadInitialData=() => {}}:InterviewsListProps):JSX.Element {
  const {data: upcomingInterviews, isLoading: upcomingInterviewsisLoading} = useInterviewDashboardQuery();
  const {data: declineReasons} = useDeclinedReasonsQuery({enabled: Boolean(Variables.uuids.host)});
  const [actionedCandidate, setActionedCandidate] = useState();
  const [offerRoleModalOpen, setOfferRoleModalOpen] = useState(false);
  const [disqualifyModalOpen, setDisqualifyModalOpen] = useState(false);
  const [suggestOfferModalOpen, setSuggestOfferModalOpen] = useState(false);
  const [suggestDeclineModalOpen, setSuggestDeclineModalOpen] = useState(false);
  const [sharedPlacementUUID, setSharedPlacementUUID] = useState();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

  function handleViewApplicationClick(e, placementId, status) {
    if (Variables.user?.partner.is_paid || status !== placementStatus.ARCHIVED) {
      window.open(Variables.urls.hosts.applicant_details.replace("<placement_id>", placementId));
    }
    else {
      setIsUpgradeOpen(true);
    }
  }

  if (upcomingInterviewsisLoading || upcomingInterviews?.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="my-4">
        <div className={styles.swiperContainer}>
          <Heading size="h4">🗓️ My Calendar</Heading>
          {upcomingInterviews.length > 3 &&
              <div id="dash-swiper-button-prev" className="swiper-button-prev"></div>
          }
          <Swiper
            slidesPerView="auto"
            spaceBetween={30}
            threshold={10}
            navigation={{
              nextEl: "#dash-swiper-button-next",
              prevEl: "#dash-swiper-button-prev",
            }}
            breakpoints={{
              425: {spaceBetween: 10, slidesPerView: 1},
              600: {spaceBetween: 30, slidesPerView: 2},
              1200: {spaceBetween: 30, slidesPerView: 3},
            }}
          >
            {
              upcomingInterviews?.map(interview =>
                <SwiperSlide key={`interview_card_${interview.id}`}>
                  {
                    interview.candidate.uuid === Variables.uuids.candidate ? ( // interviewing as a candidate
                      <OfferInterviewCard
                        interview={interview}
                        className={styles.card}
                      />
                    ) : ( // interviewing as a host
                      <InterviewCard
                        interview={interview}
                        setActionedCandidate={setActionedCandidate}
                        setOfferRoleModalOpen = {setOfferRoleModalOpen}
                        setDisqualifyModalOpen = {setDisqualifyModalOpen}
                        setSuggestOfferModalOpen={setSuggestOfferModalOpen}
                        setSuggestDeclineModalOpen={setSuggestDeclineModalOpen}
                        setSharedPlacementUUID = {setSharedPlacementUUID}
                        setIsShareModalOpen = {setIsShareModalOpen}
                        onRowClick={handleViewApplicationClick}
                      />
                    )
                  }
                </SwiperSlide>,
              )
            }
          </Swiper>
          {upcomingInterviews.length > 3 &&
          <div id="dash-swiper-button-next" className="swiper-button-next"></div>
          }
        </div>
      </div>
      <DisqualifyModal
        open={disqualifyModalOpen}
        onClose={() => {
          setDisqualifyModalOpen(false);
          loadInitialData();
        }}
        candidate={actionedCandidate}
      />
      <OfferRoleModal
        open={offerRoleModalOpen}
        onClose={() => {
          setOfferRoleModalOpen(false);
          loadInitialData();
        }}
        candidate={actionedCandidate}
      />
      <SuggestOfferModal
        open={suggestOfferModalOpen}
        onClose={() => {
          setSuggestOfferModalOpen(false);
          loadInitialData();
        }}
        candidate={actionedCandidate}
      />
      <SuggestDeclineModal
        open={suggestDeclineModalOpen}
        onClose={() => {
          setSuggestDeclineModalOpen(false);
          loadInitialData();
        }}
        candidate={actionedCandidate}
        declineReasons={declineReasons}
      />
      <ShareModal
        open={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        UUID={sharedPlacementUUID}
      />
      <UpgradeToPremiumModal open={isUpgradeOpen} handleClose={() => setIsUpgradeOpen(false)} />
    </>
  );
}
