import React, {useEffect, useState} from "react";
import classNames from "classnames";
import _ from "lodash";
import useCopyClipboard from "react-use-clipboard";
import PulseLoader from "react-spinners/PulseLoader";

import {useAlerts, messages} from "components/shared/AlertsProvider";
import Avatar from "components/shared/Avatar";
import Button from "components/shared/Button";
import Tooltip from "components/shared/Tooltip";
import LoadingSpinner from "components/shared/LoadingSpinner";
import PillList from "components/shared/PillList";
import TextEditor from "components/shared/TextEditor";
import {useInvitesCreateMutation, useShareCreateMutation, useShareUpdateMutation, useShareDeleteMutation} from "services/mutations";
import {useCandidateQuery, usePlacementQuery, useSelfQuery, useProjectQuery, useShareQuery} from "services/queries";
import {Mixpanel} from "tracking/Mixpanel";
import {combineQueries} from "util/query";
import Modal from "./Modal";
import {mergeClassNames} from "styles/utils";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./shareModal.module.scss";
import EventTracking from "tracking/EventTracking";
import {featureUrl, interestEvent} from "util/cms/constants";

const styles = mergeClassNames(baseStyles, modalTheme);

function getApplicationURL(uuid) {
  return new URL(Variables.urls.hosts.applicant_details.replace("<placement_id>", uuid), location.href).toString();
}

function getProjectURL(uuid) {
  return new URL(
    Variables.urls.projects.detail.replace("<uuid:uuid>", uuid),
    location.href,
  ).toString();
}

interface Props {
  /** Whether the sharing modal is open. */
  open: boolean;
  /** A callback to call when the modal is closed. */
  onClose: () => void;
  /** The UUID of the placement to share. */
  UUID?: string;
  /** An object to restyle the element, mirroring the style element. */
  theme?: {[key: string]: string};
  /** The model of what is being shared */
  kind?: string;
}

export function RecipientList({share, kind, projectName, refetch}: {share: Share, kind: string, projectName: string}): JSX.Element {
  const commonProps = {kind, share, refetch};
  return (
    <ul className={styles.recipientList}>
      <RecipientListItem key={share.owner.email} recipient={share.owner} isOwner {...commonProps}/>
      {kind === "project" && (share.recipients.length > 0 || share.invites.length > 0) ?
        <span className={styles.subTitle}>Colleagues who have editor access to {projectName}:</span> :
        null}
      {
        share.recipients.map(recipient =>
          <RecipientListItem key={recipient.email} recipient={recipient} {...commonProps}/>,
        )
      }
      {
        share.invites.map(invite =>
          <RecipientListItem key={invite.email} recipient={invite} isInvite {...commonProps}/>,
        )
      }
    </ul>
  );
}

interface RecipientListItemProps {
  /** Whether the user has been invited, but has yet to accept the invite. */
  isInvite?: boolean;
  /** Whether the list item is for the owner of the share. */
  isOwner?: boolean;
  /** The recipient of the share. */
  recipient: Recipient;
  /** Model of what is being shared */
  kind: string;
}

function RecipientListItem({isInvite=false, isOwner=false, recipient, ...commonProps}: RecipientListItemProps): JSX.Element {
  const [show, setShow] = useState(true);
  let label: React.ReactNode = "";
  const {share, kind, refetch} = commonProps; // refetch is currently used only for project shares
  if (isOwner) {
    label = <em>Owner</em>;
  }
  else if (isInvite) {
    label = "Invited"; // still doesn't have a WF profile
  }
  else {
    label = "Collaborator";
  }

  const deleteShareMutation = useShareDeleteMutation(share.uuid, recipient.email, refetch);
  const onItemClick = () => {
    deleteShareMutation.mutate();
    setShow(false);
  };

  return show ? (
    <li>
      <div className="row">
        <div className={classNames("col-md-9", styles.userInfo)}>
          <div className={styles.nameEmailAvtar}>
            <Avatar src={recipient.photo} size={40}/>
            <div className={styles.nameEmail}>
              {recipient.full_name && <span className={styles.name}>{recipient.full_name}</span>}
              <span className={styles.email}>{recipient.email}</span>
            </div>
          </div>
          <span className={styles.label}>{label}</span>
        </div>
        <div className={classNames("col-md-3", styles.removeAccess)}>
          { kind === "project" ?
            <span className={styles.remove} style={{visibility: isOwner&&"hidden"}} onClick={() => onItemClick(share)}>Remove access</span> :
            null }
        </div>
      </div>
    </li>
  ) : <></>;
}

interface SearchInputProps {
  /** Whether to disable the search input's submit. */
  disabled?: boolean;
  /** A callback to call when the value changes. */
  onChange: (newValue: string) => void;
  /** A callback to call when an email address is added. */
  onAdd: (email: string) => void;
  /** The share data, used for email validation. */
  share: Share;
  /** The value of the search input. */
  value: string;
}

export function SearchInput({disabled, onChange, onAdd, share, value}: SearchInputProps): JSX.Element {

  function handleSubmit(e) {
    e.preventDefault();
    if (!disabled) {
      onAdd(value);
    }
  }

  function handleInput(e) {
    const value = e.target.value;
    if (!share) {
      return;
    }
    if (share.invites.map(invite => invite.email).includes(value)) {
      e.target.setCustomValidity("The email address has already been invited to access this application.");
    }
    else if (share.recipients.map(recipient => recipient.email).includes(value)) {
      e.target.setCustomValidity("This application has already been shared with this user.");
    }
    else if (share.owner.email === value) {
      e.target.setCustomValidity("You cannot invite the owner of this application.");
    }
    else {
      e.target.setCustomValidity("");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        autoFocus
        className={classNames(styles.search, "w-100")}
        name="search"
        onChange={e => onChange(e.target.value)}
        onInput={handleInput}
        placeholder='Enter email address here, press "enter" to add'
        required
        type="email"
        value={value}
        disabled={disabled}
      />
    </form>
  );
}

interface InvitePageProps {
  /** The initial email address to show in the email list. */
  initialEmail: string;
  /** The callback to call when the back button is clicked. */
  onBack: () => void;
  /** A callback to call when the modal is closed. */
  onClose: () => void;
  /** The UUID of the placement that is being shared. */
  UUID: string;
  /**
   * The share to which people are invited, to perform checks against newly
   * added emails addresses.
   */
  share: Share;
  /** The model of what is being shared */
  kind: string;
}

export function InvitePage({initialEmail, onBack, onClose, UUID, share, kind, shareQuery, setShareData}: InvitePageProps): JSX.Element {
  const [addAlert] = useAlerts();
  const [emails, setEmails] = useState<string[]>([initialEmail]);
  const [inputEmail, setInputEmail] = useState("");
  const [message, setMessage] = useState("");
  const userQuery = useSelfQuery();
  const user = userQuery.data;
  let data, isSuccess, url, projectName;
  const projectQuery = useProjectQuery(UUID, {enabled: kind==="project"});
  const placementQuery = usePlacementQuery(UUID, "HOS", {enabled: kind==="placement"});
  switch (kind) {
  case "project": {
    data = projectQuery.data;
    isSuccess = userQuery.isSuccess && projectQuery.isSuccess;
    url = getProjectURL(data.uuid);
    projectName = data.name;
    break;
  }
  default: {
    data = placementQuery.data;
    isSuccess = userQuery.isSuccess && placementQuery.isSuccess;
    url = getProjectURL(data.associated_project);
    projectName = data.associated_project_name;
    break;
  }
  }
  const mutation = useInvitesCreateMutation({
    onError() {
      addAlert("create-invites-error", "danger", messages.inviteCreateError);
    },
    onSuccess(data) {
      addAlert(
        "create-invites",
        "success",
        data.length > 1 ? messages.invitesCreateSuccess : messages.inviteCreateSuccess,
      );
      onClose();
      setShareData({});
      setInputEmail("");
    },
  });

  useEffect(
    () => {
      if (isSuccess) {
        if (kind==="placement") {
          setMessage(
            "<p>Dear colleague/friend,</p>" +
            "<p>I wonder if you could help me screen a candidate who has applied " +
            "for the following project: " +
            `<a href="${url}">${projectName}</a>.</p>` +
            `<p>If you click on the following <a href="${getApplicationURL(data.uuid)}">link</a> you will have access to the ` +
            "briefing I have received on this candidate. I'd appreciate your " +
            "feedback and/or comments so that I know how to proceed.</p>" +
            "<p>Thank you,</br>" +
            `${user.first_name} ${user.last_name}</p>`,
          );
        }
        else if (kind==="project") {
          setMessage(
            "<p>Dear colleague/friend,</p>" +
            `<p>I would like to invite you as a collaborator to: <a href="${url}">${projectName}</a>.</p>` +
            "<p>You will have access to view all the project’s applications, interview a candidate, leave a thumbs up/down, share the candidate’s application as well as comment on it.</p>" +
            "<p>Looking forward to our collaboration,</p>" +
            `<p>${user.first_name} ${user.last_name}</p>`,
          );
        }
      }
    },
    [isSuccess, user, data],
  );

  function handleAdd(email) {
    setEmails(emails => Array.from(new Set([...emails, email])));
    setInputEmail("");
    shareQuery.refetch();
  }

  function handleInvite() {
    mutation.mutate({message, emails, share: share.uuid});
  }

  return (
    <div className={styles.card}>
      <Button kind="link" onClick={onBack} className={styles.back}>
        <span className="material-icons">
          chevron_left
        </span>Back
      </Button>
      <SearchInput
        onChange={setInputEmail}
        onAdd={handleAdd}
        share={share}
        value={inputEmail}
      />
      <PillList
        className={styles.list}
        enableDelete
        onChange={setEmails}
        theme={{hoveringOverDelete: styles.hoveringOverDelete}}
        value={emails}
      />
      <hr className={styles.rule}/>
      <h2 className={styles.subTitle}>Message</h2>
      <TextEditor
        enableAnchors
        onChange={setMessage}
        value={message}
      />
      <div className={styles.buttonGroup}>
        <Button disabled={mutation.isLoading} kind="green_text" onClick={onBack}>Cancel</Button>
        <Button
          disabled={emails.length === 0 || mutation.isLoading}
          kind="solid"
          onClick={isSuccess ? handleInvite : undefined}
        >
          {
            mutation.isLoading ?
              <PulseLoader color="#fff" margin={2} size={6}/> :
              "Send invitation"
          }
        </Button>
      </div>
    </div>
  );
}

/**
 * A modal for sharing placements.
 */
export default function ShareModal(props: Props): JSX.Element {
  const {open, onClose, UUID, kind="placement"} = props;
  const [addAlert] = useAlerts();
  const [inputEmail, setInputEmail] = useState("");
  const [showInvitePage, setShowInvitePage] = useState(false);
  const [btnLabel, setBtnLabel] = useState("Copy Link");
  const [shareData, setShareData] = useState({});
  const shareKey = [UUID];
  const queries = [];
  let shareMessage, project;
  switch (kind) {
  case "project": {
    const projectQuery = useProjectQuery(UUID, {enabled: !!UUID});
    project = projectQuery.data;
    shareMessage = `Invite colleagues to join ${project?.name ? "\""+project.name+"\"" : "the project"} as a collaborator`;
    break;
  }
  default: {
    const placementQuery = usePlacementQuery(UUID, "HOS", {enabled: !!UUID});
    queries.push(placementQuery);
    const placement = placementQuery.data;
    const candidateQuery = useCandidateQuery(placement?.candidate, {enabled: placementQuery.isSuccess});
    queries.push(candidateQuery);
    const candidate = candidateQuery.data;
    shareMessage = `Share ${candidate?.user.full_name ?? "user"}’s application for feedback`;
    break;
  }
  }
  const createShareMutation = useShareCreateMutation(
    shareKey,
    {
      onSuccess: () => Mixpanel.track(Mixpanel.WEB_HOST_SHARE),
      onError: () => () => addAlert("share-create", "danger", messages.shareCreateError),
    },
  );
  const updateShareMutation = useShareUpdateMutation(
    shareKey,
    {
      onError: () => () => addAlert("share-create", "danger", messages.shareCreateError),
    },
  );
  const share = {...createShareMutation.data, ...updateShareMutation.data, ...shareData};
  const isLoading = _.isEmpty(share) || combineQueries(queries).isLoading;
  const [__, setCopied] = useCopyClipboard(share?.url);


  const shareQuery = useShareQuery(kind==="project"&&share?.uuid);

  useEffect(initiateShare, [open]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setShareData(shareQuery.data), [shareQuery.data]);

  function initiateShare() {
    if (open) {
      createShareMutation.mutate({content_object: UUID});
    }
  }

  function handleShareClicked() {
    setCopied();
    setBtnLabel("Copied!");
    if (btnLabel !== "Copied!") {
      setTimeout(() => setBtnLabel("Copy Link"), 2000);
    }
  }

  function handleAdd() {
    setShowInvitePage(true);
  }

  function openFeaturePage() {
    EventTracking.send({
      content_object: {
        object_id: Variables.user.pk,
        object_type: "User",
      },
      event_type: interestEvent.COLLABORATION,
    });
    window.open(featureUrl.COLLABORATION);
  }

  function renderMainPage() {
    return (
      <>
        <div className={styles.card}>
          <h1 className={styles.title} id="p-share">
            <i className={classNames("ri-user-add-fill", styles.icon)}></i>
            {shareMessage} {kind==="project" && <ProjectTooltip/>}
          </h1>
          {
            Variables.isAuthenticated &&
            (<>
              <SearchInput
                onAdd={handleAdd}
                onChange={setInputEmail}
                share={share}
                value={inputEmail}
                disabled={isLoading}
              />
              {
                isLoading ?
                  <LoadingSpinner className="m-auto"/> :
                  <RecipientList
                    share={share}
                    kind={kind}
                    projectName={project?.name ? "\""+project.name+"\"" : "the project"}
                    refetch={() => shareQuery.refetch()}
                  />
              }
            </>)
          }
        </div>

        {kind !== "project" && <div className={styles.card}>
          <h1 className={styles.subtitle} id="p-share">
            <i className={classNames("ri-link", styles.icon)}></i>
            Share via link for feedback
          </h1>
          <div className="row">
            <div className="col-sm mb-3">
              <span style={{flex: "1 1 0"}}>Anyone with the link can leave feedback</span>
            </div>
            <div className="col-sm-3 d-flex flex-column align-items-stretch">
              <Button kind="primary" onClick={handleShareClicked}>{btnLabel}</Button>
            </div>
          </div>
        </div>}
      </>
    );
  }

  function handleBack() {
    setShowInvitePage(false);
    setInputEmail("");
  }

  function handleClose() {
    setShowInvitePage(false);
    onClose();
    setShareData({});
    setInputEmail("");
  }

  return (
    <Modal open={open} onClose={handleClose} theme={styles} className={styles.mobileBottom}>
      {
        showInvitePage ?
          <InvitePage
            onBack={handleBack}
            onClose={handleClose}
            initialEmail={inputEmail}
            UUID={UUID}
            share={share}
            kind={kind}
            setShareData={setShareData}
            shareQuery={shareQuery}
          /> :
          renderMainPage()
      }
    </Modal>
  );
}

const ProjectTooltip = () => (
  <Tooltip
    clickable={true}
    iconClassName={classNames( styles.toolTipIcon, "d-inline")}
  >
    <div className={styles.text}>
      A collaborator can:
      <ul style={{paddingInlineStart: 24}}>
        <li>View all the project’s applications</li>
        <li>Interview a candidate</li>
        <li>Thumbs up &amp; down </li>
        <li>Share the candidate’s application</li>
        <li>Comment on the candidate’s application</li>
      </ul>
    </div>
  </Tooltip>
);
