import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames";
import AbstractCard from "./OfferInterviewCard/AbstractCard";
import LoadingSpinner from "components/shared/LoadingSpinner";
import Dropdown from "components/shared/Dropdown";

import styles from "./documentRequestCard.module.scss";
import interviewCardStyles from "./OfferInterviewCard/index.module.scss";
import CheckBox from "components/shared/Checkbox";
import Form from "components/shared/Form/Form";
import Input from "components/shared/Form/Input";
import { Subtitle, Button } from 'workfinder-components';
import api from "services/v3";
import {usePublicFileUploadQuery, useSkillsBuilderSkillLevelsQuery} from "services/queries";
import moment from "moment";
import * as constants from "util/documentRequest/constants";
import {skillBuilderColor, skillBuilderLevels, skillDescription} from "./skillsBuilderSkills";
import SBcircle from "/static/workfinder/images/SBcircle.png";
import {SkillLevelSubmittedCard} from "./DocumentRequestUpdate";
import LinkedinRequest from "./DocumentRequest/LinkedInRequest";
import RequestCard from "./DocumentRequest/AbstractCard";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";
import { coverLetterModalInterface } from "./CoverLetterModal";

const humanizer = {
  [constants.documentType.CV]: "CV",
  [constants.documentType.WORKING_RIGHT]: "right to work",
  [constants.documentType.PORTFOLIO]: constants.documentType.PORTFOLIO,
  [constants.documentType.LINKEDIN]: "Linkedin",
  [constants.documentType.SKILL_LEVEL]: "Skill Level",
  [constants.documentType.COVER_LETTER]: "Cover Letter",
};

const MAX_FILE_SIZE = 10485760;  // i.e. 10MB

function fileValidator(file) {
  const supported_types = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];
  if (file && !supported_types.includes(file.type)) {
    return {
      isValid: false,
      reason: "The file you have selected is not the correct file type. " +
          "Please select a PDF or word document.",
    };
  }

  if (file && file.size > MAX_FILE_SIZE) {
    return {
      isValid: false,
      reason: "The file you have selected exceeds the file size limit.  Please select another file.",
    };
  }

  return {isValid: true};
}

const markAccepted = documentRequest => {
  api.updateDocumentRequestAsCandidate(documentRequest.id, {status: constants.status.ACCEPTED});
};

const getDocumentLink = (supporting_document) => {
  if (supporting_document instanceof File) {
    const {name} = supporting_document;
    const objURL = URL.createObjectURL(supporting_document);
    if (name.indexOf(".pdf") != -1) {
      return objURL; // if it is a PDF file
    }
    else {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${objURL}`;
    }
  }
  const url = supporting_document?.file ?? supporting_document?.url;
  if (!url) {
    return url;
  }
  if (url.indexOf(".pdf") != -1) {
    return url;
  }
  /* Solution adapted from
    https://stackoverflow.com/questions/27957766/how-do-i-render-a-word-document-doc-docx-in-the-browser-using-javascript*/
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
};

function handleFileChange(_, __, e, onFileChange) {
  const value = e.target.files[0];
  const validation = fileValidator(value);
  if (validation.isValid) {
    onFileChange(value);
  }
  else {
    alert(validation.reason);
  }
}

function DocumentList({documentRequest, files, isLoading, InputComponent, fileValue, setFileValue}) {
  if (isLoading) {
    return <LoadingSpinner className="d-block m-auto" />;
  }
  return  (
    <>
      <span className={styles.description}>Please select which file you would like to share:</span>
      <div className={styles.radioButtonsContainer}>
        {files.map( (file, idx) => {
          const isFile = file instanceof File;
          const {upload_date} = file;
          const date = moment(isFile ? new Date() : upload_date).format("DD.MM.YYYY");
          return (
            <div
              className={styles.radioButtonsOption}
              key={`selected_cv_${documentRequest.id}_${idx}`}
            >
              <input
                type="radio"
                className={styles.radioButton}
                name={`selected_cv_${documentRequest.id}`}
                defaultChecked={fileValue===file}
                onChange={val => val&&setFileValue(file)}
              />
              <a
                className={styles.link}
                href={getDocumentLink(file)}
                target="_blank" rel="noreferrer"
              >
                CV {date}
              </a>
            </div>
          );
        })}
        <div
          className={styles.radioButtonsOption}
          key={`selected_cv_${documentRequest.id}_new`}
        >
          <input
            type="radio"
            className={styles.radioButton}
            name={`selected_cv_${documentRequest.id}`}
            checked={false}
            disabled
          />
          <label className={styles.label}>
            <span className={styles.uploadNew} style={{float: "left"}}>
              Upload new file (*.doc, *.docx, *.pdf)
            </span>
            {InputComponent}
          </label>
        </div>
      </div>
    </>
  );
}

const CvRequest = ({documentRequest, data, isLoading, isSubmitted, setIsSubmitted, InputComponent, fileValue, setFileValue, submitFile, setCloseCard}) => {
  const [files, setFiles] = useState([]);
  const {project_name} = documentRequest;
  const onUpload = (_, __, e) => {
    handleFileChange(_, __, e, val => {
      const cvData = data.filter(d => d.document_type===constants.documentType.CV);
      setFileValue(val); setFiles([...cvData, val]);
    });
    /*  purposely ...cvData instead of ...files. If we click on "upload new",
          let's just keep the latest new upload */
  };

  useEffect(() => setFiles(data.filter(d => d.document_type===constants.documentType.CV)), [data]);

  if (isSubmitted) {
    return (
      <div className={styles.contentContainer}>
        <span className={styles.description}>
          We have successfully shared your CV with the recruiters reviewing your application.
        </span>
        <span className={styles.needHelp} onClick={() => setCloseCard(true)}>
          Close confirmation message
        </span>
      </div>
    );
  }

  return (
    <div className={styles.contentContainer}>
      <span className={styles.description}>
          Great news! Your application is being reviewed by a recruiter for the following position: <b>{project_name}</b>.
        <div className="mt-2">
        Please share your CV with us to maximise your chances of success.</div>
      </span>
      <Form id={`cv_request_${documentRequest.id}`}>
        {files.length === 0 ? (
          <label className={classNames(styles.upload, styles.label)}>
            <span style={{float: "left"}}>
                        Upload CV (doc, docx, pdf)
            </span>
            <InputComponent onUpload={onUpload}/>
          </label>)
          :   <>
            <DocumentList
              fileValue={fileValue}
              setFileValue={setFileValue}
              documentRequest={documentRequest}
              files={files}
              isLoading={isLoading}
              InputComponent={<InputComponent onUpload={onUpload}/>}
            />

            <button
              className={classNames(styles.upload, styles.button)}
              disabled={!fileValue}
              onClick={() => {
                submitFile(); setIsSubmitted(true);
              }}
            >
              Yes, share CV file
            </button>
          </>
        }
      </Form>
      <a
        href="https://www.futurelearn.com/courses/writing-applications"
        target="_blank"
        rel="noreferrer"
        className={styles.needHelp}
      >
        Need help? Click here
      </a>
    </div>
  );
};

const PortfolioRequest = ({documentRequest, data, InputComponent, fileValue, setFileValue, isSubmitted, setIsSubmitted, submitFile, setCloseCard}) => {
  const [newLink, setNewLink] = useState();
  const [linkBoxOpen, setLinkBoxOpen] = useState(false);
  const [hasSelectedANewFile, setHasSelectedtANewFile] = useState(false);
  const [hasSelectedANewLink, setHasSelectedtANewLink] = useState(false);
  const [isLinkChecked, setIsLinkChecked] = useState(false);
  const [isFileChecked, setIsFileChecked] = useState(false);
  const file = data.find(x => (x.document_type === constants.documentType.PORTFOLIO && x.file));
  const url = data.find(x => (x.document_type === constants.documentType.PORTFOLIO && x.url));

  const {project_name} = documentRequest;

  useEffect(() => setFileValue(file), [file]);
  useEffect(() => setNewLink(url?.url), [url]);


  const isFile = fileValue instanceof File;
  const date = fileValue && moment(isFile ? new Date() : fileValue.upload_date).format("DD.MM.YYYY");

  const onUpload = useCallback((_, __, e) => {
    handleFileChange(_, __, e, val => {
      setFileValue(val);
      setHasSelectedtANewFile(true);
    });
  }, [setFileValue]);

  if (isSubmitted) {
    return (
      <div className={styles.contentContainer}>
        <span className={styles.description}>
          We have successfully shared your portfolio with the recruiters reviewing your application.
        </span>
      </div>
    );
  }

  return (
    <div className={styles.contentContainer}>
      <span className={styles.description}>
          Great news! Your application is being reviewed by a recruiter for the following position: <b>{project_name}</b>.
        <div className="mt-2">
          Please share your portfolio with us to maximise your chances of success.
        </div>
      </span>
      <span className={styles.description}>
              Please select which files you would like to share:
      </span>
      <CheckBox
        disabled={!fileValue}
        id={`portfolio_file_${documentRequest.id}`}
        onChange={(val) => setIsFileChecked(val)}
        className="m-0"
      >
        <div className="pr-1">
          <a
            className={(fileValue ? styles.link : styles.linkDisabled)}
            href={getDocumentLink(fileValue)}
            target="_blank" rel="noreferrer"
          >
            Portfolio file {date}
          </a>
        </div>
        <label className={styles.label}>
          <span className={(fileValue ? styles.uploadNew : styles.uploadFirst)} style={{float: "left"}}>
            {(fileValue ? "Replace file (doc, docx, pdf)" : "Upload file")}
          </span>
          <InputComponent onUpload={onUpload}/>
        </label>
      </CheckBox>

      <div className="d-flex flex-row align-items-center">
        <CheckBox
          disabled={!newLink}
          id={`portfolio_link_${documentRequest.id}`}
          onChange={(val) => setIsLinkChecked(val)}
          className="m-0"
        >
        </CheckBox>
        <div className="pr-1">
          <a
            className={(newLink ? styles.link : styles.linkDisabled)}
            href={newLink}
            target="_blank" rel="noreferrer"
          >
            Portfolio link {hasSelectedANewLink}
          </a>
        </div>
        <a className={classNames(newLink ? styles.uploadNew : styles.uploadFirst, linkBoxOpen ? styles.openLinkBox :"" )}
          onClick={() => setLinkBoxOpen(!linkBoxOpen)}
        >
          {(linkBoxOpen ? "Save" : (newLink ? "Edit" : "Add") )} link
        </a>
      </div>

      {linkBoxOpen && (
        <input
          className={styles.textInput}
          type="text"
          defaultValue={newLink}
          onChange={e => {
            setNewLink(e.target.value); setHasSelectedtANewLink(true);
          }}
          placeholder="Paste link here"
        />
      )}

      <button
        className={classNames(styles.upload, styles.button)}
        disabled={!isFileChecked && !isLinkChecked}
        onClick={() => {
          const sendFile = document.getElementById(`portfolio_file_${documentRequest.id}`).checked;
          const sendLink = document.getElementById(`portfolio_link_${documentRequest.id}`).checked;
          /** Logic
           *  sendFile  sendLink  isNewFile isNewLink   output
           *  false     false       x         x       not allowed
           *  false     true        x       false     mark accepted
           *  false     true        x       true      submit file and newLink
           *  true      false      false      x       mark accepted
           *  true      false      true       x       submit fileValue with url
           *  true      true       false    false     mark accepted
           *  true      true       false    true      submit file with newLink
           *  true      true       true     false     submit fileValue with url
           *  true      true       true     true      submit fileValue with newLink
           */
          if (sendFile) { // issues with asynchronism?
            if (sendLink) {
              if (hasSelectedANewLink || hasSelectedANewFile) {
                if (!hasSelectedANewFile) {
                  setFileValue(file);
                } // we'll keep the previously uploaded file
                if (!hasSelectedANewLink) {
                  setNewLink(url);
                } // we'll keep the previously uploaded link
                submitFile(newLink);
              }
              else {
                // we'll just use the docs the user has already shared
                markAccepted(documentRequest);
                setIsSubmitted(true);
              }
            }
            else {
              if (hasSelectedANewFile) {
                setNewLink(url);
                submitFile();
              }
              else {
                markAccepted(documentRequest);
                setIsSubmitted(true);
              }
            }
          }
          else {
            if (sendLink && hasSelectedANewLink) {
              setFileValue(file);
              submitFile(newLink);
            }
            else {
              markAccepted(documentRequest);
              setIsSubmitted(true);
            }
          }
        }
        }
      >
          Yes, share portfolio
      </button>
      <a
        href="https://uxplanet.org/5-steps-to-build-a-design-portfolio-454f52202b19"
        target="_blank"
        rel="noreferrer"
        className={styles.needHelp}
      >
        Need help? Click here
      </a>
    </div>
  );
};

export const CardHeaderCompany = ({roleUuid, companyLogo, roleName, companyName}) => {
  const roleURL = Variables.urls.projects.detail.replace("<uuid:uuid>", roleUuid);

  return (
    <div className={classNames("d-flex mb-3", styles.cardHeaderCompany)}>
      <img
        className={styles.cardLogo}
        src={companyLogo || defaultCompanyLogo}
        alt="Company Logo"
      />
      <div className={styles.cardLogoText}>
        <Subtitle size="large">
          <a href={roleURL} className={styles.roleText} target="_blank" rel="noreferrer">{roleName}</a>
        </Subtitle>
        <Subtitle size="large">
          {companyName}
        </Subtitle>
      </div>
    </div>
  )
}

const CoverLetterRequest = ({documentRequest, isSubmitted, setIsSubmitted}) => {
  const {id, project_company_logo: companyLogo, project_company_name: companyName, project_name: role, project_uuid: uuid, placement_uuid, requestor_name} = documentRequest;
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const dr = queryParams.get("dr");
    if (window.location.hash == "#cover_letter") {
      if(id == dr) {
        handleOpenModal();
        const newUrl = window.location.href.split("?")[0];
        window.history.pushState({}, "", newUrl);
      }
    }
  }, []);

  const submitCallback = () => {
    setIsSubmitted(true);
  }

  const handleOpenModal = () => {
    coverLetterModalInterface.dispatch({
      open: true,
      placementUuid: placement_uuid,
      companyName: companyName,
      companyLogo: companyLogo,
      roleUuid: uuid,
      roleName: role,
      // onClose: ,
      submitCallback: submitCallback,
      requestorName: requestor_name,
    });
  };

  if (isSubmitted) {
    return (
      <div className="d-flex flex-column">
        <CardHeaderCompany
          roleUuid={uuid}
          companyLogo={companyLogo}
          companyName={companyName}
          roleName={role}
        />
        <Subtitle size="small">
          Thank you! We have shared your cover letter with the recruiter.
        </Subtitle>
      </div>
    );
  }

  return (<>
    <div className="d-flex flex-column">
      <CardHeaderCompany
        roleUuid={uuid}
        companyLogo={companyLogo}
        companyName={companyName}
        roleName={role}
      />
      <Subtitle size="small">
        Exciting update! A recruiter is reviewing your application and has requested a cover letter from you.
      </Subtitle>
      <br/>
      <Subtitle size="small">
        A cover letter is your chance to stand out by showcasing your personality and unique fit for the role.
      </Subtitle>
      <Subtitle size="small" className="mb-5">
        Boost your chances by sharing it with us! 😊
      </Subtitle>
      <Button onClick={handleOpenModal}>
        Share cover letter
      </Button>
      {/* <Button
        kind="tertiary"
        size="small"
        onClick={() => {
          setShowDeclineConfirmation(true);
        }}
      >
        I am not interested in this role anymore
      </Button> */}
      {/* TODO: Decline role with confirmation */}
    </div>
  </>);
};

const WorkingRightRequest = ({documentRequest, data, InputComponent, fileValue, setFileValue, isSubmitted, setIsSubmitted, submitFile, setCloseCard}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [hasSentANewFile, setHasSentANewFile] = useState(false);
  const existingFile = data.find(x => (x.document_type === constants.documentType.WORKING_RIGHT));
  const file = fileValue || existingFile;
  const {country} = documentRequest;

  const isFile = fileValue instanceof File;
  const date = fileValue && moment(isFile ? new Date() : fileValue.upload_date).format("DD.MM.YYYY");


  useEffect(() => setFileValue(file), [file]);

  const onUpload = useCallback((_, __, e) => {
    handleFileChange(_, __, e, val => {
      setFileValue(val);
      setHasSentANewFile(true);
    });
  }, [setFileValue]);

  if (isSubmitted) {
    return (
      <div className={styles.contentContainer}>
        <span className={styles.description}>
          We have successfully shared your right to work in <b>{country}</b> with the recruiters reviewing your application.
        </span>
      </div>
    );
  }

  return (
    <div className={styles.contentContainer}>
      <span className={styles.description}>
                Great news! Your application is being reviewed by a recruiter.
                Please share your right to work for the following location: <b>{country}</b>.
      </span>
      { file ? (
        <>
          <span className={styles.description}>
              Please select which file you would like to share:
          </span>
          <CheckBox
            className={styles.checkbox}
            disabled={!file}
            id={`portfolio_file_${documentRequest.id}`}
            onChange={val => setIsFileSelected(val)}
          >
            <div className="col-xl-5 col-lg-4 col-md-4 col-sm-6">
              <a
                className={(fileValue ? styles.link : styles.linkDisabled)}
                href={getDocumentLink(fileValue)}
                target="_blank" rel="noreferrer"
              >
                Right to work file {date}
              </a>
            </div>
            <label className={styles.label}>
              <span className={(fileValue ? styles.uploadNew : styles.uploadFirst)} style={{float: "left"}}>
                {(fileValue ? "Replace file (doc, docx, pdf)" : "Upload file")}
              </span>
              <InputComponent onUpload={onUpload}/>
            </label>
          </CheckBox>
          <button
            className={classNames(styles.upload, styles.button)}
            disabled={!isFileSelected}
            onClick={() => {
              if (hasSentANewFile) {
                submitFile();
              }
              else {
                markAccepted(documentRequest);
                setIsSubmitted(true);
              }
            }}
          >
            Yes, share my right to work
          </button>
        </>)
        :
        <label className={classNames(styles.upload, styles.label)}>
          <span style={{float: "left"}}>
              Upload Right to Work (*.doc, *.docx, *.pdf)
          </span>
          <InputComponent onUpload={onUpload}/>
        </label>
      }
      <a
        href="https://www.academiceducation.co.uk/work-in-the-uk-while-studying/"
        target="_blank"
        rel="noreferrer"
        className={styles.needHelp}
      >
        Need help? Click here
      </a>
    </div>
  );
};

const SkillsLevelRequest = ({documentRequest, setCloseCard}) => {
  const {skill, id} = documentRequest;
  const [selectedLevel, setSelectedLevel] = useState(-1);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {data, isLoading} = useSkillsBuilderSkillLevelsQuery({skill__name: skill});
  let groupedSkills = [];
  if (!isLoading) {
    groupedSkills = [
      {label: skillBuilderLevels.GETTING_STARTED, items: data.filter(item => item.category.toLowerCase() == skillBuilderLevels.GETTING_STARTED.toLowerCase())},
      {label: skillBuilderLevels.INTERMEDIATE, items: data.filter(item => item.category.toLowerCase() == skillBuilderLevels.INTERMEDIATE.toLowerCase())},
      {label: skillBuilderLevels.ADVANCED, items: data.filter(item => item.category.toLowerCase() == skillBuilderLevels.ADVANCED.toLowerCase())},
      {label: skillBuilderLevels.MASTERY, items: data.filter(item => item.category.toLowerCase() == skillBuilderLevels.MASTERY.toLowerCase())},
    ];
  }
  const saveSkill = () => {

    api.updateDocumentRequestSkillLevel({document_request: id, position: selectedLevel}).then(() => {
      setIsSubmitted(true);
      setTimeout(() => setCloseCard(), 10000);
    });

  };

  const selectedSkillTemplate = (option, props) => {
    if (option) {
      return (
        <div className={styles.selectedLevel}>
          <div>Step {option.position}</div>
        </div>
      );
    }

    return (
      <span>
        {props.placeholder}
      </span>
    );
  };

  const skillOptionTemplate = (option) => {
    return (
      <div style={{width: "265px"}}>
        <div className="row">
          <div className="col-2 font-weight-bold pl-0">Level {option.position}</div>
          <div className="col-10 pr-0"><span className={styles.optionText}>{option.category_description}</span></div>
        </div>
      </div>
    );
  };
  const skillOptionGroupTemplate = (option) => {
    const first_item = option.items[0];
    return (
      <div>
        <div className={styles.optionHeader}><span>{option.label}</span> {first_item?.level_name}</div>
      </div>
    );
  };
  if (isSubmitted) {
    return (<SkillLevelSubmittedCard skill={skill} />);
  }
  const skill_link = skill.replace(" ", "-");
  return (
    <div className={classNames(styles.contentContainer, styles.skillCard)}>
      <img className={styles.skillBuilderImage} src={SBcircle}></img>
      <h6 className="text-center mb-1">Your application is being reviewed</h6>
      <p className="text-center">Share your skill level with recruiters</p>
      <p>Awesome news! Your application is being reviewed by a recruiter
        and they would like to know more about your essential skill level in&nbsp;
      <a
        target="_blank" rel="noreferrer"
        href={`https://www.skillsbuilder.org/universal-framework/${skill_link}`}
      >
        <span className={styles.skillLink} style={{color: skillBuilderColor[skill]}}>
          <b>{skill}</b></span></a>.</p>
      <p>
        <span style={{color: skillBuilderColor[skill]}}>
          <span className="font-weight-bold">{skill}</span><br/>
          <em>{skillDescription[skill]}</em>
        </span>
      </p>
      <p>
        Please take a moment to reflect on your skill level and select the matching one below:
      </p>
      {!isLoading && <Dropdown
        name="skill_level"
        className={classNames(styles.skillDropDown, "w-100")}
        onChange={(value, name) => setSelectedLevel(value) }
        options={groupedSkills}
        placeholder="Select your skill level"
        optionLabel="category_description"
        optionGroupLabel="label" optionGroupChildren="items"
        value={selectedLevel}
        optionValue="position"
        optionGroupTemplate={skillOptionGroupTemplate}
        valueTemplate={selectedSkillTemplate}
        itemTemplate={skillOptionTemplate}
      />}
      <Button
        className="mt-3"
        kind="solid"
        onClick={() => saveSkill()}
        type="button"
        disabled={selectedLevel < 0}
      >
          Share
      </Button>
    </div>
  );
};

const InputComponent = ({onUpload}) => {
  return (
    <Input
      className="form-control"
      type="file"
      name="file"
      style={{display: "none"}}
      onChange={onUpload}
    />
  );
};

const submitFile = ({documentRequest, fileValue, doc_type, setIsSubmitted, setFileValue, url}) => {
  const submit = data => {
    setIsSubmitted(true);
    api.createFileUpload(data).then(() => {
      setFileValue();
    }).catch(console.log);
  };

  const data = new FormData();
  if (url) {
    data.append("url", url);
  }
  const access_type = doc_type == constants.documentType.CV ? "on request": "public" ;

  data.append("app_model", "placements_placement");
  data.append("object_id", documentRequest.placement);
  data.append("document_type", doc_type);
  data.append("access_type", access_type);
  data.append("document_request_id", documentRequest.id);

  if (fileValue instanceof File) { // a new file
    data.append("file", fileValue);
    submit(data);
  }
  else if (fileValue instanceof Object) { // an existing file
    if (url) {
      submit(data);
    }
    api.updateCandidatePlacement(documentRequest.placement_uuid, {cv: fileValue.id});
    markAccepted(documentRequest);
  }
  else if (!fileValue) {
    if (url) {
      submit(data);
    }
  }
};

const DocumentRequestCard = ({documentRequest, setCloseCard}) => {
  const [fileValue, setFileValue] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {data=[], isLoading} = usePublicFileUploadQuery();
  const {doc_type} = documentRequest;

  const commonProps = {
    documentRequest,
    data,
    isLoading,
    isSubmitted,
    setIsSubmitted,
    InputComponent,
    fileValue,
    setFileValue,
    setCloseCard,
    submitFile: url => submitFile({documentRequest, fileValue, doc_type, setIsSubmitted, setFileValue, url}),
  };

  const {project, project_name, project_company_name, project_company_logo} = documentRequest;

  const requestCardProps = {
    title: project_name,
    subtitle: project_company_name,
    image: project_company_logo || defaultCompanyLogo,
    link: Variables.urls.projects.detail.replace("<uuid:uuid>", project),
    onClose: isSubmitted ? () => setCloseCard() : null,
  };


  switch (doc_type) {
  case constants.documentType.LINKEDIN:
    return <LinkedinRequest {...commonProps}/>;
  case constants.documentType.WORKING_RIGHT:
    return (
      <RequestCard
        {...requestCardProps}
        tag={isSubmitted ? "req-rw-shared" : "req-rw-pending"}
      >
        <WorkingRightRequest {...commonProps} />
      </RequestCard>
    );
  case constants.documentType.PORTFOLIO:
    return (
      <RequestCard
        {...requestCardProps}
        tag={isSubmitted ? "req-po-shared" : "req-po-pending"}
      >
        <PortfolioRequest {...commonProps} />
      </RequestCard>
    );
  default:
    break;
  }

  const Content = () => {
    switch (doc_type) {
    case constants.documentType.CV:
      return <CvRequest {...commonProps} />;
    case constants.documentType.SKILL_LEVEL:
      return <SkillsLevelRequest {...commonProps}/>;
    case constants.documentType.COVER_LETTER:
      return <CoverLetterRequest {...commonProps}/>;
    case constants.documentType.TECH_SKILL_LEVEL:
      return null;  // TODO: Card for tech skill level request
    default:
      return <div>Case undefined</div>;
    }
  };
  return (
    <AbstractCard
      styles={interviewCardStyles}
      title={!isSubmitted ?
        `Please share your ${humanizer[doc_type]}` :
        `Thank you! We have shared your ${humanizer[doc_type]}`
      }
      tagClass={!isSubmitted ? "bannerUpcoming": "bannerSuccess"}
      tag={!isSubmitted ? `🙏 Request: ${humanizer[doc_type]}` : `🙏 ${humanizer[doc_type]} shared`}
    >
      {Content()}
    </AbstractCard>
  );
};
export default DocumentRequestCard;
