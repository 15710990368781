import React, { useContext } from "react";
import styles from "./roleList.module.scss"
import classNames from "classnames";
import { Button, Caption, Heading, Subtitle } from "workfinder-components";
import LoadingSpinner from "components/shared/LoadingSpinner";
import { RoleActions, RoleOtherDetails, RoleOwnerCollaborators } from "components/host-dashboard/RoleDetails";
import Plant2 from "/static/workfinder/images/plant-2.png";
import { RoleListContext } from "./RoleListContextProvider";

const RoleList = () => {
  const roleContext = useContext(RoleListContext);
  const {
    liveRoles,
    draftRoles,
    isSuccess,
    openCreateRoleModal
  } = roleContext

  if(!isSuccess) {
    return <LoadingSpinner className="d-block m-auto"/>;
  }

  return (<>
    <div className="d-flex justify-content-between">
      <div>
        <Heading size="h6">Live jobs</Heading>
        <Caption className={styles.grey}>You have {liveRoles.length} live roles</Caption>
      </div>
      <Button size="small" onClick={() => openCreateRoleModal()}>Create role</Button>
    </div>
    {(!(liveRoles && liveRoles.length > 0)) && (!(draftRoles && draftRoles.length > 0)) &&
      <NoRole />
    }
    {liveRoles && liveRoles.length > 0 && liveRoles.map(role => (
      <LiveRoleCard role={role}/>
    ))}
    {draftRoles && draftRoles.length > 0 &&
      <>
        <Heading size="h6">Your draft jobs</Heading>
        {draftRoles.map(role => (
          <DraftRoleCard role={role}/>
        ))}
      </>
    }
  </>);
}

const RoleDetails = ({role}) => {
  return (<>
    <Heading size="h4" className="mb-1">{role.name || "---"}</Heading>
    <RoleOtherDetails role={role} />
  </>);
}

export const NoRole = () => {
  const roleContext = useContext(RoleListContext);
  const {
    openCreateRoleModal
  } = roleContext

  return (
    <div className={styles.noRoleContainer}>
      <img src={Plant2} height={210}/>
      <Heading size="h5">Create your first role</Heading>
      <Subtitle size="small">A role can be anything from a project you need extra hands on, a position within your team,<br/>or a new opportunity in your growing enterprise.<br/>Our lightning-fast recruiting services ensure that your role gets filled in days.</Subtitle>
      <Button size="small" onClick={() => openCreateRoleModal()}>Create role</Button>
    </div>
  );
}

const ApplicationCounts = ({role: {
  new_application_count,
  interview_application_count,
  offer_application_count,
  archive_application_count,
  decline_application_count,
  total_application_count
}}) => {
  const ApplicationCount = (count, text) => (
    <div className={styles.applicationCount}>
      <div className={styles.count}>{count}</div>
      <Caption className={styles.grey}>{text}</Caption>
    </div>
  );
  return(
    <div className={styles.applicationCountsContainer}>
      {ApplicationCount(total_application_count, "Total")}
      {ApplicationCount(new_application_count, "New")}
      {ApplicationCount(interview_application_count, "Interview")}
      {ApplicationCount(offer_application_count, "Offer")}
      {ApplicationCount(archive_application_count, "Archived")}
      {ApplicationCount(decline_application_count, "Declined")}
    </div>
  )
}

const LiveRoleCard = ({role}) => {
  const funnelUrl = `${Variables.urls.hosts.dashboard}#${role.uuid}`
  return (<div className={styles.roleCard} onClick={(e) => window.open(funnelUrl, "_self")}>
    <div className={classNames(styles.col, styles.col65)}>
      <RoleDetails role={role} />
      <RoleOwnerCollaborators role={role} />
    </div>
    <div className={classNames(styles.col, styles.col55)}>
      <ApplicationCounts role={role} />
      <RoleActions role={role} showText={false} showShareButton={true} />
    </div>
  </div>);
};

const DraftRoleCard = ({role}) => {
  const roleContext = useContext(RoleListContext);
  const {
    openCreateRoleModal
  } = roleContext

  return (<div className={styles.roleCard} onClick={() => openCreateRoleModal(role.uuid)}>
    <div className={classNames(styles.col, styles.col65)}>
      <RoleDetails role={role} />
      <RoleOwnerCollaborators role={role} />
    </div>
    <div className={classNames(styles.col, styles.col55, "justify-content-center")}>
      <Button kind="secondary" className="align-self-md-end">Edit</Button>
    </div>
  </div>);
}

export default RoleList;
