import React from "react";
import classNames from "classnames";

import useStateRef from "hooks/useStateRef";

import useConstraintValidation from "./useConstraintValidation";
import {FormControlProps} from "./types";
import RadioButton from "./RadioButton";

interface Props extends FormControlProps<string> {
  baseId: string;
  className?: string;
  data: Iterable<string>;
  disabled?: boolean;
  onBlur: (e: FocusEvent) => void;
  onFocus: (e: FocusEvent) => void;
  position: "left" | "right";
  theme: {[key: string]: string};
  value: string;
}

export default function RadioButtons(props: Props) {
  const {
    baseId,
    className,
    data,
    name,
    onBlur,
    onFocus,
    theme,
    value,
    ...restProps
  } = props;
  const [ref, setRef] = useStateRef();
  const {className: constraintClassName, setFocus} = useConstraintValidation({
    name,
    value,
    ref,
  });

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e);
    }
    setFocus(false);
  }

  function handleFocus(e) {
    if (onFocus) {
      onFocus(e);
    }
    setFocus(true);
  }

  return data.map((option, idx) => (
    <RadioButton
      {...restProps}
      className={classNames(className, constraintClassName)}
      checked={option === value}
      id={`${baseId || name}-${idx}`}
      key={option}
      name={name}
      onBlur={handleBlur}
      onFocus={handleFocus}
      ref={idx === 0 ? setRef : undefined}
      theme={theme}
      value={option}
    >
      {option}
    </RadioButton>
  ));
}
