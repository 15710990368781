import apiFetch from "util/apiFetch";

interface Data {
    message: string;
    sent_from: "C-DB" | "H-DB" | "P-DB"; // candidate, host or partner dashboard
}

export const contactUs = ({data}:{data:Data}):Promise<any> => {
  const url = Variables.urls.messaging.contact_us;
  return apiFetch(url, {data, method: "POST"});
};
