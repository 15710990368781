// This file defines when certain feature should be active for placements.
import moment from "moment";

import {status} from "util/placement/constants";
import {status as interviewStatus} from "util/interview/constants";

// Defines buttons, e.g. as shown in the "Quick actions" dropdown or column on
// the host dashboard.
export const BUTTONS = {
  // An interview can be offered.
  OFFER_INTERVIEW: "Offer interview",
  // An interview has been offered, but the candidate has not selected a date yet.
  DISABLED_STATE: "Disabled state",
  // The interview is confirmed, but still too far away to join the call.
  WAIT_STATE: "Wait state",
  // The host needs to add a meeting link.
  ADD_MEETING_LINK: "Add meeting link",
  // The interview can now be joined.
  JOIN_INTERVIEW_CALL: "Join interview call",
  // View the application from quick actions.
  VIEW: "View",
  // The candidate can be disqualified.
  DISQUALIFY: "Decline",
  // An offer can be made to the candidate.
  MAKE_OFFER: "Make offer",
  // Like/Dislike button
  LIKE_DISLIKE: "Voting",
  VIEW_APPLICATION: "View application",
  MESSAGING: "Messaging",
  // For collaborator
  SUGGEST_DECLINE: "Suggest to decline",
  SUGGEST_OFFER_ROLE: "Suggest to offer role",
};

export const DISPLAY = {
  INITIALS: "Initials",
  FULL_NAME: "Full name",
  PROFILE_PICTURE: "profile picture",
  // Allow viewing of offered times.
  VIEW_DATES: "View dates",
  // Allow viewing the selected time.
  VIEW_TIME: "View time",
};

export const FEATURES = {
  ...BUTTONS,
  ...DISPLAY,
};

const FEATURE_STATES = {
  [BUTTONS.OFFER_INTERVIEW]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.ARCHIVED,
    status.INTERVIEW_COMPLETED,
    status.WITHDRAWN,
    status.ACCEPTED,
    status.DECLINED,
    status.OFFERED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_DECLINE,
    status.EXPIRED,
    status.COMPLETED,
  ],
  [BUTTONS.DISABLED_STATE]: [status.INTERVIEW_OFFERED],
  [BUTTONS.ADD_MEETING_LINK]: [
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.INTERVIEW_OFFERED,
  ],
  [BUTTONS.JOIN_INTERVIEW_CALL]: [
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.INTERVIEW_CONFIRMED
  ],
  [BUTTONS.VIEW]: [
    status.DECLINED,
    status.CANCELLED,
    status.ARCHIVED,
    status.INTERVIEW_COMPLETED,
    status.OFFERED,
    status.ACCEPTED,
    status.WITHDRAWN,
    status.PENDING,
    status.COMPLETED,
    status.SHARED_WITH_YOU,
  ],
  [BUTTONS.DISQUALIFY]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.ARCHIVED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.WITHDRAWN,
    status.OFFERED,
  ],
  [BUTTONS.MAKE_OFFER]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.ARCHIVED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.WITHDRAWN,
    status.DECLINED,
  ],
  [BUTTONS.LIKE_DISLIKE]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.SHARED_WITH_YOU,
  ],
  [BUTTONS.VIEW_APPLICATION]: [
    status.DECLINED,
    status.OFFERED,
    status.ARCHIVED,
    status.ACCEPTED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.WITHDRAWN,
    status.PENDING,
    status.EXPIRED,
    status.VIEWED,
    status.SAVED,
    status.CONTACTING,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_DECLINE,
    status.CANCELLED,
    status.UNROUTEABLE,
    status.COMPLETED,
    status.SHARED_WITH_YOU,
  ],
  [BUTTONS.MESSAGING]: [
    status.DECLINED,
    status.OFFERED,
    status.ACCEPTED,
    status.ARCHIVED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_TIME_REQUESTED,
    status.WITHDRAWN,
    status.PENDING,
    status.EXPIRED,
    status.VIEWED,
    status.SAVED,
    status.CONTACTING,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_DECLINE,
    status.CANCELLED,
    status.COMPLETED,
  ],

  [DISPLAY.FULL_NAME]: [
    status.OFFERED,
    status.ACCEPTED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.WITHDRAWN,
    status.COMPLETED,
  ],

  [DISPLAY.INITIALS]: [
    // keep in sync with src/placements/constants.py
    status.DECLINED,
    status.PENDING,
    status.EXPIRED,
    status.VIEWED,
    status.SAVED,
    status.CONTACTING,
    status.INTERVIEW_DECLINE,
    status.CANCELLED,
    status.UNROUTEABLE,
    status.SHARED_WITH_YOU,
  ],

  [DISPLAY.PROFILE_PICTURE]: [status.INTERVIEW_DECLINE],

  [DISPLAY.VIEW_TIME]: [status.INTERVIEW_OFFERED],

  [BUTTONS.SUGGEST_DECLINE]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.WITHDRAWN,
    status.OFFERED,
  ],
  [BUTTONS.SUGGEST_OFFER_ROLE]: [
    status.PENDING,
    status.VIEWED,
    status.SAVED,
    status.INTERVIEW_OFFERED,
    status.INTERVIEW_CONFIRMED,
    status.INTERVIEW_TIME_REQUESTED,
    status.INTERVIEW_MEETING_LINK_ADDED,
    status.RESCHEDULE_REQUIRED,
    status.INTERVIEW_COMPLETED,
    status.INTERVIEW_DECLINE,
    status.WITHDRAWN,
    status.DECLINED,
  ],
};

const interviewToPlacement = (placement) => {
  const {status, interview_status} = placement;
  if (!interview_status) {
    return status;
  }
  if (status.startsWith("reschedule")) {
    return interview_status.replaceAll("_", " ");
  }
  if (status.startsWith("interview")) {
    return (
      "interview " +
      interview_status
        .replace("interview_", "")
        .replace("accept", "confirm")
        .replace("decline", "declined")
        .replaceAll("_", " ")
    );
  }
  return status; // non interview status doesn't require any changes
};

/**
 * Check for features that should be active, for the given placement status.
 */
export function getActiveFeatures(
  user: Record<string, unknown> | string | null,
  placement: Record<string, unknown>,
  collaborator = false,
  owner = false,
): Set<string> {
  placement.status = interviewToPlacement(placement);
  const activeFeatures = new Set(
    Object.entries(FEATURE_STATES)
      .filter(([_, statuses]) => statuses.includes(placement?.status))
      .map(([feature, _]) => feature),
  );

  if (!user) {
    activeFeatures.forEach((feature) => {
      if (feature != FEATURES.VIEW_APPLICATION) {
        activeFeatures.delete(feature);
      }
    });
  }
  else {
    if (!owner) {
      if (activeFeatures.has(BUTTONS.DISQUALIFY)) {
        activeFeatures.delete(BUTTONS.DISQUALIFY);
      }

      if (activeFeatures.has(BUTTONS.MAKE_OFFER)) {
        activeFeatures.delete(BUTTONS.MAKE_OFFER);
      }
    }
    if (owner) {
      if (activeFeatures.has(BUTTONS.SUGGEST_DECLINE)) {
        activeFeatures.delete(BUTTONS.SUGGEST_DECLINE);
      }

      if (activeFeatures.has(BUTTONS.SUGGEST_OFFER_ROLE)) {
        activeFeatures.delete(BUTTONS.SUGGEST_OFFER_ROLE);
      }
    }

    if (!collaborator && !owner) {
      if (activeFeatures.has(BUTTONS.OFFER_INTERVIEW)) {
        activeFeatures.delete(BUTTONS.OFFER_INTERVIEW);
      }
      if (activeFeatures.has(BUTTONS.MESSAGING)) {
        activeFeatures.delete(BUTTONS.MESSAGING);
      }
      if (activeFeatures.has(BUTTONS.ADD_MEETING_LINK)) {
        activeFeatures.delete(BUTTONS.ADD_MEETING_LINK);
      }
      if (activeFeatures.has(BUTTONS.JOIN_INTERVIEW_CALL)) {
        activeFeatures.delete(BUTTONS.JOIN_INTERVIEW_CALL);
      }
      if (activeFeatures.has(DISPLAY.VIEW_TIME)) {
        activeFeatures.delete(DISPLAY.VIEW_TIME);
      }
    }

    // if (placement.interview_status === interviewStatus.INTERVIEW_OFFERED) {
    //   activeFeatures.delete(BUTTONS.ADD_MEETING_LINK);
    // }

    if (placement.interview?.interview_dates) {
      const interview_selected_date = placement.interview.interview_dates?.filter(
        (date) => date.status === "selected",
      )[0];

      if (interview_selected_date) {
        const selectedDate = moment(interview_selected_date.date_time);
        const duration = interview_selected_date.duration;

        // If the interview is too far away, swap out the "call" state with a "wait" state.
        // else if the interview time is gone, remove "call"
        if (activeFeatures.has(FEATURES.JOIN_INTERVIEW_CALL)) {
          if (selectedDate.diff(moment(), "minutes") > 15) {
            activeFeatures.delete(FEATURES.JOIN_INTERVIEW_CALL);
            activeFeatures.add(FEATURES.WAIT_STATE);
          }
          else if (selectedDate.diff(moment(), "minutes") + duration < 0) {
            activeFeatures.delete(FEATURES.JOIN_INTERVIEW_CALL);
          }
        }
      }
    }
  }
  return activeFeatures;
}

export const getName = ({activeFeatures, user}):string => {
  const {first_name: firstName, last_name: lastName} = user;
  if (
    Variables.isAuthenticated &&
    Variables.uuids?.candidate &&
    Variables.uuids.user == user.uuid
  ) {
    return `${firstName} ${lastName}`;
  }
  if (activeFeatures.has(DISPLAY.FULL_NAME)) {
    return `${firstName} ${lastName}`;
  }
  if (user.initials) {
    let initials = "";
    if(firstName)
    {
      initials += firstName.substr(0, 1).toUpperCase()
      if(firstName.length > 1)
        initials += firstName.substr(1, 1)
      initials += "."
    }
    if(lastName)
    {
      initials += lastName.substr(0, 1).toUpperCase()
      if(lastName.length > 1)
        initials += lastName.substr(1, 1)
      initials += "."
    }
    return initials;
  }
  return `${firstName ? firstName[0] : ""}.${
    lastName ? lastName[0] : ""
  }.`.toUpperCase();
};

export const getFullName = ({user}:{user: Record<string,unknown>}):string => {
  const {first_name: firstName, last_name: lastName} = user;
  return `${firstName} ${lastName}`;
};
