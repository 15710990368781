import React from "react";

interface Props {
  /** A list of errors to display. */
  errors: string[] | undefined;
  /** The name of the input that has errors. This is used to scroll it into view. */
  name: string;
  children?: React.ReactNode;
}

export default function ErrorMessage({errors, name, children = null}: Props): JSX.Element | null {
  if (!errors?.length) {
    return null;
  }
  if (errors.length > 1) {
    return (
      <ul className="text-danger" data-error-for={name}>
        {errors.map(error => <li key="error">{error}</li>)}
      </ul>
    );
  }
  return <p className="text-danger d-flex" data-error-for={name}>{children}&nbsp;{errors[0]}</p>;
}
