import React, {useState} from "react";
import moment from "moment";
import _ from "lodash";
import {Content} from "components/shared/Slate";
import Modal from "components/shared/Modal/Modal";
import styles from "./offerSentModal.module.scss";
import {
  Heading,
  Body,
} from "workfinder-components";
import {CandidateCard} from "./OfferSentModal";


export default function OfferExtensionDeclineModal({candidate, onClose, open, ...modalProps}) {
  if (!open) {
    return null;
  }
  const offer_end_date = moment(candidate.offer_end_date).format("YYYY-MM-DD");
  return (
    <Modal id="offer-extension-decline-modal"
      open={open}
      onClose={onClose}
      className={styles.offerSentModal}
      {...modalProps}
    >
      <Content className={styles.content}>
        <div className="mt-4 mb-3">
          <span className={styles.noticeLabel}>
            ⏳ Extension declined
          </span>
        </div>
        <CandidateCard candidate={candidate} isExtension={true} />
        <div className="mt-3">
          <Heading size="h5">Thank you for letting us know!</Heading>
          <Body>We have marked that you do not wish to extend {candidate.user.full_name}&apos;s time with you.
          The role will therefore end on {offer_end_date} and we will help {candidate.user.full_name} move on to their next role.</Body>
        </div>
      </Content>
    </Modal>
  );
}
