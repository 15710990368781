import React, {useState, RefObject} from "react";
import classNames from "classnames";
import styles from "./errorPopup.module.scss";
import {usePopper} from "react-popper";


interface Props {
  closable?: boolean;
  errors: string[];
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  refElement: RefObject<Element>;
}

function ErrorPopup({errors, onClose, refElement, closable = true}: Props): JSX.Element {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const {styles: popperStyles, attributes} = usePopper(refElement.current, popperElement, {
    placement: "right",
    modifiers: [
      {name: "arrow", options: {element: arrowElement}},
      {name: "flip", options: {fallbackPlacements: ["left", "bottom-end"]}},
      {name: "offset", options: {offset: [0, 12]}},
      {
        name: "preventOverflow",
        options: {
          rootBoundary: "document",
        },
      },
    ],
  });

  let message;

  if (errors.length > 1) {
    message = <ul>{errors.map(error => <li key={error}>{error}</li>)}</ul>;
  }
  else {
    message = errors[0];
  }

  return (
    <div
      ref={setPopperElement}
      className={classNames(styles.errorPopup)}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {message}
      {
        closable &&
        <button className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
      }
      <div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow}
        {...attributes.arrow}
      ></div>
    </div>
  );
}

export default ErrorPopup;
