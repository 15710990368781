import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {EventInterface} from "util/events";
import {mergeClassNames} from "styles/utils";
import Modal from "components/shared/Modal/Modal";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./compensationModal.module.scss";
import {Content, Header} from "components/shared/Slate";
import Input from "components/shared/Input";
import {useProjectMutation} from "services/mutations";
import Dropdown from "components/shared/Dropdown";
import Tooltip from "components/shared/Tooltip";
import {AutoSaving, CurrencyInput} from "workfinder-components";
import * as yup from "yup";
import {Map} from "immutable";
import _ from "lodash";
import {yupErrorToMapping} from "util/errors";

import Button from "../Button";
import useTrackedState from "hooks/useTrackedState";
import {useProjectSalaryRequestCountQuery} from "services/queries";
import PulseLoader from "react-spinners/PulseLoader";
import LoadingSpinner from "components/shared/LoadingSpinner";

const styles = mergeClassNames(modalTheme, baseStyles);

interface Compensation {
  /** The ID of the modal. */
  id: string;
  /** The Project UUID. */
  projectId: string;
  /** The Role Name. */
  roleName: string;
  /** A callback to call when to modal closes. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
}

export const schema = yup.object().shape({
  currency: yup.string()
    .required()
    .label("currency"),
  min_salary: yup.number()
    .required()
    .min(1)
    .label("Minimum Salary"),
  max_salary: yup.number()
    .required()
    .min(yup.ref("min_salary"), ({min}) => "Max salary must be more than min salary")
    .label("Maximum Salary"),
  rate: yup.string()
    .required()
    .label("Rate")
    .default("per hour"),
});

export const RATE_OPTIONS = [
  {value: "per hour", label: "per hour"},
  {value: "per day", label: "per day"},
  {value: "per week", label: "per week"},
  {value: "per month", label: "per month"},
  {value: "per year", label: "per year"},
];

export const CURRENCY_OPTIONS = [
  {value: "USD", label: "USD", code: "US", symbol: "$"},
  {value: "GBP", label: "GBP", code: "GB", symbol: "£"},
  {value: "EUR", label: "EUR", code: "EU", symbol: "€"},
  {value: "AUD", label: "AUD", code: "AU", symbol: "$"},
  {value: "CHF", label: "CHF", code: "CH", symbol: "₣"},
];
export const BasicCompensation = function ({handleProjectChange, project, errors, disabled=false, getSavingStates=(name)=>({})}) {

  return (<>
    <div className={classNames("row", styles.compensationContainer)}>
      <div>
        <p className="mb-2">Currency*</p>
        <CurrencyInput
          className="w-100"
          name="currency"
          id="project-currency"
          key="currency"
          onChange={value => handleProjectChange(value, "currency")}
          currency={project.get("currency")?.toUpperCase()}
          disabled={disabled}
        />
        <div style={{marginBottom: 2}}/>
        <AutoSaving {...getSavingStates("currency")}/>
      </div>
      <div className="d-flex flex-row">
        <div>
          <p className="mb-2">Min*</p>
          <Input
            type="number"
            className="w-100"
            name="min_salary"
            id="project-min-salary"
            key="min_salary"
            required
            onChange={e => {
              handleProjectChange(Number(e.target.value), "min_salary");
            }}
            placeholder="Min. amount"
            value={project.get("min_salary", undefined)}
            disabled={disabled}
          />
          <AutoSaving {...getSavingStates("min_salary")}/>
        </div>
        <div className={styles.betweenRange}>to</div>
        <div>
          <p className="mb-2">Max*</p>
          <Input
            type="number"
            className="w-100"
            name="max_salary"
            id="project-max-salary"
            key="max_salary"
            required
            onChange={e => {
              handleProjectChange(Number(e.target.value), "max_salary");
              if (!project.get("rate")) { // let's just check it once
                handleProjectChange("per hour", "rate");// default rate
              }
            }}
            placeholder="Max. amount"
            value={project.get("max_salary", undefined)}
            disabled={disabled}
          />
          <AutoSaving {...getSavingStates("max_salary")}/>
        </div>
      </div>
      <div>
        <p className="mb-2">Rate*</p>
        <Dropdown
          className="w-100"
          name="rate"
          id="project-rate"
          key="rate"
          onChange={(name, value) => handleProjectChange(name, value)}
          options={RATE_OPTIONS}
          value={project.get("rate", undefined) || "per hour"}
          disabled={disabled}
        />
        <AutoSaving {...getSavingStates("rate")}/>
      </div>
    </div>
  </>);
};

export const BasicCompensationInfo = function() {
  return (
    <div className={styles.descriptionTile}>
      <h3 className={styles.tileHeader}>Why compensation transparency will supercharge your role</h3>
      <p className={styles.tileDescription}>
        Over 55% of candidates would rather apply to a role with transparent compensation
        and 46% of candidates will not attend an interview unless they know the compensation.
        Recruiters that foster compensation transparency can build trust with talent by displaying
        equality and fairness upfront.
      </p>
    </div>
  );
};

export const compensationModalInterface = new EventInterface<Omit<Compensation, "id">>("compensationModal");

export default function CompensationModal({projectId, roleName, onClose, open}: Compensation) {
  const [project, setProject, setReferenceProject, hasProjectChanged] = useTrackedState(Map({
    currency: "GBP",
    min_salary: undefined,
    max_salary: undefined,
    rate: "per hour",
  }));

  const [roleTitle, setRoleTitle] = useState(roleName);

  const {data: project_info, isLoading: countLoading, isSuccess: isQuerySuccess} = useProjectSalaryRequestCountQuery({project_uuid: projectId}, {
    enabled: !!projectId,
  });

  React.useEffect(() => {
    if (project_info) {
      setReferenceProject(Map({
        currency: project_info.currency ?? "GBP",
        min_salary: project_info.min_salary ?? undefined,
        max_salary: project_info.max_salary ?? undefined,
        rate: project_info.rate ?? "per hour",
      }));
      setRoleTitle(project_info.name);
    }
  }, [isQuerySuccess]);

  useEffect(reset, [projectId]);

  function reset() {
    setReferenceProject(Map({
      currency: "GBP",
      min_salary: null,
      max_salary: null,
      rate: "per hour",
    }));
  }

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const projectMutation = useProjectMutation(projectId);
  const projectUrl = Variables.urls.projects.detail.replace(/<uuid(:uuid)?>/, projectId);

  function validate(abortEarly = false) {
    return schema.validate(project.toJSON(), {strict: true, abortEarly: abortEarly})
      .then(() => {
        setErrors({});
        setIsDisabled(false);
        return true;
      })
      .catch(e => {
        setIsDisabled(true);
        setErrors(yupErrorToMapping(e));
        return false;
      });
  }
  const updateSalaryInformation = () => {
    validate(false).then(is_valid => {
      setIsLoading(true);
      if (is_valid) {
        projectMutation.mutateAsync(_.pick(project.toJSON(), Object.keys(schema.fields)))
          .then(project => {
            setIsLoading(false);
            onClose();
          })
          .catch(errors => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    if (hasProjectChanged) {
      validate(true);
    }
  }, [project]);

  function handleProjectChange(value, name) {
    setProject(data => data.set(name, value));
  }

  return (
    <Modal
      id="compensation-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={classNames(styles.mobileBottom, styles.extraTall)}
    >
      <Header className={styles.header}>
        <h3>Share your compensation for <a href={projectUrl} target="_blank" rel="noreferrer">{roleTitle}</a>
          <Tooltip
            iconClassName={classNames(styles.toolTipIcon, "d-inline ml-2 align-middle")}
            delayHide={1200}
            clickable={true}
          >
            <div>
              <p>For roles longer than 150 hours compensation needs to be paid
              to protect individuals and to be in line with our <a href="https://superpower.tech/terms-and-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
              &nbsp;and&nbsp;<a href="https://superpower.tech/voluntary-work-placements/" target="_blank" rel="noreferrer">Code of Conduct for Voluntary Roles</a>.</p>
            </div>
          </Tooltip>
        </h3>
      </Header>
      <Content className="pt-0">
        <div className={styles.content}>
          {!countLoading && project_info?.no_of_requests > 0 && <p className={styles.description}>
            {project_info?.no_of_requests} candidate(s) have requested to see your compensation for this role.
          </p>}
          {!countLoading && project_info?.no_of_requests == 0 && <p className={styles.description}>Candidates can request to see your compensation for this role if you haven&apos;t shared it yet.</p>
          }

          <BasicCompensationInfo/>
          {
            countLoading && <LoadingSpinner className="d-block m-auto" />
          }
          { !countLoading &&
            <>
              <BasicCompensation
                handleProjectChange={handleProjectChange}
                project={project}
                errors={errors}
              />
              <div className="row mt-3">
                <div className="col-12">
                  <Button
                    className="float-right"
                    kind="blue-primary"
                    disabled={isDisabled}
                    onClick={() => updateSalaryInformation()}
                    type="button"
                  >
                    {
                      isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Save compensation"
                    }
                  </Button>
                </div>
              </div>
            </>
          }
        </div>
      </Content>
    </Modal>
  );
}
