import React, {useContext, useEffect, useMemo, useState, RefObject} from "react";
import classNames from "classnames";
import {FormContext, HTMLValidatableElement, ValidatorResult} from "./Form.tsx";
import ErrorPopup from "./ErrorPopup";
import {createPortal} from "react-dom";


export interface ValidatableControlProps {
  // The name of the form control.
  name?: string;
  // The value of the form control.
  value?: any;
  // A function called on the form control value to determine whether it is valid.
  validator?: (value: any) => ValidatorResult;
  // A reference to the form control element, e.g. an input, used to access built-in browser validation.
  ref?: RefObject<HTMLValidatableElement>;
  // A reference to the HTML element to which the error popup should attach.
  // If omitted is defined, ref will be used. To specifically disable the popup,
  // set this to `null`.
  anchorRef?: RefObject<HTMLElement>;
  // CSS class to apply when the form control value is invalid.
  errorClassName?: string;
  // CSS class to apply once the form control has been touched.
  touchedClassName?: string;
  // Class name to apply when the form control value is valid.
  validClassName?: string;
}

/*
 * A validation hook that integrates with the Form context API. Reusable hook
 * for all form controls.
 */
export default function useConstraintValidation(
  props: ValidatableControlProps,
) {
  const {
    name,
    value,
    validator,
    ref,
    errorClassName,
    touchedClassName,
    validClassName,
  } = props;
  const anchorRef = props.anchorRef === null ? null : props.anchorRef ?? ref;
  const {setInput, attemptedSubmit, inputs} = useContext(FormContext);
  const errors = inputs.getIn([name, "errors"]);
  const [focused, setFocus] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const className = useMemo(updateClassName, [
    value,
    errors,
    isValid,
    attemptedSubmit,
    touched,
    errorClassName,
    touchedClassName,
    validClassName,
  ]);
  const errorPopup = useMemo(() => {
    if (!(errors?.length && anchorRef)) {
      return;
    }
    return createPortal(
      <ErrorPopup
        errors={errors}
        onClose={() =>
          setInput(name, {
            isValid,
            touched,
            value,
            ref,
            errors: [],
          })
        }
        refElement={anchorRef}
      />,
      document.body,
    );
  }, [errors, anchorRef]);

  function updateClassName() {
    const valid = isValid && !errors?.length;
    const maxLength = parseInt(ref?.current.getAttribute("maxLength"));

    return classNames({
      "has-error": (attemptedSubmit || touched) && !valid,
      [errorClassName]:
        (attemptedSubmit || touched) && !valid && !!errorClassName,
      touched: touched,
      [touchedClassName]: touched && !!touchedClassName,
      valid: touched && valid,
      [validClassName]: touched && valid && !!validClassName,
      "limit-reached": maxLength && value?.length && maxLength === value?.length,
    });
  }

  function updateFormContext() {
    if (name) {
      setInput(name, {
        isValid: isValid && !errors?.length,
        touched,
        validator,
        value,
        ref,
        errors,
      });
    }
  }

  function updateIsValid() {
    // Use browser validation and custom validator to determine whether the input is valid.
    // This can send an `invalid` event that will make React whine if used inside useMemo
    const customValidation = validator?.(value);
    const isValidCustom = customValidation?.isValid ?? true;
    const isValidationBrowser = ref?.current?.checkValidity() ?? true;

    setIsValid(isValidationBrowser && isValidCustom);
  }

  // Clear the input from the form when the component unmounts
  useEffect(() => {
    updateFormContext();
    return () => setInput(name, undefined);
  }, []);

  // Force the `touched` flag to be set when an element is focused.
  useEffect(() => {
    if (focused && !touched) {
      setTouched(true);
    }
  }, [focused]);

  useEffect(updateFormContext, [errors, name, value, ref, isValid, setInput]);

  useEffect(updateIsValid, [isValid, ref, value]);

  return {className, isValid, setFocus, errorPopup};
}
