import React, {useState, useRef, useEffect} from "react";
import classNames from "classnames";

import Cookies from "js-cookie";
import {featureUrl, interestEvent} from "util/cms/constants";
import {Content} from "components/shared/Slate";
import modalTheme from "styles/modals/flat.module.scss";
import Modal from "components/shared/Modal/Modal";
import baseStyles from "./congratulationsModal.module.scss";
import {mergeClassNames} from "styles/utils";
import Globe from "/static/workfinder/images/globe.svg";
import {
  Heading,
  Body,
  Button,
} from "workfinder-components";
import DropdownMenu from "components/shared/DropdownMenu";
import Twitter from "/static/workfinder/images/icons/my-projects-quick-actions/twitter.svg";
import Facebook from "/static/workfinder/images/icons/my-projects-quick-actions/facebook.svg";
import Linkedin from "/static/workfinder/images/icons/my-projects-quick-actions/linkedin.svg";
import Email from "/static/workfinder/images/icons/my-projects-quick-actions/email.svg";
import CopyURL from "/static/workfinder/images/icons/my-projects-quick-actions/copy-url.svg";
import LinkCopiedImg from "/static/workfinder/images/icons/my-projects-quick-actions/link-copied.svg";
import EventTracking from "tracking/EventTracking";
import ShareModal from "components/shared/Modal/ShareModal";
const styles = mergeClassNames(modalTheme, baseStyles);

interface Props {
  /** Whether the Congratulations modal is open. */
  open: boolean;
  /** A callback to call when the modal is closed. */
  onClose: () => void;
  /** The UUID of the placement to share. */
  projectUUID?: string;
  /** The name of the project */
  projectName?: string;
  /** Whether to show share button or not */
  hasCollaboration : boolean;
  /** Is company profile incomplete */
  companyProfileIncomplete?: boolean;
  /** Is Business skills not updated */
  businessSkillsIncomplete?: boolean;
  /** An object to restyle the element, mirroring the style element. */
  theme?: {[key: string]: string};
  /** Is Project is reopened */
  isReOpened?: boolean;
}

/**
 * A modal for sharing placements.
 */
export default function CongratulationsModal(props: Props): JSX.Element {
  const {
    open,
    onClose,
    projectUUID,
    projectName,
    hasCollaboration,
    companyProfileIncomplete,
    isReOpened,
    businessSkillsIncomplete, ...modalProps} = props;
  const [btnLabel, setBtnLabel] = useState("Share role");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const projectUrl = new URL(`/role/${projectUUID}`, window.location.origin);
  const projectEditUrl = new URL(`/recruiter/opportunity/edit/${projectUUID}`, window.location.origin);
  const [linkCopied, setLinkCopied] = useState(false); // set only for one second
  const menuButtonId = `modal-project-${projectUUID}`;
  const ref = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  useEffect(() => $(".dropdown-toggle").dropdown({popperConfig: {placement: "bottom"}}));

  useEffect(() => setDropdownHeight(ref.current.clientHeight));

  function commonSocialMediaActions() {
    Cookies.remove("post-project");
    Cookies.remove("post-project-name");
  }

  const encodedProjectUrl = encodeURIComponent(projectUrl.href);
  const first_message = encodeURIComponent("Are you ready to boost your career?");
  const message = encodeURIComponent(`Are you ready to boost your career? Apply to our new open role today: ${projectName} ${projectUrl}`);

  const socialMediaItems = [
    {
      icon: <div className={styles.icon}><img src={Twitter} alt="Twitter" /></div>,
      label: "Twitter",
      onClick: () => {
        commonSocialMediaActions();
        window.open(`https://twitter.com/share?text=${message}`, "_blank");
      },
    },
    {
      icon: <div className={styles.icon}><img src={Facebook} alt="Facebook" /></div>,
      label: "Facebook",
      onClick: () => {
        commonSocialMediaActions();
        window.open(`https://facebook.com/sharer/sharer.php?u=${encodedProjectUrl}`, "_blank");
      },
    },
    {
      icon: <div className={styles.icon}><img src={Linkedin} alt="Linkedin" /></div>,
      label: "Linkedin",
      onClick: () => {
        const link = `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodedProjectUrl}&amp;source=${encodedProjectUrl}&amp;title=${first_message}&amp;summary=${message}`.
          replaceAll(" ", "").replaceAll("%2F", "/").replaceAll("&amp;", "&");
        commonSocialMediaActions();
        window.open(link, "_blank");
      },
    },
    {
      icon: <div className={styles.icon}><img src={Email} alt="Email" /></div>,
      label: "Email",
      onClick: () => {
        commonSocialMediaActions();
        location.href = `mailto:test@example.com?subject=${first_message}&body=${message}`;
      },
    },
    {
      icon: <div className={styles.icon}><img src={CopyURL} alt="CopyURL" /></div>,
      label: "Copy URL",
      onClick: () => {
        setLinkCopied(true);
        setTimeout(() => {
          setLinkCopied(false);
          commonSocialMediaActions();
        }, 1000);
        navigator.clipboard.writeText(projectUrl);
      },
    },
  ];
  function handleInvite() {
    Cookies.remove("post-project");
    Cookies.remove("post-project-name");
    setIsShareModalOpen(true);
  }

  function openProjectUrl() {
    Cookies.remove("post-project");
    Cookies.remove("post-project-name");
    window.open(projectUrl);
  }

  function openProjectEdit() {
    Cookies.remove("post-project");
    Cookies.remove("post-project-name");
    window.open(projectEditUrl);
  }

  function openCompanyDetail() {
    Cookies.remove("post-project");
    Cookies.remove("post-project-name");
    window.open("/recruiter/my-account/company");
  }

  return (<>
    <Modal id="contratulation-modal" theme={styles} open={open} onClose={onClose} className={styles.mobileBottom} {...modalProps}>
      <Content className={styles.content}>
        <div className="d-flex align-items-center flex-column mt-4">
          <img width="215px" src={Globe} alt="globe" />
          <div className={styles.blue}>
            <Heading size="h4">{isReOpened ? "It's go time!" : "Congratulations your role is live!"}</Heading>
          </div>
          {isReOpened ? <><Body size="large" className="text-center">
            <div className="text-center"><b>Your role is being marketed again!</b><br/></div>
          </Body><Body size="medium" className="text-center">We have resumed marketing your role to candidates who match your role’s skill requirements. Share your role to spread the word and invite your colleagues as collaborators to bring superpowers onto your team!
          </Body></>: <Body size="large" className="text-center">
            {
              (companyProfileIncomplete || businessSkillsIncomplete) && hasCollaboration &&
              <div className="text-center">Share your role to spread the word, invite colleagues as collaborators and complete our recommendations below to fill your role faster:<br/></div>
            }
            {
              (companyProfileIncomplete || businessSkillsIncomplete) && !hasCollaboration &&
              <div className="text-center">Your role will be recommended to young people and will show up in their dashboard.<br/></div>
            }
            {
              (!companyProfileIncomplete && !businessSkillsIncomplete) &&
              <div className="text-center">We have started our search for people who have the skills you need and will notify you once we’ve got a short-list ready for you to review.<br/>
              This is usually ready for you within 72 hours.<br/></div>
            }
            {
              (!companyProfileIncomplete && !businessSkillsIncomplete) && hasCollaboration &&
              <div className="text-center">Until then, share your role to spread the word and invite your colleagues as collaborators to bring superpowers onto your team!<br/></div>
            }
          </Body>}
        </div>
        <div className={styles.inputGroup}>
          <Button
            kind="secondary"
            onClick={openProjectUrl}
          >
            View role
            <span className="material-icons-outlined pl-2">
              visibility
            </span>
          </Button>
          <Button
            kind="secondary"
            onClick={() => {
              if (hasCollaboration) {
                handleInvite();
              }
              else {
                EventTracking.send({
                  content_object: {
                    object_id: Variables.user.pk,
                    object_type: "User",
                  },
                  event_type: interestEvent.COLLABORATION,
                });
                window.open(featureUrl.COLLABORATION);
              }
            }}
          >
              Invite collaborators
            <span className="material-icons-outlined pl-2">
                person_add
            </span>
          </Button>
          <div className={classNames("dropdown", styles.dropdown)} onClick={e => e.stopPropagation()}>
            <button
              type="button"
              id={menuButtonId}
              className={classNames("dropdown-toggle", styles.buttonSecondary)}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              role="button"
            >
              {btnLabel}
              <span className="material-icons-outlined pl-2">
                share
              </span>
            </button>
            <DropdownMenu items={socialMediaItems} toggleId={menuButtonId} ref={ref}/>
            {linkCopied ?
              <img
                style={{position: "absolute", left: 0, top: dropdownHeight+52, zIndex: 35}}
                src={LinkCopiedImg}
                alt="LinkCopied"
              />
              : null}
          </div>
        </div>
        {companyProfileIncomplete &&
          <div className={styles.redBg}>
            <Heading size="h5">🚨 Company profile is incomplete!</Heading>
            <Body size="large">Please complete your company profile so that we can start recommending your live role to the most skilled people externally.</Body>
            <Button
              className="w-100"
              kind="secondary"
              onClick={openCompanyDetail}
            >
                Complete company profile
            </Button>
          </div>
        }
        {/* {businessSkillsIncomplete && !companyProfileIncomplete &&
          <div className={styles.yellowBg}>
            <Heading size="h5">🌟 Top tip: Share your role’s tech data business skills!</Heading>
            <Body size="large">We would love it if you could share a few more details with us as we’d like to make sure that there is as close a skills match as possible and we’ve got a lot of potential candidates to look through for you! Please take 15 seconds to tell us your top three tech data and business skills:</Body>
            <Button
              className="w-100 mb-0"
              kind="secondary"
              onClick={openProjectEdit}
            >
                Select tech data business skills
            </Button>
          </div>
        } */}
      </Content>
    </Modal >
    {isShareModalOpen && <ShareModal
      open={isShareModalOpen}
      onClose={() => setIsShareModalOpen(false)}
      UUID={projectUUID}
      kind="project"
    />}
  </>);
}
