import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Heading, Subtitle, Input, Button } from "workfinder-components"
import {DuedilAsyncTypeaheadSignUp} from "components/shared/DuedilAsyncTypeahead";
import * as yup from "yup";
import {Map} from "immutable";
import {yupErrorToMapping} from "util/errors";
import useTrackedState from "hooks/useTrackedState";
import api from "services/v3";
import EmailBox from "components/shared/EmailBox/index";
import PulseLoader from "react-spinners/PulseLoader";
import ThankYouModal from "components/shared/Modal/ThankYouModal";
import useStoredState from "hooks/useStoredState";

const commonSchema = {
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .nullable()
    .label("Last Name"),
};
const step1Schema = yup.object().shape(commonSchema);
const step2Schema = yup.object().shape({
    ...commonSchema,
    company_name: yup
      .string()
      .required()
      .trim("Company Name is a required field")
      .label("Company Name")
});

export const StepBars = ({currentStep, totalSteps}) => {
  const stepStripes = [];
  for(let i = 0; i < totalSteps; i++) {
    stepStripes.push(
      <hr className={classNames(styles.step, i < currentStep ? styles.selected : "")}/>
    )
  }

  return (
    <div className={styles.stepContainer}>
      {stepStripes}
    </div>
  );
}

const HostSetup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userErrors, setUserErrors] = useState<Record<string, string[]>>({});
  const [showCompanyTextBox, setShowCompanyTextBox] = useState(false);
  const [skipSteps23, setSkipSteps23] = useState(false);
  const [companySlug, setCompanySlug] = useState("");
  const [user, setUser, setReferenceUser, hasUserChanged] = useTrackedState(Map({
    first_name: "",
    last_name: "",
    company_name: "",
    duedil_company_id: "",
  }));
  const [companyUuid, setCompanyUuid] = useState(null);
  const url = new URL(window.location.href);
  const user_uuid = url.pathname.split("/").slice(-1)[0];
  const [header, setHeader] = useState("Welcome!");
  const [label1, setLabel1] = useState("First things first, tell us a bit about yourself.");
  const [label2, setLabel2] = useState("What is your name?");
  const [storedState, setStoredState, removeStoredState] = useStoredState(
    {name: "", icon: "", description: "", billingCycle: "", chargebeeIds: {}},
    'plan-info',
    'signup'
  );

  const redirect = () => {
    if(storedState && storedState.name && storedState.billingCycle)
      window.location.href = `${window.location.origin}/payment-info/`;
    else
      window.location.href = "/";
  }

  useEffect(() => {
    if(skipSteps23) {
      setHeader("Welcome to the team!");
      setLabel1("First things first, what is your name?");
      setLabel2("");
    }
    else {
      setHeader("Welcome!");
      setLabel1("First things first, tell us a bit about yourself.");
      setLabel2("What is your name?");
    }
  }, [skipSteps23]);

  async function loadCompanyData() {
    try {
      setIsLoading(true);
      const user = await api.getUserUpToCompany(true);
      const association = user.host.association;
      const location = association.location;
      const company = location.company;
      if(company.name != "Unspecified company") {
        setSkipSteps23(true);
        setUser(data => data.set("company_name", company.name))
      }
      setCompanyUuid(company.uuid);
      setUser(data => data.set("first_name", user.first_name))
      setUser(data => data.set("last_name", user.last_name))
      if(!!company.slug) {
        setCompanySlug(company.slug);
      }
      else {
        const slug = company.uuid.replace(/-/g, "");
        await api.updateCompany(company.uuid, {
          slug: slug
        });
        setCompanySlug(slug);
      }
      setIsLoading(false);
    }
    catch {
      setIsLoading(false);
      // addAlert("my-company-load", "danger", messages.infoLoadError);
    }
  }

  useEffect(() => {
    validate(true);
  }, [user]);

  useEffect(() => {
    loadCompanyData();
  }, []);

  async function validate(abortEarly) {
    const is_valid = await (
        currentStep == 1 ? step1Schema : step2Schema
    ).validate(user.toJSON(), {strict: true, abortEarly: abortEarly})
      .then(() => {
        setUserErrors({});
        if (currentStep == 1 && !skipSteps23) {
          setCurrentStep(2);
        }
        else {
          setIsDisabled(false);
        }
        return true;
      })
      .catch(e => {
        setIsDisabled(true);
        setUserErrors(yupErrorToMapping(e));
        return false;
      });
    return is_valid;
  }

  async function submitForm() {
    const is_valid = await validate(false);
    if(is_valid) {
      setIsLoading(true);
      const onSuccess = () => {
        if(skipSteps23)
          redirect();
        else
          setCurrentStep(3);
        setIsLoading(false);
        setReferenceUser(Map());
        setShowCompanyTextBox(false);
      };
      await api.updateUser({
        first_name: user.get("first_name"),
        last_name: user.get("last_name"),
      });
      if(!skipSteps23) {
        await api.updateCompany(companyUuid, {
          name: user.get("company_name"),
          duedil_company_id: user.get("user_company_id"),
          // domains: [user.get("domain")],
        });
      }
      onSuccess();
    }
  }

  function handleUserChange(value, name) {
    setUser(data => data.set(name, value));
  }

  const updateSelectedCompany = (result) => {
    const {companyId, countryCode} = result;
    setUser(data => data.set("company_name", result.name));
    setUser(data => data.set("duedil_company_id", companyId));
    api.getCompanyData({
      endpoint: "company_data",
      "company-id": companyId,
      "country-code": countryCode,
    }).then(res => {
      res.primaryWebsite && setUser(data => data.set("domain", res.primaryWebsite));
    });
  };
  const onCompanyNotFound= () => {
    setShowCompanyTextBox(true);
  };
  const onCompanyFound= () => {
    setShowCompanyTextBox(false);
  };
  function handleCompanyChange(value, name) {
    setUser(data => data.set(name, value));
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-9 col-md-8 col-centered">
          {(isLoading || skipSteps23) && <div className={styles.dummySteps}><hr/></div>}
          {!isLoading && !skipSteps23 && <StepBars currentStep={currentStep} totalSteps={3}/>}
          {currentStep <= 2 &&
            <>
              <Heading size="h2" className={styles.header}>{header}</Heading>
              <Subtitle size="large" className="mb-4">{label1}</Subtitle>
              <Subtitle size="large">{label2}</Subtitle>
              <div className="row align-items-end">
                <Input
                  placeholder="First name"
                  className={classNames(styles.input, "col-6")}
                  value={user.get("first_name", "")}
                  onChange={(value, e) => handleUserChange(value, "first_name")}
                  disabled={isLoading}
                />
                <Input
                  placeholder="Last name"
                  className={classNames(styles.input, "col-6")}
                  value={user.get("last_name", "")}
                  onChange={(value, e) => handleUserChange(value, "last_name")}
                  disabled={isLoading}
                />
              </div>
            </>
          }
          {currentStep == 2 &&
            <>
              <Subtitle size="large">For which organisation will you be hiring for?</Subtitle>
              <div className="pb-4">
                <DuedilAsyncTypeaheadSignUp
                  id="company"
                  className={styles.asyncAheadInput}
                  updateSelectedCompany={updateSelectedCompany}
                  onCompanyNotFound = {onCompanyNotFound}
                  onCompanyFound = {onCompanyFound}
                  value={showCompanyTextBox? "Can’t find your organisation?": user.get("company_name", "")}
                  name="company_name"
                  onChange={handleCompanyChange}
                  placeholder="Search organisation name"
                  fetchAddress={false}
                />
              </div>
              {showCompanyTextBox &&
                <Input
                  placeholder="Please enter your organisation name here"
                  value={user.get("company_name", "")}
                  onChange={(value, e) => handleUserChange(value, "company_name")}
                />
              }
            </>
          }
          {(currentStep == 2 || (currentStep == 1 && skipSteps23)) &&
            <Button disabled={isDisabled || isLoading} onClick={submitForm} className={styles.button}>
              {isLoading ? <PulseLoader color="#fff" margin={2} size={6}/> : "Continue"}
            </Button>
          }
          {currentStep == 3 &&
            <InviteTeam companySlug={companySlug} redirect={redirect}/>
          }
        </div>
      </div>
    </>
  )
}

export const InviteTeam = ({companySlug, redirect, noSkipButton=false}) => {
  const [invitationEmails, setInvitationEmails] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const url = new URL(window.location.href);
  const link = `${url.origin}/signup/recruiter/${companySlug}`

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  };

  const sendInvitations = () => {
    setIsLoading(true);
    const data = invitationEmails.map(e => { return { email: e } });
    api.createHostInvitation(data).then(response => {
      setIsSuccess(true);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      //ToDo: Handle response
    });
  }

  const clear = () => {
    setInvitationEmails([]);
    setIsSuccess(false);
  }

  return (
    <>
      <Heading size="h2" className={styles.header}>Invite your team</Heading>
      <Subtitle size="large" className="mb-4">Hiring is a team sport, go faster together</Subtitle>
      <label className="form-label">Copy this invitation link to share with your team:</label>
      <div className="input-group flex-nowrap">
        <Input
          placeholder="Please enter your organisation name here"
          value={link}
          disabled={true}
          className={classNames(styles.input, styles.inviteLink)}
          aria-describedby="basic-addon2"
        />
        <Button size="small" onClick={handleCopyClick} className={styles.copyButton} id="basic-addon2">
          Copy
          {tooltipVisible && (
              <div className={styles.tooltip}>Link copied</div>
          )}
        </Button>
      </div>
      <EmailBox
        placeholder="Enter emails here"
        label="Or send them an invitation via email:"
        emails={invitationEmails}
        setEmails={(values) => {setInvitationEmails(values)}}
      />
      <Button disabled={isLoading || !(invitationEmails && invitationEmails.length > 0)} onClick={sendInvitations} className={styles.button}>{isLoading ? <PulseLoader color="#fff" margin={2} size={6}/> : "Send invitations"}</Button>
      {!noSkipButton &&
        <Button kind="tertiary" onClick={redirect} className={classNames(styles.button, styles.skipButton)}>Skip for now</Button>
      }
      <ThankYouModal
        //@ts-ignore
        isSuccess={isSuccess}
        header={<Heading size="h4">✅ Your invitation is on it's way!</Heading>}
        message={<Subtitle>You have successfully sent your invitation to {invitationEmails.length} {invitationEmails.length > 1 ? "contacts" : "contact"}.</Subtitle>}
        onClose={clear}
        width={410}
        theme={styles}
      />
    </>
  )
}

export default HostSetup;
