import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./checkbox.module.scss";
import Input from "./Input";

function Checkbox({checked, children, disabled, id, name, onChange, theme={}, ...props}) {

  const inputClassName = classNames(
    styles.checker,
    theme.checker,
    {
      [styles.checked]: checked,
      [theme.checked]: checked,
      [styles.disabled]: disabled,
    },
  );

  function handleOnChange(_, name, e) {
    onChange(e.target.checked, name, e);
  }

  const checkbox = (
    <label
      className={inputClassName}
      htmlFor={id}
    >
      <Input
        {...props}
        checked={checked}
        id={id}
        name={name}
        onChange={handleOnChange}
        type="checkbox"
      />
    </label>
  );

  if (children) {
    return <label
      className={styles.container}
      htmlFor={id}
    >
      {checkbox}
      {children}
    </label>;
  }
  return checkbox;
}

Checkbox.propTypes = {
  children: PropTypes.any,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  props: PropTypes.object,
  theme: PropTypes.object,
};

export default Checkbox;
