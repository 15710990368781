import React from "react";
import { convertNewlineToSlashN } from "util/string";

export const textWithLineBreaks = (text, trimLength = -1) => {
  if (!text) return (<></>);
  let charLength = 0
  return convertNewlineToSlashN(text).split('\n').map((e) => {
    if(trimLength <= 0) return (<>{e}<br/></>);
    else if(trimLength > 0)
    {
      if(charLength > trimLength)
        return <></>;
      else if(charLength == trimLength)
      {
        charLength += 3;
        return<>...</>;
      }
      else if(charLength < trimLength)
      {
        if(charLength + e.length < trimLength)
        {
          charLength += e.length;
          return (<>{e}<br/></>);
        }
        else {
          let comp = (<>{e.substr(0, trimLength-charLength)}</>);
          charLength = trimLength;
          return comp;
        }
      }
    }
  });
}
