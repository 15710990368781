export const status = {
  OPEN: "open",
  INTERVIEW_OFFERED: "interview_offered",
  INTERVIEW_ACCEPTED: "interview_accepted",
  INTERVIEW_DECLINE: "interview_decline",
  INTERVIEW_TIME_REQUESTED: "interview_time_requested",
  RESCHEDULE_REQUIRED: "reschedule_required",
  INTERVIEW_COMPLETED: "interview_completed",
  MEETING_LINK_ADDED: "meeting_link_added",
  PLACEMENT_OFFERED: "placement_offered",
  FILLED: "filled",
  INTERVIEW_RESCHEDULED: "interview_rescheduled"
};

// Candidate feedback
export const candidateFeedback = {
  NPS_9_10: {
    subtitle: "We are glad to hear your interview went well.",
    request: "Please select all reasons why the interview went well:",
    responses: [
      {label: "The interview ran longer than scheduled", value: "interview_ran_longer"},
      {label: "I could answer all questions the interviewer asked me confidently", value: "answer_all_questions"},
      {label: "I was asked if I was interviewing at other companies ", value: "asked_for_other_companies"},
      {label: "The interviewer discussed next steps in the hiring process", value: "discussed_next_steps"},
      {label: "I was asked for my contact details", value: "asked_contact_detail"},
      {label: "I was offered another interview", value: "offered_another_interview"},
      {label: "I was offered a role", value: "offered_role"},
    ],
  },
  NPS_7_8: {
    subtitle: "How do you think the interview could have been better?",
    request: "Please select all reasons why the interview could have been better:",
    responses: [
      {label: "The interview ran shorter than scheduled", value: "interview_ran_shorter"},
      {label: "I couldn't answer all questions the interviewer asked me confidently", value: "not_answer_all_questions_confidently"},
      {label: "The interviewer didn't seem engaged", value: "not_engaged_interviewer"},
      {label: "The interviewer didn't mention the next steps in the hiring process", value: "not_discussed_next_steps"},
      {label: "There were technical issues in the call", value: "technical_issue"},
      {label: "I showed up late to the interview", value: "late_showed_up"},
      {label: "I didn't feel as prepared as I could have been", value: "not_prepared"},
    ],
  },
  NPS_0_6: {
    subtitle: "We are sorry to hear that your interview didn’t go well.",
    request: "Please select all reasons why the interview didn’t go well:",
    responses: [
      {label: "The interview ran shorter than scheduled", value: "interview_ran_shorter"},
      {label: "I wasn't prepared: I didn't research the role, company or interviewer", value: "not_prepared_role"},
      {label: "I couldn't answer all questions the interviewer asked me", value: "not_answer_all_questions"},
      {label: "The interviewer expressed concerns towards my skills", value: "expressed_concerns_skills"},
      {label: "The interviewer seemed distracted, annoyed or impatient", value: "distracted_interviewer"},
      {label: "The interviewer didn't ask me follow-up questions", value: "not_asked_follow_up_questions"},
      {label: "The interviewer didn't mention the next steps in the hiring process", value: "not_discussed_next_steps"},
      {label: "The interviewer mentioned that other candidates were more qualified", value: "others_were_qualified"},
      {label: "I showed up late to the interview", value: "late_showed_up"},
    ],
  },
};

export const hostFeedback = {
  NPS_9_10: {
    subtitle: "We are glad to hear your interview went well.",
    request: "Please select all reasons why the interview went well:",
    responses: [
      {label: "The interview ran longer than scheduled", value: "interview_ran_longer"},
      {label: "The candidate could answer all the questions confidently", value: "c_answer_all_questions"},
      {label: "The candidate has the experience needed for this role", value: "already_experience"},
      {label: "The candidate has the skills needed for this role", value: "already_skills"},
      {label: "The candidate's personality fits our company culture", value: "personality_fits"},
      {label: "We will offer the candidate another interview", value: "offer_another_interview"},
      {label: "We offered the role to the candidate", value: "c_offered_role"},
    ],
  },
  NPS_7_8: {
    subtitle: "How do you think the interview could have been better?",
    request: "Please select all reasons why the interview could have been better:",
    responses: [
      {label: "The interview ran shorter than scheduled", value: "interview_ran_shorter"},
      {label: "The candidate couldn't answer all the questions confidently", value: "c_not_answer_all_questions_confidently"},
      {label: "The candidate doesn't have all the experience needed for this role", value: "no_all_experience"},
      {label: "The candidate doesn't have all the skills needed for this role", value: "no_all_skills"},
      {label: "The candidate's personality might not fit our company culture", value: "might_not_fit_culture"},
      {label: "The candidate showed up late to the interview", value: "c_late_show_up"},
      {label: "The candidate was not well prepared for the interview", value: "c_not_prepared"},
      {label: "There were technical issues in the call", value: "technical_issue"},
    ],
  },
  NPS_0_6: {
    subtitle: "We are sorry to hear that your interview didn’t go well.",
    request: "Please select all reasons why the interview didn’t go well:",
    responses: [
      {label: "The interview ran shorter than scheduled", value: "interview_ran_shorter"},
      {label: "There are other candidates more qualified for this role", value: "more_qualified_candidates"},
      {label: "The candidate couldn't answer any questions confidently", value: "not_confident"},
      {label: "The candidate doesn't have the experience needed for this role", value: "no_experience"},
      {label: "The candidate doesn't have the skills needed for this role", value: "no_skills"},
      {label: "The candidate's personality will not fit our company culture", value: "not_fit_culture"},
      {label: "The candidate showed up late to the interview", value: "c_late_show_up"},
      {label: "The candidate did not research the role", value: "not_researched_role"},
      {label: "The candidate did not research the company", value: "not_researched_company"},
    ],
  },
};