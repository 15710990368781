/*
 * Groups buttons at the bottom of the container.
 */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import {mergeClassNames} from "styles/utils";

import baseStyles from "./buttonGroup.module.scss";


function ButtonGroup({children, className, theme}) {
  const styles = mergeClassNames(baseStyles, theme);

  return (
    <div className={classNames(styles.buttonGroup, className)}>
      { children }
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element.isRequired),
    PropTypes.element.isRequired,
  ]),
  className: PropTypes.string,
  theme: PropTypes.object,
};

export default ButtonGroup;
