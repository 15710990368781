import React, {useCallback, useEffect, useState} from "react";
import * as yup from "yup";

import Modal from "components/shared/Modal/Modal";
import {Content, Header} from "components/shared/Slate";
import api from "services/v3";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
import classNames from "classnames";
import {EventInterface} from "util/events";
//import {status} from "util/interview/constants";

import {yupErrorToMapping} from "util/errors";
import Button from "components/shared/Button";

import baseStyles from "./addMeetingLinkModal.module.scss";
import personIcon from "/static/workfinder/images/generic-photo.png";
import PulseLoader from "react-spinners/PulseLoader";
import { formatStartDateDurationLong, formatStartEndDateDayLongSeparately } from "util/date-time";
import  {RichTextEditor, Heading, Subtitle} from "workfinder-components";

const styles = mergeClassNames(baseStyles, modalTheme);

type InterviewDateStatus = "open" | "selected";

export interface InterviewDate {
  id: number;
  date_time: Date;
  duration: number;
  status: InterviewDateStatus;
}

/**
 * A legacy data structure inherited from the old dashboard. It combines
 * information from various sources into one object. Its variable is usually
 * also named `candidate`, even though its value is more similar to a
 * placement.
 */
export interface CandidatePlacement {
  /** The interviews attached to the placement. */
  interview?: {
    uuid: string;
    interview_dates: InterviewDate[];
    meeting_link?: string
  }
  /**
   * The ID of the placement, used to retrieve interview data when not
   * present.
   */
  placementID?: uuid;
  /** The name of the project. Only used for display purposes. */
  project?: string;
  /** Project Uuid */
  projectId?: string;
  /**
   *
   */
  user: {
    /** The user's first name. If ommitted, it is guessed from the full name. */
    first_name?: string;
    full_name?: string;
  };
}

interface AddMeetingLinkData {
  /** The callback to call when the modal is closed. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
  candidate?: CandidatePlacement;
}

export const meetingLinkModalInterface = new EventInterface<AddMeetingLinkData>("meetingLinkModal");

export default function AddMeetingLinkModal(): JSX.Element | null {
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const [open, setOpen] = useState(false);
  const [candidate, setCandidate] = useState<CandidatePlacement | undefined>();
  const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false);
  const [meetingLink, setMeetingLink] = useState<string | undefined>("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const interview = candidate?.interview;
  const selectedDate = interview?.interview_dates.filter(
    date => date.status === "selected",
  )[0];
  const interviewDate = selectedDate?.date_time;
  const duration = selectedDate?.duration;
  const projectUrl = Variables.urls.projects.detail.replace(
    /<uuid(:uuid)?>/,
    interview?.placement.associated_project || candidate?.projectId,
  );
  const placementUrl = Variables.urls.hosts.applicant_details.replace(
    /<placement_id>/,
    interview?.placement.uuid || candidate?.placementID,
  );

  // Reset when a different candidate is actioned.
  useEffect(reset, [candidate]);

  const handleModalEvent = useCallback(
    (detail: AddMeetingLinkData) => {
      setOpen(open => detail.open ?? open);
      setCandidate(detail.candidate);
      setMeetingLink(detail.candidate?.interview?.meeting_link);
      if (detail.onClose) {
        setOnClose(() => detail.onClose);
      }
      else {
        setOnClose(undefined);
      }
    }, [setOpen, setCandidate, setOnClose],
  );

  useEffect(() => {
    meetingLinkModalInterface.register(handleModalEvent);
    return () => {
      meetingLinkModalInterface.remove();
    };
  }, [handleModalEvent]);

  function handleClose() {
    onClose?.();
    setOpen(false);
    setCandidate(undefined);
    setMeetingLink("");
    setOnClose(undefined);
  }

  function reset() {
    setTimeout(() => {
      setDisplayThankYouMessage(false);
    }, 300);
  }

  async function handleSendLink() {
    try {
      const schema = yup.object().shape({
        meetingLink: yup.string()
          .required("Please add meeting link")
          .min(6, "Paste your meeting link here")
      });
      try {
        schema.validateSync({meetingLink}, {strict: true, abortEarly: false});
        setErrors({});
      }
      catch (e) {
        setErrors(yupErrorToMapping(e));
        return;
      }
      setIsLoading(true);
      await api.updateInterview(interview.uuid, {
        //status: status.MEETING_LINK_ADDED,
        meeting_link: meetingLink,
      });
      setDisplayThankYouMessage(true);
      setIsLoading(false);
    }
    catch (err) {
      setIsLoading(false);
    }
  }

  function renderDate(date: {date_time: Date, duration: number} | undefined) {
    const dateTimeString = formatStartEndDateDayLongSeparately(date?.date_time, date?.duration)
    return <>
      <Subtitle size="large">{dateTimeString.date}</Subtitle>
      <Subtitle size="large">{dateTimeString.time}</Subtitle>
    </>;
  }

  if (displayThankYouMessage) {
    return (
      <Modal className={classNames(styles.success, styles.mobileBottom, styles.extraShort)} theme={styles} open={open} onClose={handleClose} id="Thank-you-modal">
        <Content className={styles.content}>
          <Heading size="h5" className={styles.blue}>Thank you!</Heading>
          <Subtitle size="large">
          We have shared your new interview location with {candidate?.user.first_name} {candidate?.user.last_name} for your interview{interviewDate && ` on ${formatStartDateDurationLong(interviewDate, duration)}`}.
          </Subtitle>
          <div className="d-flex flex-row-reverse">
          <Button
            kind="blue-primary"
            onClick={handleClose}
          >Close</Button>
        </div>
        </Content>
      </Modal>
    );
  }

  return (
    <Modal theme={styles} open={open} onClose={handleClose} id="add-meeting-link-modal" className={classNames(styles.mobileBottom, styles.short)}>
      <Content className={styles.content}>
        <Heading size="h5">Edit interview location</Heading>
        <div className={styles.candidateInfo}>
          <div className={styles.avatar}>
            <img className="img-fluid" src={personIcon} />
          </div>
          <div>
            <Heading size="h5"><a href={placementUrl} target="_blank"><b>{candidate?.user.first_name} {candidate?.user.last_name}</b></a></Heading>
            <Subtitle size="large">For <a href={projectUrl} className={styles.blue} target="_blank"><b>{candidate?.interview.placement.associated_project_name ?? candidate?.project}</b></a></Subtitle>
          </div>
        </div>
        <Heading size="h5" className={styles.iconHeader}><span className="material-icons-outlined">schedule</span>Schedule a specific time</Heading>
        <div className={styles.interviewInfo}>
          {interviewDate &&
            <div className="ml-4">
              {renderDate({date_time: interviewDate, duration: duration ?? 0})}
            </div>
          }
          {!interviewDate &&
            <Subtitle size="large" className="ml-4">{candidate?.user.first_name} has not chosen any timeslot</Subtitle>
          }
        </div>
        <Heading size="h5" className={styles.iconHeader}><span className="material-icons-outlined">location_on</span>Interview location</Heading>
        <Subtitle size="large" className="ml-4">Edit your interview location below:</Subtitle>
        <div className="ml-4">
          <RichTextEditor
            hideToolbar
            type="text"
            placeholder="Paste your meeting link here"
            onChange={e => {
              setMeetingLink(e);
            }}
            value={meetingLink}
          />
        </div>
        <div className="d-flex flex-row-reverse mb-3">
          <Button
            kind="blue-primary"
            disabled={!meetingLink || errors.meetingLink || isLoading} onClick={handleSendLink}
            type="button"
          >
            {
              isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Save new interview location"
            }
          </Button>
        </div>
      </Content>
    </Modal>
  );
}
