import React, {useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import moment from "moment";
import {useQueryClient} from "react-query";
import "primeicons/primeicons.css";

import {offerInterviewModalInterface} from "components/dashboard/OfferInterviewModal";
import {useAlerts} from "components/shared/AlertsProvider";
import Button from "components/shared/Button";
import RadioButtons from "components/shared/RadioButtons";
import LoadingSpinner from "components/shared/LoadingSpinner";
import useChat from "hooks/useChat";
import {useInterviewMutation} from "services/mutations";
import {DISABLE_REFETCH_OPTIONS, usePlacementsQuery, useSelfQuery, useUserQuery} from "services/queries";
import {EventInterface} from "util/events";
import {updateObjectCache} from "util/mutation";
import {status} from "util/placement/constants";
import {mergeClassNames} from "styles/utils";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./chatModal.module.scss";
import Modal from "./Modal";
import {getActiveFeatures, getName} from "util/placement/activeFeatures";
import {Heading} from "workfinder-components";


const styles = mergeClassNames(baseStyles, modalTheme);

interface Correspondent {
  full_name: string;
  candidate: uuid;
  host: uuid;
  is_minor: boolean;
}

interface ChatMessageProps {
  message: ChatMessage;
}

const formatText = (text) => {
  if (!text) {
    return null;
  }
  return text.split("\n").map((e, idx) => (
    <p className="my-0" key={`${e}-${idx}`}>{e}<br /></p>
  ));
};

function MinorBanner(): JSX.Element {
  return (
    <div className={styles.minorBanner}>
      <span className="wf-icon wf-warning-triangle mr-2"></span>
      This Candidate is under 18 years old. Chat features are limited for
      safeguarding reasons.
    </div>
  );
}

/**
 * The date display in the chat dialog.
 */
function DateDisplay({date}): JSX.Element {
  return (
    <div className={styles.date}>
      {
        date.calendar({
          sameDay: "[Today]",
          lastDay: "[Yesterday]",
          lastWeek: "ddd, DD/MM/YYYY",
          sameElse: "ddd, DD/MM/YYYY",
        })
      }
    </div>
  );
}

/**
 * The name display in the chat dialog.
 */
function NameDisplay({message}): JSX.Element {
  return (
    <div
      className={classNames(
        styles.name,
        message.out ? styles.rightAlign : styles.leftAlign,
      )}
    >
      {message.out ? "You" : message.sender_username}
    </div>
  );
}

interface ChatMessageProps {
  message: ChatMessage;
}

function ChatMessage({message}: ChatMessageProps): JSX.Element {
  return (
    <div
      className={classNames(
        styles.message,
        message.out ? styles.rightAlign : styles.leftAlign,
        {
          [styles.isOwnMessage]: message.out,
          [styles.unpersisted]: !message.id,
        },
      )}
    >
      <div>{formatText(message.text)}</div>
      <div className={styles.messageTime}>{moment(message.created).format("h:mm a")}</div>
    </div>
  );
}

interface ChatDialogProps {
  /** Messages to show. */
  messages: ChatMessage[] | null;
  /** The name of the correspondent. Only used in the "no messages" display. */
  correspondentName?: string;
}

function ChatDialog({messages, correspondentName}: ChatDialogProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevMessagesLoadedRef = useRef<boolean>(false);
  let prevDate: boolean | null = null;
  let prevOut: boolean | null = null;

  function scrollToBottom() {
    if (containerRef.current !== null) {
      containerRef.current.scroll({top: containerRef.current.scrollHeight, behavior: "instant"});
    }
  }

  useEffect(
    () => {
      const messagesLoaded = messages !== null;
      if (messagesLoaded && !prevMessagesLoadedRef.current) {
        scrollToBottom();
      }
      prevMessagesLoadedRef.current = messagesLoaded;
    },
    [messages],
  );

  return (
    <div className={styles.chatDialog} ref={containerRef}>
      {
        messages === null ?
          <LoadingSpinner/> :
          (
            messages.length === 0 ?
              <p className={classNames("m-auto", styles.noMessages)}>
                This is the very beginning of your conversation{correspondentName && ` with ${correspondentName}`}
              </p> :
              <>
                <div className="mt-auto"></div>
                {
                  [...messages].reverse().flatMap(message => {
                    const date = moment(message.created);
                    const components: React.ReactNode[] = [];
                    const messageId = message.random_id ?? message.id;

                    if (prevDate === null || date.isAfter(prevDate, "day")) {
                      prevDate =  date;
                      components.push(<DateDisplay key={`date-${messageId}`} date={date}/>);
                    }

                    if (prevOut === null || message.out != prevOut) {
                      prevOut = message.out;
                      components.push(
                        <NameDisplay key={`name-${messageId}`} message={message}/>,
                      );
                    }

                    components.push(
                      <ChatMessage key={messageId} message={message}/>,
                    );
                    return components;
                  })
                }
              </>
          )
      }
    </div>
  );
}

enum InputState {
  INPUTTING,
  RESCHEDULE_QUESTION,
  NEW_MEETING_LINK_QUESTION,
  ADDING_MEETING_LINK,
}

const SHARED_MESSAGES = {
  RUNNING_LATE: "I will be running late for our interview",
};

const HOST_TO_MINOR_MESSAGES = {
  ...SHARED_MESSAGES,
  RESCHEDULE: "I will not be able to make our interview, I will reschedule it",
  TECHNICAL_ISSUE: "There are technical issues with the meeting link, I will resend a new one",
};

const MINOR_TO_HOST_MESSAGES = {
  ...SHARED_MESSAGES,
  RESCHEDULE: "I will not be able to make our interview, please reschedule",
  TECHNICAL_ISSUE: "I am having technical issues joining the meeting link",
};

const HOST_TO_MINOR_OPTIONS = Object.values(HOST_TO_MINOR_MESSAGES).map(value => ({value}));
const MINOR_TO_HOST_OPTIONS = Object.values(MINOR_TO_HOST_MESSAGES).map(value => ({value}));

interface InputAreaProps {
  correspondent?: Correspondent;
  /** Whether to disable the send button. */
  disabled?: boolean;
  /** The callback to call when the typed or selected value changes. */
  onChange: (value: string) => void;
  /** The callback to call when a message is sent. */
  onSend: (value: string) => void;
  /** The callback to call when the state of the input are changes. */
  onStateChange: (state: InputState) => void;
  /** The placement the chat is about, if any. */
  placement?: Placement;
  /** The current state of the input area. */
  state: InputState;
  /**
   * Either the entered text of the selected option, depending on whether the
   * convertsation follows under-18 rules.
   */
  value: string;
}

function InputArea({correspondent, disabled, onChange, onSend, onStateChange, placement, state, value}: InputAreaProps) {
  const queryClient = useQueryClient();
  const interviewMutation = useInterviewMutation(
    {
      onSuccess() {
        onStateChange(InputState.INPUTTING);
        updateObjectCache(
          queryClient,
          ["placement", placement.uuid],
          {status: status.RESCHEDULE_REQUIRED},
        );
      },
    },
  );
  const isMinorInChat = Variables.user.is_minor || correspondent?.is_minor;
  const showMinorOptions = isMinorInChat && (status.INTERVIEW_MEETING_LINK_ADDED === placement?.status || status.INTERVIEW_CONFIRMED === placement?.status);

  function handleSend() {
    if (isMinorInChat) {
      handleMinorMessage(value, correspondent);
    }
    else {
      onSend(value);
    }
    onChange("");
  }

  function handleMinorMessage(value: string, correspondent: Correspondent) {
    switch (value) {
    case SHARED_MESSAGES.RUNNING_LATE:
      onSend(`Dear ${correspondent.full_name},\nMy sincere apologies but I will be running late to our interview.`);
      return;
    case HOST_TO_MINOR_MESSAGES.RESCHEDULE:
      onSend(`Dear ${correspondent.full_name},\nMy sincere apologies but I will not be able to make our interview. I will reschedule our interview by sending you new timeslots.`);
      onStateChange(InputState.RESCHEDULE_QUESTION);
      return;
    case MINOR_TO_HOST_MESSAGES.RESCHEDULE:
      onSend(`Dear ${correspondent.full_name},\nMy sincere apologies but I will not be able to make our interview. Could we please reschedule it?`);
      return;
    case HOST_TO_MINOR_MESSAGES.TECHNICAL_ISSUE:
      onStateChange(InputState.NEW_MEETING_LINK_QUESTION);
      return;
    case MINOR_TO_HOST_MESSAGES.TECHNICAL_ISSUE:
      onSend(`Dear ${correspondent.full_name},\nI am having technical issues joining the meeting link.`);
      return;
    default:
      throw new Error("Minor message type unknown");
    }
  }

  if (state === InputState.RESCHEDULE_QUESTION) {
    return (
      <div className={styles.questionButtons}>
        <Button
          kind="solid"
          onClick={() => {
            offerInterviewModalInterface.dispatch({
              candidate: {
                id: correspondent.candidate,
                placementKey: placement.id,
                project: placement.associated_project_name,
                status: placement.status,
                user: correspondent,
              },
            });
            onStateChange(InputState.ADDING_MEETING_LINK);
          }}
        >Reschedule interview now</Button>
        <Button
          kind="link2"
          style={{color: "#FF653B", fontWeight: "bold"}}
          onClick={
            () => interviewMutation.mutate({
              uuid: placement.interview,
              data: {status: "reschedule required"},
            })
          }
        >Reschedule later</Button>
      </div>
    );
  }

  if (state === InputState.NEW_MEETING_LINK_QUESTION) {
    return (
      <div className={styles.questionButtons}>
        <Button
          kind="solid"
          onClick={() => {
            onSend(`Dear ${correspondent.full_name},\nThere are technical issues with the meeting link, I will resend a new video conferencing link now.`);
            onStateChange(InputState.INPUTTING);
            const placementUrl = placement?.uuid ? Variables.urls.hosts.applicant_details.replace(/<placement_id(:uuid)?>/, placement?.uuid) : undefined;
            window.open(placementUrl, "_self");
          }}
        >
          Send new meeting link
        </Button>
        <Button
          kind="link2"
          onClick={() => {
            onStateChange(InputState.INPUTTING);
            onChange("");
          }}
          style={{color: "#FF653B", fontWeight: "bold"}}
        >Cancel</Button>
      </div>
    );
  }

  return (
    <>
      {
        correspondent !== undefined && !isMinorInChat &&
        <textarea
          className={styles.messageInput}
          onChange={e => onChange(e.target.value)}
          placeholder="Type message here"
          value={value}
        />
      }
      {
        !(isMinorInChat && !showMinorOptions) &&
        <div className={styles.buttonGroup}>
          <div className="d-flex align-items-center">
            {
              showMinorOptions &&
              <h3 className={classNames("pb-0", styles.minorTitle)}>
                What would you like to message the candidate?
              </h3>
            }
            <Button
              className={styles.sendButton}
              disabled={disabled || value.length === 0}
              kind="solid"
              onClick={handleSend}
            >
              <span className="wf-icon wf-send-arrow mr-2"/> Send
            </Button>
          </div>
          {
            showMinorOptions &&
            <RadioButtons
              name="minor-messages"
              onChange={onChange}
              options={correspondent.host ? MINOR_TO_HOST_OPTIONS : HOST_TO_MINOR_OPTIONS}
              value={value}
            />
          }
        </div>
      }
    </>
  );
}

interface ChatModalData {
  /** The name of the user to chat with. When not given, it will get fetched. */
  correspondentName?: uuid;
  /** The UUID of the user to chat with. */
  correspondentUUID?: uuid;
  /** Whether the chat modal should be open. */
  open?: boolean;
}

export const chatModalInterface = new EventInterface<ChatModalData>("chatModal");

export default function ChatModal(): JSX.Element | null {
  const [value, setValue] = useState("");
  const [state, setState] = useState<InputState>(InputState.INPUTTING);
  const [addAlert] = useAlerts();
  const [isOpen, setIsOpen] = useState(false);
  const [correspondentUUID, setCorrespondentUUID] = useState<uuid | undefined>();
  const [correspondentName, setCorrespondentName] = useState<string>("-");
  const {data: me} = useSelfQuery();
  const {data: placements} = usePlacementsQuery("ALL", {
    ordering: "-created_at",
    user_uuid: correspondentUUID,
    get_shared: true,
  }, {
    ...DISABLE_REFETCH_OPTIONS,
    placeholderData: [],
    enabled: !!correspondentUUID,
  });

  const {data: correspondent} = useUserQuery(
    correspondentUUID,
    {
      enabled: !!correspondentUUID,
      onSuccess(correspondent) {
        correspondent.candidate?.initials ? setCorrespondentName(correspondent.candidate?.initials) : setCorrespondentName(correspondent.full_name);
      },
    });
  const {messages, isError, isLoading, sendMessage, markRead, isOpen: isChatOpen} = useChat({
    queryParams: {correspondent: correspondentUUID},
    enabled: isOpen,
    onMessageIDCreated(data) {
      // We can't mark a message as read when we receive it, as it won't have
      // a DB ID, so we wait for that to come in.
      const inboundMessageExists = messages.some(
        message => message.random_id === data.random_id && !message.out,
      );
      if (isOpen && inboundMessageExists) {
        markRead(data.db_id);
      }
    },
    onError(msg) {
      addAlert("chat-error", "danger", msg);
    },
  });

  const handleChatModalEvent = useCallback(
    (detail: ChatModalData) => {
      setIsOpen(open => detail.open ?? open);
      setCorrespondentUUID(detail.correspondentUUID);
      setCorrespondentName(detail.correspondentName ?? "-");
      setState(InputState.INPUTTING);
    }, [setIsOpen, setCorrespondentUUID, setCorrespondentName],
  );

  useEffect(() => {
    chatModalInterface.register(handleChatModalEvent);
    return () => {
      chatModalInterface.remove();
    };
  }, [handleChatModalEvent]);

  useEffect(() => {
    if (isChatOpen) {
      updateReadState();
    }
  }, [isChatOpen]);

  function handleClose() {
    setIsOpen(false);
    setCorrespondentName("-");
    setCorrespondentUUID(undefined);
    setState(InputState.INPUTTING);
  }

  function updateReadState() {
    if (isOpen && messages != null) {
      messages
        .filter(message => !message.read && !message.out)
        .forEach(message => markRead(message.id));
    }
  }

  let initialsOrName = "";
  const placementAsHost = placements?.find(placement => placement.host === Variables.uuids?.host)
  if (placementAsHost) {
    const activeFeatures = getActiveFeatures(me, placementAsHost);
    initialsOrName = getName({activeFeatures, user: correspondent});
  }
  else {
    initialsOrName = correspondentName;
  }

  return (
    <Modal
      id="chat-modal"
      className={classNames(styles.chatModal, styles.mobileBottom, {[styles.error]: isError})}
      onClose={handleClose}
      onFocus={updateReadState}
      open={isOpen}
      theme={styles}
    >
      {isOpen &&
      <>
        <div className={styles.header}>
          <div className={styles.headerWithSub}>
            <h2>Message to {initialsOrName}</h2>
          </div>
          {
            Variables.uuids.host && placements?.length > 0 && (
              <>
                <Heading size="h3" className="pb-0 mb-1 mt-1">View application for:</Heading>
                {
                  placements.map(placement => {
                    if (placement.candidate === Variables.uuids.candidate) {
                      return null;
                    }
                    return (
                      <Button
                        key={placement.uuid}
                        baseComponent="a"
                        className="d-block pl-0 py-0"
                        href={Variables.urls.hosts.applicant_details.replace("<placement_id>", placement.uuid)}
                        target="_blank"
                        kind="link2"
                      >
                        -&nbsp; {placement.associated_project_name}
                      </Button>
                    );
                  })
                }
              </>
            )
          }
          {
            Variables.uuids.candidate && placements?.length > 0 &&
              placements.map(placement => {
                if (placement.candidate !== Variables.uuids.candidate) {
                  // It may be a host. Show "View candidate briefing"
                  return null;
                }
                return (
                  <Button
                    key={placement.uuid}
                    baseComponent="a"
                    className="d-block pl-0"
                    href={Variables.urls.projects.detail.replace("<uuid:uuid>", placement.associated_project)}
                    target="_blank"
                    kind="link2"
                  >
                    View opportunity: {placement.associated_project_name}
                  </Button>
                );
              })
          }
        </div>
        {correspondent?.is_minor && <MinorBanner/>}
        <ChatDialog
          messages={isLoading ? null : (messages ?? [])}
          correspondentName={correspondentName ?? correspondent?.full_name}
        />
        {placements?.length > 0 ? (
          <InputArea
            correspondent={correspondent}
            disabled={isLoading || isError}
            onChange={setValue}
            onSend={sendMessage}
            onStateChange={setState}
            placement={placements[placements.length - 1]}
            state={state}
            value={value}
          />
        ) : null}
      </>}
    </Modal>
  );
}
