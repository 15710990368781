import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";


/**
 * A wrapper for Bootstrap alerts.
 * @see https://getbootstrap.com/docs/4.0/components/alerts/
 */
function Alert(props) {
  const {children, onClose, style, open=true} = props;
  const type = props.type || "primary";
  const classNames = classnames(
    `alert alert-${type} alert-dismissible fade`,
    {show: open},
    style,
  );

  return (
    <div className={classNames}>
      {children}
      <button type="button" className="close" aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

Alert.propTypes = {
  float: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default Alert;
