import {useEffect, useRef, useState} from "react";
import {setEquals} from "util/set";
import {flatten} from "util/object";

/**
 * LEGACY - this has been superseded by the `Form` component,
 *
 * Reusable hook for form components. The form must have the `noValidation`
 * attribute set, otherwise the browser will run its own validation and show
 * its own ugly error messages first.
 * @param data The form data to validate. It can be nested data with property
 *             names being flattened to dot syntax and should be used as the
 *             input name.
 * @param onSubmit The callback to call if validation is successful.
 * @param formId The ID of the form to check.
 * @param fieldValidator A custom validation function of the format
 *                       `(inputEl, formEl) => isValid` that will be checked in
 *                       addition to the built-in browser validation.
 * @returns `Errors` is a set of property names of inputs with errors.
 *          `isComplete` is a boolean indicating whether the form is complete.
 *          `handleSubmit` is the callback that should be passed to the `form`'s onSubmit.
 */
export default function useFormValidation(data, onSubmit, formId, fieldValidator) {
  const [errors, setErrors] = useState(new Set());
  const [changed, setChanged] = useState(new Set());
  const prevDataRef = useRef(flatten(data));
  const [isComplete, setIsComplete] = useState(false);

  function detectChanges() {
    // Find out which keys changed value.
    let changedNow = new Set();
    const flatData = flatten(data);
    for (const [key, value] of Object.entries(flatData)) {
      if (prevDataRef.current[key] !== value) {
        changedNow.add(key);
      }
    }
    setChanged(changed => new Set([...changed, ...changedNow]));
    let newErrors = validate();
    prevDataRef.current = flatData;
    if (!setEquals(errors, newErrors)) {
      setErrors(newErrors);
      let newIsComplete = newErrors.size === 0;
      if (newIsComplete !== isComplete) {
        setIsComplete(newIsComplete);
      }
    }
  }

  function handleSubmit(e) {
    let errors = validate();

    if (isComplete) {
      onSubmit(data);
    }

    // Consider all fields to have been changed if a submit has happened.
    const changedNow = new Set();
    for (const el of document.forms[formId].elements) {
      if (el.name) {
        changedNow.add(el.name);
      }
    }
    setChanged(changed => new Set([...changed, ...changedNow]));

    setErrors(errors);
    e.preventDefault();
    return false;
  }

  /**
   * Validate all fields of the given form
   */
  function validate() {
    let errors = new Set();

    if (formId in document.forms) {
      for (const el of document.forms[formId].elements) {
        if (el.name && !el.checkValidity() && (!fieldValidator || !fieldValidator(el, document.forms[formId]))) {
          errors.add(el.name);
        }
      }
    }
    return errors;
  }

  useEffect(detectChanges, [data]);

  return [changed, errors, isComplete, handleSubmit];
}
