import React, {useEffect, useState} from "react";
import Input from "components/shared/Input";
import CheckBox from "components/shared/Checkbox";
import ErrorMessage from "components/bespoke-project/pages/ErrorMessage";
import useTrackedState from "hooks/useTrackedState";
import styles from "./signUp.module.scss";
import api from "services/v3";
import Modal from "components/shared/Modal/Modal";
import * as yup from "yup";
import {yupErrorToMapping} from "util/errors";
import classNames from "classnames";
import {Map} from "immutable";
import {DuedilAsyncTypeaheadSignUp} from "components/shared/DuedilAsyncTypeahead";
import {ADDON_COLLABORATE_WITH_COLLEAGUES, ADDON_DASHBOARDS_TO_EVALUATE_CANDIDATES, ADDON_MESSAGING_WITH_CANDIDATES, ADDON_TALENT_ADVANCE_GENTLE_LETDOWN_SERVICE, PLAN_ADDON_ID, PLAN_ANNUAL_ID, PLAN_BUSINESS_ID, PLAN_FREE_ID, PLAN_MONTHLY_ID, PLAN_TEAM_ID} from "components/pay-button/constants";
import EmailError from "../EmailError";
import PasswordError from "../PasswordError";

const already_email_message = "A user is already registered with this e-mail address.";
const incorrect_password = "Incorrect Password";

const commonSchema = {
  company_name: yup
    .string()
    .required()
    .trim("Company Name is a required field")
    .label("Company Name"),
  domain: yup
    .string()
    .required()
    .nullable()
    .trim("Company Website is a required field")
    .label("Company Website"),
  first_name: yup
    .string()
    .required()
    .trim("First Name is a required field")
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .nullable()
    .trim("Last Name is a required field")
    .label("Last Name"),
};

const signUPSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password1: yup
    .string()
    .required()
    .min(6),
  accept_terms: yup
    .boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  ...commonSchema,
});

const existingUserSchema = yup.object().shape({
  ...commonSchema,
  role: yup
    .string()
    .required()
    .min(2),
});

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

interface SignUpProps {
  onClose?: any;
  user_type?: string;
  onLogIn?: any;
  userTypes?: any;
  plan_id?: string
  addon_id?:string;
  plan_name?: string;
}
const TERMS_CONDITIONS_DICT = {};
const baseUrl = "https://superpower.tech/";
TERMS_CONDITIONS_DICT[PLAN_ANNUAL_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[PLAN_MONTHLY_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[PLAN_ADDON_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[PLAN_FREE_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[PLAN_TEAM_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[PLAN_BUSINESS_ID] = (<span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span>);
TERMS_CONDITIONS_DICT[ADDON_MESSAGING_WITH_CANDIDATES] = (<span>I understand that by clicking next, I accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u> of use to upgrade my dashboard to include “Advanced Communications” with candidates </span>);
TERMS_CONDITIONS_DICT[ADDON_TALENT_ADVANCE_GENTLE_LETDOWN_SERVICE] = (<span>I understand that by clicking next, I accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u> of use to upgrade my dashboard to include “Candidate Advisory Service” with candidates </span>);
TERMS_CONDITIONS_DICT[ADDON_DASHBOARDS_TO_EVALUATE_CANDIDATES] = (<span>I understand that by clicking next, I accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u> of use to upgrade my dashboard to include “Enhanced Dashboard” with candidates </span>);
TERMS_CONDITIONS_DICT[ADDON_COLLABORATE_WITH_COLLEAGUES] = (<span>I understand that by clicking next, I accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u> of use to upgrade my dashboard to include “Collaboration Tools” with candidates </span>);

const TITLE_SIGNUP_MODAL = { };
TITLE_SIGNUP_MODAL[PLAN_ANNUAL_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[PLAN_MONTHLY_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[PLAN_ADDON_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[PLAN_FREE_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[PLAN_TEAM_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[PLAN_BUSINESS_ID] = "Talent Solutions Sign up";
TITLE_SIGNUP_MODAL[ADDON_MESSAGING_WITH_CANDIDATES] = "Advanced Communications Feature Sign up";
TITLE_SIGNUP_MODAL[ADDON_TALENT_ADVANCE_GENTLE_LETDOWN_SERVICE] = "Candidate Advisory Service Sign up";
TITLE_SIGNUP_MODAL[ADDON_DASHBOARDS_TO_EVALUATE_CANDIDATES] = "Enhanced Dashboard Sign up";
TITLE_SIGNUP_MODAL[ADDON_COLLABORATE_WITH_COLLEAGUES] = "Collaboration Tools Sign up";

export const SignUp = ({
  onClose,
  onLogIn = () => {},
  plan_id=PLAN_ANNUAL_ID,
  addon_id=ADDON_MESSAGING_WITH_CANDIDATES,
  plan_name="Business"
}: SignUpProps) => {

  const [user, setUser, setReferenceUser, hasUserChanged] = useTrackedState(Map({
    email: "",
    password1: "",
    country: null,
    current_location: null,
    accept_terms: false,
    company_name: "",
    duedil_company_id: "",
    domain: "",
    email_override: false,
    role: "",
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUserEmailNo, setIsUserEmailNo] = useState(false);
  const [isUserEmailYes, setIsUserEmailYes] = useState(false);
  //
  const [userErrors, setUserErrors] = useState<Record<string, string[]>>({});
  const [showCompanyTextBox, setShowCompanyTextBox] = useState(false);

  async function validate(abortEarly) {
    const is_valid = await (
      Variables.isAuthenticated ? existingUserSchema : signUPSchema
    ).validate(user.toJSON(), {strict: true, abortEarly: abortEarly})
      .then(() => {
        setUserErrors({});
        setIsDisabled(false);
        return true;
      })
      .catch(e => {
        setIsLoading(false);
        setIsDisabled(true);
        setUserErrors(yupErrorToMapping(e));
        return false;
      });
    return is_valid;
  }

  useEffect(() => {
    validate(true);
  }, [user]);

  useEffect(() => {
    if (Variables.isAuthenticated) {
      api.getUserUpToCompany().then(userUpToCompany => {
        setUser(
          data => data.
            set("first_name", userUpToCompany?.first_name).
            set("last_name", userUpToCompany?.last_name).
            set("company_name", userUpToCompany?.host.association.location.company.name).
            set("domain", userUpToCompany?.host.association.location.company.domains[0]).
            set("duedil_company_id", userUpToCompany?.host.association.location.company.duedil_company_id).
            set("role", userUpToCompany?.host.association.title),
        );
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTermsAndConditions() {
    return TERMS_CONDITIONS_DICT[plan_name];
  }
  function getModalTitle() {
    return TITLE_SIGNUP_MODAL[plan_name];
  }

  function getAddOnId() {
    if (plan_id=="Add-on-GBP-Monthly") {
      return addon_id;
    }
    return "";
  }

  function handleUserChange(value, name) {
    setUser(data => data.set(name, value));
  }
  function resetForm() {
    setIsLoading(false);
    setIsUserEmailNo(false);
    setIsUserEmailYes(false);
  }
  async function submitSubscribeForm() {
    const data = {data: {plan: plan_id, addon: getAddOnId()}};
    const onSuccess = () => {
      setIsLoading(false);
      setReferenceUser(Map());
      setShowCompanyTextBox(false);
      if (Variables?.user.partner && Object.values(Variables.user.partner).length) {
        // if they already had a subscription -> they are existing partners
        window.location.href = `/success/?feature=o-${getAddOnId()}`;
      }
      else {
        window.location.href = `/success/?feature=${getAddOnId()}`;
      }
      resetForm();
      onClose();
    };
    await api.updateUser({
      first_name: user.get("first_name"),
      last_name: user.get("last_name"),
    });
    await api.updateAssociation(Variables.uuids.associations[0], {
      title: user.get("role"),
    });
    await api.updateCompany(Variables.uuids.companies[0], {
      name: user.get("company_name"),
      duedil_company_id: user.get("user_company_id"),
      domains: [user.get("domain")],
    });
    if (Object.keys(Variables.user.partner).length) {
      // if the user has already subscribed to an add-on
      await api.updateSubscription(data);
    }
    else {
      await api.createSubscription(data);
    }
    onSuccess();
  }
  async function submitSignUpForm() {
    setIsLoading(true);
    let userData = {
      ...user.toJSON(), user_type: "PPADM", addon_id: addon_id
    };
    if(plan_name != "Free") userData["plan_id"] = plan_id;
    else userData["user_type"] = "HOS";

    api.submitSignUpform(userData).then(() => {
      setIsLoading(false);
      setReferenceUser(Map());
      setShowCompanyTextBox(false);
      resetForm();
      onClose();
      if (plan_name != "Free")
        window.location.href = `/success/?feature=${getAddOnId()}`;
      else
        window.location.href= `/auth/confirm-email/?email=${encodeURIComponent(userData["email"])}&type=host`;
    }).catch((error) => {
      resetForm();
      if ("email" in error.json && error.json.email[0] == incorrect_password ) {
        error.json["password1"] = [incorrect_password];
        delete error.json["email"];
      }
      setUserErrors(error.json);
    });
  }
  const updateSelectedCompany = (result) => {
    const {companyId, countryCode} = result;
    setUser(data => data.set("company_name", result.name));
    setUser(data => data.set("duedil_company_id", companyId));
    api.getCompanyData({
      endpoint: "company_data",
      "company-id": companyId,
      "country-code": countryCode,
    }).then(res => {
      res.primaryWebsite && setUser(data => data.set("domain", res.primaryWebsite));
    });
  };
  const onCompanyNotFound= () => {
    setShowCompanyTextBox(true);
  };
  const onCompanyFound= () => {
    setShowCompanyTextBox(false);
  };
  function handleCompanyChange(value, name) {
    setUser(data => data.set(name, value));
  }
  async function checkEmail(value) {
    const is_valid = await emailSchema.validate({email: value});
    if (is_valid) {
      api.checkEmailExists(value).then((results) => {
        if (results.isExists) {
          resetForm();
          setUserErrors({
            email: [
              already_email_message,
            ],
          });
        }
      });
    }
  }

  return (
    <div className={styles.accountsModal}>
      <h2 className={styles.signup}>
        {Variables.isAuthenticated ? "Subscribe to" : getModalTitle()}
      </h2>
      <h3 className="form-header">
        {Variables.isAuthenticated ?
          getModalTitle().replace(" Sign up", "").replace(" Feature", "") :
          `${plan_name} plan`
        }
      </h3>
      {Variables.isAuthenticated ? null : (
        <p className={styles.text}>Already a subscriber?&nbsp;
          <a onClick={onLogIn}
            className={styles.link}
          >Log in</a>.
        </p>
      )}
      <div id="signup_form">
        <div className={styles.inputGroup}>
          <Input
            className={styles.inputText}
            placeholder="First Name"
            value={user.get("first_name", "")}
            required
            onChange={e => handleUserChange(e.target.value, "first_name")}
          />
          <ErrorMessage errors={userErrors.first_name} name="first_name" />
        </div>
        <div className={styles.inputGroup}>
          <Input
            className={styles.inputText}
            placeholder="Last Name"
            value={user.get("last_name", "")}
            required
            onChange={e => handleUserChange(e.target.value, "last_name")}
          />
          <ErrorMessage errors={userErrors.last_name} name="last_name" />
        </div>
        <div className={styles.inputGroup}>

          <DuedilAsyncTypeaheadSignUp
            id="company"
            className={styles.asyncAheadInput}
            updateSelectedCompany={updateSelectedCompany}
            onCompanyNotFound = {onCompanyNotFound}
            onCompanyFound = {onCompanyFound}
            value={showCompanyTextBox? "Can’t find your organisation?": user.get("company_name", "")}
            name="company_name"
            onChange={handleCompanyChange}
            placeholder="Search organisation name"
            fetchAddress={false}
          />
          <ErrorMessage errors={userErrors.company_name} name="company_name" />
        </div>
        { showCompanyTextBox && <div className={styles.inputGroup}>
          <Input
            className={styles.inputText}
            placeholder="Please enter your company name here"
            value={user.get("company_name", "")}
            onChange={e => handleUserChange(e.target.value, "company_name")}
          />
          <ErrorMessage errors={userErrors.company_name} name="company_name" />
        </div>

        }
        <div className={styles.inputGroup}>
          <Input
            className={styles.inputText}
            placeholder="Organisation website URL"
            value={user.get("domain", "")}
            required
            onChange={e => handleUserChange(e.target.value, "domain")}
          />
          <ErrorMessage errors={userErrors.domain} name="domain" />
        </div>
        {
          Variables.isAuthenticated ? (
            <div className={styles.inputGroup}>
              <Input
                className={styles.inputText}
                placeholder="Organisation Role Title"
                value={user.get("role", "")}
                required
                onChange={e => {
                  handleUserChange(e.target.value, "role");
                  checkEmail(e.target.value);
                }}
              />
            </div>
          ) : (
            <>
              <div className={classNames(styles.inputGroup, "pt-3")}>
                <Input
                  className={styles.inputText}
                  placeholder="Email Address"
                  value={user.get("email", "")}
                  required
                  onChange={e => {
                    handleUserChange(e.target.value, "email");
                    checkEmail(e.target.value);
                  }}
                />
                {isUserEmailYes && <div className={styles.errorCard}>
                  <p className="card-text">Great! Please continue.</p>
                </div>}
                {isUserEmailNo && <div className={styles.errorCard}>
                  <p className="card-text">Please enter a new email address above.</p>
                </div>}

                {!isUserEmailYes && <EmailError errors={userErrors.email} name="email" already_email_message={already_email_message} onNoClick={() => {
                  setIsUserEmailNo(true);handleUserChange("", "email");
                  handleUserChange(false, "email_override");
                }} onYesClick={() => {
                  setIsUserEmailYes(true);
                  handleUserChange(true, "email_override");
                }}
                />}
              </div>

              <div className={styles.inputGroup}>
                <Input
                  className={styles.inputText}
                  placeholder="Password"
                  value={user.get("password1", "")}
                  required
                  type="password"
                  onChange={e => handleUserChange(e.target.value, "password1")}
                />
                <PasswordError errors={userErrors.password1} name="password1"/>
              </div>
              <div className={styles.checkBoxContainer}>
                <CheckBox
                  checked={user.get("accept_terms", false)}
                  id="acceptterms"
                  onChange={(value) => {
                    handleUserChange(value, "accept_terms");
                  }}
                >
                  {/* <span>I’d like to learn more about Talent Solutions and accept the <u><a className={styles.tclink} target="_blank" href={`${baseUrl}terms-and-conditions/`} rel="noreferrer">Terms &amp; Conditions</a></u></span> */}
                  {getTermsAndConditions()}
                </CheckBox>
                <ErrorMessage errors={userErrors.accept_terms} name="accept_terms" />
              </div>
            </>
          )
        }
        <div>
          <button className={classNames(styles.accountModalSubmit, !isDisabled? styles.active: "" )}
            onClick={async () => {
              const is_valid = await validate(false);
              if (is_valid) {
                if (Variables.isAuthenticated) {
                  submitSubscribeForm();
                }
                else {
                  submitSignUpForm();
                }
              }
            }}
          >{isLoading ? "Loading..." : "Next"}</button>

        </div>
      </div>
    </div >
  );
};

export const SingnUpLoginModal = ({showSignUpModal, setShowSignUpModal, plan_id="Talent-Magnet-GBP-Yearly", plan_name="Business",addon_id="Messaging-with-candidates-GBP-Monthly"}) => {
  return (
    <Modal id="signup-modal" className={styles.signupModal} open={showSignUpModal}
      onClose={() => {
        setShowSignUpModal(false);
      }}
    >
      <SignUp onClose={() => {
        setShowSignUpModal(false);
      }}
      onLogIn={() => {
        $("#loginModal").modal();
        setShowSignUpModal(false);
      }}
      plan_id={plan_id}
      plan_name={plan_name}
      addon_id={addon_id}
      />
    </Modal>
  );
};
