import React, {useState} from "react";
import PropTypes from "prop-types";

import {useAlerts, messages} from "components/shared/AlertsProvider";
import LoadingSpinner from "components/shared/LoadingSpinner";
import Form from "components/shared/Form/Form";
import RadioButtons from "components/shared/Form/RadioButtons";
import TextArea from "components/shared/Form/TextArea";
import {ButtonGroup, Content} from "components/shared/Slate";
import {useDeclinedReasonsQuery} from "services/queries";

import styles from "./declineModal.module.scss";
import Modal from "./Modal";


function DeclineModal(props) {
  const [addAlert] = useAlerts();
  const {open, onClose, declineClicked} = props;
  const {data: reasons, isFetched, isLoading} = useDeclinedReasonsQuery({
    enabled: open,
    onError() {
      addAlert("decline", "danger", "Could not load the decline options. " + messages.ifPersists);
    },
  });
  const [reasonDeclined, setReasonDeclined] = useState();
  const [otherReason, setOtherReason] = useState("");

  function isOther() {
    return reasonDeclined && reasonDeclined.toLowerCase() === "other";
  }

  function handleDeclineClick() {
    let payload = {
      reasonDeclined,
      otherReason,
    };

    // Blank otherReason if not other.
    if (!isOther()) {
      payload.otherReason = "";
    }

    declineClicked(payload);
  }

  return (
    <Modal name="declineModal" open={open} onClose={onClose}>
      <Form onSubmit={handleDeclineClick} className={styles.form}>
        <Content className={styles.content}>
          <h1 className={styles.title}>DECLINE APPLICATION</h1>
          <p>We will inform the candidate that you have decided not to proceed with their application.</p>
          <p>Please let Superpower know more about your reasons so that we can improve your recommendations in future.</p>
          <div className={styles.radioGroup}>
            {
              !isFetched || isLoading ?
                <LoadingSpinner className={styles.spinner}/> :
                <RadioButtons
                  className={styles.label}
                  data={reasons}
                  baseId="option"
                  onChange={setReasonDeclined}
                  name="reason"
                  required
                  theme={styles}
                  value={reasonDeclined}
                />
            }
            {
              isOther() &&
              <TextArea
                className={styles.otherReason}
                name="otherReason"
                placeholder="Please provide the reason for declining this candidate"
                onChange={setOtherReason}
                required
                maxLength={800}
                value={otherReason}
              />
            }
          </div>
        </Content>
        <ButtonGroup>
          <button type="button" className={styles.cancelBtn} onClick={onClose}>Cancel</button>
          <button className="btn btn-primary">DECLINE</button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

DeclineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  declineClicked: PropTypes.func.isRequired,
};

export default DeclineModal;
