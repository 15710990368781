import React, {useMemo, useState} from "react";
import ReactDOM from "react-dom";
import ClickAwayListener from "react-click-away-listener";
import {usePopper} from "react-popper";
import {v4 as uuid4} from "uuid";
import {google, outlook, office365, yahoo, ics, CalendarEvent} from "calendar-link";
import classNames from "classnames";

import DropdownMenu, {DropdownItem} from "components/shared/DropdownMenu";
import {Button} from "workfinder-components";

import styles from "./addToCalendar.module.scss";

interface Props {
  /** A class name used to restyle the button. */
  className?: string;
  /** The event that will be added when clicking the add button. */
  event: CalendarEvent;
  /** What text to show inside the button. By default it says: Calendar */
  buttonText?: string;
}

export default function AddToCalendar(props: Props): JSX.Element {
  const {className, event, buttonText="Calendar"} = props;
  const [open, setOpen] = useState(false);
  const toggleId = useMemo<string>(() => {
    const buffer = new ArrayBuffer(16);
    uuid4({}, buffer);
    return btoa(buffer).replace(/=/g, "").replace(/\//g, "_").replace(/\+/g, "-");
  }, []);
  const menuId = `${toggleId}-menu`;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {placement: "bottom"},
  );
  const items: DropdownItem[] = [
    {
      icon: <div className={styles.icon}><div className={styles.google}/></div>,
      label: "Google",
      href: google(event),
      attrs: {
        target: "_blank",
      },
    },
    {
      icon: <div className={styles.icon}><div className={styles.apple}/></div>,
      label: "Apple",
      href: ics(event),
      attrs: {
        download: `wf-interview-${toggleId}.ics`,
      },
    },
    {
      icon: <div className={styles.icon}><div className={styles.outlook}/></div>,
      label: "Outlook",
      href: outlook(event),
      attrs: {
        target: "_blank",
      },
    },
    {
      icon: <div className={styles.icon}><div className={styles.office365}/></div>,
      label: "Office 365",
      href: office365(event),
      attrs: {
        target: "_blank",
      },
    },
    {
      icon: <div className={styles.icon}><div className={styles.yahoo}/></div>,
      label: "Yahoo",
      href: yahoo(event),
      attrs: {
        target: "_blank",
      },
    },
  ];

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded="false"
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open)}
        }
        id={toggleId}
        kind="tertiary"
        role="button"
        type="button"
        ref={setReferenceElement}
      >
        <i className="ri-calendar-event-line"></i>&nbsp;{buttonText}
      </Button>
      {
        open &&
        ReactDOM.createPortal(
          <ClickAwayListener
            onClickAway={(e) => {
              if (e.target !== referenceElement) {
                setOpen(false);
              }
            }}
          >
            <DropdownMenu
              id={menuId}
              items={items}
              ref={setPopperElement}
              style={{
                display: "block",
                ...popperStyles.popper,
              }}
              theme={styles}
              toggleId={toggleId}
              {...attributes.popper}
            />
          </ClickAwayListener>,
          document.body,
        )}
    </>
  );
}
