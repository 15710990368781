import React from "react";
import classNames from "classnames";
import {mergeClassNames} from "styles/utils";
import Modal from "components/shared/Modal/Modal";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./paymentInfoModal.module.scss";
import PaymentInfo from "components/payment-info/index";

const styles = mergeClassNames(modalTheme, baseStyles);

export default function PaymentInfoModal({onClose, open, successCallback, planData}) {
  const successAndClose = () => {
    successCallback();
    onClose();
  }
  return (
    <Modal
      id="payment-info-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={classNames(styles.mobileBottom, styles.extraTall)}
    >
      {open && <PaymentInfo planData={planData} successCallback={successAndClose} showDowngradeButton={false}/>}
    </Modal>
  );
}
