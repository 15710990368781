import React, {useContext} from "react";
import MyApplication from "./MyApplication";
import LoadingSpinner from "components/shared/LoadingSpinner";
import SwiperList from "./SwiperList";
import {CandidateDashboardContext} from "apps/CandidateDashboardContext";
import NoData from "./NoData"
import mailbox from "base64/mailbox"

export default function PlacementList() {
  const {placements, isLoading} = useContext(CandidateDashboardContext);
  if (isLoading) {
    return (
      <LoadingSpinner className="d-flex"/>
    );
  }
  else {
    return <SwiperList
      name="placementList"
      title="My applications"
      strBase64={mailbox}
      rows={1}
      noDataComponent={<NoData title="Your applications will show up here" subtitle="Your role applications will show up in this section, so you can easily keep track of it." />}
      nodes={
        placements.map(application => {
          return {
            id: application.uuid,
            node: <MyApplication placement={application} key={application.uuid}/>
          }
        })
      }
    />
  }
}
