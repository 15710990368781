import React from "react";

import styles from "./checkbox.module.scss";

interface Props extends Omit<React.ComponentPropsWithoutRef<"input">, "onChange"> {
  /** Whether the checkbox should be checked. */
  checked: boolean;
  /** The label to display next to the checkbox. */
  children: React.ReactNode;
  /** Whether the checkbox should be disabled. */
  disabled?: boolean;
  /** A callback to call when the checked state of the input changes. */
  onChange?: (checked: boolean) => void;
}

export default function Checkbox({children, onChange, ...inputProps}: Props) {

  return (
    <label className={styles.label}>
      <input
        {...inputProps}
        className={styles.checkbox}
        onChange={e => onChange?.(e.target.checked)}
        type="checkbox"
      />
      {children}
    </label>
  );
}
