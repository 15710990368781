import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {useSelfQuery} from "services/queries";

import {joinCallModalInterface} from "components/dashboard/JoinCallModal";
import {Button} from "workfinder-components";
import {chatModalInterface} from "components/shared/Modal/ChatModal";
import {QuickActions} from "./QuickActions";
import {status as interviewStatus} from "util/interview/constants";

import styles from "./interviewCard.module.scss";
import EditInterviewDropdown from "components/dashboard/editInterviewDropdown";
import {getActiveFeatures} from "util/placement/activeFeatures";
import AbstractCard from "components/candidate-dashboard/OfferInterviewCard/AbstractCard";

function InterviewCard(props) {
  const {
    interview,
    setActionedCandidate,
    setDisqualifyModalOpen,
    onRowClick,
    setSharedPlacementUUID,
    setIsShareModalOpen,
    setSuggestOfferModalOpen,
    setSuggestDeclineModalOpen,
  } = props;
  const candidate = interview.candidate;
  const user = candidate.user;
  const placement = interview.placement;
  const timeNow = new Date().getTime();
  const selectedDate = [...interview?.interview_dates]
    .reverse()
    .find(date => date.status === "selected");
  const {data: me} = useSelfQuery();
  const [lastAllowedFeatures, setLastAllowedFeatures] = useState();
  const userFullName = user.full_name;
  const endTime = moment(moment(selectedDate.date_time).add(selectedDate.duration, "minutes"));
  const fifteenMinutesToInterview = moment(selectedDate.date_time).subtract(15, "minutes");
  const status = interview.status;
  const applicationUrl = new URL(Variables.urls.hosts.applicant_details.replace("<placement_id>", placement.uuid), location.toString()).toString();
  const projectUrl = new URL(`/role/${placement.associated_project}`, location.toString()).toString();
  const activeFeatures = getActiveFeatures(me,  {...placement, interview_status: interview?.status}, placement.owner_uuid != Variables.uuids.user && placement.status != status.SHARED_WITH_YOU, placement.owner_uuid == Variables.uuids.user);
  const completedStatuses = [interviewStatus.INTERVIEW_ACCEPTED, interviewStatus.MEETING_LINK_ADDED, interviewStatus.INTERVIEW_COMPLETED];
  const isCompleted = (timeNow > endTime) && (completedStatuses.includes(status) );
  const interviewText = isCompleted ? "Completed interview" : "Upcoming interview";
  function getEventDescription() {
    return (
      <>
        <p><b>Interview with {userFullName} about {placement.associated_project_name}</b></p>
        <p>Meeting link to join: <a href={interview.meeting_link}>{interview.meeting_link}</a></p>
        <p>{user.first_name}'s application: <a href={applicationUrl}>{applicationUrl}</a></p>
        <p>Your project listing: <a href={projectUrl}>{projectUrl}</a></p>
      </>
    );
  }

  useEffect(() => {
    if (me?.allowed_features) {
      setLastAllowedFeatures(me.allowed_features);
    }
  }, [me?.allowed_features]);

  const showAddToCalendar = (timeNow < fifteenMinutesToInterview) && ([interviewStatus.INTERVIEW_ACCEPTED, interviewStatus.MEETING_LINK_ADDED].includes(status));
  const showJoinCall = (status === interviewStatus.INTERVIEW_ACCEPTED || status === interviewStatus.MEETING_LINK_ADDED) && (timeNow < endTime);
  const showMessage = lastAllowedFeatures?.MESSAGING && (timeNow < endTime);
  const showQuickActions = timeNow > endTime;

  const editButton = (timeNow < endTime) ? (
    <EditInterviewDropdown
      activeFeatures={activeFeatures}
      candidate={{...placement, placementKey: placement.id, placementID: placement.uuid, user: {...candidate, first_name: user.first_name, last_name: user.last_name, initials: user.initials}, interview: interview, project: placement.associated_project_name, id: candidate.id}}
      interview={interview}
    />
  ) : null;

  const Content = () => {
    return (
      <div>
        <div className={styles.buttonGroup}>
          {showJoinCall && <JoinCall candidate={{...candidate, interview: interview}}/>}
          {showMessage && (
            <Button
              baseComponent="button"
              kind="secondary"
              className="mb-0"
              onClick={() => {
                chatModalInterface.dispatch({
                  open: true,
                  placementUUID: placement.uuid,
                  correspondentUUID: candidate.user.uuid,
                  correspondentName: candidate.user.full_name,
                });
              }}
            >
              <i className="ri-message-3-line mr-2"/> Message
            </Button>
          )}
          {showQuickActions && (
            <QuickActions
              className="mb-0"
              text="Next Steps"
              candidate={
                {...placement,
                  placementKey: placement.id,
                  placementID: placement.uuid,
                  user: {...candidate,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    initials: user.initials,
                  },
                  interview: interview,
                  project: placement.associated_project_name,
                  id: candidate.id,
                }}
              isOwner={placement.owner_uuid == Variables.uuids.user}
              isProjectCollaborator={placement.owner_uuid != Variables.uuids.user && placement.status != status.SHARED_WITH_YOU}
              setActionedCandidate={setActionedCandidate}
              setDisqualifyModalOpen={setDisqualifyModalOpen}
              setSuggestOfferModalOpen={setSuggestOfferModalOpen}
              setSuggestDeclineModalOpen={setSuggestDeclineModalOpen}
              onRowClick={onRowClick}
              setSharedPlacementUUID={setSharedPlacementUUID}
              setIsShareModalOpen={setIsShareModalOpen}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <AbstractCard
      profile={{
        placementUuid: placement.uuid,
        name: user.full_name,
        picture: candidate.photo,
      }}
      project={{
        name: placement.associated_project_name,
        uuid: placement.uuid,
      }}
      tag={interviewText}
      tagClass={isCompleted ? "completed" : "bannerUpcoming"}
      getEventDescription={getEventDescription}
      headerChildren={<Content/>}
      interviewAs="HOS"
      interviewDate={selectedDate}
      showAddToCalendar={showAddToCalendar}
      extraButton={editButton}
    />
  );
}


InterviewCard.propTypes = {
  interview: PropTypes.object.isRequired,
  setActionedCandidate: PropTypes.func,
  setOfferRoleModalOpen: PropTypes.func,
  setDisqualifyModalOpen: PropTypes.func,
  onRowClick: PropTypes.func,
  setSharedPlacementUUID: PropTypes.func,
  setIsShareModalOpen: PropTypes.func,
  setSuggestOfferModalOpen: PropTypes.func,
  setSuggestDeclineModalOpen: PropTypes.func,
};

function JoinCall({candidate}) {
  return (
    <Button
      baseComponent="button"
      kind="primary"
      onClick={() => {
        joinCallModalInterface.dispatch({
          open: true,
          candidate,
        });
      }}
      className="mb-0"
    >
      <i className="ri-vidicon-fill mr-2"/> Join Call
    </Button>
  );
}

export default InterviewCard;
