import api from "services/v3";

export default class EventTracking {
  static send(data: TrackedEvent): Promise<TrackEvent> {
    return api.createEvent({
      ...data,
      source: "web",
      url: location.href,
    });
  }
}
