import React from "react";
import PropTypes from "prop-types";
import {Content} from "components/shared/Slate";

import BespokeProjectCard from "./BespokeProjectCard";
import ProjectTemplateList from "./ProjectTemplateList";
import styles from "./projectPickerPage.module.scss";

function ProjectPickerPage({projectTemplates}) {
  return (
    <>
      <h1 className={styles.title}>Create your own project type</h1>
      <h2 className={styles.subtitle}>
        With what kind of project do you need some extra help with?
      </h2>
      <BespokeProjectCard/>

      <h1 className={styles.title}>Our project template selection</h1>
      <h2 className={styles.subtitle}>
        Choose from our selection of different projects to get going!
      </h2>
      <Content className={styles.content}>
        <ProjectTemplateList data={projectTemplates.filter(template => template.name !== "Custom")}/>
      </Content>
    </>
  );
}

ProjectPickerPage.propTypes = {
  projectTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProjectPickerPage;
