import mixpanel from "mixpanel-browser";

/*
To be used as follows:

import {Mixpanel} from "../../tracking/Mixpanel";

Mixpanel.track(Mixpanel.PROFILE_UPDATE, {'profile_type': "host", "input_name": "input_jobTitle"});
*/

let env_check = process.env.NODE_ENV === "production" && !!(
  process.env.MIXPANEL_TOKEN && process.env.MIXPANEL_TOKEN !== "0"
);

if (env_check) {
  mixpanel.init(process.env.MIXPANEL_TOKEN);
}

let actions = {
  // event constants
  PAGE_VIEW: "pageView",
  PROFILE_UPDATE: "profileUpdate",
  PROJECT_APPLY_START: "projectApplyStart",
  PROJECT_APPLY_NEXT: "projectApplyNext",
  PROJECT_APPLY_BACK: "projectApplyBack",
  PROJECT_APPLY_PREVIEW: "projectApplyPreview",
  PROJECT_APPLY_SUBMIT: "projectApplySubmit",
  WEB_HOST_SHARE: "webHostShare",
  WEB_HOST_SHARE_CONTACT: "webHostShareContact",
  WEB_HOST_SHARE_VIEW: "webHostShareView",
  WEB_HOST_ADD_PROJECT_CHOOSE: "webHostAddProjectChoose",
  WEB_HOST_ADD_PROJECT_NEXT: "webHostAddProjectNext",
  WEB_HOST_ADD_PROJECT_SUBMIT: "webHostAddProjectSubmit",

  // event methods
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  identify: (user) => {
    if (env_check) {
      const current_id = mixpanel.get_distinct_id();
      if (current_id != user.uuid) {
        mixpanel.alias(user.uuid, current_id);
        mixpanel.people.set({
          $email: user.email,
        });
      }
      mixpanel.identify(user.uuid);
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
      }
    },
  },
};

export let Mixpanel = actions;
