import React from "react";
import {Subtitle, Heading, Caption} from "workfinder-components";
import Tooltip from "components/shared/Tooltip";
import {isMobile} from "util/device-check";
import classNames from "classnames";

interface Props {
  /** The body of the InputGroup. */
  children: React.ReactNode;
  /** A theme to restyle the stepper. */
  title?: string;
  /** The sub-title of the input group. */
  subTitle?: string;
  /** Smaller title than subtitle */
  caption? : string;
  /** A tooltip to display next to the title. */
  tooltip?: React.ReactNode;
  /** A extra header to display next to the title. */
  extraHeaderLink?: React.ReactNode;
  /** If tooltip is clickable, it also adds a delay on hide event */
  tooltipClickable?: boolean;
  /** Whether the input is required. */
  required?: boolean;
  /** ClassName applied to the first div of FormLabel */
  labelClassName?: string;
  subTitleClass?: string;
  /** Forced tooltip position */
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  extraHeaderButton?: React.ReactNode;
}

export default function FormLabel({children, title, subTitle, caption, tooltip, extraHeaderLink, labelClassName, tooltipPosition, tooltipClickable=false, required=false, subTitleClass="", extraHeaderButton=null}:Props): JSX.Element {
  return (<>
    <div className={classNames("d-flex", labelClassName)}>
      <div className="d-flex">
        {title && <Heading size="h5">{title}{required && "*"}</Heading> }
        {tooltip && <Tooltip
          delayHide={tooltipClickable ? 1000 : 0}
          clickable={tooltipClickable}
          place={tooltipPosition || (isMobile() ? "top" : "right")}
        >
          {tooltip}
        </Tooltip>}
      </div>
      {extraHeaderButton && extraHeaderButton}
    </div>

    <div className="d-flex">
      {subTitle && <Subtitle size="small" className={subTitleClass}>{subTitle}</Subtitle>}
      {extraHeaderLink}
    </div>
    {caption && <Caption>{caption}</Caption> }
    {children}
  </>
  );
}
