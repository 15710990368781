import React from "react";
import classNames from "classnames";
import {Heading, Body, Button, Caption} from "workfinder-components";

import GradientCheckMark from "/static/workfinder/images/emojis/GradientCheckMark.svg";

import styles from "./skillReferralRequestCard.module.scss";

export const Tag = () => (
  <span className={styles.tag}>
    <img
      src={GradientCheckMark}
      alt="verified-check-mark"
    />
    Reference request
  </span>
);

export const RequestorProfile = ({requestor_photo_url, requestor_full_name, requestor, includeFor=true}) => (
  <div className={styles.requestorProfile}>
    <img className={styles.requestorPic} src={requestor_photo_url}/>
    <div>
      <span
        className={classNames(styles.requestorName, "text-decoration-none")}
      >
        {includeFor ? "For " : ""}
      </span>
      <a
        className={styles.requestorName}
        href={Variables.urls.candidates.member_public_profile.replace("<user_id>", requestor)}
        target="_blank"
        rel="noreferrer"
      >
        {requestor_full_name}
      </a>
    </div>
  </div>
);

const SkillReferralRequestCard = ({skillReferralRequest = {}, setIsSkillReferralRequestModalOpen, submittedReferralUuid}) => {
  const {requestor_photo_url, requestor_full_name, uuid, requestor} = skillReferralRequest;
  const handleCompleteReference = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("ref_req", uuid);
    const updatedURL = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}#skillreferral`;
    window.history.replaceState({}, document.title, updatedURL);
    setIsSkillReferralRequestModalOpen(true);
  };

  const isReferralPending = submittedReferralUuid !== uuid;

  return (
    <div className={styles.container}>
      <Tag/>
      <Heading className={styles.greeting} size="h5">
        {isReferralPending ? <strong>👋 Hi {Variables.user?.first_name},</strong> : <strong>Thank you!</strong>}
      </Heading>
      <Body size="small" className={styles.request}>
        {isReferralPending ? (
          <>
            I&apos;d appreciate it if you could rate my skills to help me advance my journey.
            <br/>
            Thanks in advance! 😊
          </>
        ) : "Your reference has been submitted for:"}
      </Body>
      <RequestorProfile
        requestor_photo_url={requestor_photo_url}
        requestor_full_name={requestor_full_name}
        requestor={requestor}
        includeFor={isReferralPending}
      />
      {isReferralPending ? (
        <>
          <hr className={styles.hr}/>
          <Caption size="large">
            Your feedback provides valuable insights that can help them improve and refine their skillset. It&apos;s a small gesture that goes a long way in supporting their career journey.
            <div className="mb-3"/>
            By contributing a reference, you support the Superpower community by helping others thrive in our shared pursuit of skill development and career success.
          </Caption>
          <Button onClick={handleCompleteReference}>
            Complete Reference
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default SkillReferralRequestCard;
