import React, {useState} from "react";
import Button from "components/shared/Button";
import styles from "./index.module.scss";
import classNames from "classnames";
import {SingnUpLoginModal} from "components/shared/Modal/SignUpModal";
import api from "services/v3";
import {ADDON_MESSAGING_WITH_CANDIDATES} from "./constants";
import {useSubDomainQuery} from "services/queries";
import { combineQueries } from "util/query";

let cbInstance;
const openPaymentDialog = (plan:string, addon:string, method:string, redirect_url:string):void => {
  cbInstance = cbInstance ?? window.Chargebee.init({site: Variables.chargebee_site});
  cbInstance.openCheckout({
    hostedPage: async function () {
      try {
        const response = await api.getPaymentPage({plan, addon, method});
        return response;
      }
      catch (error) {
        // addAlert("load-error", "danger", error.json?.detail ?? "An error has occurred");
        cbInstance.closeAll();
      }
    },
    loaded: function () {
      console.log("checkout opened");
    },
    error: function () {
      console.log("error");
    },
    success: function (hostedPageId) {
      window.location.href = redirect_url;
    },
    close: function () {
    },
    step: function (value) {
      // value -> which step in checkout
      console.log(value);
    },
  });
};

const savePayByInvoice = (plan:string, addon:string, redirect_url:string):void => {
  const data = {is_invoice: true};
  api.updateMyPremiumPartner({data}).then(() => {
    window.location.href = redirect_url;
  });
};

export default function PayButton({addon, isInvoice, plan, className="", onClick=() => {}}): JSX.Element {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);
  const queries = [
    useSubDomainQuery({refetchInterval: 800, enabled: !stopFetching}),
  ];

  const {data, isLoading, isSuccess} = combineQueries(queries);

  if (addon == null) {
    addon = ADDON_MESSAGING_WITH_CANDIDATES;
  }
  if (isLoading) {
    return (<></>);
  }
  if (isSuccess) {
    const redirect_url = `/thank-you/?feature=${addon}`;
    if (!stopFetching) {
      setStopFetching(true);
    }
    if (data && data.length > 0 && data[0].redirect_to_thankyou) {
      window.location.href = redirect_url + "&subscribed=true";
    }

    if(data && data.length > 0)
      plan = data[0].plan_id;

    return (
      <>
        <Button className={classNames(styles.addOnButton, className) } kind="offblack" onClick={() => {
          openPaymentDialog(plan, addon, "card", redirect_url);
          onClick();
        }}
        >
          Pay by credit card
        </Button>
        {isInvoice &&
        <Button className={classNames(styles.addOnButton, className) } kind="offblack" onClick={() => {
          savePayByInvoice(plan, addon, redirect_url);
          onClick();
        }}
        >
          Pay by invoice
        </Button>}
        <Button className={classNames(styles.addOnButton, className) } kind="offblack" onClick={() => {
          openPaymentDialog(plan, addon, "card", redirect_url);
          onClick();
        }}
        >
          Pay by coupon
        </Button>
        <SingnUpLoginModal showSignUpModal={showSignUpModal} setShowSignUpModal={setShowSignUpModal} plan_id="Add-on-GBP-Monthly"  plan_name="Team" addon_id={addon} />
      </>
    );

  }
  return <></>;
}
