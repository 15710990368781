import {relativeUrl} from "util/url";

const MODAL_IDS = [
  "loginModal",
  "signupModal",
  "ppsignupModal",
  "hostSignupModal",
  "youngPersonLoginModal",
  "youngPersonSignupModal",
  "youngPersonSignupModal2", 
];

/**
 * Set the URL to redirect to after log in or sign up.
 * @param next The URL to redirect to after log in or sign up. If omitted, the
 * next page will be set to the current page.
 */
export function setNext(next: string=relativeUrl(location.href)): void {
  for (const modalId of MODAL_IDS) {
    const formEl = $(`#${modalId} form`);
    const nextInputEl = formEl.find("[name=next]").first();
    if (nextInputEl.length === 0) {
      formEl.append(`<input type="hidden" name="next" value="${next}"/>`);
    }
    else {
      nextInputEl.val(next);
    }
  }
}

/**
 * Hide the sign up and log in modal tabs. This to prevent users from signing
 * up as a type of user they shouldn'y given an action performed, e.g.
 * commenting on an application means you should sign up as a host.
 */
export function hideTabs(): void {
  const selector = MODAL_IDS.map(id => `#${id} .nav-tabs`).join(",");
  $(selector).hide();
}

window.setNext = setNext;
