import React, {useEffect, useState} from "react";
import Modal from "components/shared/Modal/Modal";
import api from "services/v3";
import styles from "./documentRequestCard.module.scss";
import SBcircle from "/static/workfinder/images/SBcircle.png";
import {skillBuilderColor} from "./skillsBuilderSkills";
import ThankYouModal from "components/shared/Modal/ThankYouModal";
import { Button, Heading, Body } from "workfinder-components";

export const SkillLevelSubmittedCard = ({skill}):JSX.Element => {
  const url_slug = skill.toLowerCase().replace(" ", "-");
  return (
    <>
      <img className={styles.skillBuilderImage} src={SBcircle}></img>
      <h6 className={styles.thankYou}>Thank you!</h6>
      <p className={styles.cardText}>
      We have shared your skill level in &nbsp;
        <span style={{color: skillBuilderColor[skill]}}>
          <a target="_blank" rel="noreferrer" href={`https://www.skillsbuilder.org/universal-framework/${url_slug}`}><b>{skill}</b></a></span>&nbsp;with recruiters
      </p>
    </>
  );
};

const SkillSaveModal = ({showSucessModal, setShowSignUpModal, skill}) => {

  return (
    <Modal id="skill-save-modal" className={styles.skillSaveModal} open={showSucessModal}
      onClose={() => {
        setShowSignUpModal(false);
      }}
    >
      {skill && <SkillLevelSubmittedCard skill={skill} />}
      <Button
        className="mt-3"
        kind="solid"
        onClick={() => setShowSignUpModal(false)}
        type="button"
      >Close</Button>
    </Modal>
  );
};

export default function DocumnetRequestUpdate(): JSX.Element {
  const [showSucessModal, setshowSucessModal] = useState(false);
  const [skill, setSkill] = useState();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const document_request = queryParams.get("dr");
    const position = queryParams.get("level");
    if (document_request && position) {
      api.updateDocumentRequestSkillLevel({document_request, position}).then((result) => {
        const {skill} = result;
        setSkill(skill);
        setshowSucessModal(true);
        const newUrl = window.location.href.split("?")[0];
        window.history.pushState({}, "", newUrl);
      });
    }
  }, []);
  return (<>
    <ThankYouModal
      isSuccess={showSucessModal}
      onClose={() => setshowSucessModal(false)}
      header={<>
        <Heading size="h5">Thanks for sharing your skill level!</Heading>
      </>
      }
      label={"🏆 Skill level saved!"}
      message={<Body>We've updated your profile and informed the member who asked.</Body>}
    />
    {/* <SkillSaveModal showSucessModal={showSucessModal} setShowSignUpModal={setshowSucessModal} skill={skill}/> */}
  </>);
}
