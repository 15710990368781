import {useMutation, useQueryClient, UseMutationOptions} from "react-query";

import api from "services/v3";
import {addToListCache, removeFromListCache, updateObjectCache, updateListCache} from "util/mutation";
import {getDefaultQueryClient} from "util/query";
import {status as interviewStatus} from "util/interview/constants";

interface UseMutationExtendedOptions {
  /** The key for the query retrieving the object. */
  queryKey?: any;
  /** Any other options, passed straight to `useMutation`. */
  options?: UseMutationOptions;
  /**
   * Enables optimistic updates. The sent data must be an object, whose keys
   * will be shallowly replaced in the cache.
   */
  optimistic?: boolean;
  /**
   * The key for the list in which the patched object is stored. This is used
   * when retrieving a list but patching individual objects in said list.
   */
  listKey?: any;
  /**
   * Same as `listKey`, but allow defining an array of list keys.
   */
  listKeys?: any[];
  /**
   * The name of a field whose value contains a unique identifier for the the
   * patched object, such that it can be identified in the a list.
   */
  idField?: string;
}

/**
 * Implements the common mutation pattern for single objects being patched.
 * @returns A mutation, as returned by `useMutation`.
 */
function usePatchMutation(mutationFn, {queryKey=undefined, options={}, optimistic=false, listKey, listKeys=[], idField="uuid"}: UseMutationExtendedOptions) {
  const {updateCache=true, ...restOptions} = options;
  const queryClient = getDefaultQueryClient();
  const allListKeys = [...listKeys];

  if (listKey) {
    allListKeys.push(listKey);
  }

  return useMutation(mutationFn, {
    ...restOptions,
    queryKey,
    onSuccess(data) {
      if (updateCache) {
        if (queryKey) {
          updateObjectCache(queryClient, queryKey, data);
        }

        for (const listKey of listKeys) {
          updateListCache(queryClient, listKey, data, idField);
        }
      }
      restOptions.onSuccess?.(data);
    },
    onMutate: async data => {
      if (optimistic) {
        queryClient.setQueryData(queryKey, oldData => ({
          ...oldData,
          ...data,
        }));
      }
      restOptions.onMutate?.(data);
    },
  });
}


/**
 * Implements the common mutation pattern for single objects being posted.
 * @param {*} mutationFn An API call that posts a single object.
 * @returns A mutation, as returned by `useMutation`.
 */
function usePostMutation(mutationFn, {queryKey=undefined, options={}, optimistic=false, listKey, listKeys=[]}: UseMutationExtendedOptions) {
  const {updateCache=true, ...restOptions} = options;
  const queryClient = getDefaultQueryClient();
  const allListKeys = [...listKeys];

  if (listKey) {
    allListKeys.push(listKey);
  }

  return useMutation(mutationFn, {
    ...restOptions,
    onSuccess(data) {
      if (updateCache) {
        if (queryKey) {
          queryClient.setQueryData(queryKey, data);
        }

        if (listKey) {
          addToListCache(queryClient, listKey, data);
        }
      }
      restOptions.onSuccess?.(data);
    },
    onMutate: async data => {
      if (optimistic) {
        queryClient.setQueryData(queryKey, data);
      }
      restOptions.onMutate?.(data);
    },
  });
}


/**
 * Implements the common mutation pattern for single objects being deleted.
 * in said list.
 * @param {*} mutationFn An API call that posts a single object.
 * @returns A mutation, as returned by `useMutation`.
 */
function useDeleteMutation(mutationFn, {queryKey=undefined, options={}, optimistic=false, listKey, listKeys=[]}: UseMutationExtendedOptions) {
  const {updateCache=true, ...restOptions} = options;
  const queryClient = useQueryClient();
  const allListKeys = [...listKeys];

  if (listKey) {
    allListKeys.push(listKey);
  }

  return useMutation(mutationFn, {
    ...restOptions,
    onSuccess(data) {
      if (updateCache) {
        if (queryKey) {
          queryClient.setQueryData(queryKey, undefined);
        }

        if (listKey) {
          removeFromListCache(queryClient, listKey, data);
        }
      }
      restOptions.onSuccess?.(data);
    },
    onMutate: async data => {
      if (optimistic) {
        queryClient.setQueryData(queryKey, data);
      }
      restOptions.onMutate?.(data);
    },
  });
}

// Activity

export function useActivityDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    id => api.deleteActivity(id),
    {
      options,
      listKeys: ["activities", "candidate-activities"],
    },
  );
}

// Associations

export function useAssociationMutation(uuid, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateAssociation(uuid, data),
    {
      queryKey: ["company", uuid],
      options,
    },
  );
}

// Candidates

export function useCandidateMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateCandidate(uuid, data),
    {
      queryKey: ["candidate", uuid],
      options,
    },
  );
}


export function useCandidateTechSkillMutation(options?: UseMutationOptions) {
  return usePostMutation(
    api.createCandidateTechSkill,
    {
      queryKey: ["candidate-tech-skills"],
      options,
    },
  );
}

export function useCandidateTechSkillDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    id => api.deleteCandidateTechSkill(id),
    {
      options,
      listKeys: ["candidate-tech-skills"],
    },
  );
}


export function useCandidateLanguagesMutation(options?: UseMutationOptions) {
  return usePostMutation(
    api.createCandidateLanguages,
    {
      queryKey: ["candidate-languages"],
      options,
    },
  );
}


export function useCandidateLanguagesDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    id => api.deleteCandidateLanguages(id),
    {
      options,
      listKeys: ["candidate-languages"],
    },
  );
}

export function useCandidateSkillsbuilderSkillsMutation(options?: UseMutationOptions) {
  return usePostMutation(
    api.createCandidateSkillsbuilderSkills,
    {
      queryKey: ["candidate-skill-builder-skills"],
      options,
    },
  );
}

// Comments

export function useCommentMutation(options?: UseMutationOptions) {
  const queryClient = useQueryClient();

  return usePatchMutation(
    api.createComment,
    {
      options: {
        ...options,
        onSuccess(data) {
          addToListCache(queryClient, ["comments", data.content_object], data);
          options.onSuccess?.(data);
        },
      },
    },
  );
}

// Companies

export function useCompanyMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateCompany(uuid, data),
    {
      queryKey: ["company", uuid],
      options,
    },
  );
}

export function usePrimaryCompanyMutation(companyPk: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updatePrimaryCompany(companyPk, data),
    {
      queryKey: ["primary-company", companyPk],
      options,
    },
  );
}

export function useLocationMutation(uuid: string, options?: UseMutationOptions) {
  const queryClient = useQueryClient();
  return usePatchMutation(
    data => api.updateLocation(uuid, data),
    {
      queryKey: ["location", uuid],
      options: {
        ...options,
        onSuccess(location) {
          queryClient.setQueryData(["company", location.company], oldCompany => {
            if (oldCompany === undefined) {
              return;
            }
            return {
              ...oldCompany,
              locations: oldCompany.locations.map(oldLocation =>
                location.uuid === oldLocation.uuid ? {...oldLocation, ...location} : oldLocation,
              ),
            };
          });
          options.onSuccess?.(location);
        },
      },
    },
  );
}

export function useLocationCreateMutation(key, options?: UseMutationOptions) {
  return usePostMutation(
    api.createLocation,
    {
      queryKey: ["location", key],
      options,
    },
  );
}

export function useCompanySocialLinksMutation(companyPk: string, options?: UseMutationOptions) {
  return usePatchMutation(data => api.updateCompanySocialLinks(companyPk, data), {options, queryKey: ["company-sociallinks", companyPk]});
}

// Hosts

export function useHostMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateHost(uuid, data),
    {
      queryKey: ["host", uuid],
      options,
    },
  );
}

// Interviews

export function useInterviewMutation(options?: UseMutationOptions) {
  return usePatchMutation(
    ({uuid, data}) => api.updateInterview(uuid, data),
    {
      options,
      listKey: "interviews",
    },
  );
}

export function useCreateInterviewMutation(options?: UseMutationOptions) {
  const queryClient = getDefaultQueryClient();
  return usePostMutation(
    ({data}) => api.createInterview(data),
    {
      options: {
        ...options,
        onSuccess(data) {
          // Update the interview if old interview is there.
          if(data.old_interview) {
            queryClient.setQueryData("interviews", interviews => {
              if (interviews === undefined) {
                return undefined;
              }
              return interviews.map(interview => {
                if (interview.id === data.old_interview) {
                  return {
                    ...interview,
                    status: interviewStatus.INTERVIEW_RESCHEDULED
                  };
                }
                return interview;
              });
            });
          }
          options?.onSuccess?.(data);
        },
      },
      listKey: "interviews",
    },
  );
}

export function useInterviewDateMutation(options?: UseMutationOptions) {
  const queryClient = useQueryClient();
  return usePatchMutation(
    ({id, data}) => api.updateInterviewDate(id, data),
    {
      options: {
        ...options,
        onSuccess(data) {
          // Update the interview that has this date, if present.
          queryClient.setQueryData("interviews", interviews => {
            if (interviews === undefined) {
              return undefined;
            }
            return interviews.map(interview => {
              if (interview.id === data.interview) {
                return {
                  ...interview,
                  interview_dates: interview.interview_dates.map(
                    date => date.id === data.id ? {...date, ...data} : date,
                  ),
                };
              }
              return interview;
            });
          });
          options?.onSuccess?.(data);
        },
      },
    },
  );
}

// Projects

export function useProjectMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateProject(uuid, data),
    {
      queryKey: ["project", uuid],
      listKey: ["myprojects", "my-projects"],
      options,
    },
  );
}

// Recommendations

export function useRecommendationMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    data => api.updateRecommendation(uuid, data),
    {
      queryKey: ["recommendation", uuid],
      options,
    },
  );
}

// Sharing

export function useInvitesCreateMutation(options?: UseMutationOptions) {
  return usePostMutation(
    api.createInvites,
    {
      queryKey: "invites",
      options,
    },
  );
}

export function useShareDeleteMutation(uuid: string, email: string, refetch) {
  const queryClient = useQueryClient();
  return useMutation(() => api.deleteShare(uuid, email), {
    onSuccess(data) {
      updateObjectCache(queryClient, ["share", data.content_object], data);
      refetch();
    },
  });
}

/**
 * Mutation for sharing.
 * @param {*} queryKey An array of the form [object_uuid].
 * @param {*} options Any extra options to pass to `useMutation`.
 * @returns
 */
export function useShareCreateMutation(key, options?: UseMutationOptions) {
  return usePostMutation(
    api.createShare,
    {
      queryKey: ["share", ...key],
      options,
    },
  );
}

/**
 * Mutation for sharing.
 * @param {*} queryKey An array of the form [content_type, object_id].
 * @param {*} options Any extra options to pass to `useMutation`.
 * @returns
 */
export function useShareUpdateMutation(key, options?: UseMutationOptions) {
  return usePatchMutation(
    ({uuid, data}) => api.updateShare(uuid, data),
    {
      queryKey: ["share", ...key],
      options,
      optimistic: true,
    },
  );
}

// Users

export function useUserMutation(options?: UseMutationOptions) {
  return usePatchMutation(api.updateUser, {options, queryKey: "user"});
}

export function usePreferenceMutation(options?: UseMutationOptions) {
  return usePostMutation(
    data => api.updateUserPreferences(data),
    {
      queryKey: ["user-preferred-fields"],
      options,
    },
  );
}

export function useUpdateInterestMutation(options?: UseMutationOptions) {
  return usePostMutation(
    api.updateInterest,
    {
      queryKey: "user-interests",
      options,
      optimistic: true,
    },
  );
}

//
export function useSocialLinksMutation(options?: UseMutationOptions) {
  return usePatchMutation(api.updateSocialLinks, {options, queryKey: "user"});
}

export function useUserDeleteMutation(options?: UseMutationOptions) {
  return useMutation(api.deleteUser, options);
}
export function useExperienceDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    uuid => api.deleteExperience(uuid),
    {
      options,
      listKeys: ["user-experiences"],
    },
  );
}

export function useEducationDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    uuid => api.deleteEducation(uuid),
    {
      options,
      listKeys: ["user-educations"],
    },
  );
}

export function useCertificateDeleteMutation(options?: UseMutationOptions) {
  return useDeleteMutation(
    uuid => api.deleteUserCertificate(uuid),
    {
      options,
      listKeys: ["user-certificates"],
    },
  );
}

type User = "CAN" | "HOS";
export function usePlacementMutation(userType: User, options?: UseMutationOptions) {
  const queryClient = useQueryClient();
  return usePatchMutation(
    ({uuid, data}) => {
      if (userType === "CAN") {
        return api.updateCandidatePlacement(uuid, data);
      }
      if (userType === "HOS") {
        return api.updateHostPlacement(uuid, data);
      }
    },
    {
      options: {
        ...options,
        updateCache: false,
        onSuccess(data) {
          updateListCache(queryClient, ["placement", data.uuid], data, "uuid");
          options.onSuccess?.(data);
        },
      },
      listKey: "placements",
    },
  );
}

export function useUpdatePlacementOfferMutation(uuid: string, options?: UseMutationOptions) {
  const queryClient = useQueryClient();
  return usePatchMutation(
    (data) => api.updatePlacementOffer(uuid, data),
    {
      options: {
        ...options,
        updateCache: false,
        onSuccess(data) {
          updateListCache(queryClient, ["placement", data.uuid], data, "uuid");
          options.onSuccess?.(data);
        },
      },
      listKey: "candidate-placements-offers",
    },
  );
}


export function useUpdateExperienceMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    (data) => api.updateExperience(uuid, data),
    {
      options: {
        ...options,
      },
      listKey: "user-experiences",
    },
  );
}

//createExperience
export function useCreateExperienceMutation(options?: UseMutationOptions) {

  return usePatchMutation(
    api.createExperience,
    {
      options: {
        ...options,
      },
      listKey: "user-experiences",
    },
  );
}


export function useUpdateEducationMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    (data) => api.updateEducation(uuid, data),
    {
      options: {
        ...options,
      },
      listKey: "user-educations",
    },
  );
}

export function useUpdateCertificateMutation(uuid: string, options?: UseMutationOptions) {
  return usePatchMutation(
    (data) => api.updateUserCertificate(uuid, data),
    {
      options: {
        ...options,
      },
      listKey: "user-certificates",
    },
  );
}

export function useCreateEducationMutation(options?: UseMutationOptions) {

  return usePatchMutation(
    api.createEducation,
    {
      options: {
        ...options,
      },
      listKey: "user-educations",
    },
  );
}


export function useCreateCertificateMutation(options?: UseMutationOptions) {

  return usePatchMutation(
    api.createUserCertificate,
    {
      options: {
        ...options,
      },
      listKey: "user-certificates",
    },
  );
}
