export const planNames = {
  FREE: "Free",
  TEAM: "Pro Recruiter",
  BUSINESS: "Super Recruiter",
  ENTERPRISE: "Enterprise",
};

export const planNextHigherPlanMap = {
  [planNames.FREE]: planNames.TEAM,
  [planNames.TEAM]: planNames.BUSINESS,
  [planNames.BUSINESS]: planNames.ENTERPRISE
}

export const features = {
  ROLE_POSTING: "Free unlimited role creation",
  DEI_SHORTLIST: "DEI balanced shortlist in hours",
  HIRING_PLATFORM: "End-to-end hiring platform",
  HIRE_MEMBERS: "Hire Superpower members",
  BASIC_PROFILE: "Basic Company Profile",
  SUPER_PROFILE: "Super Company Profile",
  RECOMMENDED_MATCH: "View recommended match",
  REQUEST_DOCUMENTS: "View & request documents",
  MESSAGING: "Direct messaging",
  ARCHIVED_APPLICANTS: "Access archived applicants",
  COLLABORATORS: "Invite collaborators",
  DEI_ALERTS: "DEI alerts & training",
  ACCOUNT_MANAGEMENT: "Centralised account management",
  REPORTING: "Reporting",
  WHITELABELLING: "Whitelabelling",
  SUPPORT: "Premium support"
};

export const featuresMapping = {
  [planNames.FREE]: [
    features.ROLE_POSTING,
    features.DEI_SHORTLIST,
    features.HIRING_PLATFORM,
    features.HIRE_MEMBERS,
    features.BASIC_PROFILE
  ],
  [planNames.TEAM]: [
    features.ROLE_POSTING,
    features.DEI_SHORTLIST,
    features.HIRING_PLATFORM,
    features.HIRE_MEMBERS,
    features.BASIC_PROFILE,
    features.SUPER_PROFILE,
    features.RECOMMENDED_MATCH,
    features.REQUEST_DOCUMENTS,
    features.MESSAGING,
    features.ARCHIVED_APPLICANTS
  ],
  [planNames.BUSINESS]: [
    features.ROLE_POSTING,
    features.DEI_SHORTLIST,
    features.HIRING_PLATFORM,
    features.HIRE_MEMBERS,
    features.BASIC_PROFILE,
    features.SUPER_PROFILE,
    features.RECOMMENDED_MATCH,
    features.REQUEST_DOCUMENTS,
    features.MESSAGING,
    features.ARCHIVED_APPLICANTS,
    features.COLLABORATORS,
    features.DEI_ALERTS,
    features.ACCOUNT_MANAGEMENT,
    features.REPORTING

  ],
  [planNames.ENTERPRISE]: [
    features.ROLE_POSTING,
    features.DEI_SHORTLIST,
    features.HIRING_PLATFORM,
    features.HIRE_MEMBERS,
    features.BASIC_PROFILE,
    features.SUPER_PROFILE,
    features.RECOMMENDED_MATCH,
    features.REQUEST_DOCUMENTS,
    features.MESSAGING,
    features.ARCHIVED_APPLICANTS,
    features.COLLABORATORS,
    features.DEI_ALERTS,
    features.ACCOUNT_MANAGEMENT,
    features.REPORTING,
    features.WHITELABELLING,
    features.SUPPORT
  ],
};

export const featuresDescriptions = {
  [features.ROLE_POSTING]: "Publish as many roles as you like, with each one taking less than 30 seconds to set up.",
  [features.DEI_SHORTLIST]: "Get a shortlist of quality-matched applicants within hours, reducing your hiring time by 10x. Quality over quantity is our focus. Ensure unbiased recruitment and achieve diversity goals with shortlists balanced on gender, ethnicity, socioeconomic background, and more.",
  [features.HIRING_PLATFORM]: "Streamline your hiring process from interviews to offers, all within our comprehensive platform.",
  [features.HIRE_MEMBERS]: "",
  [features.BASIC_PROFILE]: "Showcase your company with a detailed description and links to make a lasting impression.",
  [features.SUPER_PROFILE]: "Elevate your company's presence with an advanced profile that includes insights, benefits, values, videos, links, team members, and more, helping you stand out among other companies.",
  [features.RECOMMENDED_MATCH]: "Assess candidate suitability faster with access to our leading member-to-role matching score, offering deeper insights than any other recruitment platform.",
  [features.REQUEST_DOCUMENTS]: "Easily view and request additional documents and links from candidates to support your decision-making process.",
  [features.MESSAGING]: "Connect with candidates directly through our messaging feature, fostering efficient communication.",
  [features.ARCHIVED_APPLICANTS]: "Access archived applicants so you never miss out on a perfect match",
  [features.COLLABORATORS]: "Invite collaborators to provide feedback on applications, facilitating quicker decision-making. No extra charges for collaborators.",
  [features.DEI_ALERTS]: "Ensure unbiased hiring by providing team members with bias alerts and upskilling feedback throughout the hiring process.",
  [features.ACCOUNT_MANAGEMENT]: "Org-level management for adoption and usage including a white labelling service for rapid team onboarding.",
  [features.REPORTING]: "Gain deep insights into your team's hiring behavior and performance, including diversity, equity, and inclusion metrics.",
  [features.SUPPORT]: "",
  [features.WHITELABELLING]: ""
};

export const featuresLinks = {
  [features.MESSAGING]: "https://superpower.tech/features/messaging/",
  [features.COLLABORATORS]: "https://superpower.tech/features/collaborate/",
};

export const PAYMENT_CYCLE = {
  ANNUM: "annually",
  MONTH: "monthly",
};

export const PAYMENT_METHOD = {
  INVOICE: "Invoice",
  CARD: "Card"
}
