import React from "react";
import styles from "./invitesList.module.scss";
import classNames from "classnames";
import { Heading, Subtitle } from "workfinder-components";
import genericPhoto from "/static/workfinder/images/generic-photo.png";

interface Invite {
  id: number,
  photo?: string,
  fullName?: string,
  email: string;
  designation?: string;
  publicProfileUrl?: string;
}

const InvitesList = ({invites, className} : {invites: Invite[], className? : string}) => {
  return (
    <ul className={classNames("list-unstyled overflow-auto", styles.invitesList, className)}>
      {invites.map(user => {
        const photo = user.photo ?? genericPhoto;
        // const wordsInDesignation = user.designation?.trim().split(/\s+/).length ?? 0;
        return (<>
          <li className={classNames(styles.inviteCard, "media")}>
            <div className={styles.userDetail}>
              <img className={styles.candidatePhoto} src={photo}/>
              <div className={classNames("media-body my-auto", styles.userName)}>
                <Heading size="h6" className={classNames("mb-0", styles.overflow)}>
                  <a
                    className={classNames(styles.greyLink)}
                    href={user.publicProfileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >{user.fullName ? user.fullName : user.email}</a>
                </Heading>
                <Subtitle size="small" className={classNames(styles.grey, styles.overflow)}>{user.email}</Subtitle>
              </div>
            </div>
            {/* {user.designation &&
              <Subtitle size="small" className={classNames(styles.grey, styles.designation, wordsInDesignation == 1 ? styles.overflow : styles.overflowMultiline)}>{user.designation}</Subtitle>
            } */}
          </li>
        </>);
      },
    )}
  </ul>);
};

export default InvitesList;
