import React, { useEffect, useState } from "react";
import {Heading, Input, Subtitle} from "workfinder-components";
import api from "services/v3";
import styles from "./index.module.scss"
import classNames from "classnames";
import ErrorMessage from "components/bespoke-project/pages/ErrorMessage";
import PulseLoader from "react-spinners/PulseLoader";

export default function Verification({isLogin}) {
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [emailErrors, setEmailErrors] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = React.useState(30);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const url = new URL(window.location.href);
  const user_uuid = url.pathname.split("/").slice(-1)[0];
  const email = url.searchParams.get("email");
  const verificationApi = isLogin ? api.verifyLoginOtp : api.verifySignupOtp;
  const userData = isLogin ? {user_uuid: user_uuid, otp: code} : {user_uuid: user_uuid, otp: code, perform_login: true};
  const headerText1 = isLogin ? "We’ve sent a login code to" : "We’ve sent a 6 digit code to";
  const headerText2 = isLogin ? "Please enter it below to safely log in." : "Please enter it below to confirm your email address.";
  const footerText = isLogin ? "send login code again" : "send code again";
  const resendApi = isLogin ? api.resendLoginOtp : api.resendSignupOtp;

  useEffect(() => {
    if(validCode) {
      setTimeout(() => {
        if(code.length == 6) {
          verificationApi(userData).then((response) => {
            setEmailErrors({});
            window.location.href = `/`;
          }).catch((error) => {
            if(error.json.message) {
              setEmailErrors({
                email: [
                  error.json.message,
                ],
              });
            }
          });
        }
      }, 500);
    }
  }, [validCode, code]);

  React.useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const changeCodeValue = (value) => {
    setCode(value);
    if(value.length == 6) {
      setValidCode(true);
    }
    else {
      setEmailErrors({});
      setValidCode(false);
    }
  }
  const resendCode = () => {
    setIsLoading(true);
    resendApi({user_uuid: user_uuid}).then(() => {
      setIsLoading(false);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setTimer(30);
      }, 1500);
    }).catch(error => {
      setIsLoading(false);
      setShowFailureMessage(true);
      setTimeout(() => {
        setShowFailureMessage(false);
      }, 1500);
    });
  }

  const currentfooterText = () => {
    if(isLoading)
      return <PulseLoader color="#11152A" margin={2} size={6}/>;
    else if (timer > 0)
      return <>Wait for {timer} seconds before you {footerText}</>;
    else if (showSuccessMessage)
      return <>Code was sent successfully</>;
    else if (showFailureMessage)
      return <>Code could not be sent!</>;
    else
      return <>Check your spam folder or <span className={styles.sendCode} onClick={resendCode}>{footerText}</span></>;
  }

  return (
    <>
      <Heading size="h2" className={classNames(styles.header, "text-center")}>✉️ Check your email</Heading>
      {email && <Subtitle size="large" className={classNames(styles.lhMd, "text-center")}>{headerText1} <b>{email}</b>.</Subtitle>}
      <Subtitle size="large" className={classNames(styles.lhMd, "text-center mb-4")}>{headerText2}</Subtitle>
      <Input
        type="number"
        placeholder="Enter 6 digit code here"
        value={code}
        className={classNames(styles.input, styles.codeInput, emailErrors["email"] && emailErrors["email"].length > 0 ? styles.error : "")}
        onChange={(value, e) => changeCodeValue(value)}
      />
      <div className="d-flex justify-content-center">
        <ErrorMessage errors={emailErrors.email} name="email"><span className="material-icons-outlined" style={{"fontSize": "1.25rem"}}>error</span></ErrorMessage>
      </div>
      <Subtitle size="small" className={classNames(styles.lhMd, "text-center")}>Can’t find your code?</Subtitle>
      <Subtitle size="small" className={classNames(styles.lhMd, "text-center")}>{currentfooterText()}</Subtitle>
    </>
  );
}
