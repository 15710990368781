import React from "react";
import classNames from "classnames";

import {Dropdown as BaseDropdown} from "primereact/dropdown";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";

PrimeReact.zIndex = {
  overlay: 1050,
  modal: 1100,
};

import styles from "./dropdown.module.scss";

export default function Dropdown(props): JSX.Element {
  const {className, id, label, name, onChange, options, ...dropdownProps} = props;
  const hasNullOption = !!options.find(option => option.value === null);
  const dropdown = (
    <BaseDropdown
      {...dropdownProps}
      className={classNames(styles.dropdown, !label && className, {[styles.hasNullOption]: hasNullOption})}
      dropdownIcon="fas fa-caret-down"
      emptyMessage="Loading options..."
      name={name}
      onChange={e => onChange(e.target.value, name)}
      options={options}
      panelClassName={styles.dropdownPanel}
      panelStyle={{
        zIndex: 1050
      }}
    />
  );

  if (label === undefined) {
    return dropdown;
  }
  return (
    <div className={className}>
      <label className={styles.label} htmlFor={id}>{label}</label>
      {dropdown}
    </div>
  );
}
