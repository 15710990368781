import React, { useEffect, useState } from "react";
import styles from './EmailBox.module.scss'
import classNames from "classnames";

interface EmailInputBoxProps {
  placeholder?: string;
  className?: string;
  helpText?: string;
  onValidEmail: (email: string) => void;
  displayError?: boolean;
}

const EmailInputBox = (props: EmailInputBoxProps) => {
  const {onValidEmail, placeholder, className, helpText, displayError = true} = props
  const [val, setVal] = useState('');
  const [error, setError] = useState(false);
  const checkEmailValidity = (emailValue: string) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(emailValue.trim());
  }
  const onEmail = () => {
    if (!val) return;
    let emailValue = val;
    if(val.charAt(val.length - 1) == ',' || val.charAt(val.length - 1) == ';') {
      emailValue = val.substring(0, val.length - 1);
    }
    emailValue = emailValue.trim();
    if (checkEmailValidity(emailValue)) {
      setError(false);
      onValidEmail(emailValue);
      setVal('');
    }
    else {
      setVal(emailValue)
      setError(true);
    }
  }
  const onKey = event => {
    if (event.key === 'Enter' || event.key === ' ' || event.which === 186 || event.which === 188)
      onEmail();
  }

  return (
    <div className={classNames("form-group", styles.emailBox)}>
      <input value={val} className={classNames("solid form-control", className)} placeholder={placeholder} onKeyUp={(e) => onKey(e)} onBlur={() => onEmail()} onChange={(e) => setVal(e.target.value)} />
      {displayError && helpText &&
        <div className={styles.formText}>
            {helpText && <span>{helpText}</span>}
            {
              displayError && error && <div className={styles.invalidFeedback}>Please enter a valid email.</div>
            }
        </div>
      }
    </div>
  );
}

export default EmailInputBox;
