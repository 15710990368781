import React from "react";
import classNames from "classnames";
import styles from "./myApplication.module.scss";
import {Heading, Subtitle} from "workfinder-components";
import ApplicationModal from "components/application-modal";
import {formatDateLong} from "util/date-time";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";

const MyApplication = ({placement}) => {
  const project = placement.associated_project;
  const location = project.association && project.association.location ? project.association.location : null;
  const company = location && location.company ? location.company : null;
  const companyLogo = company.logo ? company.logo : null;
  const projectUrl = Variables.urls.candidates.candidate_preview.replace(/<project_uuid>/, project.uuid);
  const isDraft = placement.status === "draft";
  return (
    <>
      <div key={project.uuid}
        className={classNames(styles.myApplication, "text-decoration-none mb-3 card")}
        onClick={() => {
          !isDraft && window.open(projectUrl);
        }}
        style={{cursor: !isDraft ? "pointer" : null}}
      >
        <div className="card-body p-0 d-flex flex-column">
          <div className={styles.companyInfo}>
            <img className="img-fluid" src={companyLogo ?? defaultCompanyLogo}></img>
            <div className={styles.roleInfo}>
              <Heading size="h5">{project.name}</Heading>
              <Subtitle size="small">{project.association.location.company.name}</Subtitle>
            </div>
          </div>
          {
            isDraft ? (
              <ApplicationModal
                buttonText="Continue your draft"
                companyLogo={companyLogo ?? defaultCompanyLogo}
                hostName={project.association.host.full_name}
                projectName={project.name}
                companyName={company.name}
                associationUuid={project.association.uuid}
                associatedProject={project.uuid}
                primaryButton
              />
            ) : (
              <div className={styles.dateInfo}>
                <Subtitle size="small" inline={true}>You applied on {formatDateLong(placement.created_at)}</Subtitle>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default MyApplication;
