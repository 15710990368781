import React, {ReactNode} from "react";
import Tag, {TagProps} from "./Tag";
import Header, {HeaderProps} from "./Header";
import classNames from "classnames";
import styles from "./abstractCard.module.scss";

/** The idea is to move this file to workfinder-components library */

interface CardProps extends TagProps, HeaderProps {
  children: ReactNode;
  onClose?: () => void;
}

export default function Card(props: CardProps):JSX.Element {
  const {tag, title, subtitle, link, image, children, onClose} = props;
  return (
    <div className={styles.card}>
      {onClose ? <i className={classNames("ri-close-line", styles.close)} onClick={onClose}/> : null}
      <div className={styles.headerWithCard}>
        <Tag tag={tag}/>
        <Header
          title={title}
          subtitle={subtitle}
          link={link}
          image={image}
        />
      </div>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
}
