
import React, {createContext, useState} from "react";
import {Map} from "immutable";

import {
  DISABLE_REFETCH_OPTIONS,
  useCandidatePublicQuery,
  useUserPublicQuery,
  usePublicEducationsQuery,
  usePublicExperiencesQuery,
  usePublicCertificatesQuery,
  usePublicSocialLinksQuery,
} from "services/queries";
import _ from "lodash";

interface Context {
  isUserLoading: boolean;
  userData: Map;
  isCandidateLoading: boolean;
  candidateData: Map;
  educations: Array<any>;
  isEducationLoading: boolean;
  certificates: Array<any>;
  isCertificateLoading: boolean;
  experiences: Array<any>;
  isExperienceLoading: boolean;
  isSocialLinksLoading: boolean;
  socialLinks: Map
}

export const CandidatePublicProfileContext = createContext<Context>({
  isUserLoading: false,
  userData: null,
  isCandidateLoading: false,
  candidateData: null,
  educations: [],
  isEducationLoading: false,
  certificates: [],
  isCertificateLoading: false,
  experiences: [],
  isExperienceLoading: false,
  isSocialLinksLoading: false,
  socialLinks: {},

});


const CandidatePublicProfileProvider = ({userid, candidateid, children}) => {

  const [userData, setUserData] = useState({});
  const [candidateData, setCandidateData] = useState();
  const [educations, setEducations] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [socialLinks, setSocialLinks] = useState({});

  const {
    isLoading: isUserLoading,
  } = useUserPublicQuery(userid, {
    onSuccess: data => {
      setUserData(data);
    },
  });

  const {
    isLoading: isCandidateLoading,
  } = useCandidatePublicQuery(candidateid, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      setCandidateData(data);
    },
  });

  const {
    isLoading: isEducationLoading,
  } = usePublicEducationsQuery({user: userid}, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setEducations(data);
      }
      else {
        setEducations([]);
      }
    },
  });

  const {
    isLoading: isCertificateLoading,
  } = usePublicCertificatesQuery({user: userid}, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setCertificates(data);
      }
      else {
        setCertificates([]);
      }
    },
  });

  const {
    isLoading: isExperienceLoading,
  } = usePublicExperiencesQuery({user: userid}, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setExperiences(data);
      }
      else {
        setExperiences([]);
      }
    },
  });

  const {
    isLoading: isSocialLinksLoading,
  } = usePublicSocialLinksQuery({user: userid}, {
    ...DISABLE_REFETCH_OPTIONS,
    onSuccess: data => {
      if (data.length) {
        setSocialLinks(data[0]);
      }
      else {
        setSocialLinks({ });
      }
    },
  });


  return <CandidatePublicProfileContext.Provider
    value={{
      isUserLoading,
      userData,
      isCandidateLoading,
      candidateData,
      educations,
      isEducationLoading,
      certificates,
      isCertificateLoading,
      experiences,
      isExperienceLoading,
      socialLinks,
      isSocialLinksLoading,
    }}
  >
    {children}
  </CandidatePublicProfileContext.Provider>;
};
export default CandidatePublicProfileProvider;

