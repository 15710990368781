import React, {useEffect} from "react";
import classNames from "classnames";
import moment from "moment";
import {Subtitle} from "workfinder-components";
import {renderToString} from "react-dom/server";
import AddToCalendar from "components/shared/AddToCalendar";
import {formatDateDurationRange} from "util/date-time";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";
import DefaultPhoto from "/static/workfinder/images/generic-photo.png";
import styles from "./index.module.scss";

type profile = {picture: string; name: string; placementUuid?: string};

interface CardHeaderProps {
  getEventDescription?: () => JSX.Element;
  interviewDate?: {date_time: string; duration: number};
  hostFullName?: string;
  project: {name: string; uuid: string};
  className?: string;
  profile: profile;
  interviewAs?: "HOS" | "CAN";
  showAddToCalendar: boolean;
  extraButton?: JSX.Element | null;
}


export const CardHeaderCompany: React.FC<CardHeaderProps> = ({
  getEventDescription = () => <></>,
  interviewDate,
  hostFullName,
  profile,
  project,
  className,
  showAddToCalendar = true,
  interviewAs = "CAN",
  extraButton,
}) => {
  const {picture, name, placementUuid=""} = profile;
  const {name: role, uuid} = project;
  const roleUrl = Variables.urls.projects.detail.replace("<uuid:uuid>", uuid);
  const applicationUrl = Variables.urls.hosts.applicant_details.replace("<placement_id>", placementUuid);
  const [title, titleUrl, subtitle, subtitleUrl] = interviewAs === "CAN" ? [role, roleUrl, name, null] : [name, applicationUrl, role, roleUrl];
  const eventTitle = interviewAs === "CAN" ?
    `Interview with ${hostFullName || "the recruiter"} from ${profile.name}` :
    `Interview with ${name} about ${role}`;

  return (
    <div className={classNames("d-flex", styles.cardHeaderCompany, className)}>
      <img
        className={classNames(styles.cardLogo, interviewAs === "HOS" ? styles.rounded : null)}
        src={picture || (interviewAs === "CAN" ? defaultCompanyLogo : DefaultPhoto)}
        alt={interviewAs === "CAN" ? "candidate" : "company"}
      />
      <div className={styles.cardLogoText}>
        <Subtitle size="large" className={styles.roleText}>
          <a href={titleUrl} target="_blank" rel="noreferrer">
            {title}
          </a>
        </Subtitle>
        <Subtitle className={styles.subtitle} size="large">
          {subtitleUrl ? (
            <>
              For&nbsp;
              <a
                href={subtitleUrl}
                target="_blank"
                rel="noreferrer"
              >
                {subtitle}
              </a>
            </>
          ) : subtitle}
        </Subtitle>
        {interviewDate && (
          <>
            <Subtitle size="large" className={styles.interviewDates}>
              {formatDateDurationRange(interviewDate.date_time, interviewDate.duration)}
            </Subtitle>
            <div className="d-flex">
              {
                showAddToCalendar && (
                  <AddToCalendar
                    buttonText="Add to Calendar"
                    event={{
                      title: eventTitle,
                      description: renderToString(getEventDescription()),
                      start: moment(interviewDate.date_time),
                      end: moment(moment(interviewDate.date_time).add(interviewDate.duration, "minutes")),
                      url: interviewAs === "HOS" ? applicationUrl : null,
                    }}
                    className={styles.iconWithText}
                  />
                )
              }
              {extraButton}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface AbstractCardProps {
    children: React.ReactNode;
    className?: string;
    headerChildren?: React.ReactNode;
    placementChildren?: React.ReactNode;
    title?: string;
    profile: profile;
    project: {name: string; uuid: string};
    selectedDateId?: string;
    interviewDate?: {date_time: string; duration: number};
    tag?: string;
    tagClass?: string;
    hostFullName?: string;
    getEventDescription?: () => JSX.Element;
    showCompanyHeader?: boolean;
    interviewAs?: "CAN" | "HOS";
    showAddToCalendar: boolean;
    extraButton?: JSX.Element | null;
  }

const AbstractCard: React.FC<AbstractCardProps> = ({
  children,
  className,
  headerChildren,
  placementChildren,
  title,
  profile,
  project,
  selectedDateId,
  interviewDate,
  tag = "",
  tagClass = "",
  hostFullName,
  getEventDescription,
  interviewAs="CAN",
  showAddToCalendar=true,
  extraButton=null,
}) => {
  const renderBanner = () => {
    if (placementChildren) {
      return (
        <>
          <div className={classNames(styles.upcomingBanner)}>{title}</div>
          <div>{placementChildren}</div>
        </>
      );
    }
    if (headerChildren) {
      return (
        <>
          {tag ? (
            <div className={classNames(styles.banner, styles[tagClass], "mb-3")}>
              {tag}
            </div>
          ) : (
            <div className={classNames(styles.bannerUpcoming)}>
              Upcoming interview
            </div>
          )}
          <CardHeaderCompany
            profile={profile}
            project={project}
            interviewDate={interviewDate}
            getEventDescription={getEventDescription}
            hostFullName={hostFullName}
            interviewAs={interviewAs}
            showAddToCalendar={showAddToCalendar}
            extraButton={extraButton}
          />
        </>
      );
    }
    return (
      <div className={classNames(styles.banner, styles[tagClass])}>
        {tag || "Interview invitation"}
      </div>
    );
  };
  return (
    <div className={classNames(className, styles.offerCard, {selectedDate: selectedDateId})}>
      {renderBanner()}

      {children && <div className={classNames(styles.cardText, "card-body d-flex flex-column")}>{children}</div>}

      {headerChildren && <div className={classNames(styles.cardText, "mb-1")}>{headerChildren}</div>}
    </div>
  );
};

export default AbstractCard;
