import React, { useState, useEffect } from "react";
import { Button } from "workfinder-components";
import Modal from "./../Modal/Modal";
import {Content} from "components/shared/Slate";
import {mergeClassNames} from "./../../../styles/utils";
import classNames from "classnames";
import modalTheme from "./../../../styles/modals/flat.module.scss";
import baseStyles from "./ThankYouModal.module.scss";

const ThankYouModal = ({header, message, label, width = 350, isSuccess = null, onClose = () => {}, className = "", theme={}}) => {
  const styles = mergeClassNames(baseStyles, mergeClassNames(modalTheme, theme));
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isSuccess)
      setOpen(true);
  }, [isSuccess]);
  const closeModal = () => {
    setOpen(false);
    onClose();
  }
  return (
    <Modal
      id="interview-select-result-modal"
      theme={styles}
      open={open}
      onClose={closeModal}
      className={classNames(styles.modalContent, styles.mobileBottom, styles.extraShort, className)}
      style={{"width" : `${width}px`}}>
      {isSuccess &&
        <Content className={styles.content}>
          {label && <div className={styles.labelContainer}><div className={styles.label}>{label}</div></div> } 
          {header}
          {message}
          <Button kind="primary" onClick={closeModal}>Close</Button>
        </Content>
      }
    </Modal>
  );
}

export default ThankYouModal;
