import React from "react";
import PropTypes from "prop-types";
import styles from "./titledSection.module.scss";

/*
 * A section on a page as used in the Account and Projects pages.
 */
function TitledSection({title, children}) {
  return (
    <div className={styles.titledSection}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.wideContainer}>
        {children}
      </div>
    </div>
  );
}

TitledSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default TitledSection;
