import React, {useState} from "react";
import classNames from "classnames";
import {Body, Button, Heading} from "workfinder-components"
import {Content} from "components/shared/Slate";
import Modal from "components/shared/Modal/Modal";
import {mergeClassNames} from "styles/utils";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./candidatesRejectedModal.module.scss";

const styles = mergeClassNames(modalTheme, baseStyles);

export default function CandidatesRejectedModal():JSX.Element {
  const [isRejectedCandidatesOpen, setIsRejectedCandidatesOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const roleName = urlParams.get("role");

  if (roleName && window.location.hash.includes("#sd")) {
    setIsRejectedCandidatesOpen(true);
    history.pushState("", document.title, window.location.pathname);
  }
  return (
    <Modal
      id="compensation-modal"
      theme={styles}
      onClose={() => setIsRejectedCandidatesOpen(false)}
      open={isRejectedCandidatesOpen}
      className={classNames(styles.mobileBottom, styles.extraTall, styles.modalContainer)}
    >
      <Content className="pt-0 mt-4">
        <Heading size="h5" className="text-center">
          Thank you!
        </Heading>
        <Body size="medium" className="text-center">
          We have successfully declined all remaining candidates who applied to your filled role {roleName} so that they can move on.
        </Body>
        <Button
          className="mx-auto mb-0"
          size="small"
          onClick={() => setIsRejectedCandidatesOpen(false)}
        >
          Close
        </Button>
      </Content>
    </Modal>
  );
}
