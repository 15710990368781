import React, {useContext, useState} from "react";
import {Map} from "immutable";
import ClickAwayListener from "react-click-away-listener";
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";

import {useMessagesQuery} from "services/queries";
import {overlaps} from "util/set";
import {partition} from "util/array";

import Alert from "./Alert";
import styles from "./alertsProvider.module.scss";
import classNames from "classnames";

import CustomIcon from "./notification-custom.png";

type AlertType = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";

interface Alert {
  /**
   * Whether the alert is still active. If false, the alert is still being
   * shown to the user, but is undergoing a "close" animation and will
   * disappear shortly.
   */
  active: boolean;
  /** The message to display for the alert.  */
  message: string;
  /** The type of alert. */
  type: AlertType;
}

interface AddAlertFunc {
  (key: string, type: AlertType, message: string, seconds?: number, title?: string): void;
}

interface RemoveAlertFunc {
  (key: string): void;
}

interface FetchStatus {
  /** Whether the message has beem fetched successfully. */
  isSuccess: boolean;
  /** Whether the messages are still being fetched. */
  isLoading: boolean;
}

interface IAlertsContext extends FetchStatus {
  /** Add an alert to the alerts tray. */
  addAlert: AddAlertFunc;
  /** Remove the alery with the given key from the alerts tray. If it does not exists, the call is ignored. */
  removeAlert: RemoveAlertFunc;
  /** The alerts that are currently being displayed. */
  alerts: Record<string, Alert>;
  /**
   * Messages sent from the server that have not been automatically displayed
   * because they matched a tag passed in `manualTags`. Any messages that are
   * not handled by the consumer will be lost without being shown to the user.
   */
  manualMessages: Message[];
}

export const AlertsContext = React.createContext<IAlertsContext>({
  addAlert: () => {},
  removeAlert: () => {},
  alerts: {},
  manualMessages: [],
  isSuccess: true,
  isLoading: false,
});

const TAG_TO_TYPE = {
  debug: "dark",
  info: "info",
  success: "success",
  warning: "warning",
  error: "danger",
};

interface Props {
  /** The children to render inside the provider. */
  children: React.ReactNode;
  /**
   * Tags to be handled manually, e.g. when a custom message or modal is being
   * used. Tags are checked in the `extra_tags` field on the incoming messages.
   * The`tag` field is not checked.
   */
  manualTags: string[];
}

function AlertsProvider({children, manualTags=[]}: Props): JSX.Element {
  const [alerts, setAlerts] = useState(Map());
  const data= [];
  const isLoading = false;
  const isSuccess = true;
  // const {data, isLoading, isSuccess} = useMessagesQuery({
  //   onSuccess(messages: Message[]) {
  //     const autoMessages = messages.filter(message => !overlaps(message.extra_tags, manualTags));
  //     for (const i in autoMessages) {
  //       const message = messages[i];
  //       addAlert(
  //         `server-message-${i}`,
  //         TAG_TO_TYPE[message.level_tag] ?? "info",
  //         message.message,
  //         5000,
  //       );
  //     }
  //   },
  // });
  const manualMessages = (data ?? []).filter(message => overlaps(message.extra_tags, manualTags));

  function addAlert(key, type, message, seconds, title) {
    // Close "success" messages after 3 seconds unless `seconds` is explicitly nullified.
    if (seconds === undefined && type === "success") {
      seconds = 3000;
    }
    setAlerts(alerts => alerts.set(key, {type, message, title, active: true}));
    if (seconds) {
      setTimeout(() => removeAlert(key), seconds);
    }
  }

  function removeAlert(key) {
    // Trigger the remove animation but not the actual removal yet (and protect
    // it from an timed callback setting a key on an already removed alert).
    setAlerts(alerts => alerts.has(key) ? alerts.setIn([key, "active"], false) : alerts);
  }

  function hardRemoveAlert(key) {
    // Fully remove the alert without a transition.
    setAlerts(alerts => alerts.remove(key));
  }

  const contextValue = {
    addAlert,
    removeAlert,
    alerts,
    manualMessages,
    isLoading,
    isSuccess,
  };

  const extraStyle = type => {
    switch (type) {
    case "custom":
      return {
        icon: <img src={CustomIcon}/>,
        className: styles.custom,
      };
    default:
      return {
        icon: null,
        className: null,
      };
    }
  };

  return (
    <AlertsContext.Provider value={contextValue}>
      {children}
      {
        !alerts.isEmpty() &&
        <div className={styles.alertsTray}>
          {
            alerts.map(({title="", message, type, active}, key) =>
              <CSSTransition key={key} appear in={active}
                timeout={200} classNames={styles} onExited={() => hardRemoveAlert(key)}
              >
                <ClickAwayListener onClickAway={type === "success" ? () => removeAlert(key) : () => {}} className={classNames(styles.clickAwayListener, extraStyle(type).className)}>
                  <Alert onClose={() => removeAlert(key)} type={type}>
                    {extraStyle(type).icon}
                    <div className={styles.textContainer}>
                      {title && <h3>{title}</h3>}
                      {message}
                    </div>
                  </Alert>
                </ClickAwayListener>
              </CSSTransition>,
            ).valueSeq().toArray()
          }
        </div>
      }
    </AlertsContext.Provider>
  );
}

AlertsProvider.propTypes = {
  children: PropTypes.node,
};

export function useAlerts(): [AddAlertFunc, RemoveAlertFunc] {
  const context = useContext(AlertsContext);
  return [context.addAlert, context.removeAlert];
}

interface UseMessageReturn extends FetchStatus {
  /** The message matching the tag, if any. */
  message: Message | undefined;
}

interface UseMessagesReturn extends FetchStatus {
  /** The messages matching the tag, if any. */
  messages: Message[];
}

export function useMessage(tag: string): UseMessageReturn {
  const context = useContext(AlertsContext);
  return {
    message: context.manualMessages.find(message => message.extra_tags.includes(tag)),
    isLoading: context.isLoading,
    isSuccess: context.isSuccess,
  };
}

export function useMessages(tag: string): UseMessagesReturn {
  const context = useContext(AlertsContext);
  return {
    messages: context.manualMessages.filter(message => message.extra_tags.includes(tag)),
    isLoading: context.isLoading,
    isSuccess: context.isSuccess,
  };
}

/**
 * Messages that could be sent from multiple places, e.g. a "contact us" when
 * an error occurs. This allows us to update them in a central place.
 */
const ifPersists = "If this problem persists, please contact us at support@superpower.tech.";
export const messages = {
  ifPersists,
  infoUpdateSuccess: "Successfully updated information!",
  inviteCreateSuccess: "Invites have been sent!",
  invitesCreateSuccess: "Invites have been sent!",
  // This requires a context variable to guarantee the URL is correct.
  seeLiveProjects: <>Please have a look at <a key="link" className="green" href={Variables.urls.projects.live}>our live projects</a>!</>,

  // Errors
  updateError: "An error occurred updating the information. " + ifPersists,
  loadError: "An error occurred loading data. " + ifPersists,
  applicantLoadError: "An error occurred loading the applicant's information. " + ifPersists,
  infoLoadError: "An error occurred loading your information. " + ifPersists,
  inviteCreateError: "An error occurred while creating invites. " + ifPersists,
  projectLoadError: "An error occurred loading the project details. " + ifPersists,
  applicationError: "An error occurred submitting the application. " + ifPersists,
  projectCreateError: "An error occurred creating the project. " + ifPersists,
  shareCreateError: "An error occurred creating a shared application. " + ifPersists,
  recommendationUpdateError: "Recommendation update gone wrong, Try again!" + ifPersists,
};

export default AlertsProvider;
