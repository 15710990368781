import "regenerator-runtime/runtime";
import _ from "lodash";
import apiFetch from "util/apiFetch";


interface GetProjectTemplateQueryParams {
  name: string;
}

interface GetChatMessageQueryParams {
  correspondent?: uuid;
}

interface Page<T> {
  /** The total number of results. */
  count: number;
  /** The URL for the next page. */
  next: string | null;
  /** The URL for the previous page. */
  previous: string | null;
  /** The result page. */
  results: T[];
}

export default class api {

  // Associations

  static getAssociation(uuid: string) {
    const url = Variables.urls["api-v3"].associations_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getAssociationForHost(hostUUID: string) {
    const url = Variables.urls["api-v3"].associations_list;
    return apiFetch(url, {queryParams: {host__uuid: hostUUID}});
  }

  static updateAssociation(uuid: string, data) {
    const url = Variables.urls["api-v3"].associations_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  // Candidates

  static updateCandidate(uuid: string, data) {
    const url = Variables.urls["api-v3"].candidates_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getCandidate(uuid: string) {
    const url = Variables.urls["api-v3"].candidates_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getCandidateScore() {
    const url = Variables.urls["api-v3"].my_candidate_score;
    return apiFetch(url);
  }

  static getCompanyScore(queryParams) {
    const url = Variables.urls["api-v3"].my_company_score;
    return apiFetch(url, {queryParams});
  }
  static async getMyCandidate(queryParams) {
    const url = Variables.urls["api-v3"].my_candidate;
    return apiFetch(url, {queryParams});
  }

  // Chat

  static getChatMessages(queryParams: GetChatMessageQueryParams) {
    const url = Variables.urls["api-v3"].chat.messages_list;
    return apiFetch(url, {queryParams});
  }

  static getChatDialogs() {
    const url = Variables.urls["api-v3"].chat.dialogs_list;
    return apiFetch(url);
  }

  static getChatDialog(correspondent_id: string) {
    const url = Variables.urls["api-v3"].chat.dialogs_detail.replace("<pk>", correspondent_id);
    return apiFetch(url, {allow404: true});
  }

  // Comments

  static async getComments(queryParams) {
    const url = Variables.urls["api-v3"].comments_list;
    return apiFetch(url, {queryParams});
  }

  static async createComment(data) {
    const url = Variables.urls["api-v3"].comments_list;
    return apiFetch(url, {data});
  }

  static async collectCollaboratorFeedback(data) {
    const url = Variables.urls["api-v3"].collect_collaborator_feedback;
    return apiFetch(url, {data});
  }

  static async interviewDashboard() {
    const url = Variables.urls["api-v3"].interview_dashboard_list;
    return apiFetch(url);
  }
  // Companies

  static getCompany(uuid: string) {
    const url = Variables.urls["api-v3"].companies_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getCompanies(queryParams) {
    const url = Variables.urls["api-v3"].companies_detail.replace();
    return apiFetch(url, {queryParams});
  }

  static updateCompany(uuid: string, data) {
    const url = Variables.urls["api-v3"].companies_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getCompanyCount(queryParams) {
    const url = Variables.urls["api-v3"].companies_count;
    return apiFetch(url, {queryParams});
  }

  static getBenefits() {
    const url = Variables.urls["api-v3"].benefits_list;
    return apiFetch(url).then(response => response.results);
  }
  static getValues() {
    const url = Variables.urls["api-v3"].values_list;
    return apiFetch(url).then(response => response.results);
  }

  static getValuesSearch(queryParams) {
    const url = Variables.urls["api-v3"].company_values_search;
    return apiFetch(url, {queryParams});
  }

  static getBenefitsSearch(queryParams) {
    const url = Variables.urls["api-v3"].company_benefits_search;
    return apiFetch(url, {queryParams});
  }

  static updatePrimaryCompany(pk: string, data) {
    const url = Variables.urls["api-v3"].primary_companies_detail.replace("<pk>", pk);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getPrimaryCompany(pk: string) {
    const url = Variables.urls["api-v3"].primary_companies_detail.replace("<pk>", pk);
    return apiFetch(url);
  }

  static getCompanySocialLinks(companyPk: string) {
    const url = Variables.urls["api-v3"].company_sociallinks_list;
    return apiFetch(url, {queryParams: {company_pk: companyPk}});
  }

  static updateCompanySocialLinks(companyPk: string, {data}) {
    const url = Variables.urls["api-v3"].company_sociallinks_list;
    return apiFetch(url, {queryParams: {company_pk: companyPk}, method: "POST", data});
  }

  static getCompanyOptions() {
    const url = Variables.urls["api-v3"].company_options;
    return apiFetch(url);
  }

  // Courses
  static getFeaturedCourses() {
    const url = Variables.urls["api-v3"].featured_courses;
    return apiFetch(url);
  }

  // Demos
  static createDemo(data) {
    const url = Variables.urls["api-v3"].create_demo;
    return apiFetch(url, {data});
  }

  static getDemoOptions() {
    const url = Variables.urls["api-v3"].demo_options;
    return apiFetch(url);
  }

  // Education

  static getInstitution(uuid: string) {
    const url = Variables.urls["api-v3"].institutions_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getInstitutions(queryParams) {
    const url = Variables.urls["api-v3"].institutions_list;
    return apiFetch(url, {queryParams});
  }

  static getSubjects(queryParams) {
    const url = Variables.urls["api-v3"].subjects_list;
    return apiFetch(url, {queryParams});
  }

  static getYearsOfStudy() {
    const url = Variables.urls["api-v3"].years_of_study_list;
    return apiFetch(url);
  }


  // Feedbacks

  static createInterviewFeedback(data) {
    const url = Variables.urls["api-v3"].interview_feedbacks_list;
    return apiFetch(url, {data});
  }

  static updateInterviewFeedback(uuid: string, data) {
    const url = Variables.urls["api-v3"].interview_feedbacks_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getCompletedInterviews() {
    const url = Variables.urls["api-v3"].interviews_completed_list;
    return apiFetch(url);
  }

  // Hosts

  static getHost(uuid: string) {
    const url = Variables.urls["api-v3"].hosts_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static updateHost(uuid: string, data) {
    const url = Variables.urls["api-v3"].hosts_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static registerHost(data) {
    const url = Variables.urls["api-v3"].registration_host;
    return apiFetch(url, {data});
  }

  static getProjectPreferences() {
    const url = Variables.urls["api-v3"].project_preferences;
    return apiFetch(url, {allow404: true})
      .then(prefs => {
        if (prefs === null) {
          return null;
        }
        // Filter out falsy keys.
        return Object.fromEntries(
          Object.entries(prefs).filter((([__, v]) => _.isEmpty(v) || !!v)),
        );
      });
  }

  static getHostsForCompany(companyId) {
    const url = Variables.urls["api-v3"].company_hosts_list;
    return apiFetch(url, {queryParams: {company_id: companyId}});
  }

  static hasMessagingFeature(hostUserUuid) {
    const url = Variables.urls.hosts.has_messaging_feature.replace("<host_user_uuid>", hostUserUuid);
    return apiFetch(url);
  }

  // Industries

  static async getIndustries(queryParams) {
    const url = Variables.urls["api-v3"].industry_list;
    return apiFetch(url, {queryParams});
  }

  static async getActiveIndustries() { // sectors of projects from live opportunities page
    const url = Variables.urls["api-v3"].active_industries;
    return apiFetch(url);
  }

  // Locations

  static getLocation(uuid: string) {
    const url = Variables.urls["api-v3"].locations_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static updateLocation(uuid: string, data) {
    const url = Variables.urls["api-v3"].locations_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }
  static createLocation(data) {
    const url = Variables.urls["api-v3"].locations_list;
    return apiFetch(url, {method: "POST", data});
  }

  // Masterclasses
  static getFeaturedMasterClasses() {
    const url = Variables.urls["api-v3"].featured_masterclasses;
    return apiFetch(url);
  }

  // Onet
  static getOnetOccupations() {
    const url = Variables.urls["api-v3"].onet_occupation_list;
    return apiFetch(url);
  }

  // Interviews

  static getInterviews(queryParams) {
    const url = Variables.urls["api-v3"].interviews_list;
    return apiFetch(url, {queryParams});
  }

  static getInterview(uuid: string, queryParams) {
    const url = Variables.urls["api-v3"].interviews_detail.replace("<uuid>", uuid);
    return apiFetch(url, {queryParams});
  }

  static createInterview(data) {
    const url = Variables.urls["api-v3"].interviews_list;
    return apiFetch(url, {data});
  }

  static updateInterview(uuid: string, data) {
    const url = Variables.urls["api-v3"].interviews_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getInterviewDates(interviewId: number, queryParams) {
    const url = `${Variables.urls["api-v3"].interview_date_list}?interview_id=${interviewId}`;
    return apiFetch(url, {queryParams});
  }

  static getCandidateInterviewDates(queryParams) {
    const url = Variables.urls["api-v3"].candidate_interviews_date_list;
    return apiFetch(url, {queryParams});
  }

  static updateInterviewDate(id: number, data) {
    const url = Variables.urls["api-v3"].interview_date_detail.replace("<id>", id);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createInterviewDate(data) {
    const url = Variables.urls["api-v3"].interview_date_list;
    return apiFetch(url, {data});
  }

  // Placements

  static getCandidatePlacement(uuid: string, queryParams={}) {
    const url = Variables.urls["api-v3"].candidate_placements_detail.replace("<uuid>", uuid);
    return apiFetch(url, {queryParams});
  }

  static getHostPlacement(uuid: string, queryParams={}) {
    const url = Variables.urls["api-v3"].host_placements_detail.replace("<uuid>", uuid);
    return apiFetch(url, {queryParams});
  }

  static getPlacements(queryParams) {
    const url = Variables.urls["api-v3"].placements_list;
    return apiFetch(url, {queryParams});
  }

  static getCandidatePlacements(queryParams) {
    const url = Variables.urls["api-v3"].candidate_placements_list;
    return apiFetch(url, {queryParams});
  }

  static getHostPlacements(queryParams) {
    const url = Variables.urls["api-v3"].host_placements_list;
    return apiFetch(url, {queryParams});
  }

  static getPlacementOffers(queryParams) {
    const url = Variables.urls["api-v3"].candidate_placement_offers;
    return apiFetch(url, {queryParams});
  }

  static getExpandedPlacement(uuid, queryParams) {
    /** To be used only for hosts */
    const url = Variables.urls["api-v3"].placements_dashboard_detail.replace("<uuid>", uuid);
    return apiFetch(url, {queryParams});
  }

  static getExpandedPlacements(queryParams) {
    /** To be used only for hosts */
    const url = Variables.urls["api-v3"].placements_dashboard_list;
    return apiFetch(url, {queryParams});
  }

  static getPlacementOfferCount(queryParams) {
    const url = Variables.urls["api-v3"].offers_count;
    return apiFetch(url, {queryParams});
  }

  static updateCandidatePlacement(uuid: string, data) {
    const url = Variables.urls["api-v3"].candidate_placements_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static updateHostPlacement(uuid: string, data) {
    const url = Variables.urls["api-v3"].host_placements_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static updatePlacementOffer(uuid: string, data) {
    const url = Variables.urls["api-v3"].candidate_placement_offer_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createPlacement(data) {
    /** Only candidates can create placements */
    const url = Variables.urls["api-v3"].candidate_placements_list;
    return apiFetch(url, {data});
  }

  static createPlacementOffer(data) {
    const url = Variables.urls["api-v3"].placement_offer;
    return apiFetch(url, {data});
  }

  static createPlacementExtensionDecline(data) {
    const url = Variables.urls["api-v3"].placements_extension_decline_list;
    return apiFetch(url, {data});
  }

  static getCoverLetters(queryParams) {
    const url = Variables.urls["api-v3"].coverletters_list;
    return apiFetch(url, {queryParams});
  }

  static getPlacementType(uuid: string) {
    const url = Variables.urls["api-v3"].types_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getPlacementTypes() {
    const url = Variables.urls["api-v3"].types_list;
    return apiFetch(url);
  }

  static getPlacementDurations() {
    const url = Variables.urls["api-v3"].durations_list;
    return apiFetch(url);
  }

  static getPlacementDurationType(uuid: string) {
    const url = Variables.urls["api-v3"].durations_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getPlacementSkills(queryParams) {
    const url = Variables.urls["api-v3"].skills_list;
    return apiFetch(url, {queryParams});
  }

  static getWEFSkills(queryParams) {
    const url = Variables.urls["api-v3"].wef_skills_list;
    return apiFetch(url, {queryParams});
  }

  static getDeclinedReasons() {
    const url = Variables.urls["api-v3"].declined_reasons;
    return apiFetch(url);
  }

  static getMyPlacements(queryParams) {
    const url = Variables.urls["api-v3"].my_placements_list;
    return apiFetch(url, {method: "GET", queryParams});
  }

  static getCertificates(queryParams) {
    const url = Variables.urls["api-v3"].completion_certificate_list;
    return apiFetch(url, {method: "GET", queryParams});
  }

  static getPlacementOfferDeclinedReasons(queryParams) {
    const url = Variables.urls["api-v3"].placement_offer_declined_reasons;
    return apiFetch(url, {method: "GET", queryParams});
  }

  // Projects

  static getProject(uuid: string) {
    const url = Variables.urls["api-v3"].projects_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getProjects(queryParams) {
    const url = Variables.urls["api-v3"].projects_list;
    return apiFetch(url, {queryParams});
  }

  static getMyProjects(queryParams) {
    const url = Variables.urls["api-v3"].projects_my_projects;
    return apiFetch(url, {queryParams, cache: "default"});
  }

  static getProjectCount(queryParams) {
    const url = Variables.urls["api-v3"].my_project_count;
    return apiFetch(url, {queryParams});
  }

  static updateProject(uuid: string, data) {
    const url = Variables.urls["api-v3"].projects_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static cloneProject(queryParams) {
    const url = Variables.urls["api-v3"].clone_project;
    return apiFetch(url, {queryParams});
  }

  static createProjects(data) {
    const url = Variables.urls["api-v3"].projects_list;
    return apiFetch(url, {data});
  }


  static createProjectPrescreenQuestions(data) {
    const url = Variables.urls["api-v3"].project_prescreen_questions;
    return apiFetch(url, {data});
  }

  static getProjectPrescreenQuestions(queryParams) {
    const url = Variables.urls["api-v3"].project_prescreen_questions;
    return apiFetch(url, {queryParams});
  }

  static savePreScreenAnswers(data) {
    const url = Variables.urls["api-v3"].placement_screen_answer;
    return apiFetch(url, {data});
  }

  static getPreScreenAnswers(queryParams) {
    const url = Variables.urls["api-v3"].placement_screen_answer;
    return apiFetch(url, {queryParams});
  }

  static getProjectTemplate(uuid: string): Promise<ProjectTemplate> {
    const url = Variables.urls["api-v3"].project_templates_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static getProjectTemplates(queryParams: GetProjectTemplateQueryParams): Promise<Page<ProjectTemplate>> {
    const url = Variables.urls["api-v3"].project_templates_list;
    return apiFetch(url, {queryParams});
  }

  static getProjectTypes() {
    const url = Variables.urls["api-v3"].project_types;
    return apiFetch(url);
  }

  static getRequirementTypes() {
    const url = Variables.urls["api-v3"].requirement_types;
    return apiFetch(url);
  }

  static getLocationTypes() {
    const url = Variables.urls["api-v3"].location_types;
    return apiFetch(url);
  }

  static getCandidateActivities(queryParams) {
    const url = Variables.urls["api-v3"].candidate_activities_list;
    return apiFetch(url, {queryParams});
  }

  static getEducationRequirements() {
    const url = Variables.urls["api-v3"].education_requirements;
    return apiFetch(url);
  }

  static getEmploymentTypes() {
    const url = Variables.urls["api-v3"].employment_types;
    return apiFetch(url);
  }

  static getRoleTypes() {
    const url = Variables.urls["api-v3"].role_types;
    return apiFetch(url);
  }

  static getSkillsAcquired() {
    const url = Variables.urls["api-v3"].skills_acquired;
    return apiFetch(url);
  }

  static generateTags(text: string) {
    const url = Variables.urls["api-v3"].tags_generate;
    return apiFetch(url, {data: {text}});
  }

  static tagsTypeahead(queryParams) {
    const url = Variables.urls["api-v3"].tags_typeahead;
    return apiFetch(url, {queryParams});
  }

  static getSkillsBuilderSkills(queryParams) {
    const url = Variables.urls["api-v3"].skills_builder_skills_list;
    return apiFetch(url, {queryParams});
  }

  static getTechSkills(queryParams) {
    const url = Variables.urls["api-v3"].tech_skills_list;
    return apiFetch(url, {queryParams});
  }

  static getTechSkillSearch(queryParams) {
    const url = Variables.urls["api-v3"].tech_skills_search;
    return apiFetch(url, {queryParams});
  }
  // Recommendations

  static getProjectRecommendations() {
    const url = Variables.urls["api-v3"].recommendations_project_list;
    return apiFetch(url);
  }

  static getCourseRecommendations() {
    const url = Variables.urls["api-v3"].recommendations_course_list;
    return apiFetch(url);
  }

  static getMasterClassRecommendations() {
    const url = Variables.urls["api-v3"].recommendations_masterclass_list;
    return apiFetch(url);
  }

  static updateRecommendation(uuid: string, data) {
    const url = Variables.urls["api-v3"].recommendations_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createProjectFeedback(data) {
    const url = Variables.urls["api-v3"].project_feedbacks_list;
    return apiFetch(url, {data});
  }
  // Referrals

  static createReferralForUser(data) {
    const url = Variables.urls["api-v3"].referral_list;
    return apiFetch(url, {data});
  }

  static createPlacementReferral(data) {
    const url = Variables.urls["api-v3"].placement_referrals;
    return apiFetch(url, {data});
  }

  static updateUserDetectedFields(data) {
    const url = Variables.urls["api-v3"].user_detected_fields;
    return apiFetch(url, {data});
  }

  // Sharing

  static createInvites(data) {
    const url = Variables.urls["api-v3"].invites_list;
    return apiFetch(url, {data});
  }
  static getInvites() {
    const url = Variables.urls["api-v3"].user_invitee_list;
    return apiFetch(url);
  }


  static getShare(uuid: string) {
    const url = Variables.urls["api-v3"].share_detail.replace("<uuid>", uuid);
    return apiFetch(url);
  }

  static deleteShare(uuid, email) {
    const url = Variables.urls["api-v3"].share_detail.replace("<uuid>", uuid);
    const method = "DELETE";
    const data = {email};
    return apiFetch(url, {method, data});
  }

  static createShare(data) {
    const url = Variables.urls["api-v3"].share_list;
    return apiFetch(url, {data});
  }

  static updateShare(uuid: string, data) {
    const url = Variables.urls["api-v3"].share_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createHostInvitation(data) {
    const url = Variables.urls["api-v3"].create_host_invitation;
    return apiFetch(url, {method: "POST", data: {invitations: data}});
  }

  // Socialdata

  static getLinkedInData(queryParams) {
    const url = Variables.urls["api-v3"].linkedin_userdata_list.replace("<pk>/", "");
    return apiFetch(url, {queryParams});
  }

  // Tracking

  static createEvent(data) {
    const url = Variables.urls["api-v3"].events_list;
    return apiFetch(url, {data});
  }

  // Users

  static getSelf() {
    const url = Variables.urls["api-v3"].me;
    return apiFetch(url);
  }

  static getSocialLinks() {
    const url = Variables.urls["api-v3"].sociallinks_list;
    return apiFetch(url);
  }

  static updateSocialLinks({data}) {
    const url = Variables.urls["api-v3"].sociallinks_list;
    return apiFetch(url, {method: "POST", data});
  }

  static getUser(userUUID: string) {
    const url = Variables.urls["api-v3"].users_detail.replace("<uuid>", userUUID);
    return apiFetch(url);
  }


  static updateUser(data) {
    const url = Variables.urls["api-v3"].me;
    return apiFetch(url, {method: "PATCH", data});
  }

  static deleteUser(data) {
    const url = Variables.urls["api-v3"].me;
    return apiFetch(url, {method: "DELETE", data});
  }

  static createFileUpload(data) {
    const url = Variables.urls["api-v3"].document_upload;
    return apiFetch(url, {data});
  }

  static getPublicFileUploads(queryParams) {
    const url = Variables.urls["api-v3"].file_uploads_list;
    return apiFetch(url, {queryParams});
  }

  static scrapUserCVData(data) {
    const url = Variables.urls["api-v3"].scrap_user_cv;
    return apiFetch(url, {data});
  }

  static createDocumentRequestAsCandidate(data) {
    const url = Variables.urls["api-v3"].candidate_document_request_list;
    return apiFetch(url, {data});
  }

  static createDocumentRequestAsHost(data) {
    const url = Variables.urls["api-v3"].host_document_request_list;
    return apiFetch(url, {data});
  }

  static updateDocumentRequestAsCandidate(pk: number, data) {
    const url = Variables.urls["api-v3"].candidate_document_request_detail.replace("<pk>", pk);
    return apiFetch(url, {method: "PATCH", data});
  }

  static updateDocumentRequestAsHost(pk: number, data) {
    const url = Variables.urls["api-v3"].host_document_request_detail.replace("<pk>", pk);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getDocumentRequestAsCandidate(placementId) {
    const url = Variables.urls["api-v3"].candidate_document_request_list + (placementId || "");
    return apiFetch(url);
  }

  static getDocumentRequestAsHost(placementId) {
    const url = Variables.urls["api-v3"].host_document_request_list + (placementId || "");
    return apiFetch(url);
  }

  static getProjectSalaryRequestCount(queryParams) {
    const url = Variables.urls["api-v3"].project_salary_request_count;
    return apiFetch(url, {queryParams});
  }

  static getSalaryRequestStatus(queryParams) {
    const url = Variables.urls["api-v3"].candidate_document_request_list;
    return apiFetch(url, {queryParams});
  }

  static getSkillLevels(queryParams) {
    const url = Variables.urls["api-v3"].candidate_skill_levels_list;
    return apiFetch(url, {queryParams});
  }

  static getExperiences() {
    const url = Variables.urls["api-v3"].user_experiences_list;
    return apiFetch(url);
  }

  static deleteExperience(uuid: string) {
    const url = Variables.urls["api-v3"].user_experiences_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "DELETE"});
  }

  static updateExperience(uuid: string, data) {
    const url = Variables.urls["api-v3"].user_experiences_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createExperience({data}) {
    const url = Variables.urls["api-v3"].user_experiences_list;
    return apiFetch(url, {data});
  }

  static getEducations() {
    const url = Variables.urls["api-v3"].user_educations_list;
    return apiFetch(url);
  }

  static deleteEducation(uuid: string) {
    const url = Variables.urls["api-v3"].user_educations_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "DELETE"});
  }

  static updateEducation(uuid: string, data) {
    const url = Variables.urls["api-v3"].user_educations_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createEducation({data}) {
    const url = Variables.urls["api-v3"].user_educations_list;
    return apiFetch(url, {data});
  }

  static getUserPreferences() {
    const url = Variables.urls["api-v3"].user_preferred_fields;
    return apiFetch(url);
  }

  static updateUserPreferences(data) {
    const url = Variables.urls["api-v3"].user_preferred_fields;
    return apiFetch(url, {method: "POST", data});
  }

  static getUserCertificates() {
    const url = Variables.urls["api-v3"].user_certificates_list;
    return apiFetch(url);
  }

  static deleteUserCertificate(uuid: string) {
    const url = Variables.urls["api-v3"].user_certificates_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "DELETE"});
  }

  static updateUserCertificate(uuid: string, data) {
    const url = Variables.urls["api-v3"].user_certificates_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static createUserCertificate({data}) {
    const url = Variables.urls["api-v3"].user_certificates_list;
    return apiFetch(url, {data});
  }

  static updateDocumentRequestSkillLevel(data) {
    const url = Variables.urls["api-v3"].documents_request_skills;
    return apiFetch(url, {data});
  }

  static getCandidateTechSkill(queryParams) {
    const url = Variables.urls["api-v3"].candidate_tech_skills_list;
    return apiFetch(url, {queryParams});
  }

  static createCandidateTechSkill({data}) {
    const url = Variables.urls["api-v3"].candidate_tech_skills_list;
    return apiFetch(url, {data});
  }

  static deleteCandidateTechSkill(id: number) {
    const url = Variables.urls["api-v3"].candidate_tech_skills_detail.replace("<pk>", id.toString());
    return apiFetch(url, {method: "DELETE"});
  }

  static getCandidateLanguage() {
    const url = Variables.urls["api-v3"].candidate_languages_list;
    return apiFetch(url);
  }

  static createCandidateLanguages({data}) {
    const url = Variables.urls["api-v3"].candidate_languages_list;
    return apiFetch(url, {data});
  }

  static deleteCandidateLanguages(id: number) {
    const url = Variables.urls["api-v3"].candidate_languages_detail.replace("<pk>", id.toString());
    return apiFetch(url, {method: "DELETE"});
  }

  static getCandidateSkillsbuilderSkills() {
    const url = Variables.urls["api-v3"].candidate_skillsbuilder_skill_list;
    return apiFetch(url);
  }

  static createCandidateSkillsbuilderSkills({data}) {
    const url = Variables.urls["api-v3"].candidate_skillsbuilder_skill_list;
    return apiFetch(url, {data});
  }

  static getSkillsBuilderSkillLevels(queryParams):any {
    const url = Variables.urls["api-v3"].skills_builder_skill_levels_list;
    return apiFetch(url, {queryParams});
  }

  static checkEmailExists(email) {
    const url = Variables.urls["api-v3"].check_email;
    return apiFetch(url, {method: "POST", data: {email: email}});
  }

  static createUserReferralSlug() {
    const url = Variables.urls["api-v3"].user_referral_slug;
    return apiFetch(url, {method: "POST"});
  }

  static createReferralInvitation(data) {
    const url = Variables.urls["api-v3"].create_referral_invitation;
    return apiFetch(url, {method: "POST", data: {invitations: data}});
  }

  static updateInterest(interest) {
    const url = Variables.urls["api-v3"].update_interest;
    return apiFetch(url, {method: "POST", data: {interest}});
  }

  static submitSkillsReferralRequest(emails) {
    const url = Variables.urls["api-v3"].request_skill_referral_list;
    return apiFetch(url, {method: "POST", data: {emails}});
  }

  static getSkillsReferralRequest(queryParams={}) {
    const url = Variables.urls["api-v3"].request_skill_referral_list;
    return apiFetch(url, {queryParams});
  }

  static getSkillReferralAssociationChoices() {
    const url = Variables.urls["api-v3"].skill_referral_association_choices;
    return apiFetch(url);
  }

  //update-request-skill-referral
  static updateSkillsReferralRequest(uuid, data) {
    const url = Variables.urls["api-v3"].request_skill_referral_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

  static getRequestedTechSkillReferrals(queryParams={}) {
    const url = Variables.urls["api-v3"].requested_tech_skill_referrals_list;
    return apiFetch(url, {queryParams});
  }

  // Reviews

  static getReview(uuid: string) {
    const url = `/v3/reviews/${uuid}`;
    return apiFetch(url);
  }

  static updateReview(uuid: string, data) {
    const url = `/v3/reviews/${uuid}`;
    return apiFetch(url, {method: "PATCH", data});
  }

  static getReasons() {
    const url = "/v3/reviews/reasons/";
    return apiFetch(url);
  }

  // Shortlists

  static getShortlists() {
    const url = Variables.urls["api-v3"].shortlists_list;
    return apiFetch(url);
  }

  static createShortlist(data) {
    const url = Variables.urls["api-v3"].shortlists_list;
    return apiFetch(url, {data});
  }

  // Voting

  static getVotingItems() {
    const url = Variables.urls["api-v3"].voting_items_list;
    return apiFetch(url);
  }

  static addVote(data) {
    const url = Variables.urls["api-v3"].vote_list;
    return apiFetch(url, {data});
  }

  static updateVote(id: number, {data}) {
    const url = Variables.urls["api-v3"].vote.replace("<id>", id.toString());
    return apiFetch(url, {data});
  }

  static getVoted(queryParams) {
    const url = Variables.urls["api-v3"].vote_list;
    return apiFetch(url, {queryParams});
  }

  static geMyVote(queryParams) {
    const url = Variables.urls["api-v3"].vote_my_vote;
    return apiFetch(url, {queryParams});
  }

  static getVoteCount(queryParams) {
    const url = Variables.urls["api-v3"].vote_count;
    return apiFetch(url, {queryParams});
  }

  // Workfinder

  static getEthnicities() {
    const url = Variables.urls["api-v3"].ethnicities_list;
    return apiFetch(url);
  }

  static getPrimaryEthnicities() {
    const url = Variables.urls["api-v3"].primary_ethnicities_list;
    return apiFetch(url);
  }

  static getGenders() {
    const url = Variables.urls["api-v3"].genders;
    return apiFetch(url);
  }

  static getDisabilities() {
    const url = Variables.urls["api-v3"].disabilities_list;
    return apiFetch(url);
  }

  static getPronouns() {
    const url = Variables.urls["api-v3"].pronouns;
    return apiFetch(url);
  }

  static getLanguages() {
    const url = Variables.urls["api-v3"].languages_list;
    return apiFetch(url);
  }

  static getCountries() {
    const url = Variables.urls["api-v3"].countries_list;
    return apiFetch(url);
  }

  static getMessages(): Promise<Message[]> {
    const url = Variables.urls["api-v3"].messages;
    return apiFetch(url);
  }

  // Activity

  static getActivities() {
    const url = Variables.urls["api-v3"].activities_list;
    return apiFetch(url);
  }

  static deleteActivity(id: number) {
    const url = Variables.urls["api-v3"].activity_detail.replace("<pk>", id.toString());
    return apiFetch(url, {method: "DELETE"});
  }

  /*
   * A collection of shorthands for common chains of requests.
   * These assume that each chain in the link exists and that the host's first
   * association is the one only one we want.
   */

  static async getExpandedSelf() {
    const user = await api.getSelf();
    if (user.candidate) {
      user.candidate = await api.getCandidate(user.candidate);
    }
    if (user.host) {
      user.host = await api.getHost(user.host);
    }
    return user;
  }

  static async getUserUpToAssociation(skipHost=false) {
    let user;
    if (skipHost) {
      user = await api.getSelf();
      user.host = {uuid: user.host};
      const x = await api.getAssociationForHost(user.host.uuid);
      user.host.association = x.results[0];
    }
    else {
      user = await api.getExpandedSelf();
      user.host.association = await api.getAssociation(user.host.associations[0]);
    }
    return user;
  }

  static async getUserUpToLocation(skipHost: boolean) {
    const user = await api.getUserUpToAssociation(skipHost);
    user.host.association.location = await api.getLocation(user.host.association.location);
    return user;
  }

  static async getUserUpToCompany(skipHost: boolean) {
    const user = await api.getUserUpToLocation(skipHost);
    user.host.association.location.company = await api.getCompany(user.host.association.location.company);
    return user;
  }

  static async getAssociationUpToLocation(uuid: string) {
    const association = await api.getAssociation(uuid);
    association.location = await api.getLocation(association.location);
    return association;
  }

  static async getAssociationUpToCompany(uuid: string) {
    const association = await api.getAssociationUpToLocation(uuid);
    association.location.company = await api.getCompany(association.location.company);
    return association;
  }

  // Premium partners

  static async getPlans() {
    const url = Variables.urls["api-v3"].plan_display_list;
    return apiFetch(url);
  }

  static async getPaymentPage(queryParams) {
    const url = Variables.urls["api-v3"].hosted_page;
    return apiFetch(url, {queryParams});
  }

  static async getOneTimeChargePage(queryParams) {
    const url = Variables.urls["api-v3"].one_time_charge;
    return apiFetch(url, {queryParams});
  }

  static async verifyOneTimeCharge(data) {
    const url = Variables.urls["api-v3"].hosted_page_success;
    return apiFetch(url, {data, method: "POST"});
  }
  static async getMyPremiumPartner(queryParams) {
    const url = Variables.urls["api-v3"].my_premium_partner;
    return apiFetch(url, {queryParams});
  }

  static async updateMyPremiumPartner({data}) {
    const url = Variables.urls["api-v3"].my_premium_partner;
    return apiFetch(url, {data});
  }

  static async getPartnerDomain() {
    const url = Variables.urls["api-v3"].administered_partner_domain;
    return apiFetch(url);
  }

  static async getPartnerPlan() {
    const url = Variables.urls["api-v3"].partner_plan;
    return apiFetch(url);
  }
  static async createSubscription({data}) {
    const url = Variables.urls["api-v3"].create_subscription;
    return apiFetch(url, {data});
  }
  static async updateSubscription({data}) {
    const url = Variables.urls["api-v3"].update_subscription;
    return apiFetch(url, {data});
  }

  static async getFeaturedPartners() {
    const url = Variables.urls["api-v3"].featured_partner_list;
    return apiFetch(url);
  }

  static async getPartner(subdomain) {
    const url = Variables.urls["api-v3"].partner_public.replace("<lookup>", subdomain);
    return apiFetch(url);
  }

  static async getChargebeePlan(planPriceId) {
    const url = Variables.urls["api-v3"].get_chargebee_plan.replace("<plan_price_id>", planPriceId);
    return apiFetch(url);
  }

  static async getBillingInfo() {
    const url = Variables.urls["api-v3"].billing_info;
    return apiFetch(url);
  }

  static async setBillingInfo(data) {
    const url = Variables.urls["api-v3"].billing_info;
    return apiFetch(url, {data});
  }

  static updateBillingInfo(data) {
    const url = Variables.urls["api-v3"].billing_info;
    return apiFetch(url, {method: "PATCH", data});
  }

  // duedil

  static async getDuedilSearch(data) {
    const url = Variables.urls["api-v3"].duedil;
    return apiFetch(url, {data, method: "POST"});
  }

  static async getCompanyData(queryParams) {
    const url = Variables.urls["api-v3"].duedil;
    return apiFetch(url, {queryParams});
  }

  static async submitSignUpform(data) {
    const {host, protocol} = window.location;
    const url = `${protocol}//${host}/v3/auth/registration/`;
    return apiFetch(url, {data, method: "POST"});
  }

  static async submitSignUpformForOtp(data) {
    const url = Variables.urls["api-v3"].user_create_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  static async verifySignupOtp(data) {
    const url = Variables.urls["api-v3"].verify_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  static async resendSignupOtp(data) {
    const url = Variables.urls["api-v3"].resend_signup_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  static async resendLoginOtp(data) {
    const url = Variables.urls["api-v3"].resend_login_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  static async submitLoginformForOtp(data) {
    const url = Variables.urls["api-v3"].create_login_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  static async verifyLoginOtp(data) {
    const url = Variables.urls["api-v3"].verify_login_otp;
    return apiFetch(url, {data, method: "POST"});
  }

  // Success stories
  static async getSuccessStories(storyType?: string) {
    let url = "";
    if (storyType) {
      url = Variables.urls["api-v3"].successstories_type_list.replace("<story_type>", storyType);
    }
    else {
      url = Variables.urls["api-v3"].successstories_list;
    }
    return apiFetch(url);
  }

  static async getSuccessStory(storyType: string, userName: string) {
    const url = Variables.urls["api-v3"].successstories_type_detail.replace("<story_type>", storyType).replace("<username>", userName);
    return apiFetch(url);
  }

  /*Public profile */
  static getPublicUser(pk: string) {
    const url = Variables.urls["api-v3"].user_retrieve.replace("<int:pk>", pk);
    return apiFetch(url);
  }

  static getPublicCandidate(pk: string) {
    const url = Variables.urls["api-v3"].candidate_retrieve.replace("<int:pk>", pk);
    return apiFetch(url);
  }


  static getPublicEducations(queryParams) {
    const url = Variables.urls["api-v3"].public_user_educations_list;
    return apiFetch(url, {queryParams});
  }

  static getPublicExperience(queryParams) {
    const url = Variables.urls["api-v3"].public_user_experiences_list;
    return apiFetch(url, {queryParams});
  }

  static getPublicCertificates(queryParams) {
    const url = Variables.urls["api-v3"].public_user_certificates_list;
    return apiFetch(url, {queryParams});
  }

  static getPublicSocialLinks(queryParams) {
    const url = Variables.urls["api-v3"].public_sociallinks_list;
    return apiFetch(url, {queryParams});
  }

  // Config

  static async getConfigValue(queryParams) {
    const url = Variables.urls["api-v3"].get_config_value;
    return apiFetch(url, {queryParams});
  }

  static async createGPTDescription(queryParams) {
    const url = Variables.urls["api-v3"].generate_description;
    return apiFetch(url, {queryParams});
  }
  static updateFeedback(uuid, data) {
    const url = Variables.urls["api-v3"].project_aigenerated_description_detail.replace("<uuid>", uuid);
    return apiFetch(url, {method: "PATCH", data});
  }

}
