import React, {useRef, useState, useEffect, useMemo} from "react";
import classNames from "classnames";
import moment from "moment";
import Tooltip from "react-tooltip";
import {Heading, Button, Subtitle, RichTextEditor} from "workfinder-components";
import {chatModalInterface} from "components/shared/Modal/ChatModal";
import {status} from "util/interview/constants";
import RadioButtons from "components/shared/Form/RadioButtons";
import {joinCallModalInterface} from "components/dashboard/JoinCallModal";
import styles from "./index.module.scss";
import {formatDayMonthTimeRange, formatWeekdayStartEndTime12Hours, getTimeZoneAbbr} from "util/date-time";
import {reasonsToDeclineInterview} from "./constants";
import AbstractCard, {CardHeaderCompany} from "./AbstractCard";

function InterviewDate({interviewDate, onSelect, selectedDateId, onAccept, tooltipRef, showTooltip, tooltipId}) {
  const activeClass = (
    interviewDate.status == "selected" || selectedDateId == interviewDate.id ?
      styles.activeButton :
      null
  );

  const hasBeenSelected = interviewDate.status == "selected" || selectedDateId == interviewDate.id;
  const isExpired = moment(interviewDate.date_time).isBefore(moment());

  return (
    <>
      <div className={classNames(styles.offerButtons)}>
        <a
          className={isExpired ? styles.expired : classNames(styles.acceptButton, activeClass)}
          onClick={!isExpired ? onSelect : null}
        >
          {isExpired && <span>Expired </span>}
          {formatDayMonthTimeRange(interviewDate.date_time, interviewDate.duration)}
        </a>
        {hasBeenSelected && !isExpired &&
        <Button
          baseComponent="button"
          kind="primary"
          className={classNames(styles.acceptInviteButton)}
          onClick={() => {
            onAccept(selectedDateId);
          }}
          ref={tooltipRef}
          data-tip
          data-tip-disable={!showTooltip}
          data-for={tooltipId}
        >
          Accept
        </Button>
        }
      </div>
    </>
  );
}

const AddRescheduleMessage = ({
  candidateMessage,
  setCandidateMessage,
  onNewTimeRequest,
}) => {
  return (
    <>
      <p>Add a message:</p>
      <RichTextEditor
        key="richTextEditor"
        hideToolbar
        placeholder="E.g. I am available every Monday, Tuesday, and Wednesday between 1:00pm and 6:00pm UK time."
        value={candidateMessage}
        onChange={setCandidateMessage}
      />
      <Button
        size="medium"
        className="w-100"
        onClick={() => onNewTimeRequest(candidateMessage)}
        disabled={!candidateMessage.length}
      >
        Request a new time
      </Button>
    </>
  );
};

const GoBackButton = ({onClick}) => (
  <div className={classNames(styles.buttonGroup)}>
    <Button
      baseComponent="button"
      kind="tertiary"
      onClick={onClick}
    >
      <i className="ri-arrow-left-line mr-1"></i>
        Go back
    </Button>
  </div>
);

const DeclineActionButton = ({isRescheduleMessageEmpty, onDecline}) => (
  <Button size="medium"
    className={classNames(
      isRescheduleMessageEmpty ? styles.confirmDeclineButtonDisabled : "",
      "text-center btn w-100",
    )}
    onClick={onDecline}
    disabled={isRescheduleMessageEmpty}
  >
    Decline interview invite
  </Button>
);


export const ComponentWithReasonConfirmation = ({
  showDeclineConfirmation, setShowDeclineConfirmation,
  onDecline, onNewTimeRequest, defaultContent, type,
  candidateMessage, setCandidateMessage, tooltipId}) => {
  const [reasonDeclined, setReasonDeclined] = useState(reasonsToDeclineInterview[0]);

  if (!showDeclineConfirmation) {
    return defaultContent();
  }

  const isRescheduleMessageEmpty = reasonDeclined === "I would like to reschedule" && candidateMessage.length === 0;
  const DeclinedInterviewRadioButtons = () => (
    <RadioButtons
      name={`declineReason${tooltipId}`}
      data={reasonsToDeclineInterview}
      onChange={(value) => setReasonDeclined(value)}
      required
      value={reasonDeclined}
    />
  );

  return (
    <>
      <Heading size="h6">Please select why you can’t make this {type}:</Heading>
      { type === "interview" ? <DeclinedInterviewRadioButtons/> : null }
      {reasonDeclined === "I would like to reschedule" ? (
        <AddRescheduleMessage
          candidateMessage={candidateMessage}
          setCandidateMessage={setCandidateMessage}
          onNewTimeRequest={onNewTimeRequest}
        />
      ) : (
        <DeclineActionButton
          isRescheduleMessageEmpty={isRescheduleMessageEmpty}
          onDecline={onDecline}
        />
      )}
      <GoBackButton onClick={() => setShowDeclineConfirmation(false)}/>
    </>
  );
};

export default function OfferInterviewCard({interview, onAccept, onDecline, onNewTimeRequest, className}) {
  const tooltipRef = useRef();

  const [isExpanded, setExpanded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);
  const [candidateMessage, setCandidateMessage] = useState("");
  const [selectedDateId, setSelectedDateId] = useState();

  useEffect(() => {
    if (tooltipRef) {
      if (showTooltip) {
        Tooltip.show(tooltipRef);
      }
      else {
        Tooltip.hide(tooltipRef);
      }
    }
  }, [showTooltip]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const interview_decline_id = queryParams.get("inv_dec");
    if (interview_decline_id && interview_decline_id==interview.id) {
      setShowDeclineConfirmation(true);
      queryParams.delete("inv_dec");
      const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + queryParams.toString();
      history.replaceState(null, null, newUrl);
    }
  }, [interview.id]);

  const interviewDates = interview.interview_dates;
  const {associated_project, associated_project_name} = interview.placement;
  const project = {
    uuid: associated_project.uuid ?? associated_project,
    name: associated_project_name || associated_project?.name,
  };
  const association = interview.placement?.association;
  const hostFullName = interview.interviewer_name || association?.host.full_name;
  const hostName = hostFullName?.substr(0, hostFullName.indexOf(" "));
  const associationLocation = association && association?.location ? association?.location : null;
  const company = associationLocation?.company;
  const companyProfile = {
    picture: company.logo,
    name: company.name,
  };
  const projectUrlFull = new URL(`/role/${project?.uuid}`, location.toString()).toString();
  const [selectedInterviewDate, setSelectedInterviewDate] = useState(interview.interview_dates.find(date => date.status === "selected"));
  const [showAcceptDateConfirmation, setShowAcceptDateConfirmation] = useState(false);

  useEffect(() => {
    if (selectedDateId) {
      const newInterviewDate = interview.interview_dates.find(date => date.id === selectedDateId);
      setSelectedInterviewDate(newInterviewDate);
    }
  }, [interview, selectedDateId]);

  const formattedInterviewDate = useMemo(() => {
    if (!selectedInterviewDate) {
      return null;
    }
    const {date_time, duration} = selectedInterviewDate;
    return formatWeekdayStartEndTime12Hours(date_time, duration);
  }, [selectedInterviewDate]);

  const getEventDescription = () => (
    <>
      <p><b>Interview with {hostFullName} from {company?.name} about {project?.name}</b></p>
      <p>Meeting link to join: <a href={interview.meeting_link}>{interview.meeting_link}</a></p>
      <p>{company?.name}&apos;s project listing: <a href={projectUrlFull}>{projectUrlFull}</a></p>
    </>
  );


  if (showAcceptDateConfirmation) {
    return (
      <AbstractCard
        profile={companyProfile}
        className={className}
        tag="Interview confirmed!"
        project={project}
        headerChildren={(
          <div>
            <p>Thank you! Your interview is confirmed for: </p>
            <p><strong>{formattedInterviewDate}</strong></p>
            <p>We have just sent you confirmation email with all the meeting details.</p>
          </div>
        )}
      />
    );
  }

  if (interview.declined) {
    return (
      <AbstractCard
        profile={companyProfile}
        className={className}
        title="We have let the recruiter know that you will not be proceeding with this application."
        tag="Interview declined"
      >
        <p><strong>Thank you!</strong></p>
        <p>We have let the host know that you will not be proceeding with this application.</p>
      </AbstractCard>
    );
  }

  if (interview.timeRequested) {
    return (
      <AbstractCard
        profile={companyProfile}
        className={className}
        title={`We have let ${hostName || "the recruiter"} know that these times do not work for you and that you would like to reschedule.`}
        tag="Reschedule request sent"
      >
        <p><strong>Thank you!</strong></p>
        <p>We have let {hostName || "the recruiter"} know that these times do not work for you and that you would like to reschedule.</p>
      </AbstractCard>
    );
  }

  if ([status.INTERVIEW_OFFERED, status.OPEN].includes(interview.status)) {
    const tooltipId = `time-required-tooltip-${interview.id}`;
    const message = isExpanded ? interview.offer_message : `${interview.offer_message.substring(0, 100)}...`;
    const allDatesExpired = interviewDates.every(interviewDate => moment(interviewDate.date_time).isBefore(moment()));
    const defaultContent = () => (
      <>
        <CardHeaderCompany
          className="pl-0 mb-3"
          profile={companyProfile}
          project={project}
        />
        <Subtitle size="small" className="mb-auto">
        Message: &quot;{message}&quot;
        </Subtitle>
        <Button baseComponent="button" kind="tertiary" size="small" onClick={() => setExpanded(!isExpanded)} className={classNames(styles.textButton)}>
          {isExpanded ? "Close Full Message" : "View Full Message "}
        </Button>
        <Subtitle size="large" className="mt-2">Accept one interview time ({getTimeZoneAbbr()}):</Subtitle>
        <div className={styles.interviewDates}>
          {
            interviewDates.map(interviewDate =>
              <InterviewDate
                key={interviewDate.id}
                interviewDate={interviewDate}
                onSelect={() => {
                  setSelectedDateId(interviewDate.id);
                  setShowTooltip(false);
                }}
                onAccept={() => {
                  if (selectedDateId == undefined) {
                    setShowTooltip(true);
                  }
                  else {
                    onAccept(selectedDateId).then(
                      setShowAcceptDateConfirmation(true)
                    ).catch(console.error);
                  }
                }}
                selectedDateId={selectedDateId}
                tooltipRef={tooltipRef}
                data-tip
                data-tip-disable={!showTooltip}
                data-for={tooltipId}
              />,
            )}
          {allDatesExpired &&
              <div className={classNames(styles.requestNewTimeButtonArea)}>
                <Button size="medium"
                  className={styles.requestNewTimeButton}
                  onClick={() => {
                    setShowDeclineConfirmation(true);
                    setShowTooltip(false);
                  }}
                >
                  Request a new time
                </Button>
              </div>
          }
        </div>

        <div className={classNames(styles.buttonGroup)}>
          <Button
            baseComponent="button"
            kind="tertiary"
            onClick={() => {
              setShowDeclineConfirmation(true);
              setShowTooltip(false);
            }}
          >
            I can’t make this interview <i className="ri-arrow-right-line ml-1"></i>
          </Button>
        </div>
        <Tooltip id={tooltipId} place="bottom" effect="solid"
          delayHide={2000}
        >
        Please select a timeslot.
        </Tooltip>
      </>
    );

    return (
      <AbstractCard
        project={project}
        profile={companyProfile}
        hostFullName={hostFullName}
        className={className}
        selectedDateId={selectedDateId}
        title={`Interview invite from ${hostName} of ${company?.name}`}
        tagClass={showDeclineConfirmation ? "hide" : ""}
      >
        <ComponentWithReasonConfirmation
          showDeclineConfirmation={showDeclineConfirmation}
          setShowDeclineConfirmation={setShowDeclineConfirmation}
          onDecline={onDecline}
          onNewTimeRequest={onNewTimeRequest}
          defaultContent={defaultContent}
          type="interview"
          candidateMessage={candidateMessage}
          setCandidateMessage={setCandidateMessage}
          tooltipId={tooltipId}
        />
      </AbstractCard>
    );
  }
  const {interviewer, interviewer_name, interviewer_uuid} = interview;
  const correspondentUUID = interviewer ? interviewer_uuid : interview.placement.owner_uuid;
  const correspondentName = interviewer ? interviewer_name : association?.host.full_name;


  if (status.MEETING_LINK_ADDED === interview.status || interview.status === status.INTERVIEW_ACCEPTED || interview.status == status.INTERVIEW_COMPLETED) {
    const timeNow = new Date().getTime();
    const endTime = moment(moment(selectedInterviewDate.date_time).add(selectedInterviewDate.duration, "minutes"));

    return (
      <AbstractCard
        project={project}
        profile={companyProfile}
        interviewDate={selectedInterviewDate}
        getEventDescription={getEventDescription}
        hostFullName={hostFullName}
        title={`Interview with ${association?.host.full_name} of ${company?.name}`}
        titleClass={classNames(styles.acceptedCardTitle, styles.linkAdded)}
        headerClass={styles.interviewAcceptedCard}
        tag={(endTime < timeNow) ? "Completed interview" : ""}
        tagClass={(endTime < timeNow) ? "completed" : ""}
        headerChildren={
          <>
            <div className={styles.buttonGroup}>
              <div>
                <Button
                  baseComponent="button"
                  kind="secondary"
                  className={classNames(styles.messageButton, "mb-0")}
                  onClick={() => {
                    chatModalInterface.dispatch({
                      open: true,
                      placementUUID: interview.placement.uuid,
                      correspondentUUID,
                      correspondentName,
                    });
                  }}
                >
                  <i className="ri-message-3-line"></i>
                  Message
                </Button>
              </div>
              {(timeNow < endTime) ? (
                <div>
                  <Button
                    baseComponent="button"
                    kind="primary"
                    onClick={() => {
                      joinCallModalInterface.dispatch({
                        open: true,
                        candidate: {interview},
                      });
                    }}
                    className={classNames(styles.joinButton, "mb-0")}
                  >
                    <i className="ri-vidicon-fill"></i>
                    Join Call
                  </Button>
                </div>
              ) : null}
            </div>
          </>
        }
      />
    );
  }

  return null;
}
