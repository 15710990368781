import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import SelectAccountType from "./SelectAccountType";
import EnterEmail from "./EnterEmail";
import Verification from "./Verification";

const UUID_REGEX = "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";
const signupLinks = [
  {href: `/member/verification/:uuid(${UUID_REGEX})`, title: "Verify member account", component: Verification },
  {href: `/recruiter/verification/:uuid(${UUID_REGEX})`, title: "Verify recruiter account", component: Verification },
  {href: "/member/partner/:partneruuid/:partnerdomain", title: "Member account signup", component: EnterEmail},
  {href: "/member/:slug", title: "Member account signup", component: EnterEmail},
  {href: "/member", title: "Member account signup", component: EnterEmail},
  {href: "/recruiter/partner/:partneruuid/:partnerdomain", title: "Recruiter account signup", component: EnterEmail},
  {href: "/recruiter/:slug", title: "Recruiter account signup", component: EnterEmail},
  {href: "/recruiter", title: "Recruiter account signup", component: EnterEmail},
  {href: "/", title: "Select account type", component: SelectAccountType},
];
const loginLinks = [
  {href: `/verification/:uuid(${UUID_REGEX})`, title: "Verify code", component: Verification },
  {href: "/", title: "Account login", component: EnterEmail},
];

const LoginSignup = ({isLogin}) => {
  const baseName = isLogin ? "/login" : "/signup";
  const routes = isLogin ? loginLinks : signupLinks;
  return(
    <BrowserRouter basename={baseName}>
      <Switch>
        {
          routes.map(o =>
            <Route key={o.title} path={o.href}>
              { o.component && <o.component isLogin={isLogin}/> }
            </Route>,
          )
        }
        <Route path="*">
          <Redirect key={routes.find(r => r.href == "/").title} to="/"/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default LoginSignup;