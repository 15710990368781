export enum PlacementOfferStatus {
    OFFERED = "offered",
    ACCEPTED = "accepted",
    WITHDRAWN = "withdrawn",
    DRAFT = "draft",
}

type ProjectType = {
    is_paid?: boolean;
    uuid: string;
};

type PlacementType = {
    uuid: string;
    company_name: string;
    associated_project: ProjectType;
    associated_project_name: string;
    company_logo_url?: string;
    start_date: string;
    end_date?: string;
    host_name: string;
    host_uuid: string;
};

type LocationType = {
    address_city: string;
    address_country_name: string;
    address_postcode: string;
    address_region: string;
    address_street: string;
};

type OfferTypeType = "normal" | "extension";

export type OfferType = {
    uuid: string;
    offer_type: OfferTypeType;
    working_days: string[];
    periods_of_day: string[];
    location: LocationType;
    location_preference: string;
    offer_notes: string;
    placement: PlacementType;
    status: PlacementOfferStatus;
    salary: number;
    currency: string;
    rate: string;
    working_duration_hours_per_day: number;
    response_date: string;
    created_at: string;
};

export interface OfferCardProps {
    offer: OfferType;
}

export interface CompanyHeaderProps {
    companyLogo: string;
    projectName: string;
    companyName: string;
    projectUrl: string;
    status: PlacementOfferStatus;
    responseDate?: string;
    createdAt: string;
}

export enum TriggerOptions {
    WITHDRAW = "widthdraw",
}

export interface OfferTagProps {
    offer_status: PlacementOfferStatus;
    offer_type: OfferTypeType;
    trigger?: TriggerOptions
}

export interface ActionButtonsProps {
    offer: OfferType;
    trigger?: TriggerOptions;
    setTrigger: React.Dispatch<React.SetStateAction<TriggerOptions>>;
}
