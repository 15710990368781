export const status = {
    ACCEPTED: "accepted",
    PENDING: "pending",
    DENIED: "denied",
    UNSPECIFIED: "unspecified",
  };

export const documentType = {
    CV: "cv",
    PASSPORT: "passport",
    UNSPECIFIED: "unspecified",
    PORTFOLIO: "portfolio",
    WORKING_RIGHT: "working right",
    LINKEDIN: "linkedin",
    SKILL_LEVEL: "skill level",
    TECH_SKILL_LEVEL: "tech skill level",
    SALARY_RANGE: "salary range",
    COVER_LETTER: "cover letter",
}

export const documentTypeHumanized = {
    CV: "CV",
    PASSPORT: "Passport",
    UNSPECIFIED: "unspecified",
    PORTFOLIO: "Portfolio",
    WORKING_RIGHT: "Working Right",
    LINKEDIN: "Linkedin",
    SKILL_LEVEL: "skill level",
    TECH_SKILL_LEVEL: "Tech Skill Level",
    SALARY_RANGE: "Salary range",
}
