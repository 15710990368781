import React, {forwardRef} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import useStateRef from "hooks/useStateRef";

import useConstraintValidation from "./useConstraintValidation";


const Input = forwardRef((props, ref) => {
  const {
    className,
    name,
    onBlur,
    onChange,
    onFocus,
    validator,
    value,
    errorClassName,
    touchedClassName,
    validClassName,
    ...restProps
  } = props;
  const [inputRef, setInputRef] = useStateRef();
  const {
    className: constraintClassName,
    setFocus,
    errorPopup,
  } = useConstraintValidation({name, value, validator, ref: inputRef, errorClassName, touchedClassName, validClassName});

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e);
    }
    setFocus(false);
  }

  function handleChange(e) {
    const {name, value} = e.target;
    onChange(value, name, e);
  }

  function handleFocus(e) {
    if (onFocus) {
      onFocus(e);
    }
    setFocus(true);
  }

  return <>
    <input
      {...restProps}
      ref={curRef => {
        setInputRef(curRef);
        if (ref != null) {
          if (typeof ref === "function") {
            ref(curRef);
          }
          else {
            ref.current = curRef;
          }
        }
      }}
      name={name}
      value={value}
      className={classNames(className, constraintClassName)}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
    { errorPopup }
  </>;
});

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  validator: PropTypes.func,
  value: PropTypes.any,
  errorClassName: PropTypes.string,
  touchedClassName: PropTypes.string,
  validClassName: PropTypes.string,
  props: PropTypes.object,
};

export default Input;
