export function setEquals(a, b) {
  if (a.size !== b.size) {
    return false;
  }
  for (var x of a) {
    if (!b.has(x)) {
      return false;
    }
  }
  return true;
}

export function difference(a, b) {
  return new Set([...a].filter(x => !b.has(x)));
}

export function overlaps(a, b) {
  b = new Set(b);
  return a.some(x => b.has(x));
}

export function isSubset(a, b) {
  if (!(b instanceof Set)) {
    b = new Set(b);
  }
  if (!(Array.isArray(a))) {
    a = [...a];
  }
  b = new Set(b);
  return a.every(x => b.has(x));
}
