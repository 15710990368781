import React, {useContext} from "react";
import styles from "./index.module.scss";
import {Heading, Body, SplitButton} from "workfinder-components";
import {
  CandidatePublicProfileContext
} from "./CandidatePublicProfileContextProvider";
import WikipediaTooltip from "util/wikipedia/WikipediaTooltip";

function Experience({experience}): JSX.Element {
  return (
    <div className={styles.boxContainer}>
      <div className="d-flex mb-2">
        <Heading size="h5" className="mb-0">
          <span className="mr-2">{experience.title}</span>
          {experience.companyName ? (
            <span className="mb-0 font-weight-normal">
              {experience.companyName}
            </span>
          ) : null}
        </Heading>
      </div>
      {experience.startMonthYear ? (
        <Body size="small" className="mb-2">
          <i className="ri-calendar-event-fill"></i> {experience.startMonthYear}{" "}
          - {experience.endMonthYear || "Present"}
        </Body>
      ) : null}
      {experience.locationName ? (
        <Body size="small" className="mb-2">
          <i className="ri-map-pin-user-fill"></i> {experience.locationName}
        </Body>
      ) : null}
      {experience.description ? (
        <Body size="small" className="mb-2">
          <i className="ri-file-text-line"></i> {experience.description}
        </Body>
      ) : null}
      {experience.files && experience.files.length > 0 && (
        <Body size="small">
          {experience.files.map((file) => (
            <div key={file.url}>
              <i className="ri-image-line"></i>
              <a className={styles.link} onClick={
                () => {
                  if (Variables.isAuthenticated) {
                    window.location.href = file.url;
                  }
                  else {
                    window.location.href = "/signup/recruiter";
                  }
                }
              }
              >
                {file.name}
              </a>
            </div>
          ))}
        </Body>
      )}

      <div className="d-flex flex-wrap">
        {experience.techSkills && experience.techSkills.length > 0 && experience.techSkills.map((skill, index) => (
          <div key={index} className="d-inline-flex">
            <SplitButton
              tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug} />}
              secondaryComponent={skill.level && <span className="font-weight-normal">{skill.level}</span>}
            >
              <span className="text-nowrap">{skill.skill}</span>
            </SplitButton>
          </div>
        ))}
      </div>
    </div>
  );
}

const Experiences = (): JSX.Element => {
  const context = useContext(CandidatePublicProfileContext);
  const {experiences, isExperienceLoading} = context;

  if (isExperienceLoading) {
    return <></>;
  }
  if (!(experiences && experiences.length > 0)) {
    return <></>;
  }
  return (
    <>
      <Heading size="h4" className="mr-5 mb-3">
        Experience
      </Heading>
      {experiences && experiences.length >0 && experiences?.map((experience) => {
        return <Experience key={experience.uuid} experience={experience} />;
      })}
    </>
  );
};
export default Experiences;
