import React from "react";
import classNames from "classnames";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";

import BaseCalendar from "./BaseCalendar";
import styles from "./calendar.module.scss";


/*
 * The default Calendar implementation (under Arvind's design).
 */
function Calendar({className, context, ...otherProps}) {
  const dateOfBirthErrors = context?.inputs.get("date_of_birth")?.errors;
  return (
    <div className="d-flex flex-column">
      <div className={classNames(className, styles.calendarContainer)}>
        <BaseCalendar {...otherProps}/>
        <i className={classNames("far fa-calendar-alt", styles.calendarIcon)}/>
      </div>
      {dateOfBirthErrors?.length && <div className="mt-4 text-danger">{dateOfBirthErrors[0]}</div>}
    </div>
  );
}

Calendar.propTypes = {
  appendTo: PropTypes.instanceOf(Element),
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  theme: PropTypes.object,
  touchUI: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

export default Calendar;
