import useTrackedState from "hooks/useTrackedState";
import React, { useContext, useEffect, useState } from "react";
import api from "services/v3";
import {Upload, Body} from "workfinder-components";
import * as constants from "util/documentRequest/constants";
import classNames from "classnames";
import styles from "./uploadCV.module.scss"
import { CandidateProfileContext } from "./CandidateProfileContextProvider";
import Loader from "/static/workfinder/images/processing.gif";

const acceptedFormats: string[] = ["application/pdf", "application/msword"];

const UploadCV = ({className = ""}) => {
  const context = useContext(CandidateProfileContext);
  const {
    refetchEducation,
    refetchExperience,
    refetchTechSkill,
    setUser,
    setUserData
  } = context;

  const [cvFile, setCVFile, setReferenceFile, hasCVFileChanged] = useTrackedState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scrapCVError, setScrapCVError] = useState(false);

  useEffect(() => {
    if(cvFile) {
      const data = new FormData();
      data.append("file", cvFile)
      //data.append("user", Variables.user.pk)
      data.append("document_type", constants.documentType.CV)
      setIsLoading(true);
      api.createFileUpload(data).then(response => {
        api.scrapUserCVData({"file" : response.file}).then(cvData => {
          setReferenceFile(null);
          let dataToBeUpdate = {}
          if(cvData["degree"] && cvData["degree"].length > 0)
            dataToBeUpdate["has_no_formal_education"] = false;
          if(cvData["experience"] && cvData["experience"].length > 0)
            dataToBeUpdate["has_no_experience"] = false;
          setUser(user => ({...user, ...dataToBeUpdate}));
          refetchEducation();
          refetchExperience();
          refetchTechSkill();
          if(cvData.name) {
            const nameData = cvData.name.split(" ");
            setUserData(data => data.set("first_name", nameData[0]));
            nameData.shift();
            setUserData(data => data.set("last_name", nameData.join(" ")));
          }
          setIsLoading(false);
        });
      }).catch(error => {
        setIsLoading(false);
        setScrapCVError(true);
      });
    }
  }, [hasCVFileChanged]);

  const handleUploadComplete = (uploadedFiles) => {
    if(uploadedFiles && uploadedFiles.length > 0) {
      setCVFile(uploadedFiles[0]);
    }
  };

  const buttonText = () => {
    if(isLoading)
      return<><img src={Loader} height="16" className="mr-2"/>loading</>;
    else
     return <>Autofill with CV</>;
  }

  return <><Upload
    className={classNames("d-inline-block", styles.uploadCv, className)}
    acceptedFormats={acceptedFormats}
    onUploadComplete={handleUploadComplete}
    allowMultiple={false}
    buttonText={buttonText()}
  /> {scrapCVError && <Body className={styles.errorMessage}>Oops! Autofill did not work. Please try again.</Body>}
  </>;
}

export default UploadCV;
