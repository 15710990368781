import {useEffect, useState} from "react";

/**
 * `useState` that persists the state in local storage. It will back the state
 * up when it gets used and restore it on load. This also allows sharing of
 * state between pages.
 * @param intialValue `useState`'s initial value.
 * @param key The storage key. "application.some.value" means the local storage
 *            entry with the key "application", accessing "some.value". This
 *            allows the same local storage object to be used by multiple calls
 *            and deleted by any of them.
 * @param sessionId A unique value tying the state to a user or any other value.
 *                  When this value is different from that in local storage,
 *                  local storage will be cleared.
 * @param promote Whether to keep a state stored without a sessionId once a
 *                with the same key is stored but with a sessionId. Think of
 *                data entered as an anonymous user being enter
 * @param validation A function to check whether the loaded state is still
 *                   valid. If this function returns `false` the stored state
 *                   will be cleared.
 * @param storage The storage to back up to, e.g. localStorage or sessionStorage.
 */
function useStoredState(initialState, key, sessionId, options={}) {
  const {promote=true, storage=sessionStorage} = options;
  const [state, setState] = useState(init(initialState));

  useEffect(write, []);
  useEffect(write, [state, key, sessionId]);

  function init(initialState) {
    var state = read();
    if (!state) {
      if (initialState instanceof Function) {
        state = initialState();
      }
      else {
        state = initialState;
      }
    }
    return state;
  }

  /**
   * Read from local storage keeping access rules in mind.
   */
  function read() {
    var store = storage.getItem(key);
    if (store === null) {
      return;
    }
    else {
      try {
        store = JSON.parse(store);
      }
      catch {
        return;
      }
      if (sessionId && store.sessionId === sessionId || !store.sessionId && promote) {
        return store.state;
      }
      return;
    }
  }

  /**
   * Write to local storage keeping access rules in mind.
   */
  function write() {
    storage.setItem(key, JSON.stringify({sessionId, state}));
  }

  function removeStoredState() {
    storage.removeItem(key);
  }

  return [state, setState, removeStoredState];
}

export default useStoredState;
