import React, { useCallback, useContext, useMemo, useState } from "react";
import moment from "moment";
import { Button, Heading, Tabs, Subtitle, Tooltip } from "workfinder-components";
import styles from "./candidateApplications.module.scss"
import DefaultPhoto from "/static/workfinder/images/generic-photo.png";
import {status as placementStatus} from "util/placement/constants"
import {status as interviewStatus} from "util/interview/constants"
import classNames from "classnames";
import _ from "lodash";
import LoadingSpinner from "components/shared/LoadingSpinner";
import { getActiveFeatures, getName } from "util/placement/activeFeatures";
import useIsMobile from "hooks/useIsMobile";
import { QuickActions } from "components/dashboard/QuickActions";

import EditInterviewDropdown from "components/dashboard/editInterviewDropdown";
import { formatDateTimeDaySeparatelyOther, formatStartEndTime12Hours, isPast } from "util/date-time";
import AddToCalendar from "components/shared/AddToCalendar";
import { timesModalInterface } from "components/shared/Modal/TimesModal";
import { renderToString } from "react-dom/server";
import {
  FUNNELTAB,
  INTERVIEW_PLACEMENT_STATUS_MAPPING,
  INTERVIEW_TO_FUNNELTAB,
  STATUS_TO_FUNNELTAB,
  STATUS_BACKGROUND_COLOR_MAP,
  STATUS_TEXT_MAP,
  STATUS_ICON_MAP
} from "components/dashboard/constants/index";
import { HostDashboardContext } from "./HostDashboardContextProvider";
import { DISABLE_REFETCH_OPTIONS, useSelfQuery } from "services/queries";
import Stars from "/static/workfinder/images/stars.png";
import CommentIcon from "/static/workfinder/images/icons/comment-icon.png";
import EventTracking from "tracking/EventTracking";
import {featureUrl, interestEvent} from "util/cms/constants";
import { joinCallModalInterface } from "components/dashboard/JoinCallModal";
import { NoRole } from "components/company-overview/RoleList";
import { offerInterviewModalInterface } from "components/dashboard/OfferInterviewModal";
import { trimHtmlTags } from "util/string";

const CandidateApplications = ({roleId}) => {
  const context = useContext(HostDashboardContext);
  const {
    isLoading,
    interviewCandidates,
    nonInterviewCandidates
  } = context;
  const[selectedTab, setSelectedTab] = useState("new");

  const candidates = useMemo(() => {
    const orderedInterviewCandidates = _.orderBy(interviewCandidates, [o => {
      switch(o.interview_status) {
        case interviewStatus.INTERVIEW_ACCEPTED:
        case interviewStatus.MEETING_LINK_ADDED:
          return 1;
        case interviewStatus.INTERVIEW_TIME_REQUESTED:
        case interviewStatus.RESCHEDULE_REQUIRED:
          return 2;
        case interviewStatus.INTERVIEW_DECLINE:
          return 3;
        case interviewStatus.INTERVIEW_OFFERED:
          return 4;
        default:
          return 5;
      }
    }, "confidence_score", "placementKey"], ["asc", "desc", "desc"]);
    const orderedNonInterviewCandidates = _.orderBy(nonInterviewCandidates, [o => {
      switch(o.status){
        case placementStatus.ACCEPTED:
          return 6;
        case placementStatus.WITHDRAWN:
          return 7;
        case placementStatus.OFFERED:
          return 8;
        default:
          return 9;
      }
    }, "confidence_score", "placementKey"], ["asc", "desc", "desc"]);
    const apps = [...(orderedInterviewCandidates ?? []), ...(orderedNonInterviewCandidates ?? [])];
    if(roleId && roleId != "all")
      return apps?.filter(placement => placement.projectId === roleId) ?? null;
    else
      return apps;
  }, [roleId, interviewCandidates, nonInterviewCandidates]);

  const dataByTab = useCallback(
    (tab) => {
      if(tab == "all") {
        return _.orderBy(candidates, ["confidence_score", "placementKey"], ["desc", "desc"])
      }
      else {
        return candidates?.filter(candidate => (
          Object.values(interviewStatus).includes(candidate.interview_status) &&
          Object.keys(INTERVIEW_PLACEMENT_STATUS_MAPPING).includes(candidate.status) ?
            INTERVIEW_TO_FUNNELTAB[candidate.interview_status] == tab :
            STATUS_TO_FUNNELTAB[candidate.status] == tab
        ));
      }
    },
    [candidates],
  );

  const FilteredApplications = ({tab, applications}) => {
    if(isLoading) {
      return <LoadingSpinner className="d-block m-auto"/>;
    }
    if(!roleId) {
      return <div className={styles.noApplicationContainer}><NoRole /></div>
    }
    if(applications && applications.length > 0) {
      return (<>
        {tab == "archived" && <div className={styles.archiveExplanation}>
          <Heading size="h6">Total {applications.length} archived applicant(s)</Heading>
          <Subtitle inline size="small">To ensure a smooth and efficient process and to provide a positive experience for all applicants, our Skillseeker service automatically archives candidates for you if there is no response to their application within the first 72 hours. Archived candidates are not declined but are encouraged to move on.</Subtitle>
        </div>
        }
        <div className={styles.candidateApplications}>
          {applications.map((application, index) => (
              <CandidateApplication application={application} index={index} />
            ))
          }
        </div>
      </>);
    }
    else {
      return <div className={styles.noApplicationContainer}>No applications are currently in {tab} status.</div>
    }
  }

  const TabLabel = ({text, count}) => {
    return (<div className={styles.tabTitle}>
      {text}
      <Subtitle inline size="small" className={styles.count}>{count ?? 0}</Subtitle>
    </div>);
  }

  const tabData = [
    {
      id: "all",
      label: <TabLabel text="All" count={candidates?.length} />,
      component: <FilteredApplications tab="all" applications={dataByTab("all")} />,
      reloadOnFocus: true
    },
    {
      id: "new",
      label: <TabLabel text="New" count={dataByTab(FUNNELTAB.NEW)?.length} />,
      component: <FilteredApplications tab="new" applications={dataByTab(FUNNELTAB.NEW)} />,
      reloadOnFocus: true
    },
    {
      id: "interview",
      label: <TabLabel text="Interview" count={dataByTab(FUNNELTAB.INTERVIEW)?.length} />,
      component: <FilteredApplications tab="interview" applications={dataByTab(FUNNELTAB.INTERVIEW)} />,
      reloadOnFocus: true
    },
    {
      id: "offer",
      label: <TabLabel text="Offer" count={dataByTab(FUNNELTAB.OFFER)?.length} />,
      component: <FilteredApplications tab="offer" applications={dataByTab(FUNNELTAB.OFFER)} />,
      reloadOnFocus: true
    },
    {
      id: "archived",
      label: <TabLabel text="Archived" count={dataByTab(FUNNELTAB.ARCHIVED)?.length} />,
      component: <FilteredApplications tab="archived" applications={dataByTab(FUNNELTAB.ARCHIVED)} />,
      alignAtEnd: true,
      reloadOnFocus: true
    },
    {
      id: "declined",
      label: <TabLabel text="Declined" count={dataByTab(FUNNELTAB.DECLINED)?.length} />,
      component: <FilteredApplications tab="declined" applications={dataByTab(FUNNELTAB.DECLINED)} />,
      alignAtEnd: true,
      reloadOnFocus: true
    },
  ];

  return (<>
    <Tabs
      tabs={tabData}
      initialSelectedId="new"
      fixedOnTop={true}
      marginTop={50}
      onChange={(value) => setSelectedTab(value)}
    />
  </>);
}

const ApplicationStatus = ({application}) => {
  const interviewTimes = application.interview?.interview_dates ?? [];
  const isInterviewOfferExpired = application.status == placementStatus.INTERVIEW_OFFERED && areAllTimesExpired(interviewTimes);
  const status = `${application.status}${isInterviewOfferExpired ? "_EXPIRED" : ""}`;
  const text = useMemo(() => STATUS_TEXT_MAP[status], [status]);
  const icon = useMemo(() => STATUS_ICON_MAP[status], [status]);
  const backgroundColor = useMemo(() => STATUS_BACKGROUND_COLOR_MAP[status], [status]);
  return (
    <span className={styles.applicationStatus} style={{"background": backgroundColor}}>
      <Subtitle size="small" inline >{icon}&nbsp;{text}</Subtitle>
    </span>
  );
}

function getApplicantUrl(uuid) {
  return new URL(Variables.urls.hosts.applicant_details.replace("<placement_id>", uuid), location.toString()).toString();
}

function getProjectUrl(candidate) {
  return new URL(`/role/${candidate.projectId}`, location.toString()).toString();
}

function areAllTimesExpired(interviewTimes) {
  const currentDate = moment();
  return interviewTimes.every(t => {
    const startTime =  moment(t.date_time);
    const endTime = startTime?.clone().add(t.duration, "minutes");
    return currentDate > endTime;
  });
}

const InterviewDetails = ({application, activeFeatures}) => {
  const interviewTimes = _.orderBy(application.interview?.interview_dates ?? [], ["date_time"], ["desc"]);
  const isInterviewOfferExpired = application.status == placementStatus.INTERVIEW_OFFERED  && areAllTimesExpired(interviewTimes);
  const selectedTimes = interviewTimes.filter(
    date => date.status === "selected",
  );
  const selectedTime = selectedTimes && selectedTimes.length > 0 ? selectedTimes[0] :
    (interviewTimes && interviewTimes.length > 0 ? interviewTimes[0] : null);
  const startTime = selectedTime && moment(selectedTime.date_time);
  const endTime = startTime?.clone().add(selectedTime.duration, "minutes");

  function editInterviewButton() {
    if([placementStatus.INTERVIEW_TIME_REQUESTED, placementStatus.RESCHEDULE_REQUIRED].includes(application.status)) {
      return <></>;
    }
    if (!activeFeatures)
      return <></>;
    else if (isInterviewOfferExpired)
      return <></>;
    else if (application.interview?.interview_dates) {
      if (application.interview?.interview_dates.every(date => isPast(date.date_time, date.duration))) {
        return <></>;
      }
    }
    return (
      <div onClick={e => e.stopPropagation()}>
        <EditInterviewDropdown
          activeFeatures={activeFeatures}
          candidate={application}
          interview={application.interview}
        />
      </div>
    );
  }

  function offerNewTimesButton() {
    if(!([placementStatus.INTERVIEW_TIME_REQUESTED, placementStatus.RESCHEDULE_REQUIRED].includes(application.status)) && !isInterviewOfferExpired) {
      return <></>;
    }

    return (
      <Button
        kind="tertiary"
        onClick={e => {
          e.stopPropagation();
          //@ts-ignore
          offerInterviewModalInterface.dispatch({
            open: true,
            hostReschedule: true,
            candidate: application,
          });
        }}
      >
        <i className="ri-mic-line"></i>&nbsp;Offer new times
      </Button>
    )
  }

  function viewTimesButton() {
    if(!([placementStatus.INTERVIEW_OFFERED, placementStatus.INTERVIEW_TIME_REQUESTED, placementStatus.RESCHEDULE_REQUIRED].includes(application.status))) {
      return <></>;
    }
    if(isInterviewOfferExpired) {
      return <></>;
    }
    return (
      <Button
        kind="tertiary"
        onClick={e => {
          e.stopPropagation();
          timesModalInterface.dispatch({
            open: true,
            candidate: application,
          });
        }}
      >
        <i className="ri-eye-line"></i>&nbsp;View times
      </Button>
    )
  }

  function joinCallButton () {
    if (!([placementStatus.INTERVIEW_CONFIRMED, placementStatus.INTERVIEW_MEETING_LINK_ADDED].includes(application.status))) {
      return <></>;
    }
    else if (endTime && new Date() > endTime) {
      return <></>;
    }
    return (
      <Button
        kind="tertiary"
        onClick={(e) => {
          e.stopPropagation();
          joinCallModalInterface.dispatch({
            open: true,
            candidate: application,
          });
        }}
      >
          <i className="ri-customer-service-line"></i>&nbsp;Join Call
      </Button>
    )
  }

  function getEventDescription(candidate) {
    return (
      <>
        <p><b>Interview with {candidate.user.full_name} about {candidate.project}</b></p>
        <p>Meeting link to join: <a href={candidate.interview.meeting_link}>{candidate.interview.meeting_link}</a></p>
        <p>{candidate.user.first_name}'s application: <a href={getApplicantUrl(candidate.placementID)}>{getApplicantUrl(candidate.placementID)}</a></p>
        <p>Your project listing: <a href={getProjectUrl(candidate)}>{getProjectUrl(candidate)}</a></p>
      </>
    );
  }

  function addToCalendarButton() {
    if (!([placementStatus.INTERVIEW_CONFIRMED, placementStatus.INTERVIEW_MEETING_LINK_ADDED].includes(application.status))) {
      return <></>;
    }
    return (
      <AddToCalendar
        event={{
          title: `Interview with ${application.user.full_name} about ${application.project}`,
          description: renderToString(getEventDescription(application)),
          start: startTime ? startTime: "",
          end: endTime ? endTime: "",
          url: getApplicantUrl(application.placementID),
        }}
      />
    );
  }

  return (<div className={styles.interviewDetailsContainer}>
    <hr className="d-none d-md-block"/>
    <div className={styles.interviewDetails}>
      {selectedTime && <Subtitle inline size="small" className={classNames("flex-grow-1", ([placementStatus.INTERVIEW_TIME_REQUESTED, placementStatus.RESCHEDULE_REQUIRED, placementStatus.INTERVIEW_DECLINE].includes(application.status) || isInterviewOfferExpired) ? styles.stikethrough : "")}>{formatDateTimeDaySeparatelyOther(selectedTime.date_time).date} <span className={styles.grey}>•&nbsp;</span>{formatStartEndTime12Hours(selectedTime.date_time, selectedTime.duration)}</Subtitle>}
      <Subtitle inline size="small" className="d-flex flex-grow-1 justify-content-end mt-n2">
        {viewTimesButton()}
        {addToCalendarButton()}
        {editInterviewButton()}
        {offerNewTimesButton()}
        {joinCallButton()}
      </Subtitle>
    </div>
  </div>);
}

const CandidateApplication = ({application, index}) => {
  const context = useContext(HostDashboardContext);
  const {
    setIsShareModalOpen,
    setDisqualifyModalOpen,
    setSuggestOfferModalOpen,
    setSuggestDeclineModalOpen,
    setActionedCandidate,
    setIsUpgradeOpen,
    setSharedPlacementUUID
  } = context;

  function handleRowClick() {
    if (Variables.user?.partner.is_paid || application.status !== placementStatus.ARCHIVED) {
      window.open(Variables.urls.hosts.applicant_details.replace("<placement_id>", application.placementID));
    }
    else {
      setIsUpgradeOpen(true);
    }
  }

  const {data: me} = useSelfQuery(DISABLE_REFETCH_OPTIONS);
  const isMobile = useIsMobile(767);
  const pImgSrc = application.photo || DefaultPhoto;
  const activeFeatures = getActiveFeatures(me, application, application.owner_uuid != Variables.uuids.user && application.status != placementStatus.SHARED_WITH_YOU, application.owner_uuid == Variables.uuids.user);
  const summary = trimHtmlTags(application.user.summary);

  function renderName() {
    return getName({activeFeatures, user: application.user});
  }

  function isBlurImage() {
    return [placementStatus.PENDING, placementStatus.VIEWED, placementStatus.EXPIRED, placementStatus.ARCHIVED, placementStatus.UNROUTEABLE, placementStatus.DECLINED, placementStatus.CANCELLED].includes(application.status);
  }

  const onUpgradeClick = e => {
    e.stopPropagation();
    if (!Variables.user.partner?.CANDIDATE_EVALUATION_DASHBOARD) {
      EventTracking.send({
        content_object: {
          object_id: Variables.user.pk,
          object_type: "User",
        },
        event_type: interestEvent.CANDIDATE_EVALUATION_DASHBOARD,
      });
      window.open(featureUrl.CANDIDATE_EVALUATION_DASHBOARD);
    }
  }

  function content(rowNumber) {
    let gridRowStyle = {};
    if(!isNaN(rowNumber)) {
      gridRowStyle["gridRow"] = `${((rowNumber * 2) + 2)} / ${((rowNumber * 2) + 3)}`
    }
    return (<>
      <div className={classNames(styles.candidateInfo, [placementStatus.PENDING].includes(application.status) && styles.new)} style={gridRowStyle} onClick={() => handleRowClick()}>
        <div className={styles.candidateImageContainer}>
          <img className={styles.candidateImage} src={pImgSrc}/>
          {isBlurImage() && <div className={styles.blur}/>}
        </div>
        <div className={styles.nameStatusContainer}>
          <ApplicationStatus application={application}  />
          <Heading size="h4" className="mb-0 d-flex align-items-center text-capitalize">{renderName()}</Heading>
        </div>
        <Subtitle inline className={styles.candidateBio} size="small" title={summary}>{summary}</Subtitle>
        <div>
        <Subtitle
            inline
            className={styles.candidateBio}
            size="small"
            title={summary}
          >
          For <a style={{ textDecoration: 'underline' }} href={getProjectUrl(application)}> {application.project} </a>
        </Subtitle>
      </div>
        {[placementStatus.INTERVIEW_OFFERED, placementStatus.INTERVIEW_TIME_REQUESTED, placementStatus.RESCHEDULE_REQUIRED, placementStatus.INTERVIEW_CONFIRMED, placementStatus.INTERVIEW_MEETING_LINK_ADDED, placementStatus.INTERVIEW_COMPLETED, placementStatus.INTERVIEW_DECLINE].includes(application.status) && <InterviewDetails application={application} activeFeatures={activeFeatures} />}
      </div>
      <div className={classNames(styles.matchContainer, [placementStatus.PENDING].includes(application.status) && styles.new)} style={gridRowStyle} onClick={() => handleRowClick()}>
        <div className={classNames(styles.matchInsight, "d-block d-md-none")}>
          <img height="16" src={Stars}/>&nbsp;<Subtitle inline size="small">Match Insight</Subtitle>
        </div>
        <Match confidenceScore={application.confidence_score}/>
      </div>
      <div className={classNames(styles.feedbackContainer, [placementStatus.PENDING].includes(application.status) && styles.new)} style={gridRowStyle} onClick={() => handleRowClick()}>
        <div className={classNames(styles.grey, "d-block d-md-none")}>
            <Subtitle inline size="small">Team Feedback</Subtitle>
        </div>
        <div className={styles.feedback}>
          <Subtitle className={classNames(styles.feedbackCount, styles.green)}><i className="ri-thumb-up-fill"></i>{application.like_count}</Subtitle>
          <Subtitle className={classNames(styles.feedbackCount, styles.red)}><i className="ri-thumb-down-fill"></i>{application.disike_count}</Subtitle>
          <Subtitle className={classNames(styles.feedbackCount, styles.grey)}><i className="ri-message-3-line"></i>{application.comments_count}</Subtitle>
        </div>
      </div>
      <div className={classNames(styles.nextStepButton, [placementStatus.PENDING].includes(application.status) && styles.new)} style={gridRowStyle}>
        {(application.status === placementStatus.ARCHIVED && !Variables.user.partner.is_paid) &&
          <Button kind="secondary" onClick={onUpgradeClick}><i className="ri-lock-line"></i>&nbsp;Upgrade</Button>
        }
        {!(application.status === placementStatus.ARCHIVED && !Variables.user.partner.is_paid) &&
          <QuickActions
            text="Next steps"
            candidate={{...application, interview: application.interview ? (Array.isArray(application.interview) ? (application.interview.length > 0 ? application.interview[0] : {}) : application.interview) : {}}}
            isOwner={application.owner_uuid == Variables.uuids.user}
            isProjectCollaborator={application.owner_uuid != Variables.uuids.user && application.status != placementStatus.SHARED_WITH_YOU}
            onRowClick={handleRowClick}
            setActionedCandidate={setActionedCandidate}
            setDisqualifyModalOpen={setDisqualifyModalOpen}
            setSuggestOfferModalOpen={setSuggestOfferModalOpen}
            setSuggestDeclineModalOpen={setSuggestDeclineModalOpen}
            setSharedPlacementUUID={setSharedPlacementUUID}
            setIsShareModalOpen={setIsShareModalOpen}
            allowedFeatures={application.allowed_features}
            disabled={!Variables.user.partner.is_paid && application.status === placementStatus.ARCHIVED}
          />
        }
      </div>
    </>);
  }

  if(isMobile) {
    return (<>
      {index > 0 && <hr className={classNames(styles.rowSeparator, "w-100")}/>}
      <div className={classNames(styles.candidateApplication, [placementStatus.PENDING].includes(application.status) && styles.new)}>
      {content("null")}
    </div></>);
  }
  return (<>
    {index == 0 && <>
      <div
        className={classNames(styles.matchInsight, styles.gridHeader)}
        style={{gridRow: "1 / 2", gridColumn: "2 / 3"}}>
        <img height="16" src={Stars}/>&nbsp;<Subtitle inline size="small">Match Insight</Subtitle>&nbsp;
        <Tooltip iconClassName="d-inline" iconComponent={<img height="16" src={CommentIcon}/>}>
          Match Insight reflects how closely a candidate aligns with the role's requirements. We delve deep, evaluating hard and soft skills, personality traits, preferences, behavior, DEI, skill levels, and more. Saving you and your team countless hours in prescreening, so that you can get your role filled in days like a superhero! 🚀
        </Tooltip>
      </div>
      <div
        className={classNames(styles.grey, styles.gridHeader)}
        style={{gridRow: "1 / 2", gridColumn: "3 / 4"}}>
          <Subtitle inline size="small">Team Feedback</Subtitle>&nbsp;
          <Tooltip iconClassName="d-inline" iconComponent={<i className="ri-question-line"></i>}>
          Teamwork accelerates hiring! See your team's feedback here. To enable their input, invite them as collaborators for this role.
        </Tooltip>
      </div>
    </>}
    {index > 0 && <hr className={classNames(styles.rowSeparator, "w-100")} style={{gridRow: `${((index * 2) + 1)} / ${((index * 2) + 2)}`, gridColumn: "1 / 5"}} />}
    {content(index)}
  </>)
}

export const Match = ({confidenceScore, style = null, ...otherProps}) => {
  const confidencePercentage = confidenceScore
    ? (confidenceScore * 100) > 100 ? 100 : Math.round(confidenceScore * 100)
    : 0;

  return (<div
      className={styles.match}
      style={{background: `radial-gradient(closest-side, white 83%, transparent 84% 100%, white 0), conic-gradient(#FAC94C, #CA78EF, #006FF1 ${confidencePercentage}%, #ECF0F8 0)`, ...style}}
      {...otherProps}
    >
        {confidencePercentage}%
  </div>);
}

export default CandidateApplications;


