import React, {useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import EventTracking from "tracking/EventTracking";
import api from "services/v3";

import {useAlerts, messages} from "components/shared/AlertsProvider";
import _ from "lodash";
import baseStyles from "./recommendationCard.module.scss";
import dbLogo from "/static/workfinder/images/digitalboost.png";
import flLogo from "/static/workfinder/images/futurelearn.png";
import fallbackMasterclassImg from "/static/workfinder/images/generic-photo.png";
import share from "/static/workfinder/images/share-logo.svg";
import email from "/static/workfinder/images/email-logo.png";
import facebook from "/static/workfinder/images/facebook-logo.png";
import whatsapp from "/static/workfinder/images/whatsapp-logo.png";
import copy from "/static/workfinder/images/copy-logo.png";
import linkedin from "/static/workfinder/images/linkedin-logo.png";
import thumbsUp from "/static/workfinder/images/thumbs-up.svg";
import thumbsDown from "/static/workfinder/images/thumbs-down.svg";
import workfinder from "/static/workfinder/images/wf-logo.png";
import thankyou from "/static/workfinder/images/thankyou.svg";
import LinkCopied from "/static/workfinder/images/link-copied.svg";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png"
import CourseRecommendationCard from "./CourseRecommendationCard";
import {getCurrencySymbol, numberWithCommas, CURRENCY_RATE_MAPPING} from "components/project-compensation/index";
import {Button, Heading, Subtitle} from "workfinder-components";
import {mergeClassNames} from "styles/utils";
import swiperStyles from "./swiperList.module.scss";
import {formatStartEndDateShortWithDot} from "util/date-time";

const styles = mergeClassNames(baseStyles, swiperStyles);

export const OtherRecommendationCard = ({className, project, isFeatured, setClickedOnCard, clickedOnCard}) => {
  const type = project.recommendation_type || project.type;
  const pk = project[type]?.pk || project.id;
  let imgURL, title, description, footer, providerLogo, fallback_masterclass_img, redirectTo, start_time, duration;
  const {first_name= "", last_name="", image_url, name, introduction, profile_picture, summary} = project[type];
  let object_type = "Project";

  switch (type) {
  case "mentor":
    imgURL = profile_picture;
    title = `Talk To ${first_name} ${last_name}`;
    description = summary;
    footer = `Book a call with ${first_name}`;
    object_type = "Mentor";
    break;

  case "masterclass":
    imgURL = project[type].volunteer?.profile_picture;
    title = project[type].title;
    description = project[type].description;
    footer = "Learn more";
    providerLogo = dbLogo;
    fallback_masterclass_img = fallbackMasterclassImg;
    redirectTo = project[type].affiliate_url;
    object_type = "MasterClass";
    start_time = project[type].start_time
    duration = project[type].duration
    break;

  case "course":
    imgURL = image_url;
    title = name;
    description = introduction;
    footer = "View course details";
    providerLogo = flLogo;
    redirectTo = project[type].get_affiliate_url;
    object_type = "Course";
    break;
  default:
    break;
  }

  const handleClick = (clicked_button, e) => {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }

    let courseUrl = object_type === "Course" ? project[type]?.get_affiliate_url : null;
    if (courseUrl) {
      window.open(courseUrl, "_blank");
    } else if (object_type !== "Course") {
      courseUrl = Variables.urls.candidates.redirect_to_partner.replace("<type>", object_type.toLowerCase()).replace("<pk>", pk);
      window.open(courseUrl, "_blank");
    }

    EventTracking.send({
      content_object: {
        object_type: object_type ? object_type : "ProjectRecommendation",
        object_id: object_type ? pk : (project.recommendation_uuid || project.uuid),
      },
      extra_data: {clicked_button},
      name: `${isFeatured ? "featured" : "recommendation"}-opened`,
      event_type: `${isFeatured ? "feat" : "rec"}-o`,
    }).finally(() => {
      setClickedOnCard(false);
    });

    if (!courseUrl) {
      console.error("No URL found.");
      setClickedOnCard(false);
    }
  };



  if (type === "course") {
    return <CourseRecommendationCard course={project.course} handleClick={(e) => handleClick('View course details', e)}/>;
  }
  return (
    <div
      className={classNames("d-flex flex-column", className, styles.cardRecommendation)}
      onClick={(e) => handleClick(footer, e)}
      style={{cursor: "pointer"}}
    >
      <div className="row no-gutters">
        <div className="col d-flex align-items-start">
          <span className={styles.type}>{_.capitalize(type)}</span>
        </div>
        <div className="col" style={{"flex": "0 1 6rem"}}>
          <img className={styles.headerImg} src={imgURL || fallback_masterclass_img}/>
        </div>
        <div className="col d-flex flex-row-reverse">
          <img src={providerLogo} className={styles.dbLogo}/>
        </div>
      </div>
      <div className="d-flex flex-column mt-2 align-items-center">
        <Heading size="h5">{title}</Heading>
        <Subtitle inline={false}>From Digital boost</Subtitle>
      </div>
      <p className={styles.description}>
        {description}
      </p>
      <div className="row mb-4">
        {redirectTo && <div className={classNames("col-3", styles.flexColumn)}>
          <span className={styles.cardDescriptionTitle}>Location</span>
          <span>Online</span>
        </div>}
        {start_time && <div className={classNames("col-9", styles.flexColumn)}>
          <span className={styles.cardDescriptionTitle}>Date</span>
          <span>{formatStartEndDateShortWithDot(start_time, duration)}</span>
        </div>}
      </div>
      <div className={styles.footer}>
        <Button kind="secondary" onClick={(e) => handleClick(footer, e)}>
          {footer}&nbsp;
          <i className="fas fa-arrow-right"></i>
        </Button>
      </div>
    </div>);
};

function RecommendationCard({project, className=""}) {
  const location = project.association && project.association.location ? project.association.location : null;
  const locationPreference = _.capitalize(project.location_preference);
  const company = location && location.company ? location.company : null;
  const candidateActivities = project?.candidate_activities.slice(0, 5);
  const show_insight_tags = project.show_insight_tags;
  const applyUrl = `/role/${project.uuid}`;
  const projectUrl = new URL(`/role/${project.uuid}`, window.location.origin);
  const [addAlert] = useAlerts();
  const [likeThisSubmitted, setLikeThisSubmitted] = useState(false);
  const [hideLikeThisSubmitted, setHideLikeThisSubmitted] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false); // set only for one second
  const description = project.description_truncated;
  const isPartner = project.association.host.has_premium_partner;

  const duration = project.duration_months;


  function handleClick(clicked_button) {
    if (project.recommendation_uuid) {
      EventTracking.send({
        content_object: {
          object_type: "ProjectRecommendation",
          object_id: project.recommendation_uuid,
        },
        extra_data: {clicked_button},
        event_type: "rec-o",
      });
    }
    else {
      EventTracking.send({
        content_object: {
          object_type: "Project",
          object_id: project.pk,
        },
        extra_data: {clicked_button},
        name: "recommendation-opened",
        event_type: "rec-o",
      });
    }
  }

  function recommendationUpdate(value) {
    let data = {project: project.uuid, is_interested: value};
    if (project.recommendation_uuid)
      data["recommendation"] = project.recommendation_uuid;
    api.createProjectFeedback(data)
      .then(() => {
        setLikeThisSubmitted(true);
        setTimeout(() => {
          setHideLikeThisSubmitted(true); // count is 0 here
        }, 7000);
      })
      .catch(() => addAlert("recommendation_update", "danger", messages.recommendationUpdateError));
  }

  function onLinkCopy(e) {
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 5000);
    navigator.clipboard.writeText(projectUrl);
    e.stopPropagation();
  }

  const companyName = company && company.name;
  const projectName = project.name || "Work placement";
  const companyLogo = company.logo ? company.logo : null;
  const scaleup_tags = company.scaleup_tags;
  const sector_tag = company.sector_tag;
  const encodedProjectUrl = encodeURIComponent(projectUrl);
  const first_message = encodeURIComponent("Are you ready to boost your career?");
  const message = encodeURIComponent(`Are you ready to boost your career? Join us at ${companyName} and apply to our new open role today: ${projectName} ${projectUrl}`);

  const getRate = () => {
    return CURRENCY_RATE_MAPPING[project.rate];
  };

  const renderSalaryRange = () => {
    return `${getCurrencySymbol(project.currency)}${numberWithCommas(project.min_salary)}-${numberWithCommas(project.max_salary)} ${getRate()}`;
  };

  return (
    <div className="d-flex flex-column">
      <div className={classNames(className, "b3 card", styles.cardRecommendation)}>
        <div className={classNames("card-body", styles.cardBody)}>
          <div className="row mb-4">
            <div className="col-auto d-flex align-items-start">
              <div className={styles.companyLogo}>
                <img src={companyLogo ?? defaultCompanyLogo}></img>
              </div>
            </div>
            <div className="col">
              <div className={styles.projectName}>{projectName}</div>
              <a href={`${window.location.origin}/company/${company.company_id}`} target="_blank">
                <div className={styles.companyName}>{companyName}</div>
              </a>
              <div className={classNames("mt-2 d-flex align-items-center flex-wrap", styles.gap)}>
                {show_insight_tags &&
                  <>
                    {scaleup_tags.length > 0 &&
                      scaleup_tags.map(scaleup_tag => (
                        <div className={styles.scaleupTag}>
                          {scaleup_tag}
                        </div>
                      ))
                    }
                    {sector_tag.length > 0 &&
                      sector_tag.map(sector => (
                        <div className={styles.sectorTag}>
                          {sector}
                        </div>
                      ))
                    }
                  </>
                }
                {isPartner && (
                  <div className={styles.partnerTag}>
                    <img src={workfinder}/> Partner
                  </div>
                )}
                <div className="dropdown">
                  <a type="button" className={styles.shareButton} data-toggle="dropdown">
                    <span><img src={share} alt="learnmore" /></span>
                          Share
                  </a>
                  <div className="share">
                    <div className="dropdown-menu p-0">
                      <a className="dropdown-item"
                        onClick={(e) => {
                          window.open(`whatsapp://send?text=Hey check out this opportunity ${encodedProjectUrl}`, "_blank");
                          e.stopPropagation();
                        }}
                      >
                        <span className={classNames("mr-1", styles.socialDropButtons)}><img src={whatsapp} alt="whatsapp" /></span>
                              WhatsApp
                      </a>
                      <a className="dropdown-item"
                        onClick={(e) => {
                          window.open(`https://facebook.com/sharer/sharer.php?u=${encodedProjectUrl}`, "_blank");
                          e.stopPropagation();
                        }}
                      >
                        <span className={classNames("mr-1", styles.socialDropButtons)}><img src={facebook} alt="facebook" /></span>
                              Facebook
                      </a>
                      <a className="dropdown-item"
                        onClick={(e) => {
                          const link = `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodedProjectUrl}&amp;source=${encodedProjectUrl}&amp;title=${first_message}&amp;summary=${message}`.
                            replaceAll(" ", "").replaceAll("%2F", "/").replaceAll("&amp;", "&");
                          window.open(link, "_blank");
                          e.stopPropagation();
                        }}
                      >
                        <span className={classNames("mr-1", styles.socialDropButtons)}><img src={linkedin} alt="linkedin" /></span>
                              Linkedin
                      </a>
                      <a className="dropdown-item"
                        onClick={() => {
                          window.location.href = `mailto:test@example.com?subject=${first_message}&body=${message}`;
                        }}
                      >
                        <span className={classNames("mr-1", styles.socialDropButtons)}><img src={email} alt="email" /></span>
                              Email
                      </a>
                      <a className="dropdown-item" onClick={e => onLinkCopy(e)}>
                        <span className={classNames("mr-1", styles.socialDropButtons)}><img src={copy} alt="copy-link" /></span>
                              Copy link
                      </a>
                      {linkCopied ?
                        <img
                          style={{position: "absolute", left: 10, top: 220, zIndex: 1000}}
                          src={LinkCopied}
                          alt="LinkCopied"
                        />
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className={classNames("col", styles.flexColumn, styles.locationColumn)}>
              <span className={styles.cardDescriptionTitle}>Location</span>
              <span>
                {
                  locationPreference === "Remote" ? "Remote" : (
                    project.project_location?.address_city ?
                      project.project_location.address_city :
                      project.project_location?.address_postcode || locationPreference
                  )
                }
              </span>
            </div>
            <div className={classNames("col", styles.flexColumn, styles.compensationColumn)}>
              <span className={styles.cardDescriptionTitle}>Compensation</span>
              {!project.is_paid && "Voluntary" }
              {project.is_paid && !project.max_salary && "Paid" }
              {project.max_salary && <span className={styles.compensationDetail}>{renderSalaryRange()} </span>}
            </div>
            <div className={classNames("col-4", styles.flexColumn)}>
              <span className={styles.cardDescriptionTitle}>Duration</span>
              <span className="text-capitalize">{duration}</span>
            </div>
          </div>
          <div className="row">
            <div className={classNames("col-xs-12 col-md-12", styles.cardDescription)}>
              <br />
              <p className={styles.cardDescriptionTitle}> About the opportunity</p>
              <p className={styles.about}>{description}</p>
            </div>
          </div>
          <div className="row flex-grow-1">
            {candidateActivities.length === 0 ? null :
              <div className={classNames("col-xs-12 col-md-12", styles.cardDescription)}>
                <p className={styles.cardDescriptionTitle}>Key Activities</p>
                <ul style={{paddingInlineStart: "25px"}}>
                  {candidateActivities.slice(0, 3).map((skill, index) =>
                    <li key={"skill_"+index}>
                      {skill}{candidateActivities.length-1===index&&", and more"}
                    </li>,
                  )}
                </ul>
              </div>
            }
          </div>
        </div>
        <div className={styles.cardFooter}>
          <a href={applyUrl}>
            <button className={styles.button} onClick={() => handleClick("Easy Apply")}>
            Learn more
            </button>
          </a>
        </div>
        { (!project.feedback_submitted) && !likeThisSubmitted &&
          <div className={classNames("row", styles.likeThis)}>
            <div className="col-6">
              <button
                className={styles.moreButtons}
                onClick={() => {
                  if (Variables.isAuthenticated && !project.feedback_submitted && !likeThisSubmitted) {
                    recommendationUpdate(true);
                  } else {
                    window.location.href = "/signup";
                  }
                }}>
                More like this
                <img height={16} src={thumbsUp} alt="thumbs up" />
              </button>
            </div>
            <div className="col-6">
              <button
                className={styles.moreButtons}
                onClick={() => {
                  if (Variables.isAuthenticated && !project.feedback_submitted && !likeThisSubmitted) {
                    recommendationUpdate(false);
                  } else {
                    window.location.href = "/signup";
                  }
                }}>
                Less like this
                <img height={16} src={thumbsDown} alt="thumbs down" />
              </button>
            </div>
          </div>
        }
      </div>
      {
        (likeThisSubmitted && !hideLikeThisSubmitted) &&
        <div className={classNames("row", styles.thankyoudiv)}>
          <div className="col">
            <img className="img-fluid" src={thankyou} alt="pride flag" />
          </div>
        </div>
      }
    </div>
  );
}

RecommendationCard.propTypes = {
  className: PropTypes.string.isRequired,
  hasPlacements: PropTypes.bool,
  project: PropTypes.object.isRequired,
};

OtherRecommendationCard.propTypes = {
  className: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  isFeatured: PropTypes.bool,
};

export default RecommendationCard;
