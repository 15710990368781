import React, {useContext} from "react";
import styles from "./index.module.scss";
import {
  Heading,
  Body,
  SplitButton
} from "workfinder-components";

import {
  CandidatePublicProfileContext,
} from "./CandidatePublicProfileContextProvider";
import WikipediaTooltip from "util/wikipedia/WikipediaTooltip";

function Education({education}): JSX.Element {
  return (<div className={styles.boxContainer}>
    <div className="d-flex mb-2">
      <Heading size="h5" className="mb-0">
        <span className="mr-2">
          {education.degree_title}
        </span>
        {education.educational_institution ? (
          <span className="mb-0 font-weight-normal">
            {education.educational_institution}
          </span>
        ) : null}
      </Heading>
    </div>
    {education.degree_type ? (
      <Body size="small" className="mb-2"><i className="ri-graduation-cap-line"> </i>{education.degree_type}</Body>
    ) : null}
    {education.startMonthYear ? (
      <Body size="small" className="mb-2">
        <i className="ri-calendar-event-fill"></i>{" "}
        {education.startMonthYear} - {education.endMonthYear || "Present"}
      </Body>
    ) : null}
    {education.locationName ? (
      <Body size="small" className="mb-2">
        <i className="ri-map-pin-user-fill"></i>{" "}
        {education.locationName}
      </Body>
    ) : null}
    {education.description ? (
      <Body size="small" className="mb-2">
        <i className="ri-file-text-line"></i> {education.description}
      </Body>
    ) : null}
    {education.files && education.files.length > 0 &&
      <Body size="small" className="mb-3"><i className="ri-image-line"></i>
        {education.files.map(file => (
          <a key={file.url} className={styles.link}
            onClick={
              () => {
                if (Variables.isAuthenticated) {
                  window.location.href = file.url;
                }
                else {
                  window.location.href = "/signup/recruiter";
                }
              }
            }
          >{file.name}</a>
        ))}
      </Body>
    }

    <div className="d-flex flex-wrap">
      {education.techSkills && education.techSkills.length > 0 && education.techSkills.map((skill, index) =>  (
        <div key={index} className="d-inline-flex">
          <SplitButton
            tooltip={skill.wikipedia_slug && <WikipediaTooltip keyword={skill.wikipedia_slug}/>}
            secondaryComponent={skill.level && <span className="font-weight-normal">{skill.level}</span>}
          >
            <span className="text-nowrap">{skill.skill}</span>
          </SplitButton>
        </div>
      ))}
    </div>
  </div>
  );

}

const Educations = ():JSX.Element => {
  const context = useContext(CandidatePublicProfileContext);
  const {
    educations,
    isEducationLoading,
  } = context;

  if (isEducationLoading) {
    return <></>;
  }

  if (!(educations && educations.length > 0)) {
    return <></>;
  }

  return (
    <>
      <Heading size="h4" className="mr-5 mb-3">Education</Heading>
      {educations.map((education) => {
        return <Education
          key={education.uuid}
          education={education}
        />;
      })}
    </>
  );
};
export default Educations;
