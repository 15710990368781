export const skillBuilderColor = {
  Listening: "#C24F65",
  Speaking: "#D65B4E",
  "Problem Solving": "#E18552",
  Creativity: "#EFBD67",
  "Staying Positive": "#AAC779",
  "Aiming High": "#67AE71",
  Leadership: "#A1CDD8",
  Teamwork: "#59B8DD",
};
export const skillBuilderTextColor = {
  Listening: "#51000F",
  Speaking: "#571710",
  "Problem Solving": "#643014",
  Creativity: "#624719",
  "Staying Positive": "#404F28",
  "Aiming High": "#27492D",
  Leadership: "#394F55",
  Teamwork: "#224D5E",
};

export const skillDescription = {
  Listening: "The receiving, retaining and processing of information or ideas",
  Speaking: "The oral transmission of information or ideas",
  "Problem Solving": "The ability to find a solution to a situation or challenge",
  Creativity: "The use of imagination and the generation of new ideas",
  "Staying Positive": "The ability to use tactics and strategies to overcome setbacks and achieve goals",
  "Aiming High": "The ability to set clear, tangible goals and devise a robust route to achieving them",
  Leadership: "Supporting, encouraging and developing others to achieve a shared goal",
  Teamwork: "Working cooperatively with others towards achieving a shared goal",
};

export const skillBuilderLevels = {
  ADVANCED: "Advanced",
  GETTING_STARTED: "Getting started",
  INTERMEDIATE: "Intermediate",
  MASTERY: "Mastery",
};
