import React from "react";
import classNames from "classnames";

import Tooltip from "components/shared/Tooltip";
import {mergeClassNames} from "styles/utils";

import baseStyles from "./inputGroup.module.scss";
import EditButton from "./EditButton";

interface Props {
  /** The body of the InputGroup. */
  children: React.ReactNode;
  /** The ID of the InputGroup. */
  id?: string;
  /** Whether to whow the children inline with the header or not. */
  inline?: boolean;
  /** A colon symbol to display next to the title. */
  isColon?: boolean;
  /** An event handler to be called when the edit button is clicked. If not
   * set, no edit button will be shown.
   */
  onClickEdit?: React.MouseEventHandler<HTMLButtonElement>;
  /** Whether the input is required. */
  required?: boolean;
  /** The sub-title of the input group. */
  subTitle?: string;
  /** A theme to restyle the stepper. */
  theme?: {[key: string]: string};
  /** The title of the input group. */
  title: string;
  /** A tooltip to display next to the title. */
  tooltip?: React.ReactNode;
  /** If tooltip is clickable, it also adds a delay on hide event */
  tooltipClickable?: boolean;
}

export default function InputGroup(props: Props) {
  const {
    children,
    id,
    inline = false,
    onClickEdit,
    required = false,
    theme,
    title,
    tooltip,
    isColon = false,
    subTitle,
    tooltipClickable=false,
  } = props;
  const styles = mergeClassNames(baseStyles, theme);

  return (
    <div className={styles.section} id={id}>
      <div className={styles.header}>
        {title && <h3 className={classNames({[styles.inline]: inline})}>{title}{required && "*"}{isColon && ":"}</h3>}
        {subTitle && <label className={classNames({[styles.inline]: inline})}>{subTitle}{required && "*"}{isColon && ":"}</label> }
        {inline && children}
        {onClickEdit && <EditButton onClick={onClickEdit} />}
        {tooltip && <Tooltip
          className={styles.tooltip}
          delayHide={tooltipClickable ? 1000 : 0}
          clickable={tooltipClickable}
        >
          {tooltip}
        </Tooltip>}
      </div>
      {!inline && children}
    </div>
  );
}
