import React, {useContext} from "react";
import {
  CandidatePublicProfileContext,
} from "./CandidatePublicProfileContextProvider";
import styles from "./index.module.scss";
import classNames from "classnames";


const SocialLinksOrder = [
  "website",
  "linkedin_url",
  "twitter_url",
  "tiktok_url",
  "github_url",
  "instagram_url",
  "youtube_url",
  "discord_url",
  "threads_url",
];
const SocialLinksIcon = {
  website: "ri-global-fill",
  linkedin: "ri-linkedin-box-fill",
  twitter: "ri-twitter-fill",
  tiktok: "ri-tiktok-fill",
  github: "ri-github-fill",
  instagram: "ri-instagram-line",
  youtube: "ri-youtube-fill",
  discord: "ri-discord-fill",
  threads: "ri-threads-fill",
};


const SocialLinks = ():JSX.Element => {
  const context = useContext(CandidatePublicProfileContext);
  const {
    isSocialLinksLoading,
    socialLinks,
    userData,
    isUserLoading,
  } = context;


  function createInitials(fullName) {
    const words = fullName.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join(".");
  }

  if ( isUserLoading || isSocialLinksLoading) {
    return <></>;
  }

  else if (!socialLinks || !Object.keys(socialLinks).some(key => socialLinks[key])) {
    return <></>;
  }

  return (
    <div className={classNames(styles.socialMedia, "mb-4")}>
      {(userData && userData.full_name) && <><div>{createInitials(userData?.full_name)}.'s Social</div>
        {SocialLinksOrder.map((key, index) => (
          <div
            key={index}
          >
            {socialLinks[key] && (
              <a onClick={() => (window.location.href = "/signup/recruiter")} className={styles.headerLink}>
                <div className={classNames(styles.header, "d-flex")}>
                  <i className={SocialLinksIcon[key]}></i>
                  <p className="ml-2">{key}</p>
                </div>
              </a>
            )}
          </div>
        ))}</>}
    </div>
  );
};

export default SocialLinks;
