import React, {forwardRef, useRef} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";
import baseStyles from "./modalContainer.module.scss";
import {mergeClassNames} from "styles/utils";

interface Props {
  /** Children to render in the container. Should be modals. */
  children: React.ReactNode;
  /** A class name to restyle the container. */
  className?: string;
  /** The ID of the container. */
  id: string;
  /** The callback to call when the background is clicked. */
  onClickAway?: (event: MouseEvent) => void;
  /** Whether the modal is open and the container should be showed. */
  open: boolean;
  /** Theme to restyle the modal container. */
  theme?: {[key: string]: string};
}

const ModalContainer = forwardRef<HTMLDivElement | undefined, Props>(({children, id, onClickAway, open, theme}, ref): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const callback = useRef<(e: MouseEvent) => void>();
  const styles = mergeClassNames(baseStyles, theme);

  const containerStyle = classNames(
    styles.modalContainer,
    {[styles.dNone]: !open},
  );

  function handleMouseDown(event: MouseEvent) {
    if (event.target === containerRef.current && !callback.current) {
      callback.current = handleMouseUp;
      containerRef.current.addEventListener("mouseup", callback.current);
    }
  }

  /**
   * Handle clicking away from modal to close it.
   */
  function handleMouseUp(e: MouseEvent) {
    // Only close if the container was clicked, not bubbled events.
    if (e.button === 0 && e.target === containerRef.current) {
      onClickAway?.(e);
    }
    if (containerRef.current && callback.current) {
      containerRef.current.removeEventListener("mouseup", callback.current);
      callback.current = undefined;
    }
  }

  return createPortal(
    <div
      id={id}
      className={containerStyle}
      onMouseDown={handleMouseDown}
      ref={newRef => {
        containerRef.current = newRef;
        if (ref) {
          ref.current = newRef;
        }
      }}
    >
      {children}
    </div>,
    document.body,
  );
});
ModalContainer.displayName = "ModalContainer";

export default ModalContainer;
