export const status = {
  PENDING: "pending",
  ARCHIVED: "archived",
  EXPIRED: "expired",
  VIEWED: "viewed",
  DECLINED: "declined",
  SAVED: "saved",
  CONTACTING: "contacting",
  COMPLETED: "completed",
  OFFERED: "offered",
  ACCEPTED: "accepted",
  INTERVIEW_OFFERED: "interview offered",
  INTERVIEW_TIME_REQUESTED: "interview time requested",
  INTERVIEW_CONFIRMED: "interview confirmed",
  INTERVIEW_MEETING_LINK_ADDED: "interview meeting link added",
  RESCHEDULE_REQUIRED: "reschedule required",
  INTERVIEW_COMPLETED: "interview completed",
  INTERVIEW_DECLINE: "interview declined",
  WITHDRAWN: "withdrawn",
  CANCELLED: "cancelled",
  UNROUTEABLE: "unrouteable",
  SHARED_WITH_YOU: "shared with you",
};
