export const featureUrl = {
  MESSAGING: "/features/messaging/",
  GENTLE_LETDOWN_SERVICE: "/features/let-down/",
  COLLABORATION: "/features/collaborate/",
  CANDIDATE_EVALUATION_DASHBOARD: "/features/dashboard/",
};

export const interestEvent = {
  MESSAGING: "1004-in",
  GENTLE_LETDOWN_SERVICE: "1006-in",
  COLLABORATION: "1010-in",
  CANDIDATE_EVALUATION_DASHBOARD: "1003-in",
  TALENT_SOLUTIONS: "tsol-in",
};

export const OFFER_EVENT_TYPE = "offr-cl";
