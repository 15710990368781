import {useState} from "react";


interface StateRef {
    current: any;
}

/**
 * Refs to DOM nodes work great for most purposes, as they are used to access
 * the nodes directly in certain contexts. However, when the value the ref
 * points to changes nothing is rerendered. This is on purpose, as the DOM is
 * completely controlled and can change without a good reason. This hook
 * is essentially equivalent `useRef` except that when the referenced value is
 * nullish, the returned value is also null, rather than using the existing
 * ref. As a result, this hook will cause a rerender when the ref is set but
 * not when React subsequently alters the DOM.
 */
export default function useStateRef() {
  const [ref, setRef] = useState<StateRef | null>();

  function _setRef(newRef) {
    if (newRef == null) {
      setRef(newRef);
    }
    else {
      if (ref == null) {
        setRef({current: newRef});
      }
      else {
        ref.current = newRef;
        setRef(ref);
      }
    }
  }

  return [ref, _setRef];
}
