import React, {useState, useEffect} from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import TooltipWithIcon from "components/shared/Tooltip";
import tooltipStyles from "components/shared/tooltip.module.scss";
import Tooltip from "react-tooltip";
import api from "services/v3";
import {documentType, documentTypeHumanized, status} from "util/documentRequest/constants";
import {DISABLE_REFETCH_OPTIONS, useProjectQuery, useSalaryRequestStatusQuery} from "services/queries";
import {combineQueries} from "util/query";
import Button from "components/shared/Button";
import countriesInfo from "countries-information";

const allCurrencies = countriesInfo.getAllCurrencies();

export const CURRENCY_RATE_MAPPING = {
  "per hour": "an hour",
  "per day": "a day",
  "per week": "a week",
  "per month": "a month",
  "per year": "a year",
};

export function numberWithCommas(x):any {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getCurrencySymbol = (code) => {
  if(code)
    return allCurrencies.find(x => x.code.toLowerCase() === code.toLowerCase()).symbol;
  else
    return allCurrencies.find(x => x.code.toLowerCase() === "usd").symbol;
};

const ProjectCertificates = ({projectUUID}):JSX.Element => {
  const isCandidate = !!Variables.uuids.candidate;
  const projectQuery = useProjectQuery(
    projectUUID,
    DISABLE_REFETCH_OPTIONS,
  );
  const [salaryRequested, setSalarysRequested] = useState(status.UNSPECIFIED);

  const documentRequestQuery = useSalaryRequestStatusQuery({project_uuid: projectUUID}, {
    enabled: !!projectUUID && isCandidate,
  });
  const {data, isLoading, isSuccess, isError} = combineQueries([
    projectQuery,
    documentRequestQuery,
  ]);

  const [
    project,
    documentRequest,
  ] = data;

  useEffect(
    () => {
      if (isSuccess && documentRequest.results.length > 0) {
        if(documentRequest.results.find(r => r.doc_type == documentType.SALARY_RANGE && r.status == status.PENDING && r.project == projectUUID)) {
          setSalarysRequested(status.PENDING);
        }
      }
    },
    [isSuccess],
  );

  const createDocumentRequest = (projectUUID) => {
    const owner = project?.association.host.user_uuid;

    const data = {
      project: projectUUID,
      owner,
      doc_type: documentTypeHumanized.SALARY_RANGE.toLowerCase(),
    };
    setSalarysRequested(status.PENDING);
    api.createDocumentRequestAsCandidate(data).then(
      () => {
      },
    ).catch(() => {
      setSalarysRequested(status.UNSPECIFIED);
    });
  };

  const getRate = () => {
    return CURRENCY_RATE_MAPPING[project.rate];
  };

  const renderSalaryRange = () => {
    return `${getCurrencySymbol(project.currency)}${numberWithCommas(project.min_salary)}-${numberWithCommas(project.max_salary)} ${getRate()}`;
  };

  return (
    <>
      <div className="row" >

        <div className="col-2">
          <span className="material-icons">
          attach_money
          </span>
        </div>
        <div className="col-10 d-flex flex-column title" >
          <div className="d-flex">
            Compensation
            <TooltipWithIcon
              delayHide={1200}
              clickable={true}
              iconClassName={classNames(styles.toolTipIcon, "d-inline mt-1")}

            >
              <div className={classNames(styles.toolTip, styles.toolTipRequest)}>
                <p>For roles longer than 150 hours compensation needs to be paid
                to protect individuals and to be in line with our <a href="https://superpower.tech/terms-and-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                &nbsp;and&nbsp;<a href="https://superpower.tech/voluntary-work-placements/" target="_blank" rel="noreferrer">Code of Conduct for Voluntary Roles</a>.</p>
              </div>
            </TooltipWithIcon>
          </div>
          {!isLoading && project.is_paid  && <div className="d-flex grey-text">
            {!project.is_paid && "Voluntary" }
            {project.is_paid && !project.max_salary && "Paid" }
            {project.max_salary && <span className={styles.compensationDetail}>{renderSalaryRange()} </span>}
            {salaryRequested == status.UNSPECIFIED && !project.max_salary && isCandidate && <Button
              kind="tertiary"
              data-tip=""
              data-for="request-detail"
              className={classNames(styles.requestPendingButton, "px-1 ml-1") }
              onClick={() => {
                if (!Variables.isAuthenticated) {
                  $("#loginModal").modal();
                }
                else {
                  createDocumentRequest(projectUUID);
                }
              }}
            >
              {salaryRequested == status.UNSPECIFIED && "Request details"}
            </Button>}
            <Tooltip
              delayHide={1200}
              clickable={true}
              effect="solid"
              place="bottom"
              className={classNames(tooltipStyles.tooltip)}
              id="request-detail"
            >
              <div className={classNames(styles.toolTip, styles.toolTipRequest)}>
                <h5>Request compensation details</h5>
                <p >Request compensation details such as pay range for this opportunity from the recruiter by clicking on this button.</p>
              </div>
            </Tooltip>
            {salaryRequested == status.PENDING && !project.max_salary && isCandidate && <>
              <span className={styles.warning} data-tip="" data-for="details-pending">Details pending</span>
              <Tooltip
                delayHide={1200}
                clickable={true}
                effect="solid"
                place="bottom"
                className={classNames(tooltipStyles.tooltip)}
                id="details-pending"
              >
                <div className={classNames(styles.toolTip, styles.toolTipPending)}>
                  <h5>Compensation details pending</h5>
                  <p>We have requested compensation details for this opportunity from the recruiter and will notify you as soon as it’s available. </p>
                </div>
              </Tooltip>
            </>}

          </div>}
          {!isLoading && !project.is_paid && <div className="d-flex">
            Voluntary
          </div>}
        </div>
      </div>
    </>
  );
};

export default ProjectCertificates;
