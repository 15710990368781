import React from "react";

import {ButtonGroup, Content} from "components/shared/Slate";

import styles from "./contactCandidateModal.module.scss";
import Modal from "./Modal";


interface Props {
  candidate: {
    user: {email: string},
    phone?: string,
  },
  open: boolean,
  onClose: () => void,
  onContact: () => void,
}

export default function ContactCandidateModal({candidate, open, onClose}: Props) {
  return (
    <Modal onClose={onClose} open={open}>
      <Content className={styles.content}>
        <h1 className={styles.title}>CONTACT CANDIDATE</h1>
        <div className={styles.sideBySide}>
          <div>
            <div className={styles.group}>
              <p className={styles.groupTitle}>EMAIL</p>
              <a
                href={`mailto:${candidate.user.email}`}
                className={styles.groupLink}
              >
                {candidate.user.email}
              </a>
            </div>
            {
              candidate.phone &&
              <div className={styles.group}>
                <p className={styles.groupTitle}>MOBILE</p>
                <a
                  href={`tel:${candidate.phone}`}
                  className={styles.groupLink}
                >
                  {candidate.phone}
                </a>
              </div>
            }
          </div>
          <div>
            <p>
              Reach out using the details below to see if this candidate is a good match for your organisation.
              Only pay when you&apos;ve found that perfect fit.
            </p>
            <p>When you&apos;re ready, make an offer to the candidate on their application page so we can get them placement ready!</p>
          </div>
        </div>
      </Content>
      <ButtonGroup>
        <button className="btn btn-primary" onClick={onClose}>Close</button>
      </ButtonGroup>
    </Modal>
  );
}
