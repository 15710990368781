import React, {useEffect, useState} from "react";
import _ from "lodash";

import {useAlerts, messages} from "components/shared/AlertsProvider";
import LoadingSpinner from "components/shared/LoadingSpinner";
import Modal from "components/shared/Modal/Modal";
import {ButtonGroup, Content, Header} from "components/shared/Slate";
import RadioButtons from "components/shared/Form/RadioButtons";
import {useDeclinedReasonsQuery, useSelfQuery} from "services/queries";
import api from "services/v3";
import PulseLoader from "react-spinners/PulseLoader";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
import {status} from "util/placement/constants";
import {getActiveFeatures, getName} from "util/placement/activeFeatures";
import Button from "components/shared/Button";


import baseStyles from "./disqualifyModal.module.scss";


const styles = mergeClassNames(baseStyles, modalTheme);


export default function DisqualifyModal({candidate, open, ...modalProps}) {
  if (!open) {
    return null;
  }

  const [addAlert] = useAlerts();
  const {data: me} = useSelfQuery();
  const {data: reasons, isFetched, isLoading} = useDeclinedReasonsQuery({
    enabled: open,
    onError() {
      addAlert("decline", "danger", "Could not load the decline options. " + messages.ifPersists);
    },
  });

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedReasonOther, setSelectedReasonOther] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Reset when a different candidate is actioned.
  useEffect(reset, [candidate]);

  function reset() {
    setHasSubmitted(false);
    setSelectedReason(null);
    setSelectedReasonOther(null);
  }

  function handleDisqualify() {
    setIsProcessing(true);
    const data = {
      status: status.DECLINED,
      reason_declined: selectedReason,
    };
    if (selectedReason === "Other") {
      data.reason_declined_other = selectedReasonOther;
    }
    api.updateHostPlacement(candidate.placementID, data)
      .then(() => {
        setHasSubmitted(true); setIsProcessing(false);
      })
      .catch(e => {
        console.log(e); setIsProcessing(false);
      });
  }

  if (hasSubmitted) {
    const activeFeatures = getActiveFeatures(me, candidate);
    const name = getName({activeFeatures, user: candidate.user});
    return (
      <Modal theme={styles} className={styles.success} open={open}
        {...modalProps}
      >
        <Header className={styles.header}>
          You have successfully declined {name}
        </Header>
        <Content className={styles.content}>
          <p>
            We have let {name} know that you will not move further
            with them for this placement.
          </p>
        </Content>
      </Modal>
    );
  }

  return (
    <Modal theme={styles} open={open} {...modalProps}>
      <Header className={styles.header}>Decline Applicant</Header>

      <Content className={styles.content}>
        <h4 className={styles.initials}>{candidate.user.initials}</h4>&bull; <span className={styles.projectName}>{candidate.project}</span>

        <p className="mb-0">We will inform the candidate that you have decided not to proceed with their application. </p>
        <p>Please let Superpower know more about your reasons so that we can improve your recommendations in future.</p>
        <div className={styles.greyBorder} />
        {
          !isFetched || isLoading ?
            <LoadingSpinner className={styles.spinner}/> :
            <RadioButtons
              baseId="reason-"
              data={reasons}
              name="reason"
              onChange={setSelectedReason}
              position="left"
              theme={_.pick(styles, ["checker", "checked"])}
              value={selectedReason}
            />
        }
        {selectedReason === "Other" && (
          <textarea
            className={styles.textarea}
            onChange={e => {
              setSelectedReasonOther(e.target.value);
            }}
            rows="4"
            cols="110"
            value={selectedReasonOther}
          >
          </textarea>
        )}

      </Content>

      <ButtonGroup className={styles.buttonGroup}>
        { isProcessing ?
          <PulseLoader color="#FF6767" margin={2} size={6}/>
          :
          <Button
            kind="primary"
            disabled={isProcessing || !selectedReason || (selectedReason === "Other" && !selectedReasonOther)}
            onClick={handleDisqualify}
            type="button"
            style={{ height: "2.875rem"}}
          >
            Decline Applicant
          </Button>
        }
      </ButtonGroup>
    </Modal>
  );
}
