import React, {useState} from "react";
import classNames from "classnames";
import _ from "lodash";
import "primeicons/primeicons.css";
import PulseLoader from "react-spinners/PulseLoader";

import api from "services/v3";
import {Content} from "components/shared/Slate";
import Modal from "components/shared/Modal/Modal";
import styles from "components/shared/Modal/acceptModal.module.scss";
import {
  Input,
  Heading,
  RadioGroup,
  Button,
} from "workfinder-components";

export default function SuggestDeclineModal({candidate, onClose, open, ...modalProps}) {
  if (!open) {
    return null;
  }

  const [reason, setReason] = useState("");
  const [otherText, setOtherText] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSuggestDecline() {
    const interview_uuid = candidate.interview?.uuid;
    const data = {
      placement: candidate.placementID,
      interview: interview_uuid,
      feedback: reason,
      other_text: otherText,
      feedback_type: "negative",
    };
    setIsLoading(true);
    api.collectCollaboratorFeedback(data).then(response => {
      setShowThankYou(true);
      setIsLoading(false);
    });

  }
  const modalBody = <>
    <div className={styles.descriptionTile}>
      <p className={styles.tileDescription}>
              Please select the reason why you suggest to decline this candidate from progressing.
We will inform the role owner that you suggest to not proceed with this candidate’s application.
      </p>
    </div>
    <RadioGroup
      options={[
        {
          label: "This candidate’s cover letter is not good enough",
          value: "This candidate’s cover letter is not good enough",
        },
        {
          label: "This candidate doesn’t have the experience for this position",
          value: "This candidate doesn’t have the experience for this position",
        },
        {
          label: "This candidate doesn’t have the skills for this position",
          value: "This candidate doesn’t have the skills for this position",
        },
        {
          label: "This candidate isn’t available at the times needed for this position",
          value: "This candidate isn’t available at the times needed for this position",
        },
        {
          label: "We have selected another candidate for this position",
          value: "We have selected another candidate for this position",
        },
        {
          label: "We don’t have an open role at the moment",
          value: "We don’t have an open role at the moment",
        },
        {
          label: "I am not the right person to approve this at my company",
          value: "I am not the right person to approve this at my company",
        },
        {
          label: "Other",
          value: "Other",
        },
      ]}
      vertical={true}
      setValue={(value) => {
        setReason(value);
      }}
      value={reason}
    >
    </RadioGroup>

    {reason == "Other" && <Input
      label="Custom reason"
      long
      onChange={(value) => setOtherText(value)}
      placeholder=""
      type={{}}
    />}
    <Button
      disabled={!reason || (reason === "Other" && !otherText)}
      className="ml-auto mb-0"
      onClick={handleSuggestDecline}
    >
      {isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Share feedback with role owner"}
    </Button>
  </>;
  const thankYou = <>
    <p className={classNames(styles.tileDescription, styles.borderless)}>
    Thank you, we have shared your feedback to decline the candidate with the role owner.
    </p>
    <Button
      className="ml-auto mb-0"
      onClick={onClose}
      size="small"
    >
      Close
    </Button>
  </>;
  return (
    <Modal open={open} onClose={onClose} {...modalProps} className={styles.suggestDeclineModal}>
      <Content className={styles.content}>
        <Heading className={classNames(styles.offerRole, "mb-1")} size="h4">Decline Candidate</Heading>
        <Heading size="h5" className="d-inline-block">{candidate.user.full_name || candidate.interview?.candidate.user.full_name}</Heading>
        <span className={classNames("d-inline-block", styles.projectName)}>• {candidate.project || candidate.associated_project_name}</span>
        <div className="row">
          <div className="col">
            {showThankYou ? thankYou: modalBody}
          </div>
        </div>
      </Content>
    </Modal>
  );
}
