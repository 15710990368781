export const PLAN_ANNUAL_ID = "Talent-Magnet-GBP-Yearly";
export const PLAN_MONTHLY_ID = "Talent-Magnet-GBP-Monthly";
export const PLAN_ADDON_ID = "Add-on-GBP-Monthly";
/* TODO: Remove above plans */

export const PLAN_TEAM_ID = "Team";
export const PLAN_BUSINESS_ID = "Business";
export const PLAN_FREE_ID = "Free";
export const ADDON_DASHBOARDS_TO_EVALUATE_CANDIDATES = "Dashboards-to-evaluate-candidates-GBP-Monthly";
export const ADDON_MESSAGING_WITH_CANDIDATES = "Messaging-with-candidates-GBP-Monthly";
export const ADDON_INADVERTENT_BIAS_ALERTS = "Inadvertent-bias-alerts-GBP-Monthly";
export const ADDON_BOOST_24_HOUR_SHORTLIST = "Boost---24-hour-shortlist-GBP-Monthly";
export const ADDON_COLLABORATE_WITH_COLLEAGUES = "Collaborate-with-colleagues-GBP-Monthly";
export const ADDON_ENHANCED_OPPORTUNITY_BRIEFINGS = "Enhanced-opportunity-briefings-GBP-Monthly";
export const ADDON_CANDIDATE_DASHBOARD_BRANDING = "Candidate-dashboard-branding-GBP-Monthly";
export const ADDON_BALANCE_YOUR_WORKFORCE = "Balance-your-workforce-GBP-Monthly";
export const ADDON_TALENT_ADVANCE_GENTLE_LETDOWN_SERVICE = "Talent-Advance-Gentle-let-down-service-GBP-Monthly";
