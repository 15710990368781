import React, {useState} from "react";
import {Heading, Subtitle} from "workfinder-components";
import {useMyCandidateQuery, DISABLE_REFETCH_OPTIONS} from "services/queries";
import styles from "./welcomeCard.module.scss";
import classNames from "classnames";
import useStoredState from "hooks/useStoredState";

const WelcomeCard = ():JSX.Element => {
  const {data: candidateCounts, isLoading: isCountLoading} = useMyCandidateQuery({}, {...DISABLE_REFETCH_OPTIONS, enabled: Variables.uuids.candidate ? true : false, initialData: {}});
  const [storedStateHideCard, setStoredState, removeStoredState] = useStoredState(
    false, `hide-welcome-card-${Variables.uuids.candidate}`, Variables.uuids.user, {storage: localStorage},
  );
  const [showCard, setShowCard] = useState(true);
  let isEmptyDashboard = true;
  if (!isCountLoading) {
    isEmptyDashboard = !(candidateCounts.isApplied || candidateCounts.isFeedbackGiven || candidateCounts.isRoleRecommended || candidateCounts.isCourseRecommended || candidateCounts.isMasterClassRecommended);
  }
  const hideCard = () => {
    setShowCard(false);
    setStoredState(true);
  };
  return <div className={classNames((!isCountLoading && isEmptyDashboard && showCard && !storedStateHideCard) ? "" : styles.hide, styles.welcomeCard, "card mt-1 mb-3")}>
    <div className="card-body">
      <Heading size="h3" className="mb-2 d-flex justify-content-between">👋 Hello!
        <a onClick={hideCard} style={{color: "#9EA7B8"}}>
          <span className="material-icons">close</span>
        </a>
      </Heading>
      <Subtitle className="card-text">Welcome to your fully personalized dashboard, designed to help you build your skills and achieve your goals. The more you interact with it, the more personalized it becomes, offering tailored recommendations crafted just for you. Let's get started on your journey to success!</Subtitle>
    </div>
  </div>;
};

export default WelcomeCard;
