/**
 * A form component that keeps a state representing all inputs in the form
 * through a context API.
 */
import React, {
  createContext,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import {Map} from "immutable";
import _ from "lodash";
import PropTypes from "prop-types";

import {useAlerts} from "components/shared/AlertsProvider";
import {flatten} from "util/object";

// A union type for all HTML elements that support the validation API.
export type HTMLValidatableElement =
  | HTMLInputElement
  | HTMLFieldSetElement
  | HTMLObjectElement
  | HTMLOutputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

// A union type for all form controls that support arbitrary text input.
export type HTMLTextInputElement = HTMLInputElement | HTMLTextAreaElement;


export interface ValidatorResult {
  isValid: boolean;
  reason?: string;
}

// The props used by the Form context to decide if the form is good.
export interface ValidationProps {
  // Whether the form control's value is valid.
  isValid: boolean;
  // A custom validation function.
  validator?: (value: any) => ValidatorResult;
  // The name of the form control.
  name: string;
  /*
   * A reference to the form control's HTML element, providing access to the
   * browser's constraint validation API.
   */
  ref?: RefObject<HTMLValidatableElement>;
  // The value of the form control.
  value: any;
}

export interface FormContextProps {
  // Sets the validation props for a form control with the given name.
  setInput: (name, ValidationProps) => void;
  // A map of all form controls to their validation props.
  inputs: Map<string, ValidationProps>;
  // Whether the entire form is valid.
  isValid: boolean;
  // Whether the use has tried submitting the form.
  attemptedSubmit: boolean;
}

export const FormContext = createContext<FormContextProps>({
  setInput: () => { },
  inputs: Map(),
  isValid: true,
  attemptedSubmit: false,
});

function toHumanReadable(fieldName) {
  const parts = fieldName.split(".");
  const words = parts[parts.length - 1].split("_");
  words[0] = _.capitalize(words[0]);
  return words.join(" ");
}

/**
 * A form element providing context for automatic form validation.
 */
function Form({
  children,
  className,
  errorClassName,
  onSubmit,
  onValidChange,
  validClassName,
  ...props
}): JSX.Element {
  const [inputs, setInputs] = useState(Map<string, ValidationProps>());
  const isValid = useMemo(() => inputs.every((input) => input.isValid), [
    inputs,
  ]);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const formClassName = useMemo(updateClassName, [isValid]);
  const [addAlert] = useAlerts();

  const setInput = useCallback((name, o) => {
    setInputs(inputs => {
      if (o === undefined) {
        return inputs.delete(name);
      }
      else {
        return inputs.set(name, o);
      }
    });
  }, [setInputs]);

  function updateClassName() {
    return classNames(className, isValid ? "valid" : "invalid", {
      "has-error": !isValid && attemptedSubmit,
      [errorClassName]: !isValid && errorClassName,
      [validClassName]: isValid && validClassName,
    });
  }

  function setValidationErrors() {
    setInputs(inputs => {
      for (const [k, v] of inputs.entries()) {
        const errors = [];

        // Check if custom validation set a reason.
        const reason = v.validator?.(v.value).reason;
        if (reason) {
          errors.push(reason);
        }

        // Check browser validation.
        if (v.ref?.current?.validity && !v.ref.current.checkValidity()) {
          const validity = v.ref.current.validity;
          if (validity.badInput) {
            errors.push("Please enter a valid value.");
          }
          if (validity.customError) {
            errors.push(v.ref.current.validationMessage);
          }
          if (validity.patternMismatch) {
            errors.push("Please match the format requested.");
          }
          if (validity.rangeOverflow) {
            const numberInput = v.ref.current as HTMLInputElement;
            errors.push(
              `Value must be less than or equal to ${numberInput.max}`,
            );
          }
          if (validity.rangeUnderflow) {
            const numberInput = v.ref.current as HTMLInputElement;
            errors.push(
              `Value must be greater than or equal to ${numberInput.min}`,
            );
          }
          if (validity.stepMismatch) {
            const numberInput = v.ref.current as HTMLInputElement;
            const value = parseFloat(numberInput.value);
            const min = parseFloat(numberInput.value) || 0;
            const step = parseFloat(numberInput.step) || 1;
            const lowerValid = min + Math.floor((value - min) / step) * step;
            const upperValid = min + Math.ceil((value - min) / step) * step;
            errors.push(
              `Please enter a valid value. The two nearest valid values are ${lowerValid} and ${upperValid}.`,
            );
          }
          if (validity.tooLong) {
            const textInput = v.ref.current as HTMLTextInputElement;
            errors.push(
              `Please shorten this text to ${textInput.maxLength} characters or less.`,
            );
          }
          if (validity.tooShort) {
            const textInput = v.ref.current as HTMLTextInputElement;
            errors.push(
              `Please lengthen this text to ${textInput.minLength} characters or more.`,
            );
          }
          if (validity.typeMismatch) {
            errors.push("Please enter a valid value.");
          }
          if (validity.valueMissing) {
            errors.push("Please fill in this field.");
          }
        }
        if (errors.length) {
          inputs = inputs.setIn([k, "errors"], errors);
        }
      }
      return inputs;
    });
  }

  /**
   * Passed to the user in onSubmit to provide a mechanism of reporting API
   * feedback back to the form. The expected format is {inputName: ["Error message"]}.
   * Any errors for unknown inputs will be combined and reported through an alert
   * and sent to Sentry as an error, since all errors should be attributable to a field.
   */
  function setAPIErrors(errors) {
    const unmatchedAPIErrors = [];
    for (let [k, messages] of Object.entries(flatten(errors))) {
      messages = messages.map(_.capitalize);
      if (inputs.has(k)) {
        setInputs((inputs) => inputs.setIn([k, "errors"], messages));
      }
      else {
        unmatchedAPIErrors.push(...messages.map((message) => [k, message]));
      }
    }
    /*
     * Note: because sometimes there are multiple forms on separate pages, an
     * unmatched error could be part of a separate form. These should share the
     * same Form context but currently don't so this error logging would be
     * excessive
     */
    /*
    if (unmatchedAPIErrors.length) {
      Sentry.captureException(
        new Error("The API returned errors that could not be tied to an input and a generic error message was shown"),
        {
          contexts: {
            "Unmatched API errors": unmatchedAPIErrors,
          },
        }
      );
    }
    */

    if (unmatchedAPIErrors.length) {
      let alertMessage;
      if (unmatchedAPIErrors.length > 1) {
        alertMessage = (
          <ul>
            {unmatchedAPIErrors.map(([k, message]) => (
              <li key={`${k}-${message}`}>
                <span style={{fontWeight: "bold"}}>
                  {toHumanReadable(k)}:
                </span>{" "}
                {message}
              </li>
            ))}
          </ul>
        );
      }
      else {
        const [k, message] = unmatchedAPIErrors[0];
        alertMessage = (
          <>
            <span style={{fontWeight: "bold"}}>{toHumanReadable(k)}:</span>{" "}
            {message}
          </>
        );
      }

      addAlert("unmatched-api-errors", "danger", alertMessage);
    }
  }

  const contextValue = {
    setInput: useCallback(setInput, [setInputs]),
    inputs,
    isValid,
    attemptedSubmit,
  };

  function clearErrors() {
    setInputs((inputs) => {
      for (const k of inputs.keys()) {
        inputs = inputs.deleteIn([k, "errors"]);
      }
      return inputs;
    });
  }

  /**
   * Handles submission of forms and only forwards the event to `props.onSubmit`
   * when all fields are valid. `props.onSubmit` will then get passed setAPIErrors
   * to serve as a feedback mechanism for any potential API errors.
   */
  function handleSubmit(e) {
    clearErrors();
    setAttemptedSubmit(true);
    e.preventDefault();
    if (!isValid) {
      setValidationErrors();
      return false;
    }
    const data = inputs.map((input) => input.value);
    onSubmit(data, setAPIErrors);
    return false;
  }

  function handleValidChange() {
    if (onValidChange) {
      onValidChange(isValid);
    }
  }

  useEffect(handleValidChange, [isValid]);

  return (
    <form onSubmit={handleSubmit} className={formClassName} {...props}
      noValidate
    >
      <FormContext.Provider value={contextValue}>
        {children}
      </FormContext.Provider>
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onValidChange: PropTypes.func,
  errorClassName: PropTypes.string,
  validClassName: PropTypes.string,
  props: PropTypes.object,
};

export default Form;
