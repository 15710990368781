import React, {useState} from "react";
import classNames from "classnames";
import {mergeClassNames} from "styles/utils";
import Modal from "components/shared/Modal/Modal";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./pauseProjectModal.module.scss";
import {Content, Header} from "components/shared/Slate";
import {useProjectMutation} from "services/mutations";
import {CheckboxGroup, Input, Button} from "workfinder-components";
import _ from "lodash";
import PulseLoader from "react-spinners/PulseLoader";

import pauseIconWhite from "/static/workfinder/images/icons/paused_white.svg";

const styles = mergeClassNames(modalTheme, baseStyles);
const options = [
  {
    label: <div>Amount of applications: We have received too many applications and need time to process these before receiving new ones.</div>,
    value: "too_many_applications",
  },
  {
    label: <div>Lack of quality applications: We want to pause this role temporarily because we haven’t received applications with the quality we were looking for.</div>,
    value: "lack_of_quality",
  },
  {
    label: <div>The role is filled: We have filled the role and are not looking for more applicants.</div>,
    value: "role_filled",
  },
  {
    label: <div>Other</div>,
    value: "other",
  },
];
const OTHER_OPTION = "other";
export default function PauseProjectModal({projectUUID, roleName="", onClose, open, refetch}) {

  const [roleTitle, setRoleTitle] = useState(roleName);
  const [checkedOptions, setCheckedOptions] = useState(["other"]);
  const [otherText, setOtherText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const projectMutation = useProjectMutation(projectUUID);
  const projectUrl = Variables.urls.projects.detail.replace(/<uuid(:uuid)?>/, projectUUID);

  const updateProjectStatus = () => {
    setIsLoading(true);
    projectMutation.mutateAsync({
      status: "paused",
      paused_reason: checkedOptions,
      paused_reason_other: otherText,
    })
      .then(project => {
        setIsLoading(false);
        refetch();
        onClose();
      })
      .catch(errors => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      id="paused-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={classNames(styles.mobileBottom, styles.extraTall)}
    >
      <Header className={styles.header}>
        <h3 className="mb-0">Are you sure you want to pause the marketing of <a href={projectUrl} target="_blank" rel="noreferrer">{roleName}</a>?
        </h3>
      </Header>
      <Content className="pt-0">
        <div className={styles.content}>
          <p className={styles.description}>
          When you pause the marketing of your role:<br/>
            <ul className={styles.descriptionList}>
              <li>We will stop marketing your role to candidates who match your role's skill requirements.</li>
              <li>Candidates will still be able to apply to your role.</li>
            </ul>
          If you would like to still pause your role, please select the reasons below:
          </p>

          <CheckboxGroup
            checkedOptions={checkedOptions}
            options={options}
            setCheckedOptions={setCheckedOptions}
            className={styles.gap}
          >
          </CheckboxGroup>
          {checkedOptions.includes(OTHER_OPTION) ? (
            <Input
              placeholder="Why would you like to pause your role? Please add your reasons here."
              className="mt-3"
              value={otherText}
              onChange={setOtherText}
              long
            />
          ) : null}

          <Button
            className="mt-3 float-right"
            kind="primary"
            onClick={() => {
              if (!checkedOptions.includes(OTHER_OPTION)) {
                setOtherText("");
              };
              updateProjectStatus();
            }}
            type="button"
            disabled={!checkedOptions.length || (checkedOptions.includes(OTHER_OPTION) && !otherText)}
          >
            {isLoading ? <PulseLoader color="#FFF" margin={2} size={6}/> : <><img className="img-fluid mr-2" src={pauseIconWhite} /> Pause marketing</>}
          </Button>
          <Button
            className="mt-3 float-right mr-3"
            kind="secondary"
            onClick={() => {
              onClose();
            }}
            type="button"
          >
            Cancel
          </Button>
        </div>
      </Content>
    </Modal>
  );
}
