import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import * as yup from "yup";
import classNames from "classnames";
import moment from "moment";
import {Map} from "immutable";
import PulseLoader from "react-spinners/PulseLoader";
import {
  Body,
  AutoSaving,
  Input,
  Heading,
  DatePicker,
  RadioGroup,
  Button,
  RichTextEditor,
  Subtitle,
  NumberInput,
  LocationSearch,
  Checkbox,
} from "workfinder-components";
import api from "services/v3";
import ErrorMessage from "components/bespoke-project/pages/ErrorMessage";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {falsyMerge} from "util/helpers";
import useTrackedState from "hooks/useTrackedState";
import {
  useProjectTemplatesQuery,
  useSelfUpToCompanyQuery,
  useProjectQuery,
  DISABLE_REFETCH_OPTIONS,
  useProjectPrescreenQuestionsQuery,
  usePartnerPlanQuery,
  useLocationQuery,
} from "services/queries";
import {combineQueries} from "util/query";
import {
  useProjectMutation,
  useLocationMutation,
  useLocationCreateMutation,
  useCompanyMutation,
} from "services/mutations";
import ProjectPreview from "components/bespoke-project/ProjectPreview";
import warning from "/static/workfinder/images/warning.png";
import magnifyingglass from "/static/workfinder/images/magnifyingglass.png";
import stars from "/static/workfinder/images/stars.png";
import {
  apiErrorToMapping,
  scrollToErrors,
  yupErrorToMapping,
} from "util/errors";
import {APIError} from "errors";
import baseStyles from "./index.module.scss";
import modalTheme from "styles/modals/flat.module.scss";
import fullPageTheme from "styles/modals/fullPage.module.scss";
import {mergeClassNames} from "styles/utils";
import FormLabel from "./FormLabel";
import PaymentInfoModal from "./PaymentInfoModal";
import PropTypes from "prop-types";
import {PAYMENT_CYCLE, planNames} from "components/my-account/constants/UpgradeToPremium";
import Modal from "components/shared/Modal/Modal";
import { DuedilAsyncTypeaheadSignUp } from "components/shared/DuedilAsyncTypeahead";
import { getAddressParts } from "util/string";
import { EventInterface } from "util/events";
import Content from "components/shared/Slate/Content";
import { locationPurpose, locationType } from "util/companies/constants";
const BESPOKE_PROJECT = "Custom";

const styles = mergeClassNames(baseStyles, mergeClassNames(modalTheme, fullPageTheme));

export const COMPENSATION_OPTIONS = [
  {
    label: "Paid",
    value: true,
  },
  {
    label: "Voluntary",
    value: false,
  },
];

export const PROJECT_LENGTH_OPTIONS = [
  {
    label: "1 week",
    value: "1 week",
    group: "1",
  },
  {
    label: "2 weeks",
    value: "2 weeks",
    group: "1",
  },
  {
    label: "3-4 weeks",
    value: "3-4 weeks",
    group: "1",
  },
  {
    label: "1-3 months",
    value: "1-3 months",
    group: "2",
  },
  {
    label: "3-6 months",
    value: "3-6 months",
    group: "2",
  },
  {
    label: "12 months",
    value: "7-12 months",
    group: "2",
  },
  {
    label: "Apprenticeship",
    value: "apprenticeship",
    group: "3",
  },
  {
    label: "Permanent",
    value: "permanent contract",
    group: "3",
  },
  {
    label: "Contract",
    value: "contract",
    group: "3",
  },
];

export const PROJECT_LENGTH_values = [
  "1 week",
  "2 weeks",
  "3-4 weeks",
  "1-3 months",
  "3-6 months",
  "7-12 months",
  "apprenticeship",
  "permanent contract",
  "contract",
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  {label: "Full-time", value: "Full-time"},
  {label: "Part-time", value: "Part-time"},
  {label: "Custom", value: "Custom"},
];

export const DAY_PERIOD_OPTIONS = [
  {
    label: "Mornings (9am-12 pm)",
    value: "morning",
  },
  {
    label: "Afternoons (12-5 pm)",
    value: "afternoon",
  },
  {
    label: "Evenings (5-9 pm)",
    value: "evening",
  },
  {
    label: "Night-time (After 9pm)",
    value: "night",
  },
  {
    label: "Working periods are flexible",
    value: "flexible",
  },
];

export const LOCATION_TYPE_OPTIONS = [
  {label: "Remote", value: "remote"},
  {label: "Hybrid", value: "hybrid"},
  {label: "On location", value: "on location"},
];

export const FROM_TEMPLATE = [
  "about_candidate",
  "activities",
  "candidate_activities",
  "skills_acquired",
  "wef_skills",
  "skills_builder_skills",
  "tech_skills",
];

const SUPER_RECRUITER_PLANS = [
  "Talent-Magnet-GBP-Monthly",
  "Talent-Magnet-GBP-Yearly",
];

const commonSchema = {
  type: yup
    .string()
    .default(BESPOKE_PROJECT),
  name: yup.string()
    .required()
    .max(128)
    .label("Project Title"),

  employment_type: yup
    .string()
    .oneOf(EMPLOYMENT_TYPE_OPTIONS.map(option => option.value))
    .default(EMPLOYMENT_TYPE_OPTIONS[0].value)
    .label("Working Hours"),
  is_flexible_dates: yup.boolean().default(true).label("Flexible dates"),
  length: yup
    .string()
    .oneOf(PROJECT_LENGTH_values)
    .default(PROJECT_LENGTH_values[3])
    .label("length"),
  start_date: yup
    .date()
    .required()
    // .min(moment().startOf("day"), ({min}) => `Date needs to be after (${moment(min).format("L")})`)
    .label("Start Date"),
  end_date: yup
    .date()
    .nullable()
    .when(
      "is_no_end_date",
      {
        is: true,
        then: schema => schema.optional(),
        otherwise: schema => schema.required(),
      },
    )
    .label("end_date"),
  is_no_end_date: yup.boolean()
    .default(false)
    .label("is_no_end_date"),
  working_duration_hours_per_week: yup.number()
    .when(
      "employment_type",
      {
        is: "Custom",
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      },
    )
    .nullable()
    .label("working_duration_hours_per_week"),
  is_paid: yup.boolean()
    .required()
    .default(true)
    .label("Compensation"),
  location_preference: yup
    .string()
    .oneOf(LOCATION_TYPE_OPTIONS.map(option => option.value))
    .default(LOCATION_TYPE_OPTIONS[1].value)
    .label("Project Location"),
};

const schema = yup.object().shape({
  ...commonSchema,
  description: yup.string()
    .required()
    .default("")
    .label("Project Description"),
  pre_screen_questions: yup
    .array()
    .label("Pre-Screen Questions")
    .default([]),
  voluntaryWarning: yup.boolean().required().oneOf([false]),
  locationWarning: yup.boolean().required().oneOf([false]),
});

const project_schema = yup.object().shape(commonSchema);

interface CreateRoleData {
  /** The callback to call when the modal is closed. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
  companyUuid?: string;
  projectUuid?: string;
}

export const createRoleModalInterface = new EventInterface<CreateRoleData>("createRoleModal");

function PreScreenQuestion({question, updateQuestion, number}):JSX.Element {
  return (
    <div className="d-flex">
      <Subtitle className="pt-2">{number}.</Subtitle>
      <Input
        placeholder="Add your pre-screen question here"
        value={question.question}
        maxlength="255"
        onChange={(value) => {
          updateQuestion({...question, question: value});
        }}
        className={classNames(styles.questionInput, "w-100")}
      ></Input>
    </div>
  );

}

function PreScreenQuestions({project, handleProjectChange, projectUUID, isSaveButton, pre_screen_questions=[]}):JSX.Element {
  const [questions, setQuestions] = useState([
    {question: ""}, {question: ""},
  ]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [checkPlan, setCheckPlan] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);

  const defaultStoredState = {
    name: planNames.BUSINESS,
    description: "For advanced recruiting",
    icon: "🛸",
    billingCycle: PAYMENT_CYCLE.ANNUM,
    chargebeeIds: {
      monthly: "Talent-Magnet-GBP-Monthly",
      annually: "Talent-Magnet-GBP-Yearly",
    },
  };

  const prescreenQuestionsQuery = useProjectPrescreenQuestionsQuery(
    {
      project_uuid: projectUUID,
    },
    {
      enabled: !!projectUUID,
      onSuccess: (data) => {
        if (data.length > 0) {
          setQuestions(data.map(item => item.question));
        }
      },
    },
  );

  const {data: partnerPlanData} = usePartnerPlanQuery({
    refetchInterval: checkPlan ? 800 : false,
    enabled: !stopFetching,
    onSuccess: (response) => {
      if (response.plan_id) {
        setStopFetching(true);
        setCheckPlan(false);
      }
    },
  });
  const plan = partnerPlanData?.plan_id ?? "";

  const handleQuestionChange = (index, value) => {
    const updatedValues = [...questions];
    updatedValues[index] = value;
    setQuestions(updatedValues);
    handleProjectChange(updatedValues, "pre_screen_questions");
  };

  const addEmptyQuestion = () => {
    if (!questions.some(question => question.length === 0)) {
      setQuestions([...questions, {question: ""}]);
    }
  };

  function submitQuestions() {
    const payload_questions = questions.filter((question) => question.question !== "");

    const payload = {
      questions: payload_questions,
      project: projectUUID,
    };

    api.createProjectPrescreenQuestions(payload).then(
      (response) => {
        console.log(response);
      },
    );
  }

  async function openUpgrade() {
    setShowUpgradeModal(true);
  }

  if (!SUPER_RECRUITER_PLANS.includes(plan) || checkPlan) {
    return (
      <div className={styles.upgradeCard}>
        <div className={styles.upgradeCardBlock}>
          <div className="row">
            <div className="col-9">
              <Heading size="h4" className="mb-2">Pre-Screen Questions</Heading>
              <Body className="mb-2">Boost hiring with pre-screen questions: filter swiftly, gauge fit, and enrich interviews. </Body>
              <Button
                kind="secondary"
                size="small"
                onClick={openUpgrade}
              >{checkPlan ? "Loading feature": "Upgrade to Super Recruiter"}</Button>
            </div>
            <div className="col-3">
              <img className={classNames(styles.magnifyingglass, "img-fluid")} src={magnifyingglass}></img>
            </div>
          </div>
        </div>
        <PaymentInfoModal
          onClose={() => {
            setShowUpgradeModal(false);
          }}
          open={showUpgradeModal}
          successCallback={() => setCheckPlan(true)}
          planData={defaultStoredState}
        />
      </div>
    );
  }

  if (!!projectUUID && prescreenQuestionsQuery.isLoading) {
    return (<></>);
  }

  return (<FormLabel
    labelClassName={styles.prescreenQuestions}
    title="Pre-Screen Questions"
    subTitleClass="mb-2"
    subTitle="Boost hiring with pre-screen questions: filter swiftly, gauge fit, and enrich interviews. "
    tooltip={
      <div>
        <Heading size="h5">Pre-screen Questions 101</Heading>
        <Body>
          Think of pre-screen questions as your first chat with candidates, helping you quickly figure out if they might be a good fit. The candidate will see them and submit their answer before applying.
        </Body>
        <Heading size="h5">Why are pre-screens my secret weapon?</Heading>
        <Body>
          <ul>
            <li>
              They&apos;re your trusty sidekick in navigating the recruiting maze — helping you swiftly spot the right candidates.
            </li>
            <li>
              They help keep your evaluations consistent.
            </li>
            <li>
              Plus, they give you a sneak peek into the candidate, making your interviews even more meaningful!
            </li>
          </ul>
        </Body>
        <Heading size="h5">Tips for great pre-screen questions</Heading>
        <Body>
          <ol>
            <li>Keep it Simple: No jargon - just clear, friendly questions.</li>
            <li>Focus on the Must-haves: What&apos;s really key for the role?</li>
            <li>Be Neutral: Ask without hinting at what you want to hear.</li>
            <li>Go for the Real Deal: Prioritize actual skills and experiences.</li>
            <li>All are Welcome: Make sure they&apos;re inclusive and fair to everyone.</li>
          </ol>
        </Body>
      </div>
    }
  >
    {questions && questions.map(
      (value, index ) => {
        return (<><PreScreenQuestion
          question={value}
          updateQuestion={(v) => {
            handleQuestionChange(index, v);
          }}
          number={index+1}
        />
        </>);
      },
    )}
    <div className="d-flex">
      <Button className={classNames(styles.addButton, "mr-auto")} kind="secondary" size="small" onClick={addEmptyQuestion}>+ Add Question</Button>
      {isSaveButton && <Button size="small" className={styles.saveButton} onClick={submitQuestions}>Save Question</Button>}
    </div>

  </FormLabel>);
}

export function ProjectBasic({
  project,
  setProject,
  location,
  setLocation,
  templates,
  errors,
  savingFields,
  errorFields,
  isCandidatesRequested,
  isCompensationRadio,
  isLocation,
  projectUUID,
  isSaveQuestions,
}): JSX.Element {

  const [voluntaryWarning, setVoluntaryWarning] = useState(false);

  const [workingHoursPerWeek, setWorkingHoursPerWeek] = useState();

  const [isGPTbuttonDisabled, setIsGPTbuttonDisabled] = useState(true);

  function handleProjectChange(value, name) {
    setProject(data => data.set(name, value));
  }

  const getSavingStates = (name : string) => {
    return {
      isSaving: savingFields.includes(name),
      isError: errorFields.includes(name),
    };
  };

  const calculateDateDifference = () => {
    const startDateMoment = moment(project.get("start_date"));
    const endDateMoment = moment(project.get("end_date"));

    if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
      return false;
    }
    else {
      const daysDifference = endDateMoment.diff(startDateMoment, "days");
      return daysDifference;
    }
  };

  const validate_voluntary = () => {
    if (project.get("is_paid")) {
      return true;
    }
    const days = calculateDateDifference();
    let hoursPerDay = 8;
    if (project.get("employment_type") == "Part-time") {
      hoursPerDay = 4;
    }
    else if (project.get("working_duration_hours_per_week") && project.get("employment_type") == "Custom") {
      hoursPerDay = project.get("working_duration_hours_per_week")/5;
    }
    else if (project.get("employment_type") == "Full-time") {
      hoursPerDay = 8;
    }
    else {
      return false;
    }
    const totalHours = Math.round((hoursPerDay * days));
    if (totalHours < 150) {
      return true;
    }
    return false;
  };

  const validate_location = () => {
    if (project.get("location_preference") === "remote") {
      return true;
    }
    if (location && Object.keys(location.toJSON()).length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    switch (project.get("employment_type")) {
    case "Full-time":
      setWorkingHoursPerWeek(40);
      break;
    case "Part-time":
      setWorkingHoursPerWeek(20);
      break;
    default:
      setWorkingHoursPerWeek(project.get("working_duration_hours_per_week"));
    }
  }, [project]);

  useEffect(() => {
    handleProjectChange(workingHoursPerWeek, "working_duration_hours_per_week");
  }, [workingHoursPerWeek]);

  useEffect(() => {
    const validated_voluntary = validate_voluntary();
    if (!validated_voluntary) {
      setVoluntaryWarning(true);
      handleProjectChange(true, "voluntaryWarning");
    }
    else {
      setVoluntaryWarning(false);
      handleProjectChange(false, "voluntaryWarning");
    }
    const isLocationValid = validate_location();
    handleProjectChange(!isLocationValid, "locationWarning");
  }, [project, location]);

  useEffect(() => {
      if (project.get("employment_type") == "Full-time") {
        setWorkingHoursPerWeek(40);
      }
      else if (project.get("employment_type") == "Part-time") {
        setWorkingHoursPerWeek(20);
      }
  }, [project]);

  function validate() {
    return project_schema.validate(project.toJSON(), {strict: true, abortEarly: true})
      .then(() => {
        setIsGPTbuttonDisabled(false);
        return true;
      })
      .catch(error => {
        setIsGPTbuttonDisabled(true);
        return false;
      });
  }

  useEffect(() => {
    validate();
  }, [project]);

  function AIGenratedSalaryButton():JSX.Element {
    const [fetchingDescription, setFetchingDescription] = useState(false);
    const [showFeedBack, setShowFeedBack] = useState(false);
    const [showUpdatedFeedBack, setShowUpdatedFeedBack] = useState(false);
    const [savedFeedback, setSavedFeedback] = useState(null);
    const [feedbackUUID, setFeedbackUUID] = useState(null);
    const [isError, setIsError] = useState(false);

    function saveFeedBack(value) {
      if (feedbackUUID) {
        api.updateFeedback(feedbackUUID, {is_helpful: value}).then((data) => {
          setShowUpdatedFeedBack(true);
          setSavedFeedback(data.is_helpful);
        });
      }
    }

    const generateButton = () => {
      return (
        <><img src={stars}></img> <span>Create description</span></>
      );
    };

    const loadingButton = () => {
      return (
        <div>
          <div className={styles.spinner}/>
          <span>
            Loading magic...
          </span>
        </div>
      );
    };

    const feedbackbutton = () => {
      return (
        <>
          <img src={stars}></img> <span>Was this Helpful</span>
          <Button
            size="small"
            kind="secondary"
            className={styles.thumsButton}
            onClick={(event) => {
              saveFeedBack(true);
              event.stopPropagation();
            }}
          ><i className="ri-thumb-up-fill"></i>
          </Button>
          <Button
            size="small"
            kind="secondary"
            onClick={(event) => {
              saveFeedBack(false);
              event.stopPropagation();
            }}
            className={styles.thumsButton}
          >
            <i className="ri-thumb-down-fill"></i>
          </Button>
        </>
      );
    };

    const afterFeedbackButton = () => {
      return (
        <>
          <img src={stars}></img> <span>Thank you!</span>
          <Button
            size="small"
            kind="secondary"
            className={classNames(
              styles.thumsButton,
              savedFeedback == true?styles.activeVote:"",
              savedFeedback == false ? styles.notActiveVote: "")}
            onClick={(event) => {
              saveFeedBack(true);
              event.stopPropagation();
            }}
          ><i className="ri-thumb-up-fill"></i>
          </Button>
          <Button
            size="small"
            kind="secondary"
            onClick={(event) => {
              saveFeedBack(false);
              event.stopPropagation();
            }}
            className={classNames(styles.thumsButton,
              savedFeedback == false?styles.activeVote:"",
              savedFeedback == true?styles.notActiveVote:"",
            )}
          >
            <i className="ri-thumb-down-fill"></i>
          </Button>
        </>
      );
    };

    function generateDescription() {
      setFetchingDescription(true);
      if (!showFeedBack) {
        api.createGPTDescription({project_uuid: projectUUID}).then((data) => {
          setFetchingDescription(false);
          if (data.description) {
            handleProjectChange(data.description, "description");
            setFeedbackUUID(data.uuid);
            setShowFeedBack(true);
          }
        }).catch((error) => {
          setIsError(true);
        });
      }
    }

    const errorButton = () => {
      return (<>
        <span className={styles.warning}>Oops, error.</span>
      </>);
    };

    let buttonText = <></>;
    if (showUpdatedFeedBack) {
      buttonText = afterFeedbackButton();
    }
    else if (showFeedBack) {
      buttonText = feedbackbutton();
    }
    else if (isError) {
      buttonText = errorButton();
    }
    else if (fetchingDescription) {
      buttonText = loadingButton();
    }
    else {
      buttonText = generateButton();
    }

    return (
      <div className="d-flex align-items-end flex-column">
        <Button
          size="small"
          kind="secondary"
          className={styles.generateDescription}
          disabled={isGPTbuttonDisabled}
          onClick= {generateDescription}
        >
          {buttonText}
        </Button></div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-6 d-flex flex-column mt-4">
        <FormLabel
          title="Role Title"
          tooltip={
            <div>
              <Heading size="h5" className={styles.toolTipHeader}>💡 How to write a role title</Heading>
              <ul className={styles.toolTipList}>
                <li>Be specific.</li>
                <li>Keep it short, aim for max. 30 characters.</li>
                <li>Focus on responsibilities.</li>
                <li>Include keywords.</li>
                <li>Use professional language.</li>
                <li>Avoid industry jargon and abbreviations.</li>
                <li>Avoid unnecessary information.</li>
                <li>Avoid gendered language and outdated terms.</li>
              </ul>
            </div>
          }
          required={true}
        >
          <Input
            placeholder="e.g. Digital Marketing "
            value = {project.get("name")}
            onChange = {(value) => {
              handleProjectChange(value, "name");
            }}
            invalid={!!errors.name}
            {...getSavingStates("name")}
          />
          <ErrorMessage errors={errors.name} name="name"/>
        </FormLabel>
        <FormLabel
          title="Role dates"
          subTitle="When is the expected start and end date?"
        >
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-8 mb-md-2 mb-sm-0 col-sm-6 mb-2 col-12">
              <DatePicker
                className={styles.datePicker}
                minDate={new Date()}
                value={project.get("start_date")}
                onSelect={(value) => {
                  handleProjectChange(value, "start_date");
                } }
                placeholder="Start date*"
              />
              <AutoSaving {...getSavingStates("start_date")}/>
              <ErrorMessage errors={errors.start_date} name="start_date"/>
            </div>
            <div className="col">
              <DatePicker
                className={styles.datePicker}
                minDate={new Date()}
                value={project.get("end_date")}
                onSelect={(value) => {
                  handleProjectChange(value, "end_date");
                } }
                placeholder="End date*"
                readOnly={project.get("is_no_end_date")}
              />
              <AutoSaving {...getSavingStates("end_date")}/>
              <ErrorMessage errors={errors.end_date} name="end_date"/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Checkbox
                checked={project.get("is_no_end_date")}
                onChange={(value) => {
                  handleProjectChange(value, "is_no_end_date");
                }}
              >
                <span>There is no defined end date</span>
              </Checkbox>
            </div>
          </div>
        </FormLabel>
        <FormLabel
          required={true} title="Working hours"
          subTitle="What will the working hours be?"
          labelClassName="mt-2"
          tooltip={
            <div>
              <div>
                <Heading size="h6">Part-time</Heading>
                <Body size="medium">
                  Working 20 hours per week is considered part-time. This varies by country and company.
                </Body>
              </div>
              <div>
                <Heading size="h6">Full-time</Heading>
                <Body size="medium">
                  Working 40 hours per week is considered full-time. This varies by country and company.
                </Body>
              </div>
              <div>
                <Heading size="h6">Flexible</Heading>
                <Body size="medium">
                  Use a custom value for working hours if the candidate is expected to work a different number of hours per week.
                </Body>
              </div>
            </div>
          }
        >
          <div className={styles.employmentTyperadiobuttons}>
            <RadioGroup
              options={EMPLOYMENT_TYPE_OPTIONS}
              setValue={(value) => {
                handleProjectChange(value, "employment_type");
              }}
              value={project.get("employment_type")}
            >
            </RadioGroup>
          </div>

          <AutoSaving {...getSavingStates("employment_type")}/>
          <ErrorMessage errors={errors.employment_type} name="employment_type"/>
          <div className={classNames("d-flex", styles.workingHoursContainer)}>
            <Input
              className={styles.workingHoursInput}
              value={workingHoursPerWeek}
              type="number"
              size="3"
              placeholder="Working hours"
              disabled={project.get("employment_type") != "Custom" }
              onChange = {(value) => {
                const parsed = value ? Number(value) : null;
                handleProjectChange(parsed, "working_duration_hours_per_week");
                setWorkingHoursPerWeek(parsed);
              }}
            ></Input>
            <span className="m-2 flex-shrink-0"> hours per week</span>
          </div>

          {voluntaryWarning && <div className="d-flex">
            <div>
              <img src={warning}></img>
            </div>
            <div>
              <p className={classNames(styles.warning, "ml-1")}>
                Roles that are voluntary and surpass 150 working hours are <a
                  href="https://superpower.tech/voluntary-work-placements/" target="_blank" rel="noreferrer"
                >considered unethical</a>. Please amend the compensation to paid or reduce the duration or working hours.
              </p>
            </div>
          </div>}
        </FormLabel>
        {isLocation && <><FormLabel
          required={true} title="Role location"
          subTitle="Where will you be hosting this role?"
          labelClassName="mt-2"
          tooltip={
            <div>
              <div>
                <Heading size="h6">Hybrid</Heading>
                <Body size="medium">
                  The candidate is expected to work sometimes from home and sometimes in the office.
                </Body>
              </div>
              <div>
                <Heading size="h6">Remote</Heading>
                <Body size="medium">
                  The candidate is expected to work from home and not in the office.
                </Body>
              </div>
              <div>
                <Heading size="h6">On location</Heading>
                <Body size="medium">
                  The candidate is expected to work in the office.
                </Body>
              </div>
            </div>
          }
        >
          <RadioGroup
            options={LOCATION_TYPE_OPTIONS}
            setValue={(value) => {
              handleProjectChange(value, "location_preference");
            }}
            value={project.get("location_preference")}
          >
          </RadioGroup>
          <AutoSaving {...getSavingStates("location_preference")}/>
          <ErrorMessage errors={errors.location_preference} name="location_preference"/>
        </FormLabel>
        {project.get("location_preference") !== "remote" && <LocationSearch
          placeholder="Project Location*"
          className="w-100"
          value={location && location.address_country ? {
            address_country_code: location.address_country.code,
            address_country_name: location.address_country.name,
            address_region: location.address_region,
            address_city: location.address_city,
          }: null}
          name="location"
          url={Variables.urls["api-v3"].location_search}
          searchField="q"
          resultField = "results"
          onChange={(value) => {
            let obj = Object.fromEntries(
              Object.entries(value).map(([key, value]) => [key, value ?? ""]),
            );
            obj = {
              address_region: obj.address_region,
              address_city: obj.address_city,
              address_country: obj.address_country_code,
            };
            setLocation(data => data.merge(obj));
          }}
          apiDataMap={{
            countryCode: "country_code",
            country: "country_name",
            state: "state_name",
            city: "name",
          }}
          valueMap={{
            countryCode: "address_country_code",
            country: "address_country_name",
            state: "address_region",
            city: "address_city",
          }}
        />}
        </>
        }
        {isCompensationRadio && <FormLabel
          required={true} title="Compensation"
          subTitle="How will the role be compensated?"
          tooltip={
            <div>
              <Body size="medium">For roles longer than 150 hours compensation needs to be paid
              to protect individuals and to be in line with our <a href="https://superpower.tech/terms-and-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
              &nbsp;and&nbsp;<a href="https://superpower.tech/voluntary-work-placements/" target="_blank" rel="noreferrer">Code of Conduct for Voluntary Roles</a>.</Body>
            </div>
          }
          tooltipClickable
        >
          <RadioGroup
            options={COMPENSATION_OPTIONS}
            setValue={(value) => {
              handleProjectChange(value, "is_paid");
            }}
            value={project.get("is_paid")}
          >
          </RadioGroup>
          <AutoSaving {...getSavingStates("is_paid")}/>
          <ErrorMessage errors={errors.is_paid} name="is_paid"/>
        </FormLabel>}

        {isCandidatesRequested && <FormLabel
          required={true} title="How many people do you require for this role?"
          subTitle="Hiring a team will save you time and will help us match you with the right number of applicants"
        >

          <NumberInput
            name="candidates_requested"
            onChange={(value) => {
              handleProjectChange(value, "candidates_requested");
            }}
            value={project.get("candidates_requested")}
          />
          <AutoSaving {...getSavingStates("candidates_requested")}/>
          <ErrorMessage errors={errors.candidates_requested} name="candidates_requested"/>
        </FormLabel>}
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <FormLabel
          required={true} title="Role Description"
          extraHeaderButton={AIGenratedSalaryButton()}
          labelClassName="justify-content-between"
          tooltip={
            <div>
              <Heading size="h5" className={styles.toolTipHeader}>💡 How to write a role description</Heading>
              <ul className={styles.toolTipList}>
                <li>
                  <Heading size="h6">Summary</Heading>
                  <Body size="medium">Open with a strong, attention-grabbing summary of your role. Include an overview of your company, culture, expectations for the role and why a candidate would love to work for you.</Body>
                </li>
                <li>
                  <Heading size="h6">Responsibilities</Heading>
                  <Body size="medium">Outline the core responsibilities of the position. Highlight day-to-day activities. Specify how the role fits into your organisation helping candidates see the bigger picture and understand how the role impacts the business.</Body>
                </li>
                <li><Body size="h6"> Use professional language.</Body></li>
                <li><Body size="h6"> Avoid industry jargon and abbreviations.</Body></li>
                <li><Body size="h6"> Avoid unnecessary information.</Body></li>
                <li><Body size="h6"> Avoid gendered language and outdated terms.</Body></li>
              </ul>
            </div>
          }
          tooltipPosition="bottom"
        >
          <RichTextEditor
            value = {project.get("description")}
            onChange = {(value) => {
              handleProjectChange(value, "description");
            }}
            onReady = {editor => editor.setData(project.get("description"))}
          >
          </RichTextEditor>
          <AutoSaving {...getSavingStates("description")}/>
          <ErrorMessage errors={errors.description} name="description"/>
        </FormLabel>
        <PreScreenQuestions
          project={project}
          handleProjectChange={handleProjectChange}
          projectUUID={projectUUID}
          isSaveButton={isSaveQuestions}
        />
      </div>
    </div>
  );
}

const UpdateCompanyName = ({companyUuid, locationUuid, successCallback}) => {
  const [open, setOpen] = useState(true);
  const [showCompanyTextBox, setShowCompanyTextBox] = useState(false);
  const [duedilId, setDuedilId] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const companyMutation = useCompanyMutation(companyUuid);
  const locationMutation = useLocationMutation(locationUuid);

  const onCompanyNotFound = () => {
    setShowCompanyTextBox(true);
  };

  const onCompanyFound = () => {
    setShowCompanyTextBox(false);
  };

  const updateSelectedCompany = (result) => {
    const {companyId, name, countryCode} = result;
    setCompanyName(name);
    setCountryCode(countryCode);
    setDuedilId(companyId);
  }

  const onNext = () => {
    setIsSaving(true);
    function clearAndClose() {
      setIsSaving(false);
      setOpen(false);
      successCallback(companyName);
    }
    if(duedilId) {
      let newCompanyData = {
        "name": companyName,
        "duedil_company_id": duedilId,
      }
      api.getCompanyData({
        endpoint: "company_data",
        "company-id": duedilId,
        "country-code": countryCode,
      }).then(res => {
        if(res.primaryWebsite) {
          newCompanyData["domain"] = res.primaryWebsite
        }
        companyMutation.mutateAsync(newCompanyData).then(company => {
          if(res.registeredAddress?.fullAddress) {
            const { addressLine1, city, county, postalCode } = getAddressParts(res.registeredAddress.fullAddress);
            let newLocationData = {
              "address_street": addressLine1,
              "address_city": city,
              "address_country": countryCode,
              "address_postcode": postalCode
            }
            locationMutation.mutate(newLocationData);
          }
          clearAndClose()
        });
     });
    }
    else {
      companyMutation.mutateAsync({"name": companyName}).then(res => clearAndClose());
    }
  }
  return (
    <div className="row">
      <div className="col-12 col-sm-9 col-md-7 col-lg-5 col-xl-4 m-auto d-flex flex-column">
        <Heading size="h5" className="mb-3">Let’s get this show on the road! 🚀</Heading>
        <Subtitle inline>For which company will you be hiring for?</Subtitle>
        {/*@ts-ignore*/}
        <DuedilAsyncTypeaheadSignUp
          id="companyName"
          className={styles.asyncAheadInput}
          updateSelectedCompany={updateSelectedCompany}
          onCompanyNotFound = {onCompanyNotFound}
          onCompanyFound = {onCompanyFound}
          value={showCompanyTextBox? "Can’t find your organisation?": companyName}
          name="name"
          onChange={(text, name) => setCompanyName(text)}
          fetchAddress={false}
          placeholder="Search company"
          style={{width: "100%"}}
        />
        {showCompanyTextBox &&
          <Input
            id="companyName"
            name="name"
            value={companyName}
            placeholder="Company Name"
            onChange={e => setCompanyName(e.target.value)}
          />
        }
        <Button disabled={!companyName || isSaving} onClick={onNext}>
          {isSaving ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Next"}
        </Button>
      </div>
    </div>
  )
}

export function AddProjectModal(): JSX.Element | null {
  const [projectUuid, setProjectUuid] = useState<string | undefined>();
  const [companyUuid, setCompanyUuid] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const handleModalEvent = useCallback(
    (detail: CreateRoleData) => {
      setOpen(open => detail.open ?? open);
      setCompanyUuid(detail.companyUuid);
      setProjectUuid(detail.projectUuid);
      if (detail.onClose) {
        setOnClose(() => detail.onClose);
      }
      else {
        setOnClose(undefined);
      }
    }, [setOpen, setCompanyUuid, setProjectUuid, setOnClose],
  );

  useEffect(() => {
    createRoleModalInterface.register(handleModalEvent);
    return () => {
      createRoleModalInterface.remove();
    };
  }, [handleModalEvent]);

  function handleClose() {
    onClose?.();
    setOpen(false);
    setCompanyUuid(undefined);
    setProjectUuid(undefined);
    setOnClose(undefined);
  }

  return (
    //@ts-ignore
    <Modal
      open={open}
      theme={styles}
      onClose={handleClose}
      className={classNames(styles.xlWidth)}
    >
      {open &&
        //@ts-ignore
        <Content>
          <AddProject
            companyUuid={companyUuid}
            projectUuid={projectUuid}
            setProjectUuid={setProjectUuid}
          />
        </Content>
      }
    </Modal>
  )
}

export default function AddProject({companyUuid, projectUuid, setProjectUuid}): JSX.Element | null {
  const [project, setProject, setReferenceProject, hasProjectChanged] = useTrackedState(
    Map(schema.getDefault()),
  );
  const [location, setLocation, setReferenceLocation, hasLocationChanged] = useTrackedState(
    Map({}),
  );
  const [projectUUID, setProjectUUID] = useState(projectUuid ? projectUuid : "");
  const [isCreating, setIsCreating] = useState(false);
  const [isPendingSave, setIsPendingSave] = useState<boolean | null>(null);
  const [preview, setPreview] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  const [previewComponent, setPreviewComponent] = useState<JSX.Element>(<></>);
  const [locationUuid, setLocationUuid] = useState();
  const [companyName, setCompanyName] = useState("");
  const [showUpdateCompanyNameModal, setShowUpdateCompanyNameModal] = useState(false);

  const enableProjectQuery = !!projectUUID;
  const templatesQuery = useProjectTemplatesQuery({});
  const userQuery = useSelfUpToCompanyQuery(DISABLE_REFETCH_OPTIONS);
  const projectQuery = useProjectQuery(
    projectUUID,
    {...DISABLE_REFETCH_OPTIONS, enabled: enableProjectQuery},
  );

  const locationQuery = useLocationQuery(
    locationUuid,
    {
      ...DISABLE_REFETCH_OPTIONS, enabled: !!locationUuid,
      onSuccess: (data) => {
        setReferenceLocation(Map(data));
      },
    },
  );
  const locationMutation = useLocationMutation(locationUuid);
  const createLocationMutation = useLocationCreateMutation(
    companyUuid, {
      onSuccess: (data) => {
        setLocationUuid(data.uuid);
        validate(true);
      }
    },
  );
  const projectMutation = useProjectMutation(projectUUID);

  const queries = [
    templatesQuery,
    userQuery,
    projectQuery,
  ];
  const {data, isLoading} = combineQueries(queries);

  const [
    templates,
    user,
    originalProject,
  ] = data;

  const [errors, setErrors] = useState<Record<string, string[]>>({});

  useEffect(() => setProjectUuid(projectUUID), [projectUUID])

  useEffect(() => scrollToErrors(errors), [errors]);

  useEffect(() => {
    if (!projectMutation.isLoading && isPendingSave === true) {
      updateProject();
    }
  }, [isPendingSave, projectMutation.isLoading]);

  useEffect(() => {
    if (locationUuid) {
      updateProject();
    }
  }, [locationUuid]);

  useEffect(() => {
    if (!locationMutation.isLoading && Object.keys(location.toJSON()).length > 0 && hasLocationChanged) {
      updateLocation();
    }
  }, [location, locationMutation.isLoading, hasLocationChanged, locationUuid]);

  useEffect(
    () => {
      if (hasProjectChanged) {
        updateProject();
        validate(true);
      }
    }, [project, locationUuid]);

  useEffect(
    () => {
      if (originalProject) {
        setLocationUuid(originalProject.location);
        setProject(
          Map(
            falsyMerge(schema.getDefault(), originalProject, {
              start_date:
                originalProject.start_date == null
                  ? undefined
                  : moment(originalProject.start_date).toDate(),
              end_date:
                  originalProject.end_date == null
                    ? undefined
                    : moment(originalProject.end_date).toDate(),
              type: project.get("type") == "Bespoke" ? BESPOKE_PROJECT : project.get("type"),
            }),
          ),
        );
      }
    }, [projectQuery.isSuccess]);

  useEffect(() => {
    if(userQuery.isSuccess) {
      const nameOfCompany = user.host.association.location.company.name;
      setCompanyName(nameOfCompany);
      if(nameOfCompany.toLowerCase() == `${user.first_name}${"`"}s Company`.toLowerCase()
      || nameOfCompany.toLowerCase() == `${user.first_name}${"`"}s Team`.toLowerCase()
      || nameOfCompany.toLowerCase() == "Unspecified company".toLowerCase()) {
        setShowUpdateCompanyNameModal(true);
      }
    }
  }, [userQuery.isSuccess]);

  function validate(abortEarly =false) {
    return schema.validate(project.toJSON(), {strict: true, abortEarly: abortEarly})
      .then(() => {
        setErrors({});
        setIsDisabled(false);
        return true;
      })
      .catch(error => {
        setIsDisabled(true);
        setErrors(yupErrorToMapping(error));
        return false;
      });
  }

  async function submitQuestions() {
    const pre_screen_questions = project.get("pre_screen_questions");

    if (pre_screen_questions.length == 0) {
      return;
    }
    const payload = {
      questions: project.get("pre_screen_questions"),
      project: projectUUID,
    };

    await api.createProjectPrescreenQuestions(payload).then(
      (response) => {
        console.log(response);
      },
    );
  }

  function publishRole() {
    validate().then((is_valid) => {
      setIsPublishing(true);
      const payload = {
        status: "open",
      };
      if (locationUuid) {
        payload["location"] = locationUuid;
      };
      projectMutation.mutateAsync(payload).then(async (project) => {
        await submitQuestions();
        return project;
      }).then(
        project => {
          setIsPublishing(false);
          window.location.href = Variables.urls.hosts.company_overview;
        }).catch(errors => {
        setIsPublishing(false);
        setErrors(apiErrorToMapping(errors, schema));
        throw new APIError();
      });
    });
  }

  function updateProject() {
    const isCreate = !projectUUID;
    const payload = _.pick(project.toJSON(), Object.keys(schema.fields).concat(FROM_TEMPLATE));
    if (project.get("name") == "") {
      return;
    }
    if (project.get("start_date")) {
      payload.start_date = moment(project.get("start_date")).format("YYYY-MM-DD");
    }
    if (project.get("end_date")) {
      payload.end_date = moment(project.get("end_date")).format("YYYY-MM-DD");
    }
    if (project.get("type") == BESPOKE_PROJECT) {
      payload.type = "Bespoke";
    }
    delete payload.pre_screen_questions;
    delete payload.voluntaryWarning;
    delete payload.locationWarning;
    if (locationUuid) {
      Object.assign(payload, {
        location: locationUuid,
      });
    }
    if (isCreate && user?.host.association.uuid && ! isCreating) {
      setIsCreating(true);
      api.createProjects({
        ...payload,
        status: "draft",
        association: user.host.association.uuid,
      }).then(project => {
        setIsCreating(false);
        setProjectUUID(project.uuid);
        setProject(data => data.set("uuid", project.uuid));
        return project;
      });
    }
    else if (!isCreating) {
      if (!projectMutation.isLoading) {
        projectMutation.mutateAsync(payload).then(
          (project) => {
            setIsPendingSave(false);
          });
      }
      else {
        setIsPendingSave(true);
      }
    }
  }

  function updateLocation() {
    const payload = {
      ...location.toJSON(),
      company: companyUuid,
      purpose: locationPurpose.PLACEMENT,
      type: locationType.BRANCH
    };
    const isCreating = !locationUuid;
    if (isCreating) {
      setIsDisabled(true);
      createLocationMutation.mutateAsync(payload);
    }
    else {
      locationMutation.mutateAsync(payload);
    }
  }

  const showPreview = projectMap => {
    const project = Object.fromEntries(projectMap);
    const content = <ProjectPreview project={project} setPreview={setPreview} className={styles.projectPreview}/>;
    document.body.style.background = "white";
    document.getElementsByTagName("footer")[0].style.display = "block";
    setPreviewComponent(content);
    setPreview(true);
  };

  const updateCompanyNameSuccess = (compName) => {
    setCompanyName(compName);
    setShowUpdateCompanyNameModal(false);
  }

  if(showUpdateCompanyNameModal) {
    return (
      <UpdateCompanyName
        companyUuid={companyUuid}
        locationUuid={locationUuid}
        successCallback={(compName) => updateCompanyNameSuccess(compName)}
      />
    )
  }

  if (preview) {
    return (<>
      {previewComponent}
    </>);
  }

  if (isLoading) {
    return <LoadingSpinner className="d-block m-auto"/>;
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <Heading size="h4">
            🚀 Create a role for {companyName}
          </Heading>
        </div>
      </div>
      <ProjectBasic
        project ={project}
        setProject={setProject}
        location={location}
        setLocation={setLocation}
        templates={templates}
        errors={errors}
        projectUUID={projectUUID}
        isSaveQuestions={false}
      />
      <div className={styles.buttonContainer}>
        <Button
          kind="secondary"
          onClick={() => {
            showPreview(project);
          }}
          type="button"
          disabled={isDisabled}
        >
          Preview
        </Button>
        <Button
          kind="primary"
          onClick={() => {
            publishRole();
          }}
          type="button"
          disabled={isDisabled}
        >
          {isPublishing ? <PulseLoader color="#FFF" margin={2} size={6}/> : "Publish Role" }
        </Button>
      </div>
    </div>
  );
}

ProjectBasic.propTypes = {
  project: PropTypes.any,
  setProject: PropTypes.func,
  schema: PropTypes.any,
  templates: PropTypes.any,
  errors: PropTypes.any,
  savingFields: PropTypes.arrayOf(PropTypes.string),
  errorFields: PropTypes.arrayOf(PropTypes.string),
  isCandidatesRequested: PropTypes.bool,
  isCompensationRadio: PropTypes.bool,
  isLocation: PropTypes.bool,
};

ProjectBasic.defaultProps = {
  savingFields: [],
  errorFields: [],
  isCandidatesRequested: false,
  isCompensationRadio: true,
  isLocation: true,
};
