import React from "react";
import {EventInterface} from "util/events";
import {mergeClassNames} from "styles/utils";
import Modal from "components/shared/Modal/Modal";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./biasModal.module.scss";
import {Content, Header} from "components/shared/Slate";


const styles = mergeClassNames(baseStyles, modalTheme);

interface BiasDetected {
  /** The ID of the modal. */
  id: string;
  /** The Project UUID. */
  projectId: string;
  /** customDEI to check what data to show to host. */
  customDEI: boolean;
  /** stage at which gender bias detected. */
  stage: string;
  /** to determine which bias has been detected  */
  type: string;
  /** A callback to call when to modal closes. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
}

export const biasModalInterface = new EventInterface<Omit<BiasDetected, "id">>("biasModal");


export default function BiasModal({projectId, customDEI, type, stage, onClose, open}: BiasDetected) {

  return (
    <Modal
      id="bias-modal"
      theme={styles}
      onClose={onClose}
      open={open}
    >
      <Header className={styles.header}>
        {type === "gender_bias" ? <h3>Gender bias detected</h3> : <h3>Ethnicity bias detected</h3> }
      </Header>
      {stage === "application_stage" ?
        <span className={styles.stage}>At application stage</span>
        :
        <span className={styles.stage}>At interview stage</span>
      }
      <Content>
        <div className={styles.content}>
          {customDEI === false && type === "gender_bias" &&
            <p>
              We have detected a gender bias in your {stage === "application_stage" ? "application stage " : "interview stage "}
              towards (men/women &amp; other): over 50% of your applications have been by (male/female &amp; other) applicants.
              We recommend the following changes to rebalance and decrease this bias:
            </p>
          }
          {customDEI === true && type === "gender_bias" &&
            <p>
              We have detected a gender bias in your {stage === "application_stage" ? "application stage " : "interview stage "}
              that deviates from your company&apos;s Diversity, Ethnicity &amp; Inclusion goals.
              The bias detected leans more strongly towards (men/women &amp; other).
              We recommend the following changes to rebalance and decrease this bias to achieve (company name)&apos;s goals:
            </p>
          }
          {customDEI === false && type === "ethnicity_bias" &&
            <p>
              We have detected an ethnicity bias in your {stage === "application_stage" ? "application stage " : "interview stage "}
              towards (White/Black, African, Caribbean, Black British/Asian or Asian British/Mixed or Multiple Ethnic/Other Ethnic):
              over 70% of your applications have been by
              (White/Black, African, Caribbean, Black British/Asian or Asian British/Mixed or Multiple Ethnic/Other Ethnic) applicants.
              We recommend the following changes to rebalance and decrease this bias.
            </p>
          }
          {customDEI === true && type === "ethnicity_bias" &&
            <p>
              We have detected an ethnicity bias in your {stage === "application_stage" ? "application stage " : "interview stage "}
              that deviates from your company&apos;s Diversity, Ethnicity &amp; Inclusion goals. The bias detected leans more strongly
              towards (White/Black, African, Caribbean, Black British/Asian or Asian British/Mixed or Multiple Ethnic/Other Ethnic) candidates.
              We recommend the following changes to rebalance and decrease this bias to achieve (company name)’s goals:
            </p>
          }
          <ol className={styles.suggestions}>
            <li>
              <strong>Adjust to part-time or flexible.</strong>
              <p>
                If your project position is set to full-time,
                we recommend switching it to part-time or flexible as full-time positions
                can pose a hurdle for women with childcaring responsibilities to apply.
              </p>
              <a className={styles.suggestionButtons} href={`${Variables.urls.hosts.my_projects}edit/${projectId}`}>
                Adjust role type
              </a>
            </li>
            <li>
              <strong>Review your role description.</strong>
              <p>
                Consider reviewing your role description to confirm that
                your word choices can&apos;t be wrongfully perceived as offensive
                towards a culture, ethnicity, religion or gender.
              </p>
              <a className={styles.suggestionButtons} href={`${Variables.urls.hosts.my_projects}edit/${projectId}`}>
                Review role description
              </a>
            </li>
          </ol>
        </div>
        <div>
          Learn more about the benefits of diverse talent and how to mitigate bias:
          <ul>
            <li>
              <a
                className={styles.links} target="_blank"
                href="https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-how-inclusion-matters"
                rel="noreferrer"
              >
                Diversity wins: How inclusion matters, McKinsey &amp; Company
              </a>
            </li>
            <li>
              <a
                className={styles.links} target="_blank"
                href="https://hbr.org/2017/06/7-practical-ways-to-reduce-bias-in-your-hiring-process"
                rel="noreferrer"
              >
                7 Practical Ways to Reduce Bias in Your Hiring Process, Harvard Business Review
              </a>
            </li>
          </ul>
        </div>
      </Content>
    </Modal>
  );
}
