import React, {useContext, useRef, useState} from "react";
import styles from "./candidateLinkCard.module.scss";
import {
  Heading,
  Subtitle,
} from "workfinder-components";
import SocialMediaShareDropdown from "components/shared/SocialMediaShareDropdown";
import classNames from "classnames";

const CandidatePublicProfileLink = ({link, showOpenLinkIcon = false}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  };

  return (
    <div className={classNames(styles.companyLinkCard, "p-3 mb-4")}>
      <Heading size="h6" className="d-flex align-center justify-content-between">
        Public Profile
        <div className={styles.iconsContainer}>
          <div className="position-relative">
            <i className="ri-file-copy-line" onClick={handleCopyClick}></i>
              {tooltipVisible && (
                <div className={styles.tooltip}>Link copied</div>
              )}
          </div>
          <SocialMediaShareDropdown
            text={link}
            message={link}
            title={""}
            showCopyLink={false}
            toggler={<i
              id="share-company-profile"
              className="ri-share-line"
              role="button"
            />}
          />
          {showOpenLinkIcon &&
            <i className="ri-eye-line" onClick={() => window.open(link)}></i>
          }
        </div>
      </Heading>
      <Subtitle className="overflow-hidden">{link}</Subtitle>
    </div>
  );
}

export default CandidatePublicProfileLink;
