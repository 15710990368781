export function isPlainObject(obj: unknown): obj is Record<string, any> {
  return obj != null && typeof obj === "object" && obj?.constructor === Object;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Flatten a nested object, e.g. {a: {b: 2}, c: 1} → {a.b: 2, c: 1}
 * @param {*} obj
 */
export function flatten(obj) {
  const r = {};
  for (const [originalKey, originalValue] of Object.entries(obj)) {
    if (isPlainObject(originalValue)) {
      const flatObj = flatten(originalValue);

      for (const [nestedKey, nestedValue] of Object.entries(flatObj)) {
        r[`${originalKey}.${nestedKey}`] = nestedValue;
      }
    }
    else {
      r[originalKey] = originalValue;
    }
  }
  return r;
}

/**
 * Similar to `Object.fromEntries`, expects object value to be arrays. Entries
 * with the same key get combined by concatenating their arrays.
 */
export function fromListEntries(entries) {
  // Combine all errors with the same key.
  return entries.reduce((acc, [k, v]) => ({...acc, [k]: [...acc[k] ?? [], v]}), {});
}
