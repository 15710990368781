import { createRoleModalInterface } from "components/add-project/index";
import React, { createContext, useMemo } from "react";
import { DISABLE_REFETCH_OPTIONS, useMyProjectsQuery } from "services/queries";

interface Context {
  isSuccess: boolean;
  liveRoles: Array<any>;
  draftRoles: Array<any>;
  openCreateRoleModal: (string?) => void;
  refetchRoles: () => void;
}

export const RoleListContext = createContext<Context>({
  isSuccess: false,
  liveRoles: [],
  draftRoles: [],
  openCreateRoleModal: () => {},
  refetchRoles: () => {}
});

const RoleListContextProvider = ({companyUuid, children}) => {
  const {data: roles, isSuccess, refetch: refetchRoles} = useMyProjectsQuery({}, DISABLE_REFETCH_OPTIONS);
  const liveRoles = useMemo(() => {
    return roles?.filter(p => ["paused", "open"].includes(p.status));
  }, [roles]);
  const draftRoles = useMemo(() => {
    return roles?.filter(p => p.status === "draft");
  }, [roles]);

  function openCreateRoleModal(projectUuid=undefined) {
    createRoleModalInterface.dispatch({
      open: true,
      companyUuid: companyUuid,
      projectUuid: projectUuid,
      onClose: refetchRoles
    });
  }

  return <RoleListContext.Provider
    value={{
      isSuccess: isSuccess,
      liveRoles: liveRoles,
      draftRoles: draftRoles,
      openCreateRoleModal: openCreateRoleModal,
      refetchRoles: refetchRoles,
    }}
  >
    {children}
  </RoleListContext.Provider>
}

export default RoleListContextProvider;
