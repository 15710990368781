import React from "react";
import styles from "./header.module.scss";

export interface HeaderProps {
    image: string;
    title: string;
    link: string;
    subtitle: string;
  }

export default function Header(props: HeaderProps): JSX.Element {
  const {image, title, link, subtitle} = props;
  return (
    <div className={styles.header}>
      <img
        className={styles.img}
        src={image}
        alt={subtitle}
      />
      <div className={styles.rightColumn}>
        <a
          className={styles.title}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <strong>{title}</strong>
        </a>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
}
