import React from "react";
import classNames from "classnames";
import styles from "./RadioGroupSwitch.module.scss"

interface RadioGroupProps {
  name: string,
  currentlValue: any,
  vertical?: boolean,
  onChange: (value: any) => any,
  radioProps: RadioProps[]
}

interface RadioProps {
  value: any,
  text: string,
  secondaryText?: string
}

const RadioGroupSwitch = (props: RadioGroupProps) => {
  const onChangeValue = (event: any) => {
    props.onChange(event.target.value);
  }
  const flexDirection = () => {
    return props.vertical ? ".flex-column" : "";
  }

  return (
    <div className={classNames(styles.radioGroup, flexDirection())}>
      {
        props.radioProps.map(radioProps => {
          let checkedProp = {};
          let id = `${props.name}-${radioProps.value}`;
          if (radioProps.value == props.currentlValue) checkedProp["checked"] = "true";
          return (
            <div className={styles.radioItem}><input id={id} type="radio" key={radioProps.value} value={radioProps.value} name={props.name} {...checkedProp} onClick={(e) => onChangeValue(e)} /><label for={id}>{radioProps.text}{radioProps.secondaryText && (<span className={styles.secondary}>{radioProps.secondaryText}</span>)}</label></div>
          );
        })
      }
    </div>
  );
}

export default RadioGroupSwitch;
