import React from "react";
import classNames from "classnames";

import styles from "./radioButtons.module.scss";
import Tooltip from "components/shared/Tooltip";
interface Option<T> {
  /** The value of the option. */
  value: T;
  /** The label to display for the options. If omitted, the value is used. */
  label?: string;
  /** The tooltip to display for the options. If omitted, Nothing will display. */
  tooltip:React.ReactNode;
}

interface Props<T> {
  /** The CSS class used to style the container element. */
  className?: string;
  /** Whether the radio buttons should be disabled. */
  disabled?: boolean;
  /** Display the radio buttons horizontally. */
  horizontal?: boolean;
  /** The ID of the radio button container element. */
  id?: string;
  /** The `name` attribute for all the radio buttons. */
  name: string;
  /** A list of options to render as radio buttons. */
  options: Option<T>[];
  /** The value of the selection option. */
  value: T;
  /** A callback that will be called when a new option is checked. */
  onChange: (value: T, name: string) => void;
}

function RadioButton({checked, children, id, ...inputProps}) {
  return (
    <label className={styles.radioButton} htmlFor={id}>
      <input
        {...inputProps}
        checked={checked}
        id={id}
        type="radio"
      />
      <span className={classNames(styles.checker, {[styles.checked]: checked})} />
      {children}
    </label>
  );
}

export default function RadioButtons<T>(props: Props<T>): JSX.Element {
  const {
    className,
    disabled = false,
    horizontal=false,
    id,
    name,
    options,
    value,
    onChange,
    ...restProps
  } = props;

  function handleChange(e, value: T) {
    if (e.target.checked) {
      onChange(value, name);
    }
  }

  return (
    <div className={classNames(styles.radioButtons, {[styles.horizontal]: horizontal}, className)} id={id}>
      {
        options.map((option, idx) => (<div className="d-flex"
          key={option.label ?? option.value}
        >
          <RadioButton
            {...restProps}
            checked={option.value === value}
            disabled={disabled}
            id={`${name}-${idx}`}
            name={name}
            value={option.value}
            onChange={e => handleChange(e, option.value)}
          >
            {option.label ?? option.value}

          </RadioButton>
          {option.tooltip?<Tooltip
            className={styles.tooltip}
            delayHide={option?.tooltipClickable ? 1000 : 0}
            clickable={option?.tooltipClickable}
          >
            {option.tooltip}
          </Tooltip>:null}</div>
        ))
      }
    </div>
  );
}
