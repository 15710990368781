import React, {forwardRef, useEffect, useState} from "react";
import classNames from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PulseLoader from "react-spinners/PulseLoader";
import api from "services/v3";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal/Modal";
import {Content} from "components/shared/Slate";
import {useActivityDeleteMutation} from "services/mutations";
import {useSelfQuery} from "services/queries";
import modalTheme from "styles/modals/flat.module.scss";
import {mergeClassNames} from "styles/utils";
import {EventInterface} from "util/events";
import {status} from "util/interview/constants";
import {getActiveFeatures, getName, getFullName} from "util/placement/activeFeatures";
import baseStyles from "./offerInterviewModal.module.scss";
import {InterviewDate} from "components/shared/Modal/AddMeetingLinkModal";
import {useCreateInterviewMutation} from "services/mutations";
import { formatStartEndDateDayLong, formatStartEndDateDayLongSeparately, getTimeZoneFullName } from "util/date-time";
import {RichTextEditor, Heading, Subtitle} from "workfinder-components";
import { formatLink } from "util/string";
import _ from "lodash";

const styles = mergeClassNames(baseStyles, modalTheme);

const CustomDateInput = forwardRef(({value, onClick}, ref) => {
  return (
    <button
      className={styles.datePickerCustomInput}
      onClick={onClick}
      data-cy="select-availability-custom-input"
      ref={ref}
    >
      {!value && <div style={{ position: 'relative', display: 'flex'}}>Select date &amp; time</div>}
      {value && moment(value).format("DD/MM/YY hh:mm a")}
    </button>
  );
});
CustomDateInput.displayName = "CustomDateInput";

interface OfferInterviewData {
  candidate: {
    /** If `requestTime` is set, a message from the candidate should be passed. */
    candidateMessage?: string;
    /** The candidate ID. */
    id: number;
    /** The placement ID. */
    placementKey: number;
    /** The name of the project. Only used for display purposes. */
    project?: string;
    /** The status of the placement. */
    status: string;
    /** candidate user information */
    user: {
      first_name: string;
      last_name: string;
      full_name: string;
      initials: string;
    },
    /** Older interview in case of rescheduling */
    interview?: {
      uuid: string;
      status: string;
      interview_dates: InterviewDate[];
    }
  };
  /** The ID of the modal. */
  id: string;
  /**
   * The ID of a notication in the activity stream, which will be deleted
   * when dates are selected.
   */
  notificationId?: string;
  /** A callback to call when to modal closes. */
  onClose?: () => void;
  /** Whether the modal should be open. */
  open?: boolean;
  /** Whether the modal was prompted because a candidate requested a reschedule. */
  requestTime: boolean;
  /** Whether the modal was prompted because a host requested a reschedule */
  hostReschedule?: boolean
}

export const offerInterviewModalInterface = new EventInterface<OfferInterviewData | undefined>("offerInterviewModal");

function DateTimeInput({ onChange, onInputClick, value, onRemove, disabledRemove }) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginBottom: '1em', width: "100%" }}>
        <DatePicker
          placeholderText="Select date and time"
          dateFormat="Pp"
          selected={value}
          customInput={
            <div style={{ position: 'relative', flex: 1, border: '1px solid #CFD5E1', borderRadius: '4px', width: '100%', maxWidth: '320px' }}>
              <span className="material-icons-outlined" style={{ position: 'absolute', left: '5px', right: '30px', color:'#6F788B', top: '8px'}}>calendar_today</span>
              <CustomDateInput onClick={date => onChange(date)} value={value} style={{ width: '320px', paddingLeft: '25px', border: 'none' }} />
            </div>
          }
          onChange={onChange}
          onInputClick={() => {
            if (!value) {
              onChange(
                moment()
                  .minute(Math.floor(moment().minute() / 15 + 1) * 15)
                  .second(0)
                  .toDate(),
              );
            }
            onInputClick?.();
          }}
          showTimeSelect
          minDate={new Date()}
          filterTime={date => {
            const now = moment()
              .minute(Math.floor(moment().minute() / 15 + 1) * 15)
              .second(0)
              .millisecond(0);
            return now <= moment(date);
          }}
          timeIntervals={15}
          disabledKeyboardNavigation
          popperProps={{
            container: "body",
          }}
          portalId="interview-offer-modal"
          popperModifiers={[{
            name: "preventOverflow",
            options: {
              altAxis: true,
            },
          }]}
        />

      </div>
    {!disabledRemove ? (
    <button onClick={onRemove} style={{ backgroundColor: 'white', border: 'none', marginLeft: '10px', marginBottom: '10px' }}>
      <span className="material-icons-outlined">delete</span>
  </button>
    ) : (null)}
  </div>
  );
}


function DurationSelect({onChange, value}) {
  return (
    <select
      className={styles.select}
      value={value}
      onChange={e => onChange(e.target.value)}
      required={true}
    >
      <option key="default" value="0">Select duration</option>
      <option value="15">15min</option>
      <option value="30">30min</option>
      <option value="45">45min</option>
      <option value="60">60min</option>
      <option value="75">75min</option>
      <option value="90">90min</option>
    </select>
  );
}

export default function OfferInterviewModal({
  candidate, onClose, open, requestTime, notificationId, hostReschedule, ...modalProps
}: OfferInterviewData) {
  const {data: me} = useSelfQuery();
  const [firstStartDate, setFirstStartDate] = useState(null);
  const [secondStartDate, setSecondStartDate] = useState(null);
  const [thirdStartDate, setThirdStartDate] = useState(null);
  const [firstDuration, setFirstDuration] = useState("0");
  const [showSecondDate, setShowSecondDate] = useState(false);
  const [showThirdDate, setShowThirdDate] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState("");
  const activityMutation = useActivityDeleteMutation(notificationId);
  const isReschedule = requestTime || hostReschedule;

  const createInterviewMutation = useCreateInterviewMutation();

  function deleteNotification(notificationId) {
    activityMutation.mutate(notificationId);
  }

  // Reset when a different candidate is actioned.
  useEffect(reset, [candidate]);

  let initialsOrName = "";
  if (candidate) {
    const activeFeatures = getActiveFeatures(me, candidate);
    initialsOrName = candidate?.user && getName({activeFeatures, user: candidate.user});
  }

  function reset() {
    setFirstStartDate(null);
    setSecondStartDate(null);
    setThirdStartDate(null);
    setFirstDuration("0");
    setShowSecondDate(false);
    setShowThirdDate(false);
    setLocation("");
    setMessage("");
    setDisplayThankYouMessage(false);
    if(isReschedule) {
setMessage(`Hi ${initialsOrName},

Apologies but we need to reschedule our confirmed interview. We’d like to progress your application and we are offering to interview you via video at the following new time(s).

We hope that you are available.

Looking forward to speaking with you,
${Variables.user?.full_name}`);
    }
    else {
    setMessage(`Hi ${initialsOrName}

Thank you for applying for a position with us via Superpower, we’d like to progress your application and we are offering to interview you via video at the following time(s).

We hope that you are available and look forward to seeing you, if you are.

Looking forward to speaking with you.`);
    }
  }

  async function handleSendOffer() {
    try {
      let interview_dates = [];
      if(firstStartDate)
        // @ts-ignore
        interview_dates.push({
          date_time: firstStartDate,
          duration: parseInt(firstDuration),
        });
      if(secondStartDate)
        // @ts-ignore
        interview_dates.push({
          date_time: secondStartDate,
          duration: parseInt(firstDuration),
        });
      if(thirdStartDate)
        // @ts-ignore
        interview_dates.push({
          date_time: thirdStartDate,
          duration: parseInt(firstDuration),
        });

      setIsProcessing(true);

      api.updateUserDetectedFields({timezone: moment.tz.guess()});
      await createInterviewMutation.mutateAsync({
        data: {
          status: status.INTERVIEW_OFFERED,
          placement: candidate.placementKey,
          offer_message: message,
          candidate: candidate.id,
          reason_desqualified: null,
          meeting_link: location,
          additional_offer_note: null,
          offer_starting_date: null,
          offer_end_date: null,
          interview_dates: interview_dates,
          old_interview: isReschedule ? candidate.interview?.uuid : undefined,
          interviewer_uuid: Variables.user.uuid,
        }
      });

      setIsProcessing(false);
      setDisplayThankYouMessage(true);
      if (requestTime && notificationId) {
        deleteNotification(notificationId);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  function renderDateSingleLine(date: {date_time: Date, duration: number} | undefined) {
    return <>{formatStartEndDateDayLong(date?.date_time, date?.duration)}</>;
  }

  function renderDateMultiLine(date: {date_time: Date, duration: number} | undefined) {
    const dateTimeString = formatStartEndDateDayLongSeparately(date?.date_time, date?.duration)
    return <>
      <Subtitle size="large" className="d-inline">{dateTimeString.date}</Subtitle>
      <Subtitle size="large">{dateTimeString.time}</Subtitle>
    </>;
  }

  function renderOldInterviewDate () {
    const isAccepted = [status.INTERVIEW_ACCEPTED, status.MEETING_LINK_ADDED].includes(candidate.interview?.status);
    if(isAccepted)
    {
      const selectedDate = candidate.interview?.interview_dates.filter(
        date => date.status === "selected",
      )[0];
      return (
        <>
          <Subtitle size="small">Your interview with {candidate.user?.first_name} is currently scheduled for:</Subtitle>
          <Subtitle size="small"><b>{renderDateSingleLine(selectedDate)}</b></Subtitle>
        </>
      );
    }
    else {
      return(
        <>
        <Subtitle size="small">You have offered {candidate.user?.first_name} following time(s) for interview:</Subtitle>
        {_.orderBy(candidate?.interview?.interview_dates ?? [], ["date_time"])?.map(date =>
          <Subtitle size="small"><b>{renderDateSingleLine(date)}</b></Subtitle>
        )}
        </>
      );
    }
  }

  if (displayThankYouMessage) {
    let fullName = "";
    if (candidate) {
      fullName = getFullName({user: candidate?.user});
    }

    return (
      <Modal className={classNames(styles.mobileBottom, styles.extraTall, styles.modalWidthMd)} theme={styles} onClose={onClose}
        open={open} {...modalProps}
      >
        <Content className={classNames(styles.content)}>
          <Heading size="h5" className={styles.blue}>Thank you!</Heading>
          <Heading size="h5" className="mb-3">Your interview offer has been sent to {candidate?.user?.full_name || candidate?.user?.first_name}</Heading>
          <div className={classNames("row mb-3", styles.modalContainer)}>
            <div className={classNames("col-12", "col-md-6")}>
              <Heading size="h5" className={styles.iconHeader}>
                <span className="material-icons-outlined">schedule</span>You offered the following time(s):
              </Heading>
              <ol className={styles.details}>
                {firstStartDate && <li>{renderDateMultiLine({date_time: firstStartDate, duration: +firstDuration})}</li>}
                {secondStartDate && <li>{renderDateMultiLine({date_time: secondStartDate, duration: +firstDuration})}</li>}
                {thirdStartDate && <li>{renderDateMultiLine({date_time: thirdStartDate, duration: +firstDuration})}</li>}
              </ol>
            </div>
            <div className={classNames("col-12", "col-md-6")}>
              <Heading size="h5" className={styles.iconHeader}>
                <span className="material-icons-outlined">location_on</span>Interview location
              </Heading>
              <div
                dangerouslySetInnerHTML={{__html: location ? formatLink(location, styles.meetingLink) : ''}}
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word'
                }}
                className={styles.details}
              />
            </div>
          </div>
          <Heading size="h5">We have sent you an email of your interview offer.<br/>
            We will notify you as soon as {candidate?.user?.full_name || candidate?.user?.first_name} has accepted your interview offer.</Heading>
          <div className="d-flex flex-row-reverse mb-1 mt-2">
            <Button
              kind="blue-primary"
              onClick={() => {
                onClose();
                open=false;
              }}
            >
              Close
            </Button>
          </div>
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      id="interview-offer-modal"
      theme={styles}
      onClose={onClose}
      open={open}
      className={classNames(styles.mobileBottom, styles.extraTall, styles.modalWidthLg)}
      {...modalProps}
    >
    { open &&
      <Content className={styles.content}>
        <Heading size="h5" className={styles.blue}>{isReschedule ? "Reschedule interview" : "Offer interview"}</Heading>
        <Heading size="h5" className="mb-3">{initialsOrName}<span className={styles.projectName}> &#8226; {candidate.project}</span></Heading>
        {isReschedule && candidate.interview?.uuid &&
          <div className={classNames(styles.oldInterview, "mb-3")}>
            {renderOldInterviewDate()}
          </div>
        }
        {requestTime &&
          <>
            <div className={styles.messageHeader}>Message from {initialsOrName}</div>
            <Subtitle size="small">
              <div dangerouslySetInnerHTML={{ __html: candidate.candidateMessage }}></div>
            </Subtitle>
          </>
        }
        <div className={classNames("row", styles.modalContainer)}>
          <div className={classNames("col-12", "col-sm-9", "col-md-5", "py-3", "my-2")}>
            <Heading size="h5" className={styles.iconHeader}><span className="material-icons-outlined">schedule</span>Schedule a specific time</Heading>
            <div className="content">
              <div className="interviewDuration">
                Interview duration
                <DurationSelect value={firstDuration} onChange={setFirstDuration} />
              </div>
              <div className={classNames(styles.dateContainer)}>
                <Subtitle size="large" className="mb-1">
                  {isReschedule ? "To reschedule this interview suggest up to 3 new timeslots:" : "Suggest up to 3 new timeslots:"}
                </Subtitle>
                <DateTimeInput value={firstStartDate} onChange={setFirstStartDate} disabledRemove={true}/>
                {showSecondDate &&
                  <DateTimeInput
                    value={secondStartDate}
                    onChange={setSecondStartDate}
                    onRemove={() => {
                      setShowSecondDate(false);
                      setSecondStartDate(null);
                    }}
                    disabledRemove={!firstStartDate || isProcessing}
                  />
                }
                {showThirdDate &&
                  <DateTimeInput
                    value={thirdStartDate}
                    onChange={setThirdStartDate}
                    onRemove={() => {
                      setShowThirdDate(false);
                      setThirdStartDate(null);
                    }}
                    disabledRemove={!firstStartDate || isProcessing}
                  />
                }
                {(!showSecondDate || !showThirdDate) &&
                  <button
                    disabled={!firstStartDate || isProcessing}
                    onClick={() => {
                      showSecondDate ? setShowThirdDate(true) : setShowSecondDate(true);
                    }}
                    className={classNames("btn", styles.buttonAddDate)}
                  >
                    <i className="wf-icon wf-plus mr-1"></i>Suggest another time
                  </button>
                }
                <Subtitle size="small" className="mb-1">
                  The candidate will be asked to confirm one option
                </Subtitle>
              </div>
              <Subtitle size="small"><b>Timezone:&nbsp;</b>{getTimeZoneFullName()}</Subtitle>
            </div>
          </div>
          <div className={classNames("col-12", "col-sm-9", "col-md-7", "px-4", "my-2")}>
            <div className="row">
              <div className={classNames("col-12 py-3", styles.blueBackground)}>
                <Heading size="h5" className={styles.iconHeader}><span className="material-icons-outlined">location_on</span>Interview location</Heading>
                <Subtitle size="small" className="mb-1">
                  Please provide the meeting link for your interview below. You can amend this at any time later.
                </Subtitle>
                <RichTextEditor
                  placeholder="Paste Your meeting link here"
                  hideToolbar
                  value={location ?? ""}
                  onChange={e => {
                      setLocation(e);
                    }}
                />
                <Heading size="h5" className={styles.iconHeader}><span className="material-icons-outlined">mail</span>Message to {initialsOrName}</Heading>
                <Subtitle size="small" className="mb-1">
                  Include information about the interview:
                </Subtitle>
                <textarea
                  placeholder="Add a message here"
                  className={styles.textarea}
                  onChange={e => {
                    setMessage(e.target.value);
                  }}
                  rows="4"
                  cols="110"
                  value={message ?? ""}
                >
                </textarea>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex flex-row-reverse mb-1 mt-2">
              <Button className={styles.sendOfferButton} kind="blue-primary" disabled={!(firstStartDate && +firstDuration > 0) || (showSecondDate && !secondStartDate) || (showThirdDate && !thirdStartDate) || !location || !message || isProcessing} onClick={handleSendOffer}>
                { isProcessing ? <PulseLoader color="#fff" margin={2} size={6}/> : (isReschedule ? "Send new time" : "Offer interview")}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    }
    </Modal>
  );
}
