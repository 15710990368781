/*
 * Thrown when a switch than is expected to never default defaults.
 */
export class DefaultCaseError extends Error {
  constructor(message="Switch unexpectedly defaulted. The current page is set to an invalid value.") {
    super(message);
    this.name = "DefaultCaseError";
  }
}

export class ValidationError extends Error {
  constructor(message="Validation failed.") {
    super(message);
    this.name = "ValidationError";
  }
}

export class APIError extends Error {
  constructor(message="An API error occurred.") {
    super(message);
    this.name = "ValidationError";
  }
}
