import * as sentry from "@sentry/react";
import "proxy-polyfill";


const env_check = process.env.NODE_ENV === "production" && !!(
  process.env.SENTRY_JS_URL && process.env.SENTRY_JS_URL !== "0"
);

// Proxy sentry, such that every call to Sentry module methods goes through an
// environment check passes. If the check fails, a dummy function is returned
// to prevent usage of Sentry breaking the app when it's disabled.
const Sentry = new Proxy(sentry, {
  // Some members should always be available, e.g. React components that must
  // render something.
  ALWAYS_AVAILABLE: ["ErrorBoundary"],

  get: function(_, prop) {
    if (this.ALWAYS_AVAILABLE.includes(prop) || env_check) {
      return Reflect.get(...arguments);
    }
    return () => {};
  },
});

Sentry.init({
  dsn: process.env.SENTRY_JS_URL,
  release: process.env.RELEASE,
  // A list of ignores derived from https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Network errors and user cancels
    "NetworkError when attempting to fetch resource",
    "The network connection was lost",
    "Network request failed",
    "Failed to fetch",
    "NetworkError",
    "TypeError: Cancelled",
    "withrealtime/messaging",
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    "ResizeObserver loop limit exceeded",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

if (Variables.isAuthenticated) {
  Sentry.setUser({
    id: Variables.user.uuid,
    username: Variables.user.full_name,
    email: Variables.user.email,
  });
}

export default Sentry;
