import React, {useMemo} from "react";
import classNames from "classnames";
import _ from "lodash";

import Tick from "/static/workfinder/images/icons/white_tick.svg";
import PillList from "components/shared/PillList";
import Typeahead from "components/shared/Typeahead";
import {useTechSkillsQuery} from "services/queries";

import styles from "./techSkillInput.module.scss";

const CATEGORIES = [
  "Technology",
  "Business",
  "Data Science",
];

interface Skill {
  /** The name of the skill, which will be displayed. */
  name: string;
  /** The category the skill belongs to. */
  category: string | null;
}

interface Props {
  /** Maximum number of selected items allowed. */
  maxLength?: number;
  /** The name for the input. */
  name: string;
  /** The callback to call when the value changes. */
  onChange: (value: string[], name: string) => void;
  /** The selected values. */
  value: string[];
  /** css class of pill list */
  pillListClassName?: string;
}

const MobileTable = ({categorisedSkills, handleChange, value}) => {
  if (!Object.entries(categorisedSkills).length) {
    return null;
  }
  return (
    <div className={classNames(styles.techSkillInput, "d-lg-none")}>
      {CATEGORIES.map(
        category => (
          <div key={category} className={styles.skillsCategory}>
            <div className={styles.category}>{category}</div>
            {categorisedSkills[category].map(({name}) => {
              const isSelected = value.includes(name);
              return (
                <div
                  className={classNames(styles.pill, {[styles.selected]: isSelected})}
                  onClick={() => handleChange(name)}
                  key={`${category}-${name}`}
                >
                  {isSelected ? <img src={Tick}/> : "+ "} {name}
                </div>
              );
            })}
          </div>
        ),
      )}
    </div>
  );
};


export default function TechSkillInput(props: Props): JSX.Element {
  const {maxLength, name, onChange, value, pillListClassName} = props;
  const {data: techSkills, isLoading} = useTechSkillsQuery();
  const categorisedSkills = useMemo<{[category: string]: Skill[]}>(
    () => _.groupBy(techSkills?.sort((a, b) => a.value > b.value ? 1 : -1) ?? [], "category"),
    [techSkills],
  );
  const pillValues = value.filter(item => categorisedSkills[null]?.map(option => option.name).includes(item)) ?? [];
  const tableHeight = 10; //Only ten rows
  const limitReached = Boolean(maxLength && value.length >= maxLength);

  function handleChange(clickedValue) {
    if (value.includes(clickedValue)) {
      onChange(value.filter(item => item !== clickedValue), name);
    }
    else {
      if (!maxLength || value.length < maxLength) {
        onChange([...value, clickedValue], name);
      }
    }
  }

  /**
   * The PillList component returns the new value of the list in its `onChange`
   * listener, but we need to know what item was removed instead.
   */
  function handleListChange(newList) {
    const removedElement = pillValues.find(item => !newList.includes(item));
    handleChange(removedElement);
  }

  return (
    <div className={styles.tableContainer}>
      <MobileTable
        categorisedSkills={categorisedSkills}
        handleChange={handleChange}
        value={value}
      />
      <table className={classNames(styles.techSkillInput, {[styles.limitReached]: limitReached}, "d-none d-lg-table")}>
        <thead>
          <tr>
            {CATEGORIES.map(category => <td key={category}>{category}</td>)}
          </tr>
        </thead>
        <tbody>
          {
            isLoading ?
              <tr><td colSpan={3} className={styles.loadingRow}>Loading...</td></tr> :
              Array(tableHeight).fill(undefined).map((_, i) =>
                <tr key={i}>
                  {
                    CATEGORIES.map(category => {
                      const skill = categorisedSkills[category][i]?.name;
                      if (skill) {
                        const isSelected = value.includes(skill);
                        return (
                          <td
                            className={classNames({[styles.selected]: isSelected})}
                            key={category}
                            onClick={() => handleChange(skill)}
                          >
                            {isSelected ? <img src={Tick}/> : "+ "} {skill}
                          </td>
                        );
                      }
                      return <td key={category} className={styles.empty}></td>;
                    })
                  }
                </tr>,
              )
          }
        </tbody>
      </table>
      <PillList
        className="ml-2 mt-3"
        enableDelete
        onChange={handleListChange}
        value={pillValues}
        className={pillListClassName}
      />
      <p className="mt-2">Can&apos;t find what skill you are looking for?</p>
      <Typeahead
        disabled={limitReached}
        id="tech-skill-typeahead"
        onChange={handleChange}
        options={(techSkills?.map(skill => skill.name).filter(skill => !value.includes(skill)) ?? [])}
        placeholder={limitReached ? "Limit reached" : "Search skills to add"}
      />
    </div>
  );
}
