import React, {forwardRef, MouseEvent} from "react";
import classNames from "classnames";
import {mergeClassNames} from "styles/utils";

import baseStyles from "./dropdownMenu.module.scss";

// Makes sure only `icon` or `iconClass` is set.
type IconOrIconClass = {
  /** The icon to render. */
  icon?: React.ReactNode
} | {
  /**
   * The class name to render an icon, e.g. FontAwesome classes, Ignored if
   * `icon` is passed.
   */
  iconClass?: string;
};

interface BaseDropdownItem {
  /**
   * A key for the item. If not provided, an attempt will be made to extract
   * one from the label.
   */
  key?: string;
  /** The text to display on the dropdown item. */
  label: React.ReactNode;
  /** A callback to call when the dropdown item is clicked. */
  onClick?: (e: MouseEvent) => void;
  /** Whether the item is disabled or not */
  disabled?: boolean;
}

interface AnchorDropdownItem extends BaseDropdownItem {
  /** Attributes to add to the button or anchor. */
  attrs?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  /** The link to navigate to when the dropdown item is click. */
  href?: string;
}

interface ButtonDropdownItem extends BaseDropdownItem {
  /** Attributes to add to the button or anchor. */
  attrs?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

interface ReactNodeDropdownItem {
  /**
   * A key for the item.
   */
  key: string;
  /**
   * A react node as a link, useful particularly when using <NavLink>
   */
  element: React.ReactNode
}

export type DropdownItem = (AnchorDropdownItem | ButtonDropdownItem & IconOrIconClass) | ReactNodeDropdownItem;


interface Props {
  /** A list of items to display. */
  items: DropdownItem[];
  /** A theme to restyle the dropdown menu. */
  theme?: Record<string, string>;
  /** The ID of the toggle for this dropdown menu. */
  toggleId: string;
}


const DropdownMenu = forwardRef<any, Props>((props, ref): JSX.Element => {
  const {items, theme, toggleId, ...menuProps} = props;
  const styles = mergeClassNames(baseStyles, theme);

  function makeKey(item) {
    if ("key" in item) {
      return item.key;
    }
    if (typeof item.label === "string") {
      return item.label.toLocaleLowerCase().replaceAll(" ", "-");
    }
    return item;
  }

  return (
    <ul
      className={classNames("dropdown-menu", styles.dropdown)}
      aria-labelledby={toggleId}
      ref={ref}
      {...menuProps}
    >
      {
        items.map(item => {
          let icon: React.ReactNode = null;

          if ("icon" in item) {
            icon = <div className={styles.icon}>{item.icon}</div>;
          }
          else if ("iconClass" in item) {
            icon = <div className={styles.icon}><i className={item.iconClass}/></div>;
          }

          return (
            <li key={makeKey(item)}>
              {
                "element" in item ?
                  item.element
                :("href" in item ?
                  <a {...item.attrs} className="dropdown-item" type="button" href={item.href}>
                    {icon}{item.label}
                  </a>
                  :
                  <button {...item.attrs} disabled={item.disabled} className="dropdown-item" type="button" onClick={item.onClick}>
                    {icon}{item.label}
                  </button>)
              }
            </li>
          );
        })
      }
    </ul>
  );
});
DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
