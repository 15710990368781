import React from "react";
import { Heading, Subtitle } from "workfinder-components";
import styles from "./noData.module.scss";
import classNames from "classnames";

const NoData = ({title, subtitle}) => {
  return <div className={classNames(styles.noData ,"card mt-1 mb-3")}>
    <div className="card-body">
      <Heading size="h5" className="mb-2 d-flex justify-content-center">{title}</Heading>
      <Subtitle className="card-text d-flex justify-content-center">{subtitle}</Subtitle>
    </div>
  </div>;
}

export default NoData;
