import React from "react";
import "./button.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  buttonType: "primary" | "secondary" | "tertiary" | "red" | "dark";
  onClickFunction?: any;
  selected?: boolean;
  className?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({text, buttonType, onClickFunction, disabled, selected, className, children, loading, ...props}, ref) => {
    const state = disabled ? "disabled" : "";
    const isSelected = selected ? "selected" : "";
    const classNameString = className ? className : "";

    return (
      <button ref={ref} onClick={onClickFunction} className={`button-${buttonType} ${state} ${isSelected} ${classNameString}`}
        {...props}
      >
        {loading ? <img className="nps_score__loading-spinner" src="/loading.gif" alt="loading" /> : <>
          {text}
          {children}
        </>}
      </button>
    );
  },
);
Button.displayName = "Button";

export default Button;
