import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import useStateRef from "hooks/useStateRef";

import useConstraintValidation from "./useConstraintValidation";


function TextArea({className, name, value, onBlur, onChange, onFocus, validator, errorClassName, touchedClassName, validClassName, ...props}) {
  const [ref, setRef] = useStateRef();
  const {
    className: constraintClassName,
    setFocus,
    errorPopup,
  } = useConstraintValidation({
    name,
    value,
    validator,
    ref,
    errorClassName,
    touchedClassName,
    validClassName,
  });

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e);
    }
    setFocus(false);
  }

  function handleChange(e) {
    const {name, value} = e.target;
    onChange(value, name, e);
  }

  function handleFocus(e) {
    if (onFocus) {
      onFocus(e);
    }
    setFocus(true);
  }

  return (
    <>
      <textarea
        {...props}
        className={classNames( className, constraintClassName )}
        ref={setRef}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        value={value}
      />
      {errorPopup}
    </>
  );
}

TextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  validator: PropTypes.func,
  value: PropTypes.any,
  errorClassName: PropTypes.string,
  touchedClassName: PropTypes.string,
  validClassName: PropTypes.string,
};

export default TextArea;
