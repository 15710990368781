import _ from "lodash";
import {ObjectSchema, ValidationError} from "yup";

import {fromListEntries} from "util/object";
import {messages} from "components/shared/AlertsProvider";

/** Get the first name of a yup path. */
export function getFirstName(path: string): string {
  return path.split(/[.[]/)[0];
}

/**
 * Convert a yup `ValidationError`, resulting from the validation of an object
 * schema, into a object matching the upper-most name to its errors.
 */
export function yupErrorToMapping(validationError: ValidationError): Record<string, string[]> {
  if (validationError.inner && validationError.inner.length > 0) {
    return Object.fromEntries(validationError.inner
      .map(error => [error.path && getFirstName(error.path), error.errors])
      .filter(([name, _]) => !!name),
    );
  }
  else if (validationError.errors && validationError.errors.length > 0) {
    return {
      "global_errors": validationError.errors
    }
  }
}

/**
 * Convert a yup `ValidationError`, resulting from the validation of an object field
 */
export function yupOneFieldErrorToMapping(validationError: ValidationError, fieldName: string): Record<string, string[]> {
  if (validationError.errors && validationError.errors.length > 0) {
    return {
      [fieldName]: validationError.errors
    }
  }
}

/**
 * Convert an API error, resulting from making a failed API call, into a
 * mapping of errors to field names. Field names must be specified in a yup
 * schema. Any API errors with keys that are not in the yup schema are assigned
 * to the `miscellaneous` key.
 */
export function apiErrorToMapping(apiError, schema: ObjectSchema): Record<string, string[]> {
  if (!apiError.json) {
    return {miscellaneous: [messages.updateError]};
  }
  return fromListEntries(
    Object.entries(apiError.json)
      .map(([k, v]) => [
        k in schema.fields ? k : "miscellaneous",
        Array.isArray(v) ? v : [v],
      ]),
  );
}

/**
 * Scroll the top error into view. This function scrolls to `{input_name}_id`.
 */
export function scrollToErrors(errors: {[name: string]: string[]}): void {
  if (!_.isEmpty(errors)) {
    const selector = Object.keys(errors).map(name => `[data-error-for="${name}"]`).join(",");
    const elements = document.querySelectorAll(selector);
    let topElement;
    let minOffset = Infinity;
    for (const el of elements) {
      if (el.offsetTop < minOffset) {
        minOffset = el.offsetTop;
        topElement = el;
      }
    }
    topElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}
