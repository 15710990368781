import moment from "moment-timezone";

// Timezone

export function getTimeZoneAbbr() {
  /**
   * Returns the abbreviation for the computer time zone.
   *
   * @return {string} The abbreviation for the time zone.
   * If the abbreviation does not start with "+" or "-",
   * it is returned as is. Otherwise, it is formatted as
   * "GMT" followed by the time zone's offset from UTC.
   */
  const guessedTimeZone = moment.tz.guess();
  const abbr = moment().tz(guessedTimeZone).zoneAbbr();
  if (!abbr.startsWith("+") && !abbr.startsWith("-")) {
    return abbr;
  }
  else {
    return `GMT${moment().tz(guessedTimeZone).format("Z")}`;
  }
}

export function getTimeZoneFullName(brackets=true) {
  const guessedTimeZone = moment.tz.guess();
  if (brackets) {
    return `${getTimeZoneAbbr()} (${guessedTimeZone})`;
  }
  else {
    return `${getTimeZoneAbbr()} ${guessedTimeZone}`;
  }
}

// Single date

function getDateMoment(dateTime) {
  const startDate = dateTime && moment(dateTime);
  return startDate;
}

export function formatDateTimeShort(dateTime) {
  const startDate = getDateMoment(dateTime);
  return startDate && `${startDate.format("DD MMM YYYY, hh:mma")} ${getTimeZoneAbbr()}`;
}

export function formatDateTimeLong(dateTime) {
  const startDate = getDateMoment(dateTime);
  return startDate && `${startDate.format("DD MMMM YYYY [at] hh:mma")} ${getTimeZoneAbbr()}`;
}

export function formatDateTimeDayLong(dateTime) {
  const startDate = getDateMoment(dateTime);
  return startDate && `${startDate.format("ddd, Do MMM YYYY, h:mm a")} ${getTimeZoneAbbr()}`;
}

export function formatDateTimeSeparately(dateTime) {
  const startDate = getDateMoment(dateTime);
  return {
    date: startDate && startDate.format("DD MMM YYYY"),
    time: startDate && `${startDate.format("hh:mm A")} ${getTimeZoneAbbr()}`,
  };
}

export function formatDateShort(date) {
  const jsDate = new Date(date);
  const momentDate = getDateMoment(jsDate);
  return momentDate.format("DD/MM/YYYY");
}

export function formatDateLong(date) {
  const jsDate = new Date(date);
  const momentDate = getDateMoment(jsDate);
  return momentDate.format("D MMM YYYY");
}

// Range of datetimes

/**
 * @return {boolean}
*/
export function isPast(startDate, duration = 30)  {
  const startTime = new Date(startDate);
  const endTime = moment(startTime).add(duration, "m").toDate();
  const now = new Date();
  const isPast = now > endTime;
  return isPast;
}

/**
 * @return {object}
*/

function getStartAndEndDateMoment(dateTime, duration) {
  const startDate = getDateMoment(dateTime);
  const endDate = dateTime && duration && startDate.clone().add(duration, "minutes");
  return {
    startDate: startDate,
    endDate: endDate,
  };
}

export function formatStartEndDateDayLongSeparately(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  return {
    date: momentDates.startDate && momentDates.startDate.format("dddd, D MMMM YYYY"),
    time: momentDates.startDate && momentDates.endDate && `${momentDates.startDate.format("hh:mm")} - ${momentDates.endDate.format("hh:mm A")} ${getTimeZoneAbbr()}`,
  };
}

export function formatDateTimeDaySeparately(dateTime, duration) {
  const startDate = getDateMoment(dateTime);
  return {
    date: startDate && startDate.format("ddd, Do MMM YYYY"),
    time: startDate && duration && formatStartEndTime12Hours(dateTime, duration),
  };
}

export function formatDateTimeDaySeparatelyOther(dateTime, duration) {
  const startDate = getDateMoment(dateTime);
  const endDate = moment(dateTime).add(duration, "minutes");
  return {
    date: startDate && startDate.format("dddd, D MMMM"),
    time: startDate && duration && `${startDate.format("h:mm A")} - ${endDate.format("h:mma")}`,
  };
}

/**
 * @return {string}
*/

export function formatStartEndDateShort(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  const startDateTimeString = momentDates.startDate?.format("D MMM YYYY, h:mm") ?? null;
  const endTimeString = momentDates.endDate.format("h:mma") ?? null;
  return startDateTimeString && `${startDateTimeString}-${endTimeString} ${getTimeZoneAbbr()}`;
}

export function formatStartEndDateShortWithDot(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  const startDateTimeString = momentDates.startDate?.format("D MMM YYYY • h:mm") ?? null;
  const endTimeString = momentDates.endDate.format("h:mma") ?? null;
  return startDateTimeString && `${startDateTimeString}-${endTimeString} ${getTimeZoneAbbr()}`;
}

export function formatStartEndDateLong(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  const startDateTimeString = momentDates.startDate?.format("DD MMMM YYYY • hh:mm A") ?? null;
  const endTimeString = momentDates.endDate?.format("hh:mm A") ?? null;
  return startDateTimeString && `${startDateTimeString} - ${endTimeString} ${getTimeZoneAbbr()}`;
}

export function formatStartDateDurationLong(dateTime, duration) {
  const startDate = getDateMoment(dateTime);
  return startDate && duration && `${startDate.format("D MMM YYYY [at] h:mma")} ${getTimeZoneAbbr()} for ${duration} min`;
}

export function formatStartEndDateDayLong(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  const startDateTimeString = momentDates.startDate?.format("dddd, D MMMM YYYY, hh:mm") ?? null;
  const endTimeString = momentDates.endDate?.format("hh:mm A") ?? null;
  return startDateTimeString && `${startDateTimeString} - ${endTimeString} ${getTimeZoneAbbr()}`;
}

export function formatDayMonthTimeRange(dateTime, duration) {
  const startDate = getDateMoment(dateTime);
  const formattedStartDate = startDate.format("D MMM, h:mm");
  const endDate = startDate.add(duration, "minutes");
  const formattedEndTime = endDate.format("h:mma");
  return `${formattedStartDate} - ${formattedEndTime}`;
}

export function formatStartEndTime24Hours(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  return momentDates.startDate && momentDates.endDate && `${momentDates.startDate.format("HH:mm")} - ${momentDates.endDate.format("HH:mm")} ${getTimeZoneAbbr()}`;
}

export function formatStartEndTime12Hours(dateTime, duration) {
  const momentDates = getStartAndEndDateMoment(dateTime, duration);
  return momentDates.startDate && momentDates.endDate && `${momentDates.startDate.format("h:mm")} - ${momentDates.endDate.format("h:mma")} ${getTimeZoneAbbr()}`;
}

export function formatDateDurationRange(dateTime, duration) {
  const {startDate, endDate} = getStartAndEndDateMoment(dateTime, duration);
  if (!startDate || !endDate) {
    return null;
  }
  return `${startDate.format("dddd, D MMM ⋅ h:mm")} – ${endDate.format("h:mma")}`;
}


/**
 * @param {string} dateTime - 2024-02-02T23:30:00Z
 * @param {number} duration - 60
 *
 * @returns {string} - Saturday, 3 Feb ⋅ 10:15 – 11:15pm
 */
export function formatWeekdayStartEndTime12Hours(dateTime, duration) {
  const startTime = moment(dateTime).format("h:mm A");
  const endTime = moment(dateTime).add(duration, "minutes").format("h:mm A");
  const formattedDate = moment(dateTime).format("ddd D MMM");
  return `${formattedDate}, ${startTime} – ${endTime} (${getTimeZoneAbbr()})`;
}
