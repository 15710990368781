import React, {useEffect, useState} from "react";

import LoadingSpinner from "components/shared/LoadingSpinner";
import api from "services/v3";

import styles from "./projectPickerContainer.module.scss";
import ProjectPickerPage from "./ProjectPickerPage";

function ProjectPickerContainer() {
  const [loading, setLoading] = useState(true);
  const [projectTemplates, setProjectTemplates] = useState();

  async function loadInitialData() {
    const templates = await api.getProjectTemplates();
    const projectTemplates = templates.results;
    setProjectTemplates(projectTemplates);
    setLoading(false);
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    loading ?
      <div className={styles.spinner}><LoadingSpinner /></div> :
      <ProjectPickerPage projectTemplates={projectTemplates} />
  );
}

export default ProjectPickerContainer;
