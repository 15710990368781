import {useCallback, useMemo, useState, Dispatch, SetStateAction} from "react";
import {isEqual} from "lodash";

/*
 * A hook to create a state variable whose changes from the initial value are
 * tracked.
 */
export default function useTrackedState<T>(dataInit?: T): [T, Dispatch<SetStateAction<T>>, Dispatch<SetStateAction<T>>, boolean] {
  const [data, setData] = useState<T>(dataInit);
  const [referenceData, setReferenceData] = useState<T>(dataInit);
  const hasChanged = useMemo(() => !isEqual(data, referenceData), [data, referenceData]);

  const _setReferenceData = useCallback((...args) => {
    setData(...args);
    setReferenceData(...args);
  }, [setData, setReferenceData]);

  return [data, setData, _setReferenceData, hasChanged];
}
