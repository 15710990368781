import {DropdownOption} from "components/shared/Form/Dropdown";

/** Picklist keys don't align with options, so need to be mapped. */
export function picklistToOptions(data?: PickList[]): DropdownOption[] {
  if (data == null) {
    return data;
  }
  return data.map(option => (
    {
      label: option.name,
      value: option.uuid,
    }
  ));
}

/**
 * Convert a picklist item UUID to its corresponding name.
 */
export function uuidToName(uuid: string, picklist: PickList[]): string | undefined {
  return picklist.find(item => uuid === item.uuid)?.name;
}

/**
 * Convert a list of picklist item UUIDs to their corresponding names.
 */
export function uuidsToNames(uuids: string[], picklist: PickList[]): string[] {
  return picklist.filter(item => uuids.includes(item.uuid)).map(item => item.name);
}
