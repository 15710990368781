import React, { useEffect, useMemo, useRef, useState } from "react";
import DropdownMenu from "components/shared/DropdownMenu";
import Twitter from "/static/workfinder/images/icons/my-projects-quick-actions/twitter.svg";
import Facebook from "/static/workfinder/images/icons/my-projects-quick-actions/facebook.svg";
import Linkedin from "/static/workfinder/images/icons/my-projects-quick-actions/linkedin.svg";
import Email from "/static/workfinder/images/icons/my-projects-quick-actions/email.svg";
import CopyURL from "/static/workfinder/images/icons/my-projects-quick-actions/copy-url.svg";
import {Button} from "workfinder-components";
import {v4 as uuidv4} from "uuid";
import styles from "./socialMediaShareDropdown.module.scss";

const SocialMediaShareDropdown = ({text, message, title, disabled=false, toggler=<i className="ri-share-line"/>, commonSocialMediaActions=() => {}, showCopyLink=true, linkCopiedText="Link copied"}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const ref = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const uuid = useMemo<string>(uuidv4, []);
  const componentId = `social-media-${uuid}`;
  const encodedText = encodeURIComponent(text);
  useEffect(() => setDropdownHeight(ref.current.clientHeight));

  const socialMediaItems = [
    {
      icon: <img src={Twitter} alt="Twitter" />,
      //icon: <i className="mr-3 ri-twitter-x-fill"></i>,
      label: "Twitter",
      onClick: () => {
        commonSocialMediaActions();
        window.open(`https://twitter.com/share?text=${message}`, "_blank");
      },
    },
    {
      icon: <img src={Facebook} alt="Facebook" />,
      //icon: <i className="mr-3 ri-facebook-fill"></i>,
      label: "Facebook",
      onClick: () => {
        commonSocialMediaActions();
        window.open(`https://facebook.com/sharer/sharer.php?u=${encodedText}`, "_blank");
      },
    },
    {
      icon: <img src={Linkedin} alt="Linkedin" />,
      //icon: <i className="mr-3 ri-linkedin-fill"></i>,
      label: "Linkedin",
      onClick: () => {
        const link = `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodedText}&amp;source=${encodedText}&amp;title=${title}&amp;summary=${message}`.replaceAll(" ", "").replaceAll("%2F", "/").replaceAll("&amp;", "&");
        commonSocialMediaActions();
        window.open(link, "_blank");
      },
    },
    {
      icon: <img src={Email} alt="Email" />,
      //icon: <i className="mr-3 ri-mail-fill"></i>,
      label: "Email",
      onClick: () => {
        commonSocialMediaActions();
        location.href = `mailto:test@example.com?subject=${title}&body=${message}`;
      },
    }
  ];
  if(showCopyLink) {
    socialMediaItems.push(
      {
        icon: <img src={CopyURL} alt="CopyURL" />,
        label: "Copy URL",
        onClick: () => {
          setLinkCopied(true);
          setTimeout(() => {
            setLinkCopied(false);
            commonSocialMediaActions();
          }, 1500);
          navigator.clipboard.writeText(text);
        },
      }
    )
  }

  return (<div className="dropdown position-relative">
    <Button
      id={componentId}
      kind="tertiary"
      className="dropdown-toggle p-0 m-0"
      disabled={disabled}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <>{toggler}</>
    </Button>
    <DropdownMenu
      items={socialMediaItems}
      toggleId={componentId}
      ref={ref}
    />
    <>{linkCopied && <div
      className={styles.tooltip}
      style={{position: "absolute", left: 0, top: dropdownHeight+40, zIndex: 35}}
    >
      {linkCopiedText}
    </div>}</>
  </div>);
}

export default SocialMediaShareDropdown;
