import _ from "lodash";
import React from "react"
import SwiperCore, {Pagination, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper-bundle.css";
import { Heading, Subtitle } from "workfinder-components";
import classNames from "classnames";
import styles from "./swiperList.module.scss";
import ImgBase64 from "components/shared/ImgBase64";

SwiperCore.use([Pagination, Navigation]);

interface SwiperNode {
  id: any;
  node: React.ReactNode;
}

interface SwiperListProps {
  name: string;
  title: string;
  subtitle?: string;
  strBase64?: string;
  rows?: number;
  nodes: Array<SwiperNode>;
  noDataComponent?: React.ReactNode;
}

const SwiperList = (props: SwiperListProps) => {
  const {name, title, subtitle, strBase64, rows, nodes, noDataComponent} = props;
  const dataAvailable = nodes && nodes.length > 0;
  return (
    <>
      <Heading size="h3" className={classNames(styles.heading, "d-flex justify-content-between")}>
        <div className="d-flex align-items-center">
          {strBase64 && <><ImgBase64 base64={strBase64} height={24} />&nbsp;</>}
          {title}
        </div>
        <div className={classNames(styles.swiperNavContainer, "d-flex")}>
          <div id={`rl-swiper-button-prev${name}`} className={classNames(styles.swiperNavArrow, dataAvailable ? "": "d-none")}><i className="fa fa-arrow-left"></i></div>
          <div id={`rl-swiper-button-next${name}`} className={classNames(styles.swiperNavArrow, dataAvailable ? "": "d-none")}><i className="fa fa-arrow-right"></i></div>
        </div>
      </Heading>
      {
        subtitle &&
        <Subtitle>{subtitle}</Subtitle>
      }
      {
        !dataAvailable && noDataComponent &&
        <>{noDataComponent}</>
      }
      {
        (dataAvailable || !noDataComponent) &&
        <Swiper
          slidesPerView="auto"
          slidesPerGroup={1}
          navigation={{
            nextEl: `#rl-swiper-button-next${name}`,
            prevEl: `#rl-swiper-button-prev${name}`,
          }}
          breakpoints={{
            768: {slidesPerGroup: 2},
            1400: {slidesPerGroup: 3},
          }}
          spaceBetween={24}
          key={name}
          id={name}
          className={classNames("swiperListContainer", "flex-shrink-0")}
          pagination={true}
          slidesPerColumn={rows ?? 1}
        >
          {nodes.map((node)=> {
            return <SwiperSlide key={node.id}>
              {node.node}
            </SwiperSlide>
          })}
        </Swiper>
      }
    </>
  );
}

export default SwiperList;
