import React, {useEffect} from "react";
import {Slider, Slide, ButtonBack, ButtonNext} from "pure-react-carousel";

const scores = [
  "#D8405B",
  "#D05C5C",
  "#E48F74",
  "#EDA665",
  "#E9BD4E",
  "#EADD69",
  "#DFE163",
  "#D5E478",
  "#B2D765",
  "#9DCF75",
  "#77CF75",
];

interface Props {
    score: number | null;
    onScoreSet?: () => void;
    mobile?: boolean;
    setCarouselStoreState: (arg: any) => void;
    currentSlide?: number;
    hideOpacity?: boolean;
}

const ScoringGrid = ({score, mobile, onScoreSet = () => { }, setCarouselStoreState, currentSlide, hideOpacity = false}: Props): JSX.Element => {

  // Used to allow scrolling of mobile slide
  useEffect(() => {
    document.body.style.setProperty("overscroll-behavior-x", "none");

    return () => {
      document.body.style.removeProperty("overscroll-behavior-x");
    };
  }, []);

  if (mobile) {
    return (
      <div className="scoring-grid__mobile-view-container">
        <span className="scoring-grid__slider-explainer">Please slide or tap to select a score</span>

        <div className="scoring-grid__slider-container">
          <Slider className="scoring-grid__slider" classNameTray="scoring-grid__slider-tray">
            {scores.map((color, index) => {
              let setStyle;

              if (index === score) {
                setStyle = {background: color, opacity: 1};
              }
              else {
                setStyle = {background: color};
              }

              return (
                <Slide
                  index={index}
                  onClick={() => {
                    if (currentSlide === index) {
                      onScoreSet(index);
                    }
                    setCarouselStoreState({currentSlide: index});
                  }}
                  style={setStyle}
                  innerClassName="scoring-grid__slide-inner"
                  className="scoring-grid__score-slide"
                  key={`scoring-slider-${index}`}
                >
                  <div >
                    {index}
                  </div>
                </Slide>);
            })}
          </Slider>
          <div className="scoring-grid__selected-score">
            <div className="scoring-grid__score-pointer"></div>
          </div>
        </div>
        <div className="scoring-grid__arrow-button-container">
          <ButtonBack className="scoring-grid__arrow-button"  >{"\u25C4"}</ButtonBack>
          <ButtonNext className="scoring-grid__arrow-button"  >{"\u25BA"}</ButtonNext>
        </div>
      </div>

    );
  }

  return (
    <div className="scoring-grid">

      {
        scores.map((color, index) => {

          let setStyle;

          if (index === score || hideOpacity === true) {
            setStyle = {background: color, opacity: 1};
          }
          else {
            setStyle = {background: color};
          }

          return (
            <div
              key={`scoring-grid-${index}`}
              onClick={() => {
                onScoreSet(index);
                setCarouselStoreState({currentSlide: index});
              }}
              style={setStyle}
              className="scoring-grid__grid-square"
            >
              {index}
            </div>
          );
        })
      }

    </div >
  );
};

export default ScoringGrid;
