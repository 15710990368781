import React, {useMemo} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./imageInput.module.scss";
import Input from "./Input";


function ImageInput({className, id, name, onChange, round=false, value, ...props}) {
  const imageURL = useMemo(toURL, [value]);

  function toURL() {
    if (!value) {
      return "";
    }
    if (value instanceof File) {
      return URL.createObjectURL(value);
    }
    return value;
  }

  function handleUpload(_, name, e) {
    const {files} = e.target;
    let file = files[0];
    onChange(file || "", name, e);
  }

  const imageClassName = classNames(
    styles.imageUpload,
    className,
    {
      [styles.round]: round,
      [styles.set]: !!imageURL,
      "img-empty": !imageURL,
    },
  );

  return (
    <label
      className={imageClassName}
      htmlFor={id}
      style={imageURL ? {backgroundImage: `url('${imageURL}'`} : {}}
    >
      <Input
        {...props}
        className={styles.hiddenInput}
        id={id}
        name={name}
        onChange={handleUpload}
        type="file"
        accept="image/*"
      />
    </label>
  );
}

ImageInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  round: PropTypes.bool,
  value: PropTypes.any,
  props: PropTypes.object,
};

export default ImageInput;
