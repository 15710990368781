import React, {forwardRef, useState} from "react";
import classNames from "classnames";

import styles from "./input.module.scss";

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  /** A label to display above the calendar input. */
  label?: string;
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref): JSX.Element => {
  const {className, disabled, id, label, value, type: inputType, ...inputProps} = props;
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState(inputType);

  const input = (
    <input
      className={classNames(styles.input, !label && className)}
      disabled={disabled}
      id={id}
      ref={ref}
      value={value}
      type={type}
      {...inputProps}
    />
  );
  if (inputType === "password") {
    return (
      <div className={styles.passwordInput}>
        {input}
        <i
          onClick={() => {
            setShowPassword(!showPassword);
            setType(type === "password" ? "text" : "password");
          }}
          className={`fa fa-eye${showPassword ? "" : "-slash"}`}
        />
      </div>
    );
  }
  if (label === undefined) {
    return input;
  }
  return (
    <div className={className}>
      <label className={styles.label} htmlFor={id}>{label}</label>
      {input}
    </div>
  );
});

Input.displayName = "Input";
export default Input;
