import "whatwg-fetch";
import Cookies from "js-cookie";
import Sentry from "tracking/Sentry";

export const SAFE_METHODS = ("GET", "HEAD", "OPTIONS");

export default async function apiFetch(url, options={}) {
  const {additionalHeaders, allow404=false, cache, data, method, queryParams} = options;
  let updated_url = url;
  let updated_method = method;

  if (!method) {
    updated_method = !data ? "GET" : "POST";
  }
  if (queryParams) {
    updated_url = `${url}?${new URLSearchParams(queryParams)}`;
  }

  let init = {
    method: updated_method,
    cache: cache || "no-store",
    headers: {...additionalHeaders},
    credentials: "include",
    referrerPolicy: "no-referrer",
  };

  if (!SAFE_METHODS.includes(method)) {
    init.headers["X-CSRFToken"] = Cookies.get(process.env.CSRF_COOKIE_NAME ?? "csrftoken");
  }

  if (data) {
    // Infer Content-Type if not given.
    if (!init.headers["Content-Type"]) {
      if (!(data instanceof FormData)) {
        init.headers["Content-Type"] = "application/json";
        init.body = JSON.stringify(data);
      }
      else {
        init.body = data;
      }
    }
    // Format data to match content type.
    if (init.headers["Content-Type"] === "application/json") {
      init.body = JSON.stringify(data);
    }
    else {
      init.body = data;
    }
  }
  const promise = window.fetch(updated_url, init);

  return promise.then(async response => {
    if (allow404 && response.status === 404) {
      return null;
    }
    if (!response.ok) {
      // The text is a stream that can only be read once. We need the text to
      // pass to Sentry and the JSON to give the user feedback. This bit of
      // code slightly alters the standard response by reading the text and
      // storing it permanently, as well as providing the parsed JSON, as
      // this will always be needed by the calling code to figure out what's
      // up with this error.
      response.text = await response.text();
      try {
        response.json = JSON.parse(response.text);
      }
      catch {
        response.json = null;
      }
      // 400 mean the server has an issue with the submitted data, so we need
      // to report it to the user, not Sentry.
      // If 404 is allowed, set the response as `null` instead.
      if (response.status !== 400) {
        Sentry.captureException(
          new Error(response.statusText),
          {
            contexts: {
              API: {
                URL: updated_url,
                Method: updated_method,
                data: data instanceof FormData ? Object.fromEntries(data) : data,
                "Status code": response.status,
                "Response text": response.text,
              },
            },
          },
        );
      }
      throw response;
    }
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.split(";")[0] === "application/json") {
      return promise.then(response => response.json());
    }
    return promise;
  });
}
