/**
 * Update the object in the cache, shallowly patching the existing object with
 * the data in the new object.
 * @param queryClient The query client for the cached object.
 * @param queryKey The query key for the cached object.
 * @param data The new data.
 */
export function updateObjectCache(queryClient, queryKey, data) {
  queryClient.setQueryData(queryKey, old => {
    // Only update mutation if a query has already set data.
    if (old === undefined) {
      return;
    }
    else {
      return {...old, ...data};
    }
  });
}

/**
 * Update the object in a cached list, shallowly patching the existing object
 * with the data in the new object, within a list of objects.
 * @param queryClient The query client for the cached object.
 * @param queryKey The query key for the cached object.
 * @param data The new data.
 * @param idField The field name of the object ID. This will be used to find
 * the new object's cached data in the list of cached objects.
 */
export function updateListCache(queryClient, queryKey, data, idField = "uuid") {
  queryClient.setQueryData(queryKey, old => {
    // Only update mutation if a query has already set data.
    if (old === undefined) {
      return;
    }
    return old.map(obj => obj[idField] === data[idField] ? {...obj, ...data} : obj);
  });
}

/**
 * Update the object in a cached list, shallowly patching the existing object
 * with the data in the new object, within a list of objects.
 * @param queryClient The query client for the cached object.
 * @param queryKey The query key for the cached object.
 * @param data The new data.
 * @param idField The field name of the object ID. This will be used to find
 * the new object's cached data in the list of cached objects.
 */
export function removeFromListCache(queryClient, queryKey, data, idField = "uuid") {
  queryClient.setQueryData(queryKey, old => {
    // Only update mutation if a query has already set data.
    return old.filter(obj => obj[idField] !== data[idField]);
  });
}

/**
 * Add an object to cached list of objects.
 * @param queryClient The query client for the cached object.
 * @param queryKey The query key for the cached object.
 * @param data The new data.
 * @param index The index to insert at. Defaults to inserting at the end.
 */
export function addToListCache<T>(queryClient: any, queryKey: any, data: T, position?: number): void {
  queryClient.setQueryData(queryKey, (old?: T[]) => {
    if (old === undefined) {
      return undefined;
    }
    position ??= old.length;
    const newArr = [...old];
    newArr.splice(position, 0, data);
    return newArr;
  });
}
