import React from "react";
import styles from './RemovablePill.module.scss';

const RemovablePill = ({ id, text, onClose }) => {
  return (
    <div className={styles.removablePill} id={id}>
      <span>{text}</span>
      <i className="pi pi-times" onClick={onClose} />
    </div>
  );
}

export default RemovablePill;
