/*
 * This file contains `react-query` hooks for the v3 API calls, with the aim of
 * keeping query keys, caching and retry logic DRY and consistent.
 */
import {useQuery, UseQueryOptions} from "react-query";
import api from "services/v3";
import {combineQueries} from "util/query";
import _ from "lodash";

// Some queries should never be refetched, e.g. queries for static resources,
// like picklist options.
export const DISABLE_REFETCH_OPTIONS: Record<string, false | number> = {
  refetchInterval: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: Infinity,
};

// Association

export function useAssociationQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["association", uuid],
    () => api.getAssociation(uuid),
    options,
  );
}

// Candidate

export function useCandidateQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["candidate", uuid],
    () => api.getCandidate(uuid),
    options,
  );
}


export function useCandidateScoreQuery(options?: UseQueryOptions) {
  return useQuery(
    ["candidate-score"],
    () => api.getCandidateScore(),
    options,
  );
}
export function useCompanyScoreQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["company-score"],
    () => api.getCompanyScore(queryParams),
    options,
  );
}

export function useMyCandidateQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "my-candidate",
    () => api.getMyCandidate(queryParams),
    options,
  );
}

export function useUserPreferencesQuery(options?: UseQueryOptions) {
  return useQuery(
    "user-preferred-fields",
    () => api.getUserPreferences(),
    options,
  );
}

// Chat

export function useChatMessagesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["chat-messages", queryParams],
    () => api.getChatMessages(queryParams).then(r => r.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useChatDialogQuery(correspondent_id: string, options?: UseQueryOptions) {
  return useQuery(
    ["chat-dialog", correspondent_id],
    () => api.getChatDialog(correspondent_id),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useChatDialogsQuery(options?: UseQueryOptions) {
  return useQuery(
    "chat-dialogs",
    api.getChatDialogs,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// Commenting

export function useCommentsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["comments", queryParams.object_id],
    () => api.getComments(queryParams).then(r => r.results),
    options,
  );
}

// Companies

export function useCompanyQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["company", uuid],
    () => api.getCompany(uuid),
    options,
  );
}

export function useLocationQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["location", uuid],
    () => api.getLocation(uuid),
    options,
  );
}

export function useBenefitsQuery(options?: UseQueryOptions) {
  return useQuery(
    ["benefits"],
    () => api.getBenefits(),
    options,
  );
}

export function useValuesQuery(options?: UseQueryOptions) {
  return useQuery(
    ["values"],
    () => api.getValues(),
    options,
  );
}

export function usePrimaryCompanyQuery(companyPk: string, options?: UseQueryOptions) {
  return useQuery(
    ["primary-company", companyPk],
    () => api.getPrimaryCompany(companyPk),
    options,
  );
}

export function useCompanySocialLinksQuery(companyPk: string, options?: UseQueryOptions) {
  return useQuery(
    ["company-sociallinks", companyPk],
    () => api.getCompanySocialLinks(companyPk).then(response => response.results),
    options,
  );
}

export function useCompanyOptionsQuery(options?: UseQueryOptions) {
  return useQuery(
    "company-options",
    () => api.getCompanyOptions(),
    options,
  );
}

export function useBillingInfoQuery(options?: UseQueryOptions) {
  return useQuery(
    "billing-info",
    () => api.getBillingInfo(),
    options
  )
}

// Hosts

export function useHostQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["host", uuid],
    () => api.getHost(uuid),
    options,
  );
}

export function useProjectPreferencesQuery(options?: UseQueryOptions) {
  return useQuery(
    "project-preferences",
    api.getProjectPreferences,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useHostsForCompanyQuery(companyId, options?: UseQueryOptions) {
  return useQuery(
    ["hosts-for-company"],
    () => api.getHostsForCompany(companyId).then(response => response.results),
    options,
  );
}

// Industry

export function useIndustriesQuery(options?: UseQueryOptions) {
  return useQuery(
    "industries",
    () => api.getIndustries(),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// Interviews

export function useCandidateInterviewDatesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "candidate-interviews",
    () => api.getCandidateInterviewDates(queryParams),
    options,
  );
}

export function useCompletedInterviewsQuery(options?: UseQueryOptions) {
  return useQuery(
    "completed-interviews",
    () => api.getCompletedInterviews().then(response => response.results),
    options,
  );
}

export function useInterviewsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "interviews",
    () => api.getInterviews(queryParams).then(response => response.results),
    options,
  );
}

export function useInterviewQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["interview", uuid],
    () => api.getInterview(uuid, {}).then(response => response.results),
    options,
  );
}

export function useInterviewDashboardQuery(options?: UseQueryOptions) {
  return useQuery(
    "interviewDashboard",
    api.interviewDashboard,
    {...DISABLE_REFETCH_OPTIONS, ...options, enabled: Variables.isAuthenticated},
  );
}

// Placements

type User = "CAN" | "HOS" | "ALL";

export function usePlacementQuery(uuid: string, userType: User, options?: UseQueryOptions) {
  return useQuery(
    ["placement", userType, uuid],
    () => {
      if (userType === "CAN") {
        return api.getCandidatePlacement(uuid);
      }
      if (userType === "HOS") {
        return api.getHostPlacement(uuid);
      }
    },
    options,
  );
}

export function useExpandedPlacements(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["expanded-placements"],
    () => api.getExpandedPlacements(queryParams).then(response => response.results),
    options,
  );
}

export function useExpandedPlacement(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["expanded-placement", uuid],
    () => api.getExpandedPlacement(uuid),
    options,
  );
}

export function usePlacementsQuery(userType: User, queryParams, options?: UseQueryOptions) {
  const {user_uuid, get_shared} = queryParams;
  return useQuery(
    ["placements", user_uuid, get_shared],
    () => {
      if (userType === "CAN") {
        return api.getCandidatePlacements(queryParams).then(response => response.results);
      }
      if (userType === "HOS") {
        return api.getHostPlacements(queryParams).then(response => response.results);
      }
      return api.getPlacements(queryParams).then(response => response.results);
    },
    options,
  );
}

export function usePlacementOffersQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "placement-offers",
    () => api.getPlacementOffers(queryParams),
    options,
  );
}

export function usePlacementOfferCountQuery(options?: UseQueryOptions) {
  return useQuery(
    "placement-offers-count",
    api.getPlacementOfferCount,
    options,
  );
}

export function useWEFSkillsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "wef-skills",
    () => api.getWEFSkills(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePlacementSkillsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "placement-skills",
    () => api.getPlacementSkills(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useDeclinedReasonsQuery(options?: UseQueryOptions) {
  return useQuery(
    "declined-reasons",
    api.getDeclinedReasons,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePlacementOfferDeclinedReasonsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["placement-offer-declined-reasons", queryParams],
    () => api.getPlacementOfferDeclinedReasons(queryParams),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useMyPlacementsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "myplacements",
    () => api.getMyPlacements(queryParams).then(response => response.results?response.results:[]),
    options,
  );
}

export function useCertificatesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "certificates",
    () => api.getCertificates(queryParams).then(response => response.results?response.results:[]),
    options,
  );
}

// Premium partners

export function useMyPremiumPartnerQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "my-premium-partner",
    () => api.getMyPremiumPartner(queryParams),
    options,
  );
}

// Projects

export function useProjectQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["project", uuid],
    () => api.getProject(uuid),
    options,
  );
}

export function useProjectsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["projects", queryParams],
    () => api.getProjects(queryParams).then(response => response.results),
    options,
  );
}

export function useMyProjectsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["my-projects", queryParams],
    () => api.getMyProjects(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options
    },
  );
}

export function useMyProjectCountQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["my-project-count", queryParams],
    () => api.getProjectCount(queryParams).then(response => response),
    options,
  );
}

export function useProjectTemplateQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["project-template", uuid],
    () => api.getProjectTemplate(uuid),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useProjectTemplatesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["project-templates", queryParams],
    () => api.getProjectTemplates(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useProjectTypesQuery(options?: UseQueryOptions) {
  return useQuery(
    "project-types",
    api.getProjectTypes,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useSkillsBuilderSkillsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "skills-builder-skills",
    () => api.getSkillsBuilderSkills(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

//getProjectPrescreenQuestions
export function useProjectPrescreenQuestionsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["project-prescreen-questions", queryParams.project_uuid],
    () => api.getProjectPrescreenQuestions(queryParams),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePreScreenAnswersQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["placement-prescreen-questions", queryParams.placement_uuid],
    () => api.getPreScreenAnswers(queryParams),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}


export function useCandidateTechSkillQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "candidate-tech-skills",
    () => api.getCandidateTechSkill(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useCandidateLanguageQuery(options?: UseQueryOptions) {
  return useQuery(
    "candidate-languages",
    () => api.getCandidateLanguage().then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useCandidateSkillsbuilderSkillsQuery(options?: UseQueryOptions) {
  return useQuery(
    "candidate-skillsbuilder-skills",
    () => api.getCandidateSkillsbuilderSkills().then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useSkillsReferralRequestQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["skills-referral-request", ...Object.values(queryParams)],
    () => api.getSkillsReferralRequest(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useSkillReferralAssociationChoicesQuery(options?: UseQueryOptions) {
  return useQuery(
    "skill-referral-association-choices",
    api.getSkillReferralAssociationChoices,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useRequestedTechSkillReferrals(queryParams={}, options?: UseQueryOptions) {
  return useQuery(
    "requested-tech-skill-referrals",
    () => api.getRequestedTechSkillReferrals(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useRequirementTypesQuery(options?: UseQueryOptions) {
  return useQuery(
    "requirement-types",
    api.getRequirementTypes,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useLocationTypesQuery(options?: UseQueryOptions) {
  return useQuery(
    "location-types",
    api.getLocationTypes,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useCandidateActivitiesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "candidate-activities",
    () => api.getCandidateActivities(queryParams).then(response => response.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useEmploymentTypesQuery(options?: UseQueryOptions) {
  return useQuery(
    "employment-types",
    api.getEmploymentTypes,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useRoleTypesQuery(options?: UseQueryOptions) {
  return useQuery(
    "role-types",
    api.getRoleTypes,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useSkillsAcquiredQuery(options?: UseQueryOptions) {
  return useQuery(
    "skills-acquired",
    api.getSkillsAcquired,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useTechSkillsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "tech-skills",
    () => api.getTechSkills(queryParams),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// Recommendations

export function useProjectRecommendationsQuery(options?: UseQueryOptions) {
  return useQuery(
    "project-recommendations",
    () => api.getProjectRecommendations().then(response => response),
    options,
  );
}

export function useCourseRecommendationsQuery(options?: UseQueryOptions) {
  return useQuery(
    "course-recommendations",
    () => api.getCourseRecommendations().then(response => response),
    options,
  );
}

export function useMasterClassRecommendationsQuery(options?: UseQueryOptions) {
  return useQuery(
    "masterclass-recommendations",
    () => api.getMasterClassRecommendations().then(response => response),
    options,
  );
}

// Share

export function useShareQuery(uuid: string, options?: UseQueryOptions) {
  return useQuery(
    ["share", uuid],
    () => uuid ? api.getShare(uuid) : {},
    {...options, manual: true},
  );
}

export function useInvitesQuery(options?: UseQueryOptions) {
  return useQuery(
    ["member-invites"],
    () => api.getInvites().then(response => response.results),
    {...options, manual: true},
  );
}


// Socialdata

export function useLinkedInDataQuery(uuid?: string, options?: UseQueryOptions) {
  return useQuery(
    ["linkedindata", uuid],
    () => api.getLinkedInData(uuid),
    options,
  );
}

// User

export function useUserQuery(userUUID: string, options?: UseQueryOptions) {
  return useQuery(
    "user",
    () => api.getUser(userUUID),
    options,
  );
}


export function useUserSocialLinksQuery(options?: UseQueryOptions) {
  return useQuery(
    "user-sociallinks",
    () => api.getSocialLinks().then(response => response.results),
    options,
  );
}

export function useUserExperiencesQuery(options?: UseQueryOptions) {
  return useQuery(
    "user-experiences",
    () => api.getExperiences().then(response => response.results),
    options,
  );
}

export function useUserEducationsQuery(options?: UseQueryOptions) {
  return useQuery(
    "user-educations",
    () => api.getEducations().then(response => response.results),
    options,
  );
}


export function useUserCertificatesQuery(options?: UseQueryOptions) {
  return useQuery(
    "user-certificates",
    () => api.getUserCertificates().then(response => response.results),
    options,
  );
}


export function useSelfQuery(options?: UseQueryOptions) {
  return useQuery(
    "user",
    api.getSelf,
    { ...DISABLE_REFETCH_OPTIONS, ...options, enabled: Variables.isAuthenticated},
  );
}

export function useExpandedSelfQuery(options?: UseQueryOptions) {
  const userQuery = useSelfQuery(options);
  const candidateQuery = useCandidateQuery(
    Variables.uuids.candidate,
    {...options, enabled: !!Variables.uuids.candidate},
  );
  const hostQuery = useHostQuery(
    Variables.uuids.host,
    {...options, enabled: !!Variables.uuids.host},
  );
  const queries = [userQuery];
  const user = {...userQuery.data};
  if (user.candidate) {
    user.candidate = candidateQuery.data;
    queries.push(candidateQuery);
  }
  if (user.host) {
    user.host = hostQuery.data;
    queries.push(hostQuery);
  }

  const combinedQuery = combineQueries(queries);
  return {...combinedQuery, data: combinedQuery.isSuccess ? user : undefined};
}

// Voting

export function useVotingCountQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["voting-count", ...Object.values(queryParams)],
    () => api.getVoteCount(queryParams),
    options,
  );
}


export function useVotedQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["voted", ...Object.values(queryParams)],
    () => api.getVoted(queryParams).then(response => response.results),
    options,
  );
}

// Workfinder

export function useEthnicitiesQuery(options?: UseQueryOptions) {
  return useQuery(
    "secondary-ethnicities",
    api.getEthnicities,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePrimaryEthnicitiesQuery(options?: UseQueryOptions) {
  return useQuery(
    "primary-ethnicities",
    api.getPrimaryEthnicities,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useGendersQuery(options?: UseQueryOptions) {
  return useQuery(
    "genders",
    api.getGenders,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePronounsQuery(options?: UseQueryOptions) {
  return useQuery(
    "pronouns",
    api.getPronouns,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useDisabilitiesQuery(options?: UseQueryOptions) {
  return useQuery(
    "disabilities",
    api.getDisabilities,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useLanguagesQuery(options?: UseQueryOptions) {
  return useQuery(
    "languages",
    api.getLanguages,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useCountriesQuery(options?: UseQueryOptions) {
  return useQuery(
    "countries",
    api.getCountries,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useNotificationQuery(options?: UseQueryOptions) {
  return useQuery(
    "activities",
    api.getActivities,
    options,
  );
}


export function useEducationRequirementsQuery(options?: UseQueryOptions) {
  return useQuery(
    "education-requirements",
    api.getEducationRequirements,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useMessagesQuery(options?: UseQueryOptions) {
  return useQuery(
    "messages",
    api.getMessages,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// Document requests
export function usePublicFileUploadQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    "fileupload",
    () => api.getPublicFileUploads(queryParams).then(r => r.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useProjectSalaryRequestCountQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["project-salary-request-count", ...Object.values(queryParams)],
    () => api.getProjectSalaryRequestCount(queryParams),
    options,
  );
}

export function useSalaryRequestStatusQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["salary-request-candidate", ...Object.values(queryParams)],
    () => api.getSalaryRequestStatus(queryParams),
    options,
  );
}

export function useSkillsBuilderSkillLevelsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["skillsbuilderskilllevels", ...Object.values(queryParams)],
    () => api.getSkillsBuilderSkillLevels(queryParams).then(r => r.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

//plans
export function usePlansQuery(options?:UseQueryOptions) {
  return useQuery(
    "plans",
    () => api.getPlans().then(r => r.results),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}
export function useSubDomainQuery(options?:UseQueryOptions) {
  return useQuery(
    "plans",
    api.getPartnerDomain,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function usePartnerPlanQuery(options?:UseQueryOptions) {
  return useQuery(
    "partner-plans",
    api.getPartnerPlan,
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// A collection of shorthands for common chains of requests.

export function useSelfUpToAssociationQuery(options?: UseQueryOptions) {
  const userQuery = useExpandedSelfQuery(options);
  const user = _.cloneDeep(userQuery.data);
  const associationQuery = useAssociationQuery(
    user?.host.associations[0],
    {...options, enabled: userQuery.isSuccess},
  );
  const combinedQuery = combineQueries([userQuery, associationQuery]);
  if (combinedQuery.isSuccess) {
    user.host.association = associationQuery.data;
  }
  return {...combinedQuery, data: combinedQuery.isSuccess ? user : undefined};
}

export function useSelfUpToLocationQuery(options?: UseQueryOptions) {
  const userQuery = useSelfUpToAssociationQuery(options);
  const user = _.cloneDeep(userQuery.data);
  const locationUUID = user?.host.association.location;
  const locationQuery = useLocationQuery(locationUUID, {...options, enabled: userQuery.isSuccess});
  const combinedQuery = combineQueries([userQuery, locationQuery]);
  if (combinedQuery.isSuccess) {
    user.host.association.location = locationQuery.data;
  }
  return {...combinedQuery, data: combinedQuery.isSuccess ? user : undefined};
}

export function useSelfUpToCompanyQuery(options?: UseQueryOptions) {
  const userQuery = useSelfUpToLocationQuery(options);
  const user = _.cloneDeep(userQuery.data);
  const companyUUID = user?.host.association.location.company;
  const companyQuery = useCompanyQuery(companyUUID, {...options, enabled: userQuery.isSuccess});
  const combinedQuery = combineQueries([userQuery, companyQuery]);
  if (combinedQuery.isSuccess) {
    user.host.association.location.company = companyQuery.data;
  }
  return {...combinedQuery, data: combinedQuery.isSuccess ? user : undefined};
}

// Success stories
export function useSuccessStoriesQuery(storyType?: string, options?: UseQueryOptions) {
  return useQuery(
    ["success-stories", storyType],
    () => api.getSuccessStories(storyType),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

export function useSuccessStoryQuery(storyType: string, userName: string, options?: UseQueryOptions) {
  return useQuery(
    ["success-story", userName],
    () => api.getSuccessStory(storyType, userName),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}

// Public Profile
export function useUserPublicQuery(userPk: string, options?: UseQueryOptions) {
  return useQuery(
    ["public-user", userPk],
    () => api.getPublicUser(userPk),
    options,
  );
}

export function useCandidatePublicQuery(userPk: string, options?: UseQueryOptions) {
  return useQuery(
    "candidate",
    () => api.getPublicCandidate(userPk),
    options,
  );
}

export function usePublicEducationsQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["educations", queryParams.user],
    () => api.getPublicEducations(queryParams).then(r => r.results),
    options,
  );
}


export function usePublicExperiencesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["experiences", queryParams.user],
    () => api.getPublicExperience(queryParams).then(r => r.results),
    options,
  );
}


export function usePublicCertificatesQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["certificates", queryParams.user],
    () => api.getPublicCertificates(queryParams).then(r => r.results),
    options,
  );
}


export function usePublicSocialLinksQuery(queryParams, options?: UseQueryOptions) {
  return useQuery(
    ["sociallinks", queryParams.user],
    () => api.getPublicSocialLinks(queryParams).then(r => r.results),
    options,
  );
}

export function useConfigValueQuery(queryParams,options?: UseQueryOptions) {
  return useQuery(
    ["config-values", queryParams.key],
    () => api.getConfigValue(queryParams),
    {
      ...DISABLE_REFETCH_OPTIONS,
      ...options,
    },
  );
}
