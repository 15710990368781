import React from "react";
import PropTypes from "prop-types";
import Card from "../Card";
import classNames from "classnames";
import styles from "./slate.module.scss";


function Slate({children, className, ...props}) {
  return (
    <Card className={classNames(styles.card, className)} {...props}>
      { children }
    </Card>
  );
}

Slate.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Slate;
