import React, {forwardRef} from "react";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  baseComponent?: "img";    //Later we can extend to set image in div background
  base64: string;
  kind?: "jpeg" | "png",
};

const ImgBase64 = forwardRef<any, Props>((props, ref): JSX.Element => {
  const {
    baseComponent="img",
    kind="png",
    base64,
    className,
    ...imgProps
  } = props;

  return React.createElement(
    baseComponent,
    {
      className: className,
      ref,
      src: `data:image/${kind};base64,${base64}`,
      ...imgProps,
    },
  );
});

export default ImgBase64;
