import _ from "lodash";

/**
 * Picks the "least false" value.
*/
export function pickLeastFalse<X, Y>(x: X, y: Y): X | Y {
  const falsenessLevels = [
    v => v === undefined,
    v => v === null,
    v => v === "",
    v => (_.isPlainObject(v) || Array.isArray(v)) && _.isEmpty(v),
    v => typeof v === "boolean",
    v => !v,
    v => !!v,
  ];
  const xLevel = falsenessLevels.findIndex(f => f(x));
  const yLevel = falsenessLevels.findIndex(f => f(y));
  if (xLevel > yLevel) {
    return x;
  }
  return y;
}

/**
 * Like `_.merge`, except that it creates a new object and keeps the first
 * truthy value, where truthiness is determined by Python's rules, i.e. empty
 * objects and arrays are falsy.
 */
export function falsyMerge(obj, ...sources) {
  return _.mergeWith(_.cloneDeep(obj), ...sources, pickLeastFalse);
}

export const removeHash = () => {
  history.replaceState(null, "", window.location.href.split("#")[0]);
};
