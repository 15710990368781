import React, {useEffect, useState} from "react";
import Modal from "components/shared/Modal/Modal";
import {Content} from "components/shared/Slate";

import ProgressBar from "components/candidate-dashboard/ProgressBar";
import {
  Button,
  Body,
  Heading,
  RichTextEditor,
  Tooltip,
} from "workfinder-components";
import modalTheme from "styles/modals/flat.module.scss";
import baseStyles from "./applicationModal.module.scss";
import fullPageTheme from "styles/modals/fullPage.module.scss";
import {mergeClassNames} from "styles/utils";
import classNames from "classnames";
import {
  DISABLE_REFETCH_OPTIONS,
  useCandidateScoreQuery,
  usePreScreenAnswersQuery,
  useProjectPrescreenQuestionsQuery,
} from "services/queries";

import api from "services/v3";
import defaultCompanyLogo from "/static/workfinder/images/default_company_logo.png";

const styles = mergeClassNames(baseStyles, mergeClassNames(modalTheme, fullPageTheme));

const coverLetterTooltipContent = (
  <>
    <Heading size="h5">Steps to write the perfect cover letter</Heading>
    <Body className={styles.tooltipBody} size="small">
      <ol>
        <li>
          👋 <strong>Start with a Greeting:</strong> Address the hiring
          manager&apos;s with their name or with &quot;Dear Hiring
          Manager&quot;.
        </li>
        <li>
          📝 <strong>Introduction:</strong> Identify the role you&apos;re
          applying for and present a compelling reason you&apos;re interested.
        </li>
        <li>
          🌟 <strong>Highlight Your Value:</strong> Detail 2-3 relevant
          experiences or skills that demonstrate your fit for the role.
        </li>
        <li>
          ⚖️ <strong>Express Company Alignment:</strong> Show your enthusiasm by
          connecting your values or interests with the company&apos;s mission or
          projects.
        </li>
        <li>
          🎬 <strong>Wrap it Up:</strong> Summarize your main points, thank them
          for their time, and express hope for further discussion.
        </li>
        <li>
          ✍️ <strong>Sign Off:</strong> Conclude with a professional close, like
          &quot;Best regards,&quot; followed by your name.
        </li>
      </ol>
      👉 Keep it succinct and always customize your letter to the specific role
      and company.
    </Body>
  </>
);

enum DraftStatus {
  IDLE = "idle",
  SAVING = "saving",
  SAVED = "saved",
  ERROR = "error",
}

enum ApplyStatus {
  IDLE = "idle",
  APPLYING = "applying",
  APPLIED = "applied",
  ERROR = "error",
  LOADING = "Loading",
  NEXT = "Next",
}

const ApplicationModalButton = ({
  buttonText,
  primaryButton = false,
  ...props
}): JSX.Element => {
  const [isApplicationOpen, setIsApplicationOpen] = useState(false);
  const [placementuuid, setPlacementUuid] = useState();
  const [placementId, setPlacementId] = useState();
  const [isMinorApplicationOpen, setIsMinorApplicationOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [initialCoverLetter, setInitialCoverLetter] = useState("");
  const [coverLetter, setCoverLetter] = useState();
  const [applyStatus, setApplyStatus] = useState<ApplyStatus>(ApplyStatus.LOADING);
  const {associatedProject} = props;
  const [questions, setQuestions] = useState([]);


  useEffect(() => {
    api
      .getCandidatePlacements({
        associated_project: props.associatedProject,
        limit: 1,
      })
      .then(({results}) => {
        if (results.length) {
          const {cover_letter} = results[0];
          const placement = results[0];
          setPlacementId(placement.id);
          setPlacementUuid(placement.uuid);
          setInitialCoverLetter(cover_letter);
        }
      });
  }, []);


  const {isLoading} = useProjectPrescreenQuestionsQuery(
    {
      project_uuid: associatedProject,
    },
    {
      enabled: !!associatedProject,
      onSuccess: (data) => {
        if (data.length > 0) {
          setQuestions(data.map((item) => item.question));
          setApplyStatus(ApplyStatus.NEXT);
        }
        else {
          setApplyStatus(ApplyStatus.IDLE);
        }
      },
    },
  );

  useEffect(() => {
    if (window.location.hash === "#apply") {
      setIsApplicationOpen(true);
      window.history.replaceState({}, "", window.location.pathname);
    }
  });

  const [draftStatus, setDraftStatus] = useState<DraftStatus>(DraftStatus.IDLE);

  const onSaveDraft = async (coverletter) => {
    setDraftStatus(DraftStatus.SAVING);
    const {associationUuid, associatedProject} = props;

    const payload = {
      candidate: Variables.uuids.candidate,
      association: associationUuid,
      associated_project: associatedProject,
      status: "draft",
      cover_letter: coverletter,
    };
    api.createPlacement(payload).then((data) => {
      setDraftStatus(DraftStatus.SAVED);
      setPlacementUuid(data.uuid);
      setPlacementId(data.id);
      // setLastSavedCoverLetter(coverLetter);
    }).catch((error) => {
      setDraftStatus(DraftStatus.ERROR);
    }).finally(() => {
      setTimeout(() => setDraftStatus(DraftStatus.IDLE), 3000);
    });

  };
  const onNext = async (coverLetter) => {

    if (applyStatus == ApplyStatus.NEXT) {
      await onSaveDraft(coverLetter);
    }
    else if (applyStatus == ApplyStatus.IDLE) {
      onApply();
    }
  };

  const onApply = () => {
    setApplyStatus(ApplyStatus.APPLYING);
    setConfirmationOpen(true);
    const {associationUuid, associatedProject} = props;
    const payload = {
      candidate: Variables.uuids.candidate,
      association: associationUuid,
      associated_project: associatedProject,
      status: "pending",
      cover_letter: coverLetter,
    };
    api.createPlacement(payload).then(() => {
      setApplyStatus(ApplyStatus.APPLIED);
      setIsApplicationOpen(false);
      setConfirmationOpen(true);
    }).catch(() => {
      setApplyStatus(ApplyStatus.ERROR);
    }).finally(() => {
      setTimeout(() => setApplyStatus(ApplyStatus.IDLE), 5000);
    });
  };

  function openApplicationModal() {
    if (Variables.user?.is_minor) {
      setIsMinorApplicationOpen(true);
    }
    else {
      setIsApplicationOpen(true);
    }
  }


  return (
    <>
      {primaryButton ? (
        <Button onClick={openApplicationModal}>
          {buttonText || "Apply now"}
        </Button>
      ) : (
        <button onClick={openApplicationModal}>
          {buttonText || "Apply now"}
        </button>
      )}
      {isApplicationOpen || applyStatus === ApplyStatus.APPLYING ? (
        <ApplicationModal
          initialCoverLetter={initialCoverLetter}
          open={isApplicationOpen}
          onClose={() => setIsApplicationOpen(false)}
          onNext={onNext}
          onApply={onApply}
          applyStatus={applyStatus}
          onSaveDraft={onSaveDraft}
          draftStatus={draftStatus}
          setDraftStatus={setDraftStatus}
          setApplyStatus={setApplyStatus}
          placementuuid={placementuuid}
          placementid={placementId}
          questions={questions}
          setCoverLetter={setCoverLetter}
          coverLetter={coverLetter}
          {...props}
        />
      ) : null}
      {confirmationOpen && applyStatus === ApplyStatus.APPLIED ? (
        <ApplicationSentModal
          open={confirmationOpen}
          onClose={() => {
            window.location.href = Variables.urls.candidates.dashboard;
            setConfirmationOpen(false);
          }}
          {...props}
        />
      ) : null}
      {isMinorApplicationOpen && <MinorApplicationModal
        open={isMinorApplicationOpen}
        onClose={() => {
          setIsMinorApplicationOpen(false);
        }}
      />}

    </>
  );
};

const CompanyHeader = ({
  companyLogo,
  projectName,
  companyName,
  associatedProject,
}) => {
  return (
    <div className={classNames(styles.header, "d-flex")}>
      <img
        className={styles.companyLogo}
        src={companyLogo || defaultCompanyLogo}
      />
      <div className="d-flex flex-column">
        <a
          className={styles.projectLink}
          href={Variables.urls.projects.detail.replace(
            "<uuid:uuid>",
            associatedProject,
          )}
          target="_blank"
          rel="noreferrer"
        >
          <Heading size="h5">{projectName}</Heading>
        </a>
        <span className={styles.companyName}>{companyName}</span>
      </div>
    </div>
  );
};

const DraftButtonContent = ({draftStatus}) => {
  switch (draftStatus) {
  case DraftStatus.SAVING:
    return (
      <>
        <div className={styles.lsdRing}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
          Saving
      </>
    );
  case DraftStatus.SAVED:
    return (
      <>
        <i className="ri-check-line mr-1" style={{color: "#9EA7B8"}}></i>
          Saved!
      </>
    );
  case DraftStatus.ERROR:
    return (
      <>
        <i
          className="ri-error-warning-line mr-1"
          style={{color: "#9EA7B8"}}
        ></i>
          Error
      </>
    );
  case DraftStatus.IDLE:
    return <>Save Draft</>;
  default:
    return <i className="ri-error-warning-line mr-1" style={{color: "#9EA7B8"}}></i>;
  }
};

const ApplicationSentModal = ({
  companyLogo,
  projectName,
  companyName,
  hostName,
  associatedProject,
  ...props
}) => {
  return (
    <Modal
      id="application-sent-modal"
      theme={styles}
      className={styles.customModal}
      {...props}
    >
      <CompanyHeader
        companyLogo={companyLogo}
        projectName={projectName}
        companyName={companyName}
        associatedProject={associatedProject}
      />
      <div className={styles.content}>
        <hr />
        <div className={styles.clover}>🍀</div>
        <div className="text-center w-100 mb-3">
          <strong>Application sent!</strong>
        </div>
        <p className="mb-2">
          Awesome job! Your application has been sent to {hostName} at{" "}
          {companyName}.{" "}
        </p>
        <p className="mb-2">
          <strong>What&apos;s next?</strong>
        </p>
        <p className="mb-2">
          Stay tuned - we will update you as soon as we hear back from them.{" "}
        </p>
        <p className="mb-2">
          Until then, make sure to complete and polish your profile to impress{" "}
          {hostName} when they review your application:
        </p>
        <Button
          className="w-100"
          href={Variables.urls.candidates.member_profile}
          baseComponent="a"
        >
          Improve my profile
        </Button>
      </div>
    </Modal>
  );
};

const ApplicationModal = ({
  associatedProject,
  companyLogo,
  hostName,
  projectName,
  companyName,
  onNext,
  initialCoverLetter,
  applyStatus,
  onSaveDraft,
  draftStatus,
  setDraftStatus,
  setApplyStatus,
  onApply,
  placementuuid,
  placementid,
  questions,
  setCoverLetter,
  coverLetter,
  ...props
}) => {
  const {data: {profile_status_perc} = {}} = useCandidateScoreQuery(
    DISABLE_REFETCH_OPTIONS,
  );

  const [lastSavedCoverLetter, setLastSavedCoverLetter] = useState(
    initialCoverLetter,
  );
  const transformedData = {};
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    questions.forEach(question => {
      const {id} = question;
      if (!transformedData[id]) {
        transformedData[id] = {
          placement: placementid,
          question: id,
          answer: "",
        };
      }
    });
    setAnswers(transformedData);
  }, [placementid]);

  const {isLoading} = usePreScreenAnswersQuery(
    {
      placement_uuid: placementuuid,
    },
    {
      enabled: !!placementuuid || !!placementid,
      onSuccess: (data) => {
        if (data.length > 0) {
          const transformedData = {};
          data.forEach(item => {
            const {question, answer} = item;
            const {id} = question;
            if (!transformedData[id]) {
              transformedData[id] = {
                question: id,
                uuid: item.uuid,
                answer: answer,
                placement: placementid,
              };
            }
          });
          setAnswers(transformedData);
        }
      },
    },
  );


  const handleAnswerChange = (question_id, newValue) => {
    const current_answer = answers[question_id];
    setAnswers((prevAnswers) => ({...prevAnswers, [question_id]: {...current_answer, answer: newValue}}));
  };

  // const [draftStatus, setDraftStatus] = useState<DraftStatus>(DraftStatus.IDLE);
  const [prescreenContent, setPrescreenContent] = useState(false);

  const onSaveQuestionsDraft = async (from_appy=false) => {
    const payload = Object.values(answers);
    if (!from_appy) {
      setDraftStatus(DraftStatus.SAVING);
    }
    else {
      setApplyStatus(ApplyStatus.LOADING);
    }
    await api.savePreScreenAnswers(payload).then((data) => {
      const transformedData = {};
      data.forEach(item => {
        const {question, answer} = item;
        const {id} = question;
        if (!transformedData[id]) {
          transformedData[id] = {
            question: id,
            uuid: item.uuid,
            answer: answer,
            placement: placementid,
          };
        }
      });
      setAnswers(transformedData);
      if (!from_appy) {
        setDraftStatus(DraftStatus.SAVED);
      }
      else {
        setApplyStatus(ApplyStatus.APPLIED);
      }

    }).catch((error) => {
      if (!from_appy) {
        setDraftStatus(DraftStatus.ERROR);
      }
      else {
        setApplyStatus(ApplyStatus.ERROR);
      }
    }).finally(() => {
      if (!from_appy) {
        setTimeout(() => setDraftStatus(DraftStatus.IDLE), 3000);
      }
      else {
        setTimeout(() => setApplyStatus(ApplyStatus.IDLE), 3000);
      }
    });
  };
  const allQuestionsAnswered = Object.values(answers).every(answer => answer.answer.trim() !== "");


  const ApplyButtonContent = () => {
    switch (applyStatus) {
    case ApplyStatus.APPLYING:
      return (
        <>
          <div className={classNames(styles.lsdRing, styles.applySpinner)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
            Applying
        </>
      );
    case ApplyStatus.LOADING:
      return (
        <>
          <div className={classNames(styles.lsdRing, styles.applySpinner)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      );
    case ApplyStatus.APPLIED:
      return (
        <>
          <i className="ri-check-line mr-1" style={{color: "#9EA7B8"}}></i>
            Applied!
        </>
      );
    case ApplyStatus.ERROR:
      return (
        <>
          <i
            className="ri-error-warning-line mr-1"
            style={{color: "#9EA7B8"}}
          ></i>
            Error
        </>
      );
    case ApplyStatus.NEXT:
      return <>Next</>;
    case ApplyStatus.IDLE:
      return <>Apply</>;
    default:
      return (
        <i
          className="ri-error-warning-line mr-1"
          style={{color: "#9EA7B8"}}
        ></i>
      );
    }
  };

  const prescreenSection = (<>
    <div
      className={classNames(styles.content, "d-flex flex-column flex-grow-1")}
    >
      <div className="d-flex mb-2">
        <strong className={styles.sectionTitle}>
            3. Application Questions
        </strong>
          &nbsp;
        <span className={styles.recommendedTag}>Required</span>
      </div>
      <Body className={styles.coverLetterAdvice}>
          The recruiter has requested answers to the following questions:
      </Body>
      {questions.map((item, index) => (
        <div key={index}>
          <span className={styles.recruitersName}>
            {index + 1}. {item.question}
          </span>
          <RichTextEditor
            className="mt-2"
            value={answers[item.id]?.answer || ""}
            onChange={(newValue) => handleAnswerChange(item.id, newValue)}
            placeholder="Please add your answer here"
            hideToolbar
          ></RichTextEditor>
        </div>
      ))}
      <div className={styles.buttonsGroup}>
        <Button
          onClick={onSaveQuestionsDraft}
          kind="secondary"
          disabled={
            draftStatus === DraftStatus.SAVING || !placementid
          }
        >
          <DraftButtonContent draftStatus={draftStatus}/>
        </Button>
        <Button
          onClick={() => {
            setApplyStatus(ApplyStatus.APPLYING);
            onSaveQuestionsDraft(true);
            onApply();
          }}
          disabled={!allQuestionsAnswered}
        >
          Apply
        </Button>
      </div>
    </div>
  </>);

  const coverletterSection = (<>
    <div
      className={classNames(styles.content, "d-flex flex-column flex-grow-1")}
    >
      <div className="mb-3">
        <div className="d-flex mb-2">
          <strong className={styles.sectionTitle}>1. My Profile</strong>
          {profile_status_perc === 100 ? (
            <span className={styles.profileCompleteTag}>
              <i className="ri-checkbox-circle-line mr-1"></i>
                Profile complete
            </span>
          ) : (
            <span className={styles.profileRequiredTag}>
              <i className="ri-error-warning-line mr-1"></i>
                Complete profile required
            </span>
          )}
        </div>
        <ProgressBar minimal />
      </div>
      <div className="d-flex flex-column flex-grow-1 mb-3">
        <div className="d-flex align-items-center">
          <strong className={styles.sectionTitle}>2. Cover Letter</strong>
          <Tooltip
            className={styles.mobileTooltip}
            iconComponent={
              <i
                className="ri-question-line mx-1"
                style={{color: "#9EA7B8"}}
              ></i>
            }
          >
            {coverLetterTooltipContent}
          </Tooltip>
          <span className={styles.recommendedTag}>Recommended</span>
        </div>
        <Body className={styles.coverLetterAdvice}>
            Including a cover letter increases your chances of success by 10x.
            Tailor your cover letter to the role, highlighting how your skills
            align.
        </Body>
        <span className={styles.recruitersName}>
            The recruiter&apos;s name is: {hostName}
        </span>
        <div className="my-1" />
        <RichTextEditor
          className="mt-2"
          value={coverLetter}
          onChange={setCoverLetter}
          onReady={(editor) => editor.setData(initialCoverLetter)}
          hideToolbar
        ></RichTextEditor>
      </div>
      <div className={styles.buttonsGroup}>
        <Button
          onClick={() => onSaveDraft(coverLetter)}
          kind="secondary"
          disabled={
            draftStatus === DraftStatus.SAVING ||
              coverLetter === lastSavedCoverLetter
          }
        >
          <DraftButtonContent draftStatus={draftStatus}/>
        </Button>
        <Button
          onClick={() => {
            onNext(coverLetter);
            if (questions.length > 0) {
              setPrescreenContent(true);
            }
          }}
          disabled={profile_status_perc !== 100 }
        >
          <ApplyButtonContent />
        </Button>
      </div>
    </div>
  </>);

  return (
    <Modal
      id="apply-now-modal"
      theme={styles}
      className={styles.customModal}
      {...props}
    >
      <CompanyHeader
        companyLogo={companyLogo}
        projectName={projectName}
        companyName={companyName}
        associatedProject={associatedProject}
      />
      {prescreenContent ? prescreenSection:coverletterSection}
    </Modal>
  );
};

const MinorApplicationModal = ({open, onClose, ...props}):JSX.Element => {
  return (<Modal
    id="apply-minor-modal"
    theme={styles}
    onClose={onClose}
    className={styles.customModal}
    open={open}
    {...props}
  >
    <Content className={styles.content}>
      <Heading size="h4" className={styles.minorHeading}>Hi there Superhero! 😊</Heading>

      <Body>We love your enthusiasm, but to apply for jobs, you need to be <b>at least 14 years old.</b></Body>

      <Body>Don&apos;t worry, though! You can still enjoy and benefit from our fantastic learning services.</Body>

      <Body>We encourage you to explore our learning resources, where you can acquire valuable skills and knowledge. We're here to support your growth! 🚀</Body>
      <Button className="w-100" baseComponent="a" href={Variables.urls.candidates.member_profile}> Go to Skills
      </Button>
    </Content>


  </Modal>
  );
};

export default ApplicationModalButton;
