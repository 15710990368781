import React, { useState } from "react";
import Actions from "./Actions";
import PlacementList from "./PlacementList";
import CompletionCertificates from "./CompletionCertificates";
import DocumentRequestUpdate from "./DocumentRequestUpdate";
import InterviewFeedbackTrigger from "./InterviewFeedbackTrigger";
import {CandidateDashboardContextProvider} from "../../apps/CandidateDashboardContext";
import ThankYouModal from "./../shared/Modal/ThankYouModal";
import Star from "/static/workfinder/images/star.png";
import WelcomeCard from "./WelcomeCard";
import RoleList from "./RoleList";
import CourseList from "./CourseList";
import MasterclassList from "./MasterclassList";
import {Heading, Subtitle} from "workfinder-components";
import ProgressBar from "./ProgressBar";
import InterviewsList from "components/dashboard/InterviewsList/index";
import api from "services/v3";
import {DISABLE_REFETCH_OPTIONS, usePlacementQuery} from "services/queries";
import {chatModalInterface} from "components/shared/Modal/ChatModal";
import { featureUrl, interestEvent } from "util/cms/constants";
import EventTracking from "tracking/EventTracking";
import CoverLetterModal from "./CoverLetterModal";
import SkillReferralRequestModal from "./SkillReferralRequest/SkillReferralRequestModal";

export default function CandidateDashboard({interviewSelectedSuccess = null, afterInterviewEmailSuccess=null}) {
  const [isSkillReferralRequestModalOpen, setIsSkillReferralRequestModalOpen] = useState(false);
  const [submittedReferralUuid, setSubmittedReferralUuid] = useState();
  var params = new URLSearchParams(location.search);
  const placementUuid = params.get("placement_uuid");
  usePlacementQuery(placementUuid, "CAN", {
    ...DISABLE_REFETCH_OPTIONS,
    enabled: !!placementUuid,
    onSuccess: async ({candidate_user, candidate_name, host_user, host_name}) => {
      let correspondentName, correspondentUUID;
      if (Variables.user.uuid === candidate_user) {
        correspondentUUID = host_user;
        correspondentName = host_name;
        chatModalInterface.dispatch({
          open: true,
          correspondentUUID,
          correspondentName,
          placementUuid,
        });
      }
      else if (Variables.user.uuid === host_user) {
        correspondentUUID = candidate_user;
        correspondentName = candidate_name;
        api.hasMessagingFeature(Variables.uuids.user).then(({hasFeature}) => {
          if (hasFeature) {
            chatModalInterface.dispatch({
              open: true,
              correspondentUUID,
              correspondentName,
              placementUuid,
            });
          }
          else {
            window.open(featureUrl.MESSAGING);
            EventTracking.send({
              content_object: {
                object_id: Variables.user.pk,
                object_type: "User",
              },
              event_type: interestEvent.MESSAGING,
            });
          }
        });
      }
      history.pushState("", document.title, window.location.pathname);
    },
  });
  if (window.location.hash.includes("skillreferral")) {
    if (!isSkillReferralRequestModalOpen) {
      setIsSkillReferralRequestModalOpen(true);
    }
    window.location.hash = "";
  }
  return (
    <CandidateDashboardContextProvider>
      <div className="container ml-xl-0">
        <div className="row">
          <div className="col-12 d-flex flex-column">
            <WelcomeCard/>
            <ProgressBar />
            <InterviewsList/>
            <Actions setIsSkillReferralRequestModalOpen={setIsSkillReferralRequestModalOpen} submittedReferralUuid={submittedReferralUuid} hideDetails/>
            <RoleList/>
            <CourseList/>
            <MasterclassList/>
            <PlacementList/>
            <CompletionCertificates/>
            <DocumentRequestUpdate/>
            <ThankYouModal isSuccess={interviewSelectedSuccess}
              header={<>
                <Heading size="h4">✅ Your interview is confirmed!</Heading>
              </>}
              message={<Subtitle>We have shared your selected interview time with the recruiter and have sent you the interview details via email.</Subtitle>}
              width={410}
            />
            <ThankYouModal isSuccess={afterInterviewEmailSuccess}
              header={<>
                <img width={33} style={{"padding-bottom": "1rem"}} src={Star}/>
                <Heading size="h3">Thank you!</Heading>
              </>}
              message={<Subtitle>Your feedback has been recorded!</Subtitle>}
            />
            <SkillReferralRequestModal isOpen={isSkillReferralRequestModalOpen} setIsOpen={setIsSkillReferralRequestModalOpen} setSubmittedReferralUuid={setSubmittedReferralUuid}/>
            <InterviewFeedbackTrigger/>
            <CoverLetterModal/>
          </div>
        </div>
      </div>
    </CandidateDashboardContextProvider>
  );
}
