import React, {useEffect, useState} from "react";
import apiFetch from "util/apiFetch";
import {useAlerts} from "components/shared/AlertsProvider";
import classNames from "classnames";

import {Body, Heading} from "workfinder-components";
import LoadingSpinner from "components/shared/LoadingSpinner";

import styles from "./projectPreview.module.scss";

import ClickAwayListener from "react-click-away-listener";

const ProjectPreviewBanner = ({onClose}) => {
  return (
    <div className="container-fluid container">
      <div className={styles.box}>
        <div className={styles.header}>
          <Heading size="h5">Role preview</Heading>
          <div onClick={onClose}>
            <Heading size="h5"
              className={styles.closeButton}
            >
              <i className="ri-arrow-left-line mr-2"></i>
              Back to role editing
            </Heading>
          </div>
        </div>
        <Body size="small" className={styles.previewMessage}>
          Please take a moment to review the briefing we have prepared so that potential applicants can decide if they wish to apply to join your team. Please note that we have pre-filled activities and skills that are common to the draft role you shared with us - you may edit these at any time after clicking the ‘create role’ button.
        </Body>
      </div>
    </div>
  );
};

const ProjectPreviewPage = ({project, style}) => {
  const getProjectDetailFragment = () => {
    if (!project) {
      // project recommendation looks like superpower.tech/recommendationUUID/?rec=true&project=projectUUID
      const urlParams = new URLSearchParams(window.location.search);
      const projectUuid = urlParams.get("project");
      return apiFetch(`/project-detail-content/${projectUuid}`);
    }
    return apiFetch(`/project-detail-content/${project.uuid}`);
  };
  const [projectDetailFragment, setProjectDetailFragment] = useState();
  useEffect(
    () =>
      getProjectDetailFragment().then((resp) =>
        resp.text().then(
          resp => {
            setProjectDetailFragment(resp);
            window.scrollTo(0, 0);
            document.body.style.overflow = "hidden";
          },
        ),
      ),
    [],
  );
  return projectDetailFragment ? (
    <div
      dangerouslySetInnerHTML={{__html: projectDetailFragment}}
      style={style}
    />
  ) : (
    <div className={styles.spinnerContainer}>
      <LoadingSpinner color="#006FF1" className={styles.spinner} />
    </div>
  );
};

export const ProjectPreview = ({project, setPreview, className}) => {
  useEffect(
    () => {
      // since clicking on the preview button that shows this ProjectPreview component
      // triggers the onClickAway event, we need this timeout to reset preview to true
      setTimeout(
        () => setPreview(true),
        200,
      );
    }, [setPreview],
  );
  const [addAlert] = useAlerts();
  const onClose = () => {
    setPreview(false);
    document.body.style.overflow = "auto";
  };
  const [previewHeight, setPreviewHeight] = useState("70vh");
  const resizeHandler = () => {
    const offset = document.getElementById("preview")?.offsetTop;
    const screenHeight = window.innerHeight;
    const newHeight = Math.max(screenHeight - offset - 64 - 64, 40) || 40;
    setPreviewHeight(newHeight);
  };
  useEffect(() => resizeHandler(), []);
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const rec = urlParams.get("rec");
    // we are triggering this particular alert ONLY BECAUSE that's the only use for now
    if (rec) {
      addAlert(
        "reference-saved",
        "custom",
        "Your preference is saved and will adjust the opportunities we recommend to you",
        6000,
        "Thank you",
      );
    }
  }, []);

  if (!project) {
    // document.body.style.backgroundColor = "white";
    return <ProjectPreviewPage />;
  }

  // document.body.style.overflow = "hidden";
  return (
    <div className={classNames("d-flex flex-column mx-auto mt-3", className)}>
      <ClickAwayListener
        onClickAway={() => {
          setPreview(false);
        }}
      >
        <ProjectPreviewBanner onClose={onClose} />
        <div id="preview">
          <ProjectPreviewPage
            project={project}
            style={{maxHeight: previewHeight}}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ProjectPreview;
